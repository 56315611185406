import React, { FC, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { Switch } from "@mui/material";
import { Input } from "../../../../components";
import { Form, FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import { BlockedNumberModel } from "../../../../utils/types";
import { BLOCKED_NUMBER_CHANNEL } from "../../../../utils/enums";
import { formatPhoneNumber } from "../../../../utils/helpers";

export interface IBlockedNumberFormInitParams {
  getFormData: () => any;
}

export interface IBlockedNumberFormProps {
  blockedNumber?: BlockedNumberModel;
  onChange?: (params: IBlockedNumberFormInitParams) => void;
  onClose?: () => void;
}

export type IBlockedNumberForm = {
  number: FormControl;
  description: FormControl;
  channels: FormControl;
}

const BlockedNumberForm: FC<IBlockedNumberFormProps> = ({
  blockedNumber,
  onChange = () => {},
  onClose = () => {},
}) => {
  const [form, formData] = useForm<IBlockedNumberForm>({
    number: new FormControl('', [Validators.required(), Validators.phone()]),
    description: new FormControl(null, []),
    channels: new FormControl([], [Validators.required()]),
  });
  const [activeChannels, setActiveChannels] = useState<Set<string>>(new Set<string>())
  const [channels, setChannels] = useState(
    Object.entries(BLOCKED_NUMBER_CHANNEL).map(([k, v]) => ({
      text: v,
      value: k,
    }))
  );
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (blockedNumber) {
      form.patch({
        number: blockedNumber.pretty_number(),
        description: blockedNumber.description,
      });
      for (var channel of blockedNumber.channels) {
        changeChannelActivation(channel, true)
      }
      form.controls.number.disable();
    }
  }, []);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onChange({ getFormData });
  }, [getFormData]);

  const changeChannelActivation = (channel, checked) => {
    if (checked) activeChannels.add(channel);
    else activeChannels.delete(channel);
    setActiveChannels(new Set(activeChannels))
    form.controls.channels.patch(Array.from(activeChannels))
  }

  return (
    <Form formGroup={form}>
      <div>
        <Input
          type={blockedNumber?.number ? 'textarea' : 'phone'}
          control={form.controls.number}
          fullWidth
          label="Number"
          labelClass="text-md font-semibold"
          placeholder="+27 11 676 5453"
        />
        <Input
          type="textarea"
          control={form.controls.description}
          fullWidth
          label="Reason"
          labelClass="text-md font-semibold"
          placeholder="Type Reason for marking this number as spam"
          minRows={3}
          containerClass="mt-4"
        />
      </div>

      <div className="text-blue-dark mt-6">
        <div className="font-semibold">
          Select channels to block this number on
        </div>
        {!formData?.channels?.length && (
          <div className="flex items-start text-xs ml-2 mt-2 !text-danger">
            Pick at least one option
          </div>
        )}
        <div className="grid grid-cols-1fr-auto md:grid-cols-2">
          {channels.map((channel, i) => (
            <React.Fragment key={i}>
              <span className="text-sm mt-2">{channel.text}</span>
              <Switch
                value={channel.value}
                checked={activeChannels.has(channel.value)}
                onChange={e => changeChannelActivation(e.target.value, e.target.checked)}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Form>
  );
};

export default BlockedNumberForm;
