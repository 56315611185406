import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WAOnboardingStep1: FC<IIconProps> = (props) => (
  <Icon baseWidth={22.139} baseHeight={22.139} {...props}>
    {(width, height, color = "#307cd8") => (
      <svg width={width} height={height} viewBox="0 0 22.139 22.139">
        <path
          id="Icon_ionic-md-call"
          data-name="Icon ionic-md-call"
          d="M25.409,19.874a14.436,14.436,0,0,1-4.366-.676,1.15,1.15,0,0,0-1.23.307l-2.706,2.706a18.5,18.5,0,0,1-8.118-8.118L11.7,11.388A1.282,1.282,0,0,0,12,10.158a13.376,13.376,0,0,1-.738-4.428,1.234,1.234,0,0,0-1.23-1.23H5.73A1.234,1.234,0,0,0,4.5,5.73,20.892,20.892,0,0,0,25.409,26.639a1.234,1.234,0,0,0,1.23-1.23V21.1A1.234,1.234,0,0,0,25.409,19.874Z"
          transform="translate(-4.5 -4.5)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
