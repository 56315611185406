export enum CAMPAIGN_TYPES {
  DRAFT = "DRAFT",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
}

export enum CAMPAIGN_STATUS {
  DRAFT = "DRAFT",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
}

export enum CAMPAIGN_MESSAGE_STATUS {
  FAILED = "FAILED",
  DELIVERED = "DELIVERED",
  READ = "READ",
}

export const CAMPAIGN_MESSAGE_FILTERS = [
  {
    value: "FAILED",
    text: 'Failed',
  },
  {
    value: "READ",
    text: 'Read',
  },
  {
    value: "DELIVERED",
    text: 'Delivered',
  },
];

export enum CAMPAIGN_MESSAGE_DIRECTION {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum CAMPAIGN_CHANNEL {
  SMS = 'SMS',
  WHATSAPP = 'WhatsApp',
}

export enum TEMPLATE_FILE_TYPES {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  LOCATION = "LOCATION",
}