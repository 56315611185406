import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppDeliveredIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={27.742} baseHeight={27.734} {...props}>
    {(width, height, color = '#79d7ee') => (
      <svg width={width} height={height} viewBox="0 0 27.742 27.734">
        <defs>
          <clipPath id="clip-path">
            <rect width="27.742" height="27.734" fill={color} />
          </clipPath>
        </defs>
        <g clipPath="url(#clip-path)">
          <path
            d="M11.891,7.924A8.158,8.158,0,0,1,12.542,4.8a7.923,7.923,0,1,1,13.415,8.147.91.91,0,0,0-.184.52q-.015,6.529-.008,13.058c0,.849-.358,1.211-1.2,1.211H1.208c-.823,0-1.193-.367-1.193-1.183C.015,20.826.033,15.1,0,9.377-.007,8.262.357,7.9,1.461,7.909c3.341.041,6.683.015,10.024.015Zm-9.872,3.14V25.728H23.764V14.821a.733.733,0,0,0-.1.034,7.987,7.987,0,0,1-5.233.879.661.661,0,0,0-.458.153c-1.433,1.179-2.855,2.372-4.282,3.558a1.038,1.038,0,0,1-1.59,0L2.282,11.269c-.076-.063-.156-.122-.263-.2m17.825,2.8a5.941,5.941,0,1,0-5.962-5.988,5.95,5.95,0,0,0,5.962,5.988M3.759,9.919l9.133,7.611L16.045,14.9a8.018,8.018,0,0,1-3.9-4.978Z"
            transform="translate(0.001 0)"
            fill={color} />
          <path
            d="M130.937,43.451c1.088-1.091,2.136-2.155,3.2-3.2a1.4,1.4,0,0,1,.659-.354.917.917,0,0,1,.994.52.967.967,0,0,1-.158,1.118c-.259.279-.536.542-.8.812q-1.54,1.541-3.081,3.082a1.008,1.008,0,0,1-1.611.007q-.9-.895-1.792-1.793a.994.994,0,1,1,1.388-1.41c.394.391.782.789,1.2,1.214"
            transform="translate(-112.106 -34.935)"
            fill={color} />
        </g>
      </svg>
    )}
  </Icon>
);
