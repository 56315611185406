import moment from "moment";
import {SMS_LOG_ACTION} from "../action-types";
import {IDataTableRef} from "../../components";
import {SMS_CONTEXT, SMS_STATUS, SMS_TAB} from "../../utils/enums";
import {FilterModel} from "../../utils/types";
import {SmsRecordModel} from "../../utils/types";

export interface ISmsLogState {
  tab: SMS_TAB;
  filters: FilterModel[];
  logs: SmsRecordModel[];
  selectedLogs: SmsRecordModel[];
  tableRef?: IDataTableRef;
}

const initialState: ISmsLogState = {
  tab: SMS_TAB.RECEIVED,
  filters: [],
  logs: [],
  selectedLogs: [],
};

const smsLogReducer = (state: ISmsLogState = initialState, action: any) => {
  switch (action.type) {
    case SMS_LOG_ACTION.SET_TAB: {
      let newFilter;
      let filters = [...state.filters];
      if (action.tab === SMS_TAB.SENT) {
        newFilter = [
          {
            category: 'context',
            text: 'Message Sent',
            value: SMS_CONTEXT.SEND_SMS,
            locked: true,
          },
        ];
      } else if (action.tab === SMS_TAB.RECEIVED) {
        newFilter = [
          {
            category: 'context',
            text: 'Message Received',
            value: SMS_CONTEXT.CALL_TRACKING,
            locked: true,
          }
        ];
        filters = filters.filter((item) => item.category !== 'status');
      } else if (action.tab === SMS_TAB.BLOCKED) {
        newFilter = [
          {
            category: 'status',
            text: 'Message Blocked',
            value: SMS_STATUS.BLOCKED,
            locked: true,
          }
        ];
        filters = filters.filter((item) => item.category !== 'status');
      } else {
        return state;
      }

      if (!filters.length) {
        filters = [
          ...newFilter,
          {
            category: 'range',
            text: 'Last 7 Days',
            value: {
              startDate: moment().subtract(7, 'days').startOf('day').toDate(),
              endDate: moment().endOf('day').toDate(),
            },
          },
        ];
      } else {
        filters = filters.filter((item) => !item.locked);
        filters.unshift(...newFilter);
      }

      return {
        ...state,
        filters,
        tab: action.tab,
      };
    }

    case SMS_LOG_ACTION.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case SMS_LOG_ACTION.SET_LOGS:
      return {
        ...state,
        logs: action.logs,
      };

    case SMS_LOG_ACTION.SET_SELECTED_LOGS:
      return {
        ...state,
        selectedLogs: action.selectedLogs,
      };

    case SMS_LOG_ACTION.SET_TABLE_REF:
      return {
        ...state,
        tableRef: action.tableRef,
      };

    default:
      return state;
  }
};

export default smsLogReducer;
