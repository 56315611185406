import React, {FC, useEffect, useState} from "react";
import {Button, Chip, Chips, Dropdown, TextField} from "../../../components";
import {CloseCircleIcon} from "../../../components/icons";
import {FilterModel} from "../../../utils/types";

export interface IDurationOption {
  text: string;
  value: string;
  min?: number;
  max?: number;
}

interface IDurationFilterProps {
  title?: string;
  category?: string;
  opened?: boolean;
  value: FilterModel,
  options: IDurationOption[];
  unit?: string;
  placeholder?: string;
  selectOptionLabel?: string;
  onChange?: (value: FilterModel) => void;
  onDelete?: (value: FilterModel) => void;
}

export const DurationFilter: FC<IDurationFilterProps> = ({
  category = 'duration',
  opened,
  value,
  options,
  unit = 'second',
  placeholder = 'Add Duration',
  selectOptionLabel = 'Or select a duration',
  onChange = () => {},
  onDelete = () => {},
}) => {
  const [min, setMin] = useState<string>('');
  const [max, setMax] = useState<string>('');
  const [dropdownOpened, setDropdownOpened] = useState(opened);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  useEffect(() => {
    const values = (value.value || '').split('~');
    setMin(values[0]);
    setMax(values[1]);
  }, [value]);

  const onSelectOption = (option) => {
    setMin((option.min || 0).toString());
    setMax((option.max || '').toString());
  };

  const onSave = () => {
    if (!min && !max)
      return;

    const timeUnit = unit.substr(0, 1);
    const value = `${min || ''}~${max || ''}`;
    let text = `${min}${timeUnit} ~ ${max}${timeUnit}`;
    const option = options.find((item) => item.value === value);
    if (option) {
      text = option.text;
    } else if (!min || min === '0')
      text = `Less than ${max}${timeUnit}`;
    else if (!max)
      text = `Longer than ${min}${timeUnit}`;

    onChange({
      category,
      text,
      value,
    });
    setDropdownOpened(false);
  };

  return (
    <div className="flex items-center mb-2">
      <Dropdown
        text={value?.text || placeholder}
        buttonProps={{
          className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4 shadow-md',
          color: 'primary',
        }}
        dropdownClass="w-80 rounded-bl-md rounded-br-md px-6 pt-5 pb-3 max-h-screen"
        dropdownOpened={dropdownOpened}
        onDropdownOpened={() => setDropdownOpened(true)}
        onDropdownClosed={() => setDropdownOpened(false)}
      >
        <div className="grid grid-cols-2 gap-8">
          <TextField
            size="sm"
            fullWidth
            label={`Min (in ${unit}s)`}
            value={min}
            min={0}
            type="number"
            placeholder="eg. 15"
            onChange={setMin}
          />
          <TextField
            size="sm"
            fullWidth
            label={`Max (in ${unit}s)`}
            value={max}
            min={0}
            type="number"
            placeholder="eg. 75"
            onChange={setMax}
          />
        </div>
        <div className="text-xs mt-4 mb-1">{selectOptionLabel}</div>
        <Chips>
          {options.map((item, i) => (
            <Chip
              key={i}
              active={(item.min || 0) === (+min || 0) && (item.max || 0) === (+max || 0)}
              onClick={() => onSelectOption(item)}
            >
              {item.text}
            </Chip>
          ))}
        </Chips>

        <Button
          color="primary"
          className="shadow-md rounded-full text-sm px-6 ml-auto mt-1 -mr-3"
          disabled={(!min && !max) || (min && max && +max < +min)}
          onClick={onSave}
        >
          Save
        </Button>
      </Dropdown>

      <CloseCircleIcon
        className="ml-1 cursor-pointer"
        color="#DEDEDE"
        size={24}
        onClick={() => onDelete(value)}
      />
    </div>
  );
};
