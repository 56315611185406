import {COMMON_ACTION} from '../action-types';
import {ICountry} from "../../utils/interfaces";

export interface ICommonState {
  countries: ICountry[];
}

const initialState: ICommonState = {
  countries: [],
};

const commonReducer = (state: ICommonState = initialState, action: any) => {
  switch (action.type) {
    case COMMON_ACTION.SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };

    default:
      return state;
  }
};

export default commonReducer;
