import React, {FC} from "react";
import {Icon, IIconProps} from "../icons";

export const EmailSentSvg: FC<IIconProps> = (props) => (
  <Icon baseWidth={194.614} baseHeight={143.961} {...props}>
    {(width, height, color) => (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}
           viewBox="0 0 194.614 143.961">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_2748" width="194.614" height="143.961" fill="none"/>
          </clipPath>
          <clipPath id="clip-path-4">
            <rect id="Rectangle_2746" width="30.945" height="31.247" fill="none"/>
          </clipPath>
        </defs>
        <g id="Group_4235" clipPath="url(#clip-path)">
          <g id="Group_4229" transform="translate(0 0)">
            <g id="Group_4228" clipPath="url(#clip-path)">
              <path id="Path_1796"
                    d="M101.184,607.169c-5.62.015-11.24.138-16.851.706-2.115.214-4.186.685-6.307.875-4.235.38-8.394,1.088-12.644,1.414-3.422.263-6.859.418-10.271.752a39.679,39.679,0,0,1-9.916-.276c-2.35-.383-4.711-.6-7.022-1.213a31.318,31.318,0,0,0-8.087-1.161c-3.841.025-7.581.028-11.256,1.242a11.375,11.375,0,0,0-3.85,2.021c-2.243,2.047-2.546,7.155-.307,9.278,3.772,3.573,11.822,4.975,17.11,5.041,5.794.073,11.422-.319,17.224-.549,5.392-.214,10.77-.518,16.17-.508,11.03.022,22.061,0,33.071-.671,10.344-.63,20.714-.414,31.081-.414,10.63,0,21.189.076,31.743,1.362,4.6.56,9.283.756,13.9,1.149a73.98,73.98,0,0,0,12.239-.332,31.505,31.505,0,0,0,10.045-2.45c2.489-1.083,6.927-4.447,7.691-7.031,2.14-7.246-30.162-7.072-35.121-7.055-5.548.018-11.14.184-16.685-.008a42.951,42.951,0,0,1-6.906-.95,59.59,59.59,0,0,0-6.85-.528c-9.587-.794-19.2-.678-28.813-.678-3.13,0-6.261-.025-9.392-.016"
                    transform="translate(-10.439 -482.403)" fill="#f7f7f7"/>
            </g>
          </g>
          <path id="Path_1797" d="M854.161,464.26l-.936,17.4-1.746,23.491-2.613.186L845.691,484,841.3,465.948Z"
                transform="translate(-668.424 -368.86)" fill="#bcbec0"/>
          <path id="Path_1798" d="M878.186,663.288l2.926,1.212,1.312-1.513-1.513-1.312-2.826.2Z"
                transform="translate(-697.65 -525.709)" fill="#305399"/>
          <path id="Path_1799" d="M804.206,453.766l-.573,10.443,1.7,31.052,2.616.131,3.921-20.366,3.458-22.334Z"
                transform="translate(-638.496 -359.67)" fill="#bcbec0"/>
          <path id="Path_1800" d="M807.894,659.224l-2.9,1.273-1.344-.873,1.485-1.344,2.829.141Z"
                transform="translate(-638.511 -523.013)" fill="#305399"/>
          <g id="Group_4234" transform="translate(0 0)">
            <g id="Group_4233" clipPath="url(#clip-path)">
              <path id="Path_1801"
                    d="M810.509,299.766l-8.193-.538s-4.518.672-7.152,11.576c-1.466,6.066-1.6,15.534-1.426,22.741.235,1.019,1.54,5.473,6.241,4.515,8.073-1.646,12.344-3.135,15.911-2.626,3.042.531,2.616-3.609,2.154-6.763l-1.606-9.752Z"
                    transform="translate(-630.588 -237.741)" fill="#587bc9"/>
              <path id="Path_1802"
                    d="M834.709,251.021a15.322,15.322,0,0,0-6.249-.195,1.72,1.72,0,0,0-1.153.684,1.791,1.791,0,0,0-.171.5c-.44,1.952-.981,4.841.364,6.568a3.186,3.186,0,0,0,.952.789A4.524,4.524,0,0,0,830,259.9a8.156,8.156,0,0,1-.4,1.421c.5.69,2.7.685,3.077-.012q0-.885,0-1.769a4.364,4.364,0,0,0,.751-.43,4.967,4.967,0,0,0,1.808-2.684c.344-1.159.849-2.267,1.159-3.447a1.643,1.643,0,0,0-.044-1.292,1.75,1.75,0,0,0-1.238-.6l-.394-.071"
                    transform="translate(-656.782 -199.084)" fill="#b97262"/>
              <path id="Path_1803"
                    d="M817.6,234.247a6.416,6.416,0,0,1,3.461-1.01c2.9.09,4.043,2.168,4.307,4.818.754-1.044,2.2-2.378,2.232-3.684a6.1,6.1,0,0,0-.812-3.41c-.594-.838-2.042-1.252-2.912-1.716l-2.46-1.312a1.215,1.215,0,0,0-1.21-.1l-5.616,1.7c-.36.109-.768.263-.888.62a1.193,1.193,0,0,0,.043.674,28,28,0,0,1,.852,2.951c.139.573.606,3.522,1.616,3.138a13.345,13.345,0,0,0,.513-1.647,2.354,2.354,0,0,1,.873-1.018"
                    transform="translate(-646.468 -180.925)" fill="#3b455a"/>
              <path id="Path_1804"
                    d="M781.537,299.228l-2.977,1.042-10.535,11.989-.5,3.828,5.622,8.474,1.29-3.244-2.493-6.331,3.437-2.637,4.281-10.812Z"
                    transform="translate(-609.81 -237.741)" fill="#587bc9"/>
              <path id="Path_1805"
                    d="M867.3,213.429l5.357-6.289-2-16.362,2.419-1.184,4.038,16.515-.854,4.193-5.928,8.931Z"
                    transform="translate(-689.082 -150.636)" fill="#587bc9"/>
              <path id="Path_1806" d="M881.19,176.438l-.062-1.5-1.826-2.219-1.141,1.106.86,2-.462.721.73.822Z"
                    transform="translate(-697.71 -137.225)" fill="#b97262"/>
              <path id="Path_1807" d="M176.812,272.976l2.7-1.891-.385-1.724-2.741.945-1.323.862v.956Z"
                    transform="translate(-139.094 -214.011)" fill="#efbfa5"/>
              <path id="Path_1808"
                    d="M50.928,640.138a1.075,1.075,0,0,0-.973-.417,1.1,1.1,0,0,0-.817.615,2.088,2.088,0,0,0-.14.7c-.1,1.234-.093,4.162,1.55,4.584.691.177,1.262-.926,1.446-1.546a5.036,5.036,0,0,0-1.067-3.937"
                    transform="translate(-38.901 -508.258)" fill="#305399"/>
              <path id="Path_1809"
                    d="M42.22,551.234l-.086,7.524c.1,3.342-.422,6.7-.322,10.045a8.472,8.472,0,0,1,.03,2.341c-.189.745-.795.877-1.416.5-.833-.505-.7-1.686-.837-2.547-.381-2.367-.828-4.722-1.226-7.086-.2-1.159.207-2.783.12-3.956l-1.216-6.306Z"
                    transform="translate(-29.609 -437.963)" fill="#efbfa5"/>
              <path id="Path_1810" d="M19.537,452.41l3.275,14.546.7,13.737,5.192-.117,1.412-38.424-10.066.183Z"
                    transform="translate(-15.523 -351.296)" fill="#f29308"/>
              <path id="Path_1811"
                    d="M87.352,636.008a1.056,1.056,0,0,0-.987-.36,1.122,1.122,0,0,0-.788.664,2.116,2.116,0,0,0-.11.709c-.049,1.241.085,4.171,1.742,4.5a1.244,1.244,0,0,0,1.4-1.02,6.447,6.447,0,0,0-1.258-4.49"
                    transform="translate(-67.898 -505.014)" fill="#305399"/>
              <path id="Path_1812"
                    d="M81.353,556.374l.44,7.512c.036,2.088.314,4.159.53,6.232.2,1.926-.112,3.932.9,5.684.315.549.954.915,1.4.248.344-.514.14-1.372.205-1.956.269-2.382.6-4.756.89-7.135.14-1.167-.338-2.77-.306-3.946l.917-6.355Z"
                    transform="translate(-64.636 -442.047)" fill="#efbfa5"/>
              <path id="Path_1813"
                    d="M72.441,452.6l.423,14.12L70.647,481l-5.282.158L65.6,465.7,62.8,452.889l-.938-8.389,8.381-.989Z"
                    transform="translate(-49.153 -352.376)" fill="#f29308"/>
              <path id="Path_1814"
                    d="M25.913,302.047l-9.029-.216-5.235,9.736,1.861,9.119.331,10.913,12.88.176,4.238-.688-1.125-11.66.589-14.229Z"
                    transform="translate(-9.256 -239.809)" fill="#bcbec0"/>
              <path id="Path_1815" d="M7.329,305.1,0,316.262l1.1,6.219,11.352,7.864,2.136-1.867L7,318.884l.294-6.063Z"
                    transform="translate(0 -242.405)" fill="#bcbec0"/>
              <path id="Path_1816"
                    d="M78.4,291.773,87.08,294.9l5.02-1.236,8.675-11.383-3.021-1.316-9.112,7.824L80.4,286.321Z"
                    transform="translate(-62.293 -223.232)" fill="#bcbec0"/>
              <path id="Path_1817" d="M50.124,301.995l2.8,3.959,1.317-.108,2.195-4.015Z"
                    transform="translate(-39.824 -239.809)" fill="#7db5db"/>
              <path id="Path_1818"
                    d="M60.735,416.411l-.049,1.523,2.688.979,1.549-1.174v-1.773l-1.5-.083-.047-.39-.49-.315Z"
                    transform="translate(-48.216 -329.864)" fill="#efbfa5"/>
              <path id="Path_1819"
                    d="M59.572,257.56a2.833,2.833,0,0,1,2-.616c.353,0,.471.089.653.057a.9.9,0,0,0,.311-.138,2,2,0,0,0,.631-.957A3.134,3.134,0,0,1,64.5,258.34a9.082,9.082,0,0,1-.3,4,4.947,4.947,0,0,1-2.649,3.029c-.073.028-.144.053-.216.076a3.191,3.191,0,0,1-1.507.135l-1.566-.747q-.117-.1-.227-.209c-.515-2.229.043-5.751,1.534-7.065"
                    transform="translate(-45.964 -203.321)" fill="#efbfa5"/>
              <path id="Path_1820"
                    d="M2.717,273.264a3.523,3.523,0,0,0,.971,1.837q.059-.36.123-.715-.064.354-.123.715a4.431,4.431,0,0,0,1.095.826,7.122,7.122,0,0,0,3.378.719,12.886,12.886,0,0,1,2.785.092,2.586,2.586,0,0,1,2.04,1.694c.127.5.085,1.123.516,1.4a1.329,1.329,0,0,0,.7.15,2.61,2.61,0,0,0,2.068-.545c.766-.846.319-2.542,1.353-3.022a3.765,3.765,0,0,1,.983-.175c1.4-.237,2.084-1.82,1.95-3.274a3.574,3.574,0,0,0-.189-.874c-.578-1.613-1.918-2.811-2.933-4.192-1.982-2.7-3.321-6.3-2.1-9.49a8.679,8.679,0,0,0,.323-.912,2.252,2.252,0,0,0,.077-.52c.018-.952-.69-1.735-1.148-2.57q-.064-.117-.121-.246a4.457,4.457,0,0,1-.233-.7c-.009-.038-.019-.076-.028-.114a5.54,5.54,0,0,1-1.044-5.952q.079-.166.167-.324-.088.158-.167.324a5.54,5.54,0,0,0,1.044,5.952c-.515-2.229.043-5.751,1.533-7.065a2.833,2.833,0,0,1,2-.616c.353,0,.471.089.653.057a.9.9,0,0,0,.311-.137,2,2,0,0,0,.631-.957,3.135,3.135,0,0,1,1.335,2.434,9.089,9.089,0,0,1-.3,4,4.948,4.948,0,0,1-2.65,3.029c-.073.028-.144.053-.216.076a1.761,1.761,0,0,0,.241.335,4.365,4.365,0,0,0,2.776,1.052.745.745,0,0,0,.364-.014.522.522,0,0,0,.286-.493,2.115,2.115,0,0,0-.127-.59c-.583-2.03.45-4.349.622-6.4a6.491,6.491,0,0,0-1.889-5.647,4.392,4.392,0,0,0-.806.31c-.026-.034-.052-.068-.079-.1-2.373-2.85-5.724-.413-7.527,1.763a15.761,15.761,0,0,0-3.293,6.13,24.558,24.558,0,0,0-.484,7.434,36.406,36.406,0,0,0,.9,3.762,5.525,5.525,0,0,1,.074,2.325c-.344,1.619-1.625,2.806-2.876,3.93q-.171.438-.34.889T5,269.78q-.108.3-.208.607.1-.309.208-.607.167-.464.336-.914t.34-.889c-.173.156-.345.31-.515.464-1.393,1.268-2.764,2.966-2.448,4.823M15.2,245.055a5.61,5.61,0,0,1,.567-.329,5.61,5.61,0,0,0-.567.329"
                    transform="translate(-2.122 -191.747)" fill="#2f3849"/>
              <rect id="Rectangle_2742" width="38.074" height="29.719" transform="translate(141.37 2.628)"
                    fill="#f0c586"/>
              <path id="Path_1821"
                    d="M679.424,88.467l.18,25.043,36.454-.262a5.173,5.173,0,0,0,4.954-5.372l-.142-19.707-20.63,13.07Z"
                    transform="translate(-539.811 -70.052)" fill="#f6a734"/>
              <rect id="Rectangle_2743" width="28.184" height="0.607" transform="translate(148.259 8.158)"
                    fill="#f7f7f7"/>
              <rect id="Rectangle_2744" width="32.072" height="0.63" transform="translate(144.371 12.889)"
                    fill="#f7f7f7"/>
              <rect id="Rectangle_2745" width="32.072" height="0.63" transform="translate(144.371 17.63)"
                    fill="#f7f7f7"/>
              <path id="Path_1822" d="M164.133,36.709l-5.251,10.941-.014-15.481L210.391,0Z"
                    transform="translate(-126.223 -0.001)" fill="#305399"/>
              <g id="Group_4232" transform="translate(32.658 16.231)" opacity="0.13">
                <g id="Group_4231">
                  <g id="Group_4230" clipPath="url(#clip-path-4)">
                    <path id="Path_1823" d="M158.932,95.044l30.945-16.055L164.036,99.774l-5.063,10.462Z"
                          transform="translate(-158.932 -78.989)" fill="#6d6e6f"/>
                  </g>
                </g>
              </g>
              <path id="Path_1824" d="M158.936,48.267l5.092-10.773L210.33.776Z" transform="translate(-126.277 -0.617)"
                    fill="#3b455a"/>
              <path id="Path_1825" d="M407.163.16,407.277,0l-.6.465Z" transform="translate(-323.109 0)"
                    fill="#707f78"/>
              <path id="Path_1826" d="M229.865.776l-.487.305-45.8,36.344-.053.032,14.762,13.259Z"
                    transform="translate(-145.811 -0.617)" fill="#7db5db"/>
              <path id="Path_1827" d="M161.4,0,97.214,20.911l12.6,11.313L160.8.465Z" transform="translate(-77.238 0)"
                    fill="#7db5db"/>
              <path id="Path_1828" d="M284.142,648.76l-.206,1.6,3,1.4,1.811-.786-1.4-1.811-3-2.017Z"
                    transform="translate(-225.591 -514.173)" fill="#305399"/>
              <path id="Path_1829"
                    d="M282.626,463.956l2.316-20.465-2.787-22.911.627-4.874,12.446-.473.449,7.932-2.089,20.357L285.727,465Z"
                    transform="translate(-224.176 -329.908)" fill="#bcbec0"/>
              <path id="Path_1830" d="M351.46,305.037l6.26,1.705,7.241,12.829-3.43,3.176-4.827-5.716Z"
                    transform="translate(-279.239 -242.356)" fill="#7db5db"/>
              <path id="Path_1831"
                    d="M341.459,418.717l.033,20.652-4.157,26.586-2.992-.03L332.7,441.339l-3.849-24.608Z"
                    transform="translate(-261.274 -331.099)" fill="#bcbec0"/>
              <path id="Path_1832" d="M355.608,656.02l3.22,1.038,1.634-.989-1.6-1.634-3.236-.032Z"
                    transform="translate(-282.535 -519.931)" fill="#305399"/>
              <path id="Path_1833"
                    d="M275.811,336.469c.029-.895.078-1.642.085-1.909.064-2.4.278-4.8.578-7.18.269-2.136.609-7.439.975-9.562a45.362,45.362,0,0,1,2.114-7.754c1.89-4.043,1.306-4.417,9.608-5.617,3.638-.526,4.5,1.741,7.473,4.349.293.257.277,1.788.345,2.194.12.714.248,1.427.4,2.136q.174.836.387,1.666h0s2.5,11.364,4.149,13.777,5.063,5.787-.644,7.848a20.41,20.41,0,0,1-3.082.8s-4.667-.519-7.067-.5a40.792,40.792,0,0,0-6.6.615,14.215,14.215,0,0,0-4.236,1.275c-1.24.648-2.135,1.6-3.394,2.272a.656.656,0,0,1-.385.111c-.723-.1-.757-2.64-.7-4.523"
                    transform="translate(-219.119 -241.827)" fill="#7db5db"/>
              <path id="Path_1834"
                    d="M323.734,271.8a3.414,3.414,0,0,1-2.439-.123.539.539,0,0,1-.345-.5,14,14,0,0,1,.151-1.872c-.773-.272-1.667-.68-1.8-1.489a2.315,2.315,0,0,1,.046-.77l.545-3.156a2.821,2.821,0,0,1,.328-1.05,2.369,2.369,0,0,1,1.66-.9,5.764,5.764,0,0,1,4.667,1.137,2.528,2.528,0,0,1,.788,1.04,3.424,3.424,0,0,1-.115,2l-.477,1.817c-.183.7-.447,1.489-1.118,1.75-.357.139-.777.1-1.1.3-.569.362-.492,1.208-.788,1.814"
                    transform="translate(-253.673 -208.041)" fill="#b97262"/>
              <path id="Path_1835"
                    d="M319.961,240.97a6.829,6.829,0,0,1,.525-.754,13.315,13.315,0,0,0,1.269-1.528,1.723,1.723,0,0,0,.051-1.873,2.056,2.056,0,0,0-1.093-.63,7.947,7.947,0,0,0-4.179-.077c-1.3.331-2.467.88-3.777.391a4.413,4.413,0,0,0-3.407.187c-1.392.5-3.262.614-4.522,1.316a3.719,3.719,0,0,0-1.274,1.256,5.6,5.6,0,0,0-.015,5.925,7.991,7.991,0,0,0,.872,1.1,6,6,0,0,0,2.346,1.856c.022-.808-.715-3.357-.094-4.02.985-1.051,3.213-.685,4.435-.426,1.333.282,2.522,1.227,3.843,1.428a4.984,4.984,0,0,0,2.974-.813c1-.607,1.463-2.377,2.047-3.336"
                    transform="translate(-240.498 -187.398)" fill="#3b455a"/>
              <path id="Path_1836" d="M264.994,312.305l-5.18,4.539-5.778,12.143,7.3,8.683,1.256-4.856Z"
                    transform="translate(-201.834 -248.131)" fill="#7db5db"/>
              <path id="Path_1837" d="M232.544,334.42l-64.03,4.82,33.518,17.55Z"
                    transform="translate(-133.887 -265.701)" fill="#305399"/>
              <path id="Path_1838"
                    d="M158.745,345.177l3.1,41.234,60.022-4.519a8.541,8.541,0,0,0,7.559-9.4l-2.443-32.448-32.52,23.842Z"
                    transform="translate(-126.125 -270.166)" fill="#305399"/>
              <path id="Path_1839"
                    d="M424.081,335.285a11.123,11.123,0,0,0,0,1.677,10.991,10.991,0,0,0,1.5,4.782l9.989-7.324Z"
                    transform="translate(-336.912 -265.701)" fill="#292949"/>
              <path id="Path_1840"
                    d="M446.963,351.6a11.05,11.05,0,0,0,2.692-.543l-.83-11.018-10.868,7.968a11.015,11.015,0,0,0,9,3.594"
                    transform="translate(-347.963 -270.166)" fill="#292949"/>
              <path id="Path_1841"
                    d="M436.807,293.274a11.034,11.034,0,1,1,11.831,10.175,11.034,11.034,0,0,1-11.831-10.175"
                    transform="translate(-347.023 -223.585)" fill="#f6a734"/>
              <path id="Path_1842" d="M482.857,321.59l-2.584.154-.507-8.505-1.615.1L478,310.751l4.2-.25Z"
                    transform="translate(-379.775 -246.697)" fill="#fff"/>
              <path id="Path_1843"
                    d="M674.84,647.882a1.073,1.073,0,0,1,.971-.416,1.1,1.1,0,0,1,.815.614,2.082,2.082,0,0,1,.14.7c-.03-.369.225,4.119-1.547,4.574a1.22,1.22,0,0,1-1.443-.932,6.469,6.469,0,0,1,1.064-4.539"
                    transform="translate(-535.255 -514.411)" fill="#305399"/>
              <path id="Path_1844"
                    d="M676.446,559.175l.086,7.507c-.1,3.335.421,6.688.321,10.023a8.459,8.459,0,0,0-.03,2.336c.189.743.793.875,1.413.5.831-.5.7-1.682.835-2.542.38-2.361.826-4.712,1.223-7.07.195-1.156-.207-2.777-.119-3.947l1.213-6.292Z"
                    transform="translate(-537.445 -444.272)" fill="#efbfa5"/>
              <path id="Path_1845" d="M677.393,460.571l-3.267,14.514-.694,13.707-5.181-.116-1.409-38.339,10.043.182Z"
                    transform="translate(-529.814 -357.798)" fill="#305399"/>
              <path id="Path_1846"
                    d="M638.15,646.736a1.052,1.052,0,0,1,.985-.359,1.12,1.12,0,0,1,.786.663,2.127,2.127,0,0,1,.11.708c-.015-.383-.022,4.149-1.738,4.486a1.24,1.24,0,0,1-1.4-1.017,6.431,6.431,0,0,1,1.255-4.48"
                    transform="translate(-505.977 -513.538)" fill="#305399"/>
              <path id="Path_1847"
                    d="M637.328,564.3l-.44,7.5c-.036,2.083-.314,4.15-.529,6.218-.2,1.922.111,3.923-.893,5.671-.315.548-.951.913-1.4.247-.343-.513-.139-1.369-.2-1.951-.269-2.377-.6-4.745-.888-7.119-.14-1.164.337-2.764.305-3.937l-.915-6.341Z"
                    transform="translate(-502.424 -448.347)" fill="#efbfa5"/>
              <path id="Path_1848"
                    d="M622.973,460.76l-.422,14.089,2.211,14.247,5.27.158-.233-15.42,2.789-12.787.936-8.37-8.362-.986Z"
                    transform="translate(-494.625 -358.875)" fill="#305399"/>
              <path id="Path_1849"
                    d="M637.332,310.54l9.009-.215,5.224,9.715-1.857,9.1-.331,10.889-12.851.175-4.229-.687,1.123-11.634-.644-15.542Z"
                    transform="translate(-502.369 -246.557)" fill="#d3e5ef"/>
              <path id="Path_1850"
                    d="M674.085,313.585l7.313,11.139-1.1,6.205-11.326,7.846-2.132-1.863,7.572-9.572-.294-6.049Z"
                    transform="translate(-529.814 -249.148)" fill="#d3e5ef"/>
              <path id="Path_1851"
                    d="M568.153,324.217,563.46,333.6,558.4,334.6l-12.607-5.5,2.174-2.466,11.5,2.1,4.583-8.606Z"
                    transform="translate(-433.64 -254.348)" fill="#d3e5ef"/>
              <path id="Path_1852"
                    d="M657.463,265.537c1.358-3.949,6.571-2.469,7.646,1.047.7,2.293-1.07,5.27-3.447,5.928a2.3,2.3,0,0,0-.018.246,7.74,7.74,0,0,1-.1,1.1,11.969,11.969,0,0,1-2.332-.308.314.314,0,0,1-.215-.106c-.084-.13.052-.515.068-.66l.054-.514A4.1,4.1,0,0,1,657,269.237a7.616,7.616,0,0,1,.465-3.7"
                    transform="translate(-521.936 -209.102)" fill="#efbfa5"/>
              <path id="Path_1853"
                    d="M639.888,255.111a2.069,2.069,0,0,1,.47,1.06,1.655,1.655,0,0,1,.041.687c-.194.9-1.083.849-1.77,1.138a4.587,4.587,0,0,0-2.054,1.768c-.743,1.183-.409,2.705-.107,4.01.106.46.32,1.144-.037,1.554a1.53,1.53,0,0,1-1.634.138c-.4-.2-.722-.558-1.158-.631-.461-.077-1.084.125-1.3-.3a2.84,2.84,0,0,0-.141-.369c-.255-.364-.888.062-1.247-.194a.7.7,0,0,1-.144-.761,1.811,1.811,0,0,0,.148-.807,2.657,2.657,0,0,0-.316-.629,1.239,1.239,0,0,1,.171-1.317c.568-.657,1.666-.532,2.274-1.15a2.675,2.675,0,0,0,.558-1.225,2.35,2.35,0,0,1,.6-1.2c.418-.368,1.022-.305,1.435-.741a8.469,8.469,0,0,1,.676-.813,5.459,5.459,0,0,1,1.506-.67,1.959,1.959,0,0,1,2.028.451"
                    transform="translate(-500.959 -202.244)" fill="#2f3849"/>
              <path id="Path_1854"
                    d="M670.569,252.3c.024,1.3-.621,2.7-.023,3.849s2.11,1.363,3.394,1.423c.211.01,2.92.244,2.932.192a8.82,8.82,0,0,1,.886-2.623,2.888,2.888,0,0,1,2.17-1.51.557.557,0,0,1-.363-.635,4.285,4.285,0,0,1,.251-.772,1.819,1.819,0,0,0-.475-1.828c-.958-.893-2.7-.522-3.517-1.548-.509-.636-.449-1.559-.716-2.335-.516-1.5-2.16-1.816-3.389-2.494a5.886,5.886,0,0,0-4.016-.859,3.051,3.051,0,0,0-2.52,3.977c.918,2.281,4.589,2.084,5.276,4.4a2.864,2.864,0,0,1,.11.764"
                    transform="translate(-528.344 -193.137)" fill="#2f3849"/>
              <path id="Path_1855" d="M663.317,310.488l-2.795,3.95-1.314-.107-2.19-4.006Z"
                    transform="translate(-522.01 -246.557)" fill="#3b455a"/>
              <path id="Path_1856"
                    d="M534.773,346.229a.791.791,0,0,1-.572-.237l-6.365-6.525-3.954,5.061a.7.7,0,0,1-.563.279.922.922,0,0,1-.823-.582l-9.374-25.235a.722.722,0,0,1,.177-.811.694.694,0,0,1,.552-.275.81.81,0,0,1,.3.06l24.994,10a.855.855,0,0,1,.553.679.845.845,0,0,1-.357.787l-5.067,3.76,6.352,6.511a.83.83,0,0,1,0,1.158l-5.266,5.137a.843.843,0,0,1-.585.236m-7.021-8.819a.847.847,0,0,1,.6.239l6.445,6.607,4.093-3.993-6.433-6.594a.8.8,0,0,1-.233-.625.9.9,0,0,1,.37-.64l4.647-3.449-21.929-8.769,8.224,22.141,3.631-4.648a.733.733,0,0,1,.587-.269"
                    transform="translate(-407.64 -252.58)" fill="#fff"/>
              <path id="Path_1857"
                    d="M539.657,338.291l-3.075-3.152-3.031-3.107,2.109-1.565,2.675-1.985a1.133,1.133,0,0,0-.265-2l-8.5-3.4-13.426-5.369-1.826-.73-1.245-.5a1.111,1.111,0,0,0-.414-.082,1,1,0,0,0-.779.374,1.033,1.033,0,0,0-.239,1.127l.338.91,2.849,7.668,5.035,13.555.582,1.566.57,1.535a1.227,1.227,0,0,0,1.111.783,1.006,1.006,0,0,0,.806-.4l2.013-2.576,1.724-2.207,2.383,2.443,2.875,2.947.861.883a1.1,1.1,0,0,0,.793.33,1.155,1.155,0,0,0,.8-.324l2.592-2.529,2.674-2.608a1.128,1.128,0,0,0,.007-1.593m-.438,1.152-5.265,5.137a.533.533,0,0,1-.37.149.487.487,0,0,1-.352-.144l-.861-.882-5.75-5.9-4.171,5.339a.4.4,0,0,1-.321.161.61.61,0,0,1-.534-.381l-1.152-3.1-5.035-13.555-3.186-8.578a.418.418,0,0,1,.117-.494.388.388,0,0,1,.323-.176.5.5,0,0,1,.185.038l3.072,1.228,13.426,5.369,8.5,3.4a.519.519,0,0,1,.126.932l-5.35,3.971.449.46,6.149,6.3a.519.519,0,0,1,0,.722"
                    transform="translate(-406.45 -251.388)" fill="#fff"/>
              <path id="Path_1858"
                    d="M539.291,340.165a.519.519,0,0,1,.1-.8l5.09-3.777-2.174-.869-13.426-5.369-7.487-2.994.767,2.066,5.035,13.555,2.856,7.688,3.97-5.082a.428.428,0,0,1,.345-.151.539.539,0,0,1,.377.146l.861.883,5.8,5.945,4.534-4.424-.5-.511-6.149-6.3m2.124,10.367-1.28-1.313-4.089-4.191-.131-.134-.73-.748a1.144,1.144,0,0,0-.818-.332,1.04,1.04,0,0,0-.83.388l-1.985,2.541-1.306,1.672-.989-2.661-1.483-3.993L522.738,328.2l-.284-.763,6.2,2.478,6.713,2.685,6.713,2.684,1.113.445.036.014-1.615,1.2-2.589,1.922a1.2,1.2,0,0,0-.493.863,1.1,1.1,0,0,0,.32.864l3.075,3.152L545,346.9l.068.07Z"
                    transform="translate(-414.253 -259.292)" fill="#fff"/>
              <path id="Path_1859"
                    d="M517.13,321.99l9.374,25.235L531,341.472l7.01,7.186,2.633-2.568,2.633-2.569-7.01-7.186,5.862-4.35Z"
                    transform="translate(-410.866 -255.825)" fill="#3b455a"/>
              <path id="Path_1860"
                    d="M638.516,427.115l.049,1.52-2.682.976-1.546-1.171V426.67l1.492-.083.047-.389.489-.314Z"
                    transform="translate(-503.988 -338.37)" fill="#efbfa5"/>
              <path id="Path_1861" d="M259.139,344.8l1.517-2.026,3.079-.6,1.658,1.655-2.936,3.057Z"
                    transform="translate(-205.89 -271.866)" fill="#b97262"/>
              <path id="Path_1862" d="M378.419,330.552l1.517-2.026,3.079-.6,1.658,1.655-2.936,3.057Z"
                    transform="translate(-300.659 -260.543)" fill="#b97262"/>
            </g>
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
