import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import WhatsAppAccountsRouting from "./Accounts/routing";
import WhatsAppChatIntrosRouting from "./ChatIntros/routing";
import WhatsAppChatLogsRouting from "./ChatLogs/routing";
import WhatsAppCampaignsRouting from "./Campaigns/routing";
import WhatsAppListsRouting from "./Lists/routing";
import { WhatsAppDocumentation } from "./Documentation";

const WhatsappRouting = () => (
  <Switch>
    <Route path={ROUTES.WHATSAPP.ACCOUNTS.PREFIX} component={WhatsAppAccountsRouting} />
    <Route path={ROUTES.WHATSAPP.CHAT_INTROS.PREFIX} component={WhatsAppChatIntrosRouting} />
    <Route path={ROUTES.WHATSAPP.CHAT_LOGS.PREFIX} component={WhatsAppChatLogsRouting} />
    <Route path={ROUTES.WHATSAPP.CAMPAIGNS.PREFIX} component={WhatsAppCampaignsRouting} />
    <Route path={ROUTES.WHATSAPP.LISTS.PREFIX} component={WhatsAppListsRouting} />
    <Route path={ROUTES.WHATSAPP.DOCUMENTATION.PREFIX} component={WhatsAppDocumentation} />
    <Redirect to={ROUTES.WHATSAPP.ACCOUNTS.INDEX} />
  </Switch>
);

export default WhatsappRouting;
