import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { CircularProgress, Switch, Tooltip } from '@mui/material';
import {Breadcrumbs, Chip, DataTable, IDataTableColumn, IDataTableRef, NoData, TextField} from '../../../../components';
import { EditIcon, PlusIcon, SearchIcon } from '../../../../components/icons';
import GA4Create from './GA4Create';
import GA4Edit from './GA4Edit';
import { getActiveOrganization } from '../../../../redux/selectors';
import { GA4Service, ToastService } from '../../../../services';
import { GA4Model } from '../../../../utils/types';

const EmptyContent = () => {
  return (
    <NoData
      title="You have not added a Google Analytics property yet"
      text="To enable us to send specific events to Google Analytics, add your GA property"
    />
  );
};

const GA4 = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>();
  const organization = useSelector(getActiveOrganization);
  const [touched, setTouched] = useState<boolean>(false)
  const [editingGA4, setEditingGA4] = useState<GA4Model>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false)

  const onSearch = (e) => {
    e.preventDefault();

    setFilter({
      search,
    });
  };

  const onToggleDisable = useCallback((ga4: GA4Model) => {
    GA4Service.patch(ga4.name, { is_active: !ga4.is_active }).then((res) => {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> {res.is_active ? 'enabled' : 'disabled'}.</>);
      tableRef?.refresh();
    }).catch((err) => {
      ToastService.showHttpError(err, `${ga4.is_active ? 'Disabling' : 'Enabling'} ga4 failed`);
    });
  }, [tableRef]);

  const onCloseCreateModal = (ga4?: GA4Model) => {
    if (ga4) {
      tableRef?.refresh();
    }
    setShowCreateModal(false);
  };

  const onCloseEditModal = (ga4?: GA4Model) => {
    if (ga4) {
      tableRef?.refresh();
    }
    setEditingGA4(null);
  };

  const columns = useMemo<IDataTableColumn<GA4Model>[]>(() => [
    { title: 'Name', field: 'name', tooltip: { title: 'The name of your GA’s name' } },
    {
      title: 'Type',
      field: 'event',
      headerClass: 'w-48',
      render(row: any) {
        return (
          <div className="flex items-center">
            <Chip key={row.event} active containerClass="mr-2">{row.event}</Chip>
          </div>
        );
      },
    },
    { title: 'Date Created', field: 'created', headerClass: '!flex-shrink-0', cellClass: '!flex-shrink-0', tooltip: { title: 'This is when is was created' } },
    {
      cellClass: '!pl-0',
      render(row: any) {
        return (
          <div className="flex items-center cursor-pointer justify-end">
            <div className='px-10'>
              <Switch checked={row.is_active} onChange={() => onToggleDisable(row)} />
            </div>

            <Tooltip title="Edit GA4" arrow placement="left">
              <div
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md cursor-pointer"
                onClick={() => setEditingGA4(row)}
              >
                <EditIcon color="blue" size={22} />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ], [tableRef]);

  const loadGA4 = useCallback((options, callback) => {
    const query: any = {
      page: options.page + 1,
      page_size: options.perPage,
    };
    if (filter.search) {
      query.name = filter.search;
    }

    GA4Service.search(query, false).then((data) => {
      callback({
        count: data.count,
        data: data.results,
      });
    }).catch(() => {
      callback({
        count: 0,
        data: [],
      });
    });
  }, [organization, filter]);

  if (isFetchingData) {
    return (
      <div className="w-full h-full min-h-55 flex-center bg-white bg-opacity-80 pt-12">
        <CircularProgress
          sx={{
            color: '#0B1C34',
          }}
          size={40}
          thickness={4}
        />
      </div>
    )
  }


  return (
    <>
      <div className="h-full flex flex-col p-4 m-4">
        <div className='flex items-center justify-between'>
          <Breadcrumbs text={'Google analytics'} />
          <form onSubmit={onSearch}>
            <TextField
              size="sm"
              fullWidth
              className="!rounded-full !bg-white shadow-md"
              containerClass="w-80 ml-auto"
              value={search}
              icon={<SearchIcon size={16} color="primary" />}
              placeholder="Search"
              clearable
              onChange={(value) => setSearch(value)}
            />
          </form>
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          serverSide
          datasource={loadGA4}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!px-8"
          cellClass={(row) => classnames('!text-xs !px-8', { '!text-secondary': row.disabled })}
          pagination
          rowsPerPage={20}
          paginationClass="mr-16"
          onInit={setTableRef}
          showCustomEmptyComponent
          CustomEmptyComponent={EmptyContent}
        />

        <div
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-30 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setShowCreateModal(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">ADD</span>
        </div>
      </div>

      {editingGA4 && (
        <GA4Edit
          ga4={editingGA4}
          onClose={onCloseEditModal}
          setTouched={setTouched}
          touched={touched}
        />
      )}
      {showCreateModal && (
        <GA4Create
          onClose={onCloseCreateModal}
          setTouched={setTouched}
          touched={touched}
        />
      )}
    </>
  );
};

export default GA4;
