import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../../constants";
import Accounts from "./index";
import AccountViewPage from "./AccountViewPage";
import { AccountSetup } from "./AccountSetup";

const WhatsAppAccountsRouting = () => (
  <Switch>
    <Route exact path={ROUTES.WHATSAPP.ACCOUNTS.INDEX} component={Accounts} />
    <Route exact path={ROUTES.WHATSAPP.ACCOUNTS.SETUP} component={AccountSetup} />
    <Route exact path={ROUTES.WHATSAPP.ACCOUNTS.DETAILS} component={AccountViewPage} />
  </Switch>
);

export default WhatsAppAccountsRouting;