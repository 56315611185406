import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { DataTable, IDataTableColumn, Menu } from "../../../../components";
import { getActiveOrganization } from "../../../../redux/selectors";
import { CHAT_LOG_NOTIFICATION_STATUS, chatLogStatuses } from "../../../../utils/enums";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";
import { WAChatIntroLogModel } from "../../../../utils/types";
import { MoreVertIcon } from "../../../../components/icons/MoreVert";

let lastQuery: any;
const rowsPerPage = 50;
const message_status_classes = {
  [CHAT_LOG_NOTIFICATION_STATUS.READ]: 'text-green-dark !font-semibold',
  [CHAT_LOG_NOTIFICATION_STATUS.QUEUED]: 'text-blue !font-semibold',
  [CHAT_LOG_NOTIFICATION_STATUS.SENT]: 'text-blue !font-semibold',
  [CHAT_LOG_NOTIFICATION_STATUS.DELIVERED]: 'text-blue !font-semibold',
  [CHAT_LOG_NOTIFICATION_STATUS.FAILED]: 'text-danger-light !font-semibold'
};

export interface IChatLogTableProps {
  className?: string;
  filters: any[];
  onBlockSender(sender: WAChatIntroLogModel): void;
  onUnBlockSender(sender: WAChatIntroLogModel): void;
}

const ChatLogsTable: FC<IChatLogTableProps> = ({
  className,
  filters,
  onBlockSender,
  onUnBlockSender,
}) => {
  const organization = useSelector(getActiveOrganization);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chatLogs, setChatLogs] = useState<WAChatIntroLogModel[]>([]);

  useEffect(() => {
    lastQuery = {};
  }, [organization]);

  const pretty_message_status = status => {
    return (
      <span className={message_status_classes[status]}>
        {status.charAt(0).toUpperCase() + status.toLowerCase().slice(1)}
      </span>
    )
  }

  const handleRowAction = (sender: WAChatIntroLogModel, action: string) => {
    switch (action) {
      case 'unblock': {
        onUnBlockSender(sender);
        return;
      }
      case 'block': {
        onBlockSender(sender);
        return;
      }
      case 'mark-as-spam': {
        return;
      }
    }
  };

  const columns = useMemo<IDataTableColumn<WAChatIntroLogModel>[]>(() => {
    return [
      {
        title: 'Date',
        field: 'created',
        tooltip: { title: 'Date Created' },
        cellClass: '!pl-6',
        headerClass: '!pl-6',
        render(row: WAChatIntroLogModel) {
          return formatDateTime(row.created, 'DD/M/YYYY HH:mm:ss');
        },
      },
      {
        title: 'Reference ID',
        field: 'reference_id',
        tooltip: { title: 'Reference ID' },
      },
      {
        title: 'Lead WA Number',
        field: 'lead_wa_number',
        tooltip: { title: 'Lead WA Number' },
        render(row: WAChatIntroLogModel) {
          return formatPhoneNumber(row.lead_wa_number);
        },
      },
      {
        title: 'Lead WA Name',
        field: 'lead_wa_name',
        tooltip: { title: 'Lead WA Name' },
      },
      {
        title: 'Notification Type',
        field: 'notify_type',
        tooltip: { title: 'Chat Intros notification type' },
      },
      {
        title: 'Notification Status',
        field: 'notify_data.message_status',
        tooltip: { title: 'Status of the Chat Intros notification' },
        render(row: WAChatIntroLogModel) {
          if (!row.notify_message_status) return "";
          return pretty_message_status(row.notify_message_status);
        }
      },
      {
        title: 'Notification Destination',
        field: 'notified_destination',
        tooltip: { title: 'Destination of the Notification' },
        render(row: WAChatIntroLogModel) {
          return formatPhoneNumber(row.notified_destination);
        },
      },
      {
        title: 'Status',
        field: 'status',
        tooltip: { title: 'Status' },
        cellClass: '!pr-6',
        headerClass: '!pr-6',
        render(row: WAChatIntroLogModel) {
          const status = chatLogStatuses.find((item) => item.value === row.status);
          return (
            <div>
              <div className={classnames('font-semibold', status?.color)}>{status?.text || ''}</div>
              <div className="text-gray-be text-9p">{row.status}</div>
            </div>
          );
        },
      },
      {
        render(row: WAChatIntroLogModel) {
          const items = [
            ...(row.lead_wa_number ? [{ text: 'Mark lead WA number as spam', value: 'block' }] : []),
            ...(row.status === 'APARTY_BLOCKED' ? [{ text: 'Unblock lead WA number', value: 'unblock' }] : []),
          ]
          if (!items.length) return

          return (
            <Menu
              className="ml-2"
              text={<MoreVertIcon color="blue-dark" />}
              arrow={null}
              items={items}
              menuItemClass="!text-xs"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onSelectItem={(option) => handleRowAction(row, option.value)}
            />
          )
        }
      }
    ]
  }, [filters, page, organization]);

  const loadChatLogs = useCallback((query) => {
    setLoading(true)
    WAChatIntroLogModel.search(query, false).then((data) => {
      setChatLogs(data.results);
      setTotalCount(data.count);
      setLoading(false)
    }).catch(() => {
      setChatLogs([]);
      setTotalCount(0);
      setLoading(false)
    });
  }, []);

  useEffect(() => {
    const query: any = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    const usable_filters = filters.filter(x => x.value)
    if (!usable_filters.length && filters.length) return;

    usable_filters.forEach((filter) => {
      switch (filter.category) {
        case 'range':
          query.from_date = filter.value.startDate
          query.to_date = filter.value.endDate
          break;

        case 'status':
          query.status = Array.isArray(filter.value) ? filter.value.join() : filter.value
          break;

        default:
          query[filter.category] = filter.value;
      }
    });

    if (loading || JSON.stringify(lastQuery) === JSON.stringify(query)) {
      return;
    }

    lastQuery = query;
    loadChatLogs(query);
  }, [columns]);

  const refreshTable = () => {
    loadChatLogs(lastQuery);
  };

  return (
    <DataTable
      wrapperClass={className}
      columns={columns}
      serverSide={false}
      data={chatLogs}
      totalCount={totalCount}
      size="xs"
      stripped
      stickyHeader
      page={page}
      extLoading={loading}
      rowsPerPage={rowsPerPage}
      pagination
      selfPagination
      headerCellClass="!text-13p"
      cellClass="!text-11p"
      onPaginationChange={(page) => setPage(page)}
    />
  );
};

export default ChatLogsTable;
