import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../../constants";
import { Campaigns } from "./index";

const WhatsAppCampaignsRouting = () => (
    <Switch>
        <Route exact path={ROUTES.WHATSAPP.CAMPAIGNS.INDEX} component={Campaigns} />
    </Switch>
);

export default WhatsAppCampaignsRouting;
