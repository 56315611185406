import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { Button, TextField, WithTooltipIcon } from "../../../../components";
import { CaretLeftIcon, CaretRightIcon, InfoCircleIcon } from "../../../../components/icons";
import { IDateRange } from "../index";

interface ICustomDateRangeSelectDropdownProps {
  value: IDateRange;
  onChange?: (value: IDateRange) => void;
}

export const CustomDateRangeSelectDropdown: FC<ICustomDateRangeSelectDropdownProps> = ({
  value,
  onChange = () => { },
}) => {
  const [range, setRange] = useState<IDateRange>(value);

  useEffect(() => {
    setRange(value);
  }, [value]);

  const minDate = useMemo(() => {
    return moment().subtract(2, "years").toDate();
  }, []);

  const maxDate = useMemo(() => {
    return new Date();
  }, []);

  const isInvalid = useMemo(() => {
    if (range.endDate && range.startDate) {
      const diffTime = moment(range.endDate).diff(range.startDate).valueOf();
      return diffTime / 1000 / 60 / 60 / 24 > 90;
    }
    return false;
  }, [range]);

  const showPrevMonth = () => {
    document.querySelector<HTMLDivElement>('.custom-select-dropdown .rdrPprevButton').click();
  };

  const showNextMonth = () => {
    document.querySelector<HTMLDivElement>('.custom-select-dropdown .rdrNextButton').click();
  };

  const onApply = () => {
    onChange(range);
  };

  return (
    <div className="custom-select-dropdown pt-6">
      <div className="flex items-center px-8 mb-2">
        <div className="relative w-full flex-shrink-1">
          <TextField
            size="sm"
            fullWidth
            value={`${moment(range.startDate).format('D MMMM yyyy')} - ${moment(range.endDate).format('D MMMM yyyy')}`}
            readonly
            className={isInvalid ? "border-[#D83030]" : null}
          />

          {isInvalid ?
            <p className="text-danger text-[11px] pt-1">
              You can only search within a 3 month window,
              <span className="font-bold ml-1">
                for example; January - March or June - October
              </span>
            </p>
            : null}

          <WithTooltipIcon
            className="!absolute right-5 top-3"
            tooltip={<div className="w-52 text-[10px]">You can search back as far as 2 years, but you only have a search window of 3 months at a time.<br />Eg. You can search from January - March or June - October</div>}
            tooltipClassName="whitespace-pre"
            placement="left"
            icon={<InfoCircleIcon color="blue-dark" size={17} />}
          />
        </div>

        <div className="flex items-center flex-shrink-0 ml-8">
          <div className="w-6 h-6 flex-center rounded-full bg-blue cursor-pointer" onClick={showPrevMonth}>
            <CaretLeftIcon color="white" />
          </div>
          <div className="w-6 h-6 flex-center rounded-full bg-blue cursor-pointer ml-4" onClick={showNextMonth}>
            <CaretRightIcon color="white" />
          </div>
        </div>
      </div>
      <DateRangePicker
        ranges={[range]}
        months={2}
        direction="horizontal"
        monthDisplayFormat="MMMM yyyy"
        weekdayDisplayFormat="EEEEE"
        rangeColors={['#307CD8']}
        minDate={minDate}
        maxDate={maxDate}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        onChange={(e) => setRange(e.range1)}
      />

      <div className="px-3 py-2 mt-4 shadow-modal-footer shadow-[#92929241]">
        <Button
          color="primary"
          disabled={isInvalid}
          className="shadow-md rounded-full text-sm px-6 ml-auto"
          onClick={onApply}
        >
          Apply
        </Button>
      </div>
    </div >
  );
};
