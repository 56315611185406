export class ChatwootInbox {
  account_id: string;
  inbox_id: string;
  team_id: string;
  identifier: string;
}

export class WhatsappAccountModel {
  uuid: string;
  display_name: string;
  whatsapp_number: string;
  business_website: string;
  business_email: string;
  business_address: string;
  business_day_hours: any;
  fb_business_manager_id: string;
  chat_intro_count: number;
  allow_duplicate_workflows: boolean;
  chatwoot_inbox: ChatwootInbox;
  dashboard_pages: any;
  profile_picture: string;
  description: string;
  is_active: boolean;
  created: string;
  campaign_mode: boolean;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      display_name: '',
      whatsapp_number: '',
      business_website: '',
      business_email: '',
      business_address: '',
      fb_business_manager_id: '',
      chat_intro_count: 0,
      allow_duplicate_workflows: false,
      business_day_hours: {},
      dashboard_pages: {},
      chatwoot_inbox: null,
      description: '',
      profile_picture: '',
      is_active: false,
      created: '',
      campaign_mode: false,
      ...init,
    };
    Object.assign(this, data);
  }

  isNew(): boolean {
    const current = new Date();
    const comp = new Date(this.created);
    const timeDifference = current.getTime() - comp.getTime();
    const fullDiff = Math.floor(timeDifference / (1000 * 60));
    return fullDiff < 30
  }
}
