import React, {FC} from "react";
import classnames from "classnames";
import {formatPhoneNumber} from "../../utils/helpers";
import {SmsRecordModel} from "../../utils/types";

interface ISmsBalloonProps {
  className?: string;
  sms: SmsRecordModel;
}

export const SmsBalloon: FC<ISmsBalloonProps> = ({
  className = '',
  sms,
}) => {
  return (
    <div className={classnames('flex items-end', className)}>
      <div className="w-12 h-12 flex-center flex-shrink-0 bg-blue-lighter text-2xl text-blue-light font-semibold rounded-full mr-4 mb-4">S</div>
      <div className="flex-grow">
        <div className="sms-balloon bg-blue text-blue text-xs">
          <div className="max-h-72 text-white break-words overflow-auto pr-4">{sms.message}</div>
        </div>
        <div className="text-blue text-sm mt-1">
          <span className="font-semibold">Sender:</span> {formatPhoneNumber(sms.from_number)}
        </div>
      </div>
    </div>
  );
};
