import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "../../../../components";
import { Form, FormControl, Input, Validators, useForm } from "../../../../components/ReactiveForm";
import { ToastService, WACampaignsService } from "../../../../services";
import { CAMPAIGN_STATUS } from "../../../../utils/enums";

export interface ICreateCampaignFormData {
    campaignName: string;
    campaignStatus: CAMPAIGN_STATUS;
}

export interface ICreateCampaignFormInitParams {
    getFormData: () => ICreateCampaignFormData;
}


export type ICreateCampaignsForm = {
    campaignName: FormControl;
}

interface ICampaignsCreateModalProps {
    campaign?: any;
    onClose: (result?: any) => void;
    onInit?: (params: ICreateCampaignFormInitParams) => void;
    setTouched?: any;
}

export const CampaignsCreateModal: FC<ICampaignsCreateModalProps> = ({
    campaign,
    onClose,
    setTouched,
    onInit = () => { },
}) => {

    const [form] = useForm<ICreateCampaignsForm>({
        campaignName: new FormControl("", [Validators.required()]),
    });

    const [initialForm, setInitialForm] = useState(form.getFormData())

    if (setTouched) setTouched(!_.isEqual(form.getFormData(), initialForm))

    useEffect(() => {
        if (campaign) {
            form.patch({
                campaignName: campaign.name,
            });
            setInitialForm({
                campaignName: campaign.name,
            })
        }
    }, [campaign]);

    const getFormData = useCallback(() => {
        if (form.validate()) {
            const formData = form.getFormData();
            return {
                ...formData,
            };
        }
        return null;
    }, [form]);

    useEffect(() => {
        onInit({
            getFormData,
        });
    }, [onInit, getFormData]);

    const createCampaign = () => {
        const data = form.getFormData();
        if (data) {
            WACampaignsService.createCampaign({
                name: data.campaignName,
                campaign_status: CAMPAIGN_STATUS.DRAFT,
                message_data: {}
            }).then((res) => {
                ToastService.success(<><span className="text-success mr-1">Successfully</span> created a campaign.</>);
                onClose(res);
            }).catch((err) => {
                ToastService.error('Create campaign failed');
            });
        }
    };

    const modifyCampaign = () => {
        const data = form.getFormData();
        if (data) {
            WACampaignsService.modifyCampaign({
                uuid: campaign.uuid,
                name: data.campaignName,
            }).then((res) => {
                ToastService.success(<><span className="text-success mr-1">Successfully</span> updated campaign name.</>);
                onClose();
            }).catch((err) => {
                ToastService.error('Update campaign name failed');
            });
        }
    }

    return (
        <Modal
            title={"Create a WhatsApp Campaign"}
            footerComponent={(
                <>
                    <Button
                        data-cy="wa-campaigns-name-button"
                        className="text-sm rounded-full px-6 py-2 ml-auto"
                        color={"primary"}
                        onClick={() => campaign ? modifyCampaign() : createCampaign()}
                    >
                        Continue
                    </Button>
                </>
            )}
            onClose={onClose}
        >
            <Form formGroup={form}>
                <p className="mb-6">Reach out to your customers on WhatsApp. Share important news regarding updates in your marketplace.</p>
                <Input
                    control={form.controls.campaignName}
                    size="sm"
                    fullWidth
                    label="Please give your campaign a name"
                    labelClass="text-md font-semibold"
                    placeholder="This is the campaign name"
                />
            </Form>
        </Modal>
    )
}