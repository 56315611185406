export enum CREDIT_CARD {
  MASTER_CARD = 'master card',
}

export const CREDIT_CARDS = [
  {
    text: 'Master Card',
    value: CREDIT_CARD.MASTER_CARD,
  },
];
