import React, {FC} from "react";
import {Button} from "../../../../../components";
import {ArrowLeftIcon} from "../../../../../components/icons";
import {ActivateOtpModeSvg} from "../../../../../components/svgs";
import {IWhatsAppSetupFormData} from "../../index";

export interface IActivateOtpModeProps {
  formData: IWhatsAppSetupFormData;
  onBack(): void;
  onNext(): void;
}

const ActivateOtpMode: FC<IActivateOtpModeProps> = ({
  formData, onBack, onNext,
}) => {
  return (
    <div className="h-full flex flex-col text-center px-8 pt-8">
      <h6 className="font-semibold">You have selected</h6>
      <p className="text-primary text-3xl font-bold">{formData.number} to be changed to OTP Mode</p>
      <div className="max-w-154 mx-auto mt-10 mb-6">
        <ActivateOtpModeSvg size={280} className="mx-auto" />
        <p className="font-semibold mt-8">
          OTP Mode will now be activated and the numbers you have selected will no longer be able to answer any calls.
          All calls that are going to be made to this number, will be saved as a voice recording that you can access in you call logs.
        </p>
        <p className="font-semibold mt-3">
          To turn OTP Mode off, you will have to manually do this... but don't worry, we will explain how you can do this in a bit.
        </p>
      </div>
      <div className="flex mt-auto mb-6">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>
          Activate OTP Mode
        </Button>
      </div>
    </div>
  );
};

export default ActivateOtpMode;
