import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import NumberEdit, {INumberEditInitParams} from "../NumberEdit";
import {NumberModel} from "../../../utils/types";
import { ConfirmationModal } from "../../../parts";

interface INumberEditModalProps {
  number: NumberModel;
  onClose: (result?: any) => void;
}

const NumberEditModal: FC<INumberEditModalProps> = ({
  number, onClose,
}) => {
  const [editForm, setEditForm] = useState<INumberEditInitParams>({
    onSubmit: () => {},
  });

  const [touched, setTouched] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const close = (value?: any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if (touched && value === undefined) {
      setShowConfirmation(true)
      return;
    }
    if (value === undefined && !touched) {
      onClose()
    }
  }

  return (
    <Modal
      title="Numbers"
      size="full"
      contentClass="bg-gray-f6 md:pl-32 md:pr-20 lg:pl-50 lg:pr-38 lg:py-9"
      // headerActions={(
      //   <Button
      //     className="text-sm rounded-full px-6 py-2"
      //     color="primary"
      //     onClick={editForm.onSubmit}
      //   >
      //     Save
      //   </Button>
      // )}
      onClose={close}
    >
      <NumberEdit
        number={number}
        onInit={setEditForm}
        onClose={onClose}
        setTouched={setTouched}
      />
      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  );
};

export default NumberEditModal;
