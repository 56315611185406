import React, {FC, useCallback, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import {Card, WithTooltipIcon} from "../../../../components";
import {FormGroup} from "../../../../components/ReactiveForm";
import {TextFileInput} from "../../../../parts";
import {INumberAdvancedSettingsForm} from "../index";
import {AudioService} from "../../../../services";
import {NumberModel} from "../../../../utils/types";
import {AUDIO_TYPE, NUMBER_FEATURE} from "../../../../utils/enums";
import {uploadTTS} from "../../../../utils/helpers";

export interface IVoiceMailProps {
  number: NumberModel;
  form: FormGroup<INumberAdvancedSettingsForm>;
  formData: any;
  initialForm?:any
  onUpdate(data: any): void;
}

const VoiceMail: FC<IVoiceMailProps> = ({
  number,
  form,
  formData,
  initialForm,
  onUpdate,
}) => {
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      form.disable();
    };
  }, []);

  useEffect(() => {
    if (formData) {
      setEnabled(formData.voiceMail);
    }
  }, [formData]);

  const onEnable = (enabled: boolean) => {
    setEnabled(enabled);
    form.patch({
      voiceMail: enabled,
      voiceMailForm: {
        enabled,
      },
    });
    form.controls.voiceMailForm.disable(!enabled);
  };

  const onUploadFile = useCallback((file: File) => {
    return AudioService
      .upload({
        name: `${AUDIO_TYPE.VOICE_MAIL_LITE}_${number.number}_${Date.now()}`,
        type: AUDIO_TYPE.VOICE_MAIL_LITE,
        file,
      }, false)
      .then((audio) => audio.name);
  }, []);

  const onRemoveFile = useCallback((fileName) => {
    return AudioService.delete(fileName, AUDIO_TYPE.VOICE_MAIL_LITE, false);
  }, []);

  const onSave = async () => {
    if (!form.controls.voiceMailForm.validate()) {
      return;
    }

    const formData = form.controls.voiceMailForm.getFormData();

    const data: any = {};
    const features = (number.features || []).filter((item) => item !== NUMBER_FEATURE.VOICE_MAIL);

    if (form.controls.voiceMail.value) {
      features.push(NUMBER_FEATURE.VOICE_MAIL);
      if (formData.mode === 'text') {
        data.tts = formData.text;
        data.voicemail_lite_file = await uploadTTS(data.tts, AUDIO_TYPE.VOICE_MAIL_LITE);
      } else {
        data.voicemail_lite_file = formData.file;
      }
    }
    data.features = features;

    onUpdate(data);
  };

  return (
    <Card className="!p-0">
      <div className="flex items-center px-4 py-2">
        <Switch
          checked={enabled}
          onChange={(_, checked) => onEnable(checked)}
        />
        <WithTooltipIcon
          className="font-semibold"
          tooltip="This feature allows you to send all calls to voicemail"
          placement="right"
        >
          Voice mail
        </WithTooltipIcon>
      </div>
      <TextFileInput
        form={form.controls.voiceMailForm}
        onUploadFile={onUploadFile}
        audioType={AUDIO_TYPE.VOICE_MAIL_LITE}
        hidetts
      />
    </Card>
  );
};

export default VoiceMail;
