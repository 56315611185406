import React, { useState } from "react";
import classnames from "classnames";
import {
  DocumentSettingsIcon,
  LaptopVoiceCallIcon,
  MobileMessagesIcon,
  PhoneTrackIcon,
} from "../../../components/icons";

const options = [
  { icon: PhoneTrackIcon, iconSize: 100, title: `Set up basic call-tracking`, value: 'call-tracking', description: 'Follow our super easy tutorial to set up call-tracking from start to finish, including setting up your number and optionally notifications using webhooks' },
  { icon: MobileMessagesIcon, iconSize: 60, title: `Set up a business number for WhatsApp`, value: 'whatsApp', description: 'This guide will have you choose a number and set up SMS/WhatsApp with all the features that you need.' },
  { icon: LaptopVoiceCallIcon, iconSize: 120, title: `Build voice-call into your website`, value: 'website', description: 'A tutorial helping you decide whether you need a call-tracking number and teach you to build the voice-call into your website, optionally using our Chrome extension.' },
  { icon: DocumentSettingsIcon, iconSize: 110, title: `Take me to your docs`, value: 'docs', description: 'I want to see your documentation and build my own use cases.' },
];

const ApiDocumentation = () => {
  const [hoveredItem, setHoveredItem] = useState<number>();

  return (
    <div className="w-screen h-screen flex-center bg-gradient-to-r from-blue-dark to-[#22599C]">
      <div className="w-310 max-w-[95vw] max-h-[95vh] overflow-auto">
        <div className="bg-white rounded-md shadow-md">
          <div className="w-full h-15 bg-gray-ef rounded-t-md" />
          <div className="text-center px-18 pt-8 pb-10">
            <h2 className="text-primary text-3xl font-bold">Why do you need an API?</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10 mt-8 mx-auto">
              {options.map((option, i) => (
                <div
                  key={i}
                  className={classnames(
                    'relative w-full h-full border-1.5 rounded-xl px-4 py-6 cursor-pointer',
                    hoveredItem === i ? 'text-body border-blue shadow-blue-md' : 'text-[#6d8297] border-[#d8e2ea]',
                  )}
                  onMouseEnter={() => setHoveredItem(i)}
                  onMouseLeave={() => setHoveredItem(undefined)}
                >
                  <div className="h-30 flex items-end justify-center">
                    <option.icon size={option.iconSize} color={hoveredItem === i ? ['primary', 'blue'] : ['#aabed3', '#d0dfea']} />
                  </div>
                  <div className={classnames(
                    'min-h-12 text-lg leading-tight mt-10',
                    { 'font-semibold': hoveredItem === i },
                  )}>
                    {option.title}
                  </div>
                  <div className="text-blue-dark text-sm leading-tight mt-6">{option.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDocumentation;
