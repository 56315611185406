import {RelationshipModel} from "./relationship.model";
import {OrganizationModel} from "./organization.model";
import {ROLE} from "../enums";
import {formatDate} from "../helpers";

export class UserModel {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  default_org: OrganizationModel;
  is_active: boolean;
  totp_enabled: boolean;
  relationship: RelationshipModel;
  relationships: any;
  last_login: string;
  is_staff: boolean;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      is_active: false,
      last_login: '',
      relationship: null,
      ...init,
    };

    this.uuid = data.uuid;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.phone_number = data.phone_number;
    this.default_org = data.default_org;
    this.is_active = data.is_active;
    this.is_staff = data.is_staff;
    this.totp_enabled = data.totp_enabled;
    if (data.last_login)
      this.last_login = formatDate(data.last_login);
    if (data.relationship) {
      this.relationship = new RelationshipModel(data.relationship);
    }
  }

  isAdmin(): boolean {
    return (this.relationship?.roles || []).some((item) => item === ROLE.ADMIN);
  }
}
