import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Input } from "../../../components";
import { Form, FormControl, Validators, useForm } from "../../../components/ReactiveForm";
import { SmsBalloon, SmsInput } from "../../../parts";
import { NumberService, ToastService } from "../../../services";
import { CAPABILITY } from "../../../utils/enums";
import { SmsCalculator, formatPhoneNumber } from "../../../utils/helpers";
import { SmsRecordModel } from "../../../utils/types";

export interface ISendSmsFormData {
  fromNumber: string;
  targetNumber: string;
  text: string;
  smsCount: number;
}

export interface ISendMessageFormInitParams {
  getFormData: () => ISendSmsFormData;
}

export interface ISendMessageFormProps {
  receivedMessage?: SmsRecordModel;
  setTouched?: any;
  onInit?: (params: ISendMessageFormInitParams) => void;
  onClose?: any
}

export type ISendMessageForm = {
  fromNumber: FormControl;
  targetNumber: FormControl;
  text: FormControl;
}

const SendMessageForm: FC<ISendMessageFormProps> = ({
  receivedMessage,
  setTouched,
  onInit = () => { },
  onClose
}) => {
  const [sudoNumbers, setSudoNumbers] = useState<string[]>([]);
  const [form, formData] = useForm<ISendMessageForm>({
    fromNumber: new FormControl('', [Validators.required()]),
    targetNumber: new FormControl('', [Validators.required(), Validators.phone()]),
    text: new FormControl('', [Validators.required()]),
  });
  const [initialForm, setInitialForm] = useState(form.getFormData())

  if (setTouched) setTouched(!_.isEqual(form.getFormData(), initialForm))

  useEffect(() => {
    if (receivedMessage) {
      form.patch({
        fromNumber: receivedMessage.number,
        targetNumber: '+' + receivedMessage.from_number,
      });
      setInitialForm({
        fromNumber: receivedMessage.number,
        targetNumber: '+' + receivedMessage.from_number,
        text: ""
      })
      form.controls.fromNumber.readonly = true;
      form.controls.targetNumber.readonly = true;
    }
  }, [receivedMessage]);

  useEffect(() => {
    NumberService.search().then((data) => {
      setSudoNumbers(
        data.results.filter((item) => item.number && item.capabilities.includes(CAPABILITY.SMS))
          .map((item) => formatPhoneNumber(item.number))
          .sort()
      );
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading sudo numbers failed');
    });
  }, []);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      const formData = form.getFormData();
      return {
        ...formData,
        smsCount: SmsCalculator.getCount(formData.text).numberOfSMS,
      };
    }
    return null;
  }, [form]);

  useEffect(() => {
    var target = form.controls.targetNumber.value;

    if (formData.fromNumber) {
      const countryCode = formData.fromNumber.split(' ')[0].replace(/\D/g, '');
      if (target === '' || target !== `+${countryCode}`) {
        target = `+${countryCode}`
      }
    }

    form.patch({
      targetNumber: target,
    })
  }, [formData.fromNumber])

  const onPhoneInput = useCallback((key: string, value: string) => {
    const countryCode = formData.fromNumber.split(' ')[0].replace(/\D/g, '');
    if (/\d/.test(key)) {
      let newPhone = value.replace(/\D/g, '');

      if (newPhone.startsWith(countryCode) !== true) {
        newPhone = newPhone.replace(/0(\d{9})$/, `${countryCode}$1`);
        newPhone = `${countryCode}${newPhone}`;
      }

      form.controls.targetNumber.patch(`+${newPhone}`);
    } else if ((key === 'Backspace' || key === 'Delete') && value.length < `+${countryCode}`.length) {
      form.controls.targetNumber.patch(`+${countryCode}`);
    }
  }, [formData.fromNumber, form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <Form formGroup={form}>
      <div>
        {receivedMessage ? (
          <SmsBalloon sms={receivedMessage} />
        ) : (
          <Input
            type="autocomplete"
            control={form.controls.fromNumber}
            size="sm"
            fullWidth
            label="From number"
            labelClass="text-md font-semibold"
            placeholder="From which Sudo Number to you want to send?"
            options={sudoNumbers}
          />
        )}

        <SmsInput
          className="mt-4"
          control={form.controls.text}
          label="Your message"
          labelClass="text-md font-semibold"
        />

        {!receivedMessage && (
          <Input
            type="phone"
            control={form.controls.targetNumber}
            size="sm"
            fullWidth
            label="To number"
            labelClass="text-md font-semibold"
            containerClass="mt-4"
            placeholder="To who do you want to send this message to?"
            onKeyUp={(e) => onPhoneInput(e.key, e.target.value)}
          />
        )}
      </div>
    </Form>
  );
};

export default SendMessageForm;
