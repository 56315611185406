import React, {FC} from "react";
import {Modal} from "../../../components";
import MessageDetail from "../MessageDetail";
import {SmsRecordModel} from "../../../utils/types";

interface IMessageDetailModalProps {
  message: SmsRecordModel;
  onClose: (result?: any) => void;
}

const MessageDetailModal: FC<IMessageDetailModalProps> = ({
  message, onClose,
}) => {
  return (
    <Modal
      title={`Message / ${message.uuid}`}
      size="full"
      contentClass="bg-gray-f6 lg:px-18 lg:py-9"
      onClose={onClose}
    >
      <MessageDetail message={message} onClose={onClose} />
    </Modal>
  );
};

export default MessageDetailModal;
