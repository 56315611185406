import React, { FC } from "react";
import logo from "../../assets/noGA4.svg";

export interface INoDataProps {
  img?: string,
  title?: string,
  text?: string
}

export const NoData: FC<INoDataProps> = ({
  img = logo,
  title,
  text,
}) => {
  return (
    <div className="h-full flex flex-col justify-center items-center mx-20">
      <img src={img} alt="empty-logo" />
      <div className="text-[28px] text-[#307CD8] font-bold text-center my-5">{title}</div>
      <div className="text-[13px] text-center">{text}</div>
    </div>
  );
};
