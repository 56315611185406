
import React, { FC } from "react";
import { Button, Card, Input, RadioGrouping } from "../../../components";
import { Form, FormControl, Validators, useForm } from "../../../components/ReactiveForm";
import { PlusCircleIcon } from "../../../components/icons";

export type IErrorManagementForm = {
    notify_frequency: FormControl;
    error_type: FormControl;
    notify_type: FormControl;
    email_address: FormControl;
}


export const ErrorManagement: FC = () => {

    const [form, formData] = useForm<IErrorManagementForm>({
        notify_frequency: new FormControl('', [Validators.required()]),
        error_type: new FormControl('', [Validators.required()]),
        notify_type: new FormControl('', [Validators.required()]),
        email_address: new FormControl('', [Validators.required(), Validators.email()]),
    });

    const onSave = () => {
        if (!form.validate()) {
            return;
        }
        console.log("Saved data!")
    }

    const onAddEmail = () => {
        console.log("Added Email!")
    }

    return (
        <div className="h-full flex flex-col px-12 pt-6 bg-[#F8F8F8]">
            <Form onSubmit={onSave} formGroup={form}>
                <Card className="mb-8">
                    <h3
                        className={"!font-semibold text-lg pb-4"}
                    >Notification Settings</h3>
                    <div className="grid grid-cols-2 gap-8">
                        <Input
                            type="select"
                            control={form.controls.notify_frequency}
                            fullWidth
                            label="How frequently would you like to be notified?"
                            labelClass="text-sm pb-2"
                            inputClass="!text-sm !font-normal"
                        />
                        <Input
                            type="select"
                            control={form.controls.error_type}
                            fullWidth
                            label="Which error type do you want to be notified for?"
                            labelClass="text-sm pb-2"
                            inputClass="!text-sm !font-normal"
                        />
                    </div>
                </Card>
                <Card>
                    <p className="!font-semibold">How would you like to be notified?</p>
                    <RadioGrouping
                        className="py-4"
                        value={'email'}
                        onValueChange={() => { }}
                        options={[{ label: "Email", value: 'email' }]}
                    />
                    <div className="grid grid-cols-2 gap-8">
                        <Input
                            type="text"
                            control={form.controls.email_address}
                            placeholder={"Please add your email address"}
                            label="Who would you like to notify?"
                            labelClass="text-sm font-semibold pb-2"
                            inputClass="!text-sm !font-normal"
                        />

                        <span
                            className="flex flex-row items-center mb-4 hover:cursor-pointer"
                            onClick={onAddEmail}
                        >
                            <PlusCircleIcon
                                color={"blue"}
                                size={30}
                            />
                            <span
                                className={"text-blue !font-semibold text-md ml-2"}
                            >
                                ADD EMAIL ADDRESS
                            </span>
                        </span>
                    </div>
                </Card>
                <Button
                    color="primary"
                    className="shadow-md rounded-full !font-semibold px-6 fixed bottom-5 right-12"
                >
                    Save
                </Button>
            </Form>
        </div>
    )
}