import React, { FC, ReactNode, useMemo, useState } from "react";
import {
  VoiceMailIcon
} from '../../../../components/icons';
import { TITLE } from "../../../../constants";
import { SingleSelectFilter } from '../../../../parts';
import { CAMPAIGN_MESSAGE_FILTERS } from "../../../../utils/enums";
import { FilterModel } from "../../../../utils/types";

export interface ICampaignsSummaryFilterProps {
  filters: FilterModel;
  onChange(filters: FilterModel): void;
  children?: ReactNode;
}

const channelOptions = CAMPAIGN_MESSAGE_FILTERS.map((item) => ({
  value: item.value,
  text: <span className="text-10p"><b className="text-11p">{item.text}</b> ({item.value})</span>,
  plainText: `${item.text} (${item.value})`,
}))

const categories = [
  {
    type: 'channels',
    title: TITLE.channels,
    icon: VoiceMailIcon,
    iconSize: 16,
    component: SingleSelectFilter,
    props: {
      options: channelOptions,
      dropdownClass: '!w-110 !max-w-110',
    },
  },
];

export const CampaignsSummaryFilter: FC<ICampaignsSummaryFilterProps> = ({
  filters,
  onChange,
  children,
}) => {
  const [activeFilter, setActiveFilter] = useState<FilterModel>();

  const onSelectFilter = (filter: FilterModel) => {
    onChange(filter);
    setActiveFilter(undefined);
  };

  const onDeleteFilter = () => {
    onChange(null);
    setActiveFilter(undefined);
  };

  return (
    <div className="flex items-center flex-wrap flex-shrink">
      <span className="text-sm mr-2 mb-2">Filter data:</span>
      <SingleSelectFilter
        options={channelOptions}
        value={filters}
        onChange={onSelectFilter}
        onDelete={onDeleteFilter}
      />

      <div className="mr-auto" />

      {children}
    </div>
  );
};
