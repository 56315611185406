import {RelationshipModel} from "./relationship.model";

export class ProjectModel {
  uuid: string;
  parent_uuid: string;
  name: string;
  permissions: string[];
  is_active: boolean;
  relationship: RelationshipModel;
  tts_language: string;
  created: string;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      parent_uuid: null,
      name: '',
      permissions: [],
      is_active: false,
      relationship: null,
      tts_language: '',
      created: '',
      ...init,
    };

    this.uuid = data.uuid;
    this.parent_uuid = data.parent_uuid;
    this.name = data.name;
    this.permissions = data.permissions;
    this.is_active = data.is_active;
    this.tts_language = data.tts_language;
    this.created = data.created;
    if (data.relationship) {
      this.relationship = new RelationshipModel(data.relationship);
    }
  }
}
