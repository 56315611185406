import React, {FC, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "../../../../components";
import {ArrowLeftIcon, LogoIcon, LogoTextIcon} from "../../../../components/icons";
import {ROUTES} from "../../../../constants";
import {ISetupPaymentForm} from "../index";
import {CreditCardForm, ICreditCardFormInitParams} from "../../../../parts";

export interface ICreditCardProps {
  data: ISetupPaymentForm;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ISetupPaymentForm>): void;
}

const CreditCard: FC<ICreditCardProps> = ({
  data, onBack, onNext, onSubmit,
}) => {
  const [cardForm, setCardForm] = useState<ICreditCardFormInitParams>({
    getFormData: () => null,
  });

  const onContinue = () => {
    const data = cardForm.getFormData();
    if (data) {
      onSubmit(data);
      onNext();
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>
      <h1 className="text-4xl text-primary font-bold mt-4">Let's set up your prepaid account</h1>
      <p className="text-lg text-blue-dark font-medium mt-2">Please give us your card details to add funds to your account</p>

      <CreditCardForm className="mt-12" data={data} onInit={setCardForm} />

      <div className="flex items-center mt-auto">
        <Link className="text-primary text-sm hover:underline uppercase" to={ROUTES.DASHBOARD}>Skip for now</Link>
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button type="submit" color="primary" className="text-sm rounded-full shadow-md px-8 ml-2" onClick={onContinue}>Next</Button>
      </div>
    </div>
  );
};

export default CreditCard;
