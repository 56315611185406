import { MenuItem } from "@mui/material";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Card, Input, MessagePreview, Modal } from "../../../../components";
import { ExpandableCard } from "../../../../components/ExpandableCard";
import { FormControl, Validators, useForm } from "../../../../components/ReactiveForm";
import { StyledDropzone } from "../../../../components/StyledDropzone";
import { EditIcon, ImageIcon } from "../../../../components/icons";
import { ConfirmationModal } from "../../../../parts";
import { ToastService, WACampaignsService } from "../../../../services";
import { CAMPAIGN_STATUS, TEMPLATE_FILE_TYPES } from "../../../../utils/enums";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { WACampaignsModel, WATemplatesModel } from "../../../../utils/types";

type ICampaignDetailsForm = {
    name: FormControl;
    whatsapp_id?: FormControl;
    template?: FormControl;
    campaign_list?: FormControl;
    campaign_list_name?: FormControl;
}

interface ICampaignDetailsFormData {
    campaignUUID: string;
    name: FormControl;
    campaignStatus: CAMPAIGN_STATUS;
    whatsapp_id?: FormControl;
    template?: FormControl;
    campaign_list?: FormControl;
    campaign_list_name?: FormControl;
}

export interface ICampaignDetailsFormInitParams {
    getFormData: () => ICampaignDetailsFormData;
}

interface ICampaignDetailModalProps {
    campaign?: WACampaignsModel;
    onClose: (result?: any) => void;
    onImport: (result?: any) => void;
    onNameEdit: (result?: any) => void;
    onCampaignSave: (result?: any) => void;
    onCampaignSend: (result?: any) => void;
    onInit?: (params: ICampaignDetailsFormInitParams) => void;
}

export const CampaignsDetailModal: FC<ICampaignDetailModalProps> = ({
    campaign,
    onClose,
    onCampaignSave,
    onCampaignSend,
    onImport,
    onNameEdit,
    onInit = () => { },
}) => {
    //confirmation modal
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [isConfirmClose, setIsConfirmClose] = useState<boolean>(true)

    //populate the drop downs
    const [availableAccounts, setAvailableAccounts] = useState<any[]>([]);
    const [availableTemplates, setAvailableTemplates] = useState<WATemplatesModel[]>([]);

    //state
    const [selectedTemplate, setSelectedTemplate] = useState<WATemplatesModel>(null);
    const [templatePreview, setTemplatePreview] = useState<string>(null);
    const [disableSend, setDisableSend] = useState<boolean>(false);
    const [disableImport, setDisableImport] = useState<boolean>(false);

    const [form, formData] = useForm<ICampaignDetailsForm>({
        name: new FormControl(campaign?.name || "", [Validators.required()]),
        whatsapp_id: new FormControl(campaign?.message_data.whatsapp_id || null, [Validators.required()]),
        template: new FormControl(campaign?.message_data.template?.uuid || "", [Validators.required()]),
        campaign_list: new FormControl(campaign?.campaign_list || "", [Validators.required()]),
        campaign_list_name: new FormControl(campaign?.campaign_list_name || "", [Validators.required()]),
    });
    const [initialForm, setInitialForm] = useState(form.getFormData());

    const getFormData = useCallback(() => {
        if (form.validate()) {
            const formData = form.getFormData();
            return {
                ...formData,
            };
        }
        return null;
    }, [form]);

    useEffect(() => {
        onInit({
            getFormData,
        });
    }, [onInit, getFormData]);

    useEffect(() => {
        populateInputs();
        if (campaign) {
            form.patch({
                name: campaign.name,
                whatsapp_id: campaign.message_data.whatsapp_id,
                template: campaign.message_data.template?.uuid,
                campaign_list: campaign.campaign_list,
                campaign_list_name: campaign.campaign_list_name,
            });
            setInitialForm({
                name: campaign.name,
                whatsapp_id: campaign.message_data.whatsapp_id,
                template: campaign.message_data.template?.uuid,
                campaign_list: campaign.campaign_list,
                campaign_list_name: campaign.campaign_list_name,
            })
            setTemplatePreview(campaign.message_data.template_header_url);
            setSelectedTemplate(campaign.message_data.template);
        }
    }, [campaign]);

    useEffect(() => {
        let canSend = false;
        let canImport = false;
        const values = Object.values(form.getFormData());
        canSend = values.some((val: string) => val?.length === 0 || val === undefined)
        canImport = values.slice(0, 3).some((val: string) => val?.length === 0 || val === undefined)
        setDisableImport(canImport);
        setDisableSend(canSend);
    }, [form.getFormData()])

    const populateInputs = useCallback(() => {
        WACampaignsService.searchAccounts({}, false)
            .then((res) => {
                const accounts = res;
                setAvailableAccounts(accounts);
            }).catch((error) => {
                ToastService.error(error.error)
            });
    }, [])

    useEffect(() => {
        WACampaignsService.searchTemplates({}, false)
            .then((res) => {
                const templates = res.filter((item) => {
                    return item.whatsapp_number === form.controls.whatsapp_id.value
                });
                setAvailableTemplates(templates);
            }).catch((error) => {
                ToastService.error(error.error)
            })
    }, [form.controls.whatsapp_id.value])

    const onFileUpload = (files: File[]) => {
        const file = files[0]
        const data = form.getFormData();

        if (data && file) {
            WACampaignsService.uploadTemplateFile({
                media_file: file,
                type: TEMPLATE_FILE_TYPES[selectedTemplate?.media_type] || TEMPLATE_FILE_TYPES.IMAGE,
                whatsapp_number: data.whatsapp_id,
                caption: file.name,
            }).then((res) => {
                setTemplatePreview(res.full_url)
            }).catch((err) => {
                ToastService.error("Template file upload failed.")
                setTemplatePreview(null);
            })
            return;
        }
        setTemplatePreview(null)
    }

    const onSave = (isImport?: boolean) => {
        const data = form.getFormData();
        if (data) {
            WACampaignsService.modifyCampaign({
                uuid: campaign.uuid,
                name: data.name,
                message_data: {
                    template: selectedTemplate,
                    whatsapp_id: data.whatsapp_id,
                    template_header_url: templatePreview
                }
            }).then((res) => {
                onCampaignSave(res);
                if (isImport) {
                    onImport();
                }
            }).catch((err) => {
                ToastService.error("Saving the campaign failed.")
            })
        }
    }

    const onSend = () => {
        onCampaignSend();
    }

    const onTemplateChange = (uuid: string) => {
        const selected = availableTemplates.filter((template) => template.uuid === uuid).flat();
        setSelectedTemplate(selected[0]);
    }

    const isFormTouched = (callback: any) => {
        if (!_.isEqual(form.getFormData(), initialForm)) {
            setShowConfirmation(true)
            return;
        }
        callback();
    }

    const onConfirmClose = (confirm) => {
        if (confirm === false) {
            setShowConfirmation(false);
        } else if (!isConfirmClose) {
            setShowConfirmation(false);
            onImport();
        } else {
            onClose();
        }
    }

    const onImportList = () => {
        setIsConfirmClose(false);
        isFormTouched(onImport);
    }

    const close = () => {
        setIsConfirmClose(true);
        isFormTouched(onClose);
    }

    return (
        <Modal
            size="full"
            title={"Create a WhatsApp Campaign"}
            contentClass="bg-[#F8F8F8] px-0"
            onClose={close}
        >
            {showConfirmation &&
                <ConfirmationModal
                    title={"Confirm Cancel"}
                    message={<div className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</div>}
                    onClose={(res) => onConfirmClose(res)}
                />
            }

            <div className="flex flex-row-reverse h-full">
                <MessagePreview
                    preview={templatePreview}
                    template={selectedTemplate}
                />
                <div className="w-full mx-4">
                    <Card className="!px-4 !py-2 !pt-6">
                        <div className="relative max-w-120">
                            <Input
                                control={form.controls.name}
                                size="sm"
                                placeholder="This is the campaign name"
                                className="w-full"
                                containerClass="!pb-0"
                            />
                            <div className="absolute right-0 top-0">
                                <div
                                    className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md my-1 mx-1 cursor-pointer"
                                >
                                    <EditIcon color="blue" size={22} onClick={() => onNameEdit()} />
                                </div>
                            </div>
                        </div>
                    </Card>


                    <ExpandableCard
                        title={"From"}
                        containerClass="!px-4 !py-4"
                        completed={form.controls.whatsapp_id?.value?.length > 0}
                    >
                        <Input
                            type="select"
                            control={form.controls.whatsapp_id}
                            label="Choose which WhatsApp Business account you will show on the campaign"
                            placeholder="Select your WhatsApp Business Account"
                            labelClass="text-xs font-medium"
                            containerClass="col-span-2 !pb-0"
                            size="sm"
                            fullWidth
                        >
                            {availableAccounts.map((account) => (
                                <MenuItem key={account.uuid} className="!text-sm" value={account.whatsapp_number}>
                                    {formatPhoneNumber(account.whatsapp_number)}
                                </MenuItem>
                            ))}
                        </Input>
                    </ExpandableCard>

                    <ExpandableCard
                        title={"Choose your template"}
                        containerClass="!px-4 !py-4"
                        completed={form.controls.template?.value?.length > 0}
                    >
                        <p className="text-sm mb-6 font-medium">Choose from your approved template list. If you do not have approved Message templates, please contact <a href="mailto:support@sudonum.com" target="_blank" rel="noreferrer" className="text-primary font-bold">support@sudonum.com</a></p>
                        {availableTemplates.length !== 0 &&
                            <Input
                                type="select"
                                control={form.controls.template}
                                label="What message would you like your recipients to receive?"
                                placeholder="Choose a template"
                                labelClass={"text-xs font-medium"}
                                containerClass={"col-span-2 !pb-0"}
                                size="sm"
                                fullWidth
                                onChange={(k, v) => onTemplateChange(v)}
                            >
                                {availableTemplates.map((template: WATemplatesModel) => (
                                    <MenuItem key={template.uuid} className="!text-sm" value={template.uuid}>
                                        {template.name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                                    </MenuItem>
                                ))}
                            </Input>
                        }
                        {availableTemplates.length === 0 &&
                            <>
                                <span className="text-xs font-medium text-danger">What message would you like your recipients to receive?</span>
                                <div className="rounded-sm !border border-danger h-[33.87px] w-full mb-5 flex items-center px-4 text-danger text-sm font-semibold">
                                    No Templates Found
                                </div>
                            </>
                        }

                        <p className="text-xs mb-6 text-blue"><span className="font-bold">TIP: </span>Ensure that, at a minimum, 1 of your actions on your chosen template is an opt out option. This is to avoid your number being marked as spam.</p>
                        {(form.controls.template.value && selectedTemplate?.type !== 'TEXT') &&
                            <StyledDropzone
                                accept={[`${selectedTemplate?.media_type?.toLowerCase() || 'image'}/*`]}
                                default_files={[]}
                                label={"Upload your template image here"}
                                labelClass="font-lg font-bold"
                                fullWidth
                                containerClass="!max-w-full"
                                onChange={(file: File[]) => onFileUpload(file)}
                                icon={<ImageIcon size={56} color={'blue'} />}
                            />
                        }
                    </ExpandableCard>

                    <ExpandableCard
                        title={"To"}
                        containerClass="!px-4 !py-4"
                        completed={form.controls.campaign_list_name?.value?.length > 0}
                    >
                        <p className="text-sm font-medium">Upload a list of contacts to send your campaign to</p>
                        {campaign.campaign_list.length > 0 &&
                            <>
                                <Input
                                    control={form.controls.campaign_list_name}
                                    type="text"
                                    containerClass="mt-2 !pb-0"
                                    fullWidth
                                    readOnly={true}
                                />
                                <Button
                                    onClick={onImportList}
                                    className="font-extrabold text-md text-blue mr-2 ml-auto"
                                >
                                    Oops, import a new list
                                </Button>
                            </>
                        }
                        {campaign.campaign_list.length === 0 &&
                            <Button
                                data-cy="wa-campaigns-import-csv"
                                color="primary"
                                className="text-sm rounded-full px-6 py-2 shadow-md mt-6"
                                onClick={() => onSave(true)}
                                disabled={disableImport}
                            >Import Csv</Button>
                        }
                    </ExpandableCard>

                    <div className="w-full flex flex-row justify-end gap-2 mr-2 ml-auto pb-4">
                        <Button
                            data-cy="wa-campaigns-name-button"
                            className={"text-sm rounded-full px-6 py-2"}
                            color={"blue"}
                            onClick={() => onSave(false)}
                        >
                            Save
                        </Button>


                        {!disableSend &&
                            <Button
                                data-cy="wa-campaigns-name-button"
                                className="text-sm rounded-full px-6 py-2"
                                color={"primary"}
                                onClick={onSend}
                                type="submit"
                            >
                                Send
                            </Button>
                        }
                    </div>
                </div>
            </div >

        </Modal >
    )
}
