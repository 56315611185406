import {SMS_DIRECTION, SMS_STATUS} from "../enums";

export class SmsRecordModel {
  uuid: string;
  number: string;
  target_number: string;
  from_number: string;
  message: string;
  time: string;
  status: SMS_STATUS;
  context: string;
  meta: {
    [key: string]: string;
  };
  direction: SMS_DIRECTION;
  sms_count: number;
  bulk_cdr_uuid: string;

  tags: string[];

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      number: '',
      target_number: '',
      from_number: '',
      message: '',
      time: '',
      status: '',
      context: '',
      meta: {},
      direction: '',
      sms_count: 0,
      bulk_cdr_uuid: null,
      tags: [],
      ...init,
    };

    this.uuid = data.uuid;
    this.number = data.number;
    this.target_number = data.target_number;
    this.from_number = data.from_number;
    this.message = data.message;
    this.time = data.time;
    this.status = data.status;
    this.context = data.context;
    this.meta = data.meta;
    this.direction = data.direction;
    this.sms_count = data.sms_count;
    this.bulk_cdr_uuid = data.bulk_cdr_uuid;
    this.tags = data.tags;
  }

  getOthersNumber(): string {
    return this.direction === SMS_DIRECTION.INBOUND ? this.from_number : this.target_number;
  }

  getKeywords(): string[] {
    // TODO: should be fixed with real keywords
    return [this.uuid];
  }
}
