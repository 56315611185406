export const ROUTES = {
  DASHBOARD: '/dashboard',
  AUTH: {
    PREFIX: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    CONFIRM_TOTP: '/confirm-totp',
    CONFIRM_EMAIL: '/confirm-email/:uuid',
    VERIFY_TOKEN: '/verify/:uid64/:token',
    RESET_PASSWORD: '/reset-password',
  },
  ONBOARD: {
    PREFIX: '/onboard',
    INDEX: '/onboard',
    CREATE_NUMBER: '/onboard/create-number',
    WHATSAPP_SETUP: '/onboard/whatsapp-setup',
    API_DOC: '/onboard/api-doc',
    SETUP_PAYMENT: '/onboard/setup-payment',
  },
  DASH: {
    PREFIX: '/dash',
    INDEX: '/dash/calls',
    CALLS: '/dash/calls',
  },
  CALLS: {
    PREFIX: '/calls',
    INDEX: '/calls/received',
    MADE: '/calls/made',
    RECEIVED: '/calls/received',
    BLOCKED: '/calls/blocked',
  },
  MESSAGES: {
    PREFIX: '/messages',
    INDEX: '/messages/received',
    SENT: '/messages/sent',
    RECEIVED: '/messages/received',
    BLOCKED: '/messages/blocked',
  },
  NUMBERS: {
    PREFIX: '/numbers',
    INDEX: '/numbers',
  },
  WHATSAPP: {
    PREFIX: '/whatsapp',
    INDEX: '/whatsapp/accounts',
    ACCOUNTS: {
      PREFIX: '/whatsapp/accounts',
      INDEX: '/whatsapp/accounts',
      DETAILS: '/whatsapp/accounts/:id',
      SETUP: '/whatsapp/accounts/setup',
    },
    DOCUMENTATION: {
      PREFIX: '/whatsapp/documentation',
      INDEX: '/whatsapp/documentation',
    },
    CHAT_INTROS: {
      PREFIX: '/whatsapp/chat-intros',
      INDEX: '/whatsapp/chat-intros',
    },
    CHAT_LOGS: {
      PREFIX: '/whatsapp/chat-logs',
      INDEX: '/whatsapp/chat-logs',
    },
    CAMPAIGNS: {
      PREFIX: '/whatsapp/campaigns',
      INDEX: '/whatsapp/campaigns',
    },
    LISTS: {
      PREFIX: '/whatsapp/lists',
      INDEX: '/whatsapp/lists',
    },
  },
  OPTIONS: {
    PREFIX: '/more',
    INDEX: '/more/whatsapp',
    WHATS_APP: '/more/whatsapp',
  },
  DEVELOPER: {
    PREFIX: '/developer',
    INDEX: '/developer/documentation',
    DOCUMENTATION: '/developer/documentation',
    API_TOKENS: '/developer/api-tokens',
    ISSUES: {
      INDEX: '/developer/issues',
      PREFIX: '/developer/issues',
      DETAILS: '/developer/issues/:id',
    },
    ERROR_MANAGEMENT: {
      INDEX: '/developer/issue-management',
      PREFIX: '/developer/issue-management',
    },
    WEBHOOKS: {
      INDEX: '/developer/webhooks',
      PREFIX: '/developer/webhooks',
      CREATE: '/developer/webhooks/create',
      EDIT: '/developer/webhooks/:id/edit',
    }
  },
  SETTINGS: {
    PREFIX: '/settings',
    INDEX: '/settings/profile',
    PROFILE: '/settings/profile',
    USERS: {
      PREFIX: '/settings/users',
      INDEX: '/settings/users',
      CREATE: '/settings/users/invite',
      EDIT: '/settings/users/:id/edit',
    },
    PROJECTS: {
      PREFIX: '/settings/projects',
      INDEX: '/settings/projects',
      CREATE: '/settings/projects/create',
      EDIT: '/settings/projects/:id/edit',
    },
    BILLING: {
      PREFIX: '/settings/billing',
      INDEX: '/settings/billing',
      CARD: '/settings/billing/card',
      AUTO_RECHARGING: '/settings/billing/auto-recharging',
      ADD_FUNDS: '/settings/billing/add-funds',
    },
    INVOICES: {
      PREFIX: '/settings/invoices',
      INDEX: '/settings/invoices',
    },
    BLOCKED_NUMBERS: '/settings/blocked-numbers',
    BLOCKED_KEYWORDS: '/settings/blocked-keywords',
    AUDIO_FILES: {
      PREFIX: '/settings/audio-files',
      INDEX: '/settings/audio-files',
      CREATE: '/settings/audio-files/create',
      EDIT: '/settings/audio-files/:type/:name/edit',
    },
    INTEGRATIONS: {
      PREFIX: '/settings/integrations',
      INDEX: '/settings/integrations',
      GA4: {
        PREFIX: '/settings/integrations/ga4',
        INDEX: '/settings/integrations/ga4',
        CREATE: '/settings/integrations/ga4/create',
        EDIT: '/settings/integrations/ga4/:id/edit'
      },
    },
    TAGS: '/settings/tags',
    TFA: "/settings/two-factor-auth",
  },

  PUBLIC: {
    TERMS_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
  },
};
