import {HttpService} from "./http.service";
import {ICreateApiTokenRequest} from "../utils/dto";
import {ApiTokenModel} from "../utils/types";

export class ApiTokenService {

  static search(orgUuid: string, showSpinner = true): Promise<ApiTokenModel[]> {
    return HttpService.get(`/admin/org/${orgUuid}/token`, {}, {}, showSpinner)
      .then((res) => res.results.map((item) => new ApiTokenModel(item)));
  }

  static find(orgUuid: string, uuid: string, showSpinner = true): Promise<ApiTokenModel> {
    return HttpService.get(`/admin/org/${orgUuid}/token/${uuid}`, {}, {}, showSpinner)
      .then((res) => new ApiTokenModel(res));
  }

  static create(orgUuid: string, data: ICreateApiTokenRequest, showSpinner = true): Promise<ApiTokenModel> {
    return HttpService.post(`/admin/org/${orgUuid}/token`, data, {}, showSpinner)
      .then((res) => new ApiTokenModel(res));
  }

  static update(orgUuid: string, uuid: string, data: Partial<ICreateApiTokenRequest>, showSpinner = true): Promise<ApiTokenModel> {
    return HttpService.patch(`/admin/org/${orgUuid}/token/${uuid}`, data, {}, showSpinner)
      .then((res) => new ApiTokenModel(res));
  }

  static delete(orgUuid: string, uuid: string, showSpinner = true) {
    return HttpService.delete(`/admin/org/${orgUuid}/token/${uuid}`, {}, {}, showSpinner);
  }
}
