import React, {FC} from "react";
import {Button, Modal} from "../../../../components";
import {ToastService} from "../../../../services";
import {ApiTokenModel} from "../../../../utils/types";
import {copyToClipboard} from '../../../../utils/helpers';
import {CheckCircleOutlineIcon, CloneIcon} from '../../../../components/icons';

interface IApiTokenCreateConfirmModalProps {
  createdToken: ApiTokenModel;
  onClose: (data?: ApiTokenModel) => void;
  title: string;
}

const ApiTokenCreateConfirmModal: FC<IApiTokenCreateConfirmModalProps> = ({
  createdToken,
  onClose,
  title
}) => {

  const onCopy = async () => {
    onClose();
    await copyToClipboard(createdToken.key, 'text/plain', false)
    ToastService.success(<><span className="text-success mr-1">Copied</span> API Token to clipboard</>);
  };

  const onClickDone = () => {
    onClose();
    ToastService.success(<><span className="text-success mr-1">Successfully</span> created new API Token.</>);
  };

  return (
    <Modal
      title="API Token Confirmation"
      headerClass="opacity-0"
      hasCloseButton={false}
      footerComponent={(
        <>
          <Button
            data-cy="create-token-done"
            className="ml-auto text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onClickDone}
          >
            Done
          </Button>
        </>
      )}
      onClose={() => onClose()}
    >
      <div className="w-fit mt-10 mx-auto flex flex-col items-center">
        <CheckCircleOutlineIcon size={58} className="text-lime" />
        <p className="mb-[14px] text-32p font-bold text-lime">Yay!</p>
        <p className="mb-2 text-lg font-bold text-gray-1b">{title}</p>
        <p className="text-sm text-blue-dark">Copy the API token for convenience, or view it from the table</p>
        <div
          data-cy="copy-new-token"
          className="w-full mt-10 py-3 flex justify-center items-center text-sm hover:text-blue rounded-xl bg-blue/19 has-hover-action cursor-pointer"
          onClick={onCopy}
        >
          <span className="mr-2">{Array(createdToken.uuid.length).fill('●').join('')}</span>
          <CloneIcon className="pnh-transparent transition-all" size={16} />
        </div>
      </div>
    </Modal>
  );
};

export default ApiTokenCreateConfirmModal;
