import React, {FC, MouseEventHandler} from "react";
import classnames from "classnames";
import {CloseCircleIcon} from "../icons";

export type ChipSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IChipProps {
  className?: string;
  containerClass?: string;
  size?: ChipSize;
  active?: boolean;
  clickable?: boolean;
  removable?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onRemove?: () => void;
}

const iconSizes = {
  xs: 24,
  sm: 28,
};

export const Chip: FC<IChipProps> = ({
  children,
  className = '',
  size = 'xs',
  containerClass = 'mr-1 mb-1',
  active = false,
  clickable = true,
  removable = false,
  disabled,
  onRemove = () => {},
  ...props
}) => {
  return (
    <div className={classnames(
      'flex items-center',
      containerClass,
    )}>
      <button
        data-cy="chip"
        type="button"
        className={classnames(
          'flex-center whitespace-nowrap border rounded-full outline-none transition-all',
          { 'text-xs px-3 py-2': size === 'xs' },
          { 'text-xs px-4 py-2.5': size === 'sm' },
          { 'bg-blue border-blue text-white shadow': !disabled && active },
          { 'border-secondary text-secondary hover:border-blue hover:text-blue': clickable && !disabled && !active },
          { 'bg-gray-e3 text-white border-gray-e3': disabled },
          { 'cursor-default': !clickable && !disabled },
          className,
        )}
        disabled={disabled}
        {...props}
      >
        { children }
      </button>
      {removable && (
        <CloseCircleIcon className="cursor-pointer ml-1 mr-2" color="#DDD" size={iconSizes[size]} onClick={onRemove} />
      )}
    </div>
  );
};
