import {ROLE} from "../enums";

export class RelationshipModel {
  uuid: string;
  org_uuid: string;
  user_uuid: string;
  roles: ROLE[];
  is_owner: boolean;
  created: string;
  modified: string;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      org_uuid: '',
      user_uuid: '',
      roles: [],
      is_owner: false,
      created: '',
      modified: '',
      ...init,
    };

    this.uuid = data.uuid;
    this.org_uuid = data.org_uuid;
    this.user_uuid = data.user_uuid;
    this.roles = data.roles;
    this.is_owner = data.is_owner;
    this.created = data.created;
    this.modified = data.modified;
  }
}
