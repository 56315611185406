import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WhatsAppCampaignStep1Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={208.554} baseHeight={209.322} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 208.554 209.322">
        <g id="Mobile_Marketing-pana" data-name="Mobile Marketing-pana" transform="translate(-27.5 -23.89)">
          <g id="freepik--background-complete--inject-5" transform="translate(37.113 23.89)">
            <path id="Path_3081" data-name="Path 3081" d="M118.124,90h0A16.074,16.074,0,0,1,102.05,73.924h0A16.074,16.074,0,0,1,118.124,57.85h0A16.074,16.074,0,0,1,134.2,73.924h0A16.074,16.074,0,0,1,118.124,90Z" transform="translate(-76.687 -41.917)" fill="#ebebeb" />
            <rect id="Rectangle_4522" data-name="Rectangle 4522" width="3.326" height="10.749" transform="translate(34.146 28.427)" fill="#fff" />
            <path id="Path_3082" data-name="Path 3082" d="M122.056,76.989A1.985,1.985,0,1,0,120.09,75a1.985,1.985,0,0,0,1.966,1.985Z" transform="translate(-86.263 -49.97)" fill="#fff" />
            <path id="Path_3083" data-name="Path 3083" d="M135.585,89.213c0-1.511.694-2.411,2.027-2.411,1.22,0,1.811.863,1.811,2.411v5.63h3.308V88.049c0-2.881-1.628-4.269-3.908-4.269a3.753,3.753,0,0,0-3.237,1.773V84.108h-3.2V94.852h3.2Z" transform="translate(-92.793 -55.681)" fill="#fff" />
            <path id="Path_3084" data-name="Path 3084" d="M175.176,23.89l2.2,4.021,4.025,2.205-4.025,2.2-2.2,4.021-2.2-4.021-4.025-2.2,4.025-2.205Z" transform="translate(-112.2 -23.89)" fill="#f5f5f5" />
            <path id="Path_3085" data-name="Path 3085" d="M372.588,256.27l1.651,3.021,3.017,1.647-3.017,1.651-1.651,3.021-1.651-3.021-3.017-1.651,3.017-1.647Z" transform="translate(-217.821 -147.246)" fill="#ebebeb" />
            <path id="Path_3086" data-name="Path 3086" d="M385.437,224.51l.746,1.361,1.361.746-1.361.746-.746,1.361-.741-1.361-1.365-.746,1.365-.746Z" transform="translate(-226.001 -130.386)" fill="#e0e0e0" />
            <path id="Path_3087" data-name="Path 3087" d="M146.327,27.11l.746,1.365,1.361.741-1.361.746-.746,1.361-.741-1.361-1.365-.746,1.365-.741Z" transform="translate(-99.073 -25.599)" fill="#e0e0e0" />
            <path id="Path_3088" data-name="Path 3088" d="M57.908,286.159a4.875,4.875,0,0,0,2.843-4.541,4.572,4.572,0,1,0-9.125.08,4.851,4.851,0,0,0,2.965,4.5c-7.009,1.149-6.6,8.778-6.6,8.778l16.6-.141S64.885,287.121,57.908,286.159Z" transform="translate(-47.99 -158.139)" fill="#e0e0e0" />
            <path id="Path_3089" data-name="Path 3089" d="M397.063,401.573a2.585,2.585,0,0,0-5.1.15,2.585,2.585,0,0,0-5.1-.122,3.486,3.486,0,0,0,.629,2.519,10.242,10.242,0,0,0,1.82,1.924c.938.83,1.788,1.6,2.716,2.43.938-.835,1.7-1.619,2.623-2.454a10.131,10.131,0,0,0,1.806-1.942,3.5,3.5,0,0,0,.605-2.505Z" transform="translate(-227.859 -223.268)" fill="#ebebeb" />
            <path id="Path_3090" data-name="Path 3090" d="M431.69,181.693l1.778-.352,2.815-6.184-.7-3.552s2.716-.713,3.317,4,.713,3.462.713,3.462,5.841-1.135,6.428-.727c.427.3,1.736,6.071,2.421,9.2a3.467,3.467,0,0,1-1.731,3.908,2.436,2.436,0,0,1-.615.206c-1.454.291-9.13.582-9.13.582l-3.073.61Z" transform="translate(-251.672 -102.283)" fill="#e0e0e0" />
            <path id="Path_3091" data-name="Path 3091" d="M420.42,192.572l3.5,11.954,3.05-.605L424.61,192Z" transform="translate(-245.69 -113.129)" fill="#e0e0e0" />
          </g>
          <g id="freepik--Floor--inject-5" transform="translate(27.5 232.747)">
            <path id="Path_3092" data-name="Path 3092" d="M235.325,469.182c0,.07-46.527.122-103.906.122s-103.92-.052-103.92-.122,46.518-.122,103.92-.122S235.325,469.116,235.325,469.182Z" transform="translate(-27.5 -469.06)" fill="#263238" />
          </g>
          <g id="freepik--Icons--inject-5" transform="translate(37.24 62.398)">
            <path id="Path_3093" data-name="Path 3093" d="M362.425,142.179h0a19.067,19.067,0,0,1-25.48-8.806h0a19.067,19.067,0,0,1,8.806-25.48h0a19.062,19.062,0,0,1,25.48,8.806h0a19.062,19.062,0,0,1-8.806,25.48Z" transform="translate(-200.484 -105.968)" fill="#307cd8" />
            <path id="Path_3094" data-name="Path 3094" d="M359.651,138.022a.469.469,0,0,0,.446-.577c-.047-.23-.174-.812-.22-1.051a.638.638,0,0,0-.469-.572,3.753,3.753,0,0,1-1.877-1.919,6.157,6.157,0,0,1,3.035-8.4c3.139-1.529,5.8-.469,7.037,2.111,1.637,3.373,1.529,6.944-.685,8.023a2.013,2.013,0,0,1-2.942-1.356c-.366-1.651-.469-3.58-.985-4.649a1.756,1.756,0,0,0-2.43-.99c-1.253.61-1.628,2.393-.784,4.124a5.011,5.011,0,0,0,1.271,1.67s1.361,6.043,1.6,7.1A14.828,14.828,0,0,0,364.769,146a.178.178,0,0,0,.314-.07,13.817,13.817,0,0,0,.249-4.851c-.108-.591-.741-3.617-.741-3.617a3.727,3.727,0,0,0,3.566.07c3.753-1.83,4.645-6.5,2.411-11.086-1.684-3.467-6.2-5.269-10.659-3.1-5.56,2.7-6.423,8.046-4.809,11.368C356.076,136.732,357.694,138.168,359.651,138.022Z" transform="translate(-210.757 -114.695)" fill="#fff" />
            <path id="Path_3095" data-name="Path 3095" d="M424.433,268.984h0a13.137,13.137,0,0,1-7.586-16.974h0a13.137,13.137,0,0,1,16.97-7.591h0a13.137,13.137,0,0,1,7.586,16.974h0a13.137,13.137,0,0,1-16.97,7.591Z" transform="translate(-243.458 -179.002)" fill="#91eb83" />
            <path id="Path_3096" data-name="Path 3096" d="M440.342,265.75a7.08,7.08,0,0,0-13.169-5.161c-.042.094-.08.192-.117.286a6.953,6.953,0,0,0-.347,3.753l-2.538,3.064,4.11.235a7.08,7.08,0,0,0,12-2A1.7,1.7,0,0,0,440.342,265.75Zm-8.783,3.157a5.911,5.911,0,0,1-2.707-2.083l-2.4-.136,1.478-1.783a5.851,5.851,0,0,1,.174-3.641c.07-.178.15-.357.235-.525a5.954,5.954,0,0,1,11.035,4.335c-.042.15-.089.3-.145.441A5.935,5.935,0,0,1,431.559,268.907Z" transform="translate(-247.807 -185.817)" fill="#fff" fillRule="evenodd" />
            <path id="Path_3097" data-name="Path 3097" d="M443.778,269.407c-.136-.141-.779-.835-.91-.938s-.225-.178-.394-.056-.624.361-.755.432-.235.047-.366-.094a4.733,4.733,0,0,1-1-1.3,5.161,5.161,0,0,1-.469-1.454c-.028-.2.084-.253.2-.3s.235-.127.352-.188l.089-.056a1.654,1.654,0,0,0,.174-.155.305.305,0,0,0,.1-.286c0-.094-.033-1.013-.047-1.384s-.169-.371-.263-.408-.2-.094-.3-.131a.643.643,0,0,0-.511.033,1.952,1.952,0,0,0-1.079,1.131,2.188,2.188,0,0,0-.127.577,3.876,3.876,0,0,0,.155,1.337,7.156,7.156,0,0,0,1.839,3.486c1.4,1.276,1.487,1.06,1.8,1.145a1.722,1.722,0,0,0,1.408-.347,1.408,1.408,0,0,0,.389-.727A2.121,2.121,0,0,0,443.778,269.407Z" transform="translate(-255.39 -189.352)" fill="#fff" fillRule="evenodd" />
            <path id="Path_3098" data-name="Path 3098" d="M58.286,145.552h0A10.026,10.026,0,0,1,48.26,135.526h0A10.026,10.026,0,0,1,58.286,125.5h0a10.026,10.026,0,0,1,10.026,10.026h0a10.026,10.026,0,0,1-10.026,10.026Z" transform="translate(-48.26 -116.336)" fill="#307cd8" />
            <path id="Path_3099" data-name="Path 3099" d="M58.25,145.3a6.24,6.24,0,0,0,9.59-5.536,4.494,4.494,0,0,0,1.084-1.121,4.527,4.527,0,0,1-1.262.347,2.205,2.205,0,0,0,.976-1.2,4.288,4.288,0,0,1-1.407.53,2.2,2.2,0,0,0-3.753,2,6.235,6.235,0,0,1-4.518-2.294,2.2,2.2,0,0,0,.68,2.928,2.158,2.158,0,0,1-.995-.272,2.2,2.2,0,0,0,1.759,2.177,2.191,2.191,0,0,1-.99.038,2.2,2.2,0,0,0,2.046,1.52A4.405,4.405,0,0,1,58.25,145.3Z" transform="translate(-53.563 -122.764)" fill="#fff" />
            <path id="Path_3100" data-name="Path 3100" d="M83.951,369.06h0a17.265,17.265,0,0,1-20.784-12.794h0a17.265,17.265,0,0,1,12.808-20.8h0a17.265,17.265,0,0,1,20.8,12.794h0a17.265,17.265,0,0,1-12.822,20.8Z" transform="translate(-55.923 -227.541)" fill="#455a64" />
            <path id="Path_3101" data-name="Path 3101" d="M86.165,352.727c2.458-.586,2.754-.647,3.753-.835A5.245,5.245,0,0,1,91.7,351.8a2.951,2.951,0,0,1,1.225.436,3.017,3.017,0,0,1,.938.9,5.264,5.264,0,0,1,.723,1.633c.277.962.357,1.253.938,3.711s.643,2.754.83,3.753a5.124,5.124,0,0,1,.089,1.783,2.815,2.815,0,0,1-.436,1.224,2.951,2.951,0,0,1-.891.938,5.305,5.305,0,0,1-1.637.723c-.938.277-1.248.357-3.706.938s-2.754.647-3.753.835a5.16,5.16,0,0,1-1.788.089,2.909,2.909,0,0,1-1.225-.436,2.853,2.853,0,0,1-.91-.924,5.189,5.189,0,0,1-.727-1.633c-.272-.962-.352-1.248-.938-3.706s-.647-2.754-.835-3.753a5.4,5.4,0,0,1-.089-1.788,3.115,3.115,0,0,1,1.332-2.163,5.24,5.24,0,0,1,1.633-.727c.938-.272,1.253-.352,3.711-.938m-.394-1.661c-2.5.6-2.815.68-3.786.962a6.858,6.858,0,0,0-2.135.938,4.86,4.86,0,0,0-2.05,3.331,6.967,6.967,0,0,0,.1,2.346c.192.99.253,1.309.849,3.81s.68,2.815.938,3.781a6.807,6.807,0,0,0,.938,2.135,4.649,4.649,0,0,0,1.478,1.412A4.6,4.6,0,0,0,84,370.42a6.939,6.939,0,0,0,2.346-.1c.99-.188,1.309-.253,3.81-.849s2.815-.68,3.781-.938a7.038,7.038,0,0,0,2.135-.938,4.649,4.649,0,0,0,1.379-1.45,4.724,4.724,0,0,0,.676-1.877,6.963,6.963,0,0,0-.108-2.346c-.188-.995-.253-1.309-.849-3.81s-.68-2.815-.938-3.786a7.084,7.084,0,0,0-.938-2.135,4.635,4.635,0,0,0-1.45-1.375,4.565,4.565,0,0,0-1.877-.676,6.939,6.939,0,0,0-2.346.1c-.995.192-1.309.253-3.81.849" transform="translate(-63.922 -235.553)" fill="#fff" />
            <path id="Path_3102" data-name="Path 3102" d="M92.874,361.58a4.861,4.861,0,1,0,5.855,3.6,4.86,4.86,0,0,0-5.855-3.6m1.877,7.8a3.158,3.158,0,1,1,1.962-1.416,3.158,3.158,0,0,1-1.962,1.416" transform="translate(-69.96 -241.586)" fill="#fff" />
            <path id="Path_3103" data-name="Path 3103" d="M107.3,357.288a1.131,1.131,0,1,1-.508-.709,1.135,1.135,0,0,1,.508.709" transform="translate(-78.413 -238.913)" fill="#fff" />
            <path id="Path_3104" data-name="Path 3104" d="M377.7,357h0a8.051,8.051,0,0,1-8.056-8.051h0A8.051,8.051,0,0,1,377.7,340.9h0a8.051,8.051,0,0,1,8.065,8.051h0A8.051,8.051,0,0,1,377.7,357Z" transform="translate(-218.86 -230.679)" fill="#455a64" />
            <path id="Path_3105" data-name="Path 3105" d="M383.027,357.431h1.961v-4.893h1.365l.15-1.642h-1.515v-.938c0-.385.08-.54.469-.54h1.065V347.71h-1.361c-1.459,0-2.116.643-2.116,1.877V350.9H382v1.637h1.018Z" transform="translate(-225.421 -234.294)" fill="#fff" />
          </g>
          <g id="freepik--Device--inject-5" transform="translate(89.842 42.858)">
            <path id="Path_3106" data-name="Path 3106" d="M239.148,254.674l-64.693-.023a14.075,14.075,0,0,1-14.075-14.075l.028-162.18a14.075,14.075,0,0,1,14.1-14.075l64.688.038a14.075,14.075,0,0,1,14.075,14.075l-.028,162.143a14.075,14.075,0,0,1-14.1,14.1Z" transform="translate(-160.38 -64.32)" fill="#263238" />
            <path id="Path_3107" data-name="Path 3107" d="M243.965,76.483H231.42a3.12,3.12,0,0,0-3.045,3.172V81.93a3.111,3.111,0,0,1-3.045,3.181H200.882a3.115,3.115,0,0,1-3.045-3.186v-2.29a3.125,3.125,0,0,0-3.045-3.186H179.4a9.852,9.852,0,0,0-9.876,9.852l-.033,158.3a9.852,9.852,0,0,0,9.852,9.852l64.566.038a9.852,9.852,0,0,0,9.852-9.852l.033-158.305A9.852,9.852,0,0,0,243.965,76.483Z" transform="translate(-165.216 -70.759)" fill="#fff" />
            <path id="Path_3108" data-name="Path 3108" d="M322,103.872c0,.042-1.633.089-3.641.1s-3.645,0-3.645-.042,1.628-.094,3.641-.108S322,103.829,322,103.872Z" transform="translate(-242.304 -85.288)" fill="#263238" />
            <path id="Path_3109" data-name="Path 3109" d="M322.026,107.84c0,.042-1.628.089-3.641.108s-3.645,0-3.645-.047,1.633-.089,3.645-.108S322.026,107.8,322.026,107.84Z" transform="translate(-242.32 -87.393)" fill="#263238" />
            <path id="Path_3110" data-name="Path 3110" d="M322.061,111.81c0,.042-1.633.094-3.645.108s-3.641,0-3.645-.047,1.633-.089,3.645-.108S322.061,111.767,322.061,111.81Z" transform="translate(-242.336 -89.501)" fill="#263238" />
            <path id="Path_3111" data-name="Path 3111" d="M183.995,107.19h.723l1.961-.047-.056.052c0-.432,0-.905-.023-1.407v-.816a.755.755,0,0,1,.657-.605h.938a.765.765,0,0,1,.643.755c0,.666,0,1.356.023,2.074l-.089-.089h2.472l-.094.094c0-.995,0-2.027-.028-3.082v-.784a.784.784,0,0,0-.347-.582l-1.079-1.07-1.046-1.027-.5-.5c-.174-.169-.31-.342-.511-.385a.671.671,0,0,0-.568.108c-.15.127-.31.3-.469.469l-.891.9c-.558.577-1.14,1.107-1.562,1.609a1.764,1.764,0,0,0-.122.938v3.172a2.6,2.6,0,0,1,0,.324,2.668,2.668,0,0,1,0-.3v-.887c0-.352,0-.877-.033-1.407v-1.361a1.023,1.023,0,0,1,.136-.516c.469-.558,1-1.056,1.567-1.651l.882-.905c.16-.155.286-.31.469-.469a.84.84,0,0,1,.718-.145,1.374,1.374,0,0,1,.6.427l.507.5,1.046,1.027,1.084,1.06a2.162,2.162,0,0,1,.277.3.812.812,0,0,1,.131.418q0,.394,0,.788c0,1.056,0,2.088.023,3.082v.094h-2.74v-2.163a.6.6,0,0,0-.5-.6h-.9a.605.605,0,0,0-.53.469V107.3h-2.571a1.074,1.074,0,0,1-.192-.113Z" transform="translate(-172.913 -83.061)" fill="#263238" />
            <path id="Path_3112" data-name="Path 3112" d="M255.476,379.272a3.917,3.917,0,0,1-.68.047l-1.849.038c-1.558.023-3.716.037-6.1.037s-4.542,0-6.1-.037l-1.849-.038a3.99,3.99,0,0,1-.68-.047,3.45,3.45,0,0,1,.68-.047l1.849-.042c1.558,0,3.716-.033,6.1-.033s4.537,0,6.1.033l1.849.042A3.4,3.4,0,0,1,255.476,379.272Z" transform="translate(-201.7 -231.443)" fill="#455a64" />
            <path id="Path_3113" data-name="Path 3113" d="M282.574,388.312c0,.07-9.932.122-22.182.122s-22.182-.052-22.182-.122,9.932-.122,22.182-.122S282.574,388.246,282.574,388.312Z" transform="translate(-201.695 -236.242)" fill="#455a64" />
            <path id="Path_3114" data-name="Path 3114" d="M268.71,396.862c0,.07-6.826.122-15.248.122s-15.252-.052-15.252-.122,6.826-.122,15.252-.122S268.71,396.778,268.71,396.862Z" transform="translate(-201.695 -240.781)" fill="#455a64" />
            <path id="Path_3115" data-name="Path 3115" d="M331.593,408.965l-1.379-1.384L328.9,408.89v-3.96h2.688Zm-1.379-1.558.042.042,1.215,1.22v-3.613h-2.444V408.6Z" transform="translate(-249.837 -245.128)" fill="#263238" />
            <path id="Path_3116" data-name="Path 3116" d="M253.8,409.447l-.469-.216a1.243,1.243,0,1,1,.375-.272Zm-1.131-2.637a1.262,1.262,0,0,0,.023,2.519,1.342,1.342,0,0,0,.605-.192h0l.361.16-.084-.352h0a1.257,1.257,0,0,0-.938-2.107Z" transform="translate(-208.748 -246.126)" fill="#263238" />
            <path id="Path_3117" data-name="Path 3117" d="M261.26,409.4l-.554-1.361-1.046-.938h2.533Zm-1.365-2.219.891.816.469,1.159.8-1.994Z" transform="translate(-213.081 -246.28)" fill="#263238" />
            <rect id="Rectangle_4523" data-name="Rectangle 4523" width="1.651" height="0.094" transform="translate(47.674 161.689) rotate(-33.05)" fill="#263238" />
            <path id="Path_3118" data-name="Path 3118" d="M244.391,406.952a1.734,1.734,0,0,1-2.306-.626.783.783,0,0,0-.786.649,1.065,1.065,0,0,0,.192.765,3.1,3.1,0,0,0,.554.582c.286.249.544.469.826.732.281-.253.516-.493.8-.746a3.1,3.1,0,0,0,.544-.591A1.042,1.042,0,0,0,244.391,406.952Z" transform="translate(-203.33 -245.869)" fill="#307cd8" />
            <path id="Path_3119" data-name="Path 3119" d="M255.673,392.425V390.7c0-1.131,0-2.815-.023-4.954,0-4.312-.023-10.584-.042-18.452l.08.08-75.024.042h0l.122-.122v25.133l-.117-.117,53.686.052,15.67.033h.07l-15.647.033-53.766.056h-.122V367.17h.131l75.024.042h.08v.08c0,7.887-.033,14.178-.042,18.5V392A.907.907,0,0,1,255.673,392.425Z" transform="translate(-171.092 -225.084)" fill="#263238" />
            <rect id="Rectangle_4524" data-name="Rectangle 4524" width="16.618" height="20.784" transform="matrix(0.006, -1, 1, 0.006, 12.582, 163.028)" fill="#ebebeb" />
            <path id="Path_3120" data-name="Path 3120" d="M207.855,376.323h-5.8l-15.013-.033.113-.108c-.023,4.95-.052,10.617-.08,16.613.023.033-.23-.23-.122-.122h5.653l5.4.033,9.73.07-.1.1c.047-4.992.089-9.13.122-12.039,0-1.445.033-2.58.042-3.373v15.511h-.1l-9.73-.038-5.4-.028h-5.653l-.127-.127c.042-6,.075-11.668.108-16.613v-.113h.113l15.06.136,4.222.056h1.393Z" transform="translate(-174.399 -229.803)" fill="#263238" />
            <rect id="Rectangle_4525" data-name="Rectangle 4525" width="14.525" height="19.325" transform="matrix(0.006, -1, 1, 0.006, 13.305, 161.986)" fill="#e0e0e0" />
            <path id="Path_3121" data-name="Path 3121" d="M188.72,399.389l6.8-8.989,4.147,5.672,2.853-3,5.522,6.432Z" transform="translate(-175.424 -237.415)" fill="#f5f5f5" />
            <path id="Path_3122" data-name="Path 3122" d="M212.48,386.389a1.52,1.52,0,1,1-.44-1.079,1.525,1.525,0,0,1,.44,1.079Z" transform="translate(-186.418 -234.474)" fill="#f5f5f5" />
            <path id="Path_3123" data-name="Path 3123" d="M237.364,146.759a.675.675,0,0,1-.225,0l-.633.023-2.346.042c-1.975,0-4.692.033-7.722.033s-5.747,0-7.722-.033l-2.346-.042-.638-.023a.629.629,0,0,1-.221,0,.816.816,0,0,1,.221-.023h.638l2.346-.038c1.975-.023,4.692-.038,7.722-.038s5.747,0,7.722.038l2.346.038h.633A.877.877,0,0,1,237.364,146.759Z" transform="translate(-189.645 -108.029)" fill="#263238" />
            <path id="Path_3124" data-name="Path 3124" d="M323,335.871l-2.046-2.055L319,335.763V329.88h4Zm-2.046-2.308.061.061,1.806,1.811v-5.367h-3.641v5.264Z" transform="translate(-244.581 -205.289)" fill="#263238" />
            <path id="Path_3125" data-name="Path 3125" d="M211.359,335.464l-.938-.427a2.421,2.421,0,1,1,.737-.53Zm-2.238-5.184a2.477,2.477,0,1,0,.042,4.954,2.575,2.575,0,0,0,1.192-.375l.042-.023.7.314-.16-.694.033-.033a2.472,2.472,0,0,0-1.853-4.143Z" transform="translate(-184.95 -205.501)" fill="#263238" />
            <path id="Path_3126" data-name="Path 3126" d="M226.223,335.263l-1.1-2.679-2.055-1.877,4.992-.038Zm-2.688-4.377,1.755,1.609.938,2.28,1.567-3.922Z" transform="translate(-193.658 -205.708)" fill="#263238" />
            <rect id="Rectangle_4526" data-name="Rectangle 4526" width="3.247" height="0.183" transform="translate(31.583 126.723) rotate(-33.05)" fill="#263238" />
            <path id="Path_3127" data-name="Path 3127" d="M193.019,330.592a1.544,1.544,0,0,0-3.045.1,1.544,1.544,0,0,0-3.045-.052,2.088,2.088,0,0,0,.38,1.5,6.336,6.336,0,0,0,1.093,1.149c.558.469,1.07.938,1.628,1.44.544-.5,1.013-.966,1.562-1.469a6.32,6.32,0,0,0,1.074-1.164A2.093,2.093,0,0,0,193.019,330.592Z" transform="translate(-174.464 -205.006)" fill="#307cd8" />
            <circle id="Ellipse_487" data-name="Ellipse 487" cx="3.387" cy="3.387" r="3.387" transform="translate(14.713 35.342)" fill="#ebebeb" />
            <path id="Path_3128" data-name="Path 3128" d="M253.057,301.112c0,.07-14.2.127-31.706.127s-31.711-.056-31.711-.127,14.2-.122,31.711-.122S253.057,301.046,253.057,301.112Z" transform="translate(-175.912 -189.953)" fill="#455a64" />
            <path id="Path_3129" data-name="Path 3129" d="M253.057,289.862c0,.066-14.2.122-31.706.122s-31.711-.056-31.711-.122,14.2-.122,31.711-.122S253.057,289.8,253.057,289.862Z" transform="translate(-175.912 -183.981)" fill="#455a64" />
            <path id="Path_3130" data-name="Path 3130" d="M224.156,312.522c0,.066-7.727.122-17.256.122s-17.261-.056-17.261-.122,7.727-.122,17.261-.122S224.156,312.456,224.156,312.522Z" transform="translate(-175.912 -196.01)" fill="#455a64" />
            <path id="Path_3131" data-name="Path 3131" d="M251.083,433.735a1.356,1.356,0,1,1-.385-.966,1.351,1.351,0,0,1,.385.966Z" transform="translate(-207.094 -259.689)" fill="#263238" />
            <path id="Path_3132" data-name="Path 3132" d="M259.953,433.775a1.351,1.351,0,1,1-.384-.967,1.347,1.347,0,0,1,.384.967Z" transform="translate(-211.808 -259.71)" fill="#263238" />
            <path id="Path_3133" data-name="Path 3133" d="M268.883,433.815a1.356,1.356,0,1,1-.385-.966A1.347,1.347,0,0,1,268.883,433.815Z" transform="translate(-216.548 -259.732)" fill="#263238" />
            <rect id="Rectangle_4527" data-name="Rectangle 4527" width="75.033" height="45.884" transform="translate(9.998 45.429)" fill="#e0e0e0" />
            <path id="Path_3134" data-name="Path 3134" d="M196.46,226.418l21.5-28.778,13.334,18,9.031-9.627,17.72,20.39Z" transform="translate(-179.533 -135.091)" fill="#f5f5f5" />
            <path id="Path_3135" data-name="Path 3135" d="M271.762,184.626a4.851,4.851,0,1,1-4.851-4.846A4.851,4.851,0,0,1,271.762,184.626Z" transform="translate(-214.355 -125.61)" fill="#f5f5f5" />
            <path id="Path_3136" data-name="Path 3136" d="M256.187,232.259c0-.981-.047-42.431-.113-103.8l.113.113H181.163l.122-.122v103.8l-.1-.1,75.033.1-75.033.1h-.1v-.1c0-29.055,0-64.979-.023-103.8v-.122h75.268v.108c-.023,30.716-.042,56.675-.056,74.963,0,9.13-.023,16.341-.028,21.277v7.075A1.849,1.849,0,0,1,256.187,232.259Z" transform="translate(-171.358 -98.299)" fill="#263238" />
          </g>
          <g id="freepik--Megaphone--inject-5" transform="translate(59.186 70.418)">
            <path id="Path_3137" data-name="Path 3137" d="M231.628,348.472l1.661-5.578c.291-1.21.676-2.637,1.192-4.265a11.564,11.564,0,0,1,2.749-4.11c1.694-1.722,2.5-1.731,3.636-2.646.1-.066.221-.164.319-.216a1.787,1.787,0,0,0,.554-.469c.981-1.06.038-2.707-1.956-2.548-1.5.117-3.946.544-7.924,4.649-.052.066-.1.117-.15.178-2.088,2.716-4.049-5.034-4.405-7.919a3.017,3.017,0,0,0-4.086-2.346l-9.693,4.532a4.536,4.536,0,0,0-2.815.094,2.89,2.89,0,0,0-1.848,3.42l3.936,15.675L201.25,385.348h24.889Z" transform="translate(-151.419 -229.193)" fill="#ffbe9d" />
            <path id="Path_3138" data-name="Path 3138" d="M257.384,295.965l-7.694,15.079-3.847-1.07L226.513,325.7a3.913,3.913,0,0,1-5.438-.469l-7.366-8.553S228.559,310.288,231.3,305s-.427-6.5-.427-6.5l6.986-7.938Z" transform="translate(-158.034 -211.977)" fill="#307cd8" />
            <path id="Path_3139" data-name="Path 3139" d="M184.41,176.255s-11.26-2.491-27.113-17.73S134,123.033,132.084,123.146s-10.411-3.833-26.522,31.209-9.763,43.163-6.568,43.116,24.969-6.132,38.565-4.588,31.073,8.633,31.073,8.633Z" transform="translate(-95.038 -123.063)" fill="#307cd8" />
            <path id="Path_3140" data-name="Path 3140" d="M288.851,248.081l-3.842,7.3-1.722,3.284a9.533,9.533,0,0,1-12.428,4.222l-24.138-8.412,14.075-32.091,24.115,12.85a9.533,9.533,0,0,1,3.941,12.841Z" transform="translate(-175.556 -175.789)" fill="#307cd8" />
            <g id="Group_5767" data-name="Group 5767" transform="translate(71.163 46.601)" opacity="0.4">
              <path id="Path_3141" data-name="Path 3141" d="M288.851,248.081l-3.842,7.3-1.722,3.284a9.533,9.533,0,0,1-12.428,4.222l-24.138-8.412,14.075-32.091,24.115,12.85a9.533,9.533,0,0,1,3.941,12.841Z" transform="translate(-246.72 -222.39)" />
            </g>
            <path id="Path_3142" data-name="Path 3142" d="M109.217,155.891c-8.572,18.982-12.142,35.9-7.976,37.782s14.5-11.978,23.073-30.965,12.142-35.9,7.976-37.782S117.788,136.908,109.217,155.891Z" transform="translate(-97.407 -123.961)" fill="#307cd8" />
            <g id="Group_5768" data-name="Group 5768" transform="translate(2.094 0.793)" opacity="0.5">
              <path id="Path_3143" data-name="Path 3143" d="M109.217,155.891c-8.572,18.982-12.142,35.9-7.976,37.782s14.5-11.978,23.073-30.965,12.142-35.9,7.976-37.782S117.788,136.908,109.217,155.891Z" transform="translate(-99.501 -124.754)" />
            </g>
            <path id="Path_3144" data-name="Path 3144" d="M333.535,288.619l-6.545-2.055,4.049-7.694,4.907,3.05Z" transform="translate(-218.167 -205.771)" fill="#307cd8" />
            <g id="Group_5769" data-name="Group 5769" transform="translate(108.823 73.099)" opacity="0.6">
              <path id="Path_3145" data-name="Path 3145" d="M331.039,278.87l-4.049,7.694,1.346.422,3.289-7.755Z" transform="translate(-326.99 -278.87)" />
            </g>
            <path id="Path_3146" data-name="Path 3146" d="M309.887,275.362c-.094.211-3.359-1.056-7.291-2.815s-7.037-3.392-6.948-3.6,3.359,1.06,7.3,2.838S309.985,275.151,309.887,275.362Z" transform="translate(-201.528 -200.49)" fill="#307cd8" />
            <path id="Path_3147" data-name="Path 3147" d="M315.784,265.7c-.1.206-3.059-1.037-6.6-2.782s-6.329-3.326-6.226-3.537,3.054,1.037,6.6,2.782S315.887,265.492,315.784,265.7Z" transform="translate(-205.408 -195.415)" fill="#307cd8" />
            <path id="Path_3148" data-name="Path 3148" d="M305.909,286.327c-.033.113-.812-.066-2.05-.469-.619-.192-1.356-.436-2.168-.713s-1.642-.718-2.538-1.126c-3.58-1.661-6.315-3.284-6.226-3.481s3.031,1.074,6.568,2.721c.891.4,1.717.844,2.5,1.168s1.478.61,2.064.873C305.229,285.839,305.942,286.214,305.909,286.327Z" transform="translate(-200.084 -206.645)" fill="#307cd8" />
            <path id="Path_3149" data-name="Path 3149" d="M138.584,180.556s-9.787-6.071-15.14,7.155,4.94,15.4,4.94,15.4A88.029,88.029,0,0,0,138.584,180.556Z" transform="translate(-109.298 -152.844)" fill="#307cd8" />
            <g id="Group_5770" data-name="Group 5770" transform="translate(11.631 11.948)" opacity="0.5">
              <path id="Path_3150" data-name="Path 3150" d="M147.9,148.53a14.316,14.316,0,0,1-.38,2.529c-.3,1.619-.751,3.96-1.379,6.84l-1.361,4.631q-.371,1.243-.765,2.576l-.4,1.347c-.145.469-.338.9-.507,1.351l-2.21,5.705c-.192.5-.389.995-.582,1.5s-.469.981-.69,1.478l-1.454,3-1.459,3c-.249.469-.469.995-.737,1.454s-.549.938-.816,1.384l-3.12,5.269c-.253.413-.469.84-.751,1.234l-.812,1.149-1.558,2.2L126.1,199.1c-1.877,2.266-3.444,4.054-4.523,5.3a15.374,15.374,0,0,1-1.75,1.877,15.161,15.161,0,0,1,1.5-2.074c1.042-1.328,2.51-3.186,4.312-5.47l2.7-3.955,1.511-2.2c.263-.375.525-.76.793-1.149s.469-.816.727-1.234l3.082-5.264.8-1.379c.277-.469.469-.938.727-1.445l1.445-2.979c.469-1.009.971-2,1.45-2.979.225-.469.5-.966.685-1.464l.586-1.483,2.243-5.63c.174-.469.371-.887.521-1.337s.277-.891.413-1.332l.8-2.548,1.44-4.574,1.628-6.756a15.146,15.146,0,0,1,.7-2.487Z" transform="translate(-119.83 -148.53)" />
            </g>
            <g id="Group_5771" data-name="Group 5771" transform="translate(58.013 90.225)" opacity="0.4">
              <path id="Path_3151" data-name="Path 3151" d="M218.69,328.8a18.926,18.926,0,0,0,3.125,4.565,6.444,6.444,0,0,0,5,2,3.913,3.913,0,0,0,3.608-3.566,2.307,2.307,0,0,0,2.97-.525,5.181,5.181,0,0,0,1.056-3.082,2.908,2.908,0,0,0,3.674-.99,4.757,4.757,0,0,0,.469-4.016,14.155,14.155,0,0,0-1.928-3.692,5.1,5.1,0,0,0,2.731-.277,1.744,1.744,0,0,0,.905-2.3,2.266,2.266,0,0,0-1.346-.859,8.5,8.5,0,0,0-3.974-.643c-.347,0,.605,3.979-8.858,9.411C220.5,328.044,218.69,328.8,218.69,328.8Z" transform="translate(-218.69 -315.373)" />
            </g>
            <path id="Path_3152" data-name="Path 3152" d="M253.238,355.945c0,.042-.633-.08-1.656-.052a9.383,9.383,0,0,0-6.953,3.336c-.662.779-.938,1.346-1,1.323a1.445,1.445,0,0,1,.183-.413,8.217,8.217,0,0,1,.68-1.023,8.914,8.914,0,0,1,7.089-3.4,7.153,7.153,0,0,1,1.22.113,1.282,1.282,0,0,1,.436.113Z" transform="translate(-173.916 -246.565)" fill="#eb996e" />
            <path id="Path_3153" data-name="Path 3153" d="M220,329.162c1.525,1.464,2.566,3.082,4.166,4.556.727.671,1.877,1.938,2.815,2.107s1.6.042,1.98-.868c.314-.737-.249-1.745-.633-2.346-1.018-1.6-1.994-2.848-2.754-4.091-.361-.582.1-.7.272-.432.732.76,1.83,1.407,2.487,2.247.9,1.131,1.06,2.637,3.415,2.158a2.054,2.054,0,0,0,.873-.361,2.358,2.358,0,0,0,.554-2.815,7.658,7.658,0,0,0-1.314-1.98c1,1.131,2.1,2.346,3.692,2.449a2.191,2.191,0,0,0,2.229-1.206c.469-1.07-.2-2.107-.882-2.942a30.811,30.811,0,0,0-4-4.091,3.064,3.064,0,0,0-1.942-.718,2.775,2.775,0,0,0-2.346.863,3.214,3.214,0,0,0-.643,2.454,4.074,4.074,0,0,0-3.725.047,5.283,5.283,0,0,0-2.074,2.984Z" transform="translate(-161.373 -228.045)" fill="#ffbe9d" />
            <path id="Path_3154" data-name="Path 3154" d="M242.771,314.795a2.477,2.477,0,0,1-.291-2.815c.352-.69.793-1.361,1.243-1.511,1.966-.662,10.561.826,10.481,2.731s-.708,2.815-5.367,2.144c-.821-.117-2.107-.277-2.107-.277l-1.126,1.689Z" transform="translate(-173.142 -222.46)" fill="#ffbe9d" />
            <path id="Path_3155" data-name="Path 3155" d="M241.374,332.033a11.208,11.208,0,0,0-4.734-4.293,2.73,2.73,0,0,1,.91.319,8.168,8.168,0,0,1,3.416,3.1,2.622,2.622,0,0,1,.408.877Z" transform="translate(-170.206 -231.713)" fill="#eb996e" />
            <path id="Path_3156" data-name="Path 3156" d="M246.609,322.5c-.042.038-.61-.422-1.384-.9a10.062,10.062,0,0,0-1-.558c-.267-.122-.446-.155-.441-.192s.2-.056.5.033a4.553,4.553,0,0,1,1.079.511A3.26,3.26,0,0,1,246.609,322.5Z" transform="translate(-173.996 -228.041)" fill="#eb996e" />
            <path id="Path_3157" data-name="Path 3157" d="M249.462,319.319a3.673,3.673,0,0,1-1.656-.188,3.06,3.06,0,0,1-1.06-.572c-.235-.2-.333-.366-.31-.389a7.037,7.037,0,0,0,1.44.713,12.346,12.346,0,0,1,1.586.436Z" transform="translate(-175.404 -226.633)" fill="#eb996e" />
            <path id="Path_3158" data-name="Path 3158" d="M227.366,337.084a6.1,6.1,0,0,1-.784-1.018,9.115,9.115,0,0,0-2-2.28,4.03,4.03,0,0,0-2.843-.751,4.823,4.823,0,0,0-1.2.38,1.234,1.234,0,0,1,.277-.216,2.618,2.618,0,0,1,.891-.338,4.007,4.007,0,0,1,3.012.727,8.127,8.127,0,0,1,2.008,2.379A7.639,7.639,0,0,1,227.366,337.084Z" transform="translate(-161.659 -234.405)" fill="#eb996e" />
            <path id="Path_3159" data-name="Path 3159" d="M241.122,382.42a26.263,26.263,0,0,1-3.509.5,25.438,25.438,0,0,1-3.542.263,52.367,52.367,0,0,1,7.052-.765Z" transform="translate(-168.841 -260.739)" fill="#eb996e" />
          </g>
          <g id="freepik--Stars--inject-5" transform="translate(37.451 36.065)">
            <path id="Path_3160" data-name="Path 3160" d="M404.937,49.84l.53.971.966.53-.966.53-.53.966-.53-.966-.966-.53.966-.53Z" transform="translate(-237.014 -49.84)" fill="#307cd8" />
            <path id="Path_3161" data-name="Path 3161" d="M428.541,101.01l.53.971.966.53-.966.53-.53.966-.53-.966-.971-.53.971-.53Z" transform="translate(-249.542 -77.003)" fill="#307cd8" />
            <path id="Path_3162" data-name="Path 3162" d="M50.207,237.53l.53.971.971.53-.971.525-.53.971-.53-.971-.966-.525.966-.53Z" transform="translate(-48.71 -149.473)" fill="#307cd8" />
            <path id="Path_3163" data-name="Path 3163" d="M371.748,62.56l1.224,2.238,2.238,1.225-2.238,1.22-1.224,2.238-1.22-2.238-2.238-1.22,2.238-1.225Z" transform="translate(-218.355 -56.592)" fill="#307cd8" />
            <path id="Path_3164" data-name="Path 3164" d="M69.153,205.1l1.22,2.229,2.233,1.22-2.233,1.225L69.153,212l-1.22-2.229L65.7,208.548l2.233-1.22Z" transform="translate(-57.729 -132.258)" fill="#307cd8" />
            <path id="Path_3165" data-name="Path 3165" d="M432.753,318.56l1.22,2.233,2.229,1.22-2.229,1.22-1.22,2.233-1.22-2.233-2.233-1.22,2.233-1.22Z" transform="translate(-250.741 -192.486)" fill="#307cd8" />
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
