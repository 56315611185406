import React, {FC} from "react";
import {Button, Modal} from "../../components";
import {EmailSentSvg} from "../../components/svgs";
import {ToastService} from "../../services";

interface IExportEmailSentModalProps {
  onClose: () => void;
}

export const ExportEmailSentModal: FC<IExportEmailSentModalProps> = ({
  onClose,
}) => {

  const onClickDone = () => {
    onClose();
    ToastService.success('Your download request was successfully accepted. You will get email with download link');
  };

  return (
    <Modal
      title="EMAIL SENT"
      wrapperClass="!max-w-[736px]"
      hasCloseButton
      footerComponent={(
        <>
          <Button
            data-cy="modal-button-ok"
            className="ml-auto text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onClickDone}
          >
            Ok
          </Button>
        </>
      )}
      onClose={() => onClose()}
    >
      <div className="w-fit mt-10 mx-auto flex flex-col items-center">
        <EmailSentSvg />
        <p className="mb-[14px] text-28p font-bold text-blue">You’ve got mail!</p>
        <p className="mb-2 text-15p text-body text-center whitespace-pre">
          {'We have just sent you an email that contains your\n exported list of items.'}
        </p>
      </div>
    </Modal>
  );
};
