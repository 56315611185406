import React, {FC, useState} from "react";
import {Link} from "react-router-dom";
import {Button} from "../../../../components";
import {LogoIcon, LogoTextIcon} from "../../../../components/icons";
import {ROUTES} from "../../../../constants";
import {ISetupPaymentForm} from "../index";
import {BillingServiceForm, IBillingServiceFormInitParams} from "../../../../parts";

export interface ICompanyDetailProps {
  data: ISetupPaymentForm;
  onNext(): void;
  onSubmit(data: Partial<ISetupPaymentForm>): void;
}

const CompanyDetail: FC<ICompanyDetailProps> = ({
  data, onNext, onSubmit,
}) => {
  const [serviceForm, setServiceForm] = useState<IBillingServiceFormInitParams>({
    getFormData: () => null,
  });

  const onContinue = () => {
    const data = serviceForm.getFormData();
    if (data) {
      onSubmit(data);
      onNext();
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>
      <h1 className="text-4xl text-primary font-bold mt-4">Let's set up your prepaid account</h1>
      <p className="text-lg text-blue-dark font-medium mt-2">Please give us your company details</p>

      <BillingServiceForm className="mt-8" data={data} fullWidth onInit={setServiceForm} />

      <div className="flex items-center mt-auto">
        <Link className="text-primary text-sm hover:underline uppercase" to={ROUTES.DASHBOARD}>Skip for now</Link>
        <Button color="primary" className="text-sm rounded-full shadow-md px-8 ml-auto" onClick={onContinue}>Next</Button>
      </div>
    </div>
  );
};

export default CompanyDetail;
