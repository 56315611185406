import React, { FC } from "react";

interface INumberTileProps {
    value: string;
}

export const NumberTile: FC<INumberTileProps> = ({
    value
}) => {
    return (
        <div className="rounded-md bg-blue-100 min-h-[40px] min-w-[40px] grid place-items-center">
            <span className="text-blue !text-xl !font-bold">{value}</span>
        </div>
    )
}