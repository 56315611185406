import React, {FC, useState} from "react";
import classnames from "classnames";
import {Slider} from "@mui/material";
import {IWhatsAppSetupFormData} from "../index";
import ActivateOtpMode from "./ActivateOtpMode";
import FollowStep1 from "./Step1";
import FollowStep2 from "./Step2";
import FollowStep3 from "./Step3";
import FollowStep4 from "./Step4";
import FollowStep5 from "./Step5";

export interface IFollowStepProps {
  formData: IWhatsAppSetupFormData;
  onBack(): void;
  onFinish(): void;
}

const FollowStep: FC<IFollowStepProps> = ({
  formData, onBack, onFinish,
}) => {
  const [step, setStep] = useState<number>(1);

  const gotoPrevStep = () => {
    if (step === 1) {
      onBack();
    } else {
      setStep(step - 1);
    }
  };

  const gotoNextStep = () => {
    if (step === 6) {
      // ToastService.success(<><span className="text-success mr-1">Successfully</span> created your number</>);
      onFinish();
    } else {
      setStep(step + 1);
    }
  };

  return (
    <>
      <div className="w-full h-15 flex-center items-center bg-gray-ef rounded-t-md px-6">
        {step > 1 && (
          <>
            <Slider
              className={classnames('w-grow readonly', step < 6 ? 'slider-primary' : 'slider-success')}
              min={0}
              max={5}
              value={step - 1}
              disabled
            />
            <span className="flex-shrink-0 text-xs font-semibold ml-4">
              {step < 6 ? `Step ${step - 1} of 5` : 'Completed'}
            </span>
          </>
        )}
      </div>

      <div className="h-0 flex-grow overflow-auto">
        {step === 1 && (
          <ActivateOtpMode formData={formData} onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
        {step === 2 && (
          <FollowStep1 onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
        {step === 3 && (
          <FollowStep2 onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
        {step === 4 && (
          <FollowStep3 onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
        {step === 5 && (
          <FollowStep4 onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
        {step === 6 && (
          <FollowStep5 onBack={gotoPrevStep} onNext={gotoNextStep} />
        )}
      </div>
    </>
  );
};

export default FollowStep;
