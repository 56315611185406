import classnames from "classnames";
import React, { FC, ReactElement, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { IAbstractInputControlProps, InputWrapper } from "../ReactiveForm";
import { CloudUploadIcon, ImageIcon, TrashIcon } from "../icons";

export interface IStyledDropzoneProps {
  default_files?: File[];
  accept?: string[];
  minSize?: number;
  maxSize?: number;
  placeholder?: string | ReactElement;
  showFileList?: boolean;
  listContainerClass?: string;
  color?: string;
  onRemove?: () => void;
  onFilesDetected?: (res?: boolean) => void;
  icon?: ReactElement;
  border?: boolean;
}

export const StyledDropzone: FC<IStyledDropzoneProps & IAbstractInputControlProps> = ({
  default_files,
  accept,
  minSize,
  maxSize,
  placeholder = '',
  showFileList = true,
  listContainerClass,
  color = 'blue',
  disabled,
  icon,
  border = true,
  onChange = () => { },
  onRemove = () => { },
  onFilesDetected = () => { },
  ...wrapperProps
}) => {
  const [files, setFiles] = useState<File[]>(default_files);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop: acceptedFiles => {
      const new_files = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setFiles(new_files);
      onChange(new_files);
    }
  })

  const onDeselectFile = async (e, file) => {
    const new_files = files.filter(f => f !== file);

    e.preventDefault();
    e.stopPropagation();

    setFiles(new_files);
    onChange(new_files);
  };

  React.useEffect(() => {
    if (files?.length) {
      onFilesDetected(true);
      return;
    }
    onFilesDetected(false);
  }, [files])

  const FileList = (
    <div className={classnames(listContainerClass, `p-3 rounded-lg bg-gray-f8 my-4 border border-${color}`)}>
      {files.map((file, i) => (
        <div key={i} className={classnames('flex items-center mt-1', { 'text-gray': disabled })}>
          <div className={classnames(`flex-grow text-sm font-bold truncate mr-3 text-${color}`)}>{file.name}</div>
          <button
            className={classnames(
              'w-6 h-6 flex-center flex-shrink-0 rounded ml-auto',
              disabled ? 'bg-gray-f6' : 'bg-[#f0e2e2] cursor-pointer'
            )}
            disabled={disabled}
            onClick={(e) => onDeselectFile(e, file)}
          >
            <TrashIcon color={disabled ? '#888' : 'danger'} size={10} onClick={onRemove} />
          </button>
        </div>
      ))}
    </div>
  )

  const Dropbox = (
    <>
      <InputWrapper disabled={disabled} {...wrapperProps}>
        {({ containerClass, className, disabled, onBlur }) => (
          <div
            className={classnames(`MuiInput-root cursor-default ${border && 'border'} border-${color} !rounded-xl`, containerClass, className)}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="MuiInput-input" onClick={open}>
              <div className="w-full">
                {placeholder || (
                  <div className={classnames(
                    'flex-center flex-col',
                    disabled ? 'text-secondary' : `text-${color} cursor-pointer`
                  )}>
                    {icon &&
                      icon
                    }
                    {!icon &&
                      <CloudUploadIcon size={56} color={color} />
                    }
                    <p className="text-center text-base">
                      <span className="font-semibold">Drag and drop</span> files to upload, or <span className="font-semibold">click to select</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </InputWrapper>
    </>
  )

  return (
    <>
      {files?.length ? FileList : <></>}
      {!files?.length ? Dropbox : <></>}
    </>
  );
};

