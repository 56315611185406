import React, {FC} from "react";
import {Button} from "../../../../components";
import {ArrowLeftIcon, LogoIcon, LogoTextIcon} from "../../../../components/icons";
import {PaymentCompleteSvg} from "../../../../components/svgs";

export interface IPaymentCompleteProps {
  onBack(): void;
  onNext(): void;
}

const PaymentComplete: FC<IPaymentCompleteProps> = ({
  onBack, onNext,
}) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>
      <h1 className="text-4xl text-primary font-bold mt-4">Payment Complete!</h1>
      <p className="text-lg text-blue-dark font-medium mt-2">Your account has been pre-funded, you can now continue with the set up</p>

      <div className="flex-center my-8">
        <PaymentCompleteSvg />
      </div>

      <div className="flex items-center mt-auto">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>Continue</Button>
      </div>
    </div>
  );
};

export default PaymentComplete;
