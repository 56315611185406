import React, {useEffect, useState} from "react";
import {Button} from "../../../components";
import {LogoIcon, LogoTextIcon} from "../../../components/icons";
import AuthLayout from "../../../layouts/AuthLayout";
import {useHistory, useParams} from "react-router";
import {AuthService, ToastService} from "../../../services";
import {ROUTES} from "../../../constants";
import {Form, FormControl, Input, useForm, Validators} from "../../../components/ReactiveForm";
import PasswordStrengthBar from "react-password-strength-bar";
import {Link} from "react-router-dom";

enum VERIFY {
  SUCCESS,
  FAILURE,
  PASSWORD_REQUIRED,
}

const ActivateAccount = () => {
  const history = useHistory();
  const { uid64, token } = useParams<{ uid64: string, token: string }>();
  const [verifyStatus, setVerifyStatus] = useState<VERIFY>();
  const [passwordScore, setPasswordScore] = useState(0);
  const [form, formData] = useForm<{
    password: FormControl;
    confirmPassword: FormControl;
  }>({
    password: new FormControl('', [Validators.required()]),
    confirmPassword: new FormControl('', [Validators.required()]),
  });

  useEffect(() => {
    const confirmPasswordValidator = ({ value }: FormControl) => {
      if (form.controls.password.value && value && form.controls.password.value !== value)
        return `Passwords do not match.`;
      return null;
    };
    form.controls.confirmPassword.validators.push(confirmPasswordValidator);
  }, [form]);

  useEffect(() => {
    form.controls.password.setOptions({
      validators: [
        ({ value }: FormControl) => {
          if (!value) {
            return `This field is required.`;
          }
          if (passwordScore < 2) {
            return `Please input strong password.`;
          }
          return null;
        },
      ],
    });
  }, [form, passwordScore]);

  useEffect(() => {
    AuthService.verify(uid64, token).then(() => {
      setVerifyStatus(VERIFY.SUCCESS);
      history.replace(ROUTES.DASHBOARD);
    }).catch((err) => {
      if(JSON.stringify(err.response.data.non_field_errors).includes("Password"))
        setVerifyStatus(VERIFY.PASSWORD_REQUIRED);
      else
        setVerifyStatus(VERIFY.FAILURE);
      ToastService.showHttpError(err, 'Verify token failed');
    });
  }, []);

  const onSetPassword = (formData) => {
    const { password, confirmPassword } = formData;
    AuthService.verify(uid64, token, password).then(() => {
      setVerifyStatus(VERIFY.SUCCESS);
      history.replace(ROUTES.DASHBOARD);
    }).catch((err) => {
      setVerifyStatus(VERIFY.FAILURE);
      ToastService.showHttpError(err, 'Verify token failed');
    });
  };

  const PasswordForm = (
      <Form className="w-140 max-w-[95vw] bg-white shadow-md rounded-md px-8 py-4 mt-20" formGroup={form} onSubmit={onSetPassword}>
        <p className="text-blue-dark font-bold mb-4">Please enter your new password</p>

        <Input
          control={form.controls.password}
          type="password"
          placeholder="Password"
          fullWidth
        />
        <Input
          control={form.controls.confirmPassword}
          type="password"
          containerClass="mt-4"
          placeholder="Confirm password"
          fullWidth
        />
        <PasswordStrengthBar
          className="mt-2"
          password={formData?.password || ''}
          onChangeScore={setPasswordScore}
        />

        <div className="flex items-center mt-6">
          <Link className="ml-auto" to={ROUTES.AUTH.LOGIN}>
            <Button type="button" className="text-primary text-sm">Cancel</Button>
          </Link>
          <Button type="submit" color="primary" className="text-sm rounded-full shadow-md px-4 ml-2">Set Password</Button>
        </div>
      </Form>
  )

  const Failure = (
    <>
      <p className="text-danger font-semibold text-lg mt-6">Verifying account has been failed.</p>
      <Button color="primary" className="w-full rounded-full mt-8">Resend code</Button>
    </>
  )

  const Success = (
    <>
      <h1 className="text-40p text-primary font-bold mt-4">Activate account</h1>
      <p className="text-blue font-semibold text-lg mt-6">Congratulations! Your account has been activate.</p>
    </>
  )

  const Waiting = (
    <>
      <h1 className="text-40p text-primary font-bold mt-4">Activate account</h1>
      <p className="text-blue font-semibold text-lg mt-6">Just a moment, we are verifying your account...</p>
    </>
  )

  return (
    <AuthLayout contentClass="w-120 min-h-[60vh]">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>

      {verifyStatus === undefined && (Waiting)}
      {verifyStatus === VERIFY.SUCCESS && (Success)}
      {verifyStatus === VERIFY.FAILURE && (Failure)}
      {verifyStatus === VERIFY.PASSWORD_REQUIRED && (PasswordForm)}
    </AuthLayout>
  );
};

export default ActivateAccount;
