export enum SMS_CONTEXT {
  SEND_SMS = 'send_sms',
  CALL_TRACKING = 'call_tracking',
}

export enum SMS_DIRECTION {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum SMS_TAB {
  RECEIVED = 'Received',
  SENT = 'Sent',
  BLOCKED = 'Blocked',
}

export enum SMS_STATUS {
  SENT = 'Sent',
  DELIVERED = 'Delivered',
  RECEIVED = 'Received',
  FAILED = 'Failed',
  BLOCKED = 'Blacklisted',
  BLOCKED_SENDER = 'Blacklisted Sender',
  BLOCKED_KEYWORD = 'Blacklisted Keyword',
}
