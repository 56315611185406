import { combineReducers } from "redux";
import authReducer, { IAuthState } from "./auth.reducer";
import callLogReducer, { ICallLogState } from "./call-log.reducer";
import commonReducer, { ICommonState } from "./common.reducer";
import messageBoxReducer, { IMessageBoxState } from "./message-box.reducer";
import smsLogReducer, { ISmsLogState } from "./sms-log.reducer";
import spinnerReducer, { ISpinnerState } from "./spinner.reducer";
import toastReducer, { IToastState } from "./toast.reducer";

export interface IRootState {
  authReducer: IAuthState,
  callLogReducer: ICallLogState;
  commonReducer: ICommonState;
  messageBoxReducer: IMessageBoxState;
  smsLogReducer: ISmsLogState;
  spinnerReducer: ISpinnerState;
  toastReducer: IToastState;
}

const rootReducer = combineReducers<IRootState>({
  authReducer,
  callLogReducer,
  commonReducer,
  messageBoxReducer,
  smsLogReducer,
  spinnerReducer,
  toastReducer,
});

export default rootReducer;
