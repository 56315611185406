import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import ProjectForm, { IProjectFormInitParams } from '../ProjectForm';
import { Button } from '../../../../components';
import { CloseCircleIcon } from '../../../../components/icons';
import { ROUTES } from '../../../../constants';
import { getActiveOrganization } from '../../../../redux/selectors';
import { AuthService, ProjectService, RelationshipService, ToastService, UserService } from '../../../../services';
import {setOrganizations} from '../../../../redux/actions';
import { UserModel } from '../../../../utils/types';
import { ROLE } from '../../../../utils/enums';

const ProjectCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useSelector(getActiveOrganization);
  const [users, setUsers] = useState<{ user: UserModel, roles: ROLE[] }[]>([]);
  const [createForm, setCreateForm] = useState<IProjectFormInitParams>({
    getFormData: () => null,
  });

  const onClose = useCallback(() => {
    history.replace(ROUTES.SETTINGS.PROJECTS.INDEX);
  }, []);

  useEffect(() => {
    const currentUser = users.find(({ user }) => user.uuid === organization?.relationship?.user_uuid);
    if (currentUser && !currentUser.roles.includes(ROLE.ADMIN)) {
      setUsers(users.filter(({ user, roles }) => user.is_active).map(({ user, roles }) =>
        user.uuid === organization?.relationship?.user_uuid ? { user, roles: [...roles, ROLE.ADMIN] } : { user, roles }));
    }
  }, [users, organization]);

  useEffect(() => {
    UserService.search(organization.uuid).then((data) => {
      setUsers(data.map((user) => ({ user, roles: [] })));
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading users of project failed');
    });
  }, [organization, onClose]);

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ProjectService.create(organization.uuid, {
        ...data,
        is_active: true,
      }).then((data) => {
        AuthService.getMyOrganizations().then((data) => {
          dispatch(setOrganizations(data));
        });
        ToastService.success(<><span className="text-success mr-1">Successfully</span> created new project.</>);

        const invitingUsers = users.reduce((prev, { user, roles }) => {
          if (user.uuid === data.relationship?.user_uuid) {
            return [
              ...prev,
              RelationshipService.update(data.uuid, data.relationship.uuid, {
                org_uuid: data.uuid,
                uuid: user.uuid,
                roles,
              })
            ];
          } else if (roles.length > 0) {
            return [
              ...prev,
              UserService.create(data.uuid, {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                roles,
              })
            ];
          }
          return prev;
        }, []);
        Promise.all(invitingUsers)
          .then(() => {
            ToastService.success(<><span className="text-success mr-1">Successfully</span> created new project.</>)
            onClose();
          })
          .catch((err) => ToastService.showHttpError(err, 'Creating relationship failed'));
      }).catch((err) => {
        ToastService.showHttpError(err, 'Creating project failed');
      });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Add Project</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={onClose}/>
      </div>
      <div className="h-0 flex-grow overflow-auto px-6">
        <div className="w-172 max-w-full mx-auto my-6">
          <ProjectForm
            users={users}
            setUsers={setUsers}
            onInit={setCreateForm}
            onClose={onClose}
          />
        </div>
      </div>
      <div className="flex items-center px-7 py-3">
        <Button
          data-cy="save-project"
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={onCreate}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default ProjectCreate;
