import { CAMPAIGN_MESSAGE_DIRECTION, CAMPAIGN_MESSAGE_STATUS } from "../enums";

export class WAMessagesModel {
  uuid: string;
  type: string;
  status: CAMPAIGN_MESSAGE_STATUS;
  status_description: string;
  whatsapp_number: string;
  direction: CAMPAIGN_MESSAGE_DIRECTION;
  from_whatsapp_number: string;
  to_whatsapp_number: string;
  message_data: {
    components: [],
    language_code: string;
    template_name: string;
  };
  meta_data: {};
  context_data: {};
  created: string;
  modified: string;


  constructor(init: any = {}) {
    const data = {
      uuid: '',
      type: '',
      status: '',
      status_description: '',
      whatsapp_number: '',
      direction: '',
      from_whatsapp_number: '',
      to_whatsapp_number: '',
      message_data: {
        components: [],
        language_code: '',
        template_name: '',
      },
      meta_data: {},
      context_data: {},
      created: '',
      modified: '',
      ...init,
    };

    Object.assign(this, data);
  }
}