import { Step, StepIconProps, StepLabel, Stepper, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import classNames from "classnames";
import React, { FC } from "react";

interface IHorizontalIconStepperProps {
    steps: any[];
    activeStep: number;
    className?: string;
}

const Connector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#97D800"
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#97D800"
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
    },
}));

const StepIconComponent = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
    backgroundColor: '#F5F5F5',
    borderWidth: 3,
    zIndex: 1,
    color: "#eaeaf0",
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        borderColor: "#307CD8",
        color: "#307CD8",
    }),
    ...(ownerState.completed && {
        borderColor: "#97D800",
        color: "#97D800",
    }),
}));

//pass in an array of Icons
export const HorizontalIconStepper: FC<IHorizontalIconStepperProps> = ({
    steps,
    activeStep,
    className
}) => {

    const StepComponent = (props: StepIconProps) => {
        const { active, completed, icon } = props;
        return (
            <StepIconComponent ownerState={{ completed, active }}>
                {steps[icon as number - 1]}
            </StepIconComponent>
        );
    }

    return (
        <div className={classNames(className)}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
                {steps.map((step) => (
                    <Step key={step.type.name} className="!p-0">
                        <StepLabel StepIconComponent={StepComponent}></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}