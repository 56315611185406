import React from 'react'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';

export const Breadcrumbs = ({
  text
}) => {
  return (
    // TODO fix breadcrumbs trail
    <div className='flex items-center'>
      <div className='text-[#4390DF] cursor-pointer pr-1 text-[10px]'>
        <Link
          className=''
          to={ROUTES.SETTINGS.INTEGRATIONS.PREFIX} >
          Integration
        </Link>
      </div>

      <div className='text-[#4390DF] pr-1 text-[10px]'>
        {'>'}
      </div>

      <div className='text-[#4390DF] text-[10px]'>
        {text}
      </div>

    </div>
  )
}
