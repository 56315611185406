import React, {FC} from "react";
import {Link} from "react-router-dom";
import {Button} from "../../../../components";
import {ArrowLeftIcon, LogoIcon, LogoTextIcon} from "../../../../components/icons";
import {Form, FormControl, Input, useForm, Validators} from "../../../../components/ReactiveForm";
import {ROUTES} from "../../../../constants";
import {ISetupPaymentForm} from "../index";
import {CreditCardDetail} from "../../../../parts";

export interface IAddToAccountProps {
  data: ISetupPaymentForm;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ISetupPaymentForm>): void;
}

const AddToAccount: FC<IAddToAccountProps> = ({
  data, onBack, onNext, onSubmit,
}) => {
  const [form] = useForm<{
    amount: FormControl;
  }>({
    amount: new FormControl(data.amount, [Validators.required()], { readonly: true }),
  });

  const onContinue = (data) => {
    onSubmit(data);
    onNext();
  };

  return (
    <Form className="w-full h-full flex flex-col" formGroup={form} onSubmit={onContinue}>
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>
      <h1 className="text-4xl text-primary font-bold mt-4">Let's set up your prepaid account</h1>
      <p className="text-lg text-blue-dark font-medium mt-2">How much would you like to add to your account?</p>

      <div className="font-semibold mt-8 mb-2">Card Details</div>
      <CreditCardDetail data={data} editable onEdit={onBack} />

      <div className="mt-8">
        <Input
          control={form.controls.amount}
          label="Add to Account"
          labelClass="text-base font-semibold mb-2"
          fullWidth
        />
      </div>

      <div className="flex items-center mt-auto">
        <Link className="text-primary text-sm hover:underline uppercase" to={ROUTES.DASHBOARD}>Skip for now</Link>
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button type="submit" color="primary" className="text-sm rounded-full shadow-md ml-2">Add to account</Button>
      </div>
    </Form>
  );
};

export default AddToAccount;
