import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import Tags from "./index";

const TagsRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.TAGS} component={Tags} />
  </Switch>
);

export default TagsRouting;
