import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { get } from "lodash";
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";
import "./style.scss";

export type AlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify';
export interface IDataTableColumn<T> {
  tooltip?: {
    arrow?: boolean;
    title: string;
  };
  title?: string;
  field?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: AlignType;
  headerClass?: string;
  cellClass?: string;
  render?: (row: T, index: number, data: T[]) => string | ReactElement | null;
}

export interface IDataTableRef {
  refresh: (forceUpdate?: boolean) => void;
}

export interface IDataSourceOptions {
  page?: number;
  perPage?: number;
  forceUpdate?: boolean;
}

export interface IDataSource<T> {
  count: number;
  data: T[];
}

export type TableSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ClassFn<T> = (row: T, id: number) => string;

export type DataSourceCallbackFn<T> = (result: IDataSource<T>) => void;

export type DataSourceFn<T> = (options: IDataSourceOptions, callback: DataSourceCallbackFn<T>) => void;

export interface IDataTableProps<T> {
  className?: string;
  columns: IDataTableColumn<T>[];
  serverSide?: boolean;
  data?: T[];
  datasource?: DataSourceFn<T>;
  totalCount?: number;
  extLoading?: boolean;
  size?: TableSize;
  stickyHeader?: boolean;
  title?: string | ReactElement;
  titleClass?: string;
  wrapperClass?: string;
  tableContainerClass?: string;
  headerCellClass?: string;
  rowClass?: string | ClassFn<T>;
  cellClass?: string | ClassFn<T>;
  paginationClass?: string;
  stripped?: boolean;
  checkboxSelection?: boolean;
  headerCheckboxSelection?: boolean;
  selectedRows?: T[];
  isEqual?: (a: T, b: T) => boolean;
  isSelectable?: (row: T) => boolean;
  page?: number;
  pagination?: boolean | 'auto';
  selfPagination?: boolean;
  rowsPerPage?: number;
  showCustomEmptyComponent?: boolean;
  CustomEmptyComponent?: any;
  extraOptions?: ReactElement; // extra table options in the top-right corner
  onInit?: (ref: IDataTableRef) => void;
  onSelectionChange?: (rows: T[]) => void;
  onPaginationChange?: (page: number, perPage: number) => void;
}

export function DataTable<T>({
  className = '',
  columns,
  serverSide = false,
  data,
  datasource,
  totalCount,
  size = 'md',
  stickyHeader = false,
  title,
  titleClass,
  wrapperClass = '',
  tableContainerClass = '',
  headerCellClass = '',
  rowClass = '',
  cellClass = '',
  paginationClass = '',
  extLoading = false,
  stripped = false,
  checkboxSelection = false,
  headerCheckboxSelection = false,
  selectedRows,
  isEqual,
  isSelectable,
  page: pageNum,
  pagination = false,
  selfPagination = false,
  rowsPerPage = 50,
  extraOptions,
  showCustomEmptyComponent = false,
  CustomEmptyComponent,
  onInit,
  onSelectionChange,
  onPaginationChange,
}: IDataTableProps<T>) {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [pageData, setPageData] = useState<T[]>([]);
  const [selection, setSelection] = useState<T[]>([]);

  useEffect(() => {
    setLoading(extLoading)
  }, [extLoading]);

  useEffect(() => {
    if (pageNum !== undefined) {
      setPage(pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    if (!serverSide) {
      setTotalDataCount(totalCount || data.length);
    }
  }, [serverSide, data, totalCount]);

  useEffect(() => {
    setSelection(selectedRows || []);
  }, [selectedRows]);

  const loadDataSource = useCallback((forceUpdate = false) => {
    if (!serverSide || !datasource) {
      return;
    }
    setLoading(true);
    datasource({
      page,
      perPage: rowsPerPage,
      forceUpdate,
    }, async (result) => {
      if (result) {
        setTotalDataCount(result.count);
        setPageData(result.data);
      }
      setLoading(false)
    });
  }, [serverSide, datasource, page, rowsPerPage]);

  useEffect(() => {
    if (serverSide) {
      loadDataSource();
      return;
    }

    if (!pagination || selfPagination) {
      setPageData(data);
    } else {
      setPageData(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }

  }, [serverSide, pagination, selfPagination, loadDataSource, data, page, rowsPerPage]);

  useEffect(() => {
    if (onInit) {
      onInit({
        refresh: loadDataSource,
      });
    }

  }, [onInit, loadDataSource]);

  const showPagination = useMemo(() => (
    pagination === true || (pagination === 'auto' && totalDataCount > rowsPerPage)
  ), [pagination, totalDataCount, rowsPerPage]);

  const compareRows = (a: T, b: T) => {
    return isEqual ? isEqual(a, b) : (a === b);
  };

  const onSelectRow = (row: T, checked: boolean) => {
    let newSelection: T[];
    if (checked) {
      newSelection = [...selection, row];
    } else {
      newSelection = selection.filter((item) => !isEqual(item, row));
    }
    setSelection(newSelection);
    onSelectionChange && onSelectionChange(newSelection);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    if (onPaginationChange) {
      onPaginationChange(page, rowsPerPage);
    }
  };

  return (
    <>
      {
        showCustomEmptyComponent && !loading && !pageData?.length ?
          <CustomEmptyComponent />
          :
          <div className={classnames(
            `data-table w-full group relative flex flex-col table-${size}`,
            { 'table-stripped': stripped },
            wrapperClass,
          )}>
            <TableContainer className={classnames(
              'w-full relative bg-white shadow-md rounded-md !overflow-hidden',
              tableContainerClass,
              { '!min-h-55 !overflow-hidden': loading },
              { 'mb-4': !showPagination },
            )}>
              {title && (
                <div className={titleClass}>{title}</div>
              )}

              <div className="max-h-full overflow-auto">
                <Table stickyHeader={stickyHeader}>
                  <TableHead>
                    <TableRow>
                      {checkboxSelection && (
                        <TableCell padding="checkbox">
                          {headerCheckboxSelection && (
                            <Checkbox className="checkbox-blue-light" />
                          )}
                        </TableCell>
                      )}
                      {columns.map((column, i) => (
                        <TableCell
                          key={i}
                          className={classnames(headerCellClass, column.headerClass)}
                          align={column.align}
                          style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                        >
                          {column.tooltip ? (
                            <Tooltip arrow placement="top" title={column.tooltip?.title || ''}>
                              <span>{column.title || ''}</span>
                            </Tooltip>
                          ) : column.title || ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageData && pageData.map((row, rowId) => {
                      const trClass = typeof rowClass === 'function' ? rowClass(row, rowId) : rowClass;
                      const rowCellClass = typeof cellClass === 'function' ? cellClass(row, rowId) : cellClass;
                      const selectable = !isSelectable || isSelectable(row);
                      return (
                        <TableRow key={rowId} tabIndex={-1} className={trClass}>
                          {checkboxSelection && (
                            <TableCell padding="checkbox">
                              {selectable && (
                                <Checkbox
                                  className="checkbox-blue-light"
                                  checked={selection.some((r) => compareRows(r, row))}
                                  onChange={(_, checked) => onSelectRow(row, checked)}
                                />
                              )}
                            </TableCell>
                          )}
                          {columns.map((column, colId) => (
                            <TableCell key={colId} align={column.align} className={classnames(rowCellClass, column.cellClass)}>
                              {column.render
                                ? column.render(row, rowId, data)
                                : column.field ? get(row, column.field) : null}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>

              {loading && (
                <div className="absolute top-0 left-0 w-full h-full min-h-55 flex-center bg-white bg-opacity-80 pt-12">
                  <CircularProgress
                    sx={{
                      color: '#0B1C34',
                    }}
                    size={40}
                    thickness={4}
                  />
                </div>
              )}

              {!loading && !pageData?.length && (
                <div className="text-center text-gray p-10">No Records</div>
              )}
            </TableContainer>

            {extraOptions && (
              <div className="absolute hidden group-hover:block top-1.5 right-4 z-10">
                {extraOptions}
              </div>
            )}

            {showPagination && (
              <TablePagination
                className={classnames('flex-shrink-0', paginationClass)}
                component="div"
                count={totalDataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={(pageInfo) => (
                  <span className="text-xs">
                    <span className="font-semibold">{pageInfo.from}-{pageInfo.to}</span> <span>out of {pageInfo.count}</span>
                  </span>
                )}
                onPageChange={(e, newPage) => onPageChange(newPage)}
              />
            )}
          </div>
      }
    </>
  );
}
