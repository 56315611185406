import Tooltip from '@mui/material/Tooltip';
import React, { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DataTable, IDataTableColumn, TruncatedTableCell } from "../../../../components";
import { EditIcon, InfoCircleIcon } from "../../../../components/icons";
import { getActiveOrganization } from "../../../../redux/selectors";
import { WAListsService } from '../../../../services';
import { ISearchListRequest } from '../../../../utils/dto';
import { formatDateTime } from "../../../../utils/helpers";
import { WAListsModel } from "../../../../utils/types";

const rowsPerPage = 50;

export interface ICampaignTableProps {
    className?: string;
    filter: any;
    onEdit?: (result?: any) => void;
    onView?: (result?: any) => void;
    setTableRef: (result?: any) => void;
}

export const ListsTable: FC<ICampaignTableProps> = ({
    className,
    filter,
    onEdit,
    onView,
    setTableRef,
}) => {
    //selector
    const organization = useSelector(getActiveOrganization);

    const columns = useMemo<IDataTableColumn<WAListsModel>[]>(() => {
        return [
            {
                title: 'List Name',
                field: 'name',
                tooltip: { title: 'List Name' },
                cellClass: "max-w-[300px]",
                render(row: WAListsModel) {
                    return <TruncatedTableCell cellData={row.name} />
                }
            },
            {
                title: 'Number of Contacts',
                field: 'total_recipients',
                tooltip: { title: 'List Name' },
            },
            {
                title: 'Total Campaigns',
                field: 'total_campaigns',
                tooltip: { title: 'List Name' },
            },
            {
                title: 'Last Used',
                field: 'modified',
                tooltip: { title: 'Last Used' },
                render(row: WAListsModel) {
                    return formatDateTime(row.modified);
                }
            },
            {
                title: 'Date Created',
                field: 'created',
                tooltip: { title: 'Date Created' },
                render(row: WAListsModel) {
                    return formatDateTime(row.created);
                }
            },
            {
                render(row: WAListsModel) {
                    return (
                        <div className="flex items-center justify-end">
                            <Tooltip title="Edit Campaign" arrow placement="top">
                                <div
                                    className={"w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"}
                                    onClick={() => onEdit(row)}
                                >
                                    <EditIcon color="blue" size={22} />
                                </div>
                            </Tooltip>

                            <Tooltip title="View Details" arrow placement="top">
                                <div
                                    className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                                    onClick={() => onView(row)}
                                >
                                    <InfoCircleIcon color="blue" size={18} />
                                </div>
                            </Tooltip>
                        </div>
                    );
                },
            },
        ];
    }, [filter, organization]);

    const loadLists = useCallback((options, callback) => {
        const query: ISearchListRequest = {
            page: options.page + 1,
            page_size: options.perPage,
        }
        if (filter.search) {
            query.name = filter.search
        }

        WAListsService.search(query, false)
            .then((data) => {
                callback({
                    count: data.count,
                    data: data.results,
                });
            }).catch(() => {
                callback({
                    count: 0,
                    data: [],
                });
            });
    }, [organization, filter]);

    return (
        <DataTable
            wrapperClass={className}
            columns={columns}
            serverSide
            datasource={loadLists}
            size="xs"
            stripped
            stickyHeader
            rowsPerPage={rowsPerPage}
            pagination
            selfPagination
            headerCellClass="!text-13p"
            cellClass="!text-11p"
            onInit={setTableRef}
        />
    );
};
