import { HttpService } from "./http.service";
import { ISearchAudiosRequest, IUploadAudioRequest } from "../utils/dto";
import { AUDIO_TYPE } from "../utils/enums";
import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { AudioModel } from "../utils/types";

export class AudioService {

  static search(query: ISearchAudiosRequest = {}, showSpinner = true) {
    return HttpService.get('/audiofiles/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(AudioModel, res));
  }

  static upload(data: IUploadAudioRequest, showSpinner = true) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('audio_file', data.file);

    return HttpService.post('/audiofiles/', formData, { 'Content-Type': 'multipart/form-data' }, showSpinner)
      .then((res) => new AudioModel(res));
  }

  static update(name: string, data, showSpinner = true) {
    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('audio_file', data.file);

    return HttpService.patch(`/audiofiles/${name}/`, formData, {}, showSpinner)
      .then((res) => new AudioModel(res));
  }

  static delete(name: string, type: AUDIO_TYPE, showSpinner = true) {
    return HttpService.delete(`/audiofiles/${name}/?type=${type}`, {}, {}, showSpinner);
  }

  static generateAudioFromText(text: string, voice: string, showSpinner = true): Promise<Blob> {
    return HttpService.request(
      'POST',
      '/tts',
      {
        headers: {},
        data: { text, voice },
        responseType: 'blob',
      },
      showSpinner
    );
  }

  static getAudioFile(name: string, type: AUDIO_TYPE, query = {}, showSpinner = true) {
    return HttpService.get(`/audiofiles/${name}/`, { type: type, ...query }, {}, showSpinner);
  }
}
