import classNames from "classnames";
import React, { FC } from "react";
import { Button } from "../Button";
import { AuthService } from "../../services"

interface ISelfHelpButtonProps {
    className?: string;
    url: string;
}

const onClick = (redirect: string = "") => {
  AuthService.readme_login(redirect)
    .then((res) => {
      window.open(res.url);
    });
}

export const SelfHelpButton: FC<ISelfHelpButtonProps> = ({
    className,
    url = '/docs',
}) => {

    return (
        <Button color="blue-light" className={classNames('text-xs rounded-full !normal-case shadow px-6 ml-4', className)} onClick={() => onClick(url)}>
            Need help?
        </Button>
    )
}
