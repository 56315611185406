import React, {FC, useState} from "react";
import {Button, TextField, Modal} from "../../components";

interface ICreateNewTagModalProps {
  onCreate: (newTag: string) => void;
  onClose: () => void;
}

export const CreateNewTagModal: FC<ICreateNewTagModalProps> = ({
  onCreate,
  onClose,
}) => {
  const [newTag, setNewTag] = useState<string>();

  const onCreateNewTag = () => {
    if (newTag) {
      onCreate(newTag);
      onClose();
    }
  };

  return (
    <Modal
      title="Create a new tag"
      contentClass="px-7 py-5"
      onClose={onClose}
    >
      <TextField
        fullWidth
        value={newTag}
        label="Tag Name"
        placeholder="Give your tag a cooler name"
        onChange={(value) => setNewTag(value)}
      />
      <div className="flex items-center justify-end mt-10">
        <div className="flex items-center">
          <Button className="text-primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            className="rounded-3xl shadow-md px-6"
            disabled={!newTag}
            onClick={onCreateNewTag}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
