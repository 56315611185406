
import React from 'react'
import IntegrationsList from './IntegrationsList';
import { ROUTES } from '../../../constants';

export interface IIntegrationItems {
    text: string,
    subText: string,
    navigationURL: string
}

const Integrations = () => {

    // TODO import this
    const intergrationItems: IIntegrationItems[] = [
        {
            text: "Google Analytics",
            subText: "(Let's start Tracking)",
            navigationURL: ROUTES.SETTINGS.INTEGRATIONS.GA4.PREFIX
        },
    ]

    return (
        <div className='m-10'>
            {
                intergrationItems.map(item => {
                    return (
                        <IntegrationsList
                            text={item.text}
                            subText={item.subText}
                            navigationURL={item.navigationURL}
                        />
                    )
                })
            }

        </div>
    )
}

export default Integrations;