import React, {useState} from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {LaptopDocumentIcon, PhoneTrackIcon, WhatsAppSettingIcon} from "../../../components/icons";
import {ROUTES} from "../../../constants";

const options = [
  { icon: PhoneTrackIcon, iconSize: 90, title: `Track incoming calls`, link: ROUTES.ONBOARD.CREATE_NUMBER, description: 'Post your tracking number on your website, in ads or in print.', requiresPaymentOptions: true },
  { icon: WhatsAppSettingIcon, iconSize: 80, title: `WhatsApp Setup`, link: ROUTES.ONBOARD.WHATSAPP_SETUP, description: 'Setting up your number to use it on WhatsApp', requiresPaymentOptions: true },
  { icon: LaptopDocumentIcon, iconSize: 120, title: `I'm a dev, show me your API`, link: ROUTES.ONBOARD.API_DOC, description: 'See our documentation to help your set up whatever you need, using our API and integrating your systems.' },
];

const OnboardGetStarted = () => {
  const [hoveredItem, setHoveredItem] = useState<number>();

  return (
    <div className="w-screen h-screen flex-center bg-gradient-to-r from-blue-dark to-[#22599C]">
      <div className="w-250 max-w-[95vw] max-h-[95vh] overflow-auto">
        <div className="bg-white rounded-md shadow-md">
          <div className="w-full h-15 bg-gray-ef rounded-t-md" />
          <div className="text-center px-18 py-8">
            <h2 className="text-primary text-4xl font-bold">Let's get started</h2>
            <p className="text-sm font-semibold">What would you like to start with?</p>
            <div className="grid md:grid-cols-3 gap-10 mt-8 mx-auto">
              {options.map((option, i) => (
                <Link
                  key={i}
                  to={option.link}
                  className="relative cursor-pointer"
                  onMouseEnter={() => setHoveredItem(i)}
                  onMouseLeave={() => setHoveredItem(undefined)}
                >
                  <div className={classnames(
                    'w-full h-full border-1.5 rounded-xl px-4 py-6',
                    hoveredItem === i ? 'text-body border-blue' : 'text-[#6d8297] border-[#d8e2ea]',
                    { 'pb-12': option.requiresPaymentOptions },
                  )}>
                    <div className="h-25 flex items-end justify-center">
                      <option.icon size={option.iconSize} color={hoveredItem === i ? ['primary', 'blue'] : ['#aabed3', '#d0dfea']} />
                    </div>
                    <div className="text-lg font-semibold leading-tight mt-6">{option.title}</div>
                    <div className="text-blue-dark text-sm leading-tight mt-4">{option.description}</div>
                  </div>
                  {option.requiresPaymentOptions && (
                    <div className="absolute w-full bottom-0 bg-primary text-white text-sm font-semibold rounded-bl-md rounded-br-md cursor-pointer px-4 py-2">
                      Requires Payment Options
                    </div>
                  )}
                </Link>
              ))}
            </div>
            <div className="text-sm font-medium p-2 mt-4">
              <Link className="text-primary hover:underline" to={ROUTES.DASHBOARD}>Skip this</Link> & go straight to the dashboard
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardGetStarted;
