import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const OpenLinkIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={11.122} baseHeight={11.122} {...props}>
    {(width, height, color = "#f4a734") => (
      <svg width={width} height={height} viewBox="0 0 11.122 11.122">
        <path
          id="Icon_ionic-md-open"
          data-name="Icon ionic-md-open"
          d="M14.386,14.386H5.736V5.736H9.6V4.5H5.736A1.239,1.239,0,0,0,4.5,5.736v8.65a1.239,1.239,0,0,0,1.236,1.236h8.651a1.239,1.239,0,0,0,1.236-1.236V10.524H14.386ZM10.988,4.5V5.736h2.534L7.28,11.976l.865.865L14.387,6.6V9.134h1.236V4.5Z"
          transform="translate(-4.5 -4.5)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
