import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppReadIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={34.858} baseHeight={18.193} {...props}>
    {(width, height, color = '#79d7ee') => (
      <svg width={width} height={height} viewBox="0 0 34.858 18.193">
        <defs>
          <clipPath id="clipPath">
            <rect width="34.858"
              height="18.193"
              transform="translate(0 0)"
              fill={color} />
          </clipPath>
        </defs>
        <g transform="translate(0 0)">
          <g transform="translate(0 0)" clipPath="url(#clipPath)">
            <path
              d="M27.258,1.566a1.721,1.721,0,0,1-.631,1.23Q20.771,8.64,14.92,14.49c-1.035,1.034-2.092,2.046-3.1,3.109a1.681,1.681,0,0,1-2.5-.016c-2.9-2.979-5.857-5.9-8.792-8.851A1.493,1.493,0,1,1,2.62,6.606c2.009,1.993,4,4,6,6,.535.534,1.1,1.035,1.619,1.587.275.3.421.269.694-.006q4.322-4.35,8.67-8.675,2.43-2.429,4.86-4.859a2.189,2.189,0,0,1,.862-.59,1.533,1.533,0,0,1,1.929,1.5"
              transform="translate(0 0)"
              fill={color} />
            <path
              d="M78.549,16.713a1.677,1.677,0,0,1,.607-1.269q3.032-3.019,6.056-6.047Q89.528,5.085,93.845.773a4.727,4.727,0,0,1,.464-.431,1.49,1.49,0,0,1,1.9.052,1.49,1.49,0,0,1,.232,2.042,3.42,3.42,0,0,1-.353.392Q88.706,10.2,81.324,17.576a1.536,1.536,0,0,1-2.432.078,1.379,1.379,0,0,1-.343-.942"
              transform="translate(-61.883 -0.021)"
              fill={color} />
          </g>
        </g>
      </svg>

    )}
  </Icon>
);
