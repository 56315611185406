import {WAChatIntroService} from "../../services";
import {CHAT_INTRO_NOTIFY_TYPES} from "../enums"

export class WAChatIntroModel {
  uuid: string;
  whatsapp_number: string;
  chat_link: string;
  reference_id: string;
  notified_destination: string;
  notify_type: CHAT_INTRO_NOTIFY_TYPES;
  leads_generated: number;
  created: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      whatsapp_number: '',
      chat_link: '',
      reference_id: '',
      notified_destination: '',
      notify_type: '',
      leads_generated: 0,
      created: '',
      ...init,
    };

    Object.assign(this, data)
    switch (this.notify_type) {
      case CHAT_INTRO_NOTIFY_TYPES.WHATSAPP:
      case CHAT_INTRO_NOTIFY_TYPES.SMS:
      case CHAT_INTRO_NOTIFY_TYPES.VOICE_CALL:
        this.notified_destination = init.notify_data?.to_phone_number
        break;
      case CHAT_INTRO_NOTIFY_TYPES.WEBHOOK:
        this.notified_destination = init.notify_data?.url
        break;
      case CHAT_INTRO_NOTIFY_TYPES.EMAIL:
        this.notified_destination = init.notify_data?.email
        break;
    }
  }

  static search(query: any = {}, ...kwargs) {
    return WAChatIntroService.search(query, ...kwargs)
  }
}
