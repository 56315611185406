import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {ROUTES} from "../../constants";
import Calls from "./index";

const CallsRouting = () => (
  <Switch>
    <Redirect exact from={ROUTES.CALLS.PREFIX} to={ROUTES.CALLS.RECEIVED} />
    <Route path={ROUTES.CALLS.MADE} component={Calls} />
    <Route path={ROUTES.CALLS.RECEIVED} component={Calls} />
    <Route path={ROUTES.CALLS.BLOCKED} component={Calls} />
  </Switch>
);

export default CallsRouting;
