import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { Form, FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import classnames from "classnames";
import { MenuItem, Tab, Tabs } from "@mui/material";
import TextSpeechForm from "./TextSpeechForm";
import UploadFileForm from "./UploadFileForm";
import { Input, Card, Chip, Chips } from "../../../../components";
import { AUDIO_TYPE } from "../../../../utils/enums";
import { AudioModel } from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";

export interface IAudioFileFormInitParams {
  getFormData: () => any;
}

export type IAudioFileForm = {
  name: FormControl
  type: FormControl;
  file: FormControl;
}

export interface IAudioFileFormProps {
  fileType?: string,
  audioFile?: AudioModel;
  confirmCancel?: boolean,
  setTouched?: Dispatch<SetStateAction<boolean>>,
  setAudioTouched?: Dispatch<SetStateAction<boolean>>
  setForm?: (params: IAudioFileFormInitParams) => void;
  onClose?: () => void;
}

const typeOptions = [
  { text: 'Whisper', value: AUDIO_TYPE.WHISPER },
  { text: 'Record Call', value: AUDIO_TYPE.RECORD_CALL },
  { text: 'Keep Number', value: AUDIO_TYPE.KEEP_NUMBER },
  { text: 'Voice Mail', value: AUDIO_TYPE.VOICE_MAIL_LITE },
  { text: 'Capture Digits', value: AUDIO_TYPE.CAPTURE_DIGITS },
  { text: 'IVR', value: AUDIO_TYPE.IVR },
];

const tabs = [
  { text: 'Text to Speech', value: 'text' },
  { text: 'Upload a File', value: 'file' },
];

const AudioFileForm: FC<IAudioFileFormProps> = ({
  audioFile,
  confirmCancel = false,
  setTouched,
  setAudioTouched,
  fileType = "text",
  setForm = () => { },
  onClose = (bypass?: boolean) => { },
}) => {
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [type, setType] = useState<string>(fileType);
  const [form, formData] = useForm<IAudioFileForm>({
    name: new FormControl(audioFile?.name || "", [Validators.required()]),
    type: new FormControl(audioFile?.type || AUDIO_TYPE.WHISPER, [Validators.required()]),
    file: new FormControl(null, [Validators.required()]),
  });

  useEffect(() => {
    if (!audioFile) return;
    audioFile.getS3Url().then(({ url }) => setPreviewUrl(url))
  }, []);

  const getFormData = useCallback(() => {
    if (!form.validate()) return null;
    return form.getFormData();
  }, []);

  useEffect(() => {
    setForm({ getFormData });
  }, [formData]);

  return (
    <>
      <div className="px-8 md:px-12 lg:px-20 py-4">
        <Card contentClass="grid md:grid-cols-3 gap-x-8 gap-y-4">
          <Input
            type="text"
            control={form.controls.name}
            label="Audio File Name"
            labelClass="text-md font-semibold"
            inputClass="!text-sm !font-normal"
            disabled={!!audioFile}
          />

          <div className="xs:col-span-2">
            <div className="text-blue-dark font-semibold mb-1">Type</div>
            <Chips>
              {typeOptions.map((item, i) => (
                <Chip
                  key={i}
                  className="!text-sm px-3 py-2"
                  active={formData?.type === item.value}
                  disabled={!!audioFile && formData?.type !== item.value}
                  onClick={() => form.controls.type.patch(item.value)}
                >
                  {item.text}
                </Chip>
              ))}
            </Chips>
          </div>
        </Card>

        <p className="text-[15px] py-5 text-[#0B1C34]">
          Create a TTS (Text to Speech) message and select your own voice and language preference. To listen to your message before saving, click ADD and it will create a preview for you to listen to.
        </p>

        <Card className="!p-0">
          <Tabs
            value={type}
            classes={{
              root: '!overflow-visible',
              scroller: '!overflow-visible',
              indicator: '!hidden',
            }}
            variant="fullWidth"
            onChange={(_, value) => setType(value)}
          >
            {tabs.map((tab) => (
              <Tab
                data-cy={tab.text}
                key={tab.value}
                className={classnames(
                  '!text-base !uppercase',
                  type === tab.value ? ' !text-blue !border-b-2 !border-blue' : '!text-gray-c0'
                )}
                label={tab.text}
                value={tab.value}
              />
            ))}
          </Tabs>

          <div className="px-8 py-4">
            {(type === "text") ? (
              <TextSpeechForm
                className={type === 'text' ? '' : 'hidden'}
                form={form}
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
                setTouched={setTouched}
              />
            ) : (
              <UploadFileForm
                className={type === 'file' ? '' : 'hidden'}
                form={form}
                previewUrl={previewUrl}
                setPreviewUrl={setPreviewUrl}
                setAudioTouched={setAudioTouched}
              />
            )}
          </div>
        </Card>
      </div>

      {
        confirmCancel && <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={onClose}
        />
      }
    </>
  );
};

export default AudioFileForm;

