import React, { useMemo, useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import classnames from "classnames";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { DataTable, IDataTableColumn, TextField } from "../../../components";
import { EditIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import { getActiveOrganization } from "../../../redux/selectors";
import BlockedNumberCreateModal from "./BlockedNumberCreateModal";
import BlockedNumberEditModal from "./BlockedNumberEditModal";
import { BLOCKED_NUMBER_CHANNEL, BLOCKED_NUMBER_CHANNEL_VALUES } from "../../../utils/enums";
import { FilterModel, BlockedNumberModel } from "../../../utils/types";
import BlockedNumberFilter from "./BlockedNumberFilter";

const BlockedNumbers = () => {
  const [search, setSearch] = useState('');
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [creatingNumber, setCreatingNumber] = useState(false);
  const [editingNumber, setEditingNumber] = useState<BlockedNumberModel>();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [data, setData] = useState<BlockedNumberModel[]>([]);
  const organization = useSelector(getActiveOrganization);

  const debounceSetSearch = useCallback(
    debounce(value => setSearch(value), 1000),
    []
  )

  const columns = useMemo<IDataTableColumn<BlockedNumberModel>[]>(() => [
    {
      title: 'Number',
      field: 'number',
      cellClass: '!text-primary !font-medium',
      render(row: BlockedNumberModel) {
        return row.pretty_number();
      },
    },
    { title: 'Reason for Blocking', field: 'description' },
    {
      title: 'Channels',
      field: 'channels',
      render(row: BlockedNumberModel) {
        if (row.channels.length === BLOCKED_NUMBER_CHANNEL_VALUES.length)
          return 'All'
        return row.channels.map(c => BLOCKED_NUMBER_CHANNEL[c]).join(', ')
      },
    },
    {
      title: 'Created At',
      field: 'created',
      render(row: BlockedNumberModel) {
        return row.formatCreated();
      },
    },
    { title: 'Blocked by', field: 'created_by' },
    {
      render(row: BlockedNumberModel) {
        return (
          <div className="flex justify-end">
            <Tooltip title="Edit blocked number" arrow placement="left">
              <div
                data-cy="edit-number"
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md cursor-pointer"
                onClick={() => setEditingNumber(row)}
              >
                <EditIcon color="blue" size={22} />
              </div>
            </Tooltip>
          </div>
        );
      }
    }
  ], [filters, search, page, organization]);

  const loadData = useCallback((query) => {
    setLoading(true)
    BlockedNumberModel.search(query, false).then(res => {
      setData(res.results);
      setTotalCount(res.count);
      setLoading(false)
    }).catch(() => {
      setData([]);
      setTotalCount(0);
      setLoading(false)
    });
  }, []);

  const buildFilterLoadData = useCallback(() => {
    const query: any = {
      page: page + 1,
    };

    const usable_filters = filters.filter(x => x.value)
    if (!usable_filters.length && filters.length) return;

    usable_filters.forEach((filter) => {
      switch (filter.category) {
        case 'channels':
          query.channels = Array.isArray(filter.value) ? filter.value.join() : filter.value
          break;

        default:
          query[filter.category] = filter.value;
      }
    });

    if (search) query.number = search

    loadData(query);
  }, [filters, search]);

  useEffect(() => { buildFilterLoadData() }, [columns])

  const onSearch = value => {
    setNumber(value)
    debounceSetSearch(value)
  }

  return (
    <>
      <div className="relative h-full flex flex-col px-4 py-5">
        <div className="flex justify-between items-center">
          <BlockedNumberFilter filters={filters} onChange={setFilters} />
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow-md"
            containerClass="w-80 ml-auto"
            value={number}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search numbers"
            clearable
            onChange={onSearch}
          />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          data={data}
          totalCount={totalCount}
          extLoading={loading}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!px-8"
          pagination
          page={page}
          rowsPerPage={50}
          paginationClass="mr-16"
        />

        <div
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-80 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setCreatingNumber(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Block another number</span>
        </div>
      </div>

      {creatingNumber && (
        <BlockedNumberCreateModal
          onCreated={() => { setCreatingNumber(false); buildFilterLoadData() }}
          onClose={() => setCreatingNumber(false)}
        />
      )}

      {editingNumber && (
        <BlockedNumberEditModal
          blockedNumber={editingNumber}
          onEdited={() => { setEditingNumber(undefined); buildFilterLoadData() }}
          onDeleted={() => { setEditingNumber(undefined); buildFilterLoadData() }}
          onClose={() => setEditingNumber(undefined)}
        />
      )}
    </>
  );
};

export default BlockedNumbers;
