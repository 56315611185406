import React, { FC, useState } from "react";
import { Modal } from "../../../components";
import NumberCreate from "../NumberCreate";
import { ConfirmationModal } from "../../../parts";

interface INumberCreateModalProps {
  onClose: (result?: any) => void;
}

const NumberCreateModal: FC<INumberCreateModalProps> = ({
  onClose,
}) => {
  const [touched, setTouched] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const close = (value?: any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if (touched && value === undefined) {
      setShowConfirmation(true)
      return;
    }
    if (value === undefined && !touched) {
      onClose()
    }
  }

  return (
    <Modal
      title="Add a number"
      contentClass="!p-0"
      onClose={close}
      size="lg"
    >
      <NumberCreate onClose={onClose} onCreate={onClose} setTouched={setTouched} />
      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  );
};

export default NumberCreateModal;

