import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const BarChartOutlineIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          stroke={color}
          fill="transparent"
          strokeWidth={1.5}
          d="M1 23L1 15 8 15 8 23 8 8 16 8 16 23 16 1 23 1 23 23z"
        />
      </svg>
    )}
  </Icon>
);
