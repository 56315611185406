import React, { FC, useCallback, useEffect } from 'react';
import { Chip, Chips, Input } from '../../../../components';
import { Form, FormControl, useForm, Validators } from '../../../../components/ReactiveForm';
import { ProjectModel, UserModel } from '../../../../utils/types';
import { ROLE } from '../../../../utils/enums';

export interface IProjectFormInitParams {
  getFormData: () => any;
}

const roleOptions = [
  { text: 'Administrator', value: ROLE.ADMIN },
  { text: 'User', value: ROLE.USER },
  { text: 'View Only', value: ROLE.READONLY },
];

export interface IProjectFormProps {
  project?: ProjectModel;
  users: { user: UserModel, roles: ROLE[] }[];
  setUsers: (value: { user: UserModel, roles: ROLE[] }[]) => void;
  onInit?: (params: IProjectFormInitParams) => void;
  onClose?: () => void;
}

export type IProjectForm = {
  name: FormControl;
}

const ProjectForm: FC<IProjectFormProps> = ({
  project,
  users,
  setUsers,
  onInit = () => null,
  onClose = () => null,
}) => {
  const [form] = useForm<IProjectForm>({
    name: new FormControl('', [Validators.required()]),
  });

  useEffect(() => {
    if (project) {
      form.patch({
        name: project.name,
      });
    }
  }, [project]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return {
        ...form.getFormData(),
        permissions: [],
      };
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  const onUserRoleChange = ({ user }: { user: UserModel }, role: ROLE) => {
    const newUser = {
      user,
      roles: [role],
    };
    setUsers(
      users.map((item) => (item.user.uuid === user.uuid ? newUser : item))
    );
  };

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        fullWidth
        label="Project name"
        labelClass="text-md font-semibold"
        inputClass="!bg-white"
        placeholder="Give your project a cool name"
      />

      <div className="text-blue-dark mt-4">
        <div className="font-semibold">Add additional Users to Project</div>
        <table className="project-access-table w-full border-collapse mt-2">
          <thead>
            <tr>
              <th>User name</th>
              <th>Roles</th>
            </tr>
          </thead>
          <tbody>
            {users.map(({ user, roles }, i) => (
              <tr key={i}>
                <td className="font-medium">{user.first_name}</td>
                <td>
                  <Chips>
                    {roleOptions.map((option, i) => (
                      <Chip
                        key={i}
                        containerClass="mr-2 mb-0"
                        className="px-3 py-1.5"
                        active={roles.includes(option.value)}
                        onClick={() => onUserRoleChange({ user }, option.value)}
                        disabled={
                          (user.relationship.is_owner &&
                            option.value === ROLE.USER) ||
                          (user.relationship.is_owner &&
                            option.value === ROLE.READONLY)
                        }
                      >
                        {option.text}
                      </Chip>
                    ))}
                  </Chips>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Form>
  );
};

export default ProjectForm;
