import React, {FC, useCallback, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import {NumberModel} from "../../../../utils/types";
import {Card, WithTooltipIcon} from "../../../../components";
import {TextFileInput, TextFileInputForm} from "../../../../parts";
import {AudioService} from "../../../../services";
import {AUDIO_TYPE, NUMBER_FEATURE} from "../../../../utils/enums";
import {uploadTTS} from "../../../../utils/helpers";

export interface IWhisperMessageProps {
  number: NumberModel;
  form: TextFileInputForm;
  formData: any;
  initialForm?: any;
  setTouched?: any;
  onUpdate(data: any): void;
}

const WhisperMessage: FC<IWhisperMessageProps> = ({
  number,
  form,
  formData,
  initialForm,
  setTouched,
  onUpdate,
}) => {
  const [enabled, setEnabled] = useState(false);

  const onEnable = useCallback((enable) => {
    setEnabled(enable);
    form.controls.enabled.patch(enable);
    setTouched(initialForm, form.getFormData())
    onSave().then();
  }, [form]);

  useEffect(() => {
    const enabled = number.features.includes(NUMBER_FEATURE.WHISPER) || number.features.includes(NUMBER_FEATURE.WHISPER_FILE);
    form.patch({
      enabled,
      mode: !number.tts && number.whisper_file ? 'file' : 'text',
      text: enabled ? number.tts : '',
      file: number.whisper_file,
    });
    initialForm.enabled = enabled
    initialForm.mode = !number.tts && number.whisper_file ? 'file' : 'text'
    initialForm.text = enabled ? number.tts : ''
    initialForm.file = number.whisper_file
    setEnabled(enabled);

    return () => {
      form.disable();
    };
  }, [number]);

  useEffect(() => {
    if (formData) {
      const enable = formData.enabled || false;
      setEnabled(enable);
      form.controls.enabled.patch(enable);
      initialForm.enabled = enable
    }
  }, [formData]);

  const onUploadFile = useCallback((file: File) => {
    return AudioService
      .upload({
        name: `${AUDIO_TYPE.WHISPER}_${number.number}_${Date.now()}`,
        type: AUDIO_TYPE.WHISPER,
        file,
      }, false)
      .then((audio) => audio.name);
  }, []);

  const onRemoveFile = useCallback((fileName) => {
    return AudioService.delete(fileName, AUDIO_TYPE.WHISPER, false);
  }, []);

  const onSave = async () => {
    if (!form.validate()) {
      return;
    }

    const formData = form.getFormData();

    const data: any = {};
    const features = (number.features || []).filter((item) => item !== NUMBER_FEATURE.WHISPER);

    if (formData.enabled) {
      features.push(NUMBER_FEATURE.WHISPER);
      if (formData.mode === 'text' && formData.text) {
        data.tts = formData.text;
      } else {
        data.whisper_file = formData.file;
      }
    }
    data.features = features;

    onUpdate(data);
  };

  return (
    <Card className="!p-0">
      <div className="flex items-center px-4 py-2">
        <Switch
          checked={enabled}
          onChange={(_, checked) => onEnable(checked)}
        />
        <WithTooltipIcon
          className="font-semibold"
          tooltip="This is a message that will play right after picking up"
          placement="right"
        >
          Whisper Message
        </WithTooltipIcon>
      </div>
      <TextFileInput
        form={form}
        audioType={AUDIO_TYPE.WHISPER}
        disabled={!enabled}
        onUploadFile={onUploadFile}
        onChange={onSave}
        hidetts={false}
        setTouched={setTouched}
      />
    </Card>
  );
};

export default WhisperMessage;
