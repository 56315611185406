import React, { FC, useState } from 'react'
import { GA4Model } from '../../../../../utils/types/GA4.model';
import { Button, Modal } from '../../../../../components';
import { TrashIcon } from '../../../../../components/icons';
import GA4Form from '../GA4Form';
import { GA4Service } from '../../../../../services/GA4.service';
import { MessageBoxService, ToastService } from '../../../../../services';
import { ConfirmationModal } from '../../../../../parts';

interface IGA4ModalProps {
  ga4: GA4Model;
  setTouched?: any;
  showConfirmation?: any;
  touched?: any
  onClose: (res?: any) => void;
}

const GA4Edit: FC<IGA4ModalProps> = ({
  ga4,
  onClose,
  touched,
  setTouched
}) => {

  const [editForm, setEditForm] = useState({
    getFormData: () => null,
  });

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)


  const close = (value?: any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if (touched && value === undefined) {
      setShowConfirmation(true)
      return;
    }
    if (value === undefined) {
      onClose()
    }
  }



  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      GA4Service.update(ga4.name, data).then((res) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onClose(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Updating ga4 failed');
      });
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">Google Analytics</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        GA4Service.delete(ga4.name).then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose(ga4);
        }).catch((err) => {
          if (err?.response.data[0].includes('This GA4 is still attached to')) {
            ToastService.error('GA4 cannot be deleted as it is attached to a number. If you wish to delete it, please remove it from the number.');

          } else {
            ToastService.showHttpError(err, 'Deleting GA4 failed');

          }
        });
      }
    });
  };


  return (
    <Modal
      title="Edit Google Analytics"
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Delete Google Analytics
          </Button>
          <Button
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={close}
    >

      <GA4Form
        onInit={setEditForm}
        ga4={ga4}
        setTouched={setTouched}
        />

      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  )
}

export default GA4Edit
