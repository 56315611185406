import React, { FC, MouseEventHandler, ReactElement, useEffect, useState } from "react";
import classnames from "classnames";
import ReactDropzone from "react-dropzone";
import { CircularProgress } from "@mui/material";
import { CloudUploadIcon, TrashIcon } from "../icons";
import { IAbstractInputControlProps, InputWrapper } from "../ReactiveForm";
import { SelectAudioModal } from "../../parts";
import { ToastService } from "../../services";
import { getFileInfo } from "../../utils/helpers";
import { AudioModel } from "../../utils/types";
import { AUDIO_TYPE } from "../../utils/enums";

export interface IDropzoneProps {
  value?: string | File | Array<string | File>;
  accept?: string[];
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  placeholder?: string | ReactElement;
  selectFromAudioList?: boolean;
  audioType?: AUDIO_TYPE;
  onUpload?: (file: File | File[] | string) => Promise<any>;
  onRemove?: (value: any) => Promise<void>;
}

export const Dropzone: FC<IDropzoneProps & IAbstractInputControlProps> = ({
  value,
  accept,
  minSize,
  maxSize,
  maxFiles,
  placeholder = '',
  disabled,
  selectFromAudioList = false,
  audioType,
  onChange = () => { },
  onUpload,
  onRemove,
  ...wrapperProps
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAssetsModal, setShowAssetsModal] = useState(false);


  useEffect(() => {
    const files: any[] = [];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        files.push(getFileInfo(item));
      });
    } else {
      files.push(getFileInfo(value));
    }
    setFiles(files.filter((item) => !!item));
  }, [value]);

  const onClickDropzone: MouseEventHandler = (e) => {
    if (disabled) return;

    if (selectFromAudioList) {
      e.preventDefault();
      e.stopPropagation();

      setShowAssetsModal(true);
    }
  };

  const onSelectFileFromAssets = async (file: AudioModel | File) => {
    if (file instanceof AudioModel) {
      onChange(file.name);
      setShowAssetsModal(false);
      return;
    }

    if (onUpload) {
      setLoading(true);

      await onUpload(file).then((res) => {
        setFiles([file]);
        onChange(res);
        setShowAssetsModal(false);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Uploading file(s) failed');
      });

      setLoading(false);
    } else {
      setFiles([file]);
      onChange(file);
      setShowAssetsModal(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }

    const originalValue = value;
    const data = maxFiles === 1 ? acceptedFiles[0] : files;
    if (onUpload) {
      setLoading(true);

      await onUpload(data).then((res) => {
        setFiles(acceptedFiles);
        onChange(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Uploading file(s) failed');
      });

      if (originalValue && onRemove) {
        await onRemove(originalValue).catch((err) => {
          ToastService.showHttpError(err, 'Deleting original file(s) failed');
        });
      }

      setLoading(false);
    } else {
      setFiles(acceptedFiles);
      onChange(acceptedFiles);
    }
  };

  const onDeselectFile = async (e, file) => {
    e.preventDefault();
    e.stopPropagation();

    const id = files.indexOf(file);
    if (onRemove) {
      setLoading(true);
      const deletingData = maxFiles === 1 ? value : (value as any[])[id];
      const result = await onRemove(deletingData)
        .then(() => true)
        .catch((err) => {
          ToastService.showHttpError(err, 'Deleting file failed');
        });
      setLoading(false);
      if (!result) {
        return;
      }
    }

    if (maxFiles === 1) {
      setFiles([]);
      onChange(null);
    } else {
      const newFiles = files.filter((item) => item !== file);
      setFiles(newFiles);
      const newValue = (value as any[]).filter((_, i) => i !== id);
      onChange(newValue);
    }
  };

  return (
    <>
      {files?.length ? (
        <>
          <p className="mb-2 text-xs">Audio disclaimer file uploaded</p>
          <div className="p-3 rounded-lg bg-gray-f8">
            {files.map((file, i) => (
              <div key={i} className={classnames('flex items-center mt-1', { 'text-gray': disabled })}>
                <div className="flex-grow text-sm font-bold truncate mr-3">{file.name}</div>
                <button
                  className={classnames(
                    'w-6 h-6 flex-center flex-shrink-0 rounded ml-auto',
                    disabled ? 'bg-gray-f6' : 'bg-[#f0e2e2] cursor-pointer'
                  )}
                  disabled={disabled}
                  onClick={(e) => onDeselectFile(e, file)}
                >
                  <TrashIcon color={disabled ? '#888' : 'danger'} size={10} />
                </button>
              </div>
            ))}
          </div>
          <p
            className="mt-4 text-sm font-bold text-blue cursor-pointer hover:underline"
            onClick={onClickDropzone}
          >
            Upload a new file
          </p>
        </>
      ) : (
        !files?.length && (
          <InputWrapper value={value} disabled={disabled} {...wrapperProps}>
            {({ containerClass, className, disabled, onBlur }) => (
              <ReactDropzone
                accept={accept}
                minSize={minSize}
                maxSize={maxSize}
                maxFiles={maxFiles}
                multiple={maxFiles > 1}
                disabled={loading || disabled}
                onDrop={onDrop}
                onFileDialogCancel={onBlur}
              >
                {(state) => (
                  <>
                    <div
                      className={classnames('MuiInput-root outline-none cursor-default', containerClass, className)}
                      {...state.getRootProps()}
                    >
                      <input {...state.getInputProps()} />
                      <div className="MuiInput-input" onClick={onClickDropzone}>
                        <div className="w-full">
                          {placeholder || (
                            <div className={classnames(
                              'flex-center flex-col',
                              disabled ? 'text-secondary' : 'text-primary cursor-pointer'
                            )}>
                              <CloudUploadIcon size={56} />
                              <p className="text-center text-base">
                                <span className="font-semibold">Drag and drop</span> files to upload, or <span className="font-semibold">click to select</span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {loading && (
                      <div className="absolute top-0 left-0 z-max w-full h-full bg-white bg-opacity-60 flex-center">
                        <CircularProgress
                          sx={{
                            color: '#0B1C34',
                          }}
                          size={40}
                          thickness={4}
                        />
                      </div>
                    )}
                  </>
                )}
              </ReactDropzone>
            )}
          </InputWrapper>
        )
      )}

      {showAssetsModal && (
        <SelectAudioModal
          audioType={audioType}
          onClose={() => setShowAssetsModal(false)}
          onSubmit={onSelectFileFromAssets}
        />
      )}
    </>
  );
};

