import React, { FC } from "react";
import { IIntegrationItems } from "..";
import { Switch } from "@mui/material";
import { CgArrowRight } from "react-icons/cg";
import { Link } from "react-router-dom";


const IntegrationsList: FC<IIntegrationItems> = ({
    text,
    subText,
    navigationURL
}) => {

    return (
        <Link to={navigationURL} className="flex shadow-md rounded-lg justify-between py-5 px-3 items-center mt-5">
            <div className="flex items-center">
                <p className="text-sm font-bold px-2">
                    {text}
                </p>

                <p className="text-xs font-light">
                    {subText}
                </p>
            </div>

            <div className="flex items-center">
                <div>
                    <div
                        className="w-7.5 h-7.5 flex-center flex-shrink-0 rounded-md ml-auto cursor-pointer mr-5"
                        >
                        <CgArrowRight className="text-2xl" />
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default IntegrationsList;