import {AUTH_ACTION} from '../action-types';
import {OrganizationModel} from "../../utils/types";

export interface IAuthState {
  token: string;
  organizations: OrganizationModel[];
  activeOrganization: OrganizationModel;
}

const initialState: IAuthState = {
  token: null,
  organizations: [],
  activeOrganization: null,
};

const authReducer = (state: IAuthState = initialState, action: any) => {
  switch (action.type) {
    case AUTH_ACTION.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case AUTH_ACTION.SET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.organizations,
        activeOrganization: state.activeOrganization,
      };
    }

    case AUTH_ACTION.SET_ACTIVE_ORGANIZATION: {
      return {
        ...state,
        activeOrganization: action.organization,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
