import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../components";
import { ArrowLeftIcon, ArrowRightIcon, EditIcon } from "../../../../components/icons";
import { INumberCreateForm } from "../index";
import CreatingNumberEditModal from "../EditModal";
import { ROUTES } from "../../../../constants";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { NUMBER_MODE } from "../../../../utils/enums";
import classNames from "classnames";

export interface INumberCreateCompleteStepProps {
  formData: INumberCreateForm;
  otpMode: NUMBER_MODE;
  addBillingInfo?: boolean;
  onBack(): void;
  onSubmit(data: any, callback?): void;
}

export const NumberCreateCompleteStep: FC<INumberCreateCompleteStepProps> = ({
  formData, otpMode, addBillingInfo, onBack, onSubmit,
}) => {
  const [data, setData] = useState(formData);
  const [showEditModal, setShowEditModal] = useState(false);
  const history = useHistory();

  const onEditFormData = (newData) => {
    setData({
      ...data,
      ...newData,
    });
    setShowEditModal(false);
  };

  const onContinue = () => {
    onSubmit(data);
  };

  const onAddBillingInfo = () => {
    onSubmit(data, () => {
      history.push(ROUTES.ONBOARD.SETUP_PAYMENT);
    });
  };

  return (
    <>
      <div className="h-full flex flex-col px-2">
        <div className="h-0 flex-grow overflow-auto">
          <div className="text-center">
            <p className="text-sm">Number setup</p>
            <h6 className="text-blue text-4xl leading-tight font-bold">Confirm your number setup</h6>
          </div>
          <div className="mx-4 mt-10">
            <div className={classNames(otpMode === NUMBER_MODE.OTP && "mx-50", "flex-center max-md:flex-col")}>
              <div className={"flex-auto text-center md:w-0 bg-gray-f8 rounded-lg shadow-md px-8 py-4 border-blue border-2"}>
                {otpMode === NUMBER_MODE.NORMAL &&
                  <div className="text-xs">When people call this number,</div>
                }
                <div className="text-blue text-4xl font-bold my-2">{data.favouriteNumber ? formatPhoneNumber(data.favouriteNumber) : `+${data.country.code} ${data.prefix} XXX XXXX`}</div>
              </div>
              {otpMode === NUMBER_MODE.NORMAL &&
                <>
                  <ArrowRightIcon className="mx-4 max-md:rotate-90 max-md:m-2" color="blue" size={32} />
                  <div className="md:w-0 text-center flex-grow bg-gray-f8 rounded-lg shadow-md px-8 py-4 border-primary border-2">
                    <div className="text-xs">the call will be forwarded to</div>
                    <div className="text-primary text-4xl font-bold my-2">{formatPhoneNumber(data.target)}</div>
                  </div>
                </>
              }
            </div>

            <div className="bg-gray-ef flex items-center justify-between px-4 py-2 mt-8">
              <div className="font-semibold">Review the details of your new number:</div>
              <EditIcon className="cursor-pointer" color="primary" size={24} onClick={() => setShowEditModal(true)} />
            </div>

            <div className="grid xs:grid-cols-2 sm:!grid-cols-3 md:!grid-cols-5 gap-x-4 gap-y-0 mt-4">
              <div className="p-2">
                <div className="text-sm font-bold">Sudo Number</div>
                <div className="text-gray text-xs mt-1">{data.favouriteNumber ? formatPhoneNumber(data.favouriteNumber) : `+${data.country.code} ${data.prefix} XXX XXXX`}</div>
              </div>
              {otpMode === NUMBER_MODE.NORMAL &&
                <div className="p-2">
                  <div className="text-sm font-bold">Target Number</div>
                  <div className="text-gray text-xs mt-1">{formatPhoneNumber(data.target)}</div>
                </div>
              }
              <div className="p-2">
                <div className="text-sm font-bold">Name of number</div>
                <div className="text-gray text-xs mt-1">{data.name}</div>
              </div>
              {otpMode === NUMBER_MODE.NORMAL &&
                <>
                  <div className="p-2">
                    <div className="text-sm font-bold">Call recording</div>
                    <div className="text-gray text-xs mt-1">{data.recordCalls ? `"${data.recordCalls}"` : '-'}</div>
                  </div>
                  <div className="p-2">
                    <div className="text-sm font-bold">Call Whisper</div>
                    <div className="text-gray text-xs mt-1">{data.whisperMessage ? `"${data.whisperMessage}"` : '-'}</div>
                  </div>
                </>
              }
              {otpMode === NUMBER_MODE.OTP &&
                <div className="p-2">
                  <div className="text-sm font-bold">Feature</div>
                  <div className="text-gray text-xs mt-1">OTP</div>
                </div>
              }
            </div>
          </div>
        </div>

        {addBillingInfo ? (
          <div className="absolute w-full bottom-0 left-0 flex items-center bg-primary rounded-b-md px-10 py-2">
            <div className="text-white text-lg font-semibold">
              To activate your number, please add your billing info
            </div>
            <Button
              color="white"
              className="text-sm font-semibold rounded-full shadow-md ml-auto"
              onClick={onAddBillingInfo}
            >
              Add billing info
            </Button>
          </div>
        ) : (
          <div className="flex mt-10">
            <Button className="text-primary text-sm ml-auto" onClick={onBack}>
              <ArrowLeftIcon className="mr-1" size={14} /> Back
            </Button>
            <Button
              data-cy="create-number-done"
              color="primary"
              className="text-sm rounded-full shadow-md px-6 ml-2 !font-bold"
              onClick={onContinue}
            >
              Buy Your Number
            </Button>
          </div>
        )}
      </div>

      {showEditModal && (
        <CreatingNumberEditModal
          otpMode={otpMode}
          formData={data}
          onClose={() => setShowEditModal(false)}
          onSubmit={onEditFormData}
        />
      )}
    </>
  );
};

