import React, { FC } from "react";
import classnames from "classnames";
import {LogoOutlineIcon} from "../../components/icons";

export interface IAuthLayoutProps {
  contentClass?: string;
  contentWrapperClass?: string;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  contentClass = '',
  contentWrapperClass = '',
  children,
}) => {
  return (
    <div className="auth-layout w-full h-screen flex">
      <div className="relative max-md:w-full max-w-full md:max-w-[80%] h-full flex-center bg-white rounded-r-md">
        <div className={classnames('overflow-auto pl-30 pr-40 py-12 max-md:py-8 max-md:px-12', contentWrapperClass)}>
          <div className={classnames('max-w-full', contentClass)}>
            {children}
          </div>
        </div>
        <LogoOutlineIcon className="max-md:hidden absolute -right-15 z-10" size={120} color={['primary', '#273c5c']} />
      </div>
      <div
        className="max-md:hidden w-0 flex-grow bg-cover bg-left -ml-2"
        style={{ backgroundImage: 'url(/assets/images/auth-bg.png)' }}
      />
    </div>
  );
};

export default AuthLayout;
