import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import BillingInfo from "./index";
import CardDetails from "./CardDetails";
import AutoRecharging from "./AutoRecharging";
import AddFunds from "./AddFunds";

const BillingInfoRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.BILLING.INDEX} component={BillingInfo} />
    <Route exact path={ROUTES.SETTINGS.BILLING.CARD} component={CardDetails} />
    <Route exact path={ROUTES.SETTINGS.BILLING.AUTO_RECHARGING} component={AutoRecharging} />
    <Route exact path={ROUTES.SETTINGS.BILLING.ADD_FUNDS} component={AddFunds} />
  </Switch>
);

export default BillingInfoRouting;
