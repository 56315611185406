import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const ImageIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color = "#FFFFFF") => (
      <svg width={width} height={height} viewBox="0 0 61.404 46.053">
        <path
          d="M55.648,50.553H5.757A5.757,5.757,0,0,1,0,44.8V10.257A5.757,5.757,0,0,1,5.757,4.5H55.648A5.757,5.757,0,0,1,61.4,10.257V44.8A5.757,5.757,0,0,1,55.648,50.553ZM13.432,11.216a6.716,6.716,0,1,0,6.716,6.716A6.716,6.716,0,0,0,13.432,11.216ZM7.676,42.878H53.729V29.445l-10.5-10.5a1.439,1.439,0,0,0-2.035,0L24.945,35.2l-6.658-6.658a1.439,1.439,0,0,0-2.035,0L7.676,37.121Z"
          transform="translate(0 -4.5)"
          fill={color} />
      </svg>

    )}
  </Icon>
);
