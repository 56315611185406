import React, {FC, useEffect, useState} from "react";
import moment from "moment";
import {Dropdown} from "../../../components";
import {CustomDateRangeSelectDropdown} from "./CustomSelectDropdown";
import {FilterModel} from "../../../utils/types";

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

interface IDateRangeFilterProps {
  title?: string;
  category?: string;
  opened?: boolean;
  value: FilterModel,
  onChange?: (value: FilterModel) => void;
}

const options = [
  {
    text: 'The last 30 days',
    getRange() {
      return {
        startDate: moment().subtract(30, 'days').startOf('day').format(),
        endDate: moment().endOf('day').format(),
      };
    },
  },
  {
    text: 'Today',
    getRange() {
      return {
        startDate: moment().startOf('day').format(),
        endDate: moment().endOf('day').format(),
      };
    },
  },
  {
    text: 'Yesterday',
    getRange() {
      return {
        startDate: moment().subtract(1, 'day').startOf('day').format(),
        endDate: moment().subtract(1, 'day').endOf('day').format(),
      };
    },
  },
  {
    text: 'Last 7 Days',
    getRange() {
      return {
        startDate: moment().subtract(7, 'days').startOf('day').format(),
        endDate: moment().endOf('day').format(),
      };
    },
  },
  {
    text: 'This Month',
    getRange() {
      return {
        startDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(),
      };
    },
  },
  {
    text: 'Last Month',
    getRange() {
      return {
        startDate: moment().subtract(1, 'month').startOf('month').format(),
        endDate: moment().subtract(1, 'month').endOf('month').format(),
      };
    },
  },
  { text: 'Custom' },
];

export const DateRangeFilter: FC<IDateRangeFilterProps> = ({
  category = 'range',
  opened,
  value,
  onChange = () => {},
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(opened);
  const [showCustomSelect, setShowCustomSelect] = useState(false);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  const onSelectOption = (option) => {
    if (option.text === 'Custom') {
      setShowCustomSelect(true);
    } else {
      onChange({
        category,
        text: option.text,
        value: option.getRange ? option.getRange() : null,
      });
      setDropdownOpened(false);
      setShowCustomSelect(false);
    }
  };

  const onSelectRange = (range) => {
    onChange({
      category,
      text: `${moment(range.startDate).format('D MMMM yyyy')} - ${moment(range.endDate).format('D MMMM yyyy')}`,
      value: range,
    });
    setDropdownOpened(false);
    setShowCustomSelect(false);
  };

  const onOpenDropdown = () => {
    setDropdownOpened(true);
    setShowCustomSelect(false);
  };

  return (
    <Dropdown
      className="mb-2"
      text={value?.text || 'Select Date Range'}
      buttonProps={{
        className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4',
        color: 'primary',
        variant: 'outline',
      }}
      dropdownClass={showCustomSelect ? '!w-auto !h-auto max-h-screen' : 'w-full'}
      dropdownOpened={dropdownOpened}
      onDropdownOpened={onOpenDropdown}
      onDropdownClosed={() => setDropdownOpened(false)}
    >
      {showCustomSelect ? (
        <CustomDateRangeSelectDropdown
          value={value.value}
          onChange={onSelectRange}
        />
      ) : (
        options.map((item, index) => (
          <div
            key={index}
            className="text-xs hover:text-primary px-3 py-2 cursor-pointer transition-all"
            onClick={() => onSelectOption(item)}
          >
            {item.text}
          </div>
        ))
      )}
    </Dropdown>
  );
};
