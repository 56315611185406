import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, HorizontalIconStepper, NumberTile } from "../../../../components";
import { AngleLeftIcon, OpenLinkIcon } from "../../../../components/icons";
import {
    WAOnboardingFailed,
    WAOnboardingStep1,
    WAOnboardingStep2,
    WAOnboardingStep3,
    WAOnboardingTile1,
    WAOnboardingTile2,
    WAOnboardingTile3
} from "../../../../components/svgs";
import { ToastService, WAEmbeddedOnboardingService } from "../../../../services";

declare global {
    interface Window {
        FB: any;
    }
}

interface IRenderComponentProps {
    index: number;
    success: boolean;
    setSuccess: (res?: any) => void;
}

const RenderComponent: FC<IRenderComponentProps> = ({
    index,
    success,
    setSuccess,
}) => {
    switch (index) {
        default:
            return (
                <Card className="!p-12 w-full mt-16">
                    <div className="text-blue text-28p font-semibold whitespace-pre-wrap">
                        Let's get you started with WhatsApp Business API
                    </div>
                    <div className="my-8">
                        <p className="!font-bold !pb-1">WhatsApp Business API</p>
                        <p>WhatsApp is one of the most popular business communication applications in the world. WhatsApp is a Facebook company and requires a Facebook account and Facebook Business Manager to set up.</p>
                    </div>
                    <div className="bg-gray-300 h-[1px] w-full my-10" />
                    <div className="my-8">
                        <p className="!font-bold !pb-1">What you will need:</p>
                        <div className="mt-4 space-y-4">
                            <div className="flex flex-row justify-start items-center gap-3">
                                <NumberTile value={'1'} />
                                <span>
                                    Access to Facebook Business Manager
                                </span>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-3">
                                <NumberTile value={'2'} />
                                <span>
                                    A viable Phone Number for WhatsApp
                                </span>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-3">
                                <NumberTile value={'3'} />
                                <span>
                                    Your business's legal address and details
                                </span>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-3">
                                <NumberTile value={'4'} />
                                <span>
                                    Select the numbers you want to connect
                                </span>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-3">
                                <NumberTile value={'5'} />
                                <span>
                                    Verify your Facebook Business
                                </span>
                            </div>
                        </div>
                    </div>
                </Card >
            )

        case 1:
            return (
                <Card className="!p-12 w-full">
                    <div className="text-blue text-28p font-semibold whitespace-pre-wrap">
                        Ensure you have a valid and dedicated phone number to use WhatsApp Business API
                    </div>
                    <div className="my-8">
                        <p className="!font-bold !pb-1">Phone Number for WhatsApp</p>
                        <p>You will need a phone number for your WhatsApp channel with the following specifications:</p>
                    </div>
                    <div className="bg-gray-300 h-[1px] w-full my-10" />
                    <div className="mt-4 space-y-4">
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'1'} />
                            <span>
                                This phone number needs to be <span className="!font-bold">owned by your company</span> - you should not use your personal phone number
                            </span>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'2'} />
                            <span>
                                The phone number for your business must be a <span className="!font-bold">valid phone number</span> which meets the <a href="https://developers.facebook.com/docs/whatsapp/cloud-api/phone-numbers" target="_blank" className="text-primary !font-bold" rel="noreferrer">WhatsApp Business Platform criteria <OpenLinkIcon /></a>
                            </span>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'3'} />
                            <span>
                                You must be able to <span className="!font-bold">receive phone calls</span> or <span className="!font-bold">SMS</span> to the phone number when proceeding
                            </span>
                        </div>
                    </div>
                </Card>
            )

        case 2:
            return (
                <Card className="!p-12 w-full">
                    <div className="text-blue text-28p font-semibold whitespace-pre-wrap">
                        Facebook Business Manager
                    </div>
                    <div className="my-8">
                        <p>You will need to log in to your Facebook account, <span className="!font-bold">using your personal details</span>, and have access to your company's Facebook Business Manager</p>
                    </div>
                    <div className="bg-gray-300 h-[1px] w-full my-10" />
                    <div className="mt-4 space-y-4">
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'1'} />
                            <span>
                                You will have to confirm access to the requirements for WhatsApp, <span className="!font-bold">We will guide you step-by-step.</span>
                            </span>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'2'} />
                            <span>
                                After entering your dedicated WhatsApp Number, you will be asked for an <span className="!font-bold">OTP.</span>
                            </span>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-3">
                            <NumberTile value={'3'} />
                            <span>
                                Once you have <span className="!font-bold">confirmed and completed</span> all steps, you can close your window.
                            </span>
                        </div>
                    </div>
                </Card>
            )

        case 3:
            return (
                <>
                    {success === null &&
                        <Card className="!p-12 w-full">
                            <div className="text-blue text-28p font-semibold whitespace-pre-wrap">
                                Prepare your business details
                            </div>
                            <div className="my-8">
                                <p>Before continuing, you will be required to fill in the following information.</p>
                            </div>
                            <div className="bg-gray-300 h-[1px] w-full my-10" />
                            <div className="mt-4 space-y-4">
                                <div className="flex flex-row justify-start items-center gap-3">
                                    <NumberTile value={'1'} />
                                    <span>
                                        Your brand's <span className="!font-bold">display name</span>, shown to customers you chat with
                                    </span>
                                </div>
                                <div className="flex flex-row justify-start items-center gap-3">
                                    <NumberTile value={'2'} />
                                    <span>
                                        Your company's <span className="!font-bold">legal business name,</span>
                                    </span>
                                </div>
                                <div className="flex flex-row justify-start items-center gap-3">
                                    <NumberTile value={'3'} />
                                    <span>
                                        Your company's <span className="!font-bold">official address.</span>
                                    </span>
                                </div>
                                <div className="flex flex-row justify-start items-center gap-3">
                                    <NumberTile value={'4'} />
                                    <span>
                                        You must have a valid business website.
                                    </span>
                                </div>
                            </div>
                        </Card>
                    }
                    {success === false &&
                        <>
                            <WAOnboardingFailed />
                            <div className="my-8 text-blue text-28p font-bold text-center whitespace-pre-wrap">
                                Oops! Something went wrong
                            </div>
                            <p>If you need any assistance linking your WhatsApp Business {'\n'} Account, please contact <a href="mailto:support@sudonum.com" className="font-bold text-primary">support@sudonum.com</a></p>
                            <Button
                                color="primary"
                                className="rounded-full shadow-lg mt-14 mb-6 !px-6 !font-semibold"
                                onClick={() => setSuccess(null)}
                            >
                                Link your account
                            </Button>
                        </>
                    }
                    {success === true &&
                        <>
                            <Card className="!p-12 w-full">
                                <p className="mt-4 text-success text-28p font-bold text-center whitespace-pre-wrap">
                                    Yay!
                                </p>
                                <p className="text-28p text-center whitespace-pre-wrap my-8">
                                    You have <span className="!font-semibold text-success">successfully</span> linked your WhatsApp Business Account!
                                </p>
                                <p className="mt-4 text-blue text-24p font-bold text-center">
                                    What is next?
                                </p>
                                <div className="my-8">
                                    <p className="!font-bold !pb-1">Add payment details</p>
                                </div>
                                <div className="mt-4 space-y-4">
                                    <div className="flex flex-row justify-start items-center gap-3">
                                        <NumberTile value={'1'} />
                                        <span>
                                            To enable us to do testing on your account, please <span className="!font-bold">add your credit card details</span> to your Facebook Business Manager <a href="https://business.facebook.com/billing_hub/accounts/details/" target="_blank" className="!font-bold text-primary" rel="noreferrer">here <OpenLinkIcon /></a>
                                        </span>
                                    </div>
                                </div>
                                <div className="my-8">
                                    <p className="!font-bold !pb-1">Go verify your Facebook Business account with Meta</p>
                                </div>
                                <div className="mt-4 space-y-4">
                                    <div className="flex flex-row justify-start items-center gap-3">
                                        <NumberTile value={'1'} />
                                        <span>
                                            As per Meta's policy, an unverified business account can only be linked to two numbers, <a href="https://business.facebook.com/settings/whatsapp-business-accounts/" target="_blank" className="!font-bold text-primary" rel="noreferrer">Verify your account</a><OpenLinkIcon color={'primary'} className="mr-1" /> <span className="!font-bold">to link your account with more numbers</span>
                                        </span>
                                    </div>
                                    <div className="flex flex-row justify-start items-center gap-3">
                                        <NumberTile value={'2'} />
                                        <span>
                                            It is required that you <span className="!font-bold">verify your account with Meta</span> as soon as possible. A verified business can start to send 1k messages /day. If you do not verify your account, Meta will disable your account.
                                        </span>
                                    </div>
                                </div>
                            </Card>
                            <Link
                                to={'..'}
                                className="rounded-full shadow-lg my-6 !px-6 py-2 !font-bold uppercase text-white bg-primary"
                            >
                                Go to accounts
                            </Link>
                        </>
                    }
                </>
            )
    }
}

export const AccountSetup: FC = () => {

    const [code, setCode] = useState<string>('');
    const [phoneNumberId, setPhoneNumberId] = useState<string>('');
    const [wabaId, setWabaId] = useState<string>('');
    const [flag, setFlag] = useState<boolean>(false);


    //stepper component
    const stepIcons = [
        <WAOnboardingStep1 key={'step1'} />,
        <WAOnboardingStep2 key={'step2'} />,
        <WAOnboardingStep3 key={'step3'} />
    ]

    //user journey
    const [index, setIndex] = useState<number>(0);
    const [success, setSuccess] = useState<boolean>(null);

    const onWhatsAppSignUp = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                const accessToken = response.authResponse.code;
                setCode(accessToken);
                setFlag(true);
            } else {
                setSuccess(false);
                ToastService.error("Embedded sign up failed.")
            }
        }, {
            config_id: '723191849827879',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                sessionInfoVersion: 2,
            },
        });
    }

    const sessionInfoListener = (event) => {
        if (event.origin !== "https://www.facebook.com") return;
        try {
            const data = JSON.parse(event.data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    const { phone_number_id, waba_id } = data.data;
                    setPhoneNumberId(phone_number_id);
                    setWabaId(waba_id);
                }
                else {
                    const { current_step } = data.data;
                    setSuccess(false);
                    ToastService.error("Embedded sign up failed on step: ", current_step)
                }
            }
        } catch {
            ToastService.error("Embedded sign up flow failed.")
        }
    };

    useEffect(() => {
        window.addEventListener('message', sessionInfoListener);
    }, [])

    useEffect(() => {
        if (flag) {
            WAEmbeddedOnboardingService.create({
                code: code,
                phonenumber_id: phoneNumberId,
                waba_id: wabaId,
            }).then((res) => {
                setSuccess(true);
            }).catch((err) => {
                setSuccess(false);
                ToastService.showHttpError(err, "Failed to complete Whatsapp Embedded Onboarding.")
            })
            return
        }
    }, [flag])

    return (
        <div className={(index === 3 && success !== null) ? "w-full h-full flex flex-col justify-center items-center" : "w-3/4 h-full mx-auto"}>
            {index > 0 && success === null &&
                <div className="w-full grid place-items-start">
                    <HorizontalIconStepper
                        activeStep={index - 1}
                        steps={stepIcons}
                        className={"w-1/3 xs:w-3/4 md:w-1/2 lg:w-1/5 mt-26 mb-8 -ml-10 md:-ml-20 lg:-ml-26"}
                    />
                </div>
            }
            <RenderComponent
                index={index}
                success={success}
                setSuccess={(res) => setSuccess(res)}
            />
            {success === null &&
                <div className="flex flex-row mr-0 ml-auto">
                    <Button
                        className="mr-0 ml-auto mt-auto mb-0 text-primary"
                        onClick={() => setIndex((prevState) => prevState - 1)}
                    >
                        <AngleLeftIcon size={20} color="primary" />
                        Back
                    </Button>
                    <Button
                        color="primary"
                        className="rounded-full shadow-lg"
                        onClick={() => index === 3 ? onWhatsAppSignUp() : setIndex((prevState) => prevState + 1)}
                    >
                        Next
                    </Button>
                </div>
            }
        </div>
    )
}
