import React, { FC, useMemo, useState } from "react";
import { Button, Modal } from "../../components";
import BlockedNumberForm, { IBlockedNumberFormInitParams } from "../../pages/Settings/BlockedNumbers/BlockedNumberForm";
import { ToastService } from "../../services";
import { BlockedNumberModel } from "../../utils/types";

interface IBlockNumberModalProps {
  title?: string;
  defaults: any;
  onClose: (result?: any) => void;
}

export const BlockNumberModal: FC<IBlockNumberModalProps> = ({
  title,
  defaults,
  onClose,
}) => {
  const [createForm, setCreateForm] = useState<IBlockedNumberFormInitParams>({
    getFormData: () => null,
  });

  const blockedNumber = new BlockedNumberModel(defaults)

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      BlockedNumberModel.create(data).then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> marked as spam.</>);
        onClose(data);
      }).catch(err => {
        ToastService.showHttpError(err, "Create failed.");
      })
    }
  };

  return (
    <Modal
      title={title || 'Mark number as spam'}
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onCreate}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedNumberForm blockedNumber={blockedNumber} onChange={setCreateForm} onClose={() => onClose()} />
    </Modal>
  );
};
