import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import classnames from "classnames";
import { Avatar, AvatarGroup, DataTable, IDataTableColumn, TextField } from "../../../components";
import { EditIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { ROLE } from '../../../utils/enums';
import { AuthService, ProjectService, ToastService } from '../../../services';
import { formatDateTime } from "../../../utils/helpers";
import { ProjectModel } from "../../../utils/types";
import { setOrganizations } from '../../../redux/actions';
import { Tooltip } from '@mui/material';
import {debounce} from "lodash";

const Projects = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getActiveOrganization);
  const [search, setSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState('');
  const [projects, setProjects] = useState<ProjectModel[]>([]);

  const debouncedSetFilter = useCallback(
    debounce((searchEmail) => setFilterSearch(searchEmail), 500),
    []);

  useEffect(() => {
    if (!organization) {
      setProjects([]);
      return;
    }

    ProjectService.search(organization.uuid, filterSearch).then((data) => {
      setProjects([organization, ...data]);
    }, (err) => {
      setProjects([]);
      ToastService.showHttpError(err, 'Loading projects failed');
    });
  }, [organization, filterSearch]);

  const columns = useMemo<IDataTableColumn<ProjectModel>[]>(() => [
    { title: 'Project Name', field: 'name', tooltip: { title: 'The project’s name' } },
    {
      title: 'Users',
      field: 'users',
      tooltip: { title: 'The users assigned to this project' },
      render(row: ProjectModel) {
        return (
          <AvatarGroup size="xs" max={6} spacing={-4} backgrounds={['#5C5C5C', '#8D8D8D', '#D1D1D1', '#E3E3E3']}>
            {([{}, {}, {}, {}] as any).map((user, i) => (
              <Avatar key={i} src={user.avatar} />
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      title: 'Date Created',
      field: 'created',
      tooltip: { title: 'The date the project was created' },
      render(row: ProjectModel) {
        return formatDateTime(row.created);
      },
    },
    {
      render(row: ProjectModel) {
        return (
          <Tooltip data-cy="edit-project" title="Edit Project" arrow placement="left">
            <Link
              className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-auto cursor-pointer"
              to={`${ROUTES.SETTINGS.PROJECTS.PREFIX}/${row.uuid}/edit`}
            >
              <EditIcon color="blue" size={22} />
            </Link>
          </Tooltip>
        );
      },
    },
  ], []);

  const onChange = (value) => {
    setSearch(value);
    debouncedSetFilter(value);
  };

  return (
    <>
      <div className="relative h-full flex flex-col px-7 py-9">
        <div>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search"
            clearable
            onChange={onChange}
          />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          data={projects}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!text-sm !px-8"
        />

        <Link
          data-cy="create-project"
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-64 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          to={ROUTES.SETTINGS.PROJECTS.CREATE}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Add New Project</span>
        </Link>
      </div>
    </>
  );
};

export default Projects;
