import { MenuItem } from "@mui/material";
import classnames from "classnames";
import React, { FC } from "react";
import { Button, Input } from "../../../../components";
import { Form, FormControl, useForm } from "../../../../components/ReactiveForm";
import { ArrowLeftIcon } from "../../../../components/icons";
import { NumberService, ToastService } from "../../../../services";
import { ILocationInfo } from "../../../../utils/interfaces";
import { INumberCreateForm } from "../index";

export interface INumberCreateStep2Props {
  formData: INumberCreateForm;
  prefixOptions: ILocationInfo[];
  setPrefixOptions(data: ILocationInfo[]): void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<INumberCreateForm>): void;
}


export const NumberCreateStep2: FC<INumberCreateStep2Props> = ({
  formData,
  prefixOptions,
  setPrefixOptions,
  onBack,
  onNext,
  onSubmit,
}) => {
  const [form] = useForm<{
    index: FormControl;
  }>({
    index: new FormControl<number>(1),
  });

  const onContinue = data => {
    const prefix_obj = prefixOptions[data.index - 1];

    const submitData = {
      available_dids: prefix_obj.available_dids_enabled,
      prefix: prefix_obj.prefix,
    }
    onSubmit(submitData);
    onNext();
  };

  const loadPrefixOptions = React.useCallback((countryCode) => {
    setPrefixOptions(undefined);
    NumberService.getValidLocations({
      country_code: countryCode.replace(/\D/g, ''),
      capabilities: 'voice',
      type: formData.type,
    }).then((data) => {
      if (data.length > 0) {
        setPrefixOptions(data);
        form.patch({
          prefix: data[0].prefix,
        })

        return;
      }
      setPrefixOptions([]);
    }).catch((err) => {
      setPrefixOptions([]);
      ToastService.showHttpError(err, 'Loading prefix options failed');
    });
  }, [formData]);

  React.useEffect(() => {
    form.patch({
      name: formData.name,
      target: formData.target,
    });
  }, [formData]);

  React.useEffect(() => {
    if (formData.favouriteNumberOptions) {
      setPrefixOptions(formData.favouriteNumberOptions.prefixOptions);
    } else {
      loadPrefixOptions(formData.country.code);
    }
  }, [formData, loadPrefixOptions]);

  return (
    <Form className="h-full flex flex-col" formGroup={form} onSubmit={onContinue}>
      <div className="h-full flex flex-col">
        <div className="h-0 flex-grow text-center overflow-auto">
          <h5 className="text-sm font-semibold mt-4">Number Setup</h5>
          <p className="text-blue text-4xl font-bold">Choose a prefix for your number</p>

          <div className="max-w-132 mt-10 mx-auto">
            <Input
              type="select"
              control={form.controls.index}
              fullWidth
              label="Please choose a city from the list below"
              labelClass="text-xs font-semibold !pb-2"
              inputClass="!text-sm !font-normal "
              className={classnames(
                'rounded bg-[#F5F5F5]',
                { '!border-danger': prefixOptions && !prefixOptions.length },
              )}
            >
              {prefixOptions?.map((option, i) => (
                <MenuItem key={i} className="!text-xs" value={i + 1}>{option.prefix} - {option.location?.split('-')[1]?.trim() || 'Unknown'}</MenuItem>
              ))}
            </Input>
          </div>
        </div>
        <div className="flex mt-4 mx-4">
          <Button className="text-primary text-sm ml-auto" onClick={onBack}>
            <ArrowLeftIcon className="mr-1" size={14} /> Back
          </Button>
          <Button data-cy="create-number-step-2" type="submit" color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" >
            Continue
          </Button>
        </div>
      </div>
    </Form>
  );
};
