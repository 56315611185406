import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const UploadCsvTipSvg: FC<IIconProps> = (props) => (
    <Icon baseWidth={37} baseHeight={37} {...props}>
        {(width, height) => (
            <svg width={width} height={height} viewBox="0 0 27 27">
                <g transform="translate(-637.336 -252)">
                    <rect
                        width="27"
                        height="27"
                        rx="6"
                        transform="translate(637.336 252)"
                        fill="#d6e3f4"
                    //style="mix-blend-mode: multiply;isolation: isolate"
                    />
                    <path
                        d="M2.7,12.771a.9.9,0,0,0,.151.5l.48.722a.9.9,0,0,0,.749.4H5.815a.9.9,0,0,0,.749-.4l.48-.722a.9.9,0,0,0,.151-.5V11.693H2.7ZM0,4.947A4.92,4.92,0,0,0,1.224,8.2a8.078,8.078,0,0,1,1.468,2.571l0,.022H7.2l0-.022A8.078,8.078,0,0,1,8.67,8.2,4.946,4.946,0,1,0,0,4.947ZM4.947,2.7A2.251,2.251,0,0,0,2.7,4.947a.45.45,0,0,1-.9,0A3.152,3.152,0,0,1,4.947,1.8a.45.45,0,0,1,0,.9Z"
                        transform="translate(645.926 258.34)"
                        fill="#307cd9"
                    />
                </g>
            </svg>
        )}
    </Icon>
)