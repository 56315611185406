import React, {useState} from "react";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import UserForm, {IUserFormInitParams} from "../UserForm";
import {Button} from "../../../../components";
import {CloseCircleIcon} from "../../../../components/icons";
import {ROUTES} from "../../../../constants";
import {getActiveOrganization} from "../../../../redux/selectors";
import {ToastService, UserService} from "../../../../services";
import {RelationshipModel} from "../../../../utils/types";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";

const UserCreate = () => {
  const history = useHistory();
  const organization = useSelector(getActiveOrganization);
  const [relationships, setRelationships] = useState<RelationshipModel[]>([]);
  const [createForm, setCreateForm] = useState<IUserFormInitParams>({
    getFormData: () => null,
  });
  const [touched, setTouched] = useState(false)
  const [projectsTouched, setprojectsTouched] = useState<boolean>(true)
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false)


  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(bypass, touched, projectsTouched, setShowConfirmCancel, history, ROUTES.SETTINGS.USERS.INDEX)

  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      data.relationships = relationships.map(x => (
        { org_uuid: x.org_uuid, roles: x.roles }
      )).filter(x => x.roles.length > 0)

      UserService.create(organization.uuid, data).then((data) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> created new user.</>);
        onClose(true);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Creating user failed');
      });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Invite New User</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={() => onClose()} />
      </div>
      <div className="h-0 flex-grow overflow-auto px-6">
        <div className="w-172 max-w-full mx-auto my-6">
          <UserForm
            relationships={relationships}
            onRelationshipsUpdate={(relationships) => setRelationships(relationships)}
            onInit={setCreateForm}
            onClose={onClose}
            setTouched={setTouched}
            setprojectsTouched={setprojectsTouched}
            showConfirmCancel={showConfirmCancel}
            setShowConfirmCancel={setShowConfirmCancel}
            create
          />
        </div>
      </div>
      <div className="flex items-center px-7 py-3">
        <Button
          data-cy="invite"
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={onCreate}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};

export default UserCreate;
