import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const WhatsAppSettingIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={94.731} baseHeight={89.511} {...props}>
    {(width, height, color, color2) => (
      <svg width={width} height={height} viewBox="0 0 94.731 89.511">
        <g transform="translate(-658.969 -330.188)">
          <g transform="translate(381.969 -69.858)">
            <path fill={color2}
                  d="M277,437.112c.2-1.494.338-3,.614-4.478a38.875,38.875,0,0,1,18.737-27.113,37.519,37.519,0,0,1,21.761-5.5,38.632,38.632,0,0,1,27.943,13.385,37.658,37.658,0,0,1,9.632,22.05,39.555,39.555,0,0,1-32.963,42.916c-1.311.22-2.64.34-3.959.505h-4.624c-1.093-.137-2.186-.255-3.275-.412a39.483,39.483,0,0,1-14.237-4.922,1.523,1.523,0,0,0-1.068-.112c-2.02.583-4.023,1.222-6.034,1.838-2.965.91-5.928,1.832-8.9,2.722a1.518,1.518,0,0,1-2.011-1.95c.042-.175.091-.348.139-.521,1.319-4.768,2.627-9.541,3.976-14.3a1.981,1.981,0,0,0-.211-1.724,37.427,37.427,0,0,1-4.822-12.813c-.3-1.688-.469-3.4-.7-5.1Zm5.24,37.182c.373-.093.618-.143.855-.217,3.748-1.154,7.518-2.245,11.232-3.5a4.638,4.638,0,0,1,4.174.471,35.162,35.162,0,0,0,25.19,4.05,36.405,36.405,0,0,0-3.2-71.866,35.058,35.058,0,0,0-22.6,4.915c-10.85,6.673-16.85,16.443-17.76,29.158a34.875,34.875,0,0,0,5.386,21.282,3.081,3.081,0,0,1,.365,2.687C284.644,465.557,283.473,469.86,282.24,474.294Z"
                  transform="translate(0 0.074)"/>
            <path fill={color2}
                  d="M320.426,456.258a32.775,32.775,0,0,1-19.3-7.188,48.294,48.294,0,0,1-15.379-20.324c-2.222-5.437-1.458-10.671,1.384-15.658a40.707,40.707,0,0,1,3.039-4.246,1.7,1.7,0,0,1,.973-.686,44.689,44.689,0,0,1,4.5-.31A2.209,2.209,0,0,1,297.538,409a55.065,55.065,0,0,1,5.082,9.906,4.79,4.79,0,0,1-.635,4.405,19.011,19.011,0,0,1-2.235,2.83,1.3,1.3,0,0,0-.232,1.775,33.731,33.731,0,0,0,2.993,4.352,46.315,46.315,0,0,0,12.106,9.4c1.271.711,1.494.7,2.488-.424,1.359-1.534,2.659-3.122,3.919-4.738a3.165,3.165,0,0,1,3.987-1.209,14.192,14.192,0,0,1,2.159,1.167c2.666,1.638,5.318,3.3,7.982,4.943,1.081.667,1.252,1.078,1.005,2.362a16.675,16.675,0,0,1-3.235,7.558,13.2,13.2,0,0,1-9.275,4.721C322.581,456.174,321.5,456.193,320.426,456.258Zm.443-3.09a15.544,15.544,0,0,0,6.245-1.076c3.482-1.536,5.093-4.447,5.863-7.967a.725.725,0,0,0-.281-.6c-2.731-1.729-5.479-3.432-8.22-5.148-.519-.325-.836-.257-1.252.268a60.078,60.078,0,0,1-4.124,4.862c-1.87,1.9-3.187,2.14-5.242,1.11a44.682,44.682,0,0,1-15.01-11.935,13.351,13.351,0,0,1-2.556-4.875,2.931,2.931,0,0,1,.768-3.225,19.664,19.664,0,0,0,2.129-2.526,2.569,2.569,0,0,0,.329-2.946c-1.315-2.494-2.634-4.987-3.888-7.511a1.445,1.445,0,0,0-1.591-.842,2.829,2.829,0,0,0-2.731,1.45c-.8,1.488-1.807,2.875-2.488,4.411a13.488,13.488,0,0,0-.175,11.206A46.478,46.478,0,0,0,303.7,447.251,28.83,28.83,0,0,0,320.868,453.168Z"
                  transform="translate(6.712 7.165)"/>
          </g>
          <path fill={color}
                d="M31.6,21.024a10.034,10.034,0,0,0,.082-1.6c0-.562-.082-1.043-.082-1.6l3.446-2.647a.737.737,0,0,0,.164-1.043L31.933,8.59a.8.8,0,0,0-.985-.321l-4.1,1.6a11.942,11.942,0,0,0-2.789-1.6l-.575-4.252a.879.879,0,0,0-.821-.642H16.1a.879.879,0,0,0-.821.642L14.62,8.268a13.9,13.9,0,0,0-2.79,1.6l-4.1-1.6a.769.769,0,0,0-.985.321L3.46,14.125a.969.969,0,0,0,.164,1.043l3.528,2.647c0,.562-.082,1.043-.082,1.6s.082,1.043.082,1.6L3.707,23.671a.737.737,0,0,0-.164,1.043L6.825,30.25a.8.8,0,0,0,.985.321l4.1-1.6a11.941,11.941,0,0,0,2.789,1.6l.656,4.252a.8.8,0,0,0,.821.642h6.564a.879.879,0,0,0,.821-.642l.657-4.252a13.887,13.887,0,0,0,2.789-1.6l4.1,1.6a.769.769,0,0,0,.985-.321l3.282-5.536a.967.967,0,0,0-.164-1.043ZM19.379,25.035a5.617,5.617,0,1,1,0-11.231,5.617,5.617,0,1,1,0,11.231Z"
                transform="matrix(0.966, -0.259, 0.259, 0.966, 710.265, 386.317)"/>
        </g>
      </svg>
    )}
  </Icon>
);
