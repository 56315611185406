import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "../../../../components";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import AudioFileForm, { IAudioFileFormInitParams } from "../AudioFileForm";
import { AudioService, MessageBoxService, ToastService } from "../../../../services";
import { AudioModel } from "../../../../utils/types";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import { ROUTES } from "../../../../constants";

const AudioFileEdit = () => {
  const history = useHistory();
  const params = useParams<{ name: string, type: string }>();
  const [editForm, setEditForm] = useState<IAudioFileFormInitParams>({
    getFormData: () => null,
  });
  const [touched, setTouched] = useState<boolean>(false)
  const [audioTouched, setAudioTouched] = useState<boolean>(false)
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false)

  const audioFile = useMemo(() => new AudioModel(params), [params]);

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(bypass, touched, audioTouched, setShowConfirmCancel, history, ROUTES.SETTINGS.AUDIO_FILES.INDEX)
  };

  const onSave = () => {
    const data = editForm.getFormData();

    if (!data?.file) {
      ToastService.success(<>File unchanged.</>);
      onClose(true);
      return;
    }

    audioFile.update(data.file).then(() => {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
      onClose(true);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Editing audio file failed');
    });
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">audio file</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        AudioService.delete(audioFile.name, audioFile.type).then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose();
        }).catch((err) => {
          if (err?.response.data[0]) {
            if (err?.response.data[0].includes('This audio file is still') && err?.response.data[0]) {
              ToastService.error('This audio file cannot be deleted, because it is being used on a number. Please remove it from the number if you wish to delete it.');

            }
          }
          else {
            ToastService.showHttpError(err, 'Deleting audio file failed');
          }
        });
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Edit Audio File</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={() => onClose()} />
      </div>

      <div className="h-0 flex-grow overflow-auto">
        {audioFile && (
          <AudioFileForm
            fileType={'file'}
            audioFile={audioFile}
            setForm={setEditForm}
            onClose={onClose}
            confirmCancel={showConfirmCancel}
            setTouched={setTouched}
            setAudioTouched={setAudioTouched}
          />
        )}
      </div>

      <div className="flex items-center px-7 py-3">

        <Button
          data-cy="delete-audio-file"
          className="text-sm rounded-full mr-4"
          color="danger"
          variant="outline"
          leftIcon={<TrashIcon size={12} />}
          onClick={onDelete}
        >
          Delete Audio File
        </Button>
        <Button
          data-cy="save-audio-file"
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AudioFileEdit;
