import React, {FC, useEffect, useState} from "react";
import classnames from "classnames";
import {Tab, Tabs} from "@mui/material";
import {Button, Modal, Dropzone} from "../../components";
import {PlayCircleIcon} from "../../components/icons";
import {FullAudioPlayerModal} from "../FullAudioPlayerModal";
import {AudioService, ToastService} from "../../services";
import {AudioModel} from "../../utils/types";
import {AUDIO_TYPE} from "../../utils/enums";

interface ISelectAudioModalProps {
  audioType?: AUDIO_TYPE;
  onClose: () => void;
  onSubmit: (audio: AudioModel | File) => void;
}

export const SelectAudioModal: FC<ISelectAudioModalProps> = ({
  audioType,
  onClose,
  onSubmit,
}) => {
  const [tab, setTab] = useState('select');
  const [audios, setAudios] = useState<AudioModel[]>([]);
  const [selectedAudio, setSelectedAudio] = useState<AudioModel>();
  const [s3Url, setS3Url] = useState('');
  const [file, setFile] = useState<File>();

  useEffect(() => {
    AudioService.search().then((data) => {
      if (audioType) {
        setAudios(data.results.filter((audio) => audio.type === audioType));
      } else {
        setAudios(data.results);
      }
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading audios failed');
    });
  }, [audioType]);

  const onSave = () => {
    if (tab === 'select' && selectedAudio) {
      onSubmit(selectedAudio);
    } else if (tab === 'upload' && file) {
      onSubmit(file);
    } else {
      ToastService.error('Please select an audio file');
    }
  };

  const loadAudioFile = (audio: AudioModel) => {
    audio.getS3Url()
      .then(data => {
          setS3Url(data.url);
      })
  };

  return (
    <Modal
      title="Upload your files"
      contentClass="!p-0"
      hasCloseButton
      footerComponent={(
        <>
          <Button className="text-sm text-primary ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            className="text-sm rounded-3xl shadow-md px-6 uppercase"
            onClick={onSave}
          >
            {tab}
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={(_, value) => setTab(value)}
      >
        <Tab label="Choose from uploads" value="select" />
        <Tab label="Browse your computer" value="upload" />
      </Tabs>

      <div className="px-6 py-4">
        {tab === 'select' ? (
          <div>
            <h4 className="text-md font-semibold mb-3">Audio file uploads</h4>
            {audios.map((item, i) => (
              <div
                key={i}
                className={classnames(
                  'flex items-center bg-gray-f8 border rounded p-1 mt-2 transition-all cursor-pointer',
                  item === selectedAudio ? 'border-blue-light text-blue-light' : 'border-gray-f8',
                  'hover:!border-blue-light'
                )}
                onClick={() => setSelectedAudio(item)}
              >
                <div className="flex-grow text-sm truncate pl-2">{item.name}</div>
                <div
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-[#fdeace] rounded-md ml-3 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    loadAudioFile(item)
                  }}
                >
                  <PlayCircleIcon color="primary" size={22} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Dropzone
              fullWidth
              maxFiles={1}
              accept={['audio/*']}
              onChange={(files) => setFile(files ? files[0] : null)}
            />
          </div>
        )}
      </div>

      {s3Url && (
        <FullAudioPlayerModal src={s3Url} onClose={() => setS3Url(null)} />
      )}
    </Modal>
  );
};
