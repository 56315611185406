import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const SettingsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={23.619} baseHeight={23.619} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 23.619 23.619">
        <g transform="translate(-2)">
          <path
            d="M11.809,0A11.809,11.809,0,1,1,0,11.809,11.809,11.809,0,0,1,11.809,0Z"
            transform="translate(2)"
            fill={color} />
          <path
            d="M17.266,11.457a1.791,1.791,0,0,1,1.149-1.67,7.1,7.1,0,0,0-.859-2.069,1.814,1.814,0,0,1-.728.156,1.787,1.787,0,0,1-1.634-2.515A7.076,7.076,0,0,0,13.128,4.5a1.789,1.789,0,0,1-3.341,0,7.1,7.1,0,0,0-2.069.859A1.787,1.787,0,0,1,6.083,7.873a1.755,1.755,0,0,1-.728-.156A7.254,7.254,0,0,0,4.5,9.79a1.79,1.79,0,0,1,0,3.341A7.1,7.1,0,0,0,5.362,15.2a1.787,1.787,0,0,1,2.359,2.359,7.139,7.139,0,0,0,2.069.859,1.786,1.786,0,0,1,3.334,0,7.1,7.1,0,0,0,2.069-.859A1.789,1.789,0,0,1,17.552,15.2a7.139,7.139,0,0,0,.859-2.069A1.8,1.8,0,0,1,17.266,11.457Zm-5.776,2.9a2.9,2.9,0,1,1,2.9-2.9A2.9,2.9,0,0,1,11.49,14.352Z"
            transform="translate(2.352 0.35)"
            fill={color === "#F6A734" ? "#FFF" : "#0b1c34"} />
        </g>
      </svg>
    )}
  </Icon >
);
