import React, { FC } from "react";
import { Modal } from "../../../../components";
import AccountView from "../AccountView";
import { WhatsappAccountModel } from "../../../../utils/types";

interface IAccountViewModalProps {
  account: WhatsappAccountModel;
  onClose: () => void;
  onDelete: () => void;
}

const AccountViewModal: FC<IAccountViewModalProps> = ({
  account,
  onClose,
  onDelete
}) => {
  return (
    <Modal
      title={`Account details - ${account.display_name}`}
      size="full"
      contentClass="!bg-gray-f6 lg:px-9 lg:py-5"
      onClose={onClose}
    >
      <AccountView
        className="max-w-300"
        account={account}
        onDelete={onDelete}
      />
    </Modal>
  );
};

export default AccountViewModal;
