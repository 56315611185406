import { Tooltip } from '@mui/material';
import classnames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { browserName } from "react-device-detect";
import { useSelector } from "react-redux";
import { Chip, DataTable, IDataTableColumn, SelfHelpButton } from "../../../components";
import { CloneIcon, EditIcon, FocusCircleIcon, PlusIcon } from "../../../components/icons";
import { API_TOKEN_PERMISSIONS } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { ApiTokenService, MessageBoxService, ToastService } from "../../../services";
import { capitalize, copyToClipboard, fromNow } from "../../../utils/helpers";
import { ApiTokenModel } from "../../../utils/types";
import ApiTokenCreateConfirmModal from './ApiTokenCreateConfirmModal';
import ApiTokenCreateModal from "./ApiTokenCreateModal";
import ApiTokenEditModal from "./ApiTokenEditModal";

const ApiTokens = () => {
  const organization = useSelector(getActiveOrganization);
  const [tokens, setTokens] = useState<ApiTokenModel[]>([]);
  const [editingApiToken, setEditingApiToken] = useState<ApiTokenModel>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>();
  const [showConfirmToken, setShowConfirmToken] = useState<ApiTokenModel>();
  const [tokenTitle, setTokentitle] = useState<string>();

  const loadApiTokens = useCallback(() => {
    if (!organization) {
      setTokens([]);
      return;
    }

    ApiTokenService.search(organization.uuid).then((data) => {
      setTokens(data);
    }, (err) => {
      setTokens([]);
      ToastService.showHttpError(err, 'Loading api tokens failed');
    });
  }, [organization]);

  useEffect(() => {
    loadApiTokens();
  }, [loadApiTokens]);

  const onCloseCreateModal = (apiToken?: ApiTokenModel) => {
    if (apiToken) {
      setTokens([
        apiToken,
        ...tokens,
      ]);
      setTokentitle("You have successfully created an API token")
      setShowConfirmToken(apiToken);
    }
    setShowCreateModal(false);
  };

  const onCloseConfirmationModal = () => {
    setShowConfirmToken(undefined);
  };

  const onCloseEditModal = (apiToken?: ApiTokenModel) => {
    if (apiToken) {
      setTokens(tokens.map((item) => item.uuid === apiToken.uuid ? apiToken : item));
    } else if (apiToken === null) {
      setTokens(tokens.filter((item) => item.uuid !== editingApiToken.uuid));
    }
    setEditingApiToken(null);
  };

  const onDeleteApiToken = (apiToken: ApiTokenModel) => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">API TOKEN</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        ApiTokenService.delete(apiToken.org_uuid, apiToken.uuid).then(() => {
          loadApiTokens();
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting api token failed');
        });
      }
    });
  };


  const doSafari = data => {
    const api = new ApiTokenModel(
      { uuid: data.uuid, key: data.key }
    );
    setShowConfirmToken(api);
  };

  const onCopy = (row) => {
    ApiTokenService.find(row.org_uuid, row.uuid).then((data) => {
      if (browserName !== "Safari")
        copyToClipboard(data.key)
      else {
        // safari tweak
        setTokentitle("Token Details are below")
        doSafari(data);
      }
    }, (err) => {
      ToastService.showHttpError(err, 'Fetching token failed');
    });
  };

  const columns = useMemo<IDataTableColumn<ApiTokenModel>[]>(() => [
    {
      title: 'Name',
      field: 'name',
      headerClass: '!pl-11 w-48',
      tooltip: { title: 'Your API token’s name' },
      render(row: ApiTokenModel) {
        return (
          <div className="flex items-center">
            <FocusCircleIcon color="info" size={20} />
            <span className="ml-4">{row.name}</span>
          </div>
        );
      },
    },
    {
      title: 'API Token',
      field: 'uuid',
      tooltip: { title: 'The hidden API token you can copy and use' },
      render(row: ApiTokenModel) {
        return (
          <div className="flex items-center hover:text-blue has-hover-action cursor-pointer" onClick={() => onCopy(row)}>
            <span className="mr-2">{Array(row.uuid.length).fill('●').join('')}</span>
            <CloneIcon className="pnh-transparent transition-all" size={16} />
          </div>
        );
      },
    },
    {
      title: 'Permissions',
      field: 'permissions',
      headerClass: 'w-48',
      tooltip: { title: 'What you are allowed to use your API token for' },
      render(row: ApiTokenModel) {
        return (
          <div className="flex items-center">
            {row.permissions.map((item, i) => {
              const permission = API_TOKEN_PERMISSIONS.find((p) => p.values.includes(item));
              return (
                <Chip key={i} active containerClass="mr-2">{permission?.name || item}</Chip>
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Date Created',
      field: 'created',
      headerClass: 'w-48',
      tooltip: { title: 'When your API Token was created' },
      render(row: ApiTokenModel) {
        return capitalize(fromNow(row.created));
      },
    },
    {
      title: 'Last Used',
      field: 'lastUsed',
      headerClass: 'w-48',
      tooltip: { title: 'The last time your API token was used' },
    },
    {
      headerClass: '!w-20',
      render(row) {
        return (
          <div className="flex items-center">
            <Tooltip data-cy="edit-api-token" title="Edit API Token" arrow placement="left">
              <div
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-2 cursor-pointer"
                onClick={() => setEditingApiToken(row)}
              >
                <EditIcon color="blue" size={22} />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ], []);

  if (!organization) {
    return (
      <div className="relative h-full flex-center px-7 py-9">
        Organization is not selected.
      </div>
    );
  }

  return (
    <>
      <div className="relative h-full flex flex-col px-7 py-9">
        <div className="flex flex-row">
          <div>
            <h1 className="text-3xl font-semibold">{organization.name}</h1>
            <div className="text-sm">
              <span className="font-semibold">Project ID |</span> <span className="text-secondary">{organization.uuid}</span>
            </div>
            <div className="text-xs capitalize mt-1">Created {moment(organization.created).fromNow()}</div>
          </div>
          <SelfHelpButton className="ml-auto mt-10" url={'/docs/authentication'} />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-6"
          columns={columns}
          data={tokens}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!px-2 w-60"
          cellClass="!px-2"
        />

        <div
          data-cy="create-api-token"
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-62 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setShowCreateModal(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Create API Token</span>
        </div>
      </div>

      {editingApiToken && (
        <ApiTokenEditModal apiToken={editingApiToken} onClose={onCloseEditModal} />
      )}
      {showCreateModal && (
        <ApiTokenCreateModal onClose={onCloseCreateModal} />
      )}
      {!!showConfirmToken && (
        <ApiTokenCreateConfirmModal title={tokenTitle} createdToken={showConfirmToken} onClose={onCloseConfirmationModal} />
      )}
    </>
  );
};

export default ApiTokens;
