import React, { useState } from 'react'
import { Button, Modal } from '../../../../../components'
import GA4Form from '../GA4Form'
import { GA4Service } from '../../../../../services/GA4.service';
import { ToastService } from '../../../../../services';
import { ConfirmationModal } from '../../../../../parts';

const GA4Create = ({
  onClose,
  setTouched,
  touched
}) => {


  const [createForm, setCreateForm] = useState({
    getFormData: () => null,
  });
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)


  const close = (value?: any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if (touched && value === undefined) {
      setShowConfirmation(true)
      return;
    }
    if (value === undefined) {
      onClose()
    }
  }



  const onCreate = () => {
    const data = createForm.getFormData();
    const dataInit = {
      name: data.name,
      auth_credentials: {
        api_secret: data.api_secret,
        measurement_id: data.measurement_id
      },
      third_party: data.third_party,
      is_active: data.is_active,
      type: data.type
    }



    if (dataInit) {
      GA4Service.create(dataInit).then((res) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> added a new Google Analytics property.</>);
        onClose(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Creating GA4 failed');
      });
    }
  };

  return (
    <Modal
      title="Add Google Analytics Property"
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full px-8 py-2 ml-auto"
            color="primary"
            onClick={onCreate}
          >
            Create
          </Button>
        </>
      )}
      onClose={close}
    >
      <GA4Form
        onInit={setCreateForm}
        setTouched={setTouched}
      />
      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  )
}

export default GA4Create
