import React, { Component, FC, ReactElement, useEffect } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { CloseCircleIcon } from "../icons";

const modalRoot = document.getElementById('modal-root');

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'max' | 'full';

const modalTheme = {
  xs: 'w-110',
  sm: 'w-160',
  md: 'w-220',
  lg: 'w-270',
  xl: 'w-320',
  max: 'w-[95vw]',
  full: 'w-full h-full',
};

export interface IModalProps {
  title?: string | ReactElement;
  size?: ModalSize;
  headerComponent?: ReactElement;
  footerComponent?: ReactElement;
  headerActions?: ReactElement;
  wrapperClass?: string;
  headerClass?: string;
  contentClass?: string;
  footerClass?: string;
  headerActionsClass?: string;
  backdropClass?: string;
  hasCloseButton?: boolean;
  disableBackdropClose?: boolean;
  overrideParentModal?: boolean;
  onClose?: () => void;
}

export const AbstractModal: FC<IModalProps> = ({
  children,
  title = '',
  size = 'md',
  headerComponent,
  footerComponent,
  headerActions,
  wrapperClass = '',
  headerClass = '',
  contentClass = '',
  footerClass = '',
  headerActionsClass = 'flex items-center ml-auto',
  backdropClass = '',
  hasCloseButton = true,
  disableBackdropClose = false,
  onClose = () => { },
}) => {
  const onBackdropClick = () => {
    if (!disableBackdropClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-1000 flex-center animate-fade-in">
      <div
        className={classnames('absolute top-0 left-0 w-screen h-screen bg-black opacity-80', backdropClass)}
        onClick={onBackdropClick}
      />

      <div className={classnames(
        'relative flex flex-col shadow-md overflow-hidden',
        size === 'full' ? 'max-w-full max-h-full animate-zoom-in' : 'max-w-[95vw] max-h-[95vh] rounded-md animate-slide-in-up',
        modalTheme[size],
        wrapperClass,
      )}>
        {headerComponent ? headerComponent : (title && (
          <div className="flex items-center bg-blue-dark text-white py-1.5 px-6">
            <div className={classnames(
              'font-semibold text-lg uppercase pt-3 pb-2',
              headerClass,
            )}>
              {title}
            </div>
            {headerActions && (
              <div className={classnames(
                headerActionsClass,
                { 'mr-12': hasCloseButton },
              )}>{headerActions}</div>
            )}
          </div>
        ))}

        <div className={classnames(
          'bg-white flex-shrink px-6 py-3 overflow-auto',
          { 'flex-grow': size === 'full' },
          contentClass,
        )}>
          {children}
        </div>

        {footerComponent && (
          <div className={classnames("flex items-center justify-end bg-white px-6 py-3", footerClass)}>
            {footerComponent}
          </div>
        )}

        {hasCloseButton && (
          <div data-cy="close-edit" className="absolute top-4 right-5 cursor-pointer" onClick={() => onClose()}>
            <CloseCircleIcon color="white" size={32} />
          </div>
        )}
      </div>
    </div>
  );
};

export class Modal extends Component<IModalProps> {
  private readonly modalRef;
  private readonly lastModalRef;

  constructor(props) {
    super(props);

    this.lastModalRef = modalRoot.lastChild;

    this.modalRef = document.createElement('div');
    this.modalRef.className = `modal-wrapper modal-${this.props.size}`;
  }

  componentDidMount() {
    modalRoot.appendChild(this.modalRef);

    if (!this.props.overrideParentModal && this.lastModalRef && !this.lastModalRef.classList.contains('modal-full')) {
      this.lastModalRef.style.opacity = 0;
    }
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.modalRef);

    if (this.lastModalRef) {
      this.lastModalRef.style.opacity = 100;
    }
  }

  render() {
    return ReactDOM.createPortal(
      <AbstractModal {...this.props}>
        {this.props.children}
      </AbstractModal>,
      this.modalRef,
    );
  }
}
