import React, {FC, useCallback, useEffect} from "react";
import {Input} from "../../../../components";
import {Form, FormControl, useForm, Validators} from "../../../../components/ReactiveForm";
import {TagModel} from "../../../../utils/types";

export interface ITagFormInitParams {
  getFormData: () => any;
}

export interface ITagFormProps {
  tag?: TagModel;
  onInit?: (params: ITagFormInitParams) => void;
}

export type ITagForm = {
  name: FormControl;
}

const TagForm: FC<ITagFormProps> = ({
  tag,
  onInit = () => {},
}) => {
  const [form] = useForm<ITagForm>({
    name: new FormControl('', [Validators.required()]),
  });

  useEffect(() => {
    if (tag) {
      form.patch({
        name: tag.name,
      });
    }
  }, [tag]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        fullWidth
        label="Tag Name"
        labelClass="text-md font-semibold"
        placeholder="Give your tag a cool name"
      />
    </Form>
  );
};

export default TagForm;
