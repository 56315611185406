import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const HashTagIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={20.501} baseHeight={20.501} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 20.501 20.501">
        <path
          fill={color}
          d="M19.357,13.356h-2.12a.153.153,0,0,1-.153-.152V7.3a.153.153,0,0,1,.153-.152h2.12a1.661,1.661,0,0,0,1.214-.526A1.586,1.586,0,0,0,21,5.41a1.62,1.62,0,0,0-1.61-1.5H17.237a.153.153,0,0,1-.153-.153V1.643A1.659,1.659,0,0,0,16.558.429,1.584,1.584,0,0,0,15.35,0a1.621,1.621,0,0,0-1.5,1.61V3.762a.153.153,0,0,1-.153.153H7.8a.153.153,0,0,1-.153-.153V1.643A1.66,1.66,0,0,0,7.117.429,1.585,1.585,0,0,0,5.909,0a1.621,1.621,0,0,0-1.5,1.61V3.762a.153.153,0,0,1-.153.153H2.143A1.66,1.66,0,0,0,.929,4.44,1.586,1.586,0,0,0,.5,5.649a1.621,1.621,0,0,0,1.61,1.5H4.26a.153.153,0,0,1,.153.152V13.2a.153.153,0,0,1-.153.152H2.143a1.661,1.661,0,0,0-1.214.526A1.585,1.585,0,0,0,.5,15.091a1.621,1.621,0,0,0,1.61,1.5H4.26a.153.153,0,0,1,.153.153v2.119a1.662,1.662,0,0,0,.526,1.215,1.588,1.588,0,0,0,1.209.425,1.621,1.621,0,0,0,1.5-1.611V16.739a.153.153,0,0,1,.153-.153H13.7a.153.153,0,0,1,.153.153v2.119a1.662,1.662,0,0,0,.526,1.215,1.586,1.586,0,0,0,1.087.429c.04,0,.081,0,.121,0a1.621,1.621,0,0,0,1.5-1.611V16.739a.153.153,0,0,1,.153-.153h2.149a1.62,1.62,0,0,0,1.61-1.5,1.586,1.586,0,0,0-.425-1.209A1.661,1.661,0,0,0,19.357,13.356Zm-5.5-.152a.153.153,0,0,1-.153.152H7.8a.153.153,0,0,1-.153-.152V7.3A.153.153,0,0,1,7.8,7.145H13.7a.153.153,0,0,1,.153.152Zm0,0"
          transform="translate(-0.5)"
        />
      </svg>
    )}
  </Icon>
);
