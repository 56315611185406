import {HttpService} from "./http.service";
import {VoiceCallLogModel} from "../utils/types";

export class LogService {

  static searchVoiceCallLogs(query, showSpinner = true): Promise<VoiceCallLogModel[]> {
    return HttpService.get('/logs/voice-call/', query, {}, showSpinner)
      .then((res) => res.results.map((item) => new VoiceCallLogModel(item)));
  }
}
