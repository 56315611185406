import React, {CSSProperties, FC, ReactNode, useEffect, useMemo, useState} from "react";
import classnames from "classnames";

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type AvatarVariant = 'square' | 'rounded' | 'circle';

export interface IAvatarProps {
  className?: string;
  src?: string;
  size?: AvatarSize | number;
  variant?: AvatarVariant;
  background?: string;
  alt?: ReactNode;
  altText?: string;
  style?: CSSProperties;
}

const iconSizes = {
  xs: 24,
  sm: 28,
  md: 32,
};

export const Avatar: FC<IAvatarProps> = ({
  children,
  className = '',
  src,
  size = 'md',
  variant = 'circle',
  background = '#d6d6d6',
  alt,
  altText,
  style,
}) => {
  const [preview, setPreview] = useState<string>();

  const avatarSize = useMemo(() => {
    const width = iconSizes[size] || size;
    return `${width / 16}rem`;
  }, [size]);

  const placeHolder = useMemo(() => {
    if (alt) {
      return alt;
    }
    if (!altText?.trim()) {
      return '';
    }
    const words = altText.split(/\W+/).filter((word) => !!word);
    if (words.length >= 2) {
      return `${words[0][0]}${words[1][0]}`.toUpperCase();
    }
    return words[0].substr(0, 2).toUpperCase();
  }, [alt, altText]);

  useEffect(() => {
    if (src) {
      fetch(src).then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => setPreview(url))
        .catch(() => setPreview(null));
    }
  }, [src]);

  return (
    <div
      className={classnames(
        `flex-center flex-shrink-0 text-blue-dark font-semibold text-${size} bg-center bg-contain bg-no-repeat`,
        { 'rounded': variant === 'rounded' },
        { 'rounded-full': variant === 'circle' },
        className,
      )}
      style={{
        width: avatarSize,
        height: avatarSize,
        backgroundColor: background,
        backgroundImage: preview ? `url(${preview})` : null,
        ...style,
      }}
    >
      {children || (!preview && placeHolder)}
    </div>
  );
};
