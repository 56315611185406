import React, { FC, KeyboardEventHandler } from 'react';
import classnames from "classnames";
import { getCountryCallingCode, CountryCode } from "libphonenumber-js";
import {Country} from "react-phone-number-input";
import ReactPhoneInput from "react-phone-number-input/input";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";
import { getActiveOrganization } from '../../redux/selectors';

export interface IPhoneInputProps {
  value?: string;
  placeholder?: string;
  country?: Country;
  international?: boolean;
  withCountryCallingCode?: boolean;
  defaultCountry?: Country;
  useNationalFormatForDefaultCountryValue?: boolean;
  countryCode?: string;
  onFocus?: () => void;
  onKeyDown?: KeyboardEventHandler;
  onKeyUp?: KeyboardEventHandler;
}

export const PhoneInput: FC<IPhoneInputProps & IAbstractInputControlProps> = ({
  name = '',
  value,
  placeholder = '',
  country,
  international,
  withCountryCallingCode,
  defaultCountry,
  useNationalFormatForDefaultCountryValue,
  countryCode,
  onChange = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  onKeyUp = () => {},
  ...wrapperProps
}) => {
  if ((value === '' || !value) && countryCode) {
    value = `+${getCountryCallingCode(countryCode as CountryCode)}`
  }

  return (
    <InputWrapper {...wrapperProps} value={value}>
      {({ className, containerClass, disabled, readonly, onBlur }) => (
        <div className={classnames('MuiInput-root', containerClass)}>
          <ReactPhoneInput
            className={classnames(
              'MuiInput-input w-full', className,
              { 'Mui-disabled': disabled },
            )}
            name={name}
            value={value}
            placeholder={placeholder}
            country={country}
            international={international}
            withCountryCallingCode={withCountryCallingCode}
            defaultCountry={defaultCountry}
            useNationalFormatForDefaultCountryValue={useNationalFormatForDefaultCountryValue}
            readOnly={readonly}
            data-lpignore={true}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => onChange(e)}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        </div>
      )}
    </InputWrapper>
  );
};

