import React, { FC } from "react";
import { WhatsAppCampaignStep1Svg } from "../../../../components/svgs";
import { Button } from "../../../../components";

interface ICampaignNotFoundProps {
    onCreate: () => void;
}

export const CampaignNotFound: FC<ICampaignNotFoundProps> = ({
    onCreate,
}) => {
    return (
        <div className="grid place-items-center my-auto mx-auto 2xl:mx-80 xl:mx-40 lg:mx-30">
            <WhatsAppCampaignStep1Svg />
            <h1 className="text-4xl text-center font-bold text-blue m-8">
                You currently have no WhatsApp Campaigns available
            </h1>
            <p className="m-2 mb-12">
                <span>Ready to create your first campaign?</span>
            </p>
            <Button
                data-cy="wa-campaigns-first-create"
                color="primary"
                className="rounded-full shadow-md"
                onClick={() => onCreate()}
            >
                Get Started
            </Button>
        </div>
    )
}