import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import classNames from "classnames";
import React, { FC, useState } from "react";

interface IRadioLabelProps {
    labelClass: string;
    checked: boolean;
    label: string | number | null | undefined;
}
interface IRadioGroupingProps {
    options: any[];
    containerClass?: string;
    radioClass?: string;
    radioSize?: "small" | "medium"
    className?: string;
    color?: string;
    labelPlacement?: "bottom" | "end" | "start" | "top";
    labelClass?: string;
    messageClass?: string;
    value: string;
    message?: any;
    onValueChange: (res?: any) => void;
}

const RadioLabel: FC<IRadioLabelProps> = ({
    labelClass,
    checked,
    label
}) => {
    return (
        <p className={classNames(labelClass, !checked && "text-[#D5D5D5 ]", "!text-sm")}>{label}</p>
    )
}

export const RadioGrouping: FC<IRadioGroupingProps> = ({
    options,
    className,
    containerClass,
    color = 'primary',
    radioClass,
    radioSize = "small",
    labelPlacement = "end",
    labelClass,
    messageClass,
    value,
    message,
    onValueChange
}) => {

    const [selection, setSelection] = useState<any>(value);

    const onSelection = (selected: string) => {
        onValueChange(selected);
        setSelection(selected);
    }

    return (
        <div className={containerClass}>
            <RadioGroup
                color={color}
                className={className}
            >
                {options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        value={option.value}
                        labelPlacement={labelPlacement}
                        disableTypography={true}
                        onClick={() => onSelection(option.value)}
                        label={
                            <RadioLabel
                                checked={selection === option.value}
                                label={option.label}
                                labelClass={labelClass}
                            />
                        }
                        control={
                            <Radio
                                className={classNames(radioClass, "!py-1.5")}
                                size={radioSize}
                                checked={selection === option.value}
                            />
                        }
                    />
                ))}
            </RadioGroup>
            {message &&
                <div className={classNames(messageClass, "ml-7")}>
                    {message}
                </div>
            }
        </div>
    )
}

//options = [{label: 'test', value: 'testValue'}]