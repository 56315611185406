import React, {FC, useEffect, useState} from "react";
import {Button, Chip, Chips, Dropdown} from "../../../components";
import {CloseCircleIcon} from "../../../components/icons";
import {SMS_STATUS} from "../../../utils/enums";
import {FilterModel} from "../../../utils/types";

interface ISmsStatusFilterProps {
  category?: string;
  opened?: boolean;
  value: FilterModel,
  onChange?: (value: FilterModel) => void;
  onDelete?: (value: FilterModel) => void;
}

const options = Object.values(SMS_STATUS);

export const SmsStatusFilter: FC<ISmsStatusFilterProps> = ({
  category = 'status',
  opened,
  value,
  onChange = () => {},
  onDelete = () => {},
}) => {
  const [selection, setSelection] = useState<SMS_STATUS[]>([]);
  const [dropdownOpened, setDropdownOpened] = useState(opened);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  useEffect(() => {
    setSelection(value.value || []);
  }, [value]);

  const onToggleSelect = (status: SMS_STATUS) => {
    if (selection.includes(status)) {
      setSelection(selection.filter((item) => item !== status));
    } else {
      setSelection([...selection, status]);
    }
  };

  const onSave = () => {
    if (!selection.length)
      return;

    onChange({
      category,
      text: `Status: ${selection[0]}${selection.length > 1 ? ` & ${selection.length - 1} more` : ''}`,
      value: selection,
    });
    setDropdownOpened(false);
  };

  return (
    <div className="flex items-center mb-2">
      <Dropdown
        text={value?.text || 'Status'}
        buttonProps={{
          className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4 shadow-md',
          color: 'primary',
        }}
        dropdownClass="w-80 max-h-80 rounded-bl-md rounded-br-md"
        dropdownOpened={dropdownOpened}
        onDropdownOpened={() => setDropdownOpened(true)}
        onDropdownClosed={() => setDropdownOpened(false)}
      >
        <Chips className="p-2">
          {options.filter(option => option !== "Blacklisted").map((item, i) => (
            <Chip
              key={i}
              active={selection.includes(item)}
              onClick={() => onToggleSelect(item)}
            >
              {item}
            </Chip>
          ))}
        </Chips>

        <div className="px-3 py-2">
          <Button
            color="primary"
            className="shadow-md rounded-full text-sm px-6 ml-auto"
            disabled={!selection}
            onClick={onSave}
          >
            Filter
          </Button>
        </div>
      </Dropdown>

      <CloseCircleIcon
        className="ml-1 cursor-pointer"
        color="#DEDEDE"
        size={24}
        onClick={() => onDelete(value)}
      />
    </div>
  );
};
