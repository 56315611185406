import React, { FC, useState } from "react";
import { Button, Modal } from "../../../../components";
import BlockedKeywordForm, { IBlockedKeywordFormInitParams } from "../BlockedKeywordForm";
import { MessageBoxService, ToastService } from "../../../../services";
import { BlockedKeywordModel } from "../../../../utils/types";
import { TrashIcon } from "../../../../components/icons";

interface IBlockedKeywordEditModalProps {
  blockedKeyword: BlockedKeywordModel;
  onClose: () => void;
}

const BlockedKeywordEditModal: FC<IBlockedKeywordEditModalProps> = ({
  blockedKeyword, onClose,
}) => {
  const [editForm, setEditForm] = useState<IBlockedKeywordFormInitParams>({
    getFormData: () => null,
  });

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
      onClose();
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to remove this keywords from spam?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> removed from spam.</>);
        onClose();
      }
    });
  };

  return (
    <Modal
      title="Edit Blocked Keywords"
      footerComponent={(
        <>
          <Button
            className="flex-start text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Remove from spam
          </Button>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedKeywordForm blockedKeyword={blockedKeyword} onInit={setEditForm} onClose={onClose} />
    </Modal>
  );
};

export default BlockedKeywordEditModal;
