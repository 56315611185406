import React, {FC, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Modal} from "../../../../components";
import ApiTokenForm, {IApiTokenFormInitParams} from "../ApiTokenForm";
import {getActiveOrganization} from "../../../../redux/selectors";
import {ApiTokenService, ToastService} from "../../../../services";
import {ApiTokenModel} from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";

interface IApiTokenCreateModalProps {
  onClose: (data?: ApiTokenModel) => void;
}

const ApiTokenCreateModal: FC<IApiTokenCreateModalProps> = ({
  onClose,
}) => {
  const organization = useSelector(getActiveOrganization);
  const [createForm, setCreateForm] = useState<IApiTokenFormInitParams>({
    getFormData: () => null,
  });
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true)
  const [touched, setTouched] = useState<boolean>(false)
  const [permissionsTouched, setPermissionsTouched] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ApiTokenService.create(organization.uuid, {
        ...data,
        org_uuid: organization.uuid,
      }).then((data) => {
        onClose(data);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Creating api token failed');
      });
    }
  };

  const close = (value?:any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if ((touched && value === undefined) || (permissionsTouched && value === undefined)) {
      setShowConfirmation(true)
      return;
    }
    if ((value === undefined && !touched) || (value === undefined && !permissionsTouched)) {
      onClose()
    }
  }

  return (
    <Modal
      title="Add an API Token To Sudonum"
      footerComponent={(
        <>
          <Button
            data-cy="create-token"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onCreate}
            disabled={disableSaveButton}
          >
            Create Token
          </Button>
        </>
      )}
      onClose={close}
    >
      <ApiTokenForm
      onInit={setCreateForm}
      onClose={() => onClose()}
      setDisableSaveButton={setDisableSaveButton}
      setTouched={setTouched}
      setPermissionsTouched={setPermissionsTouched}/>
      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  );
};

export default ApiTokenCreateModal;
