import {HttpService} from "./http.service";
import {ICreateProjectRequest, IUpdateProjectRequest} from "../utils/dto";
import {ProjectModel} from "../utils/types";

export class ProjectService {

  static search(orgUuid: string, name = '', showSpinner = true): Promise<ProjectModel[]> {
    let payload:any = {}
    if(name) payload.name = name

    return HttpService.get(`/admin/org/${orgUuid}/org`, payload, {}, showSpinner)
      .then((res) => res.results.map((item) => new ProjectModel(item)));
  }

  static find(orgUuid: string, uuid: string, showSpinner = true): Promise<ProjectModel> {
    return HttpService.get(`/admin/org/${orgUuid}/org/${uuid}`, {}, {}, showSpinner)
      .then((res) => new ProjectModel(res));
  }

  static create(orgUuid: string, data: ICreateProjectRequest, showSpinner = true): Promise<ProjectModel> {
    return HttpService.post(`/admin/org/${orgUuid}/org`, data, {}, showSpinner)
      .then((res) => new ProjectModel(res));
  }

  static update(orgUuid: string, uuid: string, data: IUpdateProjectRequest, showSpinner = true): Promise<ProjectModel> {
    return HttpService.patch(`/admin/org/${uuid}`, data, {}, showSpinner)
      .then((res) => new ProjectModel(res));
  }

  static delete(orgUuid: string, uuid: string, showSpinner = true) {
    return HttpService.delete(`/admin/org/${orgUuid}/org/${uuid}`, {}, {}, showSpinner);
  }
}
