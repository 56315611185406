import React, {FC} from "react";
import {Icon, IIconProps} from "../icons";

export const WhatsAppStep3Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={251.137} baseHeight={221.583} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 251.137 221.583">
        <g transform="translate(-563 -289.998)">
          <rect fill="#307cd8" opacity={0.152} width="241" height="120" rx="4" transform="translate(563 348)"/>
          <text fill="#307cd8" fontSize={12} fontWeight={700} transform="translate(579.5 363)">
            <tspan x="8.838" y="12">We couldn’t call your number.</tspan>
            <tspan x="8.508" y="30">Please check you number and</tspan>
            <tspan x="47.118" y="48">try again in 1 hour</tspan>
          </text>
          <text fill="#307cd8" fontSize={14} fontWeight={700} transform="translate(644 440)">
            <tspan x="24.912" y="14">OK</tspan>
          </text>
          <line fill="none" stroke="#307cd8" opacity={0.271} x2="241" transform="translate(563.5 430.5)"/>
          <g transform="translate(0 22)">
            <g transform="translate(350.104 13.383)">
              <path fill="#2d7ddc" opacity={0.08} d="M20.607,0A20.607,20.607,0,1,1,0,20.607,20.607,20.607,0,0,1,20.607,0Z"
                    transform="translate(313.076 270.767)"/>
              <g transform="translate(310.635 269.707)">
                <rect fill="#f6a734" width="0.723" height="4.387" rx="0.361" transform="translate(22.325)"/>
                <rect fill="#f6a734" width="0.723" height="4.387" rx="0.361" transform="translate(22.325 39.643)"/>
                <rect fill="#f6a734" width="0.723" height="4.387" rx="0.361"
                      transform="translate(4.387 21.305) rotate(90)"/>
                <rect fill="#f6a734" width="0.723" height="4.387" rx="0.361"
                      transform="translate(45.365 21.305) rotate(90)"/>
              </g>
              <g transform="translate(298.923 256.115)">
                <path fill="none" stroke="#307cd8" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M332.424,323.117a33.5,33.5,0,1,1,15.846-63.025"
                      transform="translate(-298.923 -256.115)"/>
                <path fill="#307cd8" stroke="#307cd8" d="M388.468,265.839l-2.519-9.345-6.833,6.854,9.352,2.491-2.519-9.345-6.833,6.854Z"
                      transform="translate(-333.76 -256.28)"/>
              </g>
              <g transform="translate(305.773 263.096)">
                <path fill="#f6a734"
                      d="M401.353,305.895a1.339,1.339,0,0,1-1.338-1.337,24.631,24.631,0,0,0-4.824-14.7l2.055-1.711a27.264,27.264,0,0,1,5.444,16.416A1.339,1.339,0,0,1,401.353,305.895Z"
                      transform="translate(-347.593 -277.009)"/>
                <path fill="#f6a734"
                      d="M361.08,272.732a24.775,24.775,0,0,0-17.515,0l-.9-2.519a27.457,27.457,0,0,1,19.318,0Z"
                      transform="translate(-324.774 -268.458)"/>
                <path fill="#2d7ddc"
                      d="M312.371,305.895a1.339,1.339,0,0,1-1.337-1.337,27.264,27.264,0,0,1,5.444-16.416l2.054,1.711a24.637,24.637,0,0,0-4.823,14.7A1.339,1.339,0,0,1,312.371,305.895Z"
                      transform="translate(-311.034 -277.009)"/>
                <path fill="#2d7ddc"
                      d="M321.267,280.645a27.637,27.637,0,0,1,11.573-8.721l.9,2.52a24.938,24.938,0,0,0-10.422,7.911Z"
                      transform="translate(-315.479 -269.964)"/>
                <path fill="#f6a734"
                      d="M386.582,282.355a24.946,24.946,0,0,0-10.422-7.911l.9-2.52a27.648,27.648,0,0,1,11.573,8.721Z"
                      transform="translate(-339.326 -269.964)"/>
              </g>
            </g>
            <text fill="#f6a734" fontSize={12} fontWeight={700} transform="translate(647 296)">
              <tspan x="29.502" y="12">1h</tspan>
            </text>
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
