import React, { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import classnames from "classnames";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@mui/material';
import { Button, DataTable, IDataTableColumn, IDataTableRef, SelfHelpButton, TextField } from "../../components";
import { EditIcon, GraphSearchIcon, PlusIcon, SearchIcon } from "../../components/icons";
import NumberCreateModal from "./NumberCreateModal";
import NumberEditModal from "./NumberEditModal";
import { ROUTES } from "../../constants";
import { ExportEmailSentModal } from "../../parts";
import { addCallFilters, setCallFilters, setCallTab } from "../../redux/actions";
import { getActiveOrganization } from "../../redux/selectors";
import { NumberService, ToastService } from "../../services";
import { CALL_TAB, NUMBER_FEATURE } from "../../utils/enums";
import { formatPhoneNumber } from "../../utils/helpers";
import { NumberModel } from "../../utils/types";

const Numbers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(getActiveOrganization);
  const [isExported, setIsExported] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();
  const [editingNumber, setEditingNumber] = useState<NumberModel>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>();

  const columns = useMemo<IDataTableColumn<NumberModel>[]>(() => [
    {
      title: '',
      cellClass: '!px-4',
      headerClass: '!px-4',
      render(row) {
        return (
          <div className={classnames(
            'w-3 h-3 rounded-full mx-auto',
            { 'bg-green shadow-md': row.isNew },
          )} />
        );
      },
    },
    {
      title: 'Sudo Number',
      field: 'number',
      cellClass: '!pl-0',
      headerClass: '!pl-0',
      tooltip: {
        title: 'The number you created that will be used instead of your target number different description than the one above'
      },
      render(row: NumberModel) {
        return (
          <div className="flex items-center">
            <img src={`/assets/images/flags/${row.country?.toLowerCase()}.png`} alt="" width={20} />
            <div onClick={() => onEditNumber(row)} className="text-primary font-bold ml-4 cursor-pointer">{formatPhoneNumber(row.number)}</div>
          </div>
        );
      },
    },
    { title: 'Friendly Name', field: 'name', tooltip: { title: 'The name you have given your Sudo Number' } },
    {
      title: 'Target Number',
      field: 'target_number',
      tooltip: { title: 'The number the message or call is forwarded to' },
      render(row: NumberModel) {
        return (row.target_number || "").split(",")
          .map((number) => formatPhoneNumber(number))
          .join(', ');
      },
    },
    {
      title: 'Renew In',
      field: 'billing_anniversary',
      tooltip: { title: 'The time left before your Sudo Number expires' },
      render(row: NumberModel) {
        return row.formatBillingAnniversary();
      },
    },
    { title: 'Price', field: 'meta.cost', tooltip: { title: 'The cost of your Sudo Number' } },
    {
      title: 'Last Used',
      field: 'last_called',
      tooltip: { title: 'The last time your Sudo number was used' },
      render(row: NumberModel) {
        return row.formatLastCalled();
      },
    },
    {
      render(row: NumberModel) {
        const specialLabel = row.specialLabel();
        return (
          <div className="flex items-center justify-end">
            {row.isNew && (
              <div data-cy="i-am-new" className="h-7.5 flex-center text-green bg-green bg-opacity-17 text-xs font-bold uppercase rounded px-2 ml-2">I'm New</div>
            )}
            {specialLabel && (
              <div className={specialLabel.className}>{specialLabel.text}</div>
            )}
            <Tooltip title="Edit number" arrow placement="left">
              <div
                data-cy="edit-number"
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                onClick={() => onEditNumber(row)}
              >
                <EditIcon color="blue" size={22} />
              </div>
            </Tooltip>
            <Tooltip title="View Number Call Logs" arrow placement="left">
              <div
                data-cy="edit-number"
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                onClick={() => onViewLogs(row)}
              >
                <GraphSearchIcon color="blue" size={22} />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ], []);

  const loadNumbers = useCallback((options, callback) => {
    NumberService.search({
      page: options.page + 1,
      page_size: options.perPage,
      number: filter.search,
    }, false).then((data) => {
      callback({
        count: data.count,
        data: data.results,
      });
    }).catch(() => {
      callback({
        count: 0,
        data: [],
      });
    });
  }, [organization, filter]);

  const refreshTable = useCallback(() => {
    if (tableRef) {
      tableRef.refresh();
    }
  }, [tableRef]);

  const debouncedSetFilter = useCallback(
    debounce((filter) => setFilter(filter), 500),
    []);

  const onDownload = useCallback(() => {
    NumberService.exportNumbers({
      number: filter.search,
    }).then(() => {
      setIsExported(true);
    });
  }, [filter]);

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter({
      ...filter,
      search,
    });
  };

  const onEditNumber = (row: NumberModel) => {
    NumberService.find(row.number).then((number) => {
      setEditingNumber(number);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading number failed');
    });
  };

  const onViewLogs = (row: NumberModel) => {
    dispatch(setCallFilters([]));
    dispatch(setCallTab(CALL_TAB.RECEIVED));
    dispatch(addCallFilters([
      {
        category: 'number',
        text: 'Numbers',
        value: row.number,
      },
    ]));
    history.push(ROUTES.CALLS.RECEIVED);
  };

  const onCreateModalClosed = (result) => {
    if (result) {
      refreshTable();
    }
    setShowCreateModal(false);
  };

  const onEditModalClosed = (result) => {
    if (result) {
      refreshTable();
    }
    setEditingNumber(null);
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="relative z-10 flex items-center bg-white shadow-md px-5 py-1.5">
          <h1 className="text-lg text-primary font-semibold uppercase">Numbers</h1>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search numbers"
            clearable
            onChange={(text) => onSearchChange(text.split(' ').join(''))}
          />
          <Button
            data-cy="export-numbers"
            color="blue-light"
            className="text-xs rounded-full !normal-case shadow px-6 ml-4"
            onClick={onDownload}
          >
            Export list
          </Button>
          <SelfHelpButton url={'/docs/number'} />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow p-4"
          columns={columns}
          serverSide
          datasource={loadNumbers}
          pagination
          rowsPerPage={50}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="px-8"
          paginationClass="mr-16"
          rowClass={(row) => classnames({ '!bg-green !bg-opacity-11': row.isNew })}
          cellClass={(row) => classnames('px-8', { '!font-semibold': row.isNew })}
          onInit={setTableRef}
        />

        <div
          data-cy="create-number"
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-59 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setShowCreateModal(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Create Number</span>
        </div>
      </div>

      {editingNumber && (
        <NumberEditModal
          number={editingNumber}
          onClose={onEditModalClosed}
        />
      )}
      {showCreateModal && (
        <NumberCreateModal
          onClose={onCreateModalClosed}
        />
      )}
      {isExported && (
        <ExportEmailSentModal onClose={() => setIsExported(false)} />
      )}
    </>
  );
};

export default Numbers;
