import {SMS_LOG_ACTION} from "../action-types";
import {IDataTableRef} from "../../components";
import {SMS_TAB} from "../../utils/enums";
import {FilterModel} from "../../utils/types";
import {SmsRecordModel} from "../../utils/types";

export const setSmsTab = (tab: SMS_TAB) => ({
  type: SMS_LOG_ACTION.SET_TAB,
  tab,
});

export const setSmsFilters = (filters: FilterModel[]) => ({
  type: SMS_LOG_ACTION.SET_FILTERS,
  filters,
});

export const setSmsLogs = (logs: SmsRecordModel[]) => ({
  type: SMS_LOG_ACTION.SET_LOGS,
  logs,
});

export const setSelectedSmsLogs = (selectedLogs: SmsRecordModel[]) => ({
  type: SMS_LOG_ACTION.SET_SELECTED_LOGS,
  selectedLogs,
});

export const setSmsTableRef = (tableRef: IDataTableRef) => ({
  type: SMS_LOG_ACTION.SET_TABLE_REF,
  tableRef,
});
