import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import BlockedKeywords from "./index";

const BlockedKeywordsRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.BLOCKED_KEYWORDS} component={BlockedKeywords} />
  </Switch>
);

export default BlockedKeywordsRouting;
