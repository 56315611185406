import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import Invoices from "./index";

const InvoicesRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.INVOICES.INDEX} component={Invoices} />
  </Switch>
);

export default InvoicesRouting;
