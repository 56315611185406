import classNames from "classnames";
import React, { FC, useState } from "react";
import { Card } from "../Card";
import { AngleDownIcon, AngleUpIcon, CheckCircleIcon } from "../icons";

interface IExpandableCardProps {
    containerClass?: string;
    title: string;
    completed?: boolean;
}

export const ExpandableCard: FC<IExpandableCardProps> = ({
    children,
    title,
    containerClass,
    completed = false,
}) => {

    const [expanded, setExpanded] = useState<boolean>(!completed);

    return (
        <Card
            className={classNames(containerClass)}
        >
            <div className="flex flex-row"
                onClick={() => setExpanded(!expanded)}>
                <h1 className="font-bold">{title}</h1>
                {completed &&
                    <CheckCircleIcon
                        size={15}
                        color={"#9AE048"}
                        className="p-1" />
                }
                {expanded ?
                    <AngleUpIcon
                        className="cursor-pointer ml-auto"
                    />
                    :
                    <AngleDownIcon
                        className="cursor-pointer ml-auto"
                    />
                }
            </div>
            {expanded &&
                children
            }
        </Card>
    )
}