import React, { FC } from "react";
import { Button, Modal } from "../../../../components";
import { WACampaignsModel } from "../../../../utils/types";
import { ToastService, WACampaignsService } from "../../../../services";
import { CAMPAIGN_STATUS } from "../../../../utils/enums";

interface ICampaignsSendModal {
    campaign: WACampaignsModel;
    onClose: (result?: any) => void;
}

export const CampaignsSendModal: FC<ICampaignsSendModal> = ({
    campaign,
    onClose,
}) => {

    const onSend = () => {
        if (campaign) {
            WACampaignsService.modifyCampaign({
                ...campaign,
                campaign_status: CAMPAIGN_STATUS.RUNNING
            }).then((res) => {
                onClose(res);
            }).catch((err) => {
                ToastService.showHttpError(err, "Sending the campaign failed.")
            })
        }
    }

    return (
        <Modal
            size="sm"
            title={"Send Confirmation"}
            footerComponent={
                <Button
                    color="primary"
                    className="rounded-full shadow-md"
                    onClick={() => onSend()}
                >
                    Yes, I am sure
                </Button>
            }
            onClose={onClose}
        >
            <p className="text-sm">
                Are you sure you want to send this message to
                <strong className="font-bold">{" " + campaign?.total_recipients || 0} recipients? </strong> Please ensure you have an <strong>OPT OUT option</strong> to avoid being reported and potentially having your account blocked.
                <br />
                <br />
                <strong>This action cannot be undone.</strong>
            </p>
        </Modal>
    )
}