import React, {FC, useState} from "react";
import {Button, Modal} from "../../../../components";
import TagForm, {ITagFormInitParams} from "../TagForm";
import {ToastService} from "../../../../services";

interface ITagCreateModalProps {
  onClose: () => void;
}

const TagCreateModal: FC<ITagCreateModalProps> = ({
  onClose,
}) => {
  const [createForm, setCreateForm] = useState<ITagFormInitParams>({
    getFormData: () => null,
  });

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> created a new tag.</>);
      onClose();
    }
  };

  return (
    <Modal
      title="Create a new tag"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full shadow-md px-6 py-2"
            color="primary"
            onClick={onCreate}
          >
            Create
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TagForm onInit={setCreateForm} />
    </Modal>
  );
};

export default TagCreateModal;
