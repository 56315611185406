import React, { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { DataTable, IDataTableColumn, TextField } from "../../../components";
import { EditIcon, PlayCircleIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import { FullAudioPlayerModal } from "../../../parts";
import { ROUTES } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { AudioService, ToastService } from "../../../services";
import { ISearchAudiosRequest } from "../../../utils/dto";
import { AudioModel } from "../../../utils/types";
import Tooltip from '@mui/material/Tooltip';

const AudioFiles = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
  });
  const [s3Url, setS3Url] = useState('');
  const organization = useSelector(getActiveOrganization);

  const debouncedSetFilter = useCallback(
    debounce((filter) => setFilter(filter), 500),
    []
  );

  const onSearch = value => {
    setSearch(value);
    debouncedSetFilter({ search: value });
  };


  const loadAudios = useCallback((options, callback) => {
    const query: ISearchAudiosRequest = {
      page: options.page + 1,
      page_size: options.perPage,
    };
    if (filter.search) {
      query.name = filter.search;
    }

    AudioService.search(query, false).then((data) => {
      callback({
        count: data.count,
        data: data.results,
      });
    }).catch(() => {
      callback({
        count: 0,
        data: [],
      });
    });
  }, [organization, filter]);

  const loadAudioFile = (audio: AudioModel) => {
    audio.getS3Url().then(data => {
      setS3Url(data.url);
    }).catch(err => {
      if (err.response.status === 404) {
        ToastService.error(<><span className="text-failure mr-1">Failed:</span> Audio file was not found.</>);
        return;
      }
      ToastService.error(<><span className="text-failure mr-1">Failed:</span> Something went wrong.</>);
    })
  };

  const columns = useMemo<IDataTableColumn<AudioModel>[]>(() => [
    {
      title: 'Name',
      field: 'name',
      tooltip: { title: 'Your audio file’s name' }
    },
    {
      title: 'Type',
      field: 'type',
      cellClass: '!capitalize',
      tooltip: { title: 'The type of audio file will determine when it will be displayed to the user to use.' },
      render(row: AudioModel) {
        return (
          <div>
            {row.type.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase())}
          </div>
        );
      },
    },
    {
      render(row: AudioModel) {
        return (
          <div className="flex items-center justify-end">
            <Tooltip title="Play Audio" arrow placement="left">
              <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-[#fdeace] rounded-md ml-auto cursor-pointer">
                <PlayCircleIcon color="primary" size={22} onClick={() => loadAudioFile(row)} />
              </div>
            </Tooltip>
            <Tooltip title="Edit Audio" arrow placement="left">
              <Link
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                to={`${ROUTES.SETTINGS.AUDIO_FILES.PREFIX}/${row.type}/${row.name}/edit`}
              >
                <EditIcon color="blue" size={22} />
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ], []);

  return (
    <>
      <div className="relative h-full flex flex-col px-7 py-9">
        <TextField
          size="sm"
          fullWidth
          className="!rounded-full !bg-white shadow-md"
          containerClass="w-80 ml-auto"
          value={search}
          icon={<SearchIcon size={16} color="primary" />}
          placeholder="Search"
          clearable
          onChange={(value) => onSearch(value)}
        />

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          serverSide
          datasource={loadAudios}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!text-xs !px-8"
          paginationClass="mr-16"
        />

        <Link
          data-cy="create-audio-file"
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-56 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          to={ROUTES.SETTINGS.AUDIO_FILES.CREATE}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Add Audio File</span>
        </Link>
      </div>

      {s3Url && (
        <FullAudioPlayerModal src={s3Url} onClose={() => setS3Url('')} />
      )}
    </>
  );
};

export default AudioFiles;
