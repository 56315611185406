import { WAListsService } from "../../services";

export class WAListsModel {
  uuid: string;
  name: string;
  total_recipients: number;
  total_campaigns: number;
  total_optedout: number;
  created: string;
  modified: string;


  constructor(init: any = {}) {
    const data = {
      uuid: '',
      name: '',
      total_recipients: 0,
      total_campaigns: 0,
      created: '',
      modified: '',
      ...init,
    };

    Object.assign(this, data);
  }

  static search(query: any = {}, ...kwargs) {
    return WAListsService.search(query, ...kwargs);
  }
}