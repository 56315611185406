import { Tooltip } from "@mui/material";
import classNames from "classnames";
import React, { FC } from "react";

interface ITruncatedTableCellProps {
    cellData: string | undefined | null;
    tooltipClass?: string;
    cellClass?: string;
    tooltipPlacement?: | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}

//TODO update this when the new tooltips get created
export const TruncatedTableCell: FC<ITruncatedTableCellProps> = ({
    cellData,
    tooltipClass = '',
    cellClass = '',
    tooltipPlacement = "top",
}) => {
    return (
        <>
            {cellData &&
                <Tooltip
                    title={(
                        <div className={classNames(tooltipClass, "text-center")}>
                            {cellData}
                        </div>
                    )}
                    arrow
                    placement={tooltipPlacement}
                >
                    <span
                        className={classNames(
                            cellClass,
                            cellData.length > 24 && "block",
                            "hover:text-blue truncate cursor-pointer"
                        )}
                    >
                        {cellData}
                    </span>
                </Tooltip>
            }
        </>
    )
}