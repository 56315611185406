import { Tooltip } from "@mui/material";
import classnames from "classnames";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataTable, IDataTableColumn, TextField, TruncatedTableCell } from "../../../../components";
import { InfoCircleIcon, SearchIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants/routes";
import { getActiveOrganization } from "../../../../redux/selectors";
import { IssueService } from "../../../../services/issue.service";
import { formatDateTime } from "../../../../utils/helpers";
import { IssueModel } from "../../../../utils/types";
import { debounce } from "lodash";

const rowsPerPage = 50;

export const IssueTable: FC = () => {
    //redux
    const organization = useSelector(getActiveOrganization);

    //state
    const [page, setPage] = React.useState<number>(0);
    const [search, setSearch] = React.useState<string>('');
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [filter, setFilter] = React.useState({
        search: '',
    });

    const columns = React.useMemo<IDataTableColumn<IssueModel>[]>(() => {
        return [
            // {
            //     title: '',
            //     cellClass: '!px-4',
            //     headerClass: '!px-4',
            //     render(row) {
            //         return (
            //             <div className={classnames(
            //                 'w-3 h-3 rounded-full mx-auto',
            //                 { 'bg-[##F9DDD941] shadow-md': row.isNew() },
            //             )} />
            //         );
            //     },
            // },
            {
                title: "Date and Time",
                field: "time",
                tooltip: {
                    title: "TODO",
                },
                render(row: IssueModel) {
                    return formatDateTime(row.created);
                },
            },
            {
                title: "Error Type",
                field: "error_object_type",
                tooltip: {
                    title: "TODO",
                },
                render(row: IssueModel) {
                    return <span className="capitalize">{row.error_object_type.replace(".", " ")}</span>
                }
            },
            {
                title: "Reference ID",
                field: "reference_id",
                tooltip: {
                    title: "The reference ID of the error",
                },
            },
            {
                title: "Description",
                field: "description",
                tooltip: {
                    title: "TODO",
                },
                render(row: IssueModel) {
                    return (
                        <p>
                            {row.customer_error_message}
                            {/* <Link
                                className="text-primary capitalize !font-semibold ml-2 hover:cursor-pointer hover:!font-bold"
                                to={`${ROUTES.DEVELOPER.ISSUES.PREFIX}/${row.id}/`}
                            >How to fix it</Link> */}
                        </p>
                    )
                },
            },
            {
                render(row: IssueModel) {
                    return (
                        <div className="flex items-center justify-end gap-1.5">
                            {row.isNew() &&
                                <div className='rounded-md bg-[#FFE0E1] text-[#FE5A62] uppercase p-2 !font-bold max-w-fit'>New Error</div>
                            }
                            <Tooltip title="View Details" arrow placement="top">
                                <Link
                                    data-cy={"webhook-edit"}
                                    className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                                    to={`${ROUTES.DEVELOPER.ISSUES.PREFIX}/${row.id}/`}
                                >
                                    <InfoCircleIcon color="blue" size={18} />
                                </Link>
                            </Tooltip>
                        </div>
                    );
                },
            },
        ];
    }, [search]);

    const loadIssues = React.useCallback((options, callback) => {
        IssueService.search({
            page: options.page + 1,
            page_size: options.perPage,
            key_field_value: filter.search,
        }, false).then((data) => {
            callback({
                count: data.count,
                data: data.results,
            });
        }).catch(() => {
            callback({
                count: 0,
                data: [],
            });
        });
    }, [organization, filter]);

    const debouncedSetFilter = React.useCallback(
        debounce((filter) => setFilter(filter), 500),
        []);

    const onSearchChange = (search) => {
        setSearch(search);
        debouncedSetFilter({
            ...filter,
            search,
        });
    };

    return (
        <>
            <TextField
                size="sm"
                fullWidth
                className="!bg-white rounded-full shadow"
                containerClass="w-80 ml-auto"
                value={search}
                icon={<SearchIcon size={16} color="primary" />}
                placeholder="Search Reference ID"
                clearable
                onChange={(text) => onSearchChange(text.split(' ').join(''))}
            />
            <DataTable
                wrapperClass={"h-0 flex-grow py-4"}
                columns={columns}
                serverSide
                datasource={loadIssues}
                totalCount={totalCount}
                extLoading={loading}
                size="xs"
                stripped
                stickyHeader
                page={page}
                pagination
                selfPagination
                rowsPerPage={rowsPerPage}
                paginationClass="mr-16"
                onPaginationChange={(page) => setPage(page)}
            />
        </>
    );
};