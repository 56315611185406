export interface ILanguage {
  name: string;
  code: string;
}

export const LANGUAGES: ILanguage[] = [
  { name: "English (US)", code: "en-US-Wavenet-D" },
  { name: "English (UK)", code: "en-GB-Wavenet-C" },
  { name: "Spanish", code: "es-ES-Standard-A" },
  { name: "Portuguese (Brazil)", code: "pt-BR-Wavenet-A" },
  { name: "Portuguese (Portugal)", code: "pt-PT-Wavenet-D" },
  { name: "Turkish", code: "tr-TR-Wavenet-D" },
];
