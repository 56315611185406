import React, { FC, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { ArrowLeftIcon, PlayCircleIcon } from "../../../../components/icons";
import { Button, Input, Modal } from "../../../../components";
import { FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import { INumberCreateForm } from "../index";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { NUMBER_MODE } from "../../../../utils/enums";

export interface ICreatingNumberEditModalProps {
  formData: INumberCreateForm;
  otpMode: NUMBER_MODE;
  onClose(): void;
  onSubmit(data: any): void;
}

const CreatingNumberEditModal: FC<ICreatingNumberEditModalProps> = ({
  formData, otpMode, onClose, onSubmit,
}) => {
  const [form, data] = useForm<{
    target: FormControl;
    name: FormControl;
    recordCallsEnabled: FormControl;
    recordCalls: FormControl;
    whisperMessageEnabled: FormControl;
    whisperMessage: FormControl;
  }>({
    target: new FormControl('', [Validators.required(), Validators.phone(formData.country.iso)]),
    name: new FormControl('', [Validators.required()]),
    recordCallsEnabled: new FormControl(false),
    recordCalls: new FormControl('', [Validators.required()], { disabled: true }),
    whisperMessageEnabled: new FormControl(false),
    whisperMessage: new FormControl('', [Validators.required()], { disabled: true }),
  });

  useEffect(() => {
    form.patch({
      target: formData.target || '',
      name: formData.name || '',
      recordCallsEnabled: formData.recordCallsEnabled || false,
      recordCalls: formData.recordCalls || '',
      whisperMessageEnabled: formData.whisperMessageEnabled || false,
      whisperMessage: formData.whisperMessage || '',
    });
  }, [formData]);

  useEffect(() => {
    form.controls.recordCalls.disable(!data.recordCallsEnabled);
    form.controls.whisperMessage.disable(!data.whisperMessageEnabled);
  }, [data]);

  const onSave = () => {
    if (!form.validate())
      return;

    const data = form.getFormData();
    onSubmit({
      ...data,
      recordCalls: data.recordCallsEnabled ? data.recordCalls : '',
      whisperMessage: data.whisperMessageEnabled ? data.whisperMessage : '',
    });
  };

  useEffect(() => {
    if (otpMode === NUMBER_MODE.OTP) {
      form.setControl('target', new FormControl(formData.target || ''));
      form.setControl('recordCalls', new FormControl(formData.recordCalls || ''));
      form.setControl('whisperMessage', new FormControl(formData.whisperMessage || ''));
    } else {
      form.setControl('target', new FormControl(formData.target || '', [Validators.required(), Validators.phone(formData.country.iso)]));
      form.setControl('recordCalls', new FormControl(formData.recordCalls || '', [Validators.required()], { disabled: true }));
      form.setControl('whisperMessage', new FormControl(formData.whisperMessage || '', [Validators.required()], { disabled: true }));
    }
  }, [otpMode])

  return (
    <Modal
      title="Add a number"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>
            <ArrowLeftIcon className="mr-1" size={14} /> Back
          </Button>
          <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onSave}>
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <div className=" text-2xl font-bold mt-6">Number Details</div>

      <div className="grid xs:grid-cols-2 sm:!grid-cols-3 gap-4 mt-8 mb-4">
        <div>
          <div className="text-xs !font-semibold">Sudonum Number</div>
          <div className="text-blue text-2xl font-bold mt-4">{formatPhoneNumber(formData.favouriteNumber)}</div>
        </div>
        {otpMode === NUMBER_MODE.NORMAL &&
          <Input
            type="phone"
            label="Target Number"
            control={form.controls.target}
            containerClass="!max-w-60"
            labelClass="!font-semibold text-xs pb-2"
            size="sm"
          />
        }
        <Input
          label="Name of Number"
          control={form.controls.name}
          containerClass="!max-w-60"
          labelClass="!font-semibold text-xs pb-2"
          size="sm"
        />
      </div>

      {otpMode === NUMBER_MODE.NORMAL &&
        <div className="mt-4">
          <div className=" text-2xl font-bold my-6">Features</div>
          <div>
            <Input
              type="switch"
              control={form.controls.recordCallsEnabled}
              label="Record your calls"
              labelClass="!font-semibold"
            />
            <p className="text-xs">Add an optional disclaimer to let people know the call is recorded.</p>
            <Tooltip arrow title={<>This is a brief description on why enabling<br />recording your calls in necessary</>}>
              <span className="text-blue text-sm font-semibold">Why is this necessary?</span>
            </Tooltip>
            <Input
              control={form.controls.recordCalls}
              size="sm"
              containerClass="mt-1"
              placeholder="e.g. This call will be recorded for quality and training purposes."
            />
            {form.controls.recordCallsEnabled.value && (
              <div className="ml-8 mt-2">
                <Button
                  className="text-primary text-sm !normal-case"
                  leftIcon={<PlayCircleIcon size={24} />}
                >
                  Play audio
                </Button>
              </div>
            )}
          </div>
          <div className="mt-4">
            <Input
              type="switch"
              control={form.controls.whisperMessageEnabled}
              label="Whisper Message"
              labelClass="!font-semibold"
            />
            <p className="text-xs">This is a short introductory message that plays when you receive a call. The caller does not hear it.</p>
            <Tooltip arrow title={<>This is a short introductory message that plays when you receive a call.<br />The caller does not hear it.</>}>
              <span className="text-blue text-sm font-semibold">Who will hear this?</span>
            </Tooltip>
            <Input
              control={form.controls.whisperMessage}
              size="sm"
              containerClass="mt-1"
              placeholder="e.g. This call is for marketing purposes."
            />
          </div>
        </div>
      }
    </Modal>
  );
};

export default CreatingNumberEditModal;
