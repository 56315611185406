import React, {FC} from "react";
import {Button} from "../../../../../components";
import {ArrowLeftIcon} from "../../../../../components/icons";
import {WhatsAppStep2Svg} from "../../../../../components/svgs";

export interface IFollowStep2Props {
  onBack(): void;
  onNext(): void;
}

const FollowStep2: FC<IFollowStep2Props> = ({
  onBack, onNext,
}) => {
  return (
    <div className="h-full flex flex-col text-center px-8 pt-8">
      <h6 className="font-semibold">To set up your number for WhatsApp</h6>
      <p className="text-primary text-3xl font-bold">Follow all the steps below</p>
      <div className="max-w-140 mx-auto mt-10 mb-6">
        <WhatsAppStep2Svg size={240} className="mx-auto" />
        <p className="font-semibold mt-4">
          WhatsApp needs to verify your number by sending you a one-time-pin (OTP).
          A Sodunum number cannot receive OTP's via SMS, it can only be received via calls.
          Please wait for the SMS timer to run out and then selecte the "<span className="text-blue font-bold">Call me</span>" option.
        </p>
      </div>
      <div className="flex mt-auto mb-6">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FollowStep2;
