import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import NumberEdit from "../NumberEdit";
import {CloseCircleIcon} from "../../../components/icons";
import {NumberService, ToastService} from "../../../services";
import {NumberModel} from "../../../utils/types";

const NumberEditPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [number, setNumber] = useState<NumberModel>();

  useEffect(() => {
    if (!id) {
      return;
    }

    NumberService.find(id).then((data) => {
      setNumber(data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading number failed');
      history.goBack();
    });
  }, [id]);

  const onClose = () => {
    history.goBack();
  };

  return (
    <div className="fixed top-0 left-0 z-100 w-screen h-screen flex flex-col">
      <div className="flex items-center bg-blue-dark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Numbers</div>
      </div>
      <div className="bg-gray-f6 flex-shrink md:pl-32 md:pr-20 lg:pl-50 lg:pr-38 lg:py-9 px-6 py-3 overflow-auto flex-grow">
        {number ? (
          <NumberEdit number={number} onClose={onClose} />
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <div className="absolute top-4 right-5 cursor-pointer" onClick={onClose}>
        <CloseCircleIcon color="white" size={32} />
      </div>
    </div>
  );
};

export default NumberEditPage;
