import { useHistory, useLocation } from 'react-router';
import { useCallback, useMemo } from 'react';

export const useQuery = () => {
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParams = (params: { name: string, value: string }[]) => {
    const searchParams = new URLSearchParams();
    params.forEach((param) => {
      if (!param.value) return
      searchParams.set(param.name, param.value);
    })
    history.push(`?${searchParams.toString()}`);
  }

  return {
    queryParams,
    setQueryParams,
  }
}
