import React, {ReactElement} from "react";
import axios from "axios";
import store from "../redux/store";
import {showToast} from "../redux/actions";
import {IToastOptions} from "../components";

export class ToastService {

  static success(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'success',
    });
  }

  static info(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'info',
    });
  }
  static warning(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'warning',
    });
  }
  static error(message: string | ReactElement, options?: Partial<IToastOptions>) {
    ToastService.show({
      ...options,
      message,
      severity: 'error',
    });
  }

  static show(options: IToastOptions) {
    store.dispatch(showToast(options));
  }

  static showHttpError(err, defaultMessage: string | ReactElement) {
    if (!axios.isAxiosError(err)) {
      ToastService.error(err?.message || defaultMessage);
      return;
    }

    const response = err?.response;
    const data = response?.data;

    if (!response) {
      ToastService.error('Network Error!');
      return;
    }

    if (!data) {
      ToastService.error(`${response.status} error - ${response.statusText}`);
      return;
    }

    if (typeof data !== 'object') {
      ToastService.error(data);
      return;
    }

    if (data.message) {
      ToastService.error(data.message);
      return;
    }

    if (response.status === 400 && !data.message) {
      const message = (
        <div>
          <div className="font-bold">Bad Request!</div>
          {Object.entries(data).map(([key, value]) => (
            <div className="flex items-start">
              {key !== 'non_field_errors' && (
                <span className="font-semibold flex-shrink-0 mr-1">{key}:</span>
              )}
              <div>
                {Array.isArray(value) ? (
                  <>
                    {value.map((msg) => <div>{msg}</div>)}
                  </>
                ) : value}
              </div>
            </div>
          ))}
        </div>
      );
      ToastService.error(message);
      return;
    }

    ToastService.error(defaultMessage);
  }
}
