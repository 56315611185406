import {TOAST_ACTION} from "../action-types";
import {IToastOptions} from "../../components";

export interface IToastState {
  toastOptions?: IToastOptions;
}

const initialState: IToastState = {
  toastOptions: undefined,
};

const toastReducer = (state: IToastState = initialState, action: any) => {
  switch (action.type) {
    case TOAST_ACTION.SHOW_TOAST:
      return {
        ...state,
        toastOptions: action.options,
      };

    case TOAST_ACTION.CLOSE_TOAST:
      return {
        ...state,
        toastOptions: undefined,
      };

    default:
      return state;
  }
};

export default toastReducer;
