import {AUTH_ACTION} from "../action-types";
import {OrganizationModel} from "../../utils/types";

export const setToken = (token: string) => ({
  type: AUTH_ACTION.SET_TOKEN,
  token,
});

export const setOrganizations = (organizations: OrganizationModel[]) => ({
  type: AUTH_ACTION.SET_ORGANIZATIONS,
  organizations,
});

export const setActiveOrganization = (organization: OrganizationModel) => ({
  type: AUTH_ACTION.SET_ACTIVE_ORGANIZATION,
  organization,
});
