import React, { FC, useEffect, useMemo, useState } from "react";
import { FormControl, FormControlType, IAbstractInputControlProps, ValidationError } from "../types";
import { ITextFieldProps, TextField } from "../../TextField";
import { Dropzone, IDropzoneProps } from "../../Dropzone";
import { CountrySelect } from "../../CountrySelect";
import { Switch } from "../../Switch";
import { ISelectProps, Select } from "../../Select";
import { IPhoneInputProps, PhoneInput } from "../../PhoneInput";
import { Autocomplete, IAutocompleteProps } from "../../Autocomplete";

export type IInputControlProps = (ITextFieldProps | IDropzoneProps | ISelectProps | IPhoneInputProps | IAutocompleteProps) & Omit<IAbstractInputControlProps, 'onChange'>;

export interface IInputProps {
  className?: string;
  control: FormControl;
  type?: FormControlType;
  onChange?: (field: string, value: any) => void;
}

export const Input: FC<IInputProps & IInputControlProps> = ({
  className = '',
  control,
  type = 'text',
  helperText = "‎",
  onChange = () => { },
  onBlur,
  disabled = false,
  ...inputProps
}) => {
  const [value, setValue] = useState(control.value);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ValidationError>();

  const isDisabled = disabled || control.disabled;

  const helper = useMemo(() => {
    if (touched && !isDisabled && errorMessage) {
      return errorMessage;
    }
    return helperText

  }, [touched, isDisabled, errorMessage, helperText]);

  useEffect(() => {
    if (value !== control.value) {
      setValue(control.value);
    }
  }, [control.value, value]);

  useEffect(() => {
    control.refreshHandler = () => {
      setValue(control.value);
      setTouched(control.touched);
      setErrorMessage(control.error);
    };
    control.refreshHandler();
  }, [control]);

  const handleChange = (newValue) => {
    control.patch(newValue);
    control.validate();
    setValue(newValue);
    onChange(control.name, newValue);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    control.markAsTouched();
  };

  const handleFocus = () => {
    control.markAsTouched(false, false);
  };

  if (type === 'file') {
    return (
      <Dropzone
        {...inputProps}
        name={control.name}
        value={value}
        disabled={isDisabled}
        error={touched && !isDisabled && Boolean(errorMessage)}
        helperText={helper}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  }

  if (type === 'country') {
    return (
      <CountrySelect
        {...inputProps}
        name={control.name}
        value={value}
        placeholder={inputProps.placeholder as string}
        disabled={isDisabled}
        error={touched && !isDisabled && Boolean(errorMessage)}
        helperText={helper}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  }

  if (type === 'phone') {
    return (
      <PhoneInput
        {...inputProps}
        name={control.name}
        value={value}
        placeholder={inputProps.placeholder as string}
        disabled={isDisabled}
        error={touched && !isDisabled && Boolean(errorMessage)}
        helperText={helper}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      />
    );
  }

  if (type === 'switch') {
    return (
      <Switch
        {...inputProps}
        name={control.name}
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
      />
    );
  }

  if (type === 'select') {
    return (
      <Select
        {...inputProps}
        className={className}
        name={control.name}
        value={value}
        placeholder={inputProps.placeholder as string}
        disabled={isDisabled}
        error={touched && !isDisabled && Boolean(errorMessage)}
        helperText={helper}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  }

  if (type === 'autocomplete') {
    return (
      <Autocomplete
        {...(inputProps as IAutocompleteProps)}
        className={className}
        name={control.name}
        value={value}
        placeholder={inputProps.placeholder as string}
        disabled={isDisabled}
        error={touched && !isDisabled && Boolean(errorMessage)}
        helperText={helper}
        onBlur={handleBlur}
        onFocusCapture={handleFocus}
        onChange={handleChange}
      />
    );
  }

  return (
    <TextField
      {...inputProps}
      name={control.name}
      type={type}
      value={value}
      placeholder={inputProps.placeholder as string}
      readonly={control.readonly}
      disabled={isDisabled}
      error={touched && !isDisabled && Boolean(errorMessage)}
      helperText={helper}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
    />
  );
};
