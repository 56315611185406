import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "../../../components";
import {
  CalendarIcon,
  PhoneInTalkIcon,
  PlusCircleIcon,
  HashTagIcon,
  TimeIntervalIcon,
  TagsIcon,
  VoiceMailIcon,
} from '../../../components/icons';
import {
  CallerFilter,
  CallStatusFilter,
  DateRangeFilter,
  DurationFilter,
  NumberFilter,
  TagsFilter,
} from '../../../parts';
import { setCallFilters } from "../../../redux/actions";
import { getCallFilters } from "../../../redux/selectors";
import { FilterModel } from "../../../utils/types";

export interface ICallsFiltersProps {
}

const durationOptions = [
  { text: 'Less than 15s', value: '0~15', min: 0, max: 15 },
  { text: 'Longer than 60s', value: '60~', min: 60 },
  { text: 'Greater than 2 mins', value: '120~', min: 120 },
];

const categories = [
  { type: 'range', title: 'Date Range', icon: CalendarIcon, component: DateRangeFilter },
  { type: 'caller', title: 'Caller', icon: PhoneInTalkIcon, component: CallerFilter },
  { type: 'number', title: 'Sudo Number', icon: HashTagIcon, component: NumberFilter },
  { type: 'target_number', title: 'Target Number', icon: HashTagIcon, component: NumberFilter },
  {
    type: 'duration',
    title: 'Call Duration',
    icon: TimeIntervalIcon,
    component: DurationFilter,
    props: {
      options: durationOptions,
      unit: 'second',
      placeholder: 'Add Duration',
      selectOptionLabel: 'Or select a duration',
    },
  },
  { type: 'status', title: 'Status', icon: VoiceMailIcon, component: CallStatusFilter },
  // { type: 'tag', title: 'Tags', icon: TagsIcon, component: TagsFilter },
];

const CallsFilters: FC<ICallsFiltersProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector(getCallFilters);
  const [activeFilter, setActiveFilter] = useState();

  const remainCategories = useMemo(() => (
    categories.filter((item) => filters.every((f) => f.category !== item.type))
  ), [filters]);

  const onChange = useCallback((newFilters: FilterModel[]) => {
    dispatch(setCallFilters(newFilters));
  }, []);

  const onAddFilter = (category) => {
    onChange([
      ...filters,
      {
        category: category.type,
        text: '',
        value: null,
      },
    ]);
    setTimeout(() => {
      setActiveFilter(category.type);
    }, 500);
  };

  const onSelectFilter = (filter: FilterModel) => {
    onChange(filters.map((item) => (
      item.category === filter.category ? filter : item
    )));
    setActiveFilter(undefined);
  };

  const onDeleteFilter = (filter: FilterModel) => {
    onChange(filters.filter((item) => item.category !== filter.category));
    setActiveFilter(undefined);
  };

  return (
    <div data-cy="call-dt-filters" className="flex items-center flex-wrap flex-shrink">
      <span className="text-sm mr-2 mb-2">Filter data:</span>
      {filters.map((filter, i) => {
        if (filter.locked) {
          return (
            <React.Fragment key={filter.category}>
              {i > 0 && (
                <span className="text-xs mx-2 mb-2">And</span>
              )}
              <Button color="primary" className="text-xs !normal-case font-semibold rounded-full px-4 shadow-md !cursor-default mb-2">
                {filter.text}
              </Button>
            </React.Fragment>
          );
        }

        const category = categories.find((item) => item.type === filter.category);
        return (
          <React.Fragment key={filter.category}>
            {i > 0 && (
              <span className="text-xs mx-2 mb-2">And</span>
            )}
            <category.component
              title={category.title}
              key={filter.category}
              category={filter.category}
              value={filter}
              opened={filter.category === activeFilter}
              {...category.props}
              onChange={onSelectFilter}
              onDelete={onDeleteFilter}
            />
          </React.Fragment>
        );
      })}
      {remainCategories.length > 0 && (
        <>
          <span className="text-xs mx-2 mb-2">And</span>
          <Dropdown
            key={remainCategories.length}
            className="mb-2"
            text="Add a Filter"
            buttonProps={{
              className: 'text-xs font-semibold rounded-3xl hover:!bg-primary hover:text-white',
              color: 'primary-o',
              leftIcon: <PlusCircleIcon className="fill-current" color="current" size={20} />,
            }}
            dropdownClass="w-max max-h-60 rounded-bl-md rounded-br-md px-3 pt-2"
          >
            {remainCategories.map((item) => (
              <div
                key={item.type}
                className="flex items-center cursor-pointer mb-2"
                onClick={() => onAddFilter(item)}
              >
                <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-primary bg-opacity-24 rounded-md">
                  {item.icon({ color: 'primary', size: 16 })}
                </div>
                <span className="min-w-fit text-primary text-sm ml-2">{item.title}</span>
              </div>
            ))}
          </Dropdown>
        </>
      )}

      <div className="mr-auto" />

      {children}
    </div>
  );
};

export default CallsFilters;
