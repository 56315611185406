import React, {FC} from "react";
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from "@mui/material";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";
import classnames from "classnames";

export interface IAutocompleteProps extends Omit<AutocompleteProps<any, boolean, boolean, boolean>, 'size'> {
}

export const Autocomplete: FC<IAutocompleteProps & IAbstractInputControlProps> = ({
  name = '',
  placeholder = '',
  size,
  onChange = () => {},
  labelClass,
  error,
  helperText,
  ...wrapperProps
}) => {
  return (
    <InputWrapper
      {...wrapperProps}
      size={size}
      labelClass={labelClass}
      error={error}
      helperText={helperText}
    >
      {({ className, containerClass, disabled, onFocus, onBlur }) => (
        <MuiAutocomplete
          className={containerClass}
          classes={{ inputRoot: className, option: `text-${size}` }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                className: classnames(params.inputProps?.className, '!py-0')
              }}
              placeholder={placeholder}
            />
          )}
          {...wrapperProps}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e, value: any) => onChange(value)}
        />
      )}
    </InputWrapper>
  );
};
