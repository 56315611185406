import React from "react";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";
import {getLoadingSpinners} from "../../redux/selectors";

export const SpinnerContainer = () => {
  const loadingCount = useSelector(getLoadingSpinners);

  return (
    <>
      {loadingCount > 0 && (
        <div className="fixed top-0 left-0 z-max w-screen h-screen bg-white bg-opacity-60 flex-center">
          <CircularProgress
            sx={{
              color: '#0B1C34',
            }}
            size={40}
            thickness={4}
          />
        </div>
      )}
    </>
  );
};
