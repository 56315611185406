import React from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../components";
import { CloseCircleIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { ToastService } from "../../../../services";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import { WebhookModel } from "../../../../utils/types";
import { IWebhookFormInitParams, WebhookForm } from "../WebhookForm";


export const WebhookCreate = () => {
  //hooks
  const history = useHistory();

  //state
  const [touched, setTouched] = React.useState<boolean>(false);
  const [webhookTouched, setWebhookTouched] = React.useState<boolean>(false);
  const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);

  const [createForm, setCreateForm] = React.useState<IWebhookFormInitParams>({
    getFormData: () => null,
  });

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(bypass, touched, webhookTouched, setShowConfirmCancel, history, ROUTES.DEVELOPER.WEBHOOKS.INDEX)
  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      WebhookModel.create(data, data.type).then((res) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> created new webhook.</>);
        onClose(true);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Creating webhook failed');
      });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Add Webhook</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={() => onClose()} />
      </div>
      <div className="h-0 flex-grow overflow-auto my-4 mx-26">
        <WebhookForm
          setForm={setCreateForm}
          onClose={onClose}
          confirmCancel={showConfirmCancel}
        />
        <div className="flex flex-row bg-inherit justify-end">
          <Button
            data-cy="webhook-cancel"
            className="!font-semibold text-primary mr-2"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            data-cy="webhook-save"
            className="text-sm rounded-full px-8 py-2 !font-semibold shadow-md"
            color="primary"
            onClick={onCreate}
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  )
}
