import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../../constants";
import { Lists } from "./index";

const WhatsAppListsRouting = () => (
    <Switch>
        <Route path={ROUTES.WHATSAPP.LISTS.INDEX} component={Lists} />
    </Switch>
);

export default WhatsAppListsRouting;
