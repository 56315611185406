import _ from "lodash";
import { SetStateAction } from "react";
import { ITextSpeechForm } from "../../pages/Settings/AudioFiles/AudioFileForm/TextSpeechForm";


/**
 * This function compares two forms and sets the value of 'formState'
 * based on the outcome.
 *
 * if 'formState' is true, the 'onCloseConfirmation'
 * function will show the confirmation pop up.
 *
 * @param oldForm - the initial form
 * @param currentForm - the form with latest changes
 * @param formState - the hook that changes
 * if there is a difference in the two forms.
 */
export const compareFormData = (oldForm: ITextSpeechForm, currentForm: any, formState: { (value: SetStateAction<boolean>): void; (value: SetStateAction<boolean>): void; (arg0: boolean): any; }) => {

    if (formState) {
      let isTheSame: boolean = _.isEqual(oldForm, currentForm)

      isTheSame ? formState(false) : formState(true)

      return;
    }

  }

/**
 *
 * @param form1 the main form
 * @param form2 the initial form
 * @returns form where the target numbers are stripped of their spaces
 * why? Editing numbers on the form removes spaces from the number, when the
 * original number had spaces. So there is an array of numbers, and some of these
 * numbers can have spaces and some not - depending on if they were edited or not.
 */

export const stripSpaces = (form1: {
  features: any; targets: any[];
}, form2: {
  features: any; targets: any[];
}) => {
  let originalFormStripped = form1
  let initialFormStripped = form2
  let formDataTargets: any;
  let initialFormDataTargets: any;
    formDataTargets = form1.targets.map((item) => item ? item.replace(/\D/g, ''): item)
    initialFormDataTargets = form2.targets.map((item) => item.replace(/\D/g, ''))

    originalFormStripped.targets = formDataTargets
    initialFormStripped.targets = initialFormDataTargets
  return {originalFormStripped, initialFormStripped}
}
