import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { ImageIcon } from "../icons";
import { WATemplatesModel } from "../../utils/types";

interface IMessagePreviewProps {
    preview?: string;
    template: WATemplatesModel;
    containerClass?: string;
}

export const MessagePreview: FC<IMessagePreviewProps> = ({
    preview,
    template,
    containerClass = '',
}) => {
    const content = template?.message_template;
    const [src, setSrc] = useState<string>('');

    useEffect(() => {
        if (preview !== null || preview !== undefined || preview.length > 0) {
            setSrc(preview)
            return;
        }
        setSrc(null);
    }, [preview])

    return (
        <div className={classNames("mx-4 min-w-[28rem] max-w-[28rem] min-h-[30rem] h-full bg-whatsapp flex flex-col overflow-y-scroll", containerClass)} >
            <div className="bg-gray-100">
                <h1 className="font-bold py-2 px-6">Message Preview</h1>
            </div>
            {template &&
                <div className="mt-6 ml-6 mr-20">
                    <div className="bg-white shadow-md rounded-md p-1">
                        {(template && template.type !== 'TEXT') &&
                            <div className="bg-gray-300 rounded flex justify-center">
                                {src === null || src === undefined ?
                                    <ImageIcon className="p-8" color={"#FFFFFF"} size={120} />
                                    :
                                    <img
                                        className="w-full h-full bg-center bg-contain bg-no-repeat rounded"
                                        src={src}
                                        alt="WhatsApp Campaign Preview"
                                        onError={() => setSrc(null)}
                                    />
                                }
                            </div>
                        }
                        {(template && content.header?.length > 0) &&
                            <div className="m-4">
                                <p className="font-bold">{content.header}</p>
                            </div>
                        }
                        <div className="m-4">
                            <p className="text-xs">{content.body}</p>
                        </div>
                        {(template && content.footer?.length > 0) &&
                            <div className="m-4">
                                <p className="text-xs text-gray-400">{content.footer}</p>
                            </div>
                        }
                    </div>
                    {content.buttons?.length > 0 &&
                        content.buttons.map((button) => (
                            <div key={button.text} className="bg-white shadow-md rounded-md p-4 my-2 text-center">
                                <p className="text-blue text-xs font-semibold">{button.text}</p>
                            </div>
                        ))}
                </div>
            }
            <p className="text-xs font-semibold text-center mt-auto mb-4">Campaign example on recipient's phone</p>
        </div>
    )
}