import React, {FC, useState} from "react";
import {Button, Modal} from "../../../../components";
import TagForm, {ITagFormInitParams} from "../TagForm";
import {MessageBoxService, ToastService} from "../../../../services";
import {TagModel} from "../../../../utils/types";
import {TrashIcon} from "../../../../components/icons";

interface ITagEditModalProps {
  tag: TagModel;
  onClose: () => void;
}

const TagEditModal: FC<ITagEditModalProps> = ({
  tag, onClose,
}) => {
  const [editForm, setEditForm] = useState<ITagFormInitParams>({
    getFormData: () => null,
  });

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
      onClose();
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to <b>delete {tag.name}?</b> This has <b>been used</b>, and this action can not be undone.</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted tag.</>);
        onClose();
      }
    });
  };

  return (
    <Modal
      title="Edit Tag"
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Delete Tag
          </Button>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full shadow-md px-6 py-2"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TagForm tag={tag} onInit={setEditForm} />
    </Modal>
  );
};

export default TagEditModal;
