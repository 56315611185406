import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as WAEmptySVG } from "../../../../assets/wa-empty.svg";
import { OpenLinkIcon } from "../../../../components/icons";

interface IAccountNotFoundProps {
    onSuccess: () => void;
}

export const AccountNotFound: FC<IAccountNotFoundProps> = () => {
    return (
        <div className={"w-full h-full flex flex-col justify-center items-center"}>
            <WAEmptySVG />
            <div className="mt-4 text-blue text-28p font-bold text-center whitespace-pre-wrap">
                You don't have a WhatsApp <br />  Business Account yet
            </div>
            {/* <Link
                className="rounded-full shadow-lg my-6 !px-6 py-3 bg-primary uppercase text-white !font-bold"
                to={'accounts/setup'}
            >
                Create one now
            </Link> */}
            <div className="text-center !font-semibold">
              Want to know more about our WhatsApp Product? <br />
              Find more info
              <a
                className="text-primary font-semibold"
                target="_blank"
                href="https://www.sudonum.com/sales-boosters/whatsapp-lead-tracking"
                rel="noreferrer"
              > here <OpenLinkIcon />
              </a>
            </div>
        </div>
    )
}
