export class VoiceCallLogModel {
  uuid: string;
  call_date: string;
  caller_number: string;
  destination_number: string;
  caller_number_clid: string;
  destination_number_clid: string;
  ip_address: string;
  meta: {
    [key: string]: string;
  };
  tts: string;
  features: string[];
  webhooks: string[];

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      call_date: '',
      caller_number: '',
      destination_number: '',
      caller_number_clid: '',
      destination_number_clid: '',
      ip_address: '',
      meta: {},
      tts: '',
      features: [],
      webhooks: [],
      ...init,
    };

    this.uuid = data.uuid;
    this.call_date = data.call_date;
    this.caller_number = data.caller_number;
    this.destination_number = data.destination_number;
    this.caller_number_clid = data.caller_number_clid;
    this.destination_number_clid = data.destination_number_clid;
    this.ip_address = data.ip_address;
    this.meta = data.meta;
    this.tts = data.tts;
    this.features = data.features;
    this.webhooks = data.webhooks;
  }
}
