export enum WEBHOOK_TYPE {
  CALL_STATUS = 'call_status',
  CDR = 'cdr',
  MISSED_CALL = 'missed_call',
  SMS_CDR = 'sms_cdr',
  WHATSAPP = 'whatsapp',
}

export enum WA_WEBHOOK_TYPE {
  ALL = 'ALL',
  CHATINTROLOG = 'CHATINTROLOG',
  MESSAGE = 'MESSAGE',
}

export const WEBHOOK_TYPES = [
  { name: 'Call Status', value: WEBHOOK_TYPE.CALL_STATUS },
  { name: 'Call Detail Records', value: WEBHOOK_TYPE.CDR },
  { name: 'Missed Call', value: WEBHOOK_TYPE.MISSED_CALL },
  { name: 'SMS CDR', value: WEBHOOK_TYPE.SMS_CDR },
  { name: 'WhatsApp', value: WEBHOOK_TYPE.WHATSAPP },
];

export const WA_WEBHOOK_TYPES = [
  { name: 'All', value: WA_WEBHOOK_TYPE.ALL },
  { name: 'Chat Intro Logs', value: WA_WEBHOOK_TYPE.CHATINTROLOG },
  { name: 'Messages', value: WA_WEBHOOK_TYPE.MESSAGE },
]
