import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Button} from "../../../components";
import {LogoIcon, LogoTextIcon} from "../../../components/icons";
import AuthLayout from "../../../layouts/AuthLayout";
import {formatDuration} from "../../../utils/helpers";
import {AuthService, ToastService} from "../../../services";

const ConfirmEmail = () => {
  const [seconds, setSeconds] = useState(300);
  const { uuid } = useParams<{ uuid: string }>();

  useEffect(() => {
    if (seconds <= 0)
      return;

    const timer = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds]);

  const onResendEmail = () => {
    AuthService.resendVerifyEmail(uuid).then(() => {
      setSeconds(300);
      ToastService.success(<><span className="text-success mr-1">Successfully</span> sent your request.</>);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Resending email failed');
    });
  };

  return (
    <AuthLayout contentClass="w-120">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>

      <h1 className="text-40p text-primary font-bold mt-4">Almost done!</h1>

      <p className="text-blue font-semibold text-lg mt-6">An activation email has been sent to your account.</p>

      <p className="text-sm mt-8">Please <span className="font-semibold">click on the link</span> in your email to continue signing up.</p>

      <p className="text-sm mt-4">If you <span className="font-semibold">haven't received</span> the email, <span className="font-semibold">check your SPAM</span> folder or try resending the email in 5 minutes.</p>

      <div className="text-blue text-4xl font-semibold mt-4">{formatDuration(seconds, 'm:ss')}</div>

      <Button color="primary" className="w-full rounded-full mt-8" onClick={onResendEmail}>
        Resend code
      </Button>
    </AuthLayout>
  );
};

export default ConfirmEmail;
