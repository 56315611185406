import React, {FC, useCallback, useEffect} from "react";
import {FormControl, Input, useForm, Validators} from "../../components/ReactiveForm";

export interface IBillingServiceFormData {
  companyName?: string;
  vatNo?: string;
  email?: string;
}

export type IBillingServiceForm = {
  companyName: FormControl;
  vatNo: FormControl;
  email: FormControl;
};

export interface IBillingServiceFormInitParams {
  getFormData: () => any;
}

export interface IBillingServiceFormProps {
  className?: string;
  data?: IBillingServiceFormData;
  fullWidth?: boolean;
  onInit?: (params: IBillingServiceFormInitParams) => void;
}

export const BillingServiceForm: FC<IBillingServiceFormProps> = ({
  className = '',
  data,
  fullWidth = false,
  onInit = () => {},
}) => {
  const [form] = useForm<IBillingServiceForm>({
    companyName: new FormControl('', [Validators.required()]),
    vatNo: new FormControl('', [Validators.required()]),
    email: new FormControl('', [Validators.required(), Validators.email()]),
  });

  useEffect(() => {
    form.patch({
      companyName: data?.companyName || '',
      vatNo: data?.vatNo || '',
      email: data?.email || '',
    });
  }, [data]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <div className={className}>
      <Input
        control={form.controls.companyName}
        label="Company Name"
        fullWidth={fullWidth}
      />
      <Input
        control={form.controls.vatNo}
        containerClass="mt-4"
        label="VAT No"
        fullWidth={fullWidth}
      />
      <Input
        control={form.controls.email}
        containerClass="mt-4"
        label="Billing contact person email address"
        fullWidth={fullWidth}
      />
    </div>
  );
};
