import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAuthenticated = () => {
  const login = localStorage.getItem("login");
  return !!login;
}

const hasTotpSession = () => {
  const totp = localStorage.getItem("totp");
  if (!totp || totp === "no_session") {
    return true;
  }
  return totp === "valid";
}

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export const TotpRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasTotpSession() && isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/confirm-totp" />
        )
      }
    />
  );
};
