import classNames from "classnames";
import React, { FC } from "react";
import { Button, DataTable, HorizontalStepper, IDataTableColumn, Modal } from "../../../../components";
import { FormControl, Input, Validators, useForm } from "../../../../components/ReactiveForm";
import { StyledDropzone } from "../../../../components/StyledDropzone";
import { CloudUploadIcon, DownloadIcon } from "../../../../components/icons";
import { UploadCsvTipSvg } from "../../../../components/svgs";
import { ToastService, WACampaignsService, WAListsService } from "../../../../services";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { WACampaignsModel } from "../../../../utils/types";

const steps = [1, 2, 3];

export interface WACampaignValidations {
    line: number;
    columns: [
        {
            row: number;
            column: number;
            column_error: string;
        }
    ]
    row?: string;
    row_error?: string;
}

interface ICampaignsImportModalProps {
    campaign: WACampaignsModel;
    onListCreate: (result?: any) => void;
    onClose: (result?: any) => void;
}

type ICreateCampaignsForm = {
    campaign_list_name: FormControl;
}

export const CampaignsImportModal: FC<ICampaignsImportModalProps> = ({
    campaign,
    onListCreate,
    onClose,
}) => {
    //state
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [success, setSuccess] = React.useState<boolean>(null);
    const [hasUploaded, setHasUploaded] = React.useState<boolean>(false);

    //datatable
    const [error, setError] = React.useState<string>('Your CSV failed validation. Please click on the preview below to view your errors.');
    const [errorArray, setErrorArray] = React.useState<WACampaignValidations[]>([]);
    const [rows, setRows] = React.useState<[]>([]);
    const [columnCount, setColumnCount] = React.useState<number>(0);

    //form
    const [form, formData] = useForm<ICreateCampaignsForm>({
        campaign_list_name: new FormControl(campaign.campaign_list_name || "", [Validators.required()]),
    });

    //nav functions
    const onNext = () => {
        setActiveStep((prevState) => prevState + 1)
    }

    const onBack = () => {
        setActiveStep((prevState) => prevState - 1)
    }

    const onReupload = () => {
        setActiveStep(1);
        setSuccess(null);
        setError('Your CSV failed validation. Please click on the preview below to view your errors.');
    }

    const onDownload = () => {
        WAListsService.downloadSampleCSV(campaign.uuid)
            .then((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Campaigns_Sample.csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((err) => {
                ToastService.error("Sample CSV could not be downloaded.");
            });
    }

    const onUpload = (file: File[]) => {
        const data = file[0]
        if (data) {
            WAListsService.uploadCSV({
                campaign_uuid: campaign.uuid,
                uploaded_file: data,
            }).then((res) => {
                setRows([]);
                setSuccess(true);
            }).catch((err) => {
                const uploadedFile = err.response.data.uploaded_file

                //datatable
                const lines = uploadedFile.lines;
                setRows(lines);
                setColumnCount(uploadedFile.count)

                //error handling
                const errorKeys = Object.keys(uploadedFile);
                if (errorKeys.includes("error")) {
                    setError(uploadedFile.message)
                }
                else if (errorKeys.includes("errors")) {
                    setError('Your CSV failed validation. Please click on the preview below to view your errors.');
                    setErrorArray(uploadedFile.errors);
                }
                else {
                    throw new Error("Could not resolve error state for uploaded file.")
                }

                setSuccess(false);
                ToastService.error("Contact list upload failed.");
            })
        }
    }

    const columns = React.useMemo<IDataTableColumn<any>[]>(() => {
        const columnObjects = [
            {
                title: 'Row No',
                tooltip: { title: 'Row Number' },
                render(row, index) {
                    return (index + 1).toString()
                },
            },
            {
                title: 'First Name',
                tooltip: { title: 'First Name' },
                render(row: any) {
                    return row[1] || '-'
                }
            },
            {
                title: 'Last Name',
                tooltip: { title: 'Last Name' },
                render(row: any) {
                    return row[2] || '-'
                }
            },
            {
                title: 'WhatsApp Number',
                tooltip: { title: 'WhatsApp Number' },
                render(row: WACampaignsModel) {
                    return formatPhoneNumber(row[3]);
                }
            },
        ];

        for (let i = 1; i < columnCount; i++) {
            columnObjects.push({
                title: `Variable ${i + 1}`,
                tooltip: { title: `Variable ${i + 1}` },
                render(row: any) {
                    return row[i + 4] || '-'
                }
            })
        }

        return columnObjects;
    }, [rows, columnCount]);

    const close = React.useCallback(() => {
        const data = form.getFormData();
        if (data) {
            WAListsService.modifyList({
                uuid: campaign.campaign_list,
                name: data.campaign_list_name
            }).then((res) => {
                updateCampaign(res)
                onClose();
            }).catch((err) => {
                ToastService.error("Could not update campaign list.")
            })
        }
    }, [campaign])

    React.useEffect(() => {
        setTimeout(() => {
            const container = document.getElementById("validations_table")
            if (container) {
                const table = container.querySelector('table');
                if (table) {
                    errorArray.forEach((error) => {
                        //row errors
                        if (error.row_error && error.row) {
                            const row = table.querySelectorAll('tr')[error.row];
                            if (row) {
                                const cell = row.querySelectorAll('td')[0];
                                cell?.classList.add("!font-bold", "!text-danger");
                                cell.dataset.title = error.row_error
                            }
                        }
                        //col errors
                        if (error?.columns?.length) {
                            const cols = error.columns;
                            cols.forEach((col) => {
                                const row = table.querySelectorAll('tr')[col.row];
                                if (row) {
                                    const cell = row.querySelectorAll('td')[col.column];
                                    cell?.classList.add("!font-bold", "!text-danger");
                                    cell.dataset.title = col.column_error
                                }
                            })
                        }
                    })
                }
            }
        }, 5)
    }, [errorArray, activeStep, rows]);

    React.useEffect(() => {
        if (campaign && campaign.campaign_list?.length === 0) {
            WAListsService.createList({
                name: `${campaign.name}'s List`
            }).then((res) => {
                updateCampaign(res)
            }).catch((err) => {
                ToastService.error("Could not create a new list for the current campaign.")
            })
        }
    }, [campaign])

    React.useEffect(() => {
        if (campaign) {
            form.patch({
                campaign_list_name: campaign.campaign_list_name,
            });
        }
    }, [campaign])

    const updateCampaign = React.useCallback((list) => {
        WACampaignsService.modifyCampaign({
            ...campaign,
            campaign_list: list.uuid,
            campaign_list_name: list.name
        }).then((res) => {
            onListCreate(res);
        }).catch((err) => {
            ToastService.error("Could not save list onto the current campaign.")
        })
    }, [])

    return (
        <Modal
            title={"Create a campaign"}
            size="full"
            contentClass="bg-[#F8F8F8]"
            footerClass="!bg-[#F8F8F8]"
            footerComponent={
                <div className="flex flex-row m-8">
                    {activeStep !== 0 &&
                        <Button
                            className="text-sm text-primary"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    }
                    {(success === true || success === null) &&
                        <Button
                            className="text-sm rounded-full px-6 py-2"
                            color={"primary"}
                            disabled={success === null && activeStep !== 0}
                            onClick={activeStep === 1 ? close : onNext}
                        >
                            Continue
                        </Button>
                    }
                    {(success === false && activeStep !== 2) &&
                        <Button
                            className="text-sm rounded-full px-6 py-2"
                            color={"primary"}
                            onClick={() => setActiveStep(2)}
                            disabled={rows?.length === 0 || rows === undefined}
                        >
                            Preview
                        </Button>
                    }
                    {(success === false && activeStep === 2) &&
                        <Button
                            className="text-sm rounded-full px-6 py-2"
                            color={"primary"}
                            onClick={() => onReupload()}
                        >
                            Re Upload Your CSV File
                        </Button>
                    }
                </div>
            }
            onClose={onClose}
        >
            <div className="flex flex-col">
                <HorizontalStepper
                    steps={steps}
                    activeStep={activeStep}
                    className={classNames("w-1/2 my-15 mx-auto", activeStep === 2 && "!my-10")}
                />
                {activeStep === 0 &&
                    <div className="grid grid-cols-12 border-2 border-blue rounded-xl md:w-5/6 lg:w-5/6 xl:w-2/3 m-auto">
                        <div className="flex flex-col justify-center align-middle p-8 col-span-5">
                            <Button
                                className="rounded-full normal-case shadow-lg mb-6 mx-auto !py-2 !px-8 !font-semibold"
                                color="blue"
                                onClick={onDownload}
                            >
                                <DownloadIcon
                                    color={"white"}
                                    size={30}
                                    className="px-1"
                                />
                                Download Example
                            </Button>
                            <p className="text-md text-center">
                                Please download the <strong>CSV example</strong> & fill in your fields accordingly
                            </p>
                        </div>
                        <div className="bg-[#307CD908] p-8 col-span-7">
                            <span className="text-blue font-bold text-xl mb-8">Some Friendly Tips</span>
                            <div className="flex flex-col gap-6 mt-4">
                                <div className="flex flex-row gap-4">
                                    <UploadCsvTipSvg />
                                    <div>
                                        <p>If you have <strong>your own csv.</strong> Update your column headings to <strong>match ours;</strong></p>
                                        <div className="grid grid-cols-2 text-sm gap-2 mt-2">
                                            <span>Name: <strong>first_name</strong></span>
                                            <span>Surname: <strong>last_name</strong></span>
                                            <span>Number: <strong>whatsapp_number</strong> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4">
                                    <UploadCsvTipSvg />
                                    <p>All <strong>phone numbers</strong> should be in this format: <strong>27712384083</strong></p>
                                </div>
                                <div className="flex flex-row gap-4">
                                    <UploadCsvTipSvg />
                                    <p>Match <strong>your variables</strong>, to the <strong>template variable</strong> headings. <br /> <span className="text-sm">For example, in your template, variable 1 is an email. Please add the email addresses in the matched variable field: body_var_1</span></p>
                                </div>
                                <div className="flex flex-row gap-4">
                                    <UploadCsvTipSvg />
                                    <p>Ensure you have <strong>content for all variable fields</strong>, If you <strong>don't</strong> please <strong>remove the column.</strong></p>
                                </div>
                                <div className="flex flex-row gap-4">
                                    <UploadCsvTipSvg />
                                    <p><strong>Don't change</strong> the column headings!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeStep === 1 &&
                    <div className={classNames(hasUploaded ? "md:w-2/3 lg:w-2/3 xl:w-1/2 m-auto" : "md:w-5/6 lg:w-5/6 xl:w-2/3 m-auto")}>
                        <div className="text-center">
                            <h1 className="font-bold text-xl">Please upload your CSV file</h1>
                            <p className="text-sm">Once you upload a CSV file, we will automatically generate a list from it.</p>
                        </div>

                        <div className={classNames(hasUploaded && "!border-none !my-0", "grid grid-cols-12 border-2 border-blue rounded-xl my-4")}>
                            <div className={classNames(!hasUploaded && "p-8 !col-span-5", "flex flex-col justify-center align-middle col-span-full")}>
                                <StyledDropzone
                                    accept={['.csv']}
                                    default_files={[]}
                                    fullWidth
                                    containerClass="my-4"
                                    border={false}
                                    onChange={onUpload}
                                    onFilesDetected={(res: boolean) => setHasUploaded(res)}
                                    onRemove={() => setSuccess(null)}
                                    icon={<CloudUploadIcon size={120} color={'blue'} />}
                                />
                            </div>
                            {!hasUploaded &&
                                <div className="bg-[#307CD908] p-8 col-span-7">
                                    <span className="text-blue font-bold text-xl mb-8">Some Friendly Tips</span>
                                    <div className="flex flex-col gap-6 mt-4">
                                        <div className="flex flex-row gap-4">
                                            <UploadCsvTipSvg />
                                            <div>
                                                <p>If you have <strong>your own csv.</strong> Update your column headings to <strong>match ours;</strong></p>
                                                <div className="grid grid-cols-2 text-sm gap-2 mt-2">
                                                    <span>Name: <strong>first_name</strong></span>
                                                    <span>Surname: <strong>last_name</strong></span>
                                                    <span>Number: <strong>whatsapp_number</strong> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            <UploadCsvTipSvg />
                                            <p>All <strong>phone numbers</strong> should be in this format: <strong>27712384083</strong></p>
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            <UploadCsvTipSvg />
                                            <p>Match <strong>your variables</strong>, to the <strong>template variable</strong> headings. <br /> <span className="text-sm">For example, in your template, variable 1 is an email. Please add the email addresses in the matched variable field: body_var_1</span></p>
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            <UploadCsvTipSvg />
                                            <p>Ensure you have <strong>content for all variable fields</strong>, If you <strong>don't</strong> please <strong>remove the column.</strong></p>
                                        </div>
                                        <div className="flex flex-row gap-4">
                                            <UploadCsvTipSvg />
                                            <p><strong>Don't change</strong> the column headings!</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {success === null &&
                            <p className="text-blue text-xs"><span className="font-bold">TIP:</span> We only allow for 500 contacts to be imported for your first few campaign sends.</p>
                        }
                        {success === false &&
                            <p className="text-danger text-xs font-bold">{error}</p>
                        }
                        {success === true &&
                            <>
                                <p className="text-success text-xs font-bold">All items were imported successfully</p>

                                <Input
                                    label={"Give your list a name"}
                                    control={form.controls.campaign_list_name}
                                    type="text"
                                    containerClass="mt-5 !max-w-full"
                                />
                            </>
                        }
                    </div>
                }
            </div>
            {activeStep === 2 &&
                <div
                    id="validations_table"
                >
                    <div className="text-center my-10">
                        <h1 className="text-xl font-bold">Your validation failed</h1>
                        <p className="text-sm">The following rows have errors. Please go and fix them in your sheet and re-upload your CSV file</p>
                    </div>

                    <DataTable
                        cellClass={"!text-11p"}
                        wrapperClass={"w-full"}
                        columns={columns}
                        data={rows}
                        size="lg"
                        stripped
                        stickyHeader
                        headerCellClass="!text-13p"
                    />
                </div>
            }
        </Modal >
    )
}