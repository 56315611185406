export class OrganizationDetailModel {
  TaxNumber: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  Region: string;
  PostalCode: string;
  Country: string;
  AddressType: string;

  constructor(init: any = {}) {
    const data = {
      TaxNumber: '',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Region: '',
      PostalCode: '',
      Country: '',
      AddressType: '',
      ...init,
    };

    this.TaxNumber = data.TaxNumber;
    this.AddressLine1 = data.AddressLine1;
    this.AddressLine2 = data.AddressLine2;
    this.City = data.City;
    this.Region = data.Region;
    this.PostalCode = data.PostalCode;
    this.Country = data.Country;
    this.AddressType = data.AddressType;
  }
}
