import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { AudioPlayer, Chip, Chips, Input, Dropzone2 } from "../../../../../components";
import { FormControl, useForm, Validators } from "../../../../../components/ReactiveForm";
import { AudioModel } from "../../../../../utils/types";
import _ from "lodash";
import { compareFormData } from "../../../../../utils/helpers/form.helper";

export interface IUploadFileFormProps {
  className?: string;
  form: any;
  previewUrl: string;
  setPreviewUrl: (string) => void;
  setAudioTouched?: Dispatch<SetStateAction<boolean>>

}

const UploadFileForm: FC<IUploadFileFormProps> = ({
  className = '',
  form,
  previewUrl,
  setPreviewUrl,
  setAudioTouched,
}) => {
  const audiofile = new File([], form.controls.name.value, {});

  const onSelectFile = (files: File[]) => {
    if (!files?.length) return;

    setPreviewUrl(URL.createObjectURL(files[0]));
    form.patch({ file: files[0] });
  };

  return (
    <div className={className}>
      <Dropzone2
        fullWidth
        default_files={audiofile.name ? [audiofile] : []}
        inputClass="!bg-white !rounded-md shadow-md !py-4"
        accept={['audio/*']}
        onChange={onSelectFile}
      />

      {previewUrl &&
        <div className="bg-[#cfd3d9] rounded px-6 pt-2 pb-4 mt-8">
          <div className="text-blue-dark font-semibold mb-2">Audio Preview</div>
          <AudioPlayer className="bg-gray-f5" src={previewUrl} />
        </div>
      }
    </div>
  );
};

export default UploadFileForm;
