import {AUDIO_TYPE} from "../enums";
import {CONFIG} from "../../constants";
import {AudioService} from "../../services";

export class AudioModel {
  name: string;
  type: AUDIO_TYPE;

  file?: File;

  constructor(init: any = {}) {
    const data = {
      name: '',
      type: '',
      ...init,
    };

    this.name = data.name;
    this.type = data.type;
    this.file = data.file;
  }

  update(file) {
    return AudioService.update(this.name, {
      file,
      type: this.type,
    })
  }

  url() {
    return `${CONFIG.API_SERVER}${CONFIG.API_SERVER.endsWith('/') ? '' : '/'}audiofiles/${this.name}/?type=${this.type}`;
  }

  getS3Url() {
    return AudioService.getAudioFile(this.name, this.type, {noredirect: true})
  }

  getFileBlob() {
    return AudioService.getAudioFile(this.name, this.type)
  }
}
