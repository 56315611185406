import React, {FC, useEffect, useMemo, useState} from "react";
import {Button, TextField, Modal, Chips, Chip} from "../../components";
import {PlusCircleIcon} from "../../components/icons";
import {CreateNewTagModal} from "../CreateNewTagModal";

interface IAddTagModalProps {
  tags?: string[];
  onClose: () => void;
  onSubmit: (newTags: string[]) => void;
}

const allTags = ['Important', 'Follow up Required', 'New Prospect', 'Lead', 'Existing Customer'];

export const AddTagModal: FC<IAddTagModalProps> = ({
  tags,
  onClose,
  onSubmit,
}) => {
  const [defaultTags, setDefaultTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [showCreateNewTagModal, setShowCreateNewTagModal] = useState(false);

  useEffect(() => {
    if (!tags?.length) {
      setDefaultTags(allTags);
    } else {
      setDefaultTags(allTags.filter((tag) => !tags.includes(tag)));
    }
  }, [tags]);

  const searchedDefaultTags = useMemo(() => {
    const keyword = search.replace(/\s/g, '').toLowerCase();
    return (
      defaultTags.filter((item) => item.replace(/\s/g, '').toLowerCase().includes(keyword))
    );
  }, [search, defaultTags]);

  const searchedCustomTags = useMemo(() => {
    const keyword = search.replace(/\s/g, '').toLowerCase();
    return (
      customTags.filter((item) => item.replace(/\s/g, '').toLowerCase().includes(keyword))
    );
  }, [search, customTags]);

  const onToggleSelect = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const onAddTag = (tag: string) => {
    setCustomTags([...customTags, tag]);
    setSelectedTags([...selectedTags, tag]);
  };

  const onSave = () => {
    if (selectedTags.length) {
      onSubmit(selectedTags);
      onClose();
    }
  };

  return (
    <Modal
      title="Add a tag"
      contentClass="px-7 py-5"
      hasCloseButton={false}
      footerComponent={(
        <>
          <Button
            className="text-blue"
            leftIcon={<PlusCircleIcon color="blue" size={24} />}
            onClick={() => setShowCreateNewTagModal(true)}
          >
            Create a new tag
          </Button>

          <Button className="text-primary ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            className="rounded-3xl shadow-md px-6"
            disabled={!selectedTags.length}
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TextField
        fullWidth
        value={search}
        placeholder="Search Tags"
        clearable
        onChange={setSearch}
      />

      {!!searchedDefaultTags.length && (
        <Chips className="mt-3">
          {searchedDefaultTags.map((item, i) => (
            <Chip key={i} active={selectedTags.includes(item)} onClick={() => onToggleSelect(item)}>
              {item}
            </Chip>
          ))}
        </Chips>
      )}

      {!!searchedCustomTags.length && (
        <div className="mt-3">
          <div className="font-bold">Your tags</div>
          <Chips>
            {searchedCustomTags.map((item, i) => (
              <Chip key={i} active={selectedTags.includes(item)} onClick={() => onToggleSelect(item)}>
                {item}
              </Chip>
            ))}
          </Chips>
        </div>
      )}

      {showCreateNewTagModal && (
        <CreateNewTagModal
          onCreate={onAddTag}
          onClose={() => setShowCreateNewTagModal(false)}
        />
      )}
    </Modal>
  );
};
