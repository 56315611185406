import { FC } from "react";
import { ROUTES } from "./routes";
import { ROLE } from "../utils/enums";
import {
  CommentsIcon,
  DashboardIcon,
  DeveloperIcon,
  IIconProps,
  PhoneInTalkIcon,
  HashTagIcon,
  WhatsAppIcon,
} from "../components/icons";
import { AuthService } from "../services";
import { SettingsIcon } from "../components/icons/Settings";

export interface MenuItem {
  text?: string;
  type?: 'menu' | 'separator' | 'spacing';
  icon?: FC<IIconProps>;
  iconText?: string;
  prefix?: string;
  link?: string;
  pin?: 'standard' | 'toggle';
  pinned?: boolean;
  only_main_org?: boolean;
  admin_only?: boolean;
  staff_only?: boolean;
  has_role?: ROLE;
  subMenus?: MenuItem[];
  callback?: () => void
}

export const MENUS: MenuItem[] = [
  {
    text: 'Dashboards',
    type: 'menu',
    icon: DashboardIcon,
    prefix: ROUTES.DASHBOARD,
    link: '/dash/calls',
    subMenus: [
      {
        text: 'Calls',
        type: 'menu',
        link: '/dash/calls',
        pin: 'toggle',
        pinned: true,
      },
      {
        text: 'Messages',
        type: 'menu',
        link: '/dash/messages',
        pin: 'toggle',
        pinned: false,
      },
      {
        text: 'WhatsApp Leads',
        type: 'menu',
        link: '/dash/whatsapp-leads',
        pin: 'toggle',
        pinned: false,
      },
    ],
  },
  {
    text: 'Calls',
    type: 'menu',
    icon: PhoneInTalkIcon,
    prefix: ROUTES.CALLS.PREFIX,
    link: ROUTES.CALLS.INDEX,
    subMenus: [
      { text: 'Calls Received', link: ROUTES.CALLS.RECEIVED },
      { text: 'Calls Made', link: ROUTES.CALLS.MADE },
      { text: 'Blocked Calls', link: ROUTES.CALLS.BLOCKED },
    ],
  },
  {
    text: 'Messages',
    type: 'menu',
    icon: CommentsIcon,
    prefix: ROUTES.MESSAGES.PREFIX,
    link: ROUTES.MESSAGES.INDEX,
    subMenus: [
      { text: 'Messages Received', link: ROUTES.MESSAGES.RECEIVED },
      { text: 'Messages Sent', link: ROUTES.MESSAGES.SENT },
      { text: 'Blocked Messages', link: ROUTES.MESSAGES.BLOCKED },
    ],
  },
  {
    text: 'Numbers',
    type: 'menu',
    icon: HashTagIcon,
    link: ROUTES.NUMBERS.PREFIX,
  },
  {
    text: 'WhatsApp',
    type: 'menu',
    icon: WhatsAppIcon,
    prefix: ROUTES.WHATSAPP.PREFIX,
    link: ROUTES.WHATSAPP.PREFIX,
    subMenus: [
      {
        text: 'WhatsApp Accounts',
        type: 'menu',
        link: ROUTES.WHATSAPP.ACCOUNTS.INDEX,
        pinned: false,
      },
      {
        text: 'Lead Tracking',
        type: 'menu',
        link: ROUTES.WHATSAPP.CHAT_INTROS.INDEX,
        pinned: false,
        subMenus: [
          {
            text: 'WhatsApp Chat Intros',
            type: 'menu',
            link: ROUTES.WHATSAPP.CHAT_INTROS.INDEX,
            pinned: false,
          },
          {
            text: 'WhatsApp Chat Intro Logs',
            type: 'menu',
            link: ROUTES.WHATSAPP.CHAT_LOGS.INDEX,
            pinned: false,
          },
        ]
      },
      {
        text: 'Campaigns',
        type: 'menu',
        link: ROUTES.WHATSAPP.CAMPAIGNS.INDEX,
        pinned: false,
        has_role: ROLE.CAMPAIGN,
        subMenus: [
          {
            text: 'WhatsApp Campaigns',
            type: 'menu',
            link: ROUTES.WHATSAPP.CAMPAIGNS.INDEX,
            pinned: false,
          },
          {
            text: 'WhatsApp Campaign Contact Lists',
            type: 'menu',
            link: ROUTES.WHATSAPP.LISTS.INDEX,
            pinned: false,
          },
        ]
      },
      // {
      //   //TODO adjust when templates is live
      //   text: 'Templates',
      //   type: 'menu',
      //   link: ROUTES.WHATSAPP.CHAT_INTROS.INDEX,
      //   pinned: false,
      //   subMenus: [
      //     {
      //       //TODO adjust when templates is live
      //       text: 'Message Templates',
      //       type: 'menu',
      //       link: ROUTES.WHATSAPP.CHAT_INTROS.INDEX,
      //       pinned: false,
      //     },
      //     {
      //       //TODO adjust when templates is live
      //       text: 'Chat Intro Templates',
      //       type: 'menu',
      //       link: ROUTES.WHATSAPP.CHAT_LOGS.INDEX,
      //       pinned: false,
      //     },
      //   ]
      // },
      {
        text: 'Documentation',
        type: 'menu',
        link: ROUTES.WHATSAPP.DOCUMENTATION.INDEX,
        pinned: false,
      },
    ],
  },
  {
    type: 'spacing',
  },
  {
    text: 'Developer',
    type: 'menu',
    icon: DeveloperIcon,
    prefix: ROUTES.DEVELOPER.PREFIX,
    link: ROUTES.DEVELOPER.INDEX,
    subMenus: [
      { text: 'Documentation', link: ROUTES.DEVELOPER.DOCUMENTATION },
      { text: 'API Tokens', link: ROUTES.DEVELOPER.API_TOKENS },
      {
        text: 'Current Errors',
        type: 'menu',
        link: ROUTES.DEVELOPER.ISSUES.INDEX,
        pinned: false,
      },
      // {
      //   text: 'Issues & Errors',
      //   pinned: false,
      //   prefix: ROUTES.DEVELOPER.ISSUES.PREFIX,
      //   link: ROUTES.DEVELOPER.ISSUES.INDEX,
      //   subMenus: [
      //     {
      //       text: 'Current Errors',
      //       type: 'menu',
      //       link: ROUTES.DEVELOPER.ISSUES.INDEX,
      //       pinned: false,
      //     },
      //     {
      //       text: 'Error Management',
      //       type: 'menu',
      //       link: ROUTES.DEVELOPER.ERROR_MANAGEMENT.INDEX,
      //       pinned: false,
      //     },
      //   ]
      // },
      { text: 'Webhooks', link: ROUTES.DEVELOPER.WEBHOOKS.INDEX },
    ],
  },
  {
    text: 'Settings',
    type: 'menu',
    icon: SettingsIcon,
    iconText: 'V 1.1.1.',
    prefix: ROUTES.SETTINGS.PREFIX,
    link: ROUTES.SETTINGS.INDEX,
    subMenus: [
      { text: 'Profile', link: ROUTES.SETTINGS.PROFILE },
      { text: 'Users', link: ROUTES.SETTINGS.USERS.INDEX, admin_only: true },
      { text: 'Projects', link: ROUTES.SETTINGS.PROJECTS.INDEX, admin_only: true, only_main_org: true },
      // {
      //   text: 'Billing Management',
      //   subMenus: [
      //     { text: 'Billing Information', link: ROUTES.SETTINGS.BILLING.INDEX },
      //     { text: 'Invoices', link: ROUTES.SETTINGS.INVOICES.INDEX },
      //   ],
      // },
      {
        text: 'Spam Control',
        subMenus: [
          { text: 'Blocked Numbers', link: ROUTES.SETTINGS.BLOCKED_NUMBERS },
          // { text: 'Blocked Keywords', link: ROUTES.SETTINGS.BLOCKED_KEYWORDS },
        ],
      },
      { text: 'Audio Files', link: ROUTES.SETTINGS.AUDIO_FILES.INDEX },
      { text: 'Integrations', link: ROUTES.SETTINGS.INTEGRATIONS.INDEX },
      { text: 'Two Factor Authentication', link: ROUTES.SETTINGS.TFA },
      { text: 'Logout', callback: () => { AuthService.logout(); } },
      // { text: 'Tags', link: ROUTES.SETTINGS.TAGS },
    ],
  },
];
