import {HttpService} from "./http.service";
import {ICountry} from "../utils/interfaces";

export class CommonService {

  static getCountries(showSpinner = true): Promise<ICountry[]> {
    return HttpService.get('/countries', {}, {}, showSpinner)
      .then((res) => res.results);
  }
}
