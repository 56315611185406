import React, {FC} from "react";
import {CalendarRangeIcon, CreditCardIcon} from "../../components/icons";
import {ICreditCardFormData} from "../CreditCardForm";

export interface ICreditCardDetailProps {
  className?: string;
  data?: ICreditCardFormData;
  editable?: boolean;
  removable?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
}

export const CreditCardDetail: FC<ICreditCardDetailProps> = ({
  className = '',
  data,
  editable = false,
  removable = false,
  onEdit = () => {},
  onRemove = () => {},
}) => {
  return (
    <div className={className}>
      <div className="w-full flex items-center justify-between flex-wrap border border-gray-f4 px-4 py-1">
        <span className="text-xs my-1">Credit Card Details</span>
        <div className="flex items-center mx-4 my-1">
          <CreditCardIcon size={24} color="#BCBCBC" />
          <span className="text-blue-dark text-sm ml-2">∙∙∙∙ ∙∙∙∙ ∙∙∙∙ {data.cardNumber.substr(-4)}</span>
        </div>
        <div className="flex items-center mx-4 my-1">
          <CalendarRangeIcon size={24} color="#BCBCBC" />
          <span className="text-blue-dark text-sm ml-2">{data.expiryDate}</span>
        </div>
        <div />
        <div className="flex items-center">
          {editable && (
            <span
              className="text-blue text-xs font-semibold hover:underline uppercase cursor-pointer ml-4 my-1"
              onClick={onEdit}
            >
              Change
            </span>
          )}
          {removable && (
            <span
              className="text-pink text-xs font-semibold hover:underline uppercase cursor-pointer ml-4 my-1"
              onClick={onRemove}
            >
              Remove
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
