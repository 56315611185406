import React, {FC} from "react";
import classnames from "classnames";

export interface IChipsProps {
  className?: string;
}

export const Chips: FC<IChipsProps> = ({
  children,
  className = '',
}) => {
  return (
    <div className={classnames('flex items-center flex-wrap', className)}>
      {children}
    </div>
  );
};
