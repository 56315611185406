import React, { FC, ReactElement } from "react";
import classnames from "classnames";

export type CardSize = 'sm' | 'md' | 'lg';

export interface ICardProps {
  className?: string;
  title?: string;
  size?: CardSize;
  header?: ReactElement;
  headerClass?: string;
  contentClass?: string;
  onClick?: () => void;
}

export const Card: FC<ICardProps> = ({
  children,
  className = '',
  title = '',
  size = 'md',
  header,
  headerClass = '',
  contentClass = '',
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        'bg-white rounded-md mb-4.5 shadow-md',
        { 'px-6 py-4': size === 'sm' },
        { 'px-8 py-6': size === 'md' },
        { 'px-10 py-8': size === 'lg' },
        className,
      )}>
      {(header || title) && (
        <div className={classnames(
          'border-gray-e',
          { 'border-b': children },
          { 'pb-3 mb-4': size === 'md' },
          { 'pb-5 mb-6': size === 'lg' },
          headerClass,
        )}>
          {header || title}
        </div>
      )}
      <div className={contentClass}>
        {children}
      </div>
    </div>
  );
};
