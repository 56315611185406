import {HttpService} from "./http.service";
import {CallRecordModel} from "../utils/types";
import {ISearchCallsRequest} from "../utils/dto";
import {formatPaginatedResult, trimObject, filterObjectByKey} from "../utils/helpers";

export class CallService {

  static search(query?: ISearchCallsRequest, showSpinner = true) {
    return HttpService.get('/call-detail-records/', trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(CallRecordModel, res));
  }

  static exportCalls(query?: ISearchCallsRequest, showSpinner = true) {
    const exclude_list = ["page", "page_size"]
    query = filterObjectByKey(query, ([key]) => exclude_list.indexOf(key) < 0)
    return HttpService.get('/call-detail-records/export/', trimObject(query), {}, showSpinner);
  }
}
