import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '../../../constants'
import GA4 from './GA4'
import Integrations from '.'
import GA4Edit from './GA4/GA4Edit'

const IntegrationsRouting = () => (
    <Switch>
        <Route exact path={ROUTES.SETTINGS.INTEGRATIONS.PREFIX} component={Integrations} />
        <Route exact path={ROUTES.SETTINGS.INTEGRATIONS.GA4.PREFIX} component={GA4} />
    </Switch>
)

export default IntegrationsRouting;
