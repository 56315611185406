import React, {FC, useEffect} from "react";
import {Button, Input, Modal} from "../../components";
import {CloseCircleIcon, PlusCircleIcon} from "../../components/icons";
import {
  FormControl,
  FormGroup,
  useFormArray,
  Validators
} from "../../components/ReactiveForm";

export interface IExtraInfo {
  label: string;
  content: string;
}

export type IExtraInfoForm = FormGroup<{
  label: FormControl;
  content: FormControl;
}>;

export interface IAddExtraInfoModalProps {
  data?: IExtraInfo[];
  onSave?: (data: IExtraInfo[]) => void;
  onClose?: () => void;
}

export const AddExtraInfoModal: FC<IAddExtraInfoModalProps> = ({
  data = [],
  onSave = () => {},
  onClose = () => {},
}) => {
  const [form] = useFormArray<IExtraInfoForm>([]);

  useEffect(() => {
    form.clear();
    data.forEach((subForm) => {
      onAddInfo(subForm.label, subForm.content);
    });
    if (!data.length) {
      onAddInfo();
    }
  }, [data]);

  const onAddInfo = (label?: string, content?: string) => {
    form.append(new FormGroup({
      label: new FormControl(label, [Validators.required()]),
      content: new FormControl(content, [Validators.required()]),
    }));
  };

  const onRemoveInfo = (control) => {
    form.remove(control);
  };

  const onSubmit = () => {
    if (!form.validate())
      return;

    onSave(form.getFormData());
    onClose();
  };

  return (
    <Modal
      size="sm"
      title="Add Extra Info"
      disableBackdropClose
      footerComponent={(
        <>
          {form.controls.length > 3 && (
            <Button
              data-cy="add-another-extra-info"
              className="text-blue px-0"
              leftIcon={<PlusCircleIcon />}
              onClick={() => onAddInfo()}
            >
              Add another
            </Button>
          )}
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            data-cy="save-added-extra-info"
            color="primary"
            className="text-sm rounded-full shadow-md px-8 ml-2"
            onClick={onSubmit}
          >
            Add
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <p>
        Please provide us with the labels and content for new fields to be added to your extra info section.
      </p>

      {form.controls.map((subForm, i) => (
        <div key={i} className="flex subForms-start has-hover-action mt-4">
          <span className="font-semibold mt-1">{i + 1}.</span>
          <div data-cy={`extra-info-${i}`} className="w-0 flex-grow mx-4">
            <Input
              size="xs"
              fullWidth
              placeholder="Label name: eg. Campaign Type"
              control={subForm.controls.label}
            />
            <Input
              fullWidth
              containerClass="mt-2"
              placeholder="Content: eg. Paid Campaign"
              control={subForm.controls.content}
            />
          </div>
          <CloseCircleIcon
            data-cy="remove-extra-info"
            className="mt-8 pnh-transparent cursor-pointer"
            color="#DDD"
            size={24}
            onClick={() => onRemoveInfo(subForm)}
          />
        </div>
      ))}
      {form.controls.length < 4 && (
        <Button
          data-cy="add-another-extra-info"
          className="text-blue px-0 ml-6 mt-4"
          leftIcon={<PlusCircleIcon />}
          onClick={() => onAddInfo()}
        >
          Add another
        </Button>
      )}
    </Modal>
  );
};
