import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const PhoneInTalkIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={18.634} baseHeight={18.634} {...props}>
    {(width, height, color, color2) => (
      <svg width={width} height={height} viewBox="0 0 18.634 18.634">
        <path fill={color2 || color}
              d="M34.1,16.746a.643.643,0,0,1-.643-.643,4.824,4.824,0,0,0-4.819-4.819.643.643,0,0,1,0-1.285,6.111,6.111,0,0,1,6.1,6.1A.643.643,0,0,1,34.1,16.746Z"
              transform="translate(-19.325 -6.787)"/>
        <path fill={color2 || color}
              d="M37.317,9.959a.643.643,0,0,1-.643-.643,8.04,8.04,0,0,0-8.031-8.031.643.643,0,0,1,0-1.285,9.327,9.327,0,0,1,9.317,9.317.643.643,0,0,1-.643.643Z"
              transform="translate(-19.325)"/>
        <path fill={color}
              d="M8.713,10.958a7.52,7.52,0,0,1-2.271-4.27.639.639,0,0,1,.183-.535L8.065,4.715a.643.643,0,0,0,.091-.8L5.864.362A.643.643,0,0,0,5.032.127L1.353,1.86A.639.639,0,0,0,1,2.5,17.782,17.782,0,0,0,6.415,13.255,17.777,17.777,0,0,0,17.171,18.67a.639.639,0,0,0,.638-.353l1.733-3.679a.643.643,0,0,0-.233-.831l-3.558-2.291a.643.643,0,0,0-.8.09l-1.439,1.439a.639.639,0,0,1-.535.183,7.52,7.52,0,0,1-4.27-2.271Z"
              transform="translate(-0.997 -0.04)"/>
      </svg>
    )}
  </Icon>
);
