import React, { FC, useCallback, useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { NumberModel } from "../../../../utils/types";
import { Card, CheckBox, Input, WithTooltipIcon } from "../../../../components";
import { TextFileInput, TextFileInputForm } from "../../../../parts";
import { AudioService } from "../../../../services";
import { AUDIO_TYPE, NUMBER_FEATURE } from "../../../../utils/enums";
import { uploadTTS } from "../../../../utils/helpers";

export interface IRecordCallsProps {
  number: NumberModel;
  form: TextFileInputForm;
  formData: any;
  initialForm?: any;
  setTouched?: any
  onUpdate(data: any): void;
}

const RecordCalls: FC<IRecordCallsProps> = ({
  number,
  form,
  formData,
  initialForm,
  setTouched,
  onUpdate,
}) => {
  const [enabled, setEnabled] = useState(false);

  const onEnable = useCallback((enable) => {
    setEnabled(enable);
    form.controls.enabled.patch(enable);
    setTouched(initialForm, form.getFormData())
    onSave().then();
  }, [form]);


  useEffect(() => {
    const enabled = number.features.includes(NUMBER_FEATURE.RECORD_CALL) || number.features.includes(NUMBER_FEATURE.RECORD_CALL_ON_ANSWER);
    form.patch({
      enabled,
      mode: number.record_call_file ? 'file' : 'text',
      text: '', // TODO: Need to add a field on backend for tts
      file: number.record_call_file,
      recordCallsOnAnswered: number.features.includes(NUMBER_FEATURE.RECORD_CALL_ON_ANSWER),
    });
    initialForm = {
      enabled,
      mode: number.record_call_file ? 'file' : 'text',
      text: '', // TODO: Need to add a field on backend for tts
      file: number.record_call_file,
      recordCallsOnAnswered: number.features.includes(NUMBER_FEATURE.RECORD_CALL_ON_ANSWER),
    }

    setEnabled(enabled);
    return () => {
      form.disable();
    };
  }, [number]);

  useEffect(() => {
    if (formData) {
      const enable = formData.enabled || false;
      setEnabled(enable);
      form.controls.enabled.patch(enable);
    }
  }, []);


  const onUploadFile = useCallback((file: File) => {
    return AudioService
      .upload({
        name: `${AUDIO_TYPE.RECORD_CALL}_${number.number}_${Date.now()}`,
        type: AUDIO_TYPE.RECORD_CALL,
        file,
      }, false)
      .then((audio) => audio.name);
  }, []);

  const onRemoveFile = useCallback((fileName) => {
    return AudioService.delete(fileName, AUDIO_TYPE.RECORD_CALL, false);
  }, []);

  const onFieldChange = (field: string, value) => {
    form.patch({
      [field]: value,
    });
  };

  const onSave = async () => {
    if (!form.validate()) {
      return;
    }

    const formData = form.getFormData();

    const data: any = {};
    const availableFeatures = [NUMBER_FEATURE.RECORD_CALL, NUMBER_FEATURE.RECORD_CALL_ON_ANSWER]
    const features = (number.features || []).filter((item) => !availableFeatures.includes(item));

    if (formData.enabled) {
      if (formData.recordCallsOnAnswered) {
        features.push(NUMBER_FEATURE.RECORD_CALL_ON_ANSWER);
      } else {
        features.push(NUMBER_FEATURE.RECORD_CALL);
      }

      if (formData.mode === 'text' && formData.text) {
        data.record_call_file = await uploadTTS(formData.text, AUDIO_TYPE.RECORD_CALL);
      } else {
        data.record_call_file = formData.file;
      }
    }
    data.features = features;

    onUpdate(data);
  };

  return (
    <Card className="!p-0">
      <div className="flex items-center px-4 py-2">
        <Switch
          checked={enabled}
          value={formData?.enabled || false}
          onChange={(_, checked) => onEnable(checked)}
        />
        <WithTooltipIcon
          className="font-semibold"
          tooltip="This feature allows you to record your calls. To legally record a conversation, you need to make the person on the other and end aware of it. This feature allows you to add text or upload a audio file that will play in the beginning of the call to inform the receiver."
          placement="right"
        >
          Record your calls
        </WithTooltipIcon>

        <CheckBox
          color="blue-light"
          className="text-sm text-gray ml-auto"
          value={formData?.recordCallsOnAnswered || false}
          disabled={!enabled}
          onChange={(checked) => onFieldChange('recordCallsOnAnswered', checked)}
        >
          Only record from answered
        </CheckBox>

      </div>
      <TextFileInput
        form={form}
        audioType={AUDIO_TYPE.RECORD_CALL}
        disabled={!enabled}
        onUploadFile={onUploadFile}
        hidetts={true}
        initialForm={initialForm}
        setTouched={setTouched}
      />
    </Card>
  );
};

export default RecordCalls;
