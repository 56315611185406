import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {Switch} from "@mui/material";
import {Button, Card, WithTooltipIcon} from "../../../components";
import {EditIcon, PlusCircleIcon} from "../../../components/icons";
import {BillingServiceForm, CreditCardDetail, IBillingServiceFormData, ICreditCardFormData} from "../../../parts";
import {CREDIT_CARD, ROUTES} from "../../../constants";

const BillingInfo = () => {
  const history = useHistory();
  const [autoRecharge, setAutoRecharge] = useState(false);
  const [serviceForm, setServiceForm] = useState<IBillingServiceFormData>({});
  const [cardForm, setCardForm] = useState<ICreditCardFormData>({
    cardType: CREDIT_CARD.MASTER_CARD,
    cardNumber: '1234123412341234',
    csv: '865',
    expiryDate: '10/2023',
  });

  const onCardEdit = () => {
    history.push(ROUTES.SETTINGS.BILLING.CARD);
  };

  return (
    <div className="px-9 py-6">
      <Card
        title="Billing Information"
        headerClass="text-blue-dark text-lg font-semibold !border-0 !pb-0 !mb-2"
      >
        <div className="text-sm">Current Balance</div>
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center mr-4">
            <span className="text-3xl text-primary font-semibold">R2315.98</span>
            <Link to={ROUTES.SETTINGS.BILLING.ADD_FUNDS}>
              <Button
                className="text-blue text-sm ml-10"
                leftIcon={<PlusCircleIcon size={24} />}
              >
                Add Funds
              </Button>
            </Link>
          </div>

          <div className="flex items-center">
            <WithTooltipIcon tooltip="Enable Auto Recharging" placement="right">Enable Auto Recharging</WithTooltipIcon>
            <Switch className="ml-16" checked={autoRecharge} onChange={(_, checked) => setAutoRecharge(checked)} />
            {autoRecharge && (
              <Link
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                to={ROUTES.SETTINGS.BILLING.AUTO_RECHARGING}
              >
                <EditIcon color="blue" size={22} />
              </Link>
            )}
          </div>
        </div>
      </Card>

      <Card
        title="Card Details"
        headerClass="text-blue-dark text-lg font-semibold !border-0 !pb-0 !mb-2"
      >
        {cardForm ? (
          <CreditCardDetail data={cardForm} editable removable onEdit={onCardEdit} onRemove={() => setCardForm(undefined)} />
        ) : (
          <Link to={ROUTES.SETTINGS.BILLING.CARD}>
            <Button
              className="text-blue text-sm"
              leftIcon={<PlusCircleIcon size={24} />}
            >
              Add Card Details
            </Button>
          </Link>
        )}
      </Card>

      <Card
        title="Service Details and Address"
        headerClass="text-blue-dark text-lg font-semibold !border-0 !pb-0 !mb-2"
      >
        <BillingServiceForm data={serviceForm} />
      </Card>
    </div>
  );
};

export default BillingInfo;
