import React, {useMemo, useState} from "react";
import moment from "moment";
import classnames from "classnames";
import {DataTable, IDataTableColumn} from "../../../components";
import {DownloadIcon, EmailIcon, EyeIcon} from "../../../components/icons";
import {InvoiceModel} from "../../../utils/types";
import {INVOICE_STATUS} from "../../../utils/enums";
import {DateRangeFilter, IDateRange} from "../../../parts";
import {FilterModel} from "../../../utils/types";

const data: InvoiceModel[] = new Array(55).fill(0).map((_, i) => ({
  id: (i + 1).toString(),
  invoiceNo: 'INV_34567890',
  description: 'Payment deducted from running Balance',
  amount: 'R355,87',
  status: i === 2 ? INVOICE_STATUS.REFUNDED : INVOICE_STATUS.PAID,
  createdAt: '2021-05-19 13:12:50',
}));

const Invoices = () => {
  const [filter, setFiler] = useState<FilterModel>({
    category: 'range',
    text: 'The last 30 days',
    value: {
      startDate: moment().subtract(30, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
  });

  const columns = useMemo<IDataTableColumn<InvoiceModel>[]>(() => [
    { title: 'Date', field: 'createdAt' },
    { title: 'Invoice No', field: 'invoiceNo' },
    { title: 'Description', field: 'description' },
    { title: 'Amount', field: 'amount', cellClass: '!font-medium' },
    {
      title: 'CallStatus',
      field: 'status',
      render(row) {
        return (
          <span className={classnames(
            'font-medium capitalize',
            { 'text-success': row.status === INVOICE_STATUS.PAID },
            { 'text-gray': row.status === INVOICE_STATUS.REFUNDED },
          )}>
            {row.status}
          </span>
        );
      },
    },
    {
      render(row) {
        return (
          <div className="flex items-center">
            <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-auto cursor-pointer">
              <EyeIcon color="blue" size={20} />
            </div>
            <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-2 cursor-pointer">
              <DownloadIcon color="blue" size={20} />
            </div>
            <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-2 cursor-pointer">
              <EmailIcon color="blue" size={18} />
            </div>
          </div>
        );
      },
    },
  ], []);

  return (
    <>
      <div className="relative h-full flex flex-col px-7 pt-4">
        <div className="flex items-center">
          <span className="text-sm mr-2 mb-2">Filter data:</span>
          <DateRangeFilter value={filter} onChange={setFiler} />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-2"
          columns={columns}
          data={data}
          title="Invoices"
          titleClass="text-blue-dark text-lg font-semibold px-5 py-2"
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!bg-blue-semidark !text-white !text-sm !px-5"
          cellClass="!text-xs !px-5"
          pagination
          rowsPerPage={50}
        />
      </div>
    </>
  );
};

export default Invoices;
