import {formatDateTime} from "../helpers";

export interface IAuthCredentials {
  api_secret: string;
  measurement_id: string;
}

export class GA4Model {
  name: string;
  auth_credentials: IAuthCredentials;
  third_party: string;
  is_active: boolean;
  event: string;
  created: string;
  disabled: boolean;

  constructor(init: any = {}) {
    const data = {
      name: '',
      auth_credentials: {
        api_secret: '',
        measurement_id: ''
      },
      third_party: '',
      is_active: true,
      event: '',
      created: '',
      ...init,
    };

    this.name = data.name;
    this.auth_credentials = data.auth_credentials;
    this.third_party = data.third_party;
    this.event = data.event;
    this.is_active = data.is_active;
    this.created = formatDateTime(data.created);
    this.disabled = data.disabled;
  }
}
