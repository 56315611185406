import React, { FC } from "react";
import { Button, Modal } from "../../components";
import { SpamAlreadyBlocked } from "../../components/svgs/SpamAlreadyBlocked";

interface IBlockNumberModalProps {
  onClose: () => void;
}

export const ExistingBlockNumberModal: FC<IBlockNumberModalProps> = ({
  onClose,
}) => {

  return (
    <Modal
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onClose}
          >
            Ok
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <div className="flex-col text-center p-4">
        <h1 className="text-5xl font-bold text-blue m-8">
          Whoops!
        </h1>
        <p className="m-2">
          You have <span className="font-bold">already marked</span> this number as spam, don't worry!
        </p>
        <p className="text-blue font-bold">
          We have blocked it
        </p>
        <SpamAlreadyBlocked size={283} className="mt-12" />
      </div>
    </Modal>
  );
};
