import React, {FC} from "react";
import {Modal} from "../../components";
import {FullAudioPlayer} from "../FullAudioPlayer";

interface IFullAudioPlayerModalProps {
  title?: string;
  file?: File;
  src: string;
  onClose: () => void;
}

export const FullAudioPlayerModal: FC<IFullAudioPlayerModalProps> = ({
  title = 'Play audio file',
  file,
  src,
  onClose,
}) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
    >
      <FullAudioPlayer src={src} file={file} />
    </Modal>
  );
};
