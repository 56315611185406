import React, {useState} from "react";
import {useHistory} from "react-router";
import {Button, Card} from "../../../../components";
import {CloseCircleIcon} from "../../../../components/icons";
import {ToastService} from "../../../../services";
import {ROUTES} from "../../../../constants";
import {CreditCardForm, ICreditCardFormInitParams} from "../../../../parts";

const CardDetails = () => {
  const history = useHistory();
  const [cardForm, setCardForm] = useState<ICreditCardFormInitParams>({
    getFormData: () => null,
  });

  const onClose = () => {
    history.replace(ROUTES.SETTINGS.BILLING.INDEX);
  };

  const onCreate = () => {
    const data = cardForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
      onClose();
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Add Card Details</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="h-0 flex-grow overflow-auto p-6">
        <Card
          title="Card Details"
          headerClass="text-blue-dark text-lg font-semibold !border-0 !pb-0"
        >
          <CreditCardForm onInit={setCardForm} />
        </Card>
      </div>
      <div className="flex items-center px-7 py-3">
        <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
        <Button
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          onClick={onCreate}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CardDetails;
