import {formatDuration} from "../helpers";
import {CALL_CONTEXT, CALL_DIRECTION} from "../enums";

export class CallRecordModel {
  uuid: string;
  number: string;
  target_number: string;
  from_number: string;
  call_start_stamp: string;
  call_end_stamp: string;
  call_duration: number;
  voice_recording_url: string;
  status: string;
  meta: {
    [key: string]: string;
  };
  context: CALL_CONTEXT;
  call_direction: CALL_DIRECTION;
  currency: string;
  amount: number;
  recognized_keywords: string;
  digits_captured: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      number: '',
      target_number: '',
      from_number: '',
      call_start_stamp: '',
      call_end_stamp: '',
      call_duration: 0,
      voice_recording_url: '',
      status: '',
      meta: {},
      context: null,
      call_direction: '',
      currency: '',
      amount: 0,
      recognized_keywords: '',
      digits_captured: '',
      ...init,
    };

    this.uuid = data.uuid;
    this.number = data.number;
    this.target_number = data.target_number;
    this.from_number = data.from_number;
    this.call_start_stamp = data.call_start_stamp;
    this.call_end_stamp = data.call_end_stamp;
    this.call_duration = data.call_duration;
    this.voice_recording_url = data.voice_recording_url;
    this.status = data.status;
    this.meta = data.meta;
    this.context = data.context;
    this.call_direction = data.call_direction;
    this.currency = data.currency;
    this.amount = data.amount;
    this.recognized_keywords = data.recognized_keywords;
    this.digits_captured = data.digits_captured;
  }

  formatAmount(): string {
    if (!this.currency) {
      return '';
    }

    return `${this.currency} ${(this.amount || 0).toFixed(2)}`;
  }

  formatDuration(): string {
    return formatDuration(this.call_duration, this.call_duration < 3600 ? 'mm:ss' : 'h:mm:ss');
  }

  getRingTime(): number {
    const duration = Math.floor((new Date(this.call_end_stamp).getTime() - new Date(this.call_start_stamp).getTime()) / 1000);
    return Math.max(0, duration - this.call_duration);
  }

  formatRingTime(): string {
    const ringTime = this.getRingTime();
    return formatDuration(ringTime, ringTime < 3600 ? 'mm:ss' : 'h:mm:ss');
  }

  getOthersNumber(): string {
    return this.call_direction === CALL_DIRECTION.INBOUND ? this.from_number : this.target_number;
  }
}
