import {HttpService} from "./http.service";
import {
  IBuyNumberResponse,
  ICallTrackRequest,
  ICallTrackResponse,
  IGetAvailableLocationsRequest,
} from "../utils/dto";
import {formatPaginatedResult, trimObject} from "../utils/helpers";
import {ILocationInfo, INumberSummary} from "../utils/interfaces";
import {NumberModel} from "../utils/types";

export class NumberService {

  static search(query = {}, showSpinner = true) {
    return HttpService.get('/number/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(NumberModel, res));
  }

  static exportNumbers(query?: {}, showSpinner = true) {
    return HttpService.get('/number/export/', trimObject(query), {}, showSpinner);
  }

  static find(number: string, showSpinner = true) {
    return HttpService.get(`/number/${number}/`, {}, {}, showSpinner)
        .then((res) => new NumberModel(res));
  }

  static update(number: string, data, showSpinner = true) {
    return HttpService.put(`/number/${number}/`, data, {}, showSpinner)
        .then((res) => new NumberModel(res));
  }

  static delete(number: string, showSpinner = true) {
    return HttpService.delete(`/number/${number}/`, {}, {}, showSpinner);
  }

  static create(data, showSpinner = true): Promise<IBuyNumberResponse> {
    data = Object.fromEntries(Object.entries(data).filter(x => !!x[1]))
    return HttpService.post('/number/', data, {}, showSpinner);
  }

  static getValidLocations(query: IGetAvailableLocationsRequest, showSpinner = true): Promise<ILocationInfo[]> {
    return HttpService.get('/available-numbers/', query, {}, showSpinner)
      .then((res) => res.results);
  }

  static searchSimilarNumbers(query, showSpinner = true): Promise<INumberSummary[]> {
    return HttpService.post('/number/search/', query, {}, showSpinner);
  }
}
