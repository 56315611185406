import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOrganizations, setActiveOrganization } from "../redux/actions";
import { UserService, AuthService, CommonService, ToastService } from "../services";


export const Preloader = ({
  children,
}) => {
  const dispatch = useDispatch();

  const loadProfile = cb => {
    UserService.getProfile(false).then(user => {
      sessionStorage.setItem("user", JSON.stringify(user))
      dispatch(setActiveOrganization(user.default_org));
      cb()
    }).catch(err => {
      ToastService.showHttpError(err, 'Loading profile failed');
    });
  }

  const loadAllOrgs = cb => {
    AuthService.getMyOrganizations(false).then(data => {
      dispatch(setOrganizations(data));
      cb()
    }).catch(err => {
      dispatch(setOrganizations([]));
      ToastService.showHttpError(err, 'Loading organizations failed');
    });
  }

  useEffect(() => {
    loadProfile(() => { });
    loadAllOrgs(() => { });
  }, []);

  return <>{children}</>;
};
