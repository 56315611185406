import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const CoinsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={26.302} baseHeight={26.302} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 26.302 26.302">
        <path
          fill={color}
          d="M0,20.821v2.194C0,24.828,4.418,26.3,9.863,26.3s9.863-1.474,9.863-3.288V20.821c-2.122,1.495-6,2.194-9.863,2.194S2.122,22.316,0,20.821ZM16.439,6.576c5.445,0,9.863-1.474,9.863-3.288S21.884,0,16.439,0,6.576,1.474,6.576,3.288,10.994,6.576,16.439,6.576ZM0,15.432v2.651c0,1.813,4.418,3.288,9.863,3.288s9.863-1.474,9.863-3.288V15.432c-2.122,1.747-6.005,2.651-9.863,2.651S2.122,17.179,0,15.432ZM21.371,16c2.944-.57,4.932-1.628,4.932-2.846V10.958a12.615,12.615,0,0,1-4.932,1.772ZM9.863,8.219C4.418,8.219,0,10.059,0,12.329s4.418,4.11,9.863,4.11,9.863-1.839,9.863-4.11S15.309,8.219,9.863,8.219Zm11.266,2.892c3.082-.555,5.173-1.644,5.173-2.892V6.026c-1.824,1.289-4.957,1.983-8.255,2.147A5.753,5.753,0,0,1,21.129,11.112Z"
        />
      </svg>
    )}
  </Icon>
);
