import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { AudioPlayer, Button, Input, Card } from "../../../../../components";
import { FemaleIcon, MaleIcon } from "../../../../../components/icons";
import { MenuItem } from "@mui/material";
import { Form, FormControl, useForm, Validators } from "../../../../../components/ReactiveForm";
import { CONFIG, LANGUAGES } from "../../../../../constants";
import { AudioService } from "../../../../../services";
import { AudioModel } from "../../../../../utils/types";
import { compareFormData } from "../../../../../utils/helpers/form.helper";

export interface ITextSpeechFormProps {
  className?: string;
  form: any;
  previewUrl: string;
  setPreviewUrl: (string) => void;
  setTouched?: Dispatch<SetStateAction<boolean>>;
}

export type ITextSpeechForm = {
  language: FormControl;
  content: FormControl;
}

const TextSpeechForm: FC<ITextSpeechFormProps> = ({
  className = '',
  form,
  previewUrl,
  setPreviewUrl,
  setTouched,
}) => {
  const [innerForm, innerFormData] = useForm<ITextSpeechForm>({
    language: new FormControl('', [Validators.required()]),
    content: new FormControl('', [
      Validators.required(),
      Validators.maxLength(160, false, "You have a limit of 160 characters"),
      Validators.pattern(/[\w\W]+\s+[\w*\W*\s*]+/, "Text", "TTS must be more than one word"),
    ]),
  });

  const onAddAudio = () => {
    if (!innerForm.validate()) {
      return;
    }

    AudioService.generateAudioFromText(innerFormData.content, innerFormData.language).then((file) => {
      const fileName = form.controls.name.value + '.mp3';
      const audioFile = new File([file], fileName, { lastModified: new Date().getTime(), type: file.type });
      form.patch({ file: audioFile })
      setPreviewUrl(URL.createObjectURL(audioFile));
    });
  };

  return (
    <Form className={className} formGroup={form}>
      <div>
        <Card contentClass="grid md:grid-cols-2 gap-x-8 gap-y-4">
          <Input
            type="select"
            control={innerForm.controls.language}
            fullWidth
            label="Message Language"
            labelClass="text-md font-semibold"
            inputClass="!text-sm !font-normal"
          >
            {LANGUAGES.map((option) => (
              <MenuItem
                key={option.code}
                className="!text-sm"
                value={option.code}
              >
                {option.name}
              </MenuItem>
            ))}
          </Input>

          <Input
            type="textarea"
            control={innerForm.controls.content}
            fullWidth
            label="Your message"
            labelClass="text-md font-semibold"
            inputClass="!bg-grey !text-sm"
            maxLength={CONFIG.MAX_SMS_LENGTH}
            minRows={4}
            placeholder="Type your cool message here that will be played to the caller"
            max={CONFIG.MAX_SMS_LENGTH}
          />
        </Card>
        <Button data-cy="add-audio-blue" color="blue" className="text-sm rounded-full shadow-md px-6 mt-3 ml-auto" onClick={onAddAudio}>Add</Button>
      </div>

      {previewUrl &&
        <div className="bg-[#cfd3d9] rounded px-6 pt-2 pb-4 mt-6">
          <div className="text-blue-dark font-semibold mb-2">Audio Preview</div>
          <AudioPlayer className="bg-gray-f5" src={previewUrl} />
        </div>
      }
    </Form>
  );
};

export default TextSpeechForm;
