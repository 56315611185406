import React, {FC, useEffect, useState} from "react";
import {INumberEditForm} from "../index";
import {FormGroup} from "../../../../components/ReactiveForm";
import {Button, Card, Chip, Chips} from "../../../../components";
import {PlusCircleIcon} from "../../../../components/icons";
import {AddWebhookModal} from "../../../../parts";
import {NumberModel} from "../../../../utils/types";

export interface IWebhooksProps {
  number: NumberModel;
  form: FormGroup<INumberEditForm>;
  formData: any;
  initialForm: any;
  onUpdate(data: any): void;
}

const Webhooks: FC<IWebhooksProps> = ({
  number,
  form,
  formData,
  initialForm,
  onUpdate,
}) => {
  const [showAddWebhookModal, setShowAddWebhookModal] = useState(false);

  useEffect(() => {
    form.patch({
      webhooks: number.webhooks || [],
    });
    initialForm.webhooks = number.webhooks || []
  }, [number]);

  const onSetWebhooks = (webhooks) => {
    form.controls.webhooks.patch(webhooks);
    onSave();
  };

  const onRemoveWebhook = (webhook) => {
    form.controls.webhooks.patch(formData.webhooks.filter((item) => webhook !== item));
    onSave();
  };

  const onSave = () => {
    if (!form.controls.webhooks.validate()) {
      return;
    }

    const formData = form.getFormData();

    onUpdate({
      webhooks: formData.webhooks,
    });
  };

  return (
    <>
      <Card
        size="sm"
        header={(
          <Button
            className="text-blue px-0"
            leftIcon={<PlusCircleIcon />}
            onClick={() => setShowAddWebhookModal(true)}
          >
            Add webhook
          </Button>
        )}
      >
        {!!formData.webhooks?.length && (
          <Chips className="mt-3">
            {formData.webhooks.map((item, i) => (
              <Chip key={i} size="sm" active clickable={false} removable onRemove={() => onRemoveWebhook(item)}>
                {item}
              </Chip>
            ))}
          </Chips>
        )}
      </Card>

      {showAddWebhookModal && (
        <AddWebhookModal
          webhooks={formData.webhooks}
          onSubmit={onSetWebhooks}
          onClose={() => setShowAddWebhookModal(false)}
        />
      )}
    </>
  );
};

export default Webhooks;
