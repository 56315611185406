import React, {FC, ReactElement, useEffect, useState} from "react";
import classnames from "classnames";

type CheckBoxColor = 'primary' | 'blue' | 'blue-light' | 'danger';

export interface ICheckBoxProps {
  className?: string;
  boxClassName?: string;
  value?: boolean;
  color?: CheckBoxColor;
  icon?: ReactElement;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const CheckBox: FC<ICheckBoxProps> = ({
  children,
  className = '',
  boxClassName = '',
  value = false,
  color = 'primary',
  icon,
  disabled,
  onChange = () => {},
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onToggle = () => {
    if (!disabled) {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  return (
    <div
      className={classnames(
        'flex-center',
        className,
        disabled ? 'opacity-60 cursor-default' : 'cursor-pointer',
      )}
      onClick={onToggle}
    >
      <div className={classnames(
        'w-4 h-4 text-white flex-grow-0 flex-shrink-0 flex-center rounded border transition-all',
        boxClassName,
        checked ? `bg-${color} border-${color}` : 'bg-white border-gray-d9',
        { 'mr-2': Boolean(children) }
      )}>
        {icon || <i className="fa fa-check text-9p" />}
      </div>
      { children }
    </div>
  );
};
