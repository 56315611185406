import React, { FC, useCallback, useEffect, useMemo } from 'react';
import libPhoneNumber, { PhoneNumberFormat } from 'google-libphonenumber';
import { ArrowLeftIcon } from "../../../../components/icons";
import { Button, Input, RadioGrouping, WithTooltipIcon } from "../../../../components";
import { Form, FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import { INumberCreateForm } from "../index";
import { getExamplePhoneNumber } from "../../../../utils/helpers";
import { getCountryCallingCode, CountryCode } from "libphonenumber-js";
import { NUMBER_MODE, NUMBER_FEATURE } from '../../../../utils/enums';

const phoneUtils = libPhoneNumber.PhoneNumberUtil.getInstance();

const radioButtons = [{ label: 'Track incoming calls', value: NUMBER_MODE.NORMAL }, { label: 'As a virtual number to use on platforms like WhatsApp Business', value: NUMBER_MODE.OTP }];

export interface INumberCreateStep3Props {
  formData: INumberCreateForm;
  otpMode: NUMBER_MODE;
  onOtp(res?: NUMBER_MODE): void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<INumberCreateForm>): void;
}

export const NumberCreateStep3: FC<INumberCreateStep3Props> = ({
  formData,
  otpMode,
  onOtp,
  onBack,
  onNext,
  onSubmit,
}) => {
  const is_otp = formData.features?.includes(NUMBER_FEATURE.VOICE_OTP)
  const [form] = useForm<{
    name: FormControl;
    target: FormControl;
    otpMode: FormControl;
  }>({
    name: new FormControl('', [Validators.required()]),
    otpMode: new FormControl((is_otp) ? NUMBER_MODE.NORMAL : NUMBER_MODE.OTP, [Validators.required()]),
    target: new FormControl('', (!is_otp) ? [Validators.required(), Validators.phone(formData.country.iso)] : []),
  });

  const exampleNumber = useMemo(() => {
    return getExamplePhoneNumber(formData.country.iso);
  }, [formData]);

  useEffect(() => {
    var target = formData.target;

    if ((target === '' || !target) && otpMode !== NUMBER_MODE.OTP) {
      target = `+${getCountryCallingCode(formData.country.iso as CountryCode)}`;
    }

    form.patch({
      name: formData.name,
      target: target,
    });
  }, [formData]);

  const onContinue = (data) => {
    let submitData = {
      ...data
    }
    if (data.target) {
      const phoneNumber = phoneUtils.parse(data.target);
      const regionCode = +phoneUtils.format(phoneNumber, PhoneNumberFormat.NATIONAL).substr(0, 3);
      submitData = {
        ...data,
        regionCode: regionCode.toString(),
      }
    }
    onSubmit(submitData);
    onNext();
  };

  const onPhoneInput = useCallback((key: string, value: string) => {
    const countryCode = getCountryCallingCode(formData.country.iso as CountryCode);
    if (/\d/.test(key)) {
      let newPhone = value.replace(/\D/g, '');

      if (newPhone.startsWith(countryCode) !== true) {
        newPhone = newPhone.replace(/0(\d{9})$/, `${countryCode}$1`);
        newPhone = `${countryCode}${newPhone}`;
      }

      form.controls.target.patch(`+${newPhone}`);
    } else if ((key === 'Backspace' || key === 'Delete') && value.length < `+${countryCode}`.length) {
      form.controls.target.patch(`+${countryCode}`);
    }
  }, [formData.country])

  const onRadioSelect = (value: NUMBER_MODE) => {
    onOtp(value);
    form.patch({ otpMode: value })
    if (formData.features?.includes(NUMBER_FEATURE.VOICE_OTP)) {
      form.setControl('target', new FormControl(''));
    } else {
      form.setControl('target', new FormControl('', [Validators.required(), Validators.phone(formData.country.iso)]));
    }
  }

  return (
    <Form className="h-full flex flex-col" formGroup={form} onSubmit={onContinue}>
      <div className="h-0 flex-grow overflow-auto">
        <div className="text-center">
          <h5 className="text-sm font-semibold mt-4">Number Setup</h5>
          <p className="text-blue text-4xl font-bold">Add details to your number</p>
        </div>
        <div data-cy="input-number-wrapper" className="max-w-132 mt-10 mx-auto">
          <Input
            control={form.controls.name}
            fullWidth
            label="Give your number a name"
            labelClass="font-semibold text-xs mb-2"
            inputClass="!text-sm"
            placeholder="Give your number a cool name to easily identify it in your reports"
          />
          <p className='font-semibold text-xs'>How will you use this number?</p>
          <RadioGrouping
            value={otpMode}
            onValueChange={(val: NUMBER_MODE) => onRadioSelect(val)}
            options={radioButtons}
            containerClass={'mb-8'}
            message={otpMode === NUMBER_MODE.OTP &&
              <WithTooltipIcon
                placement='bottom'
                tooltip={"Your number has been switched to OTP mode to allow your virtual number setup on a platform like WhatsApp Business."}>
                <p className='text-[10px]'><strong className='text-blue'>OTP MODE</strong> enabled on your number.</p>
              </WithTooltipIcon>}
          />
          {otpMode !== NUMBER_MODE.OTP &&
            <Input
              type="phone"
              control={form.controls.target}
              fullWidth
              label="Please add a target number"
              labelClass="font-semibold text-xs mb-2"
              inputClass="!text-sm"
              className="mt-6"
              description="Enter the number to forward calls to for the country you choose, starting with the relevant country code."
              placeholder={`E.g. ${exampleNumber}`}
              onKeyUp={(e) => onPhoneInput(e.key, e.target.value)}
            />
          }
        </div>
      </div>

      <div className="flex mt-10 mx-4">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button data-cy="create-number-step-3" type="submit" color="primary" className="text-sm rounded-full shadow-md px-6 ml-2">
          Continue
        </Button>
      </div>
    </Form>
  );
};
