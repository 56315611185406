import { Radio } from "@mui/material";
import { default as classNames, default as classnames } from "classnames";
import _ from "lodash";
import React, { FC } from "react";
import { Country } from "react-phone-number-input";
import { Button, CountrySelect } from "../../../../components";
import { ArrowLeftIcon } from "../../../../components/icons";
import { CommonService, ToastService } from "../../../../services";
import { INumberCreateForm } from "../index";

export interface INumberCreateStep1Props {
  formData: INumberCreateForm;
  setTouched?: any;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<INumberCreateForm>): void;
}

const types = [
  { title: `Mobile`, value: 'MOBILE', description: 'Use a mobile number if you want your clients to know they can text you, or if plan to text your clients.' },
  { title: `Landline`, value: 'LOCAL', description: 'This is useful if you want to give the impression that you are an established business.' },
  { title: `National`, value: 'NATIONAL', description: 'This is useful if you would like to be reachable from the entire country at the same price.' },
];

export const NumberCreateStep1: FC<INumberCreateStep1Props> = ({
  formData, setTouched, onBack, onNext, onSubmit,
}) => {
  const [countries, setCountries] = React.useState<any[]>([]);
  const [country, setCountry] = React.useState<{ iso: Country, code: string }>({ iso: null, code: null });
  const [type, setType] = React.useState('');
  const [validate, setValidate] = React.useState<boolean>(false);

  setTouched(!_.isEqual(country, formData.country))

  const selectedCountryInfo = React.useMemo(() => {
    const selectedCountry = countries.find(c => c.iso_code === country?.iso);
    if (selectedCountry?.did_types.length === 1) {
      setType(selectedCountry?.did_types[0]);
    }
    return selectedCountry;
  }, [country, countries]);

  React.useEffect(() => {
    setCountry(formData.country);
    setType(formData.type);
  }, [formData]);

  // Set default
  React.useEffect(() => {
    CommonService.getCountries().then(data => {
      sessionStorage.setItem("countries", JSON.stringify(data));
      setCountries(data);
      setCountry({ iso: "ZA", code: "27" });
      setType(formData.type);
    }).catch(err => {
      ToastService.showHttpError(err, 'Loading countries failed');
    })
  }, []);

  const onContinue = () => {
    setValidate(true);
    if (!country || !type) {
      return;
    }

    const savingData = {
      country,
      type,
    };
    onSubmit(savingData);
    onNext();
  };

  return (
    <div className="h-full flex flex-col">
      <div className="h-0 flex-grow text-center overflow-auto">
        <h5 className="text-sm font-semibold mt-4">Number Setup</h5>
        <p className="text-blue text-4xl font-bold">Choose a country and number type</p>

        <div className="max-w-140 mx-auto">
          <CountrySelect
            containerClass="mt-10"
            label="Which country should your number be from?"
            labelClass="font-semibold text-xs mb-3"
            fullWidth
            placeholder="Choose your country"
            value={country}
            onChange={setCountry}
          />

          <div className={classnames(
            'text-left mt-8',
            { 'opacity-0': !country },
            { 'text-danger': !type && validate },
          )}>
            <div className="font-semibold text-xs mb-3">What kind of number would you like?</div>
            <div className="grid md:grid-cols-3 gap-3">
              {types.map((item, i) => (
                selectedCountryInfo?.did_types.includes(item.value) && (
                  <div
                    key={i}
                    data-cy="phone-type"
                    className={classnames(
                      'flex flex-row border-1.5 rounded p-3 cursor-pointer',
                      type === item.value ? 'text-body border-blue' : 'border-grey-d6 text-grey-d6',
                      { '!border-danger': !type && validate },
                    )}
                    onClick={() => setType(item.value)}
                  >
                    <div className="align-top">
                      <Radio
                        checked={type === item.value}
                        color={"primary"}
                        className={classNames("!p-0", { '!bg-danger': !type && validate })}
                      />
                    </div>
                    <div className="flex-shrink ml-2">
                      <div className={classnames('text-base font-semibold leading-tight mt-1 mb-2', type !== item.value ? 'text-gray-9a' : 'text-grey-75')}>
                        {item.title}
                      </div>
                      <div className={classnames('text-xs leading-tight', type !== item.value && 'text-gray-ba')}>
                        {item.description}
                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-4 mx-4">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button data-cy="create-number-step-1" color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
};
