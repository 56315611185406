import moment from "moment";
import { IPaginatedResult } from "../interfaces";
import { PhoneNumberFormat } from "google-libphonenumber";
import libPhoneNumber from "google-libphonenumber";
import { fround } from "./common.helpers";

const phoneUtils = libPhoneNumber.PhoneNumberUtil.getInstance();

export const formatFileSize = (size: number): string => {
  const units = ['', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  do {
    size /= 1024;
    i++;
  } while (size >= 1024 && i < units.length - 1);

  return `${fround(size)}${units[i]}`;
};

export const formatPaginatedResult = (Model, data): IPaginatedResult<typeof Model> => {
  return {
    ...data,
    results: data.results.map((item) => new Model(item)),
  };
};

export const getExamplePhoneNumber = (region: string, format = PhoneNumberFormat.INTERNATIONAL) => {
  try {
    const phoneNumber = phoneUtils.getExampleNumber(region);
    return phoneUtils.format(phoneNumber, format);
  } catch {
    return '+27 71 236 4876';
  }
};

export const getCountryCodeFromNumber = (number: string) => {
  try {
    const phoneNumber = phoneUtils.parse(`+${number}`);
    return phoneUtils.getRegionCodeForNumber(phoneNumber);
  } catch {
    return undefined;
  }
};

export const getCountryCodeFromNumberToLowerCase = (number: string) => {
  try {
    const phoneNumber = phoneUtils.parse(`+${number}`);
    return phoneUtils.getRegionCodeForNumber(phoneNumber).toLowerCase();
  } catch {
    return undefined;
  }
};

export const formatPhoneNumber = (phone: string, format = PhoneNumberFormat.INTERNATIONAL) => {
  if (!phone) {
    return '';
  }
  try {
    const phoneNumber = phoneUtils.parse(`+${phone}`);
    return phoneUtils.format(phoneNumber, format);
  } catch {
    return phone;
  }
};

export const formatDateTime = (datetime: string | Date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!datetime) {
    return '';
  }
  return moment(datetime).format(format);
};

export const capitalize = (str: string) => {
  if (!str) {
    return '';
  }
  return `${str[0].toUpperCase()}${str.substr(1)}`;
};
