import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ChatLogsFilter from './ChatLogsFilter';
import ChatLogsTable from './ChatLogsTable';
import { useQuery } from '../../../hooks/useQuery';
import { ChatLogFilterModel, FilterModel, WAChatIntroLogModel } from '../../../utils/types';
import { BlockedNumberService } from '../../../services/blocked-number.service';
import { BlockNumberModal } from '../../../parts/BlockNumberModal';
import { ExistingBlockNumberModal } from '../../../parts/ExistingBlockNumberModal';
import { ToastService } from '../../../services/toast.service';
import { SelfHelpButton } from '../../../components';

const getFiltersFromQuery = (queryParams): ChatLogFilterModel[] => {
  const filters_from_params: any = Array.from(queryParams).map(([k, v]) => {
    try {
      return ChatLogFilterModel.parse(k, queryParams.get(k))
    } catch (e: unknown) {
      console.warn(`Parsing filter has failed. ${e}`)
      return null
    }
  })

  return filters_from_params.filter(x => x instanceof ChatLogFilterModel)
}

const add_default_range = (filters) => {
  return [
    new FilterModel({
      category: 'range',
      text: 'Last 7 Days',
      value: {
        startDate: moment().subtract(7, 'days').startOf('day').format(),
        endDate: moment().endOf('day').format(),
      },
    }),
    ...filters
  ]
}


const ChatLogs = () => {
  let { queryParams, setQueryParams } = useQuery();
  const [blockingSender, setBlockingSender] = useState<string>();
  const [showBlockedModal, setShowBlockedModal] = useState<boolean>();

  const filters_from_query = getFiltersFromQuery(queryParams)
  const [filters, setFilters] = useState<FilterModel[]>(
    (FilterModel.getCategory(filters_from_query, "range"))
      ? FilterModel.moveRangeToHead(filters_from_query)
      : add_default_range(filters_from_query)
  );

  useEffect(() => {
    setQueryParams(filters.map(x => ({ name: x.category, value: x.toString() })))
  }, [filters]);

  const onUnBlockSender = (call: WAChatIntroLogModel) => {
    BlockedNumberService.search({ number: call.lead_wa_number }).then(res => {
      if (res.results.length === 0) {
        ToastService.success(<>{call.lead_wa_number} has already been unblocked.</>);
        return;
      }

      res.results[0].delete().then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> unblocked number.</>);
      })
    })
  }

  const onBlockSender = (call: WAChatIntroLogModel) => {
    BlockedNumberService.search({ number: call.lead_wa_number }).then(res => {
      if (res.results.length > 0) {
        setShowBlockedModal(true);
      } else {
        setBlockingSender(call.lead_wa_number);
      }
    })
  };

  return (
    <>
      <div className="h-full flex flex-col px-4 pt-4">
        <div className='flex flex-row'>
          <ChatLogsFilter filters={filters} onChange={setFilters} />
          <SelfHelpButton className='ml-auto mb-2' url={'/docs/chat-intros-logs'} />
        </div>
        <ChatLogsTable
          className="h-0 flex-1 mt-3"
          filters={filters}
          onBlockSender={onBlockSender}
          onUnBlockSender={onUnBlockSender} />
      </div>

      {blockingSender && (
        <BlockNumberModal
          title="Mark sender as spam"
          defaults={{ number: blockingSender, channels: ["WHATSAPP"] }}
          onClose={() => setBlockingSender(undefined)}
        />
      )}

      {showBlockedModal && (
        <ExistingBlockNumberModal
          onClose={() => setShowBlockedModal(false)}
        />
      )}
    </>
  );
};

export default ChatLogs;
