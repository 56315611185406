import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import { MenuItem } from "../../../../constants";
import { PinIcon } from "../../../../components/icons";

interface IMenuPanelProps {
  sidebarCollapsed: boolean;
  menu: MenuItem;
  onTogglePin(item: MenuItem): void;
  onToggleSidebar(): void;
}

interface SubMenuItem extends MenuItem {
  active?: boolean;
  hasActiveChild?: boolean;
  subMenus?: SubMenuItem[];
}

const MenuPanel: FC<IMenuPanelProps> = ({
  sidebarCollapsed, menu, onTogglePin, onToggleSidebar,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [menus, setMenus] = useState<SubMenuItem[]>([]);
  const [openedMenu, setOpenedMenu] = useState<SubMenuItem>();

  useEffect(() => {
    const subMenus = (menu.subMenus as MenuItem[]).map((sm) => {
      const ssMenus = (sm.subMenus || []).map((ssm) => ({
        ...ssm,
        active: !!ssm.link && location.pathname.startsWith(ssm.link),
      }));
      const subMenu = {
        ...sm,
        ...(sm.subMenus?.length > 0 ? { link: ssMenus.find((ssm) => ssm.active)?.link || sm.subMenus[0].link } : {}),
        subMenus: ssMenus,
        active: !!sm.link && location.pathname.startsWith(sm.link),
        hasActiveChild: ssMenus.some((ssm) => ssm.active),
      };
      if (subMenu.hasActiveChild) {
        setOpenedMenu(subMenu);
      }
      return subMenu;
    });
    setMenus(subMenus);
  }, [location, menu]);

  const onMenuClick = (menu: MenuItem) => {

    if (menu.callback) {
      menu.callback();
    }
    else if (menu.link) {
      history.push(menu.link);
      if (!sidebarCollapsed) {
        onToggleSidebar();
      }
    } else {
      setOpenedMenu(menu === openedMenu ? undefined : menu);
    }
  };

  const onPinMenuItem = (e: any, item: MenuItem) => {
    e.preventDefault();
    e.stopPropagation();

    const subMenu = (menu.subMenus as MenuItem[]).find((m) => m.text === item.text) as MenuItem;
    onTogglePin(subMenu);
  };

  return (
    <div className={classnames(
      'menu-panel bg-white py-5 overflow-x-hidden transition-all',
      sidebarCollapsed ? 'w-0 lg:w-44 lg:pl-3' : 'w-44 pl-3',
    )}>
      <div className="text-primary text-lg font-bold uppercase">{menu.text}</div>
      <div className="mt-5">
        {menus.map((item, index) => {
          const submenuId = `submenu-${item.text.replace(/\W/g, '-').toLowerCase()}`;
          const hasSubMenu = !!item.subMenus?.length;
          return (
            <div key={`sm-${index}`}>
              <div
                className={classnames(
                  'min-h-11 max-w-40 flex items-center text-sm px-3 py-2 transition-all cursor-pointer',
                  'hover:text-primary hover:font-bold',
                  item.active || item.hasActiveChild
                    ? 'border-l-2 border-primary text-primary font-bold'
                    : 'border-l border-gray-e text-gray-be',
                  { 'bg-primary bg-opacity-24': item === openedMenu },
                )}
                id={submenuId}
                onClick={() => onMenuClick(item)}
              >
                <span className="mr-2">{item.text}</span>

                {item.pin && (item.pinned || item.active) && (
                  <div
                    className={classnames(
                      'w-6.5 h-6.5 flex-center flex-shrink-0 ml-auto bg-opacity-80 rounded-md cursor-pointer transition-all',
                      item.pinned ? 'bg-primary-light' : 'bg-gray-d6',
                    )}
                    onClick={(e) => onPinMenuItem(e, item)}
                  >
                    <PinIcon color={item.pinned ? 'primary' : '#f4f4f4'} />
                  </div>
                )}

                {hasSubMenu && (
                  <span className={classnames(
                    'text-2xl transform font-normal transition-all ml-auto',
                    item === openedMenu ? '-rotate-90' : 'rotate-90',
                  )}>›</span>
                )}
              </div>

              {(item === openedMenu) && (item.subMenus || []).map((subItem, subIndex) => {
                const ssmId = `ssm-${subItem.text.replace(/\W/g, '-').toLowerCase()}`;
                return (
                  <div
                    key={`ssm-${index}-${subIndex}`}
                    id={ssmId}
                    className={classnames(
                      'flex items-center text-xs px-3 py-2 transition-all cursor-pointer',
                      'border-l border-gray-e hover:text-primary hover:font-bold',
                      subItem.active ? 'text-primary font-bold bg-primary bg-opacity-12' : 'text-gray-be',
                    )}
                    onClick={() => onMenuClick(subItem)}
                  >
                    {subItem.text}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuPanel;
