import React, {FC, ReactNode, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {Button, Chip, Chips, Dropdown, TextField} from "../../../components";
import {CloseCircleIcon, SearchIcon} from "../../../components/icons";
import {FilterModel} from "../../../utils/types";

export interface IMultiSelectOption {
  value: string;
  text: ReactNode;
  plainText?: string;
}

interface IMultiSelectFilterProps {
  title?: string;
  category?: string;
  opened?: boolean;
  value: FilterModel;
  options: IMultiSelectOption[];
  dropdownClass?: string;
  onChange?: (value: FilterModel) => void;
  onDelete?: (value: FilterModel) => void;
}

export const MultiSelectFilter: FC<IMultiSelectFilterProps> = ({
  title = 'Status',
  category = 'status',
  opened,
  value,
  options,
  dropdownClass,
  onChange = () => {},
  onDelete = () => {},
}) => {
  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState<string[]>([]);
  const [dropdownOpened, setDropdownOpened] = useState(opened);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  useEffect(() => {
    setSearch('');
  }, [dropdownOpened]);

  useEffect(() => {
    setSelection(value.value || []);
  }, [value]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }
    const keyword = search.trim().toLowerCase();
    return options.filter((item) => {
      let text = item.plainText;
      if (!text && typeof item.text === 'string') {
        text = item.text;
      }
      return text?.toLowerCase()?.includes(keyword)
    });
  }, [options, search]);

  const onToggleSelect = (option: string) => {
    if (selection.includes(option)) {
      setSelection(selection.filter((item) => item !== option));
    } else {
      setSelection([...selection, option]);
    }
  };

  const onSave = () => {
    if (!selection.length)
      return;

    const option = options.find((item) => item.value === selection[0]);
    let optionText = option.plainText;
    if (!optionText && typeof option.text === 'string') {
      optionText = option.text;
    }

    onChange({
      category,
      text: `${title}: ${optionText}${selection.length > 1 ? ` & ${selection.length - 1} more` : ''}`,
      value: selection,
    });
    setDropdownOpened(false);
  };

  return (
    <div className="flex items-center mb-2">
      <Dropdown
        text={value?.text || title}
        buttonProps={{
          className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4 shadow-md',
          color: 'primary',
        }}
        dropdownClass={classnames('w-80 max-h-80 rounded-bl-md rounded-br-md flex flex-col', dropdownClass)}
        dropdownOpened={dropdownOpened}
        onDropdownOpened={() => setDropdownOpened(true)}
        onDropdownClosed={() => setDropdownOpened(false)}
      >
        <div className="p-2 overflow-y-auto shadow">
          <TextField
            size="sm"
            fullWidth
            inputClass="placeholder:text-xs"
            className="!rounded-full mb-2"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder={`Filter by ${title}`}
            onChange={(value) => setSearch(value)}
          />

          <Chips>
            {filteredOptions.map((item, i) => (
              <Chip
                key={i}
                active={selection.includes(item.value)}
                onClick={() => onToggleSelect(item.value)}
              >
                {item.text}
              </Chip>
            ))}
          </Chips>
        </div>

        <div className="px-3 py-2">
          <Button
            color="primary"
            className="shadow-md rounded-full text-sm px-6 ml-auto"
            disabled={!selection}
            onClick={onSave}
          >
            Filter
          </Button>
        </div>
      </Dropdown>

      <CloseCircleIcon
        className="ml-1 cursor-pointer"
        color="#DEDEDE"
        size={24}
        onClick={() => onDelete(value)}
      />
    </div>
  );
};
