import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "../../../components";
import { Form, FormControl, Input, Validators, useForm } from "../../../components/ReactiveForm";
import { LogoIcon, LogoTextIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import AuthLayout from "../../../layouts/AuthLayout";
import { AuthService, MessageBoxService, ToastService } from "../../../services";

const Login = () => {
  const [form] = useForm<{
    email: FormControl;
    password: FormControl;
  }>({
    email: new FormControl('', [Validators.required(), Validators.email()]),
    password: new FormControl('', [Validators.required()]),
  });

  const onLogin = (formData) => {
    AuthService.login(formData.email, formData.password).then(() => {
      window.location.href = ROUTES.DASHBOARD;
    }).catch((err) => {
      ToastService.error('Login failed');
    });
  };

  const onForgotPassword = () => {
    MessageBoxService.confirm({
      type: 'email',
      title: 'Reset Your Password',
      message: <b>Please enter your email address</b>,
      confirmMessage: 'A link will be sent via email for you to reset your password',
      confirmButtonText: 'Send Email',
    }).then((email) => {
      if (email) {
        AuthService.reset(email).then(() => {
          ToastService.success('A link will be sent via email for you to reset your password. Please check your inbox.');
        }).catch((err) => {
          ToastService.showHttpError(err, 'Login failed');
        });
      }
    });
  };

  return (
    <AuthLayout contentClass="w-100">
      <Form formGroup={form} onSubmit={onLogin}>
        <div className="flex items-center text-blue-dark">
          <LogoIcon size={24} />
          <LogoTextIcon className="ml-1" size={100} />
        </div>

        <h1 className="text-40p text-primary font-bold mt-4">Log In</h1>

        <div className="mt-8">
          <Input
            control={form.controls.email}
            placeholder="Enter your email address"
            fullWidth
            lpIgnore={false}
          />
          <Input
            control={form.controls.password}
            type="password"
            helperTextClass="absolute bottom-[-20px]"
            containerClass="mt-4 !pb-1"
            placeholder="Password"
            fullWidth
            lpIgnore={false}
          />
        </div>

        <div className="text-right">
          <span
            data-cy="open-forgot-password-modal"
            className="text-primary text-xs font-bold hover:underline cursor-pointer"
            onClick={onForgotPassword}
          >
            Forgot Password?
          </span>
        </div>

        <Button type="submit" color="primary" className="w-full rounded-full shadow-md mt-8">Log In</Button>

        {/* <div className="text-sm text-center mt-12">
          Don't have an account yet?
          <Link className="text-primary font-semibold hover:underline ml-1" to={ROUTES.AUTH.REGISTER}>Create an account</Link>
        </div> */}
      </Form>
    </AuthLayout>
  );
};

export default Login;
