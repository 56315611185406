import React, {FC, useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import {Accordion, AccordionDetails, AccordionSummary, Radio, RadioGroup, Switch} from "@mui/material";
import {INumberAdvancedSettingsForm} from "../index";
import {NumberModel} from "../../../../utils/types";
import {FormGroup} from "../../../../components/ReactiveForm";
import {AngleDownIcon} from "../../../../components/icons";
import {TextFileInput} from "../../../../parts";
import {AUDIO_TYPE, NUMBER_FEATURE} from "../../../../utils/enums";
import {AudioService} from "../../../../services";
import {uploadTTS} from "../../../../utils/helpers";

export interface IAdvancedSettingsProps {
  number: NumberModel;
  form: FormGroup<INumberAdvancedSettingsForm>;
  formData: any;
  initialForm?: any;
  onUpdate(data: any): void;
}

const forwardSmsTypes = [
  { text: 'First 160 Characters', value: 'default', description: `If your message has more than 160 characters, only the first 160 will be forwarded to you.` },
  { text: 'Everything', value: NUMBER_FEATURE.SPAN_MULTIPLE_SMS, description: `If you received a message with more than 160 characters, everything will be forwarded to you that could result in more costs.` },
];

const AdvancedSettings: FC<IAdvancedSettingsProps> = ({
  number,
  form,
  formData,
  initialForm,
  onUpdate,
}) => {
  const [settings, setSettings] = useState<any>({});

  useEffect(() => {
    form.patch({
      showAsCallerId: number.features.includes(NUMBER_FEATURE.SHOW_CALLER_ID),
      voiceMail: number.features.includes(NUMBER_FEATURE.VOICE_MAIL),
      smsForwarding: !number.features.includes(NUMBER_FEATURE.DISABLE_SMS),
      forwardType: number.features.includes(NUMBER_FEATURE.SPAN_MULTIPLE_SMS) ? NUMBER_FEATURE.SPAN_MULTIPLE_SMS : 'default',
    });
    initialForm.showAsCallerId = number.features.includes(NUMBER_FEATURE.SHOW_CALLER_ID)
    initialForm.voiceMail = number.features.includes(NUMBER_FEATURE.VOICE_MAIL)
    initialForm.smsForwarding = !number.features.includes(NUMBER_FEATURE.DISABLE_SMS)
    initialForm.forwardType = number.features.includes(NUMBER_FEATURE.SPAN_MULTIPLE_SMS) ? NUMBER_FEATURE.SPAN_MULTIPLE_SMS : 'default'
    if (number.features.includes(NUMBER_FEATURE.VOICE_MAIL)) {
      form.controls.voiceMailForm.patch({
        enabled: true,
        mode: !number.tts && number.voicemail_lite_file ? 'file' : 'text',
        text: number.tts,
        file: number.voicemail_lite_file,
      });
      initialForm.voiceMailForm = {
        enabled: true,
        mode: !number.tts && number.voicemail_lite_file ? 'file' : 'text',
        text: number.tts,
        file: number.voicemail_lite_file,
      }
    } else {
      form.controls.voiceMailForm.disable();
    }

    return () => {
      form.controls.voiceMailForm.disable();
    };
  }, [number]);

  useEffect(() => {
    if (formData) {
      setSettings(formData);
      initialForm = formData
    }
  }, [formData]);

  const onSettingsChange = (field: string, value: any) => {
    const newSettings = {
      ...settings,
      [field]: value,
    };
    setSettings(newSettings);
    form.controls.voiceMailForm.disable(!newSettings.voiceMail);
    form.patch(newSettings);
  };

  const onUploadVoiceMailFile = useCallback((file: File) => {
    return AudioService
      .upload({
        name: `${AUDIO_TYPE.VOICE_MAIL_LITE}_${number.number}_${Date.now()}`,
        type: AUDIO_TYPE.VOICE_MAIL_LITE,
        file,
      }, false)
      .then((audio) => audio.name);
  }, []);

  const onRemoveVoiceMailFile = useCallback((fileName) => {
    return AudioService.delete(fileName, AUDIO_TYPE.VOICE_MAIL_LITE, false);
  }, []);

  const onSave = async () => {
    if (!form.validate()) {
      return;
    }

    const formData = form.getFormData();
    const data: any = {
    };
    let features = [];
    if (formData.voiceMail) {
      features = [NUMBER_FEATURE.VOICE_MAIL];
      if (formData.voiceMailForm.mode === 'text') {
        data.tts = formData.voiceMailForm.text;
        data.voicemail_lite_file = await uploadTTS(data.tts, AUDIO_TYPE.VOICE_MAIL_LITE);
      } else {
        data.voicemail_lite_file = formData.voiceMailForm.file;
      }
    } else {
      if (formData.showAsCallerId) {
        features.push(NUMBER_FEATURE.SHOW_CALLER_ID);
      }
      if (!formData.smsForwarding) {
        features.push(NUMBER_FEATURE.DISABLE_SMS);
      } else if (formData.forwardType === NUMBER_FEATURE.SPAN_MULTIPLE_SMS) {
        features.push(NUMBER_FEATURE.SPAN_MULTIPLE_SMS);
      }
    }

    const validFeatures = [NUMBER_FEATURE.VOICE_MAIL, AUDIO_TYPE.VOICE_MAIL_LITE, NUMBER_FEATURE.SHOW_CALLER_ID, NUMBER_FEATURE.DISABLE_SMS, NUMBER_FEATURE.SPAN_MULTIPLE_SMS];
    const oldFeatures = (number.features || []).filter((item) => !validFeatures.includes(item as NUMBER_FEATURE));
    data.features = [
      ...oldFeatures,
      ...features,
    ];

    onUpdate(data);
  };

  if (settings.voiceMail) {
    return null;
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<AngleDownIcon />}>
          <h5 className="text-lg font-semibold">View advanced settings</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-1fr-auto lg:grid-cols-2 gap-x-4 text-sm">
            <div>
              <h6 className="text-base font-semibold">Show sudonum number as caller ID</h6>
              <p className="text-xs mt-1">
                Shows Sudonum as the caller ID to the party receiving the call. By Default the ID of the party making the call is always shown.
              </p>
            </div>
            <Switch checked={settings.showAsCallerId || false} onChange={(_, checked) => onSettingsChange('showAsCallerId', checked)} />
          </div>

          <div className="grid grid-cols-1fr-auto lg:grid-cols-2 gap-x-4 text-sm mt-4">
            <div>
              <h6 className="text-base font-semibold">Voice mail</h6>
              <p className="text-xs mt-1">
                Use this feature when you want to send all calls to voicemail. You need to have a voicemail greeting uploaded.
                When the Sudonum is called the voicemail file will play and the caller will be able to leave a message of up to 60 seconds after the tone.
              </p>
              <p className="text-xs font-semibold mt-2">
                When this feature is enabled a target number cannot be set.
              </p>
            </div>
            <Switch checked={settings.voiceMail || false} onChange={(_, checked) => onSettingsChange('voiceMail', checked)} />
          </div>
          {settings.voiceMail && (
            <TextFileInput
              className="bg-gray-fc mt-4"
              form={form.controls.voiceMailForm}
              onUploadFile={onUploadVoiceMailFile}
              onChange={onSave}
            />
          )}

          <div className="grid grid-cols-1fr-auto lg:grid-cols-2 gap-x-4 text-sm mt-4">
            <div>
              <h6 className="text-base font-semibold">SMS Forwarding</h6>
              <p className="text-xs mt-1">
                By default when delivering an incoming SMS to a target number the SMS is limited to 160 character only.
                Passing this as a feature allows incoming SMS to be forwarded without the 160 character limit.
              </p>
            </div>
            <Switch checked={settings.smsForwarding || false} onChange={(_, checked) => onSettingsChange('smsForwarding', checked)} />
          </div>

          {settings.smsForwarding && (
            <div className="mt-4">
              <div className="text-sm font-semibold text-blue">Choose how you want to forward a SMS</div>
              <RadioGroup value={settings.forwardType}>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mt-2">
                  {forwardSmsTypes.map((item, i) => (
                    <div
                      key={i}
                      className={classnames(
                        'flex items-start border rounded px-2 pt-1 pb-2 cursor-pointer',
                        item.value === settings.forwardType ? 'border-blue' : 'text-gray-b4',
                      )}
                      onClick={() => onSettingsChange('forwardType', item.value)}
                    >
                      <Radio size="small" value={item.value} />
                      <div className="ml-1 mt-1.5">
                        <div className="text-sm font-bold">{item.text}</div>
                        <p className="text-xs">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </RadioGroup>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AdvancedSettings;

