export enum BLOCKED_KEYWORD_CHANNEL {
  SMS = 'SMS',
  WHATSAPP = 'WhatsApp',
}
export const BLOCKED_KEYWORD_CHANNEL_VALUES = Object.values(BLOCKED_KEYWORD_CHANNEL);

export enum BLOCKED_KEYWORD_TYPE {
  EXACT = 'Exact',
  ENDS_WITH = 'Ends with',
  STARTS_WITH = 'Starts with',
}
export const BLOCKED_KEYWORD_TYPE_VALUES = Object.values(BLOCKED_KEYWORD_TYPE);

export enum BLOCKED_NUMBER_CHANNEL {
  SMS = 'SMS',
  VOICE = 'Calls',
  WHATSAPP = 'WhatsApp',
}
export const BLOCKED_NUMBER_CHANNEL_VALUES = Object.values(BLOCKED_NUMBER_CHANNEL);
export const BLOCKED_NUMBER_CHANNEL_KEYS = Object.keys(BLOCKED_NUMBER_CHANNEL);

export const BLOCKED_NUMBER_CHANNELS = [
  {
    value: "SMS",
    text: 'SMS',
    color: 'text-blue',
  },
  {
    value: "VOICE",
    text: 'Calls',
    color: 'text-blue',
  },
  {
    value: "WHATSAPP",
    text: 'WhatsApp',
    color: 'text-blue',
  },
];
