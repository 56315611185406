import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import AudioFiles from "./index";
import AudioFileCreate from "./AudioFileCreate";
import AudioFileEdit from "./AudioFileEdit";

const AudioFilesRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.AUDIO_FILES.INDEX} component={AudioFiles} />
    <Route exact path={ROUTES.SETTINGS.AUDIO_FILES.CREATE} component={AudioFileCreate} />
    <Route exact path={ROUTES.SETTINGS.AUDIO_FILES.EDIT} component={AudioFileEdit} />
  </Switch>
);

export default AudioFilesRouting;
