import React, { FC } from "react";
import { MessagePreview, Modal, TextField } from "../../../../components";
import { FormControl } from "../../../../components/ReactiveForm";
import { WACampaignsModel } from "../../../../utils/types";
import { formatPhoneNumber } from "../../../../utils/helpers";

interface ICampaignViewModalProps {
    campaign: WACampaignsModel;
    onClose: (result?: any) => void;
}

export type ICampaignDetailsForm = {
    wabaAccount?: FormControl;
    templateName?: FormControl;
    campaignList?: FormControl
}

export const CampaignViewModal: FC<ICampaignViewModalProps> = ({
    campaign,
    onClose,
}) => {
    return (
        <Modal
            title={"View Campaign"}
            size="lg"
            onClose={() => onClose()}
            contentClass="!p-0"
        >
            <div className="flex flex-row-reverse w-full py-8">
                <MessagePreview
                    template={campaign.message_data.template}
                    preview={campaign.message_data.template_header_url}
                />
                <div className="w-full ml-8">
                    <TextField
                        value={formatPhoneNumber(campaign?.message_data.whatsapp_id)}
                        label={"From"}
                        containerClass="!py-4"
                        labelClass="font-bold text-xl"
                        readonly
                        fullWidth
                    />
                    <TextField
                        value={campaign?.message_data.template.name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
                        label={"Message"}
                        containerClass="!py-4"
                        labelClass="font-bold text-xl"
                        readonly
                        fullWidth
                    />
                    <TextField
                        value={campaign?.campaign_list_name}
                        label={"To"}
                        containerClass="!py-4"
                        labelClass="font-bold text-xl"
                        readonly
                        fullWidth
                    />
                </div>
            </div>
        </Modal>
    )
}