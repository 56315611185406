import React, {useCallback, useMemo, useState} from "react";
import {useHistory} from "react-router";
import moment from "moment";
import {Button, Card, DataTable, IDataTableColumn, TextField} from "../../../../components";
import {CloseCircleIcon, CreditCardIcon, RefillIcon} from "../../../../components/icons";
import {CreditCardDetail, ICreditCardFormData} from "../../../../parts";
import {CREDIT_CARD, ROUTES} from "../../../../constants";
import {MessageBoxService} from "../../../../services";

export interface IPayment {
  date: string;
  cardInfo: {
    type: CREDIT_CARD;
    last4Digits: string;
  };
  amount: string;
}

const AddFunds = () => {
  const history = useHistory();
  const [cardInfo, setCardInfo] = useState<ICreditCardFormData>({
    cardType: CREDIT_CARD.MASTER_CARD,
    cardNumber: '1234123412341234',
    csv: '865',
    expiryDate: '10/2023',
  });
  const [amount, setAmount] = useState('');
  const [payments, setPayments] = useState<IPayment[]>([
    { date: '2021-05-19 13:12:50', cardInfo: { type: CREDIT_CARD.MASTER_CARD, last4Digits: '7824' }, amount: 'R500' },
  ]);

  const onClose = () => {
    history.replace(ROUTES.SETTINGS.BILLING.INDEX);
  };

  const onEditCard = () => {
    history.push(ROUTES.SETTINGS.BILLING.CARD);
  };

  const onAddFunds = () => {
    setPayments([
      ...payments,
      {
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        cardInfo: {
          type: cardInfo.cardType,
          last4Digits: cardInfo.cardNumber.slice(-4),
        },
        amount,
      },
    ]);
    setAmount('');
  };

  const onRefill = useCallback((payment: IPayment) => {
    MessageBoxService.confirm({
      title: 'Refill Same Amount',
      message: <p className="font-semibold">Are you sure you want to refill your amount with the same amount as your last payment?</p>,
    }).then((result) => {
      if (result) {
        setPayments([
          ...payments,
          {
            ...payment,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        ]);
      }
    });
  }, [payments]);

  const columns = useMemo<IDataTableColumn<IPayment>[]>(() => [
    { title: 'Date', field: 'date' },
    {
      title: 'Card Details',
      field: 'cardInfo',
      render(row) {
        return (
          <div className="flex items-center">
            <CreditCardIcon size={24} color="#BCBCBC" />
            <span className="text-blue-dark text-sm ml-2">∙∙∙∙ ∙∙∙∙ ∙∙∙∙ {row.cardInfo.last4Digits}</span>
          </div>
        );
      },
    },
    { title: 'Refill Amount', field: 'amount', cellClass: '!font-semibold' },
    {
      render(row) {
        return (
          <Button
            className="text-xs text-blue ml-auto"
            leftIcon={<RefillIcon size={20} />}
            onClick={() => onRefill(row)}
          >
            Refill same amount
          </Button>
        );
      },
    },
  ], [onRefill]);

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Adding Funds</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="h-0 flex-grow overflow-auto p-6">
        <Card
          title="Payment Details"
          size="sm"
          headerClass="text-blue-dark text-lg font-semibold !border-0 pb-2"
        >
          <CreditCardDetail data={cardInfo} editable onEdit={onEditCard} />
        </Card>

        <Card size="sm">
          <div className="flex items-center">
            <label className="text-sm text-blue-dark font-medium">How much would you like to refill your account with?</label>
            <TextField value={amount} containerClass="!w-30 flex-grow flex-shrink mx-4" fullWidth size="sm" onChange={setAmount} />
            <Button
              className="text-sm rounded-full shadow-md px-6"
              color="primary"
              disabled={!amount.trim()}
              onClick={onAddFunds}
            >
              Add Funds
            </Button>
          </div>
        </Card>

        <Card size="sm" className="!p-0">
          <div className="text-lg text-blue-dark font-semibold px-6 pt-4">Recent Payments</div>
          <DataTable
            columns={columns}
            data={payments}
            size="xs"
            wrapperClass="mt-2"
            tableContainerClass="!rounded-t-none"
            stripped
            headerCellClass="!bg-blue-semidark !text-white !text-sm !px-6"
            cellClass="!text-xs !px-6"
          />
        </Card>
      </div>
    </div>
  );
};

export default AddFunds;
