import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const SpamAlreadyBlocked: FC<IIconProps> = (props) => (
      <Icon baseWidth={194.614} baseHeight={143.961} {...props}>
            {(width, height, color) => (
                  <svg
                        id="Group_5705"
                        xmlns="http://www.w3.org/2000/svg"
                        width={width}
                        height={height}
                        viewBox="0 0 283 177.57">
                        <defs>
                              <clipPath id="clipPath">
                                    <rect
                                          id="Rectangle_4377"
                                          width="283"
                                          height="177.57"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-2">
                                    <path
                                          id="Path_2754"
                                          d="M179.908.6c-16.275,2.805-29.921,16.987-32.1,33.358-2.157,16.224,15.935,29.248.534,48.729-4.813,6.088-8.014,14.564-5.887,22.027,2.067,7.251,10.864,11.838,10.928,19.378.051,5.952-5.562,10.688-6.014,16.624-.388,5.095,3.276,9.8,7.779,12.219s9.727,3,14.807,3.552l-1.5,1.61c25.587,2.3,51.334,1.786,77.019,1.272L367,156.945c4.163-.083,8.427-.186,12.306-1.7s7.358-4.754,7.949-8.876c1.005-7.014-6.326-13.1-5.649-20.155.865-9.007,13.548-12.482,15.962-21.2,1.706-6.163-2.413-12.371-6.854-16.971s-9.694-9.087-11.063-15.333c-2.37-10.809,7.764-20.025,11.082-30.581a25.736,25.736,0,0,0-22.2-33.344c-6.14-.563-12.219,1.075-18.274,2.714-6.873,1.86-13.714,3.719-20.58,2.359C319.333,11.806,311.162,2.769,300.665,1.8c-12.352-1.139-22.6,9.057-34.4,12.9-14.108,4.592-29.4-.207-43.276-5.473C211.407,4.826,199.314,0,187.124,0a42.367,42.367,0,0,0-7.216.6"
                                          transform="translate(-141.788 -0.001)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient"
                                    x1="-0.063"
                                    y1="1.107"
                                    x2="-0.063"
                                    y2="1.107"
                                    gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#dae3fe" />
                                    <stop offset="1" stopColor="#e9effd" />
                              </linearGradient>
                              <clipPath id="clipPath-4">
                                    <path
                                          id="Path_2758"
                                          d="M1867.608,851.179l-.009.028c-.047.138-.115.31-.176.47l-.194.485-.4.966-.816,1.925q-.831,1.919-1.7,3.819-1.737,3.8-3.61,7.544c-2.515,4.975-5.157,9.886-8.06,14.641-1.461,2.37-2.932,4.736-4.5,7.037s-3.19,4.564-4.9,6.761a88.723,88.723,0,0,1-11.305,12.258.13.13,0,0,0-.016.178.129.129,0,0,0,.181.019,71.979,71.979,0,0,0,6.166-5.7c1.938-2.022,3.757-4.155,5.517-6.332a133.417,133.417,0,0,0,9.521-13.822c1.449-2.393,2.82-4.833,4.154-7.291s2.613-4.946,3.82-7.468,2.385-5.057,3.488-7.626q.823-1.928,1.606-3.873c.258-.65.516-1.3.764-1.953l.365-.983.177-.494c.057-.169.116-.322.17-.518a.128.128,0,0,0-.244-.075"
                                          transform="translate(-1831.894 -851.092)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-2"
                                    x1="-6.416"
                                    y1="1.249"
                                    x2="-6.412"
                                    y2="1.249"
                                    xlinkHref="#linear-gradient" />
                              <clipPath id="clipPath-6">
                                    <path
                                          id="Path_2762"
                                          d="M280.432,778.957a.218.218,0,0,0-.2.235c.326,4.218.783,8.419,1.319,12.613s1.174,8.375,1.938,12.536,1.654,8.3,2.768,12.394q.826,3.07,1.879,6.082a53.629,53.629,0,0,0,2.439,5.907l0,.005a.515.515,0,0,0,.919-.467v0a52.671,52.671,0,0,1-2.46-5.767q-1.076-2.956-1.93-6c-1.153-4.045-2.085-8.158-2.9-12.293s-1.49-8.3-2.078-12.477-1.089-8.37-1.464-12.568v0a.218.218,0,0,0-.217-.2h-.017"
                                          transform="translate(-280.231 -778.956)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-3"
                                    x1="-3.097"
                                    y1="1.583"
                                    x2="-3.086"
                                    y2="1.583"
                                    xlinkHref="#linear-gradient" />
                              <clipPath id="clipPath-8">
                                    <path
                                          id="Path_2766"
                                          d="M57.494,1064.843a.016.016,0,0,0-.009.02,20.648,20.648,0,0,0,1.651,3.375c.153.275.321.54.493.8l.509.793c.365.51.712,1.036,1.1,1.534a42.289,42.289,0,0,0,5.061,5.587c.445.447.935.842,1.409,1.256s.944.835,1.449,1.211l1.489,1.161c.244.2.509.37.766.552l.775.538.775.539.389.268.4.25,1.6,1c.268.164.532.339.8.494l.814.468,1.627.935c2.18,1.228,4.369,2.441,6.59,3.592,1.106.585,2.22,1.152,3.331,1.724l3.352,1.678c2.232,1.12,4.476,2.211,6.672,3.381q1.653.868,3.232,1.853a24.633,24.633,0,0,1,2.977,2.217l0,0a.129.129,0,0,0,.177-.188,21.5,21.5,0,0,0-2.9-2.41q-1.555-1.066-3.183-2.013c-.54-.319-1.083-.633-1.633-.93s-1.1-.606-1.647-.9c-1.1-.6-2.205-1.178-3.311-1.758-2.21-1.163-4.42-2.321-6.616-3.5s-4.377-2.387-6.548-3.611l-1.619-.933c-.541-.31-1.081-.62-1.606-.948l-1.586-.969-.4-.242-.385-.26-.768-.523a56.143,56.143,0,0,1-5.886-4.528c-.459-.415-.927-.823-1.38-1.245l-1.324-1.305c-.432-.444-.849-.9-1.275-1.354s-.811-.937-1.219-1.405-.772-.971-1.161-1.456l-.547-.754c-.185-.25-.364-.5-.533-.764a23.191,23.191,0,0,1-1.882-3.221.018.018,0,0,0-.016-.01h0"
                                          transform="translate(-57.483 -1064.842)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-4"
                                    x1="-0.153"
                                    y1="1.229"
                                    x2="-0.15"
                                    y2="1.229"
                                    xlinkHref="#linear-gradient" />
                              <clipPath id="clipPath-9">
                                    <path
                                          id="Path_2767"
                                          d="M495.149,191.253,458.395,193.3a2.587,2.587,0,0,0-2.443,2.583v23.043a2.587,2.587,0,0,0,2.587,2.587h35.388a2.587,2.587,0,0,0,2.583-2.446l1.366-25.092a2.587,2.587,0,0,0-2.58-2.728c-.049,0-.1,0-.147,0"
                                          transform="translate(-455.952 -191.249)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-10">
                                    <path
                                          id="Path_2768"
                                          d="M500.788,191.253,464.034,193.3a2.578,2.578,0,0,0-1.629.7l14.478,13.464a4.5,4.5,0,0,0,5.816.144l19.344-16.11a2.566,2.566,0,0,0-1.108-.251q-.073,0-.147,0"
                                          transform="translate(-462.405 -191.249)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-12">
                                    <rect
                                          id="Rectangle_4284"
                                          width="13.636"
                                          height="6.516"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-13">
                                    <rect
                                          id="Rectangle_4285"
                                          width="1.508"
                                          height="1.421"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-14">
                                    <path
                                          id="Path_2771"
                                          d="M564.519,427.067,386.3,431.678a5.873,5.873,0,0,0-6.184,5.2l1.517,104.8A3.381,3.381,0,0,0,385,545.042H567.363a3.38,3.38,0,0,0,3.372-3.369l2.5-105.915a8.5,8.5,0,0,0-8.5-8.694l-.221,0"
                                          transform="translate(-380.114 -427.064)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-5"
                                    x1="-0.335"
                                    y1="0.794"
                                    x2="-0.335"
                                    y2="0.794"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#09005d" />
                                    <stop
                                          offset="1"
                                          stopColor="#1a0f91" />
                              </linearGradient>
                              <clipPath id="clipPath-15">
                                    <path
                                          id="Path_2772"
                                          d="M596.161,458.767,425.353,463.35a7.852,7.852,0,0,0-8.231,7.661l1.432,95.135a3.2,3.2,0,0,0,3.19,3.19H596.558a3.2,3.2,0,0,0,3.19-3.19l2.54-101.267a5.972,5.972,0,0,0-5.968-6.114l-.16,0"
                                          transform="translate(-417.122 -458.765)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-6"
                                    x1="-0.208"
                                    y1="0.804"
                                    x2="-0.207"
                                    y2="0.804"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#4042e2" />
                                    <stop
                                          offset="1"
                                          stopColor="#4f52ff" />
                              </linearGradient>
                              <clipPath id="clipPath-17">
                                    <rect
                                          id="Rectangle_4289"
                                          width="170.065"
                                          height="98.619"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-18">
                                    <rect
                                          id="Rectangle_4292"
                                          width="214.994"
                                          height="9.208"
                                          rx="4.604"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-7"
                                    x1="-0.191"
                                    y1="0.584"
                                    x2="-0.19"
                                    y2="0.584"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-19">
                                    <path
                                          id="Path_2776"
                                          d="M1721.924,64.487l-36.754,1.874a2.469,2.469,0,0,0-2.443,2.363V89.8a2.484,2.484,0,0,0,2.587,2.367H1720.7a2.5,2.5,0,0,0,2.583-2.238l1.366-22.955a2.47,2.47,0,0,0-2.58-2.5c-.049,0-.1,0-.147,0"
                                          transform="translate(-1682.727 -64.483)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-20">
                                    <path
                                          id="Path_2777"
                                          d="M1727.563,64.487l-36.754,1.874a2.7,2.7,0,0,0-1.63.641l14.478,12.318a4.823,4.823,0,0,0,5.816.132l19.344-14.738a2.766,2.766,0,0,0-1.109-.229c-.049,0-.1,0-.147,0"
                                          transform="translate(-1689.18 -64.483)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-22">
                                    <rect
                                          id="Rectangle_4295"
                                          width="13.637"
                                          height="5.961"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-23">
                                    <rect
                                          id="Rectangle_4296"
                                          width="1.508"
                                          height="1.3"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-24">
                                    <path
                                          id="Path_2780"
                                          d="M1920.669,227.3l-23.723,1.322a1.67,1.67,0,0,0-1.577,1.667v14.874a1.67,1.67,0,0,0,1.67,1.67h22.842a1.67,1.67,0,0,0,1.667-1.579l.881-16.2a1.67,1.67,0,0,0-1.665-1.761c-.031,0-.063,0-.095,0"
                                          transform="translate(-1895.369 -227.301)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-25">
                                    <path
                                          id="Path_2781"
                                          d="M1924.309,227.3l-23.723,1.322a1.664,1.664,0,0,0-1.052.452l9.345,8.691a2.9,2.9,0,0,0,3.754.093l12.486-10.4a1.658,1.658,0,0,0-.716-.162l-.094,0"
                                          transform="translate(-1899.534 -227.301)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-27">
                                    <rect
                                          id="Rectangle_4300"
                                          width="8.801"
                                          height="4.205"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-28">
                                    <rect
                                          id="Rectangle_4301"
                                          width="0.973"
                                          height="0.918"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-29">
                                    <path
                                          id="Path_2784"
                                          d="M1568.2,329.912l-36.754,2.049a2.587,2.587,0,0,0-2.443,2.583v23.043a2.587,2.587,0,0,0,2.587,2.587h35.388a2.587,2.587,0,0,0,2.583-2.446l1.365-25.092a2.587,2.587,0,0,0-2.58-2.728q-.073,0-.147,0"
                                          transform="translate(-1529 -329.908)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-30">
                                    <path
                                          id="Path_2785"
                                          d="M1573.836,329.912l-36.754,2.049a2.578,2.578,0,0,0-1.63.7l14.478,13.464a4.5,4.5,0,0,0,5.816.144l19.344-16.11a2.568,2.568,0,0,0-1.109-.251q-.073,0-.146,0"
                                          transform="translate(-1535.453 -329.908)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-32">
                                    <rect
                                          id="Rectangle_4305"
                                          width="13.637"
                                          height="6.515"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-34">
                                    <path
                                          id="Path_2788"
                                          d="M304.064,93.983l-26.291,1.465a1.85,1.85,0,0,0-1.748,1.848V113.78a1.851,1.851,0,0,0,1.85,1.851H303.19a1.851,1.851,0,0,0,1.848-1.75l.977-17.949a1.851,1.851,0,0,0-1.846-1.951q-.052,0-.105,0"
                                          transform="translate(-276.025 -93.98)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-35">
                                    <path
                                          id="Path_2789"
                                          d="M308.1,93.984l-26.291,1.465a1.844,1.844,0,0,0-1.166.5L291,105.582a3.219,3.219,0,0,0,4.161.1L309,94.16a1.839,1.839,0,0,0-.793-.179c-.035,0-.069,0-.1,0"
                                          transform="translate(-280.641 -93.981)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-37">
                                    <rect
                                          id="Rectangle_4310"
                                          width="9.755"
                                          height="4.66"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-38">
                                    <rect
                                          id="Rectangle_4311"
                                          width="1.078"
                                          height="1.016"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-39">
                                    <path
                                          id="Path_2792"
                                          d="M245.7,444.787l-36.754,2.049a2.587,2.587,0,0,0-2.443,2.583v23.043a2.587,2.587,0,0,0,2.587,2.587h35.388a2.587,2.587,0,0,0,2.583-2.446l1.366-25.092a2.587,2.587,0,0,0-2.58-2.728c-.049,0-.1,0-.147,0"
                                          transform="translate(-206.508 -444.783)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-40">
                                    <path
                                          id="Path_2793"
                                          d="M251.344,444.787l-36.754,2.048a2.577,2.577,0,0,0-1.629.7L227.438,461a4.5,4.5,0,0,0,5.816.144L252.6,445.034a2.568,2.568,0,0,0-1.108-.251c-.049,0-.1,0-.146,0"
                                          transform="translate(-212.961 -444.783)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-42">
                                    <rect
                                          id="Rectangle_4315"
                                          width="13.637"
                                          height="6.516"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-44">
                                    <path
                                          id="Path_2796"
                                          d="M39.2,201.477,2.443,203.526A2.587,2.587,0,0,0,0,206.109v23.043a2.587,2.587,0,0,0,2.587,2.587H37.975a2.587,2.587,0,0,0,2.583-2.446L41.924,204.2a2.587,2.587,0,0,0-2.58-2.728c-.049,0-.1,0-.147,0"
                                          transform="translate(0 -201.473)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-45">
                                    <path
                                          id="Path_2797"
                                          d="M44.836,201.477,8.083,203.525a2.578,2.578,0,0,0-1.63.7L20.931,217.69a4.5,4.5,0,0,0,5.816.144l19.344-16.11a2.566,2.566,0,0,0-1.108-.251c-.049,0-.1,0-.147,0"
                                          transform="translate(-6.453 -201.473)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-48">
                                    <rect
                                          id="Rectangle_4321"
                                          width="1.508"
                                          height="1.422"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-49">
                                    <path
                                          id="Path_2800"
                                          d="M1968.448,416.161l-30.181,1.682a2.124,2.124,0,0,0-2.006,2.121v18.922a2.124,2.124,0,0,0,2.124,2.124h29.06a2.124,2.124,0,0,0,2.121-2.009l1.121-20.6a2.124,2.124,0,0,0-2.119-2.24q-.06,0-.121,0"
                                          transform="translate(-1936.261 -416.158)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-50">
                                    <path
                                          id="Path_2801"
                                          d="M1973.079,416.161l-30.181,1.682a2.116,2.116,0,0,0-1.338.575l11.888,11.057a3.7,3.7,0,0,0,4.776.118l15.885-13.229a2.111,2.111,0,0,0-.91-.206q-.06,0-.12,0"
                                          transform="translate(-1941.56 -416.158)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-52">
                                    <rect
                                          id="Rectangle_4325"
                                          width="11.198"
                                          height="5.35"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-53">
                                    <rect
                                          id="Rectangle_4326"
                                          width="1.238"
                                          height="1.167"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-54">
                                    <path
                                          id="Path_2804"
                                          d="M684.383,106.734l-61.443,50.8a8.144,8.144,0,0,0,3.971.8l116.413-6.066a8.164,8.164,0,0,0,5.168-2.2l-45.692-42.8a14.251,14.251,0,0,0-18.417-.523"
                                          transform="translate(-622.94 -103.613)"
                                          fill="#d76d34" />
                              </clipPath>
                              <clipPath id="clipPath-55">
                                    <path
                                          id="Path_2805"
                                          d="M729.477,459.091l-116.391,6.487a8.192,8.192,0,0,0-7.736,8.18V546.73a8.192,8.192,0,0,0,8.192,8.192H725.609a8.192,8.192,0,0,0,8.18-7.747l4.324-79.46a8.192,8.192,0,0,0-8.17-8.638c-.154,0-.31,0-.466.013"
                                          transform="translate(-605.35 -459.078)"
                                          fill="#f6a734" />
                              </clipPath>
                              <clipPath id="clipPath-56">
                                    <path
                                          id="Path_2806"
                                          d="M747.336,459.092l-116.391,6.487a8.164,8.164,0,0,0-5.16,2.218l45.847,42.638a14.25,14.25,0,0,0,18.419.456l61.259-51.018a8.135,8.135,0,0,0-3.511-.794q-.23,0-.463.013"
                                          transform="translate(-625.785 -459.079)"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-57">
                                    <path
                                          id="Path_2809"
                                          d="M752.447,151.1l-116.391,6.487c-1.387.077-2.677,39.333-3.791,40.01a1.346,1.346,0,0,0-.2,2.133l44.676,41.549a14.25,14.25,0,0,0,18.419.456l59.3-49.386a1.353,1.353,0,0,0-.739-2.386c-.417-.041-.843-38.858-1.275-38.863h0"
                                          transform="translate(-631.633 -151.105)"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-59">
                                    <path
                                          id="Path_2811"
                                          d="M425.971,1370.54a6.667,6.667,0,0,0-1.24,3.883,7.128,7.128,0,0,0,2.829.277c2.468-.109,5.6-.362,7.4-.518-1.773-2.176-7.4-3.642-7.4-3.642Z"
                                          transform="translate(-424.73 -1370.54)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-8"
                                    x1="-9.353"
                                    y1="0.748"
                                    x2="-9.332"
                                    y2="0.748"
                                    xlinkHref="#linear-gradient-6" />
                              <clipPath id="clipPath-60">
                                    <path id="Path_2812"
                                          d="M432.446,1349.953l.149,2.944a.672.672,0,0,0,.574.632,2.614,2.614,0,0,0,1.194-.1.8.8,0,0,0,.535-.611l.514-2.866Z"
                                          transform="translate(-432.446 -1349.953)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-9"
                                    x1="-18.382"
                                    y1="2.039"
                                    x2="-18.34"
                                    y2="2.039"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#ff928e" />
                                    <stop
                                          offset="1"
                                          stopColor="#feb3b1" />
                              </linearGradient>
                              <clipPath id="clipPath-62">
                                    <path
                                          id="Path_2814"
                                          d="M258.468,1355.6a1.428,1.428,0,0,0,1.284.95,4.772,4.772,0,0,0,2.721-.144,12.524,12.524,0,0,0,1.871-4.712,1.655,1.655,0,0,0-.9-1.9H262.1c-1.98,0-3.224,4.179-3.632,5.8"
                                          transform="translate(-258.468 -1349.801)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-10"
                                    x1="-4.011"
                                    y1="1.042"
                                    x2="-3.995"
                                    y2="1.042"
                                    xlinkHref="#linear-gradient-6" />
                              <clipPath id="clipPath-63">
                                    <path
                                          id="Path_2815"
                                          d="M282.91,1331.025a.786.786,0,0,0,.409.891,2.79,2.79,0,0,0,1.507.365.657.657,0,0,0,.536-.438l1.069-3.046-2.792-.667Z"
                                          transform="translate(-282.886 -1328.13)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-11"
                                    x1="-9.861"
                                    y1="2.408"
                                    x2="-9.826"
                                    y2="2.408"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-64">
                                    <path
                                          id="Path_2816"
                                          d="M281.96,1049.378c.906.8,3.852.909,3.852.909,10.611-25.468,14.952-29.086,14.952-29.086-1.532,9.613-.247,30.894-.247,30.894a6.367,6.367,0,0,0,4.009,0,164.035,164.035,0,0,1,5.419-40.282c-7.172-.161-10.986-2.615-12.231-3.6-5.978,9.638-15.752,41.165-15.752,41.165"
                                          transform="translate(-281.96 -1008.213)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-12"
                                    x1="-0.568"
                                    y1="1.034"
                                    x2="-0.565"
                                    y2="1.034"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-65">
                                    <path
                                          id="Path_2817"
                                          d="M404.117,774.663c-1.357,6.771-.871,18.89-.551,24.418.09,1.554-.995,3.708-1.871,5.177a.461.461,0,0,0,.186.648,31.97,31.97,0,0,0,12.2,3.383.822.822,0,0,0,.827-.614,160.458,160.458,0,0,1,9.24-26.161c-3.821-6.1-12.049-7.026-16.684-7.026a28.535,28.535,0,0,0-3.349.174"
                                          transform="translate(-401.628 -774.489)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-13"
                                    x1="-1.358"
                                    y1="2.088"
                                    x2="-1.354"
                                    y2="2.088"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#fe7062" />
                                    <stop
                                          offset="1"
                                          stopColor="#ff928e" />
                              </linearGradient>
                              <clipPath id="clipPath-66">
                                    <path
                                          id="Path_2818"
                                          d="M438.433,1073.159a10.659,10.659,0,0,0-4.207,3.652.34.34,0,0,0,.58.354,10.164,10.164,0,0,1,3.922-3.394.34.34,0,0,0-.3-.612"
                                          transform="translate(-434.176 -1073.125)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-14"
                                    x1="-9.14"
                                    y1="6.974"
                                    x2="-9.119"
                                    y2="6.974"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#020039" />
                                    <stop
                                          offset="1"
                                          stopColor="#090056" />
                              </linearGradient>
                              <clipPath
                                    id="clipPath-67">
                                    <path
                                          id="Path_2819"
                                          d="M353.824,926.77a23.294,23.294,0,0,0,4.3,10.25l.98-.8a30.254,30.254,0,0,1-.488-9.8,7.434,7.434,0,0,0-1.416-.126,14.242,14.242,0,0,0-3.378.471"
                                          transform="translate(-353.824 -926.299)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-15"
                                    x1="-3.548"
                                    y1="7.715"
                                    x2="-3.537"
                                    y2="7.715"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-68">
                                    <path
                                          id="Path_2820"
                                          d="M352.613,795.306a11.727,11.727,0,0,1,4.793-.345,16.393,16.393,0,0,1,5.238-10.734l-1.516-8.355s-10.131,7.744-8.515,19.433"
                                          transform="translate(-352.439 -775.873)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-16"
                                    x1="-2.229"
                                    y1="3.637"
                                    x2="-2.222"
                                    y2="3.637"
                                    xlinkHref="#linear-gradient-13" />
                              <clipPath id="clipPath-69">
                                    <path
                                          id="Path_2821"
                                          d="M549.27,838.649c.632,5.084,3.363,7.283,6.309,8.126a17.873,17.873,0,0,1,1.313-5.484c-3.763-3.885-3.712-11.063-3.712-11.063Z"
                                          transform="translate(-549.27 -830.227)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-17"
                                    x1="-5.08"
                                    y1="4.055"
                                    x2="-5.071"
                                    y2="4.055"
                                    xlinkHref="#linear-gradient-13" />
                              <clipPath id="clipPath-70">
                                    <path
                                          id="Path_2822"
                                          d="M599.315,923.475c7.857,3.048,9.936-2.864,9.936-2.864l-1.452-.256a8.231,8.231,0,0,1-7.171-2.364,17.872,17.872,0,0,0-1.313,5.484"
                                          transform="translate(-599.315 -917.991)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-18"
                                    x1="-8.078"
                                    y1="5.902"
                                    x2="-8.064"
                                    y2="5.902"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-71">
                                    <path
                                          id="Path_2823"
                                          d="M493.589,738.755a1.819,1.819,0,0,0,.642,2.615,27.394,27.394,0,0,0,2.721,1.243,1.35,1.35,0,0,0,1.806-.9l1.888-6.94-3.131-1.871Z"
                                          transform="translate(-493.279 -732.903)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-19"
                                    x1="-4.746"
                                    y1="7.333"
                                    x2="-4.736"
                                    y2="7.333"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-72">
                                    <path
                                          id="Path_2824"
                                          d="M306.056,919.547a3.7,3.7,0,0,0-2.1,5.014l.147.323a3.443,3.443,0,0,0,4.58,1.7l46.97-21.729-1.218-2.913Z"
                                          transform="translate(-303.618 -901.941)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-20"
                                    x1="-0.717"
                                    y1="2.51"
                                    x2="-0.715"
                                    y2="2.51"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-74">
                                    <rect
                                          id="Rectangle_4346"
                                          width="47.759"
                                          height="46.898"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-75">
                                    <path
                                          id="Path_2826"
                                          d="M676.2,637.032a22.672,22.672,0,1,1,22.668,22.259A22.467,22.467,0,0,1,676.2,637.032m-3.9,0A26.576,26.576,0,1,0,698.87,610.94,26.335,26.335,0,0,0,672.3,637.032"
                                          transform="translate(-672.299 -610.94)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-21"
                                    x1="-1.089"
                                    y1="1.556"
                                    x2="-1.087"
                                    y2="1.556"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-77">
                                    <path
                                          id="Path_2828"
                                          d="M386.288,1001.32l-.9.471c.025,1.706,2.047.794,2.047.794a20.976,20.976,0,0,1,3.471.464c1.465.372,1.933-.275,1.933-.275l-1.98-1.826Z"
                                          transform="translate(-385.39 -1000.947)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-22"
                                    x1="-11.803"
                                    y1="6.889"
                                    x2="-11.774"
                                    y2="6.889"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-78">
                                    <path
                                          id="Path_2829"
                                          d="M670.235,923.361c.4.309,1.75-.336,2.58-.782a.954.954,0,0,0,.461-1.127c-.2-.629-.532-1.441-.942-1.544a.321.321,0,0,0-.079-.009c-.806,0-3.594,2.232-2.02,3.462"
                                          transform="translate(-669.762 -919.899)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-23"
                                    x1="-15.697"
                                    y1="10.747"
                                    x2="-15.674"
                                    y2="10.747"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-80">
                                    <path
                                          id="Path_2832"
                                          d="M505.282,696c-.349,1.184,1.208,4.076,1.208,4.076a4.631,4.631,0,0,0,5.008,2.582c2.178-.379,1.772-1.6,1.667-3.486A23.017,23.017,0,0,0,511.5,693.7s-5.866,1.117-6.216,2.3"
                                          transform="translate(-505.231 -693.703)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-24"
                                    x1="-5.716"
                                    y1="8.051"
                                    x2="-5.705"
                                    y2="8.051"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-81">
                                    <path
                                          id="Path_2833"
                                          d="M492.541,662.685l-.091.172a.148.148,0,0,1-.273-.028.147.147,0,0,0-.265-.041c-.261.419-.967,1.1-2.861,1.45-2.688.5-4.465,1.584-4.508,3.441a.148.148,0,0,1-.229.121c-.2-.121-.516-.178-.94.179-.738.621-.436,2.23,1.182,3.555a.147.147,0,0,1-.007.237c-.293.191-.73.645.4,1.251a3.021,3.021,0,0,0,2.1.486.148.148,0,0,0,.112-.153l-.1-1.646a.148.148,0,0,1,.148-.157h.862s-1.135-2.327-.138-2.736,4.946-1.214,5.8-2.616a2.606,2.606,0,0,0-1.007-3.584.137.137,0,0,0-.055-.012.151.151,0,0,0-.131.081"
                                          transform="translate(-482.992 -662.604)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-25"
                                    x1="-3.608"
                                    y1="6.022"
                                    x2="-3.6"
                                    y2="6.022"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-82">
                                    <path
                                          id="Path_2834"
                                          d="M506.43,722.44c-1.028.643.533,2.569,1.36,2.655l.264-1s-.459-1.764-1.265-1.764a.674.674,0,0,0-.359.114"
                                          transform="translate(-506.091 -722.326)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-26"
                                    x1="-19.088"
                                    y1="25.752"
                                    x2="-19.05"
                                    y2="25.752"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-83">
                                    <path
                                          id="Path_2835"
                                          d="M2088.561,1371.717a.339.339,0,0,0,.322.446h5.747a.529.529,0,0,0,.525-.56c-.185-3.243-.92-4.4-.92-4.4l-2.047-.3a9.2,9.2,0,0,0-3.627,4.816"
                                          transform="translate(-2088.543 -1366.901)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-27"
                                    x1="-33.765"
                                    y1="0.889"
                                    x2="-33.749"
                                    y2="0.889"
                                    xlinkHref="#linear-gradient-6" />
                              <clipPath id="clipPath-84">
                                    <path
                                          id="Path_2836"
                                          d="M2114.406,1352.087a.842.842,0,0,0,.694.835,4.544,4.544,0,0,0,1.215.011.513.513,0,0,0,.448-.451l.325-2.726-2.682-.3Z"
                                          transform="translate(-2114.406 -1349.455)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-28"
                                    x1="-99.38"
                                    y1="2.107"
                                    x2="-99.333"
                                    y2="2.107"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-85">
                                    <path
                                          id="Path_2837"
                                          d="M1954.718,1366.9a38.839,38.839,0,0,0-7.41,4.273.427.427,0,0,0,.245.777h8.926a1.015,1.015,0,0,0,1.021-.993,8.025,8.025,0,0,0-1.076-4.057Z"
                                          transform="translate(-1947.125 -1366.901)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-29"
                                    x1="-21.327"
                                    y1="0.865"
                                    x2="-21.316"
                                    y2="0.865"
                                    xlinkHref="#linear-gradient-6" />
                              <clipPath id="clipPath-86">
                                    <path
                                          id="Path_2838"
                                          d="M2007.041,1337.255a.8.8,0,0,0,.356.813,2.507,2.507,0,0,0,.785.328.694.694,0,0,0,.821-.546l.693-3.366-1.938-1.307Z"
                                          transform="translate(-2007.029 -1333.177)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-30"
                                    x1="-93.308"
                                    y1="1.793"
                                    x2="-93.261"
                                    y2="1.793"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-87">
                                    <path
                                          id="Path_2839"
                                          data-name="Path 2839"
                                          d="M2008.125,977.675c-1.934,6.439-3.473,42.659-3.473,42.659.749.442,3.072.159,3.072.159,1.628-5.049,7.5-32.99,7.5-32.99,1.12,6.233,2.594,33.207,2.594,33.207,1.243.753,3.654.352,3.654.352s1.739-41.563,1.286-44.12a9.665,9.665,0,0,0-2.865-4.683Z"
                                          transform="translate(-2004.652 -972.26)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-31"
                                    x1="-5.465"
                                    y1="1.099"
                                    x2="-5.462"
                                    y2="1.099"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-88">
                                    <path
                                          id="Path_2840"
                                          d="M2090.89,1040.231l-2.212,1.419a.192.192,0,0,0-.083.114l-1.024,3.928a.193.193,0,1,0,.374.1l1-3.855,2.149-1.378a.193.193,0,1,0-.209-.325"
                                          transform="translate(-2087.565 -1040.2)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-32"
                                    x1="-71.68"
                                    y1="8.1"
                                    x2="-71.646"
                                    y2="8.1"
                                    xlinkHref="#linear-gradient-14" />
                              <clipPath id="clipPath-89">
                                    <path
                                          id="Path_2841"
                                          d="M1863.918,777.938c7.145,5.508,17.118,5.7,17.118,5.7l4.877-7.7s-2.653-2.1-12.8-1.258a13.435,13.435,0,0,1-6.332-1.018,17.44,17.44,0,0,1-2.862,4.271"
                                          transform="translate(-1863.918 -773.667)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-33"
                                    x1="-22.778"
                                    y1="3.872"
                                    x2="-22.766"
                                    y2="3.872"
                                    gradientUnits="objectBoundingBox">
                                    <stop
                                          offset="0"
                                          stopColor="#e1473d" />
                                    <stop
                                          offset="1"
                                          stopColor="#e9605a" />
                              </linearGradient>
                              <clipPath id="clipPath-90">
                                    <path
                                          id="Path_2842"
                                          d="M1808.332,661.5l-.351,8.341a1.358,1.358,0,0,0,.958,1.35,17.5,17.5,0,0,0,6.092,8.459,17.441,17.441,0,0,0,2.862-4.271,19.314,19.314,0,0,1-7.332-5.7,2.775,2.775,0,0,1-.566-2.21l.454-1.608a2.877,2.877,0,0,0-.23-1.713l-1.295-2.763a.3.3,0,0,0-.279-.18.308.308,0,0,0-.314.3"
                                          transform="translate(-1807.98 -661.201)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-34"
                                    x1="-22.937"
                                    y1="5.107"
                                    x2="-22.924"
                                    y2="5.107"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-91">
                                    <path
                                          id="Path_2843"
                                          d="M1859.014,766.744c-3.94.714-10.746,2.454-14.318,6.3,0,0-2.525,6.787-8.2,7.759a28.889,28.889,0,0,1-1.271,6c3.84,1.385,8.839.807,14.548-5.523l9.159,15.262a2.594,2.594,0,0,1,.369,1.334.829.829,0,0,0,1.062.8,93.712,93.712,0,0,0,11.7-4.111,1.282,1.282,0,0,0,.6-1.708c-.509-1.068-1.309-2.775-2.076-4.524-.935-2.131-5.757-14.532-7.53-19.3a3.611,3.611,0,0,0-3.387-2.351,3.654,3.654,0,0,0-.652.059"
                                          transform="translate(-1835.226 -766.685)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-35"
                                    x1="-4.915"
                                    y1="1.838"
                                    x2="-4.912"
                                    y2="1.838"
                                    xlinkHref="#linear-gradient-13" />
                              <clipPath id="clipPath-92">
                                    <path
                                          id="Path_2844"
                                          d="M1770.92,860.82s3.047,5.235,8.106,7.06a28.881,28.881,0,0,0,1.271-6c-2.268.389-5.039-.151-8.353-2.427Z"
                                          transform="translate(-1770.92 -859.455)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-36"
                                    x1="-17.608"
                                    y1="5.726"
                                    x2="-17.598"
                                    y2="5.726"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-93">
                                    <path
                                          id="Path_2845"
                                          d="M1699.558,847.271c-1.22,0-5.726.683-5.726.683s-4.311-.977-5.073-.5l5.311,2.966,5.768-.225.481-.815s.013-.061.029-.161c.078-.493.223-1.949-.789-1.949h0"
                                          transform="translate(-1688.759 -847.271)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-37"
                                    x1="-18.752"
                                    y1="7.376"
                                    x2="-18.741"
                                    y2="7.376"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-94">
                                    <path
                                          id="Path_2846"
                                          d="M1942.976,714l1.519,6.678c2.813.23,6.31-2.19,6.31-2.19l-3.043-7.427Z"
                                          transform="translate(-1942.976 -711.063)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-38"
                                    x1="-20.982"
                                    y1="7.199"
                                    x2="-20.971"
                                    y2="7.199"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-95">
                                    <path
                                          id="Path_2847"
                                          d="M1924.894,667.875c-.07.1-2.2,7.8-2.2,7.8l4.424,1.124,3.105-7s-2.745-2.361-4.4-2.361a1.062,1.062,0,0,0-.926.436"
                                          transform="translate(-1922.692 -667.439)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-39"
                                    x1="-30.935"
                                    y1="9.744"
                                    x2="-30.919"
                                    y2="9.744"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-96">
                                    <path
                                          id="Path_2848"
                                          d="M1918.3,646.451c-.119,1.393,2.013,1.926,3.267,1.832.953-.071,1.661.873,1.945,1.33a.56.56,0,0,1,.051.483l-1.346,3.813a.557.557,0,0,1-.07.136c-.183.26-.877,1.107-1.7.344a5.078,5.078,0,0,0-2.078-1.425.565.565,0,0,0-.643.405,24.407,24.407,0,0,0-.744,3.716.563.563,0,0,0,.458.607,18.207,18.207,0,0,0,4.225.351.565.565,0,0,0,.476-.328c.422-.921,1.713-3.715,2.193-4.438.451-.679,1.556-.66,2.019-.618a.556.556,0,0,0,.416-.136,1.464,1.464,0,0,0,.091-2.349.566.566,0,0,1,.013-.814,1.467,1.467,0,0,0,.188-2.18,2.069,2.069,0,0,0-2.47-.591.565.565,0,0,1-.68-.251.447.447,0,0,0-.461-.216.556.556,0,0,1-.594-.571,1.686,1.686,0,0,0-1.3-1.76,2.442,2.442,0,0,0-.728-.116c-1.5,0-2.426,1.566-2.529,2.774"
                                          transform="translate(-1916.98 -643.677)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-40"
                                    x1="-15.336"
                                    y1="5.587"
                                    x2="-15.328"
                                    y2="5.587"
                                    xlinkHref="#linear-gradient-5" />
                              <clipPath id="clipPath-97">
                                    <path
                                          id="Path_2849"
                                          d="M1972.648,710.615c-1.006.118-1.318,2.537-1.318,2.537s1.059,1.217,2.3-.471c.937-1.271.138-2.077-.794-2.077a1.632,1.632,0,0,0-.191.011"
                                          transform="translate(-1971.33 -710.604)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-41"
                                    x1="-65.465"
                                    y1="20.866"
                                    x2="-65.432"
                                    y2="20.866"
                                    xlinkHref="#linear-gradient-9" />
                              <clipPath id="clipPath-98">
                                    <path
                                          id="Path_2850"
                                          d="M1147.863,623.3a5.942,5.942,0,0,0-3.721,1.3l-10.845,8.72a5.529,5.529,0,0,0,0,8.681l10.845,8.72a5.942,5.942,0,0,0,3.721,1.3H1211.2a5.736,5.736,0,0,0,5.826-5.641V628.939a5.736,5.736,0,0,0-5.826-5.641Z"
                                          transform="translate(-1131.192 -623.298)"
                                          fill="#d76d34" />
                              </clipPath>
                              <clipPath id="clipPath-100">
                                    <rect
                                          id="Rectangle_4372"
                                          width="18.277"
                                          height="17.7"
                                          fill="none" />
                              </clipPath>
                              <clipPath id="clipPath-101">
                                    <path
                                          id="Path_2852"
                                          d="M1640.617,688.883l-4.42-2.6,4.9,8.636,5.288-12.756Z"
                                          transform="translate(-1636.197 -682.16)"
                                          fill="none" />
                              </clipPath>
                              <linearGradient
                                    id="linear-gradient-42"
                                    x1="-12.557"
                                    y1="6.063"
                                    x2="-12.549"
                                    y2="6.063"
                                    xlinkHref="#linear-gradient-33" />
                              <clipPath id="clipPath-103">
                                    <rect
                                          id="Rectangle_4375"
                                          width="36.567"
                                          height="9.954"
                                          fill="none" />
                              </clipPath>
                        </defs>
                        <g
                              id="Group_5704"
                              clipPath="url(#clipPath)">
                              <g
                                    id="Group_5481"
                                    transform="translate(17.873 0)">
                                    <g
                                          id="Group_5480"
                                          clipPath="url(#clipPath-2)">
                                          <rect
                                                id="Rectangle_4275"
                                                width="258.939"
                                                height="160.396"
                                                transform="translate(-1.457 0)"
                                                fill="url(#linear-gradient)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5483"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5482"
                                          clipPath="url(#clipPath)">
                                          <path
                                                id="Path_2755"
                                                d="M1827.9,728.009c2.1,6.271,12.8,43.265-20.113,59.544,0,0-13.315-22.541,19.161-59.736a.559.559,0,0,1,.951.192"
                                                transform="translate(-1577.54 -635.903)"
                                                fill="#91b3fa" />
                                          <path
                                                id="Path_2756"
                                                d="M1892.177,779.191c-.327,4.218-.783,8.419-1.319,12.613s-1.175,8.375-1.937,12.536-1.654,8.3-2.768,12.394q-.826,3.07-1.879,6.082a53.632,53.632,0,0,1-2.439,5.907.516.516,0,1,1-.925-.456v0l0,0a52.66,52.66,0,0,0,2.46-5.767q1.075-2.956,1.93-6c1.153-4.045,2.085-8.158,2.9-12.293s1.49-8.3,2.078-12.477,1.089-8.37,1.464-12.568v0a.218.218,0,0,1,.435.036"
                                                transform="translate(-1643.76 -680.763)"
                                                fill="#bdd0fb" />
                                          <path
                                                id="Path_2757"
                                                d="M1833.048,782.359a.629.629,0,0,0-1.188-.275c-2.856,5.636-10.809,20.181-19.949,26.924-14.882,10.98-27.33,31.131-21.729,43.954,0,0,44.061.6,42.866-70.6"
                                                transform="translate(-1563.319 -683.196)"
                                                fill="#bdd0fb" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5485"
                                    transform="translate(230.925 107.287)">
                                    <g
                                          id="Group_5484"
                                          clipPath="url(#clipPath-4)">
                                          <rect
                                                id="Rectangle_4277"
                                                width="35.991"
                                                height="56.262"
                                                transform="translate(-0.015 0)"
                                                fill="url(#linear-gradient-2)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5487"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5486"
                                          clipPath="url(#clipPath)">
                                          <path
                                                id="Path_2759"
                                                d="M1795.008,1037.172s2.359-8.746,13.412-15.454c10.255-6.225,35.168-20.331,42.437-29.756a.487.487,0,0,1,.862.406,59.736,59.736,0,0,1-18.517,30.366c-16.6,14.615-38.193,14.439-38.193,14.439"
                                                transform="translate(-1568.733 -866.747)"
                                                fill="#91b3fa" />
                                          <path
                                                id="Path_2760"
                                                d="M1864.246,1064.862a20.663,20.663,0,0,1-1.651,3.375c-.153.275-.321.54-.493.8l-.509.793c-.365.51-.712,1.036-1.1,1.534a42.288,42.288,0,0,1-5.061,5.587c-.445.447-.935.842-1.409,1.256s-.944.835-1.449,1.21l-1.489,1.161c-.244.2-.509.37-.766.552l-.775.539-.775.538-.389.268-.4.25-1.6,1c-.268.164-.532.339-.8.494l-.813.468-1.627.935c-2.18,1.228-4.369,2.441-6.59,3.592-1.106.585-2.22,1.152-3.331,1.724l-3.352,1.678c-2.232,1.12-4.476,2.211-6.672,3.381q-1.653.868-3.232,1.853a24.624,24.624,0,0,0-2.977,2.217l0,0a.129.129,0,0,1-.177-.188,21.483,21.483,0,0,1,2.9-2.41q1.555-1.066,3.183-2.013c.54-.319,1.083-.633,1.633-.931s1.1-.606,1.647-.9c1.1-.6,2.205-1.178,3.311-1.758,2.21-1.163,4.419-2.321,6.616-3.5s4.377-2.387,6.548-3.611l1.62-.933c.541-.31,1.082-.62,1.606-.948l1.586-.969.4-.242.385-.26.768-.523a56.133,56.133,0,0,0,5.886-4.528c.459-.415.926-.823,1.38-1.245l1.324-1.305c.432-.444.849-.9,1.275-1.354s.811-.937,1.219-1.405.772-.971,1.161-1.456l.547-.754c.185-.25.364-.5.533-.764a23.183,23.183,0,0,0,1.882-3.221.016.016,0,0,1,.021-.009.017.017,0,0,1,.009.02"
                                                transform="translate(-1587.75 -930.609)"
                                                fill="#bdd0fb" />
                                          <path
                                                id="Path_2761"
                                                d="M241.757,728.009c-2.1,6.271-12.8,43.265,20.113,59.544,0,0,13.315-22.541-19.161-59.736a.559.559,0,0,0-.951.192"
                                                transform="translate(-208.374 -635.903)"
                                                fill="#bdd0fb" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5489"
                                    transform="translate(35.325 98.194)">
                                    <g
                                          id="Group_5488"
                                          clipPath="url(#clipPath-6)">
                                          <rect
                                                id="Rectangle_4279"
                                                width="11.403"
                                                height="50.128"
                                                transform="translate(-0.009 0)"
                                                fill="url(#linear-gradient-3)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5491"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5490"
                                          clipPath="url(#clipPath)">
                                          <path
                                                id="Path_2763"
                                                d="M111.015,782.359a.629.629,0,0,1,1.188-.275c2.856,5.636,10.808,20.181,19.949,26.924,14.882,10.98,27.33,31.131,21.729,43.954,0,0-44.061.6-42.866-70.6"
                                                transform="translate(-96.999 -683.196)"
                                                fill="#91b3fa" />
                                          <path
                                                id="Path_2764"
                                                d="M133.975,851.206c.047.138.115.31.176.47l.194.485.4.966.816,1.925q.83,1.919,1.7,3.819,1.736,3.8,3.61,7.544c2.515,4.975,5.157,9.886,8.06,14.641,1.461,2.371,2.932,4.736,4.5,7.037s3.19,4.564,4.9,6.761a88.73,88.73,0,0,0,11.305,12.258.129.129,0,0,1,.013.182.13.13,0,0,1-.178.016,72.015,72.015,0,0,1-6.166-5.7c-1.938-2.022-3.757-4.155-5.518-6.332a133.426,133.426,0,0,1-9.521-13.822c-1.45-2.393-2.82-4.833-4.154-7.291s-2.613-4.946-3.82-7.468-2.385-5.058-3.488-7.626q-.823-1.928-1.606-3.873c-.258-.649-.516-1.3-.764-1.953l-.365-.983-.177-.494c-.057-.169-.115-.322-.17-.518a.128.128,0,0,1,.244-.075Z"
                                                transform="translate(-116.861 -743.805)"
                                                fill="#bdd0fb" />
                                          <path
                                                id="Path_2765"
                                                d="M62.624,1037.172s-2.359-8.746-13.412-15.454c-10.255-6.225-35.168-20.331-42.437-29.756a.487.487,0,0,0-.862.406,59.736,59.736,0,0,0,18.517,30.366c16.6,14.615,38.193,14.439,38.193,14.439"
                                                transform="translate(-5.155 -866.747)"
                                                fill="#bdd0fb" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5493"
                                    transform="translate(7.246 134.232)">
                                    <g
                                          id="Group_5492"
                                          clipPath="url(#clipPath-8)">
                                          <rect
                                                id="Rectangle_4281"
                                                width="47.494"
                                                height="35.275"
                                                transform="translate(-0.002)"
                                                fill="url(#linear-gradient-4)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5495"
                                    transform="translate(57.476 24.108)">
                                    <g
                                          id="Group_5494"
                                          clipPath="url(#clipPath-9)">
                                          <rect
                                                id="Rectangle_4282"
                                                width="39.55"
                                                height="48.091"
                                                transform="translate(-7.121 28.485) rotate(-75.964)"
                                                fill="#f6a734" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5497"
                                    transform="translate(58.29 24.108)">
                                    <g
                                          id="Group_5496"
                                          clipPath="url(#clipPath-10)">
                                          <rect
                                                id="Rectangle_4283"
                                                width="19.733"
                                                height="40.488"
                                                transform="matrix(0.051, -0.999, 0.999, 0.051, -0.9, 17.697)"
                                                fill="#f6a734" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5505"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5504"
                                          clipPath="url(#clipPath)">
                                          <g
                                                id="Group_5500"
                                                transform="translate(67.62 33.156)"
                                                opacity="0.74">
                                                <g id="Group_5499" >
                                                      <g
                                                            id="Group_5498"
                                                            clipPath="url(#clipPath-12)">
                                                            <path
                                                                  id="Path_2769"
                                                                  d="M544.7,269.536a4.053,4.053,0,0,1-2.688-1.023l-5.455-4.816a.387.387,0,1,1,.512-.58l5.455,4.816a3.276,3.276,0,0,0,4.1.205l2.827-2.032a.387.387,0,1,1,.451.628l-2.827,2.032a4.062,4.062,0,0,1-2.375.77"
                                                                  transform="translate(-536.424 -263.02)"
                                                                  fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g
                                                id="Group_5503"
                                                transform="translate(64.857 30.716)"
                                                opacity="0.74">
                                                <g id="Group_5502" >
                                                      <g
                                                            id="Group_5501"
                                                            clipPath="url(#clipPath-13)">
                                                            <path
                                                                  id="Path_2770"
                                                                  d="M515.62,245.088a.385.385,0,0,1-.256-.1l-.734-.648a.387.387,0,1,1,.512-.58l.734.648a.387.387,0,0,1-.256.676"
                                                                  transform="translate(-514.5 -243.667)"
                                                                  fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g
                                    id="Group_5507"
                                    transform="translate(47.916 53.835)">
                                    <g
                                          id="Group_5506"
                                          clipPath="url(#clipPath-14)">
                                          <rect
                                                id="Rectangle_4287"
                                                width="173.013"
                                                height="220.575"
                                                transform="translate(-35.393 106.181) rotate(-71.565)"
                                                fill="url(#linear-gradient-5)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5509"
                                    transform="translate(52.582 57.831)">
                                    <g
                                          id="Group_5508"
                                          clipPath="url(#clipPath-15)">
                                          <rect
                                                id="Rectangle_4288"
                                                width="205.194"
                                                height="212.183"
                                                transform="translate(-54.801 62.592) rotate(-48.797)"
                                                fill="url(#linear-gradient-6)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5514"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5513"
                                          clipPath="url(#clipPath)">
                                          <path
                                                id="Path_2773"
                                                d="M440.9,893.342a.258.258,0,0,1-.258-.254l-.113-7.715a.258.258,0,0,1,.254-.261h0a.258.258,0,0,1,.258.254l.113,7.715a.258.258,0,0,1-.254.261Z"
                                                transform="translate(-384.993 -773.536)"
                                                fill="#fff" />
                                          <path
                                                id="Path_2774"
                                                d="M436.471,537.505a.258.258,0,0,1-.258-.254l-.434-29.55a6.256,6.256,0,0,1,1.807-4.69,8.6,8.6,0,0,1,5.7-1.965l54.547-1.464a.25.25,0,0,1,.264.251.258.258,0,0,1-.251.265l-54.562,1.464a8.059,8.059,0,0,0-5.339,1.822,5.757,5.757,0,0,0-1.648,4.315l.434,29.545a.258.258,0,0,1-.254.262Z"
                                                transform="translate(-380.846 -436.606)"
                                                fill="#91b3fa" />
                                          <g id="Group_5512"
                                                transform="translate(61.363 64.979)"
                                                opacity="0.2">
                                                <g id="Group_5511" >
                                                      <g
                                                            id="Group_5510"
                                                            clipPath="url(#clipPath-17)">
                                                            <path
                                                                  id="Path_2775"
                                                                  d="M487.137,610.426c.183-7.863.115-77.492-.336-83.506s8.914-7.141,23.293-7.818,135.7-3.356,138.825-3.609c7.022-.569,8.475,9.926,7.772,32.71-.528,17.117-1.4,45.315-1.8,58.283a7.156,7.156,0,0,1-7.139,6.935c-29.195.08-141.091.4-148.54.623-8.572.255-12.143-.5-12.071-3.617"
                                                                  transform="translate(-486.785 -515.471)"
                                                                  fill="#91b3fa" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g
                                    id="Group_5516"
                                    transform="translate(38.337 167.954)">
                                    <g
                                          id="Group_5515"
                                          clipPath="url(#clipPath-18)">
                                          <rect
                                                id="Rectangle_4291"
                                                width="211.079"
                                                height="59.761"
                                                transform="translate(-2.115 0.514) rotate(-13.671)"
                                                fill="url(#linear-gradient-7)" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5518"
                                    transform="translate(212.121 8.129)">
                                    <g
                                          id="Group_5517"
                                          clipPath="url(#clipPath-19)">
                                          <rect
                                                id="Rectangle_4293"
                                                width="37.127"
                                                height="47.504"
                                                transform="translate(-6.571 26.03) rotate(-75.833)"
                                                fill="#f6a734" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5520"
                                    transform="translate(212.935 8.129)">
                                    <g
                                          id="Group_5519"
                                          clipPath="url(#clipPath-20)">
                                          <rect
                                                id="Rectangle_4294"
                                                width="34.256"
                                                height="42.355"
                                                transform="matrix(0.513, -0.859, 0.859, 0.513, -7.144, 11.967)"
                                                fill="#f6a734" />
                                    </g>
                              </g>
                              <g
                                    id="Group_5528"
                                    transform="translate(0 0)">
                                    <g
                                          id="Group_5527"
                                          clipPath="url(#clipPath)">
                                          <g
                                                id="Group_5523"
                                                transform="translate(222.265 16.405)"
                                                opacity="0.74">
                                                <g id="Group_5522" >
                                                      <g
                                                            id="Group_5521"
                                                            clipPath="url(#clipPath-22)">
                                                            <path
                                                                  id="Path_2778"
                                                                  d="M1771.474,136.1a4.277,4.277,0,0,1-2.688-.936l-5.455-4.406a.333.333,0,0,1-.034-.5.412.412,0,0,1,.546-.031l5.455,4.406a3.515,3.515,0,0,0,4.1.188l2.827-1.859a.41.41,0,0,1,.54.081.335.335,0,0,1-.088.494l-2.827,1.859a4.324,4.324,0,0,1-2.375.7"
                                                                  transform="translate(-1763.2 -130.142)"
                                                                  fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g
                                                id="Group_5526"
                                                transform="translate(219.502 14.174)"
                                                opacity="0.74">
                                                <g id="Group_5525" >
                                                      <g
                                                            id="Group_5524"
                                                            clipPath="url(#clipPath-23)">
                                                            <path
                                                                  id="Path_2779"
                                                                  d="M1742.4,113.736a.406.406,0,0,1-.256-.089l-.734-.593a.333.333,0,0,1-.034-.5.412.412,0,0,1,.546-.031l.734.593a.333.333,0,0,1,.034.5.4.4,0,0,1-.29.12"
                                                                  transform="translate(-1741.276 -112.436)"
                                                                  fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5530" data-name="Group 5530" transform="translate(238.926 28.653)">
                                    <g id="Group_5529" data-name="Group 5529" clipPath="url(#clipPath-24)">
                                          <rect id="Rectangle_4298" data-name="Rectangle 4298" width="25.577" height="31.069" transform="translate(-4.636 18.365) rotate(-75.833)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5532" data-name="Group 5532" transform="translate(239.451 28.653)">
                                    <g id="Group_5531" data-name="Group 5531" clipPath="url(#clipPath-25)">
                                          <rect id="Rectangle_4299" data-name="Rectangle 4299" width="12.994" height="26.237" transform="translate(-0.699 11.41) rotate(-86.497)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5540" data-name="Group 5540" transform="translate(0 0)">
                                    <g id="Group_5539" data-name="Group 5539" clipPath="url(#clipPath)">
                                          <g id="Group_5535" data-name="Group 5535" transform="translate(245.474 34.493)" opacity="0.74">
                                                <g id="Group_5534" data-name="Group 5534">
                                                      <g id="Group_5533" data-name="Group 5533" clipPath="url(#clipPath-27)">
                                                            <path id="Path_2782" data-name="Path 2782" d="M1952.652,277.831a2.616,2.616,0,0,1-1.735-.661l-3.521-3.108a.25.25,0,1,1,.33-.374l3.521,3.108a2.115,2.115,0,0,0,2.646.132l1.824-1.311a.25.25,0,1,1,.291.405l-1.824,1.311a2.621,2.621,0,0,1-1.533.5" transform="translate(-1947.311 -273.626)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5538" data-name="Group 5538" transform="translate(243.69 32.917)" opacity="0.74">
                                                <g id="Group_5537" data-name="Group 5537">
                                                      <g id="Group_5536" data-name="Group 5536" clipPath="url(#clipPath-28)">
                                                            <path id="Path_2783" data-name="Path 2783" d="M1933.883,262.052a.248.248,0,0,1-.165-.062l-.474-.418a.25.25,0,1,1,.33-.374l.474.418a.25.25,0,0,1-.165.437" transform="translate(-1933.159 -261.134)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5542" data-name="Group 5542" transform="translate(192.743 41.588)">
                                    <g id="Group_5541" data-name="Group 5541" clipPath="url(#clipPath-29)">
                                          <rect id="Rectangle_4303" data-name="Rectangle 4303" width="31.553" height="42.923" transform="matrix(0.031, -1, 1, 0.031, -0.938, 30.237)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5544" data-name="Group 5544" transform="translate(193.556 41.588)">
                                    <g id="Group_5543" data-name="Group 5543" clipPath="url(#clipPath-30)">
                                          <rect id="Rectangle_4304" data-name="Rectangle 4304" width="35.071" height="43.219" transform="translate(-7.643 13.376) rotate(-60.255)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5552" data-name="Group 5552" transform="translate(0 0)">
                                    <g id="Group_5551" data-name="Group 5551" clipPath="url(#clipPath)">
                                          <g id="Group_5547" data-name="Group 5547" transform="translate(202.886 50.635)" opacity="0.74">
                                                <g id="Group_5546" data-name="Group 5546">
                                                      <g id="Group_5545" data-name="Group 5545" clipPath="url(#clipPath-32)">
                                                            <path id="Path_2786" data-name="Path 2786" d="M1617.746,408.195a4.053,4.053,0,0,1-2.688-1.023l-5.455-4.816a.387.387,0,0,1,.512-.58l5.455,4.816a3.276,3.276,0,0,0,4.1.205l2.827-2.032a.387.387,0,1,1,.451.628l-2.827,2.032a4.062,4.062,0,0,1-2.375.77" transform="translate(-1609.472 -401.679)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5550" data-name="Group 5550" transform="translate(200.123 48.196)" opacity="0.74">
                                                <g id="Group_5549" data-name="Group 5549">
                                                      <g id="Group_5548" data-name="Group 5548" clipPath="url(#clipPath-13)">
                                                            <path id="Path_2787" data-name="Path 2787" d="M1588.669,383.747a.385.385,0,0,1-.256-.1l-.734-.648a.387.387,0,1,1,.512-.58l.734.648a.387.387,0,0,1-.256.676" transform="translate(-1587.549 -382.326)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5554" data-name="Group 5554" transform="translate(34.795 11.847)">
                                    <g id="Group_5553" data-name="Group 5553" clipPath="url(#clipPath-34)">
                                          <rect id="Rectangle_4308" data-name="Rectangle 4308" width="23.2" height="31.144" transform="translate(-1.136 21.591) rotate(-86.987)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5556" data-name="Group 5556" transform="translate(35.377 11.847)">
                                    <g id="Group_5555" data-name="Group 5555" clipPath="url(#clipPath-35)">
                                          <rect id="Rectangle_4309" data-name="Rectangle 4309" width="24.942" height="30.939" transform="translate(-5.416 9.654) rotate(-60.709)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5564" data-name="Group 5564" transform="translate(0 0)">
                                    <g id="Group_5563" data-name="Group 5563" clipPath="url(#clipPath)">
                                          <g id="Group_5559" data-name="Group 5559" transform="translate(42.052 18.319)" opacity="0.74">
                                                <g id="Group_5558" data-name="Group 5558">
                                                      <g id="Group_5557" data-name="Group 5557" clipPath="url(#clipPath-37)">
                                                            <path id="Path_2790" data-name="Path 2790" d="M339.508,149.981a2.9,2.9,0,0,1-1.923-.732l-3.9-3.445a.277.277,0,1,1,.366-.415l3.9,3.445a2.343,2.343,0,0,0,2.933.147l2.022-1.453a.277.277,0,1,1,.323.449l-2.022,1.453a2.905,2.905,0,0,1-1.7.551" transform="translate(-333.59 -145.32)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5562" data-name="Group 5562" transform="translate(40.075 16.574)" opacity="0.74">
                                                <g id="Group_5561" data-name="Group 5561">
                                                      <g id="Group_5560" data-name="Group 5560" clipPath="url(#clipPath-38)">
                                                            <path id="Path_2791" data-name="Path 2791" d="M318.708,132.493a.276.276,0,0,1-.183-.069l-.525-.464a.277.277,0,1,1,.366-.415l.525.464a.277.277,0,0,1-.183.484" transform="translate(-317.907 -131.477)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5566" data-name="Group 5566" transform="translate(26.032 56.068)">
                                    <g id="Group_5565" data-name="Group 5565" clipPath="url(#clipPath-39)">
                                          <rect id="Rectangle_4313" data-name="Rectangle 4313" width="42.413" height="30.835" transform="translate(-0.412 0.005) rotate(-0.779)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5568" data-name="Group 5568" transform="translate(26.845 56.068)">
                                    <g id="Group_5567" data-name="Group 5567" clipPath="url(#clipPath-40)">
                                          <rect id="Rectangle_4314" data-name="Rectangle 4314" width="31.058" height="43.384" transform="matrix(0.367, -0.93, 0.93, 0.367, -6.06, 15.351)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5576" data-name="Group 5576" transform="translate(0 0)">
                                    <g id="Group_5575" data-name="Group 5575" clipPath="url(#clipPath)">
                                          <g id="Group_5571" data-name="Group 5571" transform="translate(36.176 65.116)" opacity="0.74">
                                                <g id="Group_5570" data-name="Group 5570">
                                                      <g id="Group_5569" data-name="Group 5569" clipPath="url(#clipPath-42)">
                                                            <path id="Path_2794" data-name="Path 2794" d="M295.253,523.07a4.053,4.053,0,0,1-2.688-1.023l-5.455-4.816a.387.387,0,1,1,.512-.58l5.455,4.816a3.276,3.276,0,0,0,4.1.205L300,519.64a.387.387,0,1,1,.451.628l-2.827,2.032a4.062,4.062,0,0,1-2.375.77" transform="translate(-286.979 -516.554)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5574" data-name="Group 5574" transform="translate(33.412 62.676)" opacity="0.74">
                                                <g id="Group_5573" data-name="Group 5573">
                                                      <g id="Group_5572" data-name="Group 5572" clipPath="url(#clipPath-13)">
                                                            <path id="Path_2795" data-name="Path 2795" d="M266.176,498.621a.385.385,0,0,1-.256-.1l-.734-.648a.387.387,0,1,1,.512-.58l.734.648a.387.387,0,0,1-.256.676" transform="translate(-265.055 -497.199)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5578" data-name="Group 5578" transform="translate(0 25.397)">
                                    <g id="Group_5577" data-name="Group 5577" clipPath="url(#clipPath-44)">
                                          <rect id="Rectangle_4318" data-name="Rectangle 4318" width="31.649" height="42.99" transform="translate(-1.008 30.232) rotate(-88.091)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5580" data-name="Group 5580" transform="translate(0.813 25.397)">
                                    <g id="Group_5579" data-name="Group 5579" clipPath="url(#clipPath-45)">
                                          <rect id="Rectangle_4319" data-name="Rectangle 4319" width="28.717" height="43.12" transform="translate(-5.033 16.177) rotate(-72.719)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5588" data-name="Group 5588" transform="translate(0 0)">
                                    <g id="Group_5587" data-name="Group 5587" clipPath="url(#clipPath)">
                                          <g id="Group_5583" data-name="Group 5583" transform="translate(10.144 34.444)" opacity="0.74">
                                                <g id="Group_5582" data-name="Group 5582">
                                                      <g id="Group_5581" data-name="Group 5581" clipPath="url(#clipPath-12)">
                                                            <path id="Path_2798" data-name="Path 2798" d="M88.746,279.759a4.053,4.053,0,0,1-2.688-1.023L80.6,273.92a.387.387,0,0,1,.512-.58l5.455,4.816a3.276,3.276,0,0,0,4.1.205l2.827-2.032a.387.387,0,0,1,.451.628l-2.827,2.032a4.061,4.061,0,0,1-2.375.77" transform="translate(-80.472 -273.243)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5586" data-name="Group 5586" transform="translate(7.38 32.005)" opacity="0.74">
                                                <g id="Group_5585" data-name="Group 5585">
                                                      <g id="Group_5584" data-name="Group 5584" clipPath="url(#clipPath-48)">
                                                            <path id="Path_2799" data-name="Path 2799" d="M59.669,255.311a.384.384,0,0,1-.256-.1l-.734-.648a.387.387,0,1,1,.512-.58l.734.648a.387.387,0,0,1-.256.676" transform="translate(-58.548 -253.889)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5590" data-name="Group 5590" transform="translate(244.081 52.46)">
                                    <g id="Group_5589" data-name="Group 5589" clipPath="url(#clipPath-49)">
                                          <rect id="Rectangle_4323" data-name="Rectangle 4323" width="32.54" height="39.527" transform="translate(-5.898 23.364) rotate(-75.833)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5592" data-name="Group 5592" transform="translate(244.749 52.46)">
                                    <g id="Group_5591" data-name="Group 5591" clipPath="url(#clipPath-50)">
                                          <rect id="Rectangle_4324" data-name="Rectangle 4324" width="21.443" height="34.975" transform="translate(-3.159 13.85) rotate(-77.152)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5600" data-name="Group 5600" transform="translate(0 0)">
                                    <g id="Group_5599" data-name="Group 5599" clipPath="url(#clipPath)">
                                          <g id="Group_5595" data-name="Group 5595" transform="translate(252.411 59.889)" opacity="0.74">
                                                <g id="Group_5594" data-name="Group 5594">
                                                      <g id="Group_5593" data-name="Group 5593" clipPath="url(#clipPath-52)">
                                                            <path id="Path_2802" data-name="Path 2802" d="M2009.136,480.445a3.328,3.328,0,0,1-2.208-.84l-4.479-3.954a.318.318,0,0,1,.42-.476l4.479,3.954a2.69,2.69,0,0,0,3.367.168l2.321-1.668a.318.318,0,0,1,.371.516l-2.321,1.668a3.335,3.335,0,0,1-1.95.632" transform="translate(-2002.342 -475.094)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                          <g id="Group_5598" data-name="Group 5598" transform="translate(250.142 57.886)" opacity="0.74">
                                                <g id="Group_5597" data-name="Group 5597">
                                                      <g id="Group_5596" data-name="Group 5596" clipPath="url(#clipPath-53)">
                                                            <path id="Path_2803" data-name="Path 2803" d="M1985.26,460.368a.316.316,0,0,1-.21-.079l-.6-.532a.317.317,0,1,1,.42-.476l.6.532a.317.317,0,0,1-.21.555" transform="translate(-1984.339 -459.201)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5602" data-name="Group 5602" transform="translate(78.527 13.061)">
                                    <g id="Group_5601" data-name="Group 5601" clipPath="url(#clipPath-54)">
                                          <rect id="Rectangle_4328" data-name="Rectangle 4328" width="109.427" height="136.268" transform="translate(-23.453 41.549) rotate(-60.557)" fill="#d76d34" />
                                    </g>
                              </g>
                              <g id="Group_5604" data-name="Group 5604" transform="translate(76.309 57.87)">
                                    <g id="Group_5603" data-name="Group 5603" clipPath="url(#clipPath-55)">
                                          <rect id="Rectangle_4329" data-name="Rectangle 4329" width="100.756" height="136.514" transform="translate(-3.585 95.71) rotate(-87.855)" fill="#f6a734" />
                                    </g>
                              </g>
                              <g id="Group_5606" data-name="Group 5606" transform="translate(78.885 57.871)">
                                    <g id="Group_5605" data-name="Group 5605" clipPath="url(#clipPath-56)">
                                          <rect id="Rectangle_4330" data-name="Rectangle 4330" width="110.633" height="136.932" transform="translate(-24.052 42.611) rotate(-60.557)" fill="#d76d34" />
                                    </g>
                              </g>
                              <g id="Group_5608" data-name="Group 5608" transform="translate(79.622 19.048)">
                                    <g id="Group_5607" data-name="Group 5607" clipPath="url(#clipPath-57)">
                                          <path id="Path_2807" data-name="Path 2807" d="M846.738,296.814H754.467V208.87c0-3.366,3.13-6.147,7.135-6.34l77.131-3.729c4.345-.21,8.006,2.689,8.006,6.34Z" transform="translate(-738.983 -192.779)" fill="#91b3fa" />
                                          <path id="Path_2808" data-name="Path 2808" d="M865.471,343.323H779.514V261.4c0-3.135,2.915-5.726,6.646-5.906l71.852-3.474c4.048-.2,7.458,2.505,7.458,5.906Z" transform="translate(-760.872 -239.288)" fill="#bdd0fb" />
                                    </g>
                              </g>
                              <g id="Group_5610" data-name="Group 5610" transform="translate(0 0)">
                                    <g id="Group_5609" data-name="Group 5609" clipPath="url(#clipPath)">
                                          <path id="Path_2810" data-name="Path 2810" d="M434.967,1399.431c-1.8.156-4.934.409-7.4.518a7.126,7.126,0,0,1-2.829-.277c0,.056,0,.108,0,.156a.51.51,0,0,0,.485.482,86.661,86.661,0,0,0,9.691.01.363.363,0,0,0,.3-.546,2.957,2.957,0,0,0-.246-.343" transform="translate(-371.195 -1223.022)" fill="#7d97f4" />
                                    </g>
                              </g>
                              <g id="Group_5612" data-name="Group 5612" transform="translate(53.541 172.767)">
                                    <g id="Group_5611" data-name="Group 5611" clipPath="url(#clipPath-59)">
                                          <rect id="Rectangle_4332" data-name="Rectangle 4332" width="5.952" height="10.824" transform="matrix(0.175, -0.985, 0.985, 0.175, -0.739, 4.095)" fill="url(#linear-gradient-8)" />
                                    </g>
                              </g>
                              <g id="Group_5614" data-name="Group 5614" transform="translate(54.513 170.172)">
                                    <g id="Group_5613" data-name="Group 5613" clipPath="url(#clipPath-60)">
                                          <rect id="Rectangle_4333" data-name="Rectangle 4333" width="2.966" height="3.627" fill="url(#linear-gradient-9)" />
                                    </g>
                              </g>
                              <g id="Group_5616" data-name="Group 5616" transform="translate(0 0)">
                                    <g id="Group_5615" data-name="Group 5615" clipPath="url(#clipPath)">
                                          <path id="Path_2813" data-name="Path 2813" d="M259.549,1396.778a1.428,1.428,0,0,1-1.284-.95l0,.012a1.017,1.017,0,0,0,.685,1.159,4.475,4.475,0,0,0,1.9.3,1.885,1.885,0,0,0,1.422-.656l0-.006a4.773,4.773,0,0,1-2.721.144" transform="translate(-225.683 -1219.873)" fill="#7d97f4" />
                                    </g>
                              </g>
                              <g id="Group_5618" data-name="Group 5618" transform="translate(32.582 170.153)">
                                    <g id="Group_5617" data-name="Group 5617" clipPath="url(#clipPath-62)">
                                          <rect id="Rectangle_4335" data-name="Rectangle 4335" width="7.907" height="7.287" transform="translate(-1.195 6.721) rotate(-79.919)" fill="url(#linear-gradient-10)" />
                                    </g>
                              </g>
                              <g id="Group_5620" data-name="Group 5620" transform="translate(35.66 167.421)">
                                    <g id="Group_5619" data-name="Group 5619" clipPath="url(#clipPath-63)">
                                          <rect id="Rectangle_4336" data-name="Rectangle 4336" width="3.61" height="4.214" transform="translate(-0.066)" fill="url(#linear-gradient-11)" />
                                    </g>
                              </g>
                              <g id="Group_5622" data-name="Group 5622" transform="translate(35.543 127.093)">
                                    <g id="Group_5621" data-name="Group 5621" clipPath="url(#clipPath-64)">
                                          <rect id="Rectangle_4337" data-name="Rectangle 4337" width="46.374" height="52.648" transform="matrix(0.87, -0.493, 0.493, 0.87, -19.165, 10.873)" fill="url(#linear-gradient-12)" />
                                    </g>
                              </g>
                              <g id="Group_5624" data-name="Group 5624" transform="translate(50.628 97.63)">
                                    <g id="Group_5623" data-name="Group 5623" clipPath="url(#clipPath-65)">
                                          <rect id="Rectangle_4338" data-name="Rectangle 4338" width="33.296" height="39.703" transform="matrix(0.932, -0.362, 0.362, 0.932, -11.476, 4.424)" fill="url(#linear-gradient-13)" />
                                    </g>
                              </g>
                              <g id="Group_5626" data-name="Group 5626" transform="translate(54.731 135.276)">
                                    <g id="Group_5625" data-name="Group 5625" clipPath="url(#clipPath-66)">
                                          <rect id="Rectangle_4339" data-name="Rectangle 4339" width="5.923" height="6.208" transform="matrix(0.447, -0.894, 0.894, 0.447, -1.729, 3.362)" fill="url(#linear-gradient-14)" />
                                    </g>
                              </g>
                              <g id="Group_5628" data-name="Group 5628" transform="translate(44.602 116.767)">
                                    <g id="Group_5627" data-name="Group 5627" clipPath="url(#clipPath-67)">
                                          <rect id="Rectangle_4340" data-name="Rectangle 4340" width="11.67" height="7.684" transform="matrix(0.238, -0.971, 0.971, 0.238, -2.481, 10.112)" fill="url(#linear-gradient-15)" />
                                    </g>
                              </g>
                              <g id="Group_5630" data-name="Group 5630" transform="translate(44.428 97.805)">
                                    <g id="Group_5629" data-name="Group 5629" clipPath="url(#clipPath-68)">
                                          <rect id="Rectangle_4341" data-name="Rectangle 4341" width="17.887" height="22.33" transform="matrix(0.932, -0.362, 0.362, 0.932, -7.994, 2.542)" fill="url(#linear-gradient-16)" />
                                    </g>
                              </g>
                              <g id="Group_5632" data-name="Group 5632" transform="translate(69.24 104.657)">
                                    <g id="Group_5631" data-name="Group 5631" clipPath="url(#clipPath-69)">
                                          <rect id="Rectangle_4342" data-name="Rectangle 4342" width="13.091" height="18.184" transform="translate(-5.58 2.165) rotate(-21.204)" fill="url(#linear-gradient-17)" />
                                    </g>
                              </g>
                              <g id="Group_5634" data-name="Group 5634" transform="translate(75.548 115.72)">
                                    <g id="Group_5633" data-name="Group 5633" clipPath="url(#clipPath-70)">
                                          <rect id="Rectangle_4343" data-name="Rectangle 4343" width="9.387" height="10.659" transform="translate(-0.76 8.465) rotate(-84.872)" fill="url(#linear-gradient-18)" />
                                    </g>
                              </g>
                              <g id="Group_5636" data-name="Group 5636" transform="translate(62.182 92.388)">
                                    <g id="Group_5635" data-name="Group 5635" clipPath="url(#clipPath-71)">
                                          <rect id="Rectangle_4344" data-name="Rectangle 4344" width="12.527" height="11.733" transform="translate(-4.724 7.321) rotate(-58.782)" fill="url(#linear-gradient-19)" />
                                    </g>
                              </g>
                              <g id="Group_5638" data-name="Group 5638" transform="translate(38.273 113.697)">
                                    <g id="Group_5637" data-name="Group 5637" clipPath="url(#clipPath-72)">
                                          <rect id="Rectangle_4345" data-name="Rectangle 4345" width="52.591" height="25.444" transform="translate(-0.56 0)" fill="url(#linear-gradient-20)" />
                                    </g>
                              </g>
                              <g id="Group_5643" data-name="Group 5643" transform="translate(0 0)">
                                    <g id="Group_5642" data-name="Group 5642" clipPath="url(#clipPath)">
                                          <g id="Group_5641" data-name="Group 5641" transform="translate(87.441 79.657)" opacity="0.29">
                                                <g id="Group_5640" data-name="Group 5640">
                                                      <g id="Group_5639" data-name="Group 5639" clipPath="url(#clipPath-74)">
                                                            <path id="Path_2825" data-name="Path 2825" d="M741.412,655.359a23.884,23.884,0,1,1-23.88-23.449,23.667,23.667,0,0,1,23.88,23.449" transform="translate(-693.653 -631.91)" fill="#91b3fa" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5645" data-name="Group 5645" transform="translate(84.749 77.014)">
                                    <g id="Group_5644" data-name="Group 5644" clipPath="url(#clipPath-75)">
                                          <rect id="Rectangle_4348" data-name="Rectangle 4348" width="70.056" height="70.504" transform="translate(-20.376 42.391) rotate(-64.328)" fill="url(#linear-gradient-21)" />
                                    </g>
                              </g>
                              <g id="Group_5647" data-name="Group 5647" transform="translate(0 0)">
                                    <g id="Group_5646" data-name="Group 5646" clipPath="url(#clipPath)">
                                          <path id="Path_2827" data-name="Path 2827" d="M686.294,651.759a.129.129,0,0,1-.128-.112c-.079-.617-.134-1.245-.165-1.867a.129.129,0,0,1,.122-.135.125.125,0,0,1,.135.122c.03.616.085,1.237.163,1.847a.129.129,0,0,1-.112.144h-.017m.183-7.3-.023,0a.129.129,0,0,1-.1-.149,23.974,23.974,0,0,1,1.431-4.9,24.783,24.783,0,0,1,23.065-15.266,25.128,25.128,0,0,1,13.357,3.817.129.129,0,1,1-.137.218,24.868,24.868,0,0,0-13.22-3.778,24.526,24.526,0,0,0-22.827,15.107,23.719,23.719,0,0,0-1.415,4.843.129.129,0,0,1-.127.106" transform="translate(-599.526 -545.47)" fill="#91b3fa" />
                                    </g>
                              </g>
                              <g id="Group_5649" data-name="Group 5649" transform="translate(48.581 126.177)">
                                    <g id="Group_5648" data-name="Group 5648" clipPath="url(#clipPath-77)">
                                          <rect id="Rectangle_4350" data-name="Rectangle 4350" width="4.252" height="7.844" transform="matrix(0.238, -0.971, 0.971, 0.238, -0.59, 2.404)" fill="url(#linear-gradient-22)" />
                                    </g>
                              </g>
                              <g id="Group_5651" data-name="Group 5651" transform="translate(84.429 115.961)">
                                    <g id="Group_5650" data-name="Group 5650" clipPath="url(#clipPath-78)">
                                          <rect id="Rectangle_4351" data-name="Rectangle 4351" width="5.291" height="5.828" transform="matrix(0.379, -0.925, 0.925, 0.379, -2.423, 3.229)" fill="url(#linear-gradient-23)" />
                                    </g>
                              </g>
                              <g id="Group_5653" data-name="Group 5653" transform="translate(0 0)">
                                    <g id="Group_5652" data-name="Group 5652" clipPath="url(#clipPath)">
                                          <path id="Path_2830" data-name="Path 2830" d="M314.06,1039.306a.131.131,0,0,1-.042-.007c-1.5-.517-2.281-1.374-2.331-2.547a2.828,2.828,0,0,1,1.845-2.724l5.808-2.265a.129.129,0,1,1,.094.24l-5.808,2.265a2.57,2.57,0,0,0-1.681,2.473c.046,1.056.772,1.835,2.158,2.314a.129.129,0,0,1-.042.251" transform="translate(-272.394 -901.694)" fill="#91b3fa" />
                                          <path id="Path_2831" data-name="Path 2831" d="M386.09,1024.44a.129.129,0,0,1-.047-.249l.753-.293a.129.129,0,0,1,.094.24l-.753.293a.125.125,0,0,1-.047.009" transform="translate(-337.307 -894.819)" fill="#91b3fa" />
                                    </g>
                              </g>
                              <g id="Group_5655" data-name="Group 5655" transform="translate(63.688 87.447)">
                                    <g id="Group_5654" data-name="Group 5654" clipPath="url(#clipPath-80)">
                                          <rect id="Rectangle_4353" data-name="Rectangle 4353" width="10.901" height="11.317" transform="matrix(0.964, -0.264, 0.264, 0.964, -2.678, 0.652)" fill="url(#linear-gradient-24)" />
                                    </g>
                              </g>
                              <g id="Group_5657" data-name="Group 5657" transform="translate(60.885 83.526)">
                                    <g id="Group_5656" data-name="Group 5656" clipPath="url(#clipPath-81)">
                                          <rect id="Rectangle_4354" data-name="Rectangle 4354" width="16.186" height="16.03" transform="translate(-5.704 4.153) rotate(-37.836)" fill="url(#linear-gradient-25)" />
                                    </g>
                              </g>
                              <g id="Group_5659" data-name="Group 5659" transform="translate(63.797 91.055)">
                                    <g id="Group_5658" data-name="Group 5658" clipPath="url(#clipPath-82)">
                                          <rect id="Rectangle_4355" data-name="Rectangle 4355" width="3.289" height="3.371" transform="translate(-1.395 0.193) rotate(-15.322)" fill="url(#linear-gradient-26)" />
                                    </g>
                              </g>
                              <g id="Group_5661" data-name="Group 5661" transform="translate(263.277 172.309)">
                                    <g id="Group_5660" data-name="Group 5660" clipPath="url(#clipPath-83)">
                                          <rect id="Rectangle_4356" data-name="Rectangle 4356" width="7.779" height="6.752" transform="matrix(0.969, -0.247, 0.247, 0.969, -1.317, 0.322)" fill="url(#linear-gradient-27)" />
                                    </g>
                              </g>
                              <g id="Group_5663" data-name="Group 5663" transform="translate(266.538 170.109)">
                                    <g id="Group_5662" data-name="Group 5662" clipPath="url(#clipPath-84)">
                                          <rect id="Rectangle_4357" data-name="Rectangle 4357" width="2.682" height="3.54" fill="url(#linear-gradient-28)" />
                                    </g>
                              </g>
                              <g id="Group_5665" data-name="Group 5665" transform="translate(245.451 172.309)">
                                    <g id="Group_5664" data-name="Group 5664" clipPath="url(#clipPath-85)">
                                          <rect id="Rectangle_4358" data-name="Rectangle 4358" width="11.48" height="7.505" transform="translate(-1.37 0.309) rotate(-14.323)" fill="url(#linear-gradient-29)" />
                                    </g>
                              </g>
                              <g id="Group_5667" data-name="Group 5667" transform="translate(253.002 168.058)">
                                    <g id="Group_5666" data-name="Group 5666" clipPath="url(#clipPath-86)">
                                          <rect id="Rectangle_4359" data-name="Rectangle 4359" width="2.711" height="5.304" transform="translate(-0.044)" fill="url(#linear-gradient-30)" />
                                    </g>
                              </g>
                              <g id="Group_5669" data-name="Group 5669" transform="translate(252.702 122.561)">
                                    <g id="Group_5668" data-name="Group 5668" clipPath="url(#clipPath-87)">
                                          <rect id="Rectangle_4360" data-name="Rectangle 4360" width="43.919" height="50.727" transform="matrix(0.809, -0.587, 0.587, 0.809, -23.387, 16.967)" fill="url(#linear-gradient-31)" />
                                    </g>
                              </g>
                              <g id="Group_5671" data-name="Group 5671" transform="translate(263.154 131.125)">
                                    <g id="Group_5670" data-name="Group 5670" clipPath="url(#clipPath-88)">
                                          <rect id="Rectangle_4361" data-name="Rectangle 4361" width="3.671" height="5.734" transform="translate(-0.021)" fill="url(#linear-gradient-32)" />
                                    </g>
                              </g>
                              <g id="Group_5673" data-name="Group 5673" transform="translate(234.962 97.527)">
                                    <g id="Group_5672" data-name="Group 5672" clipPath="url(#clipPath-89)">
                                          <rect id="Rectangle_4362" data-name="Rectangle 4362" width="10.566" height="22.256" transform="translate(-0.269 9.968) rotate(-88.452)" fill="url(#linear-gradient-33)" />
                                    </g>
                              </g>
                              <g id="Group_5675" data-name="Group 5675" transform="translate(227.91 83.35)">
                                    <g id="Group_5674" data-name="Group 5674" clipPath="url(#clipPath-90)">
                                          <rect id="Rectangle_4363" data-name="Rectangle 4363" width="9.935" height="18.448" transform="translate(-0.022)" fill="url(#linear-gradient-34)" />
                                    </g>
                              </g>
                              <g id="Group_5677" data-name="Group 5677" transform="translate(231.345 96.647)">
                                    <g id="Group_5676" data-name="Group 5676" clipPath="url(#clipPath-91)">
                                          <rect id="Rectangle_4364" data-name="Rectangle 4364" width="46.206" height="48.474" transform="matrix(0.475, -0.88, 0.88, 0.475, -13.429, 24.905)" fill="url(#linear-gradient-35)" />
                                    </g>
                              </g>
                              <g id="Group_5679" data-name="Group 5679" transform="translate(223.238 108.341)">
                                    <g id="Group_5678" data-name="Group 5678" clipPath="url(#clipPath-92)">
                                          <rect id="Rectangle_4365" data-name="Rectangle 4365" width="12.587" height="12.442" transform="matrix(0.779, -0.626, 0.626, 0.779, -4.114, 3.307)" fill="url(#linear-gradient-36)" />
                                    </g>
                              </g>
                              <g id="Group_5681" data-name="Group 5681" transform="translate(212.881 106.805)">
                                    <g id="Group_5680" data-name="Group 5680" clipPath="url(#clipPath-93)">
                                          <rect id="Rectangle_4366" data-name="Rectangle 4366" width="11.365" height="10.085" transform="translate(-1.682 1.058) rotate(-38.792)" fill="url(#linear-gradient-37)" />
                                    </g>
                              </g>
                              <g id="Group_5683" data-name="Group 5683" transform="translate(244.927 89.635)">
                                    <g id="Group_5682" data-name="Group 5682" clipPath="url(#clipPath-94)">
                                          <rect id="Rectangle_4367" data-name="Rectangle 4367" width="11.584" height="12.391" transform="translate(-4.134 2.25) rotate(-28.557)" fill="url(#linear-gradient-38)" />
                                    </g>
                              </g>
                              <g id="Group_5685" data-name="Group 5685" transform="translate(242.371 84.136)">
                                    <g id="Group_5684" data-name="Group 5684" clipPath="url(#clipPath-95)">
                                          <rect id="Rectangle_4368" data-name="Rectangle 4368" width="7.83" height="9.602" transform="matrix(0.999, -0.033, 0.033, 0.999, -0.305, 0.01)" fill="url(#linear-gradient-39)" />
                                    </g>
                              </g>
                              <g id="Group_5687" data-name="Group 5687" transform="translate(241.65 81.141)">
                                    <g id="Group_5686" data-name="Group 5686" clipPath="url(#clipPath-96)">
                                          <rect id="Rectangle_4369" data-name="Rectangle 4369" width="15.605" height="17.534" transform="translate(-5.041 2.019) rotate(-21.93)" fill="url(#linear-gradient-40)" />
                                    </g>
                              </g>
                              <g id="Group_5689" data-name="Group 5689" transform="translate(248.502 89.577)">
                                    <g id="Group_5688" data-name="Group 5688" clipPath="url(#clipPath-97)">
                                          <rect id="Rectangle_4370" data-name="Rectangle 4370" width="3.792" height="4.228" transform="translate(-0.585 0.093) rotate(-9.048)" fill="url(#linear-gradient-41)" />
                                    </g>
                              </g>
                              <g id="Group_5691" data-name="Group 5691" transform="translate(142.596 78.572)">
                                    <g id="Group_5690" data-name="Group 5690" clipPath="url(#clipPath-98)">
                                          <rect id="Rectangle_4371" data-name="Rectangle 4371" width="56.757" height="91.133" transform="translate(-9.985 25.319) rotate(-69.864)" fill="#d76d34" />
                                    </g>
                              </g>
                              <g id="Group_5696" data-name="Group 5696" transform="translate(0 0)">
                                    <g id="Group_5695" data-name="Group 5695" clipPath="url(#clipPath)">
                                          <g id="Group_5694" data-name="Group 5694" transform="translate(202.212 83.52)" opacity="0.92">
                                                <g id="Group_5693" data-name="Group 5693">
                                                      <g id="Group_5692" data-name="Group 5692" clipPath="url(#clipPath-100)">
                                                            <path id="Path_2851" data-name="Path 2851" d="M1608.576,662.552h9.366a4.387,4.387,0,0,1,4.456,4.315v9.07a4.387,4.387,0,0,1-4.456,4.315h-9.366a4.387,4.387,0,0,1-4.456-4.315v-9.07a4.387,4.387,0,0,1,4.456-4.315" transform="translate(-1604.12 -662.552)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                              <g id="Group_5698" data-name="Group 5698" transform="translate(206.256 85.992)">
                                    <g id="Group_5697" data-name="Group 5697" clipPath="url(#clipPath-101)">
                                          <rect id="Rectangle_4374" data-name="Rectangle 4374" width="16.187" height="15.317" transform="translate(-5.644 9.348) rotate(-58.877)" fill="url(#linear-gradient-42)" />
                                    </g>
                              </g>
                              <g id="Group_5703" data-name="Group 5703" transform="translate(0 0)">
                                    <g id="Group_5702" data-name="Group 5702" clipPath="url(#clipPath)">
                                          <g id="Group_5701" data-name="Group 5701" transform="translate(159.059 86.649)" opacity="0.87">
                                                <g id="Group_5700" data-name="Group 5700">
                                                      <g id="Group_5699" data-name="Group 5699" clipPath="url(#clipPath-103)">
                                                            <path id="Path_2853" data-name="Path 2853" d="M1269.5,689.852l-1.026.277a.326.326,0,0,1-.085.011h-.419a.322.322,0,0,1-.295-.19,5.077,5.077,0,0,0-.243-.479,3.83,3.83,0,0,0-.423-.6,1.544,1.544,0,0,0-.483-.367,1.987,1.987,0,0,0-.621-.174.348.348,0,0,0-.095,0,3.262,3.262,0,0,0-.571.137,3.1,3.1,0,0,0-.567.259,1.215,1.215,0,0,0-.474.468,1.32,1.32,0,0,0-.153.64,1.26,1.26,0,0,0,.161.64,1.391,1.391,0,0,0,.483.482q.339.187.72.374t.8.345c.022.01.048.019.076.029a.234.234,0,0,0,.076.014c.022.01.048.019.076.029l.076.029.161.058q.076.029.144.058a8.5,8.5,0,0,1,.991.439,7.674,7.674,0,0,1,.906.554,1.868,1.868,0,0,1,.652.755,2.551,2.551,0,0,1,.212,1.086,2.325,2.325,0,0,1-.254,1.1,2.35,2.35,0,0,1-.745.827,3.745,3.745,0,0,1-1.093.511,4.683,4.683,0,0,1-1.279.165h-.677a5.765,5.765,0,0,1-1.448-.165,2.782,2.782,0,0,1-1.076-.525,3.658,3.658,0,0,1-.788-.856,4.306,4.306,0,0,1-.408-.781.323.323,0,0,1,.229-.432l.761-.181a.331.331,0,0,1,.075-.009h.408a.324.324,0,0,1,.3.21,2.971,2.971,0,0,0,.3.6,3.689,3.689,0,0,0,.559.662,2.489,2.489,0,0,0,.644.4,2.662,2.662,0,0,0,.742.19.382.382,0,0,0,.075,0,2.254,2.254,0,0,0,.64-.148,2.221,2.221,0,0,0,.559-.324,1.6,1.6,0,0,0,.457-.568,1.685,1.685,0,0,0,.152-.726,1.307,1.307,0,0,0-.711-1.223q-.356-.2-.754-.381t-.838-.338a.586.586,0,0,0-.059-.021.518.518,0,0,1-.059-.022.152.152,0,0,1-.042-.007.47.47,0,0,1-.059-.022.117.117,0,0,0-.042-.022.45.45,0,0,1-.059-.022.155.155,0,0,1-.042-.007.487.487,0,0,1-.059-.022,7.543,7.543,0,0,1-.991-.4q-.466-.23-.906-.5a1.829,1.829,0,0,1-.635-.726,2.375,2.375,0,0,1-.212-1.043,2.135,2.135,0,0,1,.246-1.021,2.388,2.388,0,0,1,.72-.806,3.7,3.7,0,0,1,1.059-.518,4.078,4.078,0,0,1,1.194-.173l.677-.014a4.3,4.3,0,0,1,1.245.194,2.425,2.425,0,0,1,.94.554,4.293,4.293,0,0,1,.678.8,5.407,5.407,0,0,1,.491.942Z" transform="translate(-1261.792 -687.378)" fill="#fff" />
                                                            <path id="Path_2854" data-name="Path 2854" d="M1338.843,688.747a.335.335,0,0,1,.075.01.729.729,0,0,0,.166.019h.44a.373.373,0,0,0,.1.014h.1q.135,0,.28.007c.1,0,.195.012.3.022l.279.029.279.029q.288.043.551.108a3.549,3.549,0,0,1,.533.18,2.851,2.851,0,0,1,.644.36,2.435,2.435,0,0,1,.508.518,2.123,2.123,0,0,1,.347.626,2.022,2.022,0,0,1,.11.655,2.4,2.4,0,0,1-.127.777,2.869,2.869,0,0,1-.381.734,2.492,2.492,0,0,1-.6.6,2.63,2.63,0,0,1-.8.374,3.306,3.306,0,0,1-.381.093q-.2.036-.4.065c-.135.019-.268.036-.4.05a3.472,3.472,0,0,1-.381.021,1.958,1.958,0,0,1-.237.014h-.237l-.659-.014-.036,0a1.965,1.965,0,0,1-.219.012h-.237l-.174,0a.325.325,0,0,0-.334.325v3.642a.325.325,0,0,1-.325.325h-1.213a.325.325,0,0,1-.325-.325v-8.945a.325.325,0,0,1,.325-.325h2.432Zm-.894,4.091a.325.325,0,0,0,.325.325h.708v.014a6.309,6.309,0,0,0,.974-.1,4.544,4.544,0,0,0,.889-.259,1.217,1.217,0,0,0,.635-.5,1.627,1.627,0,0,0,.212-.863,1.813,1.813,0,0,0-.22-.942,1.225,1.225,0,0,0-.661-.525,5.711,5.711,0,0,0-.923-.245,6.889,6.889,0,0,0-1.025-.1h-.576a.325.325,0,0,0-.339.325Z" transform="translate(-1326.72 -688.575)" fill="#fff" />
                                                            <path id="Path_2855" data-name="Path 2855" d="M1408.48,697.914a.325.325,0,0,1-.308.429h-1.211a.325.325,0,0,1-.308-.221l-.884-2.622a.325.325,0,0,0-.308-.221h-2.51a.325.325,0,0,0-.309.226l-.838,2.613a.325.325,0,0,1-.31.226h-1.21a.325.325,0,0,1-.308-.427l2.969-8.945a.325.325,0,0,1,.308-.223h1.9a.325.325,0,0,1,.308.221Zm-4.6-6.4-.738,2.453a.325.325,0,0,0,.311.419h1.509a.325.325,0,0,0,.31-.422l-.77-2.453a.325.325,0,0,0-.621,0" transform="translate(-1382.542 -688.575)" fill="#fff" />
                                                            <path id="Path_2856" data-name="Path 2856" d="M1485.409,698.018a.325.325,0,0,1-.325.325h-1.213a.325.325,0,0,1-.325-.325v-4.164a.325.325,0,0,0-.634-.1l-1.394,4.362a.325.325,0,0,1-.31.226h-1.136a.325.325,0,0,1-.309-.224l-1.444-4.433a.325.325,0,0,0-.634.1v4.231a.325.325,0,0,1-.325.325h-1.213a.325.325,0,0,1-.325-.325v-8.945a.325.325,0,0,1,.325-.325h2.081a.325.325,0,0,1,.309.225l1.858,5.731q.067.23.119.439t.1.41a.2.2,0,0,1,.008-.057.263.263,0,0,1,.025-.058.261.261,0,0,0,.025-.057.2.2,0,0,0,.009-.057c.011-.048.022-.094.034-.137s.023-.084.034-.122a.532.532,0,0,1,.017-.137c.011-.043.022-.084.034-.122l1.842-5.829a.325.325,0,0,1,.31-.227h2.13a.325.325,0,0,1,.325.325Z" transform="translate(-1448.842 -688.575)" fill="#fff" />
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                        </g>
                  </svg>

            )}
      </Icon>
);
