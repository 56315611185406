import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppCampaignsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={32.002} baseHeight={35.757} {...props}>
    {(width, height, color = '#79d7ee') => (
      <svg width={width} height={height} viewBox="0 0 32.002 35.757">
        <g
          id="Group_195"
          data-name="Group 195"
          transform="translate(-102.91 -1991.035)"
        >
          <g
            id="Group_37"
            data-name="Group 37"
            transform="translate(103.91 1992.035)"
          >
            <line
              id="Line_53"
              data-name="Line 53"
              x1="20.254"
              transform="translate(0 4.051)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_54"
              data-name="Line 54"
              x1="20.254"
              transform="translate(0 29.706)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M144.923,50.081v2.7a2.7,2.7,0,0,1-2.7,2.7H127.37a2.7,2.7,0,0,1-2.7-2.7V24.426a2.7,2.7,0,0,1,2.7-2.7h14.853a2.7,2.7,0,0,1,2.7,2.7v4.051"
              transform="translate(-124.669 -21.725)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
          <g
            id="Group_40"
            data-name="Group 40"
            transform="translate(111.493 1998.539)"
          >
            <g
              id="Group_39"
              data-name="Group 39"
              transform="translate(7.971 7.971)"
            >
              <g
                id="Group_38"
                data-name="Group 38"
              >
                <path
                  id="Path_91"
                  data-name="Path 91"
                  d="M158.766,46.641l-12.132-4.474L151.108,54.3l2.186-3.29,5.193,5.192,2.181-2.181-5.192-5.193Z"
                  transform="translate(-146.634 -42.167)"
                  fill="none"
                  stroke={color}
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
            <line
              id="Line_55"
              data-name="Line 55"
              x2="2.218"
              y2="2.218"
              transform="translate(2.735 2.735)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_56"
              data-name="Line 56"
              x2="3.137"
              transform="translate(0 9.338)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_57"
              data-name="Line 57"
              y1="2.218"
              x2="2.218"
              transform="translate(2.735 13.724)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
            <line
              id="Line_58"
              data-name="Line 58"
              y2="3.137"
              transform="translate(9.338 0)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2" />
            <line
              id="Line_59"
              data-name="Line 59"
              x1="2.218"
              y2="2.218"
              transform="translate(13.724 2.735)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
