import React, {FC, useEffect, useState} from "react";
import { Country } from "react-phone-number-input";
import {useSelector} from "react-redux";
import {Autocomplete, Input} from "@mui/material";
import classnames from "classnames";
import {IAbstractInputControlProps, InputWrapper} from "../ReactiveForm";
import {ICountry} from "../../utils/interfaces";

export interface ICountrySelectProps {
  value?: { iso: Country, code: string};
  defaultValue?: string;
  placeholder?: string;
}

export const CountrySelect: FC<ICountrySelectProps & IAbstractInputControlProps> = ({
  name = '',
  value,
  defaultValue,
  placeholder = '',
  onChange = () => {},
  ...wrapperProps
}) => {
  const countries = JSON.parse(sessionStorage.getItem("countries")) as ICountry[] || [];
  const [country, setCountry] = useState<ICountry>();

  useEffect(() => {
    setCountry(countries.find(item => item.iso_code === value?.iso));
  }, [value]);

  const onCountrySelect = (country) => {
    setCountry(country);
    onChange({ iso: country?.iso_code, code: country?.code});
  };

  return (
    <InputWrapper {...wrapperProps}>
      {({ className, containerClass, disabled, onFocus, onBlur }) => (
        <Autocomplete
          data-cy="country-select-wrapper"
          className={containerClass}
          classes={{ inputRoot: className }}
          renderInput={({InputLabelProps, InputProps, ...props}) => {
            let startAdornment = null;
            if (country) {
              startAdornment = (
                <div className="flex items-center ml-4">
                  <div className="w-8 h-5 bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(/assets/images/flags/${country.iso_code.toLowerCase()}.png)` }} />
                  <span className="text-right ml-3">+{country.code}</span>
                </div>
              );
            }
            return (
              <Input
                {...props}
                {...InputProps}
                placeholder={placeholder}
                startAdornment={startAdornment}
              />
            );
          }}
          value={country || null}
          options={countries}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            return (
              <li {...props} data-cy="country-select-item" className={classnames('flex items-center', props.className)}>
                <div className="w-8 h-5 bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(/assets/images/flags/${option.iso_code.toLowerCase()}.png)` }} />
                <span className="w-12 text-xs text-right mr-3">+{option.code}</span>
                <span className="text-15p">{option.name}</span>
              </li>
            );
          }}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e, value: ICountry) => onCountrySelect(value)}
        />
      )}
    </InputWrapper>
  );
};
