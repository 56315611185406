import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Chip, Chips, WithTooltipIcon } from '../../../../../components';
import { PlusCircleIcon } from '../../../../../components/icons';
import AddGA4 from '../../../../../parts/AddGA4';
import { NumberModel } from '../../../../../utils/types';
import { INumberEditForm } from '../..';
import { FormGroup } from '../../../../../components/ReactiveForm';

export interface IGA4Props {
  number: NumberModel;
  form: FormGroup<INumberEditForm>;
  formData: any;
  initialForm: any;
  onUpdate(data: any): void;
}

const GA4: FC<IGA4Props> = (
  {
    number,
    form,
    formData,
    initialForm,
    onUpdate,
  }
) => {
  const [showGA4, setShowGA4] = useState(false);

  useEffect(() => {
    form.patch({
      third_party_integrations: number.third_party_integrations || [],
    });
    initialForm.third_party_integrations = number.third_party_integrations || []
  }, [number]);



  const onSetGA4 = (third_party_integrations) => {
    form.controls.third_party_integrations.patch(third_party_integrations);
    onSave();
  };

  const onRemoveGA4 = (third_party_integrations) => {
    form.controls.third_party_integrations.patch(formData.third_party_integrations.filter((item) => third_party_integrations !== item));
    onSave();
  };

  const onSave = () => {
    if (!form.controls.third_party_integrations.validate()) {
      return;
    }

    const formData = form.getFormData();

    onUpdate({
      third_party_integrations: formData.third_party_integrations,
    });
  };


  return (
    <>
      <Card
        size="sm"
        header={(
          <WithTooltipIcon
              tooltip={`You can set up event tracking by giving us the Data Studio information we require. The benifits of using Google Analytics is that you are able to measure more interactions and leads`}
              placement="right"
              iconClass="ml-4"
            >
              <Button
            className="text-blue px-0"
            leftIcon={<PlusCircleIcon />}
            onClick={() => setShowGA4(true)}
          >

            Enable Integration
          </Button>
            </WithTooltipIcon>

        )}
      >
        {!!formData.third_party_integrations?.length && (
          <Chips className="mt-3">
            {formData.third_party_integrations.map((item, i) => (
              <Chip key={i} size="sm" active clickable={false} removable onRemove={() => onRemoveGA4(item)}>
                {item}
              </Chip>
            ))}
          </Chips>
        )}
      </Card>

      {showGA4 && (
        <AddGA4
          ga4s={formData.third_party_integrations}
          onSubmit={onSetGA4}
          onClose={() => setShowGA4(false)}
        />
      )}
    </>
  )
}

export default GA4;

