import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WhatsAppCampaignStep2Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={302.08} baseHeight={169.195} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 302.08 169.195">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_4614" data-name="Rectangle 4614" width="302.08" height="169.195" fill="none" />
          </clipPath>
          <clipPath id="clip-path-5">
            <rect id="Rectangle_4545" data-name="Rectangle 4545" width="14.339" height="1.409" fill="none" />
          </clipPath>
          <clipPath id="clip-path-9">
            <rect id="Rectangle_4550" data-name="Rectangle 4550" width="4.617" height="4.936" fill="none" />
          </clipPath>
          <clipPath id="clip-path-10">
            <rect id="Rectangle_4551" data-name="Rectangle 4551" width="1.397" height="1.959" fill="none" />
          </clipPath>
          <clipPath id="clip-path-11">
            <rect id="Rectangle_4552" data-name="Rectangle 4552" width="2.102" height="6.92" fill="none" />
          </clipPath>
          <clipPath id="clip-path-12">
            <rect id="Rectangle_4553" data-name="Rectangle 4553" width="26.429" height="22.038" fill="none" />
          </clipPath>
          <clipPath id="clip-path-13">
            <rect id="Rectangle_4554" data-name="Rectangle 4554" width="7.865" height="11.644" fill="none" />
          </clipPath>
          <clipPath id="clip-path-14">
            <rect id="Rectangle_4555" data-name="Rectangle 4555" width="8.892" height="4.574" fill="none" />
          </clipPath>
          <clipPath id="clip-path-15">
            <rect id="Rectangle_4556" data-name="Rectangle 4556" width="2.046" height="1.121" fill="none" />
          </clipPath>
          <clipPath id="clip-path-16">
            <rect id="Rectangle_4557" data-name="Rectangle 4557" width="41.442" height="52.05" fill="none" />
          </clipPath>
          <clipPath id="clip-path-17">
            <rect id="Rectangle_4558" data-name="Rectangle 4558" width="2.055" height="0.421" fill="none" />
          </clipPath>
          <clipPath id="clip-path-18">
            <rect id="Rectangle_4559" data-name="Rectangle 4559" width="2.001" height="0.259" fill="none" />
          </clipPath>
          <clipPath id="clip-path-19">
            <rect id="Rectangle_4560" data-name="Rectangle 4560" width="1.515" height="0.18" fill="none" />
          </clipPath>
          <clipPath id="clip-path-20">
            <rect id="Rectangle_4561" data-name="Rectangle 4561" width="2.054" height="0.421" fill="none" />
          </clipPath>
          <clipPath id="clip-path-22">
            <rect id="Rectangle_4563" data-name="Rectangle 4563" width="1.514" height="0.18" fill="none" />
          </clipPath>
          <clipPath id="clip-path-24">
            <rect id="Rectangle_4565" data-name="Rectangle 4565" width="5.213" height="1.791" fill="none" />
          </clipPath>
          <clipPath id="clip-path-26">
            <rect id="Rectangle_4567" data-name="Rectangle 4567" width="21.374" height="74.109" fill="none" />
          </clipPath>
          <clipPath id="clip-path-27">
            <rect id="Rectangle_4568" data-name="Rectangle 4568" width="34.142" height="78.632" fill="none" />
          </clipPath>
          <clipPath id="clip-path-28">
            <rect id="Rectangle_4569" data-name="Rectangle 4569" width="4.085" height="17.057" fill="none" />
          </clipPath>
          <clipPath id="clip-path-29">
            <rect id="Rectangle_4570" data-name="Rectangle 4570" width="2.688" height="4.697" fill="none" />
          </clipPath>
          <clipPath id="clip-path-30">
            <rect id="Rectangle_4571" data-name="Rectangle 4571" width="1.829" height="1.748" fill="none" />
          </clipPath>
          <clipPath id="clip-path-31">
            <rect id="Rectangle_4572" data-name="Rectangle 4572" width="15.141" height="27.384" fill="none" />
          </clipPath>
          <clipPath id="clip-path-32">
            <rect id="Rectangle_4573" data-name="Rectangle 4573" width="1.278" height="9.434" fill="none" />
          </clipPath>
          <clipPath id="clip-path-33">
            <rect id="Rectangle_4574" data-name="Rectangle 4574" width="26.511" height="37.762" fill="none" />
          </clipPath>
          <clipPath id="clip-path-34">
            <rect id="Rectangle_4575" data-name="Rectangle 4575" width="7.422" height="8.556" fill="none" />
          </clipPath>
          <clipPath id="clip-path-35">
            <rect id="Rectangle_4576" data-name="Rectangle 4576" width="15.854" height="18.822" fill="none" />
          </clipPath>
          <clipPath id="clip-path-36">
            <rect id="Rectangle_4577" data-name="Rectangle 4577" width="1.543" height="0.657" fill="none" />
          </clipPath>
          <clipPath id="clip-path-37">
            <rect id="Rectangle_4578" data-name="Rectangle 4578" width="1.429" height="0.766" fill="none" />
          </clipPath>
          <clipPath id="clip-path-38">
            <rect id="Rectangle_4579" data-name="Rectangle 4579" width="1.066" height="0.629" fill="none" />
          </clipPath>
          <clipPath id="clip-path-39">
            <rect id="Rectangle_4580" data-name="Rectangle 4580" width="8.8" height="4.275" fill="none" />
          </clipPath>
          <clipPath id="clip-path-40">
            <rect id="Rectangle_4581" data-name="Rectangle 4581" width="13.741" height="13.964" fill="none" />
          </clipPath>
          <clipPath id="clip-path-41">
            <rect id="Rectangle_4582" data-name="Rectangle 4582" width="1.783" height="7.125" fill="none" />
          </clipPath>
          <clipPath id="clip-path-42">
            <rect id="Rectangle_4583" data-name="Rectangle 4583" width="20.854" height="23.261" fill="none" />
          </clipPath>
          <clipPath id="clip-path-44">
            <rect id="Rectangle_4585" data-name="Rectangle 4585" width="41.501" height="52.05" fill="none" />
          </clipPath>
          <clipPath id="clip-path-45">
            <rect id="Rectangle_4586" data-name="Rectangle 4586" width="6.891" height="4.842" fill="none" />
          </clipPath>
          <clipPath id="clip-path-47">
            <rect id="Rectangle_4588" data-name="Rectangle 4588" width="14.16" height="8.563" fill="none" />
          </clipPath>
          <clipPath id="clip-path-48">
            <rect id="Rectangle_4589" data-name="Rectangle 4589" width="53.033" height="41.686" fill="none" />
          </clipPath>
          <clipPath id="clip-path-49">
            <rect id="Rectangle_4590" data-name="Rectangle 4590" width="16.419" height="1.442" fill="none" />
          </clipPath>
          <clipPath id="clip-path-50">
            <rect id="Rectangle_4591" data-name="Rectangle 4591" width="33.134" height="34.546" fill="none" />
          </clipPath>
          <clipPath id="clip-path-51">
            <rect id="Rectangle_4592" data-name="Rectangle 4592" width="7.913" height="14.52" fill="none" />
          </clipPath>
          <clipPath id="clip-path-52">
            <rect id="Rectangle_4593" data-name="Rectangle 4593" width="5.474" height="16.986" fill="none" />
          </clipPath>
          <clipPath id="clip-path-53">
            <rect id="Rectangle_4594" data-name="Rectangle 4594" width="45.233" height="27.661" fill="none" />
          </clipPath>
          <clipPath id="clip-path-54">
            <rect id="Rectangle_4595" data-name="Rectangle 4595" width="22.161" height="1.563" fill="none" />
          </clipPath>
          <clipPath id="clip-path-55">
            <rect id="Rectangle_4596" data-name="Rectangle 4596" width="12.41" height="2.166" fill="none" />
          </clipPath>
          <clipPath id="clip-path-56">
            <rect id="Rectangle_4597" data-name="Rectangle 4597" width="3.361" height="3.423" fill="none" />
          </clipPath>
          <clipPath id="clip-path-57">
            <rect id="Rectangle_4598" data-name="Rectangle 4598" width="1.789" height="1.92" fill="none" />
          </clipPath>
          <clipPath id="clip-path-58">
            <rect id="Rectangle_4599" data-name="Rectangle 4599" width="8.738" height="4.96" fill="none" />
          </clipPath>
          <clipPath id="clip-path-59">
            <rect id="Rectangle_4600" data-name="Rectangle 4600" width="13.462" height="15.742" fill="none" />
          </clipPath>
          <clipPath id="clip-path-60">
            <rect id="Rectangle_4601" data-name="Rectangle 4601" width="11.9" height="11.739" fill="none" />
          </clipPath>
          <clipPath id="clip-path-61">
            <rect id="Rectangle_4602" data-name="Rectangle 4602" width="7.24" height="6.102" fill="none" />
          </clipPath>
          <clipPath id="clip-path-64">
            <rect id="Rectangle_4605" data-name="Rectangle 4605" width="7.417" height="19.163" fill="none" />
          </clipPath>
          <clipPath id="clip-path-65">
            <rect id="Rectangle_4606" data-name="Rectangle 4606" width="5.52" height="1.935" fill="none" />
          </clipPath>
          <clipPath id="clip-path-66">
            <rect id="Rectangle_4607" data-name="Rectangle 4607" width="12.336" height="20.362" fill="none" />
          </clipPath>
          <clipPath id="clip-path-67">
            <rect id="Rectangle_4608" data-name="Rectangle 4608" width="28.437" height="49.888" fill="none" />
          </clipPath>
          <clipPath id="clip-path-68">
            <rect id="Rectangle_4609" data-name="Rectangle 4609" width="9.21" height="2.11" fill="none" />
          </clipPath>
          <clipPath id="clip-path-69">
            <rect id="Rectangle_4610" data-name="Rectangle 4610" width="4.507" height="14.888" fill="none" />
          </clipPath>
          <clipPath id="clip-path-70">
            <rect id="Rectangle_4611" data-name="Rectangle 4611" width="2.199" height="10.477" fill="none" />
          </clipPath>
          <clipPath id="clip-path-71">
            <rect id="Rectangle_4612" data-name="Rectangle 4612" width="14.976" height="20.901" fill="none" />
          </clipPath>
        </defs>
        <g id="Group_5972" data-name="Group 5972" clipPath="url(#clip-path)">
          <rect id="Rectangle_4528" data-name="Rectangle 4528" width="22.858" height="43.093" transform="translate(228.698 7.195)" fill="#e6e6e6" />
          <rect id="Rectangle_4529" data-name="Rectangle 4529" width="26.851" height="43.093" transform="translate(223.584 7.195)" fill="#f0f0f0" />
          <rect id="Rectangle_4530" data-name="Rectangle 4530" width="18.676" height="35.438" transform="translate(227.665 11.023)" fill="#fff" />
          <rect id="Rectangle_4531" data-name="Rectangle 4531" width="7.261" height="13.774" transform="translate(233.606 21.592)" fill="#ebebeb" />
          <rect id="Rectangle_4532" data-name="Rectangle 4532" width="22.858" height="43.093" transform="translate(49.847 7.195)" fill="#e6e6e6" />
          <rect id="Rectangle_4533" data-name="Rectangle 4533" width="26.851" height="43.093" transform="translate(44.733 7.195)" fill="#f0f0f0" />
          <rect id="Rectangle_4534" data-name="Rectangle 4534" width="18.676" height="35.438" transform="translate(48.814 11.023)" fill="#fff" />
          <rect id="Rectangle_4535" data-name="Rectangle 4535" width="7.261" height="13.774" transform="translate(54.755 21.592)" fill="#ebebeb" />
          <g id="Group_5773" data-name="Group 5773">
            <g id="Group_5772" data-name="Group 5772" clipPath="url(#clip-path)">
              <path id="Path_3166" data-name="Path 3166" d="M562.287,110.761h-72.8a2.364,2.364,0,0,1-2.361-2.361V2.361A2.364,2.364,0,0,1,489.487,0h72.8a2.364,2.364,0,0,1,2.361,2.361V108.4a2.364,2.364,0,0,1-2.361,2.361M489.487.1a2.263,2.263,0,0,0-2.26,2.26V108.4a2.263,2.263,0,0,0,2.26,2.26h72.8a2.263,2.263,0,0,0,2.26-2.26V2.361A2.263,2.263,0,0,0,562.287.1Z" transform="translate(-290.925)" fill="#e0e0e0" />
              <path id="Path_3167" data-name="Path 3167" d="M353.859,110.761h-72.8A2.364,2.364,0,0,1,278.7,108.4V2.361A2.364,2.364,0,0,1,281.059,0h72.8a2.364,2.364,0,0,1,2.361,2.361V108.4a2.364,2.364,0,0,1-2.361,2.361M281.059.1a2.263,2.263,0,0,0-2.26,2.26V108.4a2.263,2.263,0,0,0,2.26,2.26h72.8a2.263,2.263,0,0,0,2.26-2.26V2.361A2.263,2.263,0,0,0,353.859.1Z" transform="translate(-166.446)" fill="#e0e0e0" />
              <path id="Path_3168" data-name="Path 3168" d="M145.565,110.761h-72.8A2.364,2.364,0,0,1,70.4,108.4V2.361A2.364,2.364,0,0,1,72.765,0h72.8a2.364,2.364,0,0,1,2.361,2.361V108.4a2.364,2.364,0,0,1-2.361,2.361M72.765.1a2.263,2.263,0,0,0-2.26,2.26V108.4a2.263,2.263,0,0,0,2.26,2.26h72.8a2.263,2.263,0,0,0,2.261-2.26V2.361A2.263,2.263,0,0,0,145.565.1Z" transform="translate(-42.047)" fill="#e0e0e0" />
              <rect id="Rectangle_4536" data-name="Rectangle 4536" width="302.08" height="0.048" transform="translate(0 135.359)" fill="#e0e0e0" />
              <rect id="Rectangle_4537" data-name="Rectangle 4537" width="22.446" height="0.17" transform="translate(263.263 147.191)" fill="#ebebeb" />
              <rect id="Rectangle_4538" data-name="Rectangle 4538" width="5.891" height="0.17" transform="translate(199.391 149.03)" fill="#ebebeb" />
              <rect id="Rectangle_4539" data-name="Rectangle 4539" width="29.271" height="0.17" transform="translate(16.371 142.036)" fill="#ebebeb" />
              <rect id="Rectangle_4540" data-name="Rectangle 4540" width="4.292" height="0.17" transform="translate(51.675 142.036)" fill="#ebebeb" />
              <rect id="Rectangle_4541" data-name="Rectangle 4541" width="63.482" height="0.17" transform="translate(69.916 144.898)" fill="#ebebeb" />
              <path id="Path_3169" data-name="Path 3169" d="M231.325,50.561a14.347,14.347,0,0,0-2.051,4.968l-2.774.261v5.373l2.775.261a14.354,14.354,0,0,0,2.051,4.968l-1.772,2.14,3.8,3.8,2.141-1.772a14.349,14.349,0,0,0,4.968,2.05l.261,2.775H246.1l.261-2.774a14.368,14.368,0,0,0,4.969-2.051l2.14,1.772,3.8-3.8-1.772-2.14a14.361,14.361,0,0,0,2.05-4.968l2.774-.261V55.791l-2.774-.262a14.36,14.36,0,0,0-2.051-4.968l1.772-2.141-3.8-3.8-2.14,1.772a14.36,14.36,0,0,0-4.968-2.051l-.261-2.775h-5.373l-.261,2.774a14.351,14.351,0,0,0-4.968,2.051l-2.141-1.772-3.8,3.8Zm17.227,2.773a7.274,7.274,0,1,1-10.287,0,7.274,7.274,0,0,1,10.287,0" transform="translate(-135.272 -24.826)" fill="#ebebeb" />
              <path id="Path_3170" data-name="Path 3170" d="M260.934,218.149a7.174,7.174,0,0,0-1.025,2.483l-1.387.131v2.685l1.387.13a7.166,7.166,0,0,0,1.025,2.483l-.886,1.07,1.9,1.9,1.07-.886a7.172,7.172,0,0,0,2.483,1.025l.13,1.387h2.685l.13-1.386a7.173,7.173,0,0,0,2.484-1.025l1.069.886,1.9-1.9-.886-1.07a7.167,7.167,0,0,0,1.025-2.483l1.387-.13v-2.685l-1.387-.131a7.167,7.167,0,0,0-1.025-2.483l.886-1.07-1.9-1.9-1.07.886a7.175,7.175,0,0,0-2.483-1.025l-.13-1.387h-2.685l-.13,1.386a7.172,7.172,0,0,0-2.483,1.025l-1.07-.886-1.9,1.9Zm8.61,1.386a3.635,3.635,0,1,1-5.141,0,3.635,3.635,0,0,1,5.141,0" transform="translate(-154.397 -127.6)" fill="#ebebeb" />
              <path id="Path_3171" data-name="Path 3171" d="M68.811,144.695a11.331,11.331,0,0,0-1.618,3.922l-2.19.206v4.242l2.191.206a11.324,11.324,0,0,0,1.618,3.922l-1.4,1.69,3,3,1.689-1.4a11.338,11.338,0,0,0,3.922,1.618l.206,2.19h4.241l.206-2.19a11.335,11.335,0,0,0,3.922-1.618l1.69,1.4,3-3-1.4-1.69a11.34,11.34,0,0,0,1.618-3.922l2.19-.206v-4.242l-2.19-.207a11.328,11.328,0,0,0-1.619-3.921l1.4-1.69-3-3-1.69,1.4a11.341,11.341,0,0,0-3.922-1.619l-.206-2.19H76.229l-.206,2.19a11.318,11.318,0,0,0-3.922,1.618l-1.69-1.4-3,3Zm13.6,2.189a5.742,5.742,0,1,1-8.12,0,5.743,5.743,0,0,1,8.12,0" transform="translate(-38.821 -82.176)" fill="#ebebeb" />
              <path id="Path_3172" data-name="Path 3172" d="M644.246,128.776a5.309,5.309,0,0,0-.758,1.836l-1.025.1v1.985l1.025.1a5.309,5.309,0,0,0,.758,1.836l-.655.791,1.4,1.4.791-.655a5.3,5.3,0,0,0,1.836.757l.1,1.025H649.7l.1-1.025a5.3,5.3,0,0,0,1.835-.758l.791.655,1.4-1.4-.655-.791a5.3,5.3,0,0,0,.757-1.835l1.025-.1v-1.985l-1.025-.1a5.3,5.3,0,0,0-.758-1.835l.655-.791-1.4-1.4-.791.655a5.307,5.307,0,0,0-1.835-.758l-.1-1.025h-1.985l-.1,1.025a5.3,5.3,0,0,0-1.836.757l-.791-.655-1.4,1.4Zm6.365,1.025a2.687,2.687,0,1,1-3.8,0,2.688,2.688,0,0,1,3.8,0" transform="translate(-383.696 -74.924)" fill="#ebebeb" />
              <path id="Path_3173" data-name="Path 3173" d="M456.3,67.133a5.308,5.308,0,0,0-.758,1.836l-1.025.1v1.985l1.025.1a5.308,5.308,0,0,0,.758,1.836l-.654.791,1.4,1.4.791-.655a5.3,5.3,0,0,0,1.836.757l.1,1.025h1.985l.1-1.025a5.308,5.308,0,0,0,1.836-.758l.791.655,1.4-1.4-.655-.791a5.3,5.3,0,0,0,.757-1.836l1.025-.1V69.066l-1.025-.1a5.287,5.287,0,0,0-.758-1.835l.655-.791-1.4-1.4-.791.655a5.306,5.306,0,0,0-1.835-.758l-.1-1.025h-1.985l-.1,1.025a5.3,5.3,0,0,0-1.836.757l-.791-.655-1.4,1.4Zm6.365,1.025a2.687,2.687,0,1,1-3.8,0,2.688,2.688,0,0,1,3.8,0" transform="translate(-271.447 -38.11)" fill="#f5f5f5" />
              <path id="Path_3174" data-name="Path 3174" d="M126.433,208.6a5.308,5.308,0,0,0-.758,1.836l-1.025.1v1.985l1.025.1a5.308,5.308,0,0,0,.758,1.836l-.655.791,1.4,1.4.791-.655a5.3,5.3,0,0,0,1.836.757l.1,1.025h1.985l.1-1.025a5.308,5.308,0,0,0,1.836-.758l.791.655,1.4-1.4-.654-.791a5.294,5.294,0,0,0,.757-1.835l1.025-.1v-1.985l-1.025-.1a5.287,5.287,0,0,0-.758-1.835l.655-.791-1.4-1.4-.791.655a5.306,5.306,0,0,0-1.835-.758l-.1-1.025H129.9l-.1,1.025a5.3,5.3,0,0,0-1.836.757l-.791-.655-1.4,1.4Zm6.365,1.025a2.687,2.687,0,1,1-3.8,0,2.688,2.688,0,0,1,3.8,0" transform="translate(-74.444 -122.595)" fill="#f5f5f5" />
              <path id="Path_3175" data-name="Path 3175" d="M570.452,145.117a9.105,9.105,0,0,0-1.3,3.155l-1.762.166v3.412l1.762.166a9.111,9.111,0,0,0,1.3,3.155l-1.125,1.359,2.413,2.412,1.359-1.125a9.121,9.121,0,0,0,3.155,1.3l.166,1.762h3.411l.166-1.762a9.118,9.118,0,0,0,3.155-1.3l1.359,1.125,2.413-2.412-1.125-1.359a9.115,9.115,0,0,0,1.3-3.155l1.761-.166v-3.412l-1.762-.166a9.108,9.108,0,0,0-1.3-3.155l1.125-1.359-2.413-2.412-1.359,1.125a9.122,9.122,0,0,0-3.155-1.3l-.166-1.762h-3.412l-.166,1.762a9.108,9.108,0,0,0-3.155,1.3l-1.359-1.125-2.413,2.413Zm10.939,1.761a4.619,4.619,0,1,1-6.532,0,4.619,4.619,0,0,1,6.532,0" transform="translate(-338.859 -83.257)" fill="#ebebeb" />
              <path id="Path_3176" data-name="Path 3176" d="M402.063,192.544a9.191,9.191,0,0,0-1.312,3.179l-1.775.168v3.438l1.775.167a9.18,9.18,0,0,0,1.312,3.179l-1.134,1.37,2.432,2.431,1.37-1.134a9.18,9.18,0,0,0,3.179,1.312l.167,1.776h3.438l.167-1.775a9.188,9.188,0,0,0,3.18-1.312l1.37,1.134,2.432-2.431-1.134-1.37a9.187,9.187,0,0,0,1.312-3.179l1.775-.167v-3.438l-1.775-.168a9.189,9.189,0,0,0-1.312-3.179l1.134-1.37-2.431-2.432-1.37,1.134a9.181,9.181,0,0,0-3.179-1.312l-.168-1.775h-3.438l-.167,1.775a9.181,9.181,0,0,0-3.179,1.312l-1.37-1.134-2.432,2.432Zm11.024,1.775a4.655,4.655,0,1,1-6.583,0,4.655,4.655,0,0,1,6.583,0" transform="translate(-238.278 -111.556)" fill="#ebebeb" />
              <path id="Path_3177" data-name="Path 3177" d="M423.978,267.223a15.406,15.406,0,0,0-2.2,5.336l-2.98.281v5.77l2.98.281a15.418,15.418,0,0,0,2.2,5.336l-1.9,2.3,4.08,4.08,2.3-1.9a15.4,15.4,0,0,0,5.336,2.2l.281,2.98h5.77l.281-2.979a15.419,15.419,0,0,0,5.336-2.2l2.3,1.9,4.08-4.08-1.9-2.3a15.419,15.419,0,0,0,2.2-5.336l2.979-.281v-5.77l-2.98-.281a15.413,15.413,0,0,0-2.2-5.335l1.9-2.3-4.081-4.08-2.3,1.9a15.427,15.427,0,0,0-5.336-2.2l-.281-2.98h-5.77l-.281,2.979a15.415,15.415,0,0,0-5.335,2.2l-2.3-1.9-4.08,4.081Zm18.5,2.978a7.812,7.812,0,1,1-11.047,0,7.812,7.812,0,0,1,11.047,0" transform="translate(-250.116 -153.825)" fill="#ebebeb" />
            </g>
          </g>
          <g id="Group_5775" data-name="Group 5775">
            <g id="Group_5774" data-name="Group 5774" clipPath="url(#clip-path)">
              <path id="Path_3178" data-name="Path 3178" d="M323.648,397.392c0,1.364-56.9,2.47-127.08,2.47s-127.08-1.106-127.08-2.47,56.9-2.47,127.08-2.47,127.08,1.106,127.08,2.47" transform="translate(-41.5 -235.858)" fill="#f5f5f5" />
            </g>
          </g>
          <g id="Group_5780" data-name="Group 5780">
            <g id="Group_5779" data-name="Group 5779" clipPath="url(#clip-path)">
              <path id="Path_3179" data-name="Path 3179" d="M106.046,226.816a32.06,32.06,0,0,0-5.37,31.834c4.578-4.086,7.8-10.314,8.006-15.761s-1.222-10.809-2.636-16.073" transform="translate(-58.889 -135.461)" fill="#407bff" />
              <path id="Path_3180" data-name="Path 3180" d="M108.86,296.017a21.091,21.091,0,0,0-3.339,13.016c3.971-1.382,7.471-5.867,9.569-9.511a47.523,47.523,0,0,0,4.339-11.8,21.09,21.09,0,0,0-10.57,8.3" transform="translate(-62.985 -171.835)" fill="#407bff" />
              <path id="Path_3181" data-name="Path 3181" d="M105.033,263.909a121.425,121.425,0,0,0-4.445,32.611q0,4.7.374,9.383c.015.193.317.195.3,0a121.129,121.129,0,0,1,1.91-32.87q.9-4.561,2.15-9.043a.151.151,0,0,0-.291-.08" transform="translate(-60.074 -157.55)" fill="#263238" />
              <path id="Path_3182" data-name="Path 3182" d="M101.272,319.3a100.35,100.35,0,0,1,11.558-20.182c.114-.157-.147-.308-.261-.152a103.276,103.276,0,0,0-9.474,15.58q-1.123,2.307-2.114,4.674c-.075.179.217.257.291.08" transform="translate(-60.301 -178.514)" fill="#263238" />
              <path id="Path_3183" data-name="Path 3183" d="M78.039,312.834a17.586,17.586,0,0,1,2.784,10.853c-3.311-1.153-6.23-4.892-7.979-7.93a39.627,39.627,0,0,1-3.619-9.841,17.587,17.587,0,0,1,8.813,6.918" transform="translate(-41.344 -182.701)" fill="#407bff" />
              <path id="Path_3184" data-name="Path 3184" d="M86.991,331.735A81.985,81.985,0,0,0,77.5,315.249c-.113-.155-.376,0-.261.153A81.683,81.683,0,0,1,86.7,331.815c.074.177.367.1.291-.08" transform="translate(-46.11 -188.236)" fill="#263238" />
              <path id="Path_3185" data-name="Path 3185" d="M101.407,339.5c4.672-4.807,9.443-9.517,14.115-14.323.136-.14-.078-.354-.213-.214-4.672,4.807-9.443,9.517-14.115,14.324-.136.139.078.353.213.214" transform="translate(-60.41 -194.051)" fill="#263238" />
              <path id="Path_3186" data-name="Path 3186" d="M120.932,320.082a91.962,91.962,0,0,0-11.017,12.776h.742a10.945,10.945,0,0,0,10.276-12.777" transform="translate(-65.644 -191.161)" fill="#407bff" />
              <path id="Path_3187" data-name="Path 3187" d="M89.594,296.6q2.611,4.27,4.917,8.714c.089.173.35.02.261-.152q-2.3-4.442-4.917-8.715c-.1-.166-.363-.014-.261.153" transform="translate(-53.494 -177.005)" fill="#263238" />
              <path id="Path_3188" data-name="Path 3188" d="M77.237,261.254a50.69,50.69,0,0,0-1.329,9.977,12.827,12.827,0,0,0,2.708,8.226c1.767,2.083,5.333,3,6.771,1.8Z" transform="translate(-45.334 -156.028)" fill="#407bff" />
              <rect id="Rectangle_4544" data-name="Rectangle 4544" width="17.708" height="3.282" transform="translate(31.812 146.84)" fill="#263238" />
              <path id="Path_3189" data-name="Path 3189" d="M96.558,383.3h-11l-2-12.443H98.137Z" transform="translate(-49.907 -221.484)" fill="#263238" />
              <g id="Group_5778" data-name="Group 5778" transform="translate(33.788 150.183)" opacity="0.2">
                <g id="Group_5777" data-name="Group 5777">
                  <g id="Group_5776" data-name="Group 5776" clipPath="url(#clip-path-5)">
                    <path id="Path_3190" data-name="Path 3190" d="M83.888,372.873H98.227l-.179,1.408H84.115Z" transform="translate(-83.888 -372.873)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_5782" data-name="Group 5782">
            <g id="Group_5781" data-name="Group 5781" clipPath="url(#clip-path)">
              <path id="Path_3191" data-name="Path 3191" d="M628.2,186.662a9.331,9.331,0,1,1-1.275,15.313l-2.768.435.658-2.887c-.027-.045-.058-.085-.083-.13a9.33,9.33,0,0,1,3.468-12.731" transform="translate(-372.373 -110.743)" fill="#407bff" />
              <path id="Path_3192" data-name="Path 3192" d="M629.326,183.354a9.249,9.249,0,0,0-6.72,5.564" transform="translate(-371.837 -109.504)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3193" data-name="Path 3193" d="M620.948,200.424a3.378,3.378,0,0,0-.336,1.337" transform="translate(-370.647 -119.699)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3194" data-name="Path 3194" d="M638.119,207.207l-3.938-3.594,1.254-1.375,2.641,2.41,4.388-4.285,1.3,1.331Z" transform="translate(-378.749 -119.663)" fill="#fff" />
            </g>
          </g>
          <g id="Group_5784" data-name="Group 5784">
            <g id="Group_5783" data-name="Group 5783" clipPath="url(#clip-path)">
              <path id="Path_3195" data-name="Path 3195" d="M389.313,38.59a9.842,9.842,0,1,1-1.232,16.161l-2.916.48.673-3.05c-.028-.047-.061-.089-.089-.137a9.842,9.842,0,0,1,3.565-13.454" transform="translate(-229.582 -22.25)" fill="#407bff" />
              <path id="Path_3196" data-name="Path 3196" d="M417.615,38.08a9.756,9.756,0,0,0-8.368-3.83" transform="translate(-244.413 -20.441)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3197" data-name="Path 3197" d="M403.512,34.676a3.557,3.557,0,0,0-1.424.295" transform="translate(-240.138 -20.709)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <rect id="Rectangle_4548" data-name="Rectangle 4548" width="9.193" height="2.298" transform="translate(159.75 23.435)" fill="#fff" />
              <path id="Path_3198" data-name="Path 3198" d="M417.339,60.11V55.466l2.264,2.322Z" transform="translate(-249.246 -33.126)" fill="#fff" />
            </g>
          </g>
          <g id="Group_5828" data-name="Group 5828">
            <g id="Group_5827" data-name="Group 5827" clipPath="url(#clip-path)">
              <path id="Path_3199" data-name="Path 3199" d="M503.133,183.571a61.626,61.626,0,0,1-2.3,11.611,117.358,117.358,0,0,1-4.7,13.432c-1.027,2.473-2.207,5.1-3.561,7.861-.609,1.255-1.261,2.533-1.946,3.843-.788,22.374-13.345,45.118-13.345,45.118-.7.359-3.126-.033-3.109-.74,1.859-2.952,5.518-31.621,7.877-45.221.147-1.848.359-3.805.62-5.876.69-5.49,1.712-11.459,2.718-16.71,1.56-8.274,3.044-14.758,3.044-14.758l4.136.4Z" transform="translate(-283.187 -108.773)" fill="#eec1bb" />
              <path id="Path_3200" data-name="Path 3200" d="M467.444,393.746h-.707l-.2-.891-2.392,1.087h-6.061s-.962-.967,1.919-1.734a2.435,2.435,0,0,0,.408-.158,16.873,16.873,0,0,0,3.724-3.158h3.321a3.7,3.7,0,0,1,.272.484,4.2,4.2,0,0,1-.283,4.37" transform="translate(-273.487 -232.257)" fill="#263238" />
              <path id="Path_3201" data-name="Path 3201" d="M471.406,387.935h0c-.435-.489-2.43,1.658-3.892,2.392-1.3.647-2.772.935-3.425.277,1.539-.723,4.278-4.106,4.278-4.106l2.348.236s.538.886.69,1.2" transform="translate(-277.166 -230.827)" fill="#eec1bb" />
              <path id="Path_3202" data-name="Path 3202" d="M535.007,212.852h0q-.5,3.609-1.288,7.724c4.485,14.965-4.74,44.172-4.74,44.172a4.885,4.885,0,0,1-3.642-.418c1.74-8.8.489-31.681-.864-45.352-.12-1.239-.271-2.6-.435-4.06-.277-2.468-.609-5.186-.962-8-.6-4.7-1.267-9.649-1.87-14.035-.739-5.3-1.381-9.785-1.712-12.041-.136-.989-.218-1.549-.218-1.549l12.948,1.4s3.294,3.691,3.8,15.22a94.3,94.3,0,0,1-1.022,16.933" transform="translate(-310.125 -107.08)" fill="#eec1bb" />
              <path id="Path_3203" data-name="Path 3203" d="M530.046,393.746h-.723l-.2-.891-2.386,1.087H520.68s-.962-.967,1.924-1.734a2.423,2.423,0,0,0,.408-.158,17.006,17.006,0,0,0,3.724-3.158h3.321a4.739,4.739,0,0,1,.272.484,4.239,4.239,0,0,1-.283,4.37" transform="translate(-310.872 -232.257)" fill="#263238" />
              <path id="Path_3204" data-name="Path 3204" d="M534.015,388.284h0c-.44-.489-2.435,1.658-3.9,2.392-1.3.647-2.772.935-3.419.278,1.533-.723,3.952-3.871,3.952-3.871h2.669s.539.886.7,1.2" transform="translate(-314.558 -231.176)" fill="#eec1bb" />
              <path id="Path_3205" data-name="Path 3205" d="M546.448,87.482c-.665-.574-1.739-.354-2.653-.462a4.208,4.208,0,0,1-3.1-2.3,12.049,12.049,0,0,1-.984-3.654,8.331,8.331,0,0,0-1.25-3.573c-.762-1.055-2.6-2.363-3.931-1.563l-1.786,2.767a83.841,83.841,0,0,1,2.609,9.868,8.246,8.246,0,0,0,1.672,4.217,6.566,6.566,0,0,0,4.373,1.794,5.6,5.6,0,0,0,2.455-.309,2.525,2.525,0,0,0,1.577-1.64,1.63,1.63,0,0,0-1.01-1.864,5.2,5.2,0,0,0,2.022-1.244,1.466,1.466,0,0,0,.011-2.034" transform="translate(-318.166 -45.198)" fill="#263238" />
              <path id="Path_3206" data-name="Path 3206" d="M500.162,67.326a6.433,6.433,0,0,0-1.975,5.935,40.775,40.775,0,0,0,2.7,5.546l3.457-13.815a8.773,8.773,0,0,0-4.18,2.334" transform="translate(-297.461 -38.815)" fill="#263238" />
              <path id="Path_3207" data-name="Path 3207" d="M518.55,64.792c3.985.081,7.986,3.413,8.3,6.646s-2.867,7.406-3.464,7.662l-1.887.694s2.082-4.814,1.218-6.1-1.49-1.3-1.49-1.3Z" transform="translate(-309.692 -38.696)" fill="#263238" />
              <path id="Path_3208" data-name="Path 3208" d="M505.533,65.487c2.073-1.466,4.522-.628,5.975,0a6.617,6.617,0,0,1,3.173,4.4c.317,1.738.7,3.845-.188,4.943a2.858,2.858,0,0,1-2.033,1.089L502.42,69.548a10.192,10.192,0,0,1,3.113-4.061" transform="translate(-300.059 -38.624)" fill="#263238" />
              <path id="Path_3209" data-name="Path 3209" d="M516.372,108.354c.785.561,1.347.224,2.163-.3,4.905-2.844,2.89-5.1,2.89-5.1-1.208-.987-1.507-2.377-1.532-4.573a1.345,1.345,0,0,0-.026-.188,1.4,1.4,0,0,0-.406-.744,1.43,1.43,0,0,0-2.023-.011v0l-2.228,1.1c0,.033,0,.067,0,.1.206,1.166.4,2.065.525,2.778a1.784,1.784,0,0,0,.041.26,1.414,1.414,0,0,0,.037.264c.121.985-1.267,2.717-1.877,3.179-.365.307-.225,1.332,2.437,3.222" transform="translate(-306.848 -57.95)" fill="#eec1bb" />
              <g id="Group_5787" data-name="Group 5787" transform="translate(208.365 39.05)" opacity="0.2">
                <g id="Group_5786" data-name="Group 5786">
                  <g id="Group_5785" data-name="Group 5785" clipPath="url(#clip-path-9)">
                    <path id="Path_3210" data-name="Path 3210" d="M517.327,98.465h0a.34.34,0,0,1,0,.109c.206,1.166.4,2.065.526,2.778a1.777,1.777,0,0,1,.041.26,2.029,2.029,0,0,1,.016.276,9.2,9.2,0,0,0,4.034-3.758,1.464,1.464,0,0,0-.385-.743,1.435,1.435,0,0,0-2.024-.035Z" transform="translate(-517.325 -96.952)" />
                  </g>
                </g>
              </g>
              <path id="Path_3211" data-name="Path 3211" d="M513.436,72.448c.642,4.035,1,5.723-.51,8.252-2.278,3.8-7.252,3.8-9.078.062-1.615-3.374-2.411-9.39,1.041-11.924a5.355,5.355,0,0,1,8.546,3.61" transform="translate(-300.087 -40.455)" fill="#eec1bb" />
              <path id="Path_3212" data-name="Path 3212" d="M530.582,84a3.31,3.31,0,0,1-1.523,2.024c-.98.549-1.98-.994-1.907-2.144a2.19,2.19,0,0,1,2.008-2.014A1.575,1.575,0,0,1,530.582,84" transform="translate(-314.827 -48.893)" fill="#eec1bb" />
              <g id="Group_5790" data-name="Group 5790" transform="translate(213.347 33.643)" opacity="0.2">
                <g id="Group_5789" data-name="Group 5789">
                  <g id="Group_5788" data-name="Group 5788" clipPath="url(#clip-path-10)">
                    <path id="Path_3213" data-name="Path 3213" d="M529.975,85.536a1.495,1.495,0,0,1,1.167-1.759" transform="translate(-529.846 -83.677)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
                  </g>
                </g>
              </g>
              <path id="Path_3214" data-name="Path 3214" d="M516.045,85.141c.094.425-.04.832-.3.908s-.547-.207-.641-.632.04-.832.3-.908.547.207.641.632" transform="translate(-307.616 -50.463)" fill="#263238" />
              <path id="Path_3215" data-name="Path 3215" d="M506.321,87.779c.091.412-.046.808-.3.884s-.542-.2-.633-.609.046-.808.3-.884.542.2.633.609" transform="translate(-301.812 -52.052)" fill="#263238" />
              <path id="Path_3216" data-name="Path 3216" d="M515.815,81.011a.184.184,0,0,1-.153-.007,1.484,1.484,0,0,0-1.407-.011.186.186,0,0,1-.265-.07.22.22,0,0,1,.079-.284,1.851,1.851,0,0,1,1.779-.008.207.207,0,0,1,.078.276.214.214,0,0,1-.111.1" transform="translate(-306.954 -48.022)" fill="#263238" />
              <path id="Path_3217" data-name="Path 3217" d="M502.917,83.28a.153.153,0,0,1-.114-.062.221.221,0,0,1,.029-.282,1.7,1.7,0,0,1,1.58-.541.187.187,0,0,1,.111.24.193.193,0,0,1-.224.147,1.36,1.36,0,0,0-1.248.443.176.176,0,0,1-.135.054" transform="translate(-300.265 -49.189)" fill="#263238" />
              <path id="Path_3218" data-name="Path 3218" d="M509.244,87.5a13.955,13.955,0,0,1-.669,2.771.318.318,0,0,0,.271.43,2.036,2.036,0,0,0,1.069-.271Z" transform="translate(-303.721 -52.26)" fill="#d4827d" />
              <path id="Path_3219" data-name="Path 3219" d="M514.838,84.469l-.97.031s.683.545.97-.031" transform="translate(-306.896 -50.447)" fill="#263238" />
              <path id="Path_3220" data-name="Path 3220" d="M504.983,87.149l-.97.031s.683.545.97-.031" transform="translate(-301.01 -52.048)" fill="#263238" />
              <path id="Path_3221" data-name="Path 3221" d="M511.283,73.293s-2.774-2.2-3.254-4.435c0,0-8.546-.326-6.5,6.625,0,0-2.892-5.807,3.636-8.031s9.51,6.288,7.025,9.9c-.018-.029.81-2.672-.91-4.057" transform="translate(-299.147 -40.067)" fill="#263238" />
              <path id="Path_3222" data-name="Path 3222" d="M502.683,68.512a5.853,5.853,0,0,0-1.11,3.467c.159.874,1.464,1.29,1.464,1.29l1.106-2.171.305,1.675a5.385,5.385,0,0,0,3.961-4.183l-1.759-2.128s-3.3,1.222-3.969,2.051" transform="translate(-299.553 -39.692)" fill="#263238" />
              <path id="Path_3223" data-name="Path 3223" d="M516.072,95.121a.871.871,0,0,0,1-.978" transform="translate(-308.212 -56.225)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <g id="Group_5793" data-name="Group 5793" transform="translate(215.823 30.669)" opacity="0.2">
                <g id="Group_5792" data-name="Group 5792">
                  <g id="Group_5791" data-name="Group 5791" clipPath="url(#clip-path-11)">
                    <path id="Path_3224" data-name="Path 3224" d="M535.841,83.064s3.012-3.736.653-6.92A3.5,3.5,0,0,1,537.9,79.4a5.244,5.244,0,0,1-2.061,3.661" transform="translate(-535.841 -76.144)" />
                  </g>
                </g>
              </g>
              <g id="Group_5796" data-name="Group 5796" transform="translate(199.485 85.8)" opacity="0.2">
                <g id="Group_5795" data-name="Group 5795">
                  <g id="Group_5794" data-name="Group 5794" clipPath="url(#clip-path-12)">
                    <path id="Path_3225" data-name="Path 3225" d="M520.675,232.994h0a40.153,40.153,0,0,1-10.97,2.066c-.278-2.468-.609-5.186-.962-8-1.027,2.473-2.207,5.1-3.561,7.86a27.087,27.087,0,0,1-9.9-2.87c.69-5.49,1.712-11.459,2.718-16.71a16.2,16.2,0,0,1,4.4-1.777,19.54,19.54,0,0,1,4.49-.544,34.34,34.34,0,0,1,6.566.6,41.4,41.4,0,0,1,8.2,2.435,94.29,94.29,0,0,1-.984,16.933" transform="translate(-495.279 -213.022)" />
                  </g>
                </g>
              </g>
              <path id="Path_3226" data-name="Path 3226" d="M499.922,179.3h17.194l.467,2.218s6.317,8.741,3.115,30.539c0,0-13.834,1.6-26.864-1.2,0,0,2.925-22.4,5.98-29.707Z" transform="translate(-294.931 -107.08)" fill="#263238" />
              <path id="Path_3227" data-name="Path 3227" d="M518.959,131.187l1.222,9.143-18.849-.76c-.05-1.964,1.7-7.177,1.5-8.6-.572-1.761-3.468-4.418-4.238-6.546-.822-2.954.875-11.077,1.429-11.335.671-.312,1.508-.284,2.022-.511,2-.471,6.553-2.1,8.592-2.35.4-.037.787-.108,1.177-.153,0,0,7.712-.371,8.053,2.767a69.147,69.147,0,0,0-.907,18.347" transform="translate(-297.642 -65.73)" fill="#407bff" />
              <g id="Group_5799" data-name="Group 5799" transform="translate(213.303 53.233)" opacity="0.2">
                <g id="Group_5798" data-name="Group 5798">
                  <g id="Group_5797" data-name="Group 5797" clipPath="url(#clip-path-13)">
                    <path id="Path_3228" data-name="Path 3228" d="M537.452,142.449l-.153-2.5-2.857-7.779,2.238,9.159-7.093,2.485Z" transform="translate(-529.586 -132.165)" />
                  </g>
                </g>
              </g>
              <g id="Group_5802" data-name="Group 5802" transform="translate(212.959 67.102)" opacity="0.2">
                <g id="Group_5801" data-name="Group 5801">
                  <g id="Group_5800" data-name="Group 5800" clipPath="url(#clip-path-14)">
                    <path id="Path_3229" data-name="Path 3229" d="M528.732,171.175a26.568,26.568,0,0,0,2.49-2.464l6.4.242-.309-2.315c.19-.15-7.481.2-7.481.2l-1.1,2.39Z" transform="translate(-528.732 -166.601)" />
                  </g>
                </g>
              </g>
              <path id="Path_3230" data-name="Path 3230" d="M535.9,111.825c.33.041,1.67-.718,4.091,2.39s5.133,14.957,5.286,16.686a3.093,3.093,0,0,1-2.728,3.519,116.114,116.114,0,0,1-12.457.868v-3.722l8.062-1.825s-4.575-11.013-3.853-13.85a13.172,13.172,0,0,1,1.6-4.067" transform="translate(-316.582 -66.753)" fill="#407bff" />
              <path id="Path_3231" data-name="Path 3231" d="M519.367,110.016a8.192,8.192,0,0,1-6.064,6.45,3.925,3.925,0,0,1-3.178-1.058,4.742,4.742,0,0,1-1.642-2.877c3.794-1.5,7.231-2.778,10.884-2.514" transform="translate(-303.68 -65.683)" fill="#eec1bb" />
              <g id="Group_5805" data-name="Group 5805" transform="translate(221.386 62.508)" opacity="0.2">
                <g id="Group_5804" data-name="Group 5804">
                  <g id="Group_5803" data-name="Group 5803" clipPath="url(#clip-path-15)">
                    <path id="Path_3232" data-name="Path 3232" d="M549.834,155.675a2.511,2.511,0,0,1,1.866.64,1.637,1.637,0,0,0-2.046-1.081Z" transform="translate(-549.654 -155.194)" />
                  </g>
                </g>
              </g>
              <path id="Path_3233" data-name="Path 3233" d="M462.173,154.143v-41.5H420.731v16.581a9.387,9.387,0,0,0,3.952.2c1.308-.487.455-1.995,1.717-2.589,1.863-.878,4.184.359,5.161,2.172a7.208,7.208,0,0,1,0,6.717c-.977,1.813-3.3,3.049-5.161,2.172-1.263-.594-.236-2.064-1.544-2.552a10.2,10.2,0,0,0-4.125.157v18.646h18.532a2.939,2.939,0,0,1-.167,1.193c-.487,1.308-1.878,2.086-2.472,3.349-.877,1.863.359,4.185,2.172,5.161a7.205,7.205,0,0,0,6.717,0c1.813-.976,3.049-3.3,2.172-5.161-.595-1.263-1.985-2.041-2.472-3.349a2.939,2.939,0,0,1-.167-1.193Z" transform="translate(-251.272 -67.273)" fill="#407bff" />
              <path id="Path_3234" data-name="Path 3234" d="M467.282,154.143v-41.5H425.84v16.581a3.3,3.3,0,0,0,2.32.079c1.308-.487,2.086-1.878,3.349-2.472,1.863-.878,4.184.359,5.161,2.172a7.208,7.208,0,0,1,0,6.717c-.977,1.813-3.3,3.049-5.161,2.172-1.263-.594-2.041-1.985-3.349-2.472a3.3,3.3,0,0,0-2.32.078v18.646h18.532a2.939,2.939,0,0,1-.167,1.193c-.487,1.308-1.878,2.086-2.472,3.349-.877,1.863.359,4.185,2.172,5.161a7.205,7.205,0,0,0,6.717,0c1.813-.976,3.049-3.3,2.172-5.161-.594-1.263-1.985-2.041-2.472-3.349a2.94,2.94,0,0,1-.167-1.193Z" transform="translate(-254.323 -67.273)" fill="#407bff" />
              <g id="Group_5808" data-name="Group 5808" transform="translate(171.517 45.369)" opacity="0.3">
                <g id="Group_5807" data-name="Group 5807">
                  <g id="Group_5806" data-name="Group 5806" clipPath="url(#clip-path-16)">
                    <path id="Path_3235" data-name="Path 3235" d="M467.282,154.143v-41.5H425.84v16.581a3.3,3.3,0,0,0,2.32.079c1.308-.487,2.086-1.878,3.349-2.472,1.863-.878,4.184.359,5.161,2.172a7.208,7.208,0,0,1,0,6.717c-.977,1.813-3.3,3.049-5.161,2.172-1.263-.594-2.041-1.985-3.349-2.472a3.3,3.3,0,0,0-2.32.078v18.646h18.532a2.939,2.939,0,0,1-.167,1.193c-.487,1.308-1.878,2.086-2.472,3.349-.877,1.863.359,4.185,2.172,5.161a7.205,7.205,0,0,0,6.717,0c1.813-.976,3.049-3.3,2.172-5.161-.594-1.263-1.985-2.041-2.472-3.349a2.94,2.94,0,0,1-.167-1.193Z" transform="translate(-425.84 -112.642)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3236" data-name="Path 3236" d="M523.538,159.452s-2.782.7-3.086,1.35.725.6.725.6-1.129.124-1.141.611c-.014.584,1.064.527,1.064.527s-.918.139-.9.573c.019.45,1.241.471,1.241.471s-.628.221-.444.515a5.529,5.529,0,0,0,3.538-.095c.763-.485.766-3.815.617-4.541s-.7-1.118-1.613-2.222Z" transform="translate(-310.579 -93.911)" fill="#eec1bb" />
              <g id="Group_5811" data-name="Group 5811" transform="translate(210.549 67.129)" opacity="0.2">
                <g id="Group_5810" data-name="Group 5810">
                  <g id="Group_5809" data-name="Group 5809" clipPath="url(#clip-path-17)">
                    <path id="Path_3237" data-name="Path 3237" d="M522.8,167.088h0l1.957-.311a.054.054,0,0,0,.04-.062.051.051,0,0,0-.055-.047l-1.958.312a.053.053,0,0,0-.04.062.051.051,0,0,0,.05.047" transform="translate(-522.747 -166.667)" />
                  </g>
                </g>
              </g>
              <g id="Group_5814" data-name="Group 5814" transform="translate(210.528 68.426)" opacity="0.2">
                <g id="Group_5813" data-name="Group 5813">
                  <g id="Group_5812" data-name="Group 5812" clipPath="url(#clip-path-18)">
                    <path id="Path_3238" data-name="Path 3238" d="M522.747,170.147h0l1.9-.15a.055.055,0,0,0-.008-.109l-1.9.15a.052.052,0,0,0-.044.058.051.051,0,0,0,.051.051" transform="translate(-522.695 -169.888)" />
                  </g>
                </g>
              </g>
              <g id="Group_5817" data-name="Group 5817" transform="translate(210.878 69.549)" opacity="0.2">
                <g id="Group_5816" data-name="Group 5816">
                  <g id="Group_5815" data-name="Group 5815" clipPath="url(#clip-path-19)">
                    <path id="Path_3239" data-name="Path 3239" d="M523.617,172.855l1.418-.071a.055.055,0,0,0-.005-.109l-1.419.071a.055.055,0,0,0,.006.109" transform="translate(-523.565 -172.675)" />
                  </g>
                </g>
              </g>
              <path id="Path_3240" data-name="Path 3240" d="M447.543,157.689s2.979.833,3.284,1.487-.724.6-.724.6,1.129.125,1.141.611c.014.584-1.064.526-1.064.526s.918.139.9.573c-.019.449-1.24.471-1.24.471s.628.22.444.515a5.532,5.532,0,0,1-3.538-.1c-.763-.485-.766-3.815-.617-4.541s.209-.607,1.122-1.71l.166.617a2.951,2.951,0,0,1,.091.513Z" transform="translate(-266.393 -93.246)" fill="#eec1bb" />
              <g id="Group_5820" data-name="Group 5820" transform="translate(181.703 66.168)" opacity="0.2">
                <g id="Group_5819" data-name="Group 5819">
                  <g id="Group_5818" data-name="Group 5818" clipPath="url(#clip-path-20)">
                    <path id="Path_3241" data-name="Path 3241" d="M453.133,164.7h0l-1.958-.312a.053.053,0,0,1-.04-.062.051.051,0,0,1,.055-.047l1.958.312a.053.053,0,0,1,.04.062.051.051,0,0,1-.05.047" transform="translate(-451.129 -164.281)" />
                  </g>
                </g>
              </g>
              <g id="Group_5823" data-name="Group 5823" transform="translate(181.777 67.465)" opacity="0.2">
                <g id="Group_5822" data-name="Group 5822">
                  <g id="Group_5821" data-name="Group 5821" clipPath="url(#clip-path-18)">
                    <path id="Path_3242" data-name="Path 3242" d="M453.264,167.761h0l-1.9-.15a.055.055,0,0,1,.008-.109l1.9.15a.055.055,0,0,1-.007.109" transform="translate(-451.314 -167.502)" />
                  </g>
                </g>
              </g>
              <g id="Group_5826" data-name="Group 5826" transform="translate(181.913 68.588)" opacity="0.2">
                <g id="Group_5825" data-name="Group 5825">
                  <g id="Group_5824" data-name="Group 5824" clipPath="url(#clip-path-22)">
                    <path id="Path_3243" data-name="Path 3243" d="M453.115,170.469,451.7,170.4a.055.055,0,0,1,.005-.109l1.419.071a.055.055,0,0,1-.006.109" transform="translate(-451.651 -170.289)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path id="Path_3244" data-name="Path 3244" d="M298.181,373.564l4.113.4,3.04-10.414-6.257-.681Z" transform="translate(-178.081 -216.717)" fill="#eec1bb" />
          <g id="Group_5887" data-name="Group 5887">
            <g id="Group_5886" data-name="Group 5886" clipPath="url(#clip-path)">
              <path id="Path_3245" data-name="Path 3245" d="M300.053,385.908h-1.469a1.041,1.041,0,0,0-.836.436c-.847,1.156-1.483.719-2.4-.368-.586-.068-.743.6-.791.784l-1.1,3.993a.628.628,0,0,0,.613.8H305.7c1.85,0,2.234-1.865,1.472-2.038-3.428-.757-5.855-1.766-6.914-2.763-.2-.174.005-.3-.208-.846" transform="translate(-175.245 -230.475)" fill="#263238" />
              <path id="Path_3246" data-name="Path 3246" d="M314.185,387.524a.6.6,0,0,0,.249-.479.268.268,0,0,0-.12-.259c-.46-.326-2.3.532-2.5.633a.08.08,0,0,0-.049.049.081.081,0,0,0,.049.1,6.652,6.652,0,0,0,1.376.177,1.641,1.641,0,0,0,1-.225m.014-.6a.106.106,0,0,1,.048.11.481.481,0,0,1-.182.35,3.169,3.169,0,0,1-1.966.077c.762-.336,1.845-.714,2.1-.537" transform="translate(-186.19 -230.955)" fill="#407bff" />
              <path id="Path_3247" data-name="Path 3247" d="M311.856,386.114c.551-.23,1.707-1.136,1.645-1.616-.01-.1-.082-.22-.331-.254-1.007-.115-1.438,1.692-1.438,1.769a.081.081,0,0,0,.024.086.1.1,0,0,0,.058,0Zm1.28-1.721c.187.019.187.086.187.11.053.292-.786,1.045-1.386,1.361.125-.422.494-1.477,1.131-1.477Z" transform="translate(-186.172 -229.478)" fill="#407bff" />
              <path id="Path_3248" data-name="Path 3248" d="M310.554,388.722a1.78,1.78,0,0,0-1.247.8" transform="translate(-184.726 -232.155)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3249" data-name="Path 3249" d="M312.82,390.428a1.779,1.779,0,0,0-1.247.8" transform="translate(-186.08 -233.174)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3250" data-name="Path 3250" d="M315.639,391.862a1.779,1.779,0,0,0-1.247.8" transform="translate(-187.763 -234.03)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <g id="Group_5831" data-name="Group 5831" transform="translate(120.432 151.827)" opacity="0.3">
                <g id="Group_5830" data-name="Group 5830">
                  <g id="Group_5829" data-name="Group 5829" clipPath="url(#clip-path-24)">
                    <path id="Path_3251" data-name="Path 3251" d="M304.22,377.466l-.373,1.277L299.007,378l.088-1.047Z" transform="translate(-299.007 -376.953)" />
                  </g>
                </g>
              </g>
              <path id="Path_3252" data-name="Path 3252" d="M357.625,373.564l4.113.4,3.04-10.414-6.257-.681Z" transform="translate(-213.583 -216.717)" fill="#eec1bb" />
              <path id="Path_3253" data-name="Path 3253" d="M359.5,385.908h-1.469a1.043,1.043,0,0,0-.836.436c-.847,1.156-1.483.719-2.4-.368-.586-.068-.743.6-.791.784l-1.1,3.993a.628.628,0,0,0,.613.8h11.635c1.851,0,2.234-1.865,1.472-2.038-3.428-.757-5.855-1.766-6.914-2.763-.2-.174.005-.3-.208-.846" transform="translate(-210.747 -230.475)" fill="#263238" />
              <path id="Path_3254" data-name="Path 3254" d="M373.629,387.524a.6.6,0,0,0,.249-.479.268.268,0,0,0-.12-.259c-.46-.326-2.3.532-2.5.633a.08.08,0,0,0-.049.049.082.082,0,0,0,.049.1,6.656,6.656,0,0,0,1.376.177,1.64,1.64,0,0,0,1-.225m.014-.6a.1.1,0,0,1,.048.11.48.48,0,0,1-.182.35,3.169,3.169,0,0,1-1.966.077c.762-.336,1.846-.714,2.1-.537" transform="translate(-221.692 -230.955)" fill="#407bff" />
              <path id="Path_3255" data-name="Path 3255" d="M371.3,386.114c.551-.23,1.707-1.136,1.645-1.616-.01-.1-.082-.22-.331-.254-1.007-.115-1.438,1.692-1.438,1.769a.081.081,0,0,0,.024.086.1.1,0,0,0,.058,0Zm1.28-1.721c.187.019.187.086.187.11.053.292-.786,1.045-1.385,1.361.124-.422.493-1.477,1.131-1.477Z" transform="translate(-221.674 -229.478)" fill="#407bff" />
              <path id="Path_3256" data-name="Path 3256" d="M370,388.722a1.78,1.78,0,0,0-1.247.8" transform="translate(-220.228 -232.155)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3257" data-name="Path 3257" d="M372.264,390.428a1.779,1.779,0,0,0-1.247.8" transform="translate(-221.581 -233.174)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3258" data-name="Path 3258" d="M375.083,391.862a1.779,1.779,0,0,0-1.247.8" transform="translate(-223.265 -234.03)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <g id="Group_5834" data-name="Group 5834" transform="translate(144.375 151.827)" opacity="0.3">
                <g id="Group_5833" data-name="Group 5833">
                  <g id="Group_5832" data-name="Group 5832" clipPath="url(#clip-path-24)">
                    <path id="Path_3259" data-name="Path 3259" d="M363.665,377.466l-.373,1.277L358.451,378l.088-1.047Z" transform="translate(-358.452 -376.953)" />
                  </g>
                </g>
              </g>
              <path id="Path_3260" data-name="Path 3260" d="M355.777,194.585s3.409,15.054,5.034,28.167a43.12,43.12,0,0,1-.286,13.276l-6.57,32.666-8.269-.828c1.3-14.089,3.11-31.464,3.629-38.857-1.819-8.663-5.694-19.15-9.5-29.579Z" transform="translate(-202.944 -116.211)" fill="#407bff" />
              <g id="Group_5837" data-name="Group 5837" transform="translate(136.867 78.374)" opacity="0.2">
                <g id="Group_5836" data-name="Group 5836">
                  <g id="Group_5835" data-name="Group 5835" clipPath="url(#clip-path-26)">
                    <path id="Path_3261" data-name="Path 3261" d="M355.777,194.585s3.409,15.054,5.034,28.167a43.12,43.12,0,0,1-.286,13.276l-6.57,32.666-8.269-.828c1.3-14.089,3.11-31.464,3.629-38.857-1.819-8.663-5.694-19.15-9.5-29.579Z" transform="translate(-339.811 -194.585)" />
                  </g>
                </g>
              </g>
              <path id="Path_3262" data-name="Path 3262" d="M303.6,225.223c.187-.815.486-2.47.852-4.652l-.034-.008c.269-2.886.766-16.275,1.679-24.815.7-5.439,2.092-9.12,3.492-10.923.072-.093.138-.177.2-.252.232-.29.373-.45.373-.45q.4.1.794.186l.294.063a41.957,41.957,0,0,0,18.581-.531c.91,2.247,1.589,7.038,2.253,12.006-1.034,1.135-5.571,2.606-10.775,3.582l-1.484,6.713c-.781,4.162-1.784,8.244-2.636,11.92l-.89,4.026c-.054.264-.107.526-.157.783-.452,2.314-11.2,39.6-11.2,39.6l-7-.41s3.127-25.808,5.659-36.84" transform="translate(-177.941 -109.795)" fill="#407bff" />
              <g id="Group_5840" data-name="Group 5840" transform="translate(120.004 74.047)" opacity="0.2">
                <g id="Group_5839" data-name="Group 5839">
                  <g id="Group_5838" data-name="Group 5838" clipPath="url(#clip-path-27)">
                    <path id="Path_3263" data-name="Path 3263" d="M303.6,225.223c.187-.815.486-2.47.852-4.652l-.034-.008c.269-2.886.766-16.275,1.679-24.815.7-5.439,2.092-9.12,3.492-10.923.072-.093.138-.177.2-.252.232-.29.373-.45.373-.45q.4.1.794.186l.294.063a41.957,41.957,0,0,0,18.581-.531c.91,2.247,1.589,7.038,2.253,12.006-1.034,1.135-5.571,2.606-10.775,3.582l-1.484,6.713c-.781,4.162-1.784,8.244-2.636,11.92l-.89,4.026c-.054.264-.107.526-.157.783-.452,2.314-11.2,39.6-11.2,39.6l-7-.41s3.127-25.808,5.659-36.84" transform="translate(-297.945 -183.842)" />
                  </g>
                </g>
              </g>
              <g id="Group_5843" data-name="Group 5843" transform="translate(141.791 85.351)" opacity="0.2">
                <g id="Group_5842" data-name="Group 5842">
                  <g id="Group_5841" data-name="Group 5841" clipPath="url(#clip-path-28)">
                    <path id="Path_3264" data-name="Path 3264" d="M352.037,223.525c-.027.137,3.206-11.617,3.206-11.617s2.722,10.494-1.42,17.057Z" transform="translate(-352.036 -211.908)" />
                  </g>
                </g>
              </g>
              <path id="Path_3265" data-name="Path 3265" d="M334.762,95.732s1.644,1.264,3.762,3.089a7.87,7.87,0,0,0,1.441.982c1.388.737,2.652,1.158,2.995.489a2.272,2.272,0,0,0,.178-.473c.291-1.12.172-2.695-.734-3.415-1.021-.813-1.513-1.508-1.329-2.608a8.253,8.253,0,0,1,.586-1.893l-.8-.939-4.2-4.24c.105,2.831.319,7.471-1.893,9.008" transform="translate(-199.929 -51.794)" fill="#eec1bb" />
              <g id="Group_5846" data-name="Group 5846" transform="translate(138.828 37.546)" opacity="0.2">
                <g id="Group_5845" data-name="Group 5845">
                  <g id="Group_5844" data-name="Group 5844" clipPath="url(#clip-path-29)">
                    <path id="Path_3266" data-name="Path 3266" d="M346.963,97.916c-1.156-.686-2.423-1.876-2.271-3.235a6.369,6.369,0,0,1,.328-1.462l2.347,3.107a7.854,7.854,0,0,0-.4,1.59" transform="translate(-344.679 -93.218)" />
                  </g>
                </g>
              </g>
              <path id="Path_3267" data-name="Path 3267" d="M336.471,66.244c.4,4.317.472,6.147,2.7,8.411,3.351,3.4,8.505,2.26,9.393-2.02.8-3.851.018-10.263-4.22-12.078a5.552,5.552,0,0,0-7.872,5.686" transform="translate(-200.933 -35.862)" fill="#eec1bb" />
              <path id="Path_3268" data-name="Path 3268" d="M349.858,77.741c.023.4.271.737.555.742s.5-.317.473-.721-.271-.737-.555-.743-.5.317-.473.721" transform="translate(-208.943 -45.998)" fill="#263238" />
              <path id="Path_3269" data-name="Path 3269" d="M360.965,77.622c.023.391.271.714.553.72s.494-.307.472-.7-.271-.714-.554-.72-.494.307-.471.7" transform="translate(-215.577 -45.941)" fill="#263238" />
              <path id="Path_3270" data-name="Path 3270" d="M347.859,73.617a.194.194,0,0,0,.153-.049,1.558,1.558,0,0,1,1.423-.4.2.2,0,0,0,.249-.144.23.23,0,0,0-.157-.266,1.943,1.943,0,0,0-1.8.481.217.217,0,0,0,0,.3.223.223,0,0,0,.141.074" transform="translate(-207.631 -43.421)" fill="#263238" />
              <path id="Path_3271" data-name="Path 3271" d="M361.956,72.359a.2.2,0,0,0,.151-.023.21.21,0,0,0,.055-.292,2.223,2.223,0,0,0-1.648-1.085.194.194,0,0,0-.2.2.23.23,0,0,0,.211.224,1.78,1.78,0,0,1,1.3.874.225.225,0,0,0,.138.1" transform="translate(-215.187 -42.378)" fill="#263238" />
              <path id="Path_3272" data-name="Path 3272" d="M333.911,68.427s1.332-2.013.783-5.934a1.24,1.24,0,0,1,.976-1.406c1.862-.362,4.352-2.711,7.39,1.955,0,0-1.449-5.589-9.931-3.337-7.549,2-2.38,8.4-1.6,9.06s2.384-.337,2.384-.337" transform="translate(-196.534 -35.338)" fill="#263238" />
              <path id="Path_3273" data-name="Path 3273" d="M332.851,80.318a3.474,3.474,0,0,0,2.1,1.632c1.143.287,1.733-1.55,1.343-2.7a2.3,2.3,0,0,0-2.586-1.488,1.653,1.653,0,0,0-.855,2.552" transform="translate(-198.62 -46.419)" fill="#eec1bb" />
              <path id="Path_3274" data-name="Path 3274" d="M353.487,87.192a12.23,12.23,0,0,0-.774,1.589c-.1-.021-.2-.042-.3-.068-1.374-.322-1.906-.925-2.078-1.5a1.819,1.819,0,0,1-.028-.861,1.762,1.762,0,0,1,.151-.469,6.656,6.656,0,0,0,2.566,1.189c.284.08.467.117.467.117" transform="translate(-209.187 -51.293)" fill="#263238" />
              <path id="Path_3275" data-name="Path 3275" d="M353.077,87.075l-.24.463a4.2,4.2,0,0,1-2.477-1.183,1.769,1.769,0,0,1,.151-.469,6.657,6.657,0,0,0,2.566,1.189" transform="translate(-209.244 -51.293)" fill="#fff" />
              <path id="Path_3276" data-name="Path 3276" d="M352.507,90.685c-1.374-.321-1.906-.925-2.078-1.5a4.541,4.541,0,0,1,1.455.659,1.7,1.7,0,0,1,.623.837" transform="translate(-209.285 -53.266)" fill="#dc5753" />
              <path id="Path_3277" data-name="Path 3277" d="M358.086,78.365a14.661,14.661,0,0,0,1.437,2.623.333.333,0,0,1-.156.509,2.136,2.136,0,0,1-1.157.019Z" transform="translate(-213.858 -46.802)" fill="#d4827d" />
              <path id="Path_3278" data-name="Path 3278" d="M350.82,76.649l.991-.234s-.542.739-.991.234" transform="translate(-209.519 -45.637)" fill="#263238" />
              <path id="Path_3279" data-name="Path 3279" d="M361.884,76.6l.991-.234s-.542.739-.991.234" transform="translate(-216.127 -45.61)" fill="#263238" />
              <path id="Path_3280" data-name="Path 3280" d="M347.4,61.546s2.614-3.093.87-5.642c-.656-.958-1.859-1.1-2.921-1.569-2.451-1.08-4.437-1.3-7.566,1.768-3.254,3.19-3.843,4.263-.254,8.193,0,0-.387-.514.94-1.683s2.792-2.209,2.081-3.343a1.9,1.9,0,0,1,1.182,1.475l1.941-.53a3.678,3.678,0,0,0-.6-1.5,3.464,3.464,0,0,1,1.333,1.461l1.679.316a5.825,5.825,0,0,0-.721-2.093,9.821,9.821,0,0,1,2.034,3.148" transform="translate(-200.118 -32.016)" fill="#263238" />
              <g id="Group_5849" data-name="Group 5849" transform="translate(135.068 32.125)" opacity="0.2">
                <g id="Group_5848" data-name="Group 5848">
                  <g id="Group_5847" data-name="Group 5847" clipPath="url(#clip-path-30)">
                    <path id="Path_3281" data-name="Path 3281" d="M337.223,81.557A1.7,1.7,0,0,0,335.6,80.01" transform="translate(-335.494 -79.909)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
                  </g>
                </g>
              </g>
              <path id="Path_3282" data-name="Path 3282" d="M370.76,137.713a6.205,6.205,0,0,1-.689-.619c-.183-.192-.311-.44-.267-.44a.68.68,0,0,0-.033-.081l-.078-.18-.189-.483c-.128-.347-.25-.725-.372-1.109-.256-.762-.495-1.555-.728-2.355-.472-1.6-.922-3.227-1.378-4.857l-.836-3.416c-.121-.553-.309-1.359-.558-2.28v0c-.68-2.509-1.816-5.856-3.3-7.186-2.306-1.764-3.778.412-4.727,2.675-.911,2.172,1.337,8.106,1.337,8.106l2.739-.2c.346,1.262.709,2.519,1.067,3.8.466,1.673,1.022,3.314,1.578,4.957.283.824.578,1.648.9,2.478.189.421.361.836.556,1.276.095.217.2.446.317.675l.206.384a3.939,3.939,0,0,0,.289.458,4.533,4.533,0,0,0,1.467,1.239,6.238,6.238,0,0,0,1.356.564,8.165,8.165,0,0,0,2.617.316l.495-2.391c-.589-.44-1.239-.9-1.767-1.332" transform="translate(-213.441 -68.12)" fill="#263238" />
              <g id="Group_5852" data-name="Group 5852" transform="translate(143.946 45.94)" opacity="0.5">
                <g id="Group_5851" data-name="Group 5851">
                  <g id="Group_5850" data-name="Group 5850" clipPath="url(#clip-path-31)">
                    <path id="Path_3283" data-name="Path 3283" d="M370.76,137.713a6.205,6.205,0,0,1-.689-.619c-.183-.192-.311-.44-.267-.44a.68.68,0,0,0-.033-.081l-.078-.18-.189-.483c-.128-.347-.25-.725-.372-1.109-.256-.762-.495-1.555-.728-2.355-.472-1.6-.922-3.227-1.378-4.857l-.836-3.416c-.121-.553-.309-1.359-.558-2.28v0c-.68-2.509-1.816-5.856-3.3-7.186-2.306-1.764-3.778.412-4.727,2.675-.911,2.172,1.337,8.106,1.337,8.106l2.739-.2c.346,1.262.709,2.519,1.067,3.8.466,1.673,1.022,3.314,1.578,4.957.283.824.578,1.648.9,2.478.189.421.361.836.556,1.276.095.217.2.446.317.675l.206.384a3.939,3.939,0,0,0,.289.458,4.533,4.533,0,0,0,1.467,1.239,6.238,6.238,0,0,0,1.356.564,8.165,8.165,0,0,0,2.617.316l.495-2.391c-.589-.44-1.239-.9-1.767-1.332" transform="translate(-357.386 -114.06)" fill="#fff" />
                  </g>
                </g>
              </g>
              <g id="Group_5855" data-name="Group 5855" transform="translate(149.265 50.44)" opacity="0.3">
                <g id="Group_5854" data-name="Group 5854">
                  <g id="Group_5853" data-name="Group 5853" clipPath="url(#clip-path-32)">
                    <path id="Path_3284" data-name="Path 3284" d="M370.724,125.232a8.866,8.866,0,0,1,.911,2.151c.29.911.618,5.829-1.042,7.283v-3.41c.06-2.089.07-3.935.13-6.024" transform="translate(-370.594 -125.232)" />
                  </g>
                </g>
              </g>
              <path id="Path_3285" data-name="Path 3285" d="M316.394,121.31l-2.571-8.768c1.627-2.428,9.675-4.293,9.675-4.293l4.125,1.448,1.178-1.158s2.038.455,3.783,1.023c2.329.76,3.589,1.371,4.144,2.663,0,0,.972,2.864.387,8.765a96.208,96.208,0,0,0,.475,12.846l2.744,8.687c-9.688,7.545-23.25.5-23.25.5.435-1.332,1.887-5.423,2.084-6.725a37.712,37.712,0,0,0-.648-7.131c-.136-.561-1.89-7.312-2.125-7.857" transform="translate(-187.423 -64.649)" fill="#263238" />
              <g id="Group_5858" data-name="Group 5858" transform="translate(126.4 43.6)" opacity="0.5">
                <g id="Group_5857" data-name="Group 5857">
                  <g id="Group_5856" data-name="Group 5856" clipPath="url(#clip-path-33)">
                    <path id="Path_3286" data-name="Path 3286" d="M316.394,121.31l-2.571-8.768c1.627-2.428,9.675-4.293,9.675-4.293l4.125,1.448,1.178-1.158s2.038.455,3.783,1.023c2.329.76,3.589,1.371,4.144,2.663,0,0,.972,2.864.387,8.765a96.208,96.208,0,0,0,.475,12.846l2.744,8.687c-9.688,7.545-23.25.5-23.25.5.435-1.332,1.887-5.423,2.084-6.725a37.712,37.712,0,0,0-.648-7.131c-.136-.561-1.89-7.312-2.125-7.857" transform="translate(-313.823 -108.249)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3287" data-name="Path 3287" d="M393.285,153.665a7.75,7.75,0,0,1,6.747-7.05l-.744,1.239,1.419-.843-6.5,8.159Z" transform="translate(-234.88 -87.562)" fill="#407bff" />
              <g id="Group_5861" data-name="Group 5861" transform="translate(158.405 59.053)" opacity="0.8" >
                <g id="Group_5860" data-name="Group 5860">
                  <g id="Group_5859" data-name="Group 5859" clipPath="url(#clip-path-34)">
                    <path id="Path_3288" data-name="Path 3288" d="M393.285,153.665a7.75,7.75,0,0,1,6.747-7.05l-.744,1.239,1.419-.843-6.5,8.159Z" transform="translate(-393.285 -146.615)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3289" data-name="Path 3289" d="M357.346,147.39l2.237-.068,10.705,3.63-9.758,14.906-9.6-2.4a1.028,1.028,0,0,1-.619-1.5l3.878-7.013a.458.458,0,0,0,0-.446l-.526-.935a.62.62,0,0,1,0-.62l2.6-4.876a1.325,1.325,0,0,1,1.084-.681" transform="translate(-209.133 -87.985)" fill="#407bff" />
              <path id="Path_3290" data-name="Path 3290" d="M385.646,150.53l6.1-3.915-10.024,14.894-5.83,3.928Z" transform="translate(-224.491 -87.562)" fill="#407bff" />
              <g id="Group_5864" data-name="Group 5864" transform="translate(151.398 59.053)" opacity="0.2">
                <g id="Group_5863" data-name="Group 5863">
                  <g id="Group_5862" data-name="Group 5862" clipPath="url(#clip-path-35)">
                    <path id="Path_3291" data-name="Path 3291" d="M385.646,150.53l6.1-3.915-10.024,14.894-5.83,3.928Z" transform="translate(-375.888 -146.615)" />
                  </g>
                </g>
              </g>
              <path id="Path_3292" data-name="Path 3292" d="M390.9,172.1s-2.369-.621-2.89-.215.192.792.192.792-.816-.367-1.059.024c-.292.47.456.87.456.87s-.681-.267-.879.093.6.9.6.9-.526-.081-.546.235a3.657,3.657,0,0,0,2.412,1.39c.745-.078,2.359-2.787,2.612-3.44a1.325,1.325,0,0,0-.253-1.616Z" transform="translate(-230.938 -102.203)" fill="#eec1bb" />
              <g id="Group_5867" data-name="Group 5867" transform="translate(157.219 70.423)" opacity="0.2">
                <g id="Group_5866" data-name="Group 5866">
                  <g id="Group_5865" data-name="Group 5865" clipPath="url(#clip-path-36)">
                    <path id="Path_3293" data-name="Path 3293" d="M390.359,174.94l0,0,1.46.559a.05.05,0,0,0,.057-.033c.012-.026,0-.054-.014-.061l-1.46-.559a.05.05,0,0,0-.057.033.045.045,0,0,0,.011.059" transform="translate(-390.342 -174.846)" />
                  </g>
                </g>
              </g>
              <g id="Group_5870" data-name="Group 5870" transform="translate(156.656 71.338)" opacity="0.2">
                <g id="Group_5869" data-name="Group 5869">
                  <g id="Group_5868" data-name="Group 5868" clipPath="url(#clip-path-37)">
                    <path id="Path_3294" data-name="Path 3294" d="M388.96,177.213h0l1.346.668a.047.047,0,0,0,.058-.029.046.046,0,0,0-.01-.063l-1.346-.668a.047.047,0,0,0-.058.029.046.046,0,0,0,.009.062" transform="translate(-388.943 -177.118)" />
                  </g>
                </g>
              </g>
              <g id="Group_5873" data-name="Group 5873" transform="translate(156.385 72.334)" opacity="0.2">
                <g id="Group_5872" data-name="Group 5872">
                  <g id="Group_5871" data-name="Group 5871" clipPath="url(#clip-path-38)">
                    <path id="Path_3295" data-name="Path 3295" d="M388.287,179.683l.983.531a.055.055,0,0,0,.05-.091l-.983-.53a.054.054,0,0,0-.049.091" transform="translate(-388.27 -179.589)" />
                  </g>
                </g>
              </g>
              <path id="Path_3296" data-name="Path 3296" d="M341.906,106.362l-1.788-1.732-.91,2.518-3.972-3.166-2.014,2.21,4.989,3.476,1-2.208,2.7,2.024Z" transform="translate(-199.009 -62.101)" fill="#263238" />
              <g id="Group_5876" data-name="Group 5876" transform="translate(134.408 44.261)" opacity="0.3">
                <g id="Group_5875" data-name="Group 5875">
                  <g id="Group_5874" data-name="Group 5874" clipPath="url(#clip-path-39)">
                    <path id="Path_3297" data-name="Path 3297" d="M342.2,109.89a25.59,25.59,0,0,1,0,3.123l-2.7-2.024-1,2.208-4.794-3.306,4.907,4.275.884-2.6,3.009,2.6Z" transform="translate(-333.706 -109.89)" />
                  </g>
                </g>
              </g>
              <g id="Group_5879" data-name="Group 5879" transform="translate(131.709 57.116)" opacity="0.3">
                <g id="Group_5878" data-name="Group 5878">
                  <g id="Group_5877" data-name="Group 5877" clipPath="url(#clip-path-40)">
                    <path id="Path_3298" data-name="Path 3298" d="M340.745,142.531,327,155.771v-2.616s12.369-11.331,12.519-11.347a7.411,7.411,0,0,1,1.222.723" transform="translate(-327.004 -141.807)" />
                  </g>
                </g>
              </g>
              <g id="Group_5882" data-name="Group 5882" transform="translate(130.76 52.749)" opacity="0.3">
                <g id="Group_5881" data-name="Group 5881">
                  <g id="Group_5880" data-name="Group 5880" clipPath="url(#clip-path-41)">
                    <path id="Path_3299" data-name="Path 3299" d="M326.433,137.1l-1.488-6.139-.295,7.125Z" transform="translate(-324.65 -130.964)" />
                  </g>
                </g>
              </g>
              <path id="Path_3300" data-name="Path 3300" d="M335.2,86.712a.826.826,0,1,0,.826-.826.826.826,0,0,0-.826.826" transform="translate(-200.19 -51.293)" fill="#407bff" />
              <path id="Path_3301" data-name="Path 3301" d="M309.335,118.908s-1.858,1.808-2.483,7.98c-.449,4.429-1.584,13.98,1.858,15.207s18.453-13.125,18.453-13.125l-2.437-4.183-11.03,6.1s1.125-10.116-.819-11.041a9.3,9.3,0,0,0-3.542-.94" transform="translate(-182.936 -71.015)" fill="#263238" />
              <g id="Group_5885" data-name="Group 5885" transform="translate(123.373 47.893)" opacity="0.5">
                <g id="Group_5884" data-name="Group 5884">
                  <g id="Group_5883" data-name="Group 5883" clipPath="url(#clip-path-42)">
                    <path id="Path_3302" data-name="Path 3302" d="M309.335,118.908s-1.858,1.808-2.483,7.98c-.449,4.429-1.584,13.98,1.858,15.207s18.453-13.125,18.453-13.125l-2.437-4.183-11.03,6.1s1.125-10.116-.819-11.041a9.3,9.3,0,0,0-3.542-.94" transform="translate(-306.309 -118.908)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3303" data-name="Path 3303" d="M353.484,125.2s1.448-2.9,2.135-3.028a2.4,2.4,0,0,1,1.241.1s4.68-2.757,4.992-2.032c.168.393-.871,1.258-3.1,2.531,0,0,1.285.473.767,1.226a.955.955,0,0,1,.441,1.373,1.23,1.23,0,0,1-.112,1.36,12.618,12.618,0,0,1-4.606,2.212c-1.489.218-2.357-2.675-2.357-2.675Z" transform="translate(-210.751 -71.74)" fill="#eec1bb" />
            </g>
          </g>
          <g id="Group_5895" data-name="Group 5895">
            <g id="Group_5894" data-name="Group 5894" clipPath="url(#clip-path)">
              <path id="Path_3304" data-name="Path 3304" d="M340.446,298.71c1.263.594,2.041,1.985,3.349,2.472a3.282,3.282,0,0,0,2.286-.065V282.458H328.221a2.94,2.94,0,0,1,.167-1.193c.487-1.308,1.878-2.086,2.472-3.349.877-1.863-.359-4.185-2.172-5.162a7.208,7.208,0,0,0-6.717,0c-1.813.977-3.049,3.3-2.172,5.162.595,1.263,1.985,2.041,2.472,3.349a2.94,2.94,0,0,1,.167,1.193H304.58v41.5h41.5v-16.6a3.282,3.282,0,0,0-2.286-.065c-1.308.487-2.092,1.867-3.349,2.472-1.677.808-4.177-.362-5.161-2.172a7.205,7.205,0,0,1,0-6.717c.977-1.813,3.3-3.049,5.161-2.172" transform="translate(-181.903 -162.391)" fill="#407bff" />
              <g id="Group_5890" data-name="Group 5890" transform="translate(122.677 109.518)" opacity="0.4">
                <g id="Group_5889" data-name="Group 5889">
                  <g id="Group_5888" data-name="Group 5888" clipPath="url(#clip-path-44)">
                    <path id="Path_3305" data-name="Path 3305" d="M340.446,298.71c1.263.594,2.041,1.985,3.349,2.472a3.282,3.282,0,0,0,2.286-.065V282.458H328.221a2.94,2.94,0,0,1,.167-1.193c.487-1.308,1.878-2.086,2.472-3.349.877-1.863-.359-4.185-2.172-5.162a7.208,7.208,0,0,0-6.717,0c-1.813.977-3.049,3.3-2.172,5.162.595,1.263,1.985,2.041,2.472,3.349a2.94,2.94,0,0,1,.167,1.193H304.58v41.5h41.5v-16.6a3.282,3.282,0,0,0-2.286-.065c-1.308.487-2.092,1.867-3.349,2.472-1.677.808-4.177-.362-5.161-2.172a7.205,7.205,0,0,1,0-6.717c.977-1.813,3.3-3.049,5.161-2.172" transform="translate(-304.58 -271.908)" />
                  </g>
                </g>
              </g>
              <path id="Path_3306" data-name="Path 3306" d="M345.5,298.71c1.263.594,2.041,1.985,3.349,2.472a3.282,3.282,0,0,0,2.286-.065V282.458H333.273a2.939,2.939,0,0,1,.167-1.193c.487-1.308,1.878-2.086,2.472-3.349.877-1.863-.359-4.185-2.172-5.162a7.208,7.208,0,0,0-6.717,0c-1.813.977-3.049,3.3-2.172,5.162.594,1.263,1.985,2.041,2.472,3.349a2.94,2.94,0,0,1,.167,1.193H309.631v41.5h41.5v-16.6a3.282,3.282,0,0,0-2.286-.065c-1.308.487-2.092,1.867-3.349,2.472-1.677.808-4.177-.362-5.161-2.172a7.205,7.205,0,0,1,0-6.717c.977-1.813,3.3-3.049,5.161-2.172" transform="translate(-184.92 -162.391)" fill="#407bff" />
              <g id="Group_5893" data-name="Group 5893" transform="translate(159.321 145.2)" opacity="0.4">
                <g id="Group_5892" data-name="Group 5892">
                  <g id="Group_5891" data-name="Group 5891" clipPath="url(#clip-path-45)">
                    <path id="Path_3307" data-name="Path 3307" d="M395.56,362.969s1.437,2.575,3.905,2.361,2.985-1.288,2.985-1.288l-.034-3.541-5.506,2.109a1.73,1.73,0,0,1-1.35.36" transform="translate(-395.56 -360.5)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_5942" data-name="Group 5942">
            <g id="Group_5941" data-name="Group 5941" clipPath="url(#clip-path)">
              <path id="Path_3308" data-name="Path 3308" d="M522.442,285.925l14.186-5.451,8.188-7.938c.228-.263,2.6-4.358,2.6-4.358l4.019,8.609S541.444,286.829,537.784,288c-2.958.945-13.432,3.511-13.432,3.511Z" transform="translate(-312.016 -160.163)" fill="#407bff" />
              <g id="Group_5898" data-name="Group 5898" transform="translate(219.122 121.081)" opacity="0.4">
                <g id="Group_5897" data-name="Group 5897">
                  <g id="Group_5896" data-name="Group 5896" clipPath="url(#clip-path-47)">
                    <path id="Path_3309" data-name="Path 3309" d="M544.032,309.181s1.71-6.7,5.533-8.1,8.765.793,8.623.887-4.281,3.949-4.423,4c-.105.035-5.368,1.89-8.091,2.79Z" transform="translate(-544.032 -300.618)" />
                  </g>
                </g>
              </g>
              <path id="Path_3310" data-name="Path 3310" d="M426.775,298.631a2.939,2.939,0,0,0,.167,1.193c.487,1.308,1.878,2.086,2.472,3.349.877,1.863-.359,4.184-2.172,5.161a7.205,7.205,0,0,1-6.717,0c-1.813-.977-3.049-3.3-2.172-5.161.594-1.263,1.985-2.041,2.472-3.349a2.94,2.94,0,0,0,.167-1.193h-18.5v18.646a3.3,3.3,0,0,1-2.32.078c-1.308-.487-2.086-1.878-3.349-2.472-1.863-.877-4.184.359-5.161,2.172a7.208,7.208,0,0,0,0,6.717c.977,1.813,3.3,3.05,5.161,2.172,1.263-.594,2.041-1.985,3.349-2.472a3.3,3.3,0,0,1,2.32.079v16.581l41.357.185v-41.5Z" transform="translate(-233.407 -178.35)" fill="#263238" />
              <g id="Group_5901" data-name="Group 5901" transform="translate(157.412 120.281)" opacity="0.4">
                <g id="Group_5900" data-name="Group 5900">
                  <g id="Group_5899" data-name="Group 5899" clipPath="url(#clip-path-48)">
                    <path id="Path_3311" data-name="Path 3311" d="M426.775,298.631a2.939,2.939,0,0,0,.167,1.193c.487,1.308,1.878,2.086,2.472,3.349.877,1.863-.359,4.184-2.172,5.161a7.205,7.205,0,0,1-6.717,0c-1.813-.977-3.049-3.3-2.172-5.161.594-1.263,1.985-2.041,2.472-3.349a2.94,2.94,0,0,0,.167-1.193h-18.5v18.646a3.3,3.3,0,0,1-2.32.078c-1.308-.487-2.086-1.878-3.349-2.472-1.863-.877-4.184.359-5.161,2.172a7.208,7.208,0,0,0,0,6.717c.977,1.813,3.3,3.05,5.161,2.172,1.263-.594,2.041-1.985,3.349-2.472a3.3,3.3,0,0,1,2.32.079v16.581l41.357.185v-41.5Z" transform="translate(-390.819 -298.631)" />
                  </g>
                </g>
              </g>
              <path id="Path_3312" data-name="Path 3312" d="M431.32,298.631a2.94,2.94,0,0,0,.167,1.193c.487,1.308,1.878,2.086,2.472,3.349.877,1.863-.359,4.184-2.172,5.161a7.205,7.205,0,0,1-6.717,0c-1.813-.977-3.049-3.3-2.172-5.161.594-1.263,1.985-2.041,2.472-3.349a2.939,2.939,0,0,0,.167-1.193h-18.5v18.646a3.3,3.3,0,0,1-2.32.078c-1.308-.487-2.086-1.878-3.349-2.472-1.863-.877-4.184.359-5.161,2.172a7.208,7.208,0,0,0,0,6.717c.977,1.813,3.3,3.05,5.161,2.172,1.263-.594,2.041-1.985,3.349-2.472a3.3,3.3,0,0,1,2.32.079v16.581l41.357.185v-41.5Z" transform="translate(-236.122 -178.35)" fill="#263238" />
              <path id="Path_3313" data-name="Path 3313" d="M556.44,367.5l6.5-.394c-1.04,5.731-2.111,9.449-1.655,12.391l-2.622,1.753-2.428-2.388a65.435,65.435,0,0,1,.2-11.363" transform="translate(-332.111 -219.248)" fill="#e9b376" />
              <path id="Path_3314" data-name="Path 3314" d="M542.406,386.314l2.364.028c1.143,1.98,1.935,1.514,3.147.117.686-.072.862.717.915.929l1.23,4.689a.735.735,0,0,1-.729.929l-5.673-.067-7.946-.093c-2.166-.025-2.589-2.214-1.695-2.405,4.022-.839,6.877-1.987,8.131-3.139.232-.2,0-.357.255-.987" transform="translate(-318.668 -230.717)" fill="#407bff" />
              <path id="Path_3315" data-name="Path 3315" d="M552.492,389.573a2.083,2.083,0,0,1,1.449.957" transform="translate(-329.963 -232.663)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3316" data-name="Path 3316" d="M549.816,391.538a2.083,2.083,0,0,1,1.449.957" transform="translate(-328.365 -233.837)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3317" data-name="Path 3317" d="M546.5,393.178a2.083,2.083,0,0,1,1.449.957" transform="translate(-326.383 -234.816)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <g id="Group_5904" data-name="Group 5904" transform="translate(214.998 160.847)" opacity="0.7">
                <g id="Group_5903" data-name="Group 5903">
                  <g id="Group_5902" data-name="Group 5902" clipPath="url(#clip-path-49)">
                    <path id="Path_3318" data-name="Path 3318" d="M549.673,400.764a.738.738,0,0,1-.214.026l-5.673-.067-7.946-.093a2.064,2.064,0,0,1-2.049-1.282c.33.006,16.118.314,16.327.246l.084.35a.733.733,0,0,1-.529.82" transform="translate(-533.792 -399.348)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3319" data-name="Path 3319" d="M576.054,311.228s-17.766-9.174-21.225-9.436-6.765,1.612-7.426,8.774.661,25.583.661,25.583l9.583.165,1.188-21.341s14.842,10.841,15.282,10.732,6.234-.373,6.234-.373Z" transform="translate(-326.813 -180.225)" fill="#263238" />
              <g id="Group_5907" data-name="Group 5907" transform="translate(220.404 121.545)" opacity="0.3">
                <g id="Group_5906" data-name="Group 5906">
                  <g id="Group_5905" data-name="Group 5905" clipPath="url(#clip-path-50)">
                    <path id="Path_3320" data-name="Path 3320" d="M576.054,311.228s-17.766-9.174-21.225-9.436-6.765,1.612-7.426,8.774.661,25.583.661,25.583l9.583.165,1.188-21.341s14.842,10.841,15.282,10.732,6.234-.373,6.234-.373Z" transform="translate(-547.217 -301.769)" fill="#fff" />
                  </g>
                </g>
              </g>
              <g id="Group_5910" data-name="Group 5910" transform="translate(223.564 144.73)" opacity="0.4">
                <g id="Group_5909" data-name="Group 5909">
                  <g id="Group_5908" data-name="Group 5908" clipPath="url(#clip-path-51)">
                    <path id="Path_3321" data-name="Path 3321" d="M562.974,359.334s-7.829,2.742-7.912,9.2,6.821,5.228,6.821,5.228Z" transform="translate(-555.061 -359.334)" />
                  </g>
                </g>
              </g>
              <path id="Path_3322" data-name="Path 3322" d="M633.082,390.862l-1.761-6.4c6.156-.667,10.269.849,13.132-.5l2.577,2.145-1.7,3.195a42,42,0,0,1-12.245,1.565" transform="translate(-377.042 -229.309)" fill="#e9b376" />
              <path id="Path_3323" data-name="Path 3323" d="M653.874,386.89l-.684-2.418c1.675-1.763.961-2.429-.829-3.244-.28-.678.471-1.094.671-1.213l4.41-2.668a.781.781,0,0,1,1.167.464l1.642,5.8,2.3,8.129c.627,2.216-1.477,3.307-1.941,2.453-2.068-3.848-4.1-6.413-5.651-7.344-.276-.176-.364.11-1.083.037" transform="translate(-389.57 -225.292)" fill="#407bff" />
              <path id="Path_3324" data-name="Path 3324" d="M660.407,400.673a2.216,2.216,0,0,1,.539-1.765" transform="translate(-394.4 -238.238)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3325" data-name="Path 3325" d="M663.217,402.809a2.217,2.217,0,0,1,.539-1.766" transform="translate(-396.078 -239.514)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <path id="Path_3326" data-name="Path 3326" d="M665.889,405.7a2.217,2.217,0,0,1,.539-1.766" transform="translate(-397.674 -241.24)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
              <g id="Group_5913" data-name="Group 5913" transform="translate(267.621 151.942)" opacity="0.7">
                <g id="Group_5912" data-name="Group 5912">
                  <g id="Group_5911" data-name="Group 5911" clipPath="url(#clip-path-52)">
                    <path id="Path_3327" data-name="Path 3327" d="M665.773,377.605a.787.787,0,0,1,.091.21l1.642,5.8,2.3,8.129a2.194,2.194,0,0,1-.69,2.475c-.093-.338-4.538-16.527-4.669-16.72l.331-.191a.779.779,0,0,1,1,.292" transform="translate(-664.446 -377.238)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3328" data-name="Path 3328" d="M602.237,333.937l.771,3.872s1.977,7.681-.794,11.539a11.119,11.119,0,0,1-6.554,4.343l.8,7.407-.8.5s-5.11,0-15.571-.478c-9.937-.454-18.479-.928-20.242-4.014s-2.17-4.584.713-8.264c2.606-3.327,22.445-8.227,23.135-8.425l-.277-1.69-.322-1.2Z" transform="translate(-333.526 -199.436)" fill="#263238" />
              <g id="Group_5916" data-name="Group 5916" transform="translate(224.932 134.501)" opacity="0.3">
                <g id="Group_5915" data-name="Group 5915">
                  <g id="Group_5914" data-name="Group 5914" clipPath="url(#clip-path-53)">
                    <path id="Path_3329" data-name="Path 3329" d="M602.237,333.937l.771,3.872s1.977,7.681-.794,11.539a11.119,11.119,0,0,1-6.554,4.343l.8,7.407-.8.5s-5.11,0-15.571-.478c-9.937-.454-18.479-.928-20.242-4.014s-2.17-4.584.713-8.264c2.606-3.327,22.445-8.227,23.135-8.425l-.277-1.69-.322-1.2Z" transform="translate(-558.458 -333.937)" fill="#fff" />
                  </g>
                </g>
              </g>
              <g id="Group_5919" data-name="Group 5919" transform="translate(239.973 153.719)" opacity="0.4">
                <g id="Group_5918" data-name="Group 5918">
                  <g id="Group_5917" data-name="Group 5917" clipPath="url(#clip-path-54)">
                    <path id="Path_3330" data-name="Path 3330" d="M617.962,382.188c-.61.159-22.161-.536-22.161-.536s6.37,1.092,11.08,1.489a46.65,46.65,0,0,0,11.08-.954" transform="translate(-595.801 -381.652)" />
                  </g>
                </g>
              </g>
              <g id="Group_5922" data-name="Group 5922" transform="translate(250.168 138.816)" opacity="0.4">
                <g id="Group_5921" data-name="Group 5921">
                  <g id="Group_5920" data-name="Group 5920" clipPath="url(#clip-path-55)">
                    <path id="Path_3331" data-name="Path 3331" d="M621.114,346.815l12.41-2.164s-8.945-.145-12.41,2.164" transform="translate(-621.114 -344.649)" />
                  </g>
                </g>
              </g>
              <path id="Path_3332" data-name="Path 3332" d="M524.36,323.718a4.71,4.71,0,0,0-2.576-1.9c-1.541-.309-2.689-.965-3.85-1.115s-.108,1.028.58,1.541,1.461,1.1.992,1.556-5.91,1.834-6.489,2.538.371.755.371.755-1.313.371-1.307.931c0,.145.24.481.865.406,1.405-.169,4.336-.676,4.336-.676-.169.026-3.741.91-4.259,1.112-.677.264-.861.734-.695,1.121.236.551,4.7-.394,4.919-.3,0,0-2.59.458-2.838,1.191s1.4.534,2.817.082c1.3-.414,3.958-.886,5.239-1.435,1.578-.677,2.486-1.485,2.52-3.095a5.471,5.471,0,0,0-.625-2.709" transform="translate(-305.828 -191.523)" fill="#e9b376" />
              <path id="Path_3333" data-name="Path 3333" d="M538.341,325.768l3.973-1.589,1.373,4.3-4.66,1.662Z" transform="translate(-321.512 -193.608)" fill="#e9b376" />
              <path id="Path_3334" data-name="Path 3334" d="M600.664,240.682s-1.079-2.437-.85-7.262c0,0-.264-.053-.265-.058.029-.674.081-1.3.142-1.852a.092.092,0,0,0,0-.021l-5.259,5.291a21.131,21.131,0,0,1,.612,5.572s-.269,2.319,2.59,1.734c2.089-.428,3.032-3.4,3.032-3.4" transform="translate(-355.009 -138.251)" fill="#e9b376" />
              <g id="Group_5925" data-name="Group 5925" transform="translate(239.8 98.932)" opacity="0.2">
                <g id="Group_5924" data-name="Group 5924">
                  <g id="Group_5923" data-name="Group 5923" clipPath="url(#clip-path-56)">
                    <path id="Path_3335" data-name="Path 3335" d="M595.372,247.277l3.361-1.651c-.058,1.182-.723,2.725-3.169,3.423a11.238,11.238,0,0,0-.192-1.773" transform="translate(-595.372 -245.626)" />
                  </g>
                </g>
              </g>
              <path id="Path_3336" data-name="Path 3336" d="M589.2,216.7c.52,4.012.894,6.265-.724,8.668a5.3,5.3,0,0,1-9.277-1.022c-1.542-3.409-2.133-9.4,1.46-11.688A5.582,5.582,0,0,1,589.2,216.7" transform="translate(-345.193 -126.477)" fill="#e9b376" />
              <path id="Path_3337" data-name="Path 3337" d="M592.145,208.543c-1.858-2.175-5.317-.27-6.631,1.141-.2.211,3.361,1.216,3.15,1.182l1.124.932a8.549,8.549,0,0,1,.7,2.511,6.164,6.164,0,0,0,1.522,2.928,15.747,15.747,0,0,0,2.464,2.179c1.254-.942.871-3.367,1.031-4.886.239-2.263.186-6.5-3.359-5.986" transform="translate(-349.679 -123.959)" fill="#263238" />
              <path id="Path_3338" data-name="Path 3338" d="M606.294,228.412a3.8,3.8,0,0,1-1.444,2.546c-1.07.816-1.76-.393-1.889-1.664-.116-1.144-.051-2.577,1.228-2.893a1.746,1.746,0,0,1,2.1,2.012" transform="translate(-360.081 -135.18)" fill="#e9b376" />
              <path id="Path_3339" data-name="Path 3339" d="M590.821,228.6c.078.406-.075.774-.34.822s-.545-.241-.623-.647.074-.774.34-.822.545.241.623.647" transform="translate(-352.266 -136.139)" fill="#263238" />
              <path id="Path_3340" data-name="Path 3340" d="M580.977,230.7c.078.406-.074.774-.34.822s-.545-.241-.623-.647.074-.774.34-.822.545.241.623.647" transform="translate(-346.387 -137.393)" fill="#263238" />
              <path id="Path_3341" data-name="Path 3341" d="M583.84,230.493s-.9,2.264-1.15,3.164a1.95,1.95,0,0,0,1.59-.021Z" transform="translate(-347.998 -137.657)" fill="#d58745" />
              <path id="Path_3342" data-name="Path 3342" d="M591.028,223.511a.2.2,0,0,1-.2.01,1.56,1.56,0,0,0-1.4-.048.2.2,0,0,1-.265-.072.192.192,0,0,1,.072-.263,1.941,1.941,0,0,1,1.773.041.191.191,0,0,1,.019.332" transform="translate(-351.85 -133.15)" fill="#263238" />
              <path id="Path_3343" data-name="Path 3343" d="M590.558,239.186a2.41,2.41,0,0,0,1.522-1.137" transform="translate(-352.697 -142.169)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
              <g id="Group_5928" data-name="Group 5928" transform="translate(243.408 92.027)" opacity="0.2">
                <g id="Group_5927" data-name="Group 5927">
                  <g id="Group_5926" data-name="Group 5926" clipPath="url(#clip-path-57)">
                    <path id="Path_3344" data-name="Path 3344" d="M606.166,228.916a.735.735,0,0,0-.812-.109,1.266,1.266,0,0,0-.581.627,2.022,2.022,0,0,0-.189,1.019" transform="translate(-604.478 -228.632)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
                  </g>
                </g>
              </g>
              <path id="Path_3345" data-name="Path 3345" d="M576.976,226.536a.2.2,0,0,1-.156-.3,1.9,1.9,0,0,1,1.533-.857.2.2,0,0,1,.189.2.192.192,0,0,1-.2.187,1.487,1.487,0,0,0-1.207.692.19.19,0,0,1-.162.083" transform="translate(-344.472 -134.599)" fill="#263238" />
              <path id="Path_3346" data-name="Path 3346" d="M584.135,206.668a6.846,6.846,0,0,0-6.742-2.045c-3.436.81-5.229,3.324-5.3,5.729a4.861,4.861,0,0,0,.472,2.343.5.5,0,0,0,.924-.091,3.191,3.191,0,0,1,.135-.366c.014.027.029.054.044.08a.5.5,0,0,0,.927-.223,2.47,2.47,0,0,1,.053-.461l.007.011a.494.494,0,0,0,.895-.168,3.586,3.586,0,0,1,4.741-2.438l1.485.882a6.186,6.186,0,0,0,2.357-3.254" transform="translate(-341.67 -122.085)" fill="#263238" />
              <g id="Group_5931" data-name="Group 5931" transform="translate(241.713 137.929)" opacity="0.4">
                <g id="Group_5930" data-name="Group 5930">
                  <g id="Group_5929" data-name="Group 5929" clipPath="url(#clip-path-58)">
                    <path id="Path_3347" data-name="Path 3347" d="M607.286,342.725l-.234,1.572-6.929,2.086,1.6,1.024,6.858-1.906.282-3.053Z" transform="translate(-600.122 -342.447)" />
                  </g>
                </g>
              </g>
              <path id="Path_3348" data-name="Path 3348" d="M603.1,253.652c-3.111-1.995-8.595-1.046-8.595-1.046l-10.058,6.137,6.877,16.166s2.87,6.875,4.418,11.454c.383,1.135,1.117,2.717,2.305,2.562l19.1-3.454a1.612,1.612,0,0,0,1.27-2.014c-1.844-6.661-12.535-28.018-15.322-29.8" transform="translate(-349.05 -150.728)" fill="#407bff" />
              <g id="Group_5934" data-name="Group 5934" transform="translate(242.388 111.915)" opacity="0.4">
                <g id="Group_5933" data-name="Group 5933">
                  <g id="Group_5932" data-name="Group 5932" clipPath="url(#clip-path-59)">
                    <path id="Path_3349" data-name="Path 3349" d="M615.259,277.862a46.488,46.488,0,0,1-8.436,10.787,20.923,20.923,0,0,0-3.678,4.955l-1.348-3.443Z" transform="translate(-601.797 -277.862)" />
                  </g>
                </g>
              </g>
              <path id="Path_3350" data-name="Path 3350" d="M569.727,262.134,556.7,278.373l-15.289,6.941,1.524,6.329s12.4-3.345,17.38-6.45,13.2-11.788,13.2-11.788,7.164-5.749,6.822-10.4-7.161-5.737-10.61-.871" transform="translate(-323.347 -154.66)" fill="#407bff" />
              <path id="Path_3351" data-name="Path 3351" d="M594.156,255.162s.484-2.481.718-2.381,2.563,1.5,2.563,1.5l2.6-3.9,1.563,1.034-3.6,7.457Z" transform="translate(-354.846 -149.532)" fill="#407bff" />
              <g id="Group_5937" data-name="Group 5937" transform="translate(235.164 106.631)" opacity="0.4">
                <g id="Group_5936" data-name="Group 5936">
                  <g id="Group_5935" data-name="Group 5935" clipPath="url(#clip-path-60)">
                    <path id="Path_3352" data-name="Path 3352" d="M586.864,274.148l-3-4.122,2.426,6.454,1.181-1.257,8.292-10.482Z" transform="translate(-583.861 -264.742)" />
                  </g>
                </g>
              </g>
              <g id="Group_5940" data-name="Group 5940" transform="translate(239.31 101.743)" opacity="0.4">
                <g id="Group_5939" data-name="Group 5939">
                  <g id="Group_5938" data-name="Group 5938" clipPath="url(#clip-path-61)">
                    <path id="Path_3353" data-name="Path 3353" d="M594.156,256.493a18.809,18.809,0,0,1,2.17,1.525,13.7,13.7,0,0,1,.907-1.739l2.286.87,1.877-4.542-1.847,5.232-2.165-1.047-1.057,1.918Z" transform="translate(-594.156 -252.606)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_5971" data-name="Group 5971">
            <g id="Group_5970" data-name="Group 5970" clipPath="url(#clip-path)">
              <path id="Path_3354" data-name="Path 3354" d="M200.4,123.8a2.94,2.94,0,0,0-.167-1.193c-.487-1.308-1.878-2.086-2.472-3.349-.877-1.863.359-4.184,2.172-5.161a7.205,7.205,0,0,1,6.717,0c1.813.977,3.049,3.3,2.172,5.161-.594,1.263-1.985,2.041-2.472,3.349a2.94,2.94,0,0,0-.167,1.193h18.5V105.151a3.3,3.3,0,0,1,2.32-.078c1.308.488,2.086,1.878,3.349,2.472,1.863.877,4.184-.359,5.161-2.172a7.208,7.208,0,0,0,0-6.717c-.977-1.813-3.3-3.05-5.161-2.172-1.263.594-2.041,1.984-3.349,2.472a3.3,3.3,0,0,1-2.32-.078V82.3l-41.357-.185v41.5Z" transform="translate(-109.485 -49.039)" fill="#263238" />
              <g id="Group_5945" data-name="Group 5945" transform="translate(73.838 33.073)" opacity="0.4">
                <g id="Group_5944" data-name="Group 5944">
                  <g id="Group_5943" data-name="Group 5943" clipPath="url(#clip-path-48)">
                    <path id="Path_3355" data-name="Path 3355" d="M200.4,123.8a2.94,2.94,0,0,0-.167-1.193c-.487-1.308-1.878-2.086-2.472-3.349-.877-1.863.359-4.184,2.172-5.161a7.205,7.205,0,0,1,6.717,0c1.813.977,3.049,3.3,2.172,5.161-.594,1.263-1.985,2.041-2.472,3.349a2.94,2.94,0,0,0-.167,1.193h18.5V105.151a3.3,3.3,0,0,1,2.32-.078c1.308.488,2.086,1.878,3.349,2.472,1.863.877,4.184-.359,5.161-2.172a7.208,7.208,0,0,0,0-6.717c-.977-1.813-3.3-3.05-5.161-2.172-1.263.594-2.041,1.984-3.349,2.472a3.3,3.3,0,0,1-2.32-.078V82.3l-41.357-.185v41.5Z" transform="translate(-183.323 -82.112)" />
                  </g>
                </g>
              </g>
              <path id="Path_3356" data-name="Path 3356" d="M203.513,123.8a2.94,2.94,0,0,0-.167-1.193c-.487-1.308-1.878-2.086-2.472-3.349-.877-1.863.359-4.184,2.172-5.161a7.205,7.205,0,0,1,6.717,0c1.813.977,3.049,3.3,2.172,5.161-.594,1.263-1.985,2.041-2.472,3.349a2.94,2.94,0,0,0-.167,1.193h18.5V105.151a3.3,3.3,0,0,1,2.32-.078c1.308.488,2.086,1.878,3.349,2.472,1.863.877,4.184-.359,5.161-2.172a7.208,7.208,0,0,0,0-6.717c-.977-1.813-3.3-3.05-5.161-2.172-1.263.594-2.041,1.984-3.349,2.472a3.3,3.3,0,0,1-2.32-.078V82.3l-41.357-.185v41.5Z" transform="translate(-111.345 -49.039)" fill="#263238" />
              <path id="Path_3357" data-name="Path 3357" d="M157.994,130.456s-3.02,10.7-1.088,12.562,8.692.621,8.347.483,1.725-13.005,1.862-12.539-9.121-.506-9.121-.506" transform="translate(-93.323 -77.912)" fill="#b97964" />
              <path id="Path_3358" data-name="Path 3358" d="M169.879,369.607l-.009,9.282,4.31.335,1.515-8.72c-1.971-1.424-3.911-1.764-5.815-.9" transform="translate(-101.451 -220.457)" fill="#b97964" />
              <path id="Path_3359" data-name="Path 3359" d="M169.1,389.3c-.424,1.225-.452,6.237-.452,6.237l9.135.011c.543-1.812-.3-3.05-1.363-3.214a2.963,2.963,0,0,1-1.389-1.024c0-.058-.561-2.842-.906-2.975,0,0-.238-.29-.441,0a4.89,4.89,0,0,0-.379,1.23c-1.426-1.917-2.823-1.661-4.206-.264" transform="translate(-100.723 -231.838)" fill="#263238" />
              <path id="Path_3360" data-name="Path 3360" d="M169.231,393.744a3.051,3.051,0,0,1,3.04.429c.158.12.307-.16.151-.279a3.31,3.31,0,0,0-3.27-.462c-.179.071-.1.384.079.312" transform="translate(-100.961 -234.825)" fill="#fff" />
              <path id="Path_3361" data-name="Path 3361" d="M177.7,404.145h0l-9-.01a.162.162,0,0,1,0-.323l9,.01a.162.162,0,0,1,0,.323" transform="translate(-100.661 -241.167)" fill="#fff" />
              <path id="Path_3362" data-name="Path 3362" d="M216.039,366.6l-.01,10.476,4.147.342,2.04-9.806a5.788,5.788,0,0,0-6.176-1.012" transform="translate(-129.018 -218.662)" fill="#b97964" />
              <path id="Path_3363" data-name="Path 3363" d="M214.893,389.355c-.424,1.225-.452,6.237-.452,6.237l10.5.012a2.214,2.214,0,0,0-1.657-3.051,6.838,6.838,0,0,1-2.455-1.188c0-.058-.561-2.842-.906-2.975,0,0-.238-.29-.441,0a4.89,4.89,0,0,0-.379,1.23c-1.426-1.917-2.823-1.661-4.206-.264" transform="translate(-128.07 -231.87)" fill="#263238" />
              <path id="Path_3364" data-name="Path 3364" d="M215.02,393.8a3.051,3.051,0,0,1,3.04.429c.157.12.307-.161.151-.28a3.311,3.311,0,0,0-3.27-.462c-.179.071-.1.384.079.312" transform="translate(-128.308 -234.858)" fill="#fff" />
              <path id="Path_3365" data-name="Path 3365" d="M224.874,404.2h0l-10.389-.012a.162.162,0,0,1,0-.323l10.389.012a.162.162,0,0,1,0,.323" transform="translate(-128.007 -241.199)" fill="#fff" />
              <path id="Path_3366" data-name="Path 3366" d="M164.092,271.623l9.184.489c2.06-6.678,4.1-30.621,4.307-37.809.252-8.589,3.327-30.146.608-34.631s-15.019-.913-15.019-.913,1.533,26.248.92,34.618c-.531,7.255-1.353,28.581,0,38.246" transform="translate(-97.451 -117.899)" fill="#263238" />
              <path id="Path_3367" data-name="Path 3367" d="M174.536,376.574l-.6,3.068-9.952-.323-.365-2.946Z" transform="translate(-97.719 -224.78)" fill="#407bff" />
              <path id="Path_3368" data-name="Path 3368" d="M196.614,270.264l9.1.489c1.108-7.392,5.561-34.313,5.2-39.47-.447-6.3-5.72-33.564-7.681-35.666s-16.318,3.347-16.318,3.347,5.9,23.446,10.553,33.587c.967,2.107-1.6,28.723-.854,37.714" transform="translate(-111.631 -116.539)" fill="#263238" />
              <path id="Path_3369" data-name="Path 3369" d="M220.191,376.283l-.464,2.838-10.118-.649-.2-2.972Z" transform="translate(-125.062 -224.259)" fill="#407bff" />
              <g id="Group_5948" data-name="Group 5948" transform="translate(73.612 92.611)" opacity="0.3">
                <g id="Group_5947" data-name="Group 5947">
                  <g id="Group_5946" data-name="Group 5946" clipPath="url(#clip-path-64)">
                    <path id="Path_3370" data-name="Path 3370" d="M190.18,240.417l-2.673-8.936-4.743-1.548,3.708,2.875,2.982,16.287Z" transform="translate(-182.763 -229.933)" />
                  </g>
                </g>
              </g>
              <path id="Path_3371" data-name="Path 3371" d="M213.88,58.457c0,.385.169.676.412.717s.467-.284.487-.676-.162-.677-.406-.717-.446.3-.494.676" transform="translate(-127.735 -34.506)" fill="#263238" />
              <path id="Path_3372" data-name="Path 3372" d="M212.872,59.331a13.5,13.5,0,0,0,.913,3.619,2.217,2.217,0,0,1-1.853-.149Z" transform="translate(-126.571 -35.434)" fill="#a24e3f" />
              <path id="Path_3373" data-name="Path 3373" d="M211.841,54.641a.195.195,0,0,1-.144-.35l0,0a2.22,2.22,0,0,1,1.867-.825.217.217,0,0,1,.2.235v0a.237.237,0,0,1-.25.2,1.758,1.758,0,0,0-1.468.676.216.216,0,0,1-.2.068" transform="translate(-126.392 -31.927)" fill="#263238" />
              <path id="Path_3374" data-name="Path 3374" d="M196.4,53.974a5.538,5.538,0,0,1-.187.607,5.992,5.992,0,0,1-2.16,2.828,5.871,5.871,0,0,1-.789.48,5.542,5.542,0,0,1-6.532-1.021c-2.186-2.17-2.129-4.677-2.378-8.919a5.792,5.792,0,0,1,.441-2.67,5.946,5.946,0,0,1,7.724-3.135h0c4.216,1.695,4.8,8,3.879,11.829" transform="translate(-110.091 -24.903)" fill="#b97964" />
              <g id="Group_5951" data-name="Group 5951" transform="translate(78.426 31.685)" opacity="0.2">
                <g id="Group_5950" data-name="Group 5950">
                  <g id="Group_5949" data-name="Group 5949" clipPath="url(#clip-path-65)">
                    <path id="Path_3375" data-name="Path 3375" d="M200.234,79.489a5.849,5.849,0,0,1-.789.48,5.573,5.573,0,0,1-4.73.2,6.447,6.447,0,0,1,.774-1.44,8.668,8.668,0,0,1,4.746.756" transform="translate(-194.714 -78.668)" />
                  </g>
                </g>
              </g>
              <path id="Path_3376" data-name="Path 3376" d="M186.85,78.019c2.129,1.9,7.337,1.2,7.337,1.2s3.25-.878,2.565-1.874c-1.911-.311-2.84-1.189-3.224-2.318a5.423,5.423,0,0,1-.192-2.149,10.55,10.55,0,0,1,.161-1.2l-6.438-4.687c.841,3.094,1.874,8.786-.208,11.027" transform="translate(-111.592 -40.009)" fill="#b97964" />
              <path id="Path_3377" data-name="Path 3377" d="M190.878,47.926l.93.342-.462-1.868a3.45,3.45,0,0,0,0-3.813s3.759-1.123,1.636-3.063c-.789-.725-1.771,0-4.367-1.108-1.407-.607-5.81-.653-6.028,1.108,0,0-3.173,0-3.515,5.309a10.536,10.536,0,0,0,3.359,8.393s3.832.888,5.712-.623,2.736-4.676,2.736-4.676" transform="translate(-106.933 -22.715)" fill="#263238" />
              <path id="Path_3378" data-name="Path 3378" d="M202.2,59.233a2.736,2.736,0,0,0,1.433,1.884c.878.368,1.459-.847,1.329-2.124-.119-1.133-.717-2.726-1.656-2.512s-1.413,1.608-1.106,2.752" transform="translate(-120.701 -33.72)" fill="#b97964" />
              <path id="Path_3379" data-name="Path 3379" d="M157.3,91.836s-1.5.455-2.24.727c-1.551.568-2.575,2.3-3.137,3.991-1.372,4.144-3.589,13.953-3.589,13.953l8.045,1.691,4.292-9.242Z" transform="translate(-88.59 -54.847)" fill="#407bff" />
              <g id="Group_5954" data-name="Group 5954" transform="translate(59.745 36.989)" opacity="0.5">
                <g id="Group_5953" data-name="Group 5953">
                  <g id="Group_5952" data-name="Group 5952" clipPath="url(#clip-path-66)">
                    <path id="Path_3380" data-name="Path 3380" d="M157.3,91.836a8.8,8.8,0,0,0-3.286,1.337,7,7,0,0,0-2.091,3.382c-1.372,4.144-3.589,13.953-3.589,13.953l8.045,1.691,4.292-9.242Z" transform="translate(-148.335 -91.836)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3381" data-name="Path 3381" d="M184.948,91.458a62.794,62.794,0,0,0-19.483-.476l-2.3,36.278a95.767,95.767,0,0,1-1.164,10.313c9.971,4.281,21.767,3.057,28.437-.542-.077.151-.3-3.807-.638-9.606-.6-10.219-1.589-26.151-2.862-35.375-.035-.228-1.757-.567-1.994-.592" transform="translate(-96.754 -53.993)" fill="#407bff" />
              <g id="Group_5957" data-name="Group 5957" transform="translate(65.251 36.413)" opacity="0.5">
                <g id="Group_5956" data-name="Group 5956">
                  <g id="Group_5955" data-name="Group 5955" clipPath="url(#clip-path-67)">
                    <path id="Path_3382" data-name="Path 3382" d="M184.948,91.458a62.794,62.794,0,0,0-19.483-.476l-2.3,36.278a95.767,95.767,0,0,1-1.164,10.313c9.971,4.281,21.767,3.057,28.437-.542-.077.151-.3-3.807-.638-9.606-.6-10.219-1.589-26.151-2.862-35.375-.035-.228-1.757-.567-1.994-.592" transform="translate(-162.005 -90.406)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3383" data-name="Path 3383" d="M186.582,88.26a3.349,3.349,0,0,1,1.184-1.78l5.464.421,2.562,1.689Z" transform="translate(-111.432 -51.648)" fill="#407bff" />
              <g id="Group_5960" data-name="Group 5960" transform="translate(75.15 34.832)" opacity="0.5">
                <g id="Group_5959" data-name="Group 5959">
                  <g id="Group_5958" data-name="Group 5958" clipPath="url(#clip-path-68)">
                    <path id="Path_3384" data-name="Path 3384" d="M186.582,88.26a3.349,3.349,0,0,1,1.184-1.78l5.464.421,2.562,1.689Z" transform="translate(-186.582 -86.48)" fill="#fff" />
                  </g>
                </g>
              </g>
              <g id="Group_5963" data-name="Group 5963" transform="translate(87.94 48.756)" opacity="0.2">
                <g id="Group_5962" data-name="Group 5962">
                  <g id="Group_5961" data-name="Group 5961" clipPath="url(#clip-path-69)">
                    <path id="Path_3385" data-name="Path 3385" d="M218.336,121.051c.875,4.086,2.133,12.1,4.507,14.888l-.564-8.107Z" transform="translate(-218.336 -121.051)" />
                  </g>
                </g>
              </g>
              <g id="Group_5966" data-name="Group 5966" transform="translate(65.291 46.762)" opacity="0.2">
                <g id="Group_5965" data-name="Group 5965">
                  <g id="Group_5964" data-name="Group 5964" clipPath="url(#clip-path-70)">
                    <path id="Path_3386" data-name="Path 3386" d="M164.091,126.576,164.3,116.1l-2.2,9.979Z" transform="translate(-162.103 -116.099)" />
                  </g>
                </g>
              </g>
              <path id="Path_3387" data-name="Path 3387" d="M228.719,124.184c-.307.118,5.61,13.43,8.019,13.549s13.719-5.028,13.719-5.028l-2.537-3.029-8.548.49-4.36-9.282Z" transform="translate(-136.59 -72.196)" fill="#b97964" />
              <path id="Path_3388" data-name="Path 3388" d="M217.708,93.977c-3.069,2.027-4.59,6.616,3.637,20.784l8.884-5.425c-1.8-3.316-3.7-5.726-5.817-10.528-4.265-6.141-6.7-4.83-6.7-4.83" transform="translate(-128.555 -56.056)" fill="#407bff" />
              <g id="Group_5969" data-name="Group 5969" transform="translate(86.698 37.804)" opacity="0.5">
                <g id="Group_5968" data-name="Group 5968">
                  <g id="Group_5967" data-name="Group 5967" clipPath="url(#clip-path-71)">
                    <path id="Path_3389" data-name="Path 3389" d="M217.708,93.977c-3.069,2.027-4.59,6.616,3.637,20.784l8.884-5.425c-1.8-3.316-3.7-5.726-5.817-10.528-4.265-6.141-6.7-4.83-6.7-4.83" transform="translate(-215.253 -93.859)" fill="#fff" />
                  </g>
                </g>
              </g>
              <path id="Path_3390" data-name="Path 3390" d="M282.765,137.336c0-.777-.239-1.622-.657-1.647s-2.653,0-2.653,0c0-.627,1.176-1.313.793-1.742-.5-.559-3.4,1.829-3.578,2.316a5.812,5.812,0,0,1-.538,1.2l1.53,3.512s2.832,1.866,3.489,1.208a1.855,1.855,0,0,0,.6-1.36,3.045,3.045,0,0,0,.6-1.508c-.06-.6.418-1.2.418-1.972" transform="translate(-164.913 -79.945)" fill="#b97964" />
            </g>
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
