import React, { FC, useCallback, useEffect, useState } from "react";
import _, { uniq, isEqual } from "lodash";
import { Button, Card, Input } from "../../../components";
import { CalendarIcon, PhoneIcon, TrashIcon } from "../../../components/icons";
import { Form, FormArray, FormControl, FormGroup, useForm, Validators } from "../../../components/ReactiveForm";
import { NumberModel } from "../../../utils/types";
import { MessageBoxService, NumberService, ToastService } from "../../../services";
import { IExtraInfoForm, TextFileInputForm } from "../../../parts";
import TargetNumbers from "./TargetNumbers";
import ExtraInfo from "./ExtraInfo";
import Webhooks from "./Webhooks";
import Features from "./Features";
import RecordCalls from "./RecordCalls";
import WhisperMessage from "./WhisperMessage";
import VoiceMail from "./VoiceMail";
import AdvancedSettings from "./AdvancedSettings";
import { formatDateTime, formatPhoneNumber, uploadTTS } from "../../../utils/helpers";
import { AUDIO_TYPE, NUMBER_FEATURE } from "../../../utils/enums";
import { stripSpaces } from "../../../utils/helpers/form.helper";
import GA4 from "./Integration/GA4";

export interface INumberEditInitParams {
  onSubmit: () => void;
}

export interface INumberEditProps {
  number: NumberModel;
  setTouched?: any
  onInit?: (params: INumberEditInitParams) => void;
  onClose?: (result?: any) => void;
}

export type INumberFeaturesForm = {
  blockAnonymous: FormControl;
  otpMode: FormControl;
  parkNumber: FormControl;
  parkNumberForm?: TextFileInputForm;
};

export type INumberAdvancedSettingsForm = {
  showAsCallerId: FormControl;
  voiceMail: FormControl;
  voiceMailForm: TextFileInputForm;
  smsForwarding: FormControl;
  forwardType: FormControl;
};

export type IThirdPartyIntegrations = {
  ga4: FormControl
}

export type INumberEditForm = {
  friendlyName: FormControl;
  targets: FormArray;
  targetsCallStrategy: FormControl;
  extraInfo: FormArray<IExtraInfoForm>;
  webhooks: FormControl;
  features: FormGroup<INumberFeaturesForm>,
  recordCalls: TextFileInputForm;
  whisperMessage: TextFileInputForm;
  settings: FormGroup<INumberAdvancedSettingsForm>,
  // third_party_integrations: FormGroup<IThirdPartyIntegrations>
  third_party_integrations: FormControl
  // ga4: FormControl
};


const NumberEdit: FC<INumberEditProps> = ({
  number,
  setTouched = () => { },
  onInit = () => { },
  onClose = () => { },
}) => {
  const [form, formData] = useForm<INumberEditForm>({
    friendlyName: new FormControl(''),
    targets: new FormArray([], [Validators.required(), Validators.phone(number.country)]),
    targetsCallStrategy: new FormControl(NUMBER_FEATURE.HUNT_GROUP_LINEAR),
    extraInfo: new FormArray<IExtraInfoForm>([]),
    webhooks: new FormControl([]),
    features: new FormGroup({
      blockAnonymous: new FormControl(false),
      otpMode: new FormControl(false),
      parkNumber: new FormControl(false),
    }),
    // ga4: new FormControl([]),
    // third_party_integrations: new FormGroup({
    //   ga4: new FormControl([])
    // }),
    third_party_integrations: new FormControl([]),
    recordCalls: new FormGroup({
      enabled: new FormControl(false),
      mode: new FormControl('text'),
      text: new FormControl(''),
      file: new FormControl(null),
      recordCallsOnAnswered: new FormControl(false),
    }),
    whisperMessage: new FormGroup({
      enabled: new FormControl(false),
      mode: new FormControl('text'),
      text: new FormControl(''),
      file: new FormControl(null),
    }),
    settings: new FormGroup({
      showAsCallerId: new FormControl(false),
      voiceMail: new FormControl(false),
      voiceMailForm: new FormGroup({
        enabled: new FormControl(false),
        mode: new FormControl('text'),
        text: new FormControl(''),
        file: new FormControl(null),
      }),
      smsForwarding: new FormControl(false),
      forwardType: new FormControl(''),
    }),
  });

  const [initialForm, setInitialForm] = useState(form.getFormData())

  let { originalFormStripped, initialFormStripped } = stripSpaces(form.getFormData(), initialForm)

  setTouched(!_.isEqual(originalFormStripped, initialFormStripped))

  useEffect(() => {
    form.patch({
      friendlyName: number.name || '',
    });
    initialForm.friendlyName = number.name || ''
  }, [number]);

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: 'Are you sure you want to delete this number?',
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        NumberService.delete(number.number).then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose(true);
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting number failed');
        });
      }
    });
  };

  const onSubmit = useCallback(async () => {
    if (form.validate()) {
      const formData = form.getFormData();

      const data: any = {
        name: formData.friendlyName,
      };
      let features = [];

      if (formData.features.otpMode) {
        features = [NUMBER_FEATURE.VOICE_OTP];
      } else if (formData.features.parkNumber) {
        if (formData.features.parkNumberForm.mode === 'text') {
          features = [NUMBER_FEATURE.KEEP_NUMBER_TTS];
          data.tts = formData.features.parkNumberForm.text;
        } else {
          features = [NUMBER_FEATURE.KEEP_NUMBER_FILE];
          data.keep_number_file = formData.features.parkNumberForm.file;
        }
      } else if (formData.settings.voiceMail) {
        features = [NUMBER_FEATURE.VOICE_MAIL];
        if (formData.settings.voiceMailForm.mode === 'text') {
          if (formData.settings.voiceMailForm.text) {
            data.voicemail_lite_file = await uploadTTS(
              formData.settings.voiceMailForm.text, AUDIO_TYPE.VOICE_MAIL_LITE
            );
          }
        } else {
          data.voicemail_lite_file = formData.settings.voiceMailForm.file;
        }
      } else {
        data.webhooks = formData.webhooks;

        const targetNumbers = uniq(formData.targets.map((item) => item.replace(/\D/g, '')));
        data.target_number = targetNumbers.join(',');
        if (targetNumbers.length > 1) {
          features.push(formData.targetsCallStrategy);
        }

        if (formData.recordCalls.enabled) {
          if (formData.recordCalls.recordCallsOnAnswered) {
            features.push(NUMBER_FEATURE.RECORD_CALL_ON_ANSWER);
          } else {
            features.push(NUMBER_FEATURE.RECORD_CALL);
          }
          if (formData.recordCalls.file) {
            data.record_call_file = formData.recordCalls.file;
          }
        }
        if (formData.whisperMessage.enabled) {
          if (formData.whisperMessage.mode === 'text') {
            features.push(NUMBER_FEATURE.WHISPER);
            data.tts = formData.whisperMessage.text;
          } else {
            features.push(NUMBER_FEATURE.WHISPER_FILE);
            data.whisper_file = formData.whisperMessage.file;
          }
        }
        if (formData.settings.showAsCallerId) {
          features.push(NUMBER_FEATURE.SHOW_CALLER_ID);
        }
        if (!formData.settings.smsForwarding) {
          features.push(NUMBER_FEATURE.DISABLE_SMS);
        } else if (formData.settings.forwardType === NUMBER_FEATURE.SPAN_MULTIPLE_SMS) {
          features.push(NUMBER_FEATURE.SPAN_MULTIPLE_SMS);
        }
        if (formData.third_party_integrations) {
          data.third_party_integrations = formData.third_party_integrations
        }
        if (formData.features.blockAnonymous) {
          features.push(NUMBER_FEATURE.BLOCK_ANONYMOUS_CALLERS);
        }
      }

      const metadata: any = {};
      formData.extraInfo.forEach((item) => {
        metadata[item.label] = item.content;
      });

      data.meta_data = metadata;
      if (features.length > 0)
        data.features = features.join();

      NumberService.update(number.number, data).then(() => {
        Object.assign(number, data);
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onClose(form.getFormData());
      }).catch((err) => {
        console.error(err)
        ToastService.showHttpError(err, 'Updating number failed');
      })
    }
  }, [form]);

  const onUpdate = useCallback((data) => {
    let shouldUpdate = false;
    Object.entries(data).forEach(([key, value]) => {
      if (!isEqual(number[key], value)) {
        shouldUpdate = true;
      }
    });

    let formData = data;
    if (data.features) {
      formData = {
        ...formData,
        features: data.features.join(),
      };
    }
    if (data.meta) {
      formData.meta_data = data.meta
      delete formData.meta;
    }
    if (data.webhooks) {
      formData = {
        ...formData,
        webhooks: formData.webhooks,
      };
    }

    if (data.ga4) {
      formData = {
        ...formData,
        third_party_integrations: formData.ga4,
        ga4: formData.ga4
      };
    }

    if (shouldUpdate) {
      //  TODO:
    }
  }, [number]);

  useEffect(() => {
    onInit({
      onSubmit,
    });
  }, [onInit, onSubmit]);

  const onSave = () => {
    NumberService.update(number.number, formData).then(() => {
      Object.assign(number, formData);
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
    }).catch((err) => {
      console.error(err)
      ToastService.showHttpError(err, 'Updating number failed');
    })
  };

  return (
    <Form className="pb-20" formGroup={form} onSubmit={onSubmit}>
      <Card size="lg" className="relative">
        <div className="flex items-center">
          <div className="text-3xl font-semibold text-blue">{formatPhoneNumber(number.number)}</div>
          {formData?.features?.otpMode && (
            <div className="text-primary text-md font-semibold uppercase ml-2">∙ OTP Mode</div>
          )}
          {formData?.features?.parkNumber && (
            <div className="text-primary text-md font-semibold uppercase ml-2">∙ Parked</div>
          )}
          {formData?.settings?.voiceMail && (
            <div className="text-primary text-md font-semibold uppercase ml-2">∙ Voice Mail</div>
          )}
        </div>
        <div className="flex items-center flex-wrap text-gray-70 text-sm mt-1 mb-3">
          <div className="flex items-center flex-wrap mb-1">
            <CalendarIcon size={16} />
            <span className="font-semibold ml-1">Issued Date</span>
            <span className="ml-5 sm:ml-1">{formatDateTime(number.issued_date) || '-'}</span>
            <div className="hidden md:block h-3 mx-2 border-l border-gray-70" />
          </div>
          <div className="w-full md:w-auto flex items-center flex-wrap mb-1">
            <CalendarIcon size={16} />
            <span className="font-semibold ml-1">Billing Anniversary</span>
            <span className="ml-5 sm:ml-1">{number.formatBillingAnniversary() ? `In ${number.formatBillingAnniversary()}` : '-'}</span>
            <div className="hidden lg:block h-3 mx-2 border-l border-gray-70" />
          </div>
          <div className="w-full lg:w-auto flex items-center flex-wrap mb-1">
            <PhoneIcon size={16} />
            <span className="font-semibold ml-1">Last Call</span>
            <span className="ml-5 sm:ml-1">{number.formatLastCalled() || '-'}</span>
          </div>
        </div>
        <Input
          label="Friendly Name"
          tooltip="This is the name you would like to give your Sudo Number"
          tooltipPlacement="right"
          control={form.controls.friendlyName}
        // onBlur={onSaveFriendlyName}
        />
      </Card>

      {formData?.features?.otpMode && (
        <div className="bg-info-lighter text-blue-dark font-semibold rounded-md shadow-md px-6 py-4 mb-4.5">
          The information of this number has been hidden, because you are in OTP mode
        </div>
      )}
      {formData?.features?.parkNumber && (
        <div className="bg-info-lighter text-blue-dark font-semibold rounded-md shadow-md px-6 py-4 mb-4.5">
          The information of this number has been hidden, because your number has been parked
        </div>
      )}
      {formData?.settings?.voiceMail && (
        <div className="bg-info-lighter text-blue-dark font-semibold rounded-md shadow-md px-6 py-4 mb-4.5">
          The information of this number has been hidden, because your number is using voice mail
        </div>
      )}

      {!formData?.features?.otpMode && !formData?.features?.parkNumber && !formData?.settings?.voiceMail && (
        <TargetNumbers
          number={number}
          form={form}
          formData={formData}
          onUpdate={onUpdate}
          initialForm={initialForm}
          setTouched={setTouched}
        />
      )}

      <ExtraInfo
        number={number}
        form={form.controls.extraInfo}
        formData={formData.extraInfo}
        onUpdate={onUpdate}
        initialForm={initialForm}
      />

      {!formData?.features?.otpMode && !formData?.features?.parkNumber && !formData?.settings?.voiceMail && (
        <Webhooks
          number={number}
          form={form}
          formData={formData}
          onUpdate={onUpdate}
          initialForm={initialForm}
        />
      )}

      {formData?.third_party_integrations && !formData?.features?.parkNumber && !formData?.features?.otpMode && (
        <GA4
          number={number}
          form={form}
          formData={formData}
          onUpdate={onUpdate}
          initialForm={initialForm}
        />
      )}

      {!formData?.settings?.voiceMail && (
        <Features
          number={number}
          form={form.controls.features}
          formData={formData.features}
          onUpdate={onUpdate}
          initialForm={initialForm}
          setTouched={setTouched}
        />
      )}

      {formData?.settings?.voiceMail && (
        <VoiceMail
          number={number}
          form={form.controls.settings}
          formData={formData.settings}
          onUpdate={onUpdate}
          initialForm={initialForm}
        />
      )}

      {!formData?.features?.otpMode && !formData?.features?.parkNumber && !formData?.settings?.voiceMail && (
        <div className="grid md:grid-cols-2 gap-x-4 gap-y-0">
          <RecordCalls
            number={number}
            form={form.controls.recordCalls}
            formData={formData.recordCalls}
            initialForm={initialForm.recordCalls}
            onUpdate={onUpdate}
            setTouched={setTouched}
          />
          <WhisperMessage
            number={number}
            form={form.controls.whisperMessage}
            formData={formData.whisperMessage}
            initialForm={initialForm.whisperMessage}
            onUpdate={onUpdate}
            setTouched={setTouched}
          />
        </div>
      )}

      {!formData?.features?.otpMode && !formData?.features?.parkNumber && (
        <AdvancedSettings
          number={number}
          form={form.controls.settings}
          formData={formData.settings}
          onUpdate={onUpdate}
          initialForm={initialForm.settings}
        />
      )}

      <div className="bottom-0 fixed lg:pl-50 lg:pr-38 left-0 right-0 pt-4
            pb-8 md:pl-32 md:pr-20 bg-gray-f6  flex items-center justify-between
            pl-8 pr-7">
        <Button
          data-cy="delete-number"
          className="text-sm rounded-full px-6 py-2"
          color="danger"
          variant="outline"
          leftIcon={<TrashIcon size={12} />}
          leftIconClass="!m-0 xs:!mr-2"
          onClick={onDelete}
        >
          <span className="hidden xs:block">Delete Number</span>
        </Button>
        <Button
          data-cy="number-save"
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          type="submit"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default NumberEdit;
