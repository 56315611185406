import React, {useEffect, useMemo, useState} from "react";
import qs from "qs";
import {useLocation} from 'react-router';
import {Link} from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import {Button} from "../../../components";
import {CheckCircleOutlineIcon} from "../../../components/icons";
import {Form, FormControl, Input, useForm, Validators} from "../../../components/ReactiveForm";
import {ROUTES} from "../../../constants";
import SimpleLayout from "../../../layouts/SimpleLayout";
import {AuthService, ToastService} from '../../../services';

const ResetPassword = () => {
  const [form, formData] = useForm<{
    password: FormControl;
    confirmPassword: FormControl;
  }>({
    password: new FormControl('', [Validators.required()]),
    confirmPassword: new FormControl('', [Validators.required()]),
  });
  const { search } = useLocation();
  const [step, setStep] = useState(0);
  const [passwordScore, setPasswordScore] = useState(0);

  const { uid64, token } = useMemo(() => qs.parse(search.replace(/\?/g, '')), [search]);

  useEffect(() => {
    const confirmPasswordValidator = ({ value }: FormControl) => {
      if (form.controls.password.value && value && form.controls.password.value !== value)
        return `Passwords do not match.`;
      return null;
    };
    form.controls.confirmPassword.validators.push(confirmPasswordValidator);
  }, [form]);

  useEffect(() => {
    form.controls.password.setOptions({
      validators: [
        ({ value }: FormControl) => {
          if (!value) {
            return `This field is required.`;
          }
          if (passwordScore < 2) {
            return `Please input strong password.`;
          }
          return null;
        },
      ],
    });
  }, [form, passwordScore]);

  const onResetPassword = (formData) => {
    const { password, confirmPassword } = formData;
    AuthService.confirmResetPassword(password, confirmPassword, uid64, token ).then(() => {
      ToastService.success('Your password has been reset successfully');
      setStep(1);
    }).catch((err) => {
      ToastService.showHttpError(err, 'Reset Password failed');
    });
  };

  return (
    <SimpleLayout className="flex-center">
      {step === 0 && (
        <Form className="w-140 max-w-[95vw] bg-white shadow-md rounded-md px-8 py-4 -mt-40" formGroup={form} onSubmit={onResetPassword}>
          <p className="text-blue-dark font-bold mb-4">Please enter your new password</p>

          <Input
            control={form.controls.password}
            type="password"
            placeholder="Password"
            fullWidth
          />
          <Input
            control={form.controls.confirmPassword}
            type="password"
            containerClass="mt-4"
            placeholder="Confirm password"
            fullWidth
          />
          <PasswordStrengthBar
            className="mt-2"
            password={formData?.password || ''}
            onChangeScore={setPasswordScore}
          />

          <div className="flex items-center mt-6">
            <Link className="ml-auto" to={ROUTES.AUTH.LOGIN}>
              <Button type="button" className="text-primary text-sm">Cancel</Button>
            </Link>
            <Button type="submit" color="primary" className="text-sm rounded-full shadow-md px-4 ml-2">Reset Password</Button>
          </div>
        </Form>
      )}

      {step === 1 && (
        <div className="text-blue-dark text-center">
          <CheckCircleOutlineIcon className="text-green-light" size={140} />
          <h1 className="text-4xl font-bold mt-2">Password Reset</h1>
          <p className="mt-1">Your password has been reset successfully</p>

          <div className="mt-24">
            Ready to
            <Link className="text-primary font-semibold hover:underline mx-1" to={ROUTES.AUTH.LOGIN}>log in</Link>
            again?
          </div>
        </div>
      )}
    </SimpleLayout>
  );
};

export default ResetPassword;
