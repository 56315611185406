import { MenuItem, Radio, RadioGroup } from "@mui/material";
import classnames from "classnames";
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Chip, Chips, Select, TextField } from "../../../../components";
import { ArrowLeftIcon, SearchIcon } from "../../../../components/icons";
import { NumberService } from "../../../../services";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { ILocationInfo, INumberSummary } from "../../../../utils/interfaces";
import { INumberCreateForm } from "../index";

export interface INumberCreateStep4Props {
  numbers: INumberSummary[];
  prefixOptionsData: ILocationInfo[];
  formData: INumberCreateForm;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<INumberCreateForm>): void;
}

const filterOptions = [
  {
    text: 'Contains',
    value: 'contains',
    description: <div>Specify the digits you would like your number to contain. For example; if you would like your number to look like "2760<strong>0736</strong>789" then type: "<strong>0736</strong>"</div>,
    example: 'Eg. 0763',
  },
  {
    text: 'Starts With',
    value: 'starts_with',
    description: <div>Specify the digits you would like your number to start with. For example; if you would like your number to look like "27<strong>600</strong>736789" then type: "27<strong>600</strong>"</div>,
    example: 'Eg. 600',
  },
  {
    text: 'Ends With',
    value: 'ends_with',
    description: <div>Specify the digits you would like your number to end with. For example; if you would like your number to look like "2760073<strong>6789</strong>" then type: "<strong>6789</strong>"</div>,
    example: 'Eg. 6789',
  },
];

export const NumberCreateStep4: FC<INumberCreateStep4Props> = ({
  formData, numbers, prefixOptionsData, onBack, onNext, onSubmit,
}) => {
  const prefixOptions = prefixOptionsData.filter(x => x.available_dids_enabled)
  const [mode, setMode] = useState(0);
  const [prefix, setPrefix] = useState(formData.prefix);
  const [searchedPrefixNumbers, setSearchedPrefixNumbers] = useState<INumberSummary[]>();
  const [selectedPrefixNumber, setSelectedPrefixNumber] = useState<any>(null);
  const [filter, setFilter] = useState('contains');
  const [search, setSearch] = useState('');
  const [searchedMatchNumbers, setSearchedMatchNumbers] = useState<INumberSummary[]>();
  const [selectedMatchedNumber, setSelectedMatchedNumber] = useState<any>(null);

  const searchPrefixNumbers = useCallback((prefix = '') => {
    if (prefix) {
      setSearchedPrefixNumbers(undefined);
      NumberService.searchSimilarNumbers({
        country_code_iso: formData.country.iso,
        starts_with: prefix,
        prefix_type: formData.type,
      }).then(data => {
        if (data.length > 0) {
          setSearchedPrefixNumbers(data.slice(0, 4));
        } else {
          setSearchedPrefixNumbers([]);
        }
      }).catch(() => {
        setSearchedPrefixNumbers([]);
      });
    }
  }, [formData]);

  useEffect(() => {
    if (mode === 0) searchPrefixNumbers(formData.prefix);
  }, []);

  const onSelectPrefix = (prefix) => {
    setPrefix(prefix);
    searchPrefixNumbers(prefix);
  };

  const searchMatchNumbers = useCallback(() => {
    setSearchedMatchNumbers(undefined);
    if (search) {
      NumberService.searchSimilarNumbers({
        country_code_iso: formData.country.iso,
        // capabilities: ['voice'],
        prefix_type: formData.type,
        [filter]: search,
      }).then((data) => {
        setSearchedMatchNumbers(data.slice(0, 4));
      }).catch(() => {
        setSearchedMatchNumbers([]);
      });
    }
  }, [formData, search, filter]);

  const onSearchMatchNumbers = (e) => {
    e.preventDefault();
    searchMatchNumbers();
  };

  const Panel1 = () => {
    return (
      <div className="h-full flex flex-col">
        <div
          className={classnames('text-sm font-semibold cursor-pointer')}
        >
          {formData.type === 'LOCAL' ? 'Choose a number you like, or change your area code' : 'Choose a number you like'}
        </div>
        {prefixOptions && prefixOptions.length > 1 && (
          <Select
            size="xs"
            fullWidth
            containerClass="mt-2"
            className={classnames(
              '!bg-white rounded border-[#E3E3E3]',
              { '!border-danger': prefixOptions && !prefixOptions.length },
            )}
            value={prefix}
            disabled={mode !== 0}
            onChange={onSelectPrefix}
          >
            {prefixOptions.map((option, i) => (
              <MenuItem key={i} className="!text-xs" value={option.prefix}>{option.prefix} - {option.location?.split('-')[1]?.trim() || 'Unknown'}</MenuItem>
            ))}
          </Select>
        )}
        {searchedPrefixNumbers && searchedPrefixNumbers.length > 0 && (
          <Chips className="mt-3 flex-1 max-h-[220px]">
            {searchedPrefixNumbers.map((item) => (
              <Chip
                key={item.number}
                className="w-full px-4 text-sm"
                containerClass="w-full mx-4 mb-1"
                active={item.number === selectedPrefixNumber?.number}
                disabled={mode !== 0}
                onClick={() => setSelectedPrefixNumber(item)}
              >
                {formatPhoneNumber(item.number)}
              </Chip>
            ))}
          </Chips>
        )}
        {searchedPrefixNumbers && searchedPrefixNumbers.length === 0 && (
          <div className={classnames('my-auto pb-16')}>
            <p className="mx-auto text-sm text-center">
              Unfortunately there are no numbers available that starts with {prefix}. <b>Please select another area code</b>
            </p>
          </div>
        )}
        {prefixOptions && prefixOptions.length === 0 && (
          <div className={classnames('my-auto')}>
            <p className="mx-auto text-sm text-center">
              Unfortunately there are no numbers available with different area codes, <b>please choose a number with specific digits</b>
            </p>
          </div>
        )}
      </div>
    );
  }

  const Panel2 = () => {
    return (
      <div className="h-full flex flex-col">
        <div
          className={classnames('text-sm font-semibold cursor-pointer')}
        >
          Get a number that...
        </div>
        <form className="mt-2" onSubmit={onSearchMatchNumbers}>
          <Select
            size="xs"
            fullWidth
            className="!bg-white rounded border-[#E3E3E3]"
            value={filter}
            disabled={mode !== 1}
            onChange={setFilter}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} className="!text-xs" value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>

          <div className="flex items-center mt-2">
            <TextField
              size="xs"
              fullWidth
              className="!rounded-none"
              placeholder={filterOptions.find((option) => option.value === filter)?.example}
              value={search}
              disabled={mode !== 1}
              onChange={(e) => setSearch(e)}
            />
            <button
              type="submit"
              className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 outline-none"
              onClick={onSearchMatchNumbers}
            >
              <SearchIcon color="blue" size={16} />
            </button>
          </div>

          {(mode === 1 && !search) && filterOptions.map((option) => option.value === filter ? (
            <>
              <p className="text-[9px] text-blue-light mt-1">{option.description}</p>
            </>
          ) : null)}
        </form>
        {searchedMatchNumbers && (
          <Chips className="mt-3">
            {searchedMatchNumbers.length ? (
              searchedMatchNumbers.map((item) => (
                <Chip
                  key={item.number}
                  className="w-full px-4 text-sm"
                  containerClass="w-full mx-4 mb-1"
                  active={item.number === selectedMatchedNumber?.number}
                  disabled={mode !== 1}
                  onClick={() => setSelectedMatchedNumber(item)}
                >
                  {formatPhoneNumber(item.number)}
                </Chip>
              ))
            ) : (
              <div className={classnames()}>
                <p className="text-xs">
                  Unfortunately there are no numbers available that {filterOptions.find((option) => option.value === filter)?.text} those digits. <b>Please select another dropdown option on choose different digits</b>
                </p>
              </div>
            )}
          </Chips>
        )}
      </div>
    );
  }

  const onContinue = () => {
    onSubmit({
      favouriteNumber: mode === 0 ? selectedPrefixNumber.number : selectedMatchedNumber.number,
      prefix,
    });
    onNext();
  };

  const panels = [Panel1(), Panel2()]

  const onPanelClick = i => {
    setMode(i);
  }

  return (
    <div className="h-full flex flex-col">
      <div className="h-0 flex-grow overflow-auto">
        <div className="text-center">
          <h6 className="text-sm font-semibold mt-4">Track incoming calls</h6>
          <p className="text-blue text-2xl font-bold">Choose a {formData.type === "LOCAL" ? 'landline' : 'mobile'} number you like</p>
        </div>
        <RadioGroup value={mode}>
          <div className={"min-h-[358px] mx-40 mt-8 grid md:grid-cols-2 gap-3"}>
            {panels.map((panel, i) => (
              <div
                data-cy="number-mode-panel"
                key={i}
                className={classnames(
                  'flex flex-col border-1.5 rounded overflow-hidden',
                  i === mode ? 'border-blue' : 'border-gray-d0 cursor-pointer text-gray-b4',
                )}
                onClick={e => onPanelClick(i)}
              >
                <div className="relative flex flex-1 items-start px-9 pt-1 pb-2">
                  <Radio size="small" value={i} className="!absolute top-1 left-0" />
                  <div className="h-full ml-0 mt-1.5 !w-full">
                    {panel}
                  </div>
                </div>
                <div className={classnames(
                  'text-white text-center p-1.5',
                  i === mode ? 'bg-blue' : 'bg-gray-d0',
                )}>
                  <span className={classnames('font-semibold')}>
                    Price
                    {i === 0 && selectedPrefixNumber && (
                      <> {selectedPrefixNumber.currency} {selectedPrefixNumber.rate?.toFixed(2)}</>
                    )}
                    {i === 1 && selectedMatchedNumber && (
                      <> {selectedMatchedNumber.currency} {selectedMatchedNumber.rate?.toFixed(2)}</>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>

      <div className="flex mt-4 mx-4">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button
          data-cy="create-number-step-3"
          color="primary"
          disabled={![selectedPrefixNumber, selectedMatchedNumber][mode]?.number}
          className="text-sm rounded-full shadow-md px-6 ml-2"
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
