import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DataTable, IDataTableColumn, IDataTableRef, Modal, StatisticsTile } from "../../../../components";
import { CalendarIcon, InfoCircleIcon, PersonIcon, PhoneIphoneIcon, WhatsAppCampaignsIcon, WhatsAppContactsIcon, WhatsAppListLastSentIcon, WhatsAppOptedOutIcon } from "../../../../components/icons";
import { WAListsService } from "../../../../services";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { WAListsContactsModel } from "../../../../utils/types/whatsapp-lists-contacts.model";
import { WAListsModel } from "../../../../utils/types/whatsapp-lists.model";

let lastQuery: any;
const rowsPerPage = 50;

interface IListDetailsModalProps {
    list: WAListsModel
    onClose?: (result?: any) => void;
}

export const ListDetailsModal: FC<IListDetailsModalProps> = ({
    list,
    onClose,
}) => {
    //datatable
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [listContacts, setListContacts] = useState<WAListsContactsModel[]>([]);

    const columns = useMemo<IDataTableColumn<WAListsContactsModel>[]>(() => {
        return [
            {
                title: 'First Name',
                field: 'contact_details.first_name',
                tooltip: { title: 'Contact First Name' },
            },
            {
                title: 'Last Name',
                field: 'contact_details.last_name',
                tooltip: { title: 'Contact Last Name' },
            },
            {
                title: 'WhatsApp Number',
                field: 'contact_details.whatsapp_number',
                tooltip: { title: 'Contact\'s WhatsApp Number' },
                render(row: WAListsContactsModel) {
                    return formatPhoneNumber(row.contact_details.whatsapp_number);
                }
            },
        ];
    }, [page]);

    const loadListContacts = useCallback((query) => {
        setLoading(true)
        WAListsService.searchListContacts(list.uuid, query)
            .then((data) => {
                setListContacts(data.results);
                setTotalCount(data.count);
                setLoading(false)
            }).catch(() => {
                setListContacts([]);
                setTotalCount(0);
                setLoading(false)
            });
    }, []);

    useEffect(() => {
        lastQuery = {};
    }, []);

    useEffect(() => {
        const query: any = {
            page: page + 1,
            page_size: rowsPerPage,
        };

        if (loading || JSON.stringify(lastQuery) === JSON.stringify(query)) {
            return;
        }

        lastQuery = query;
        loadListContacts(query);
    }, [columns]);

    return (
        <Modal
            title={"List Details"}
            size="lg"
            contentClass="bg-[#F8F8F8]"
            onClose={() => onClose()}
        >
            <div className="flex flex-row flex-wrap">
                <StatisticsTile
                    color={"#2680EB"}
                    backdropClass={"bg-[#D9E6F7]"}
                    value={list.total_recipients}
                    label="Total Contacts"
                    tooltip="Total contacts refers to all the contacts in your list."
                >
                    <WhatsAppContactsIcon
                        size={25}
                        color={"#2680EB"}
                    />
                </StatisticsTile>
                <StatisticsTile
                    color={"#79D7EE"}
                    backdropClass={"bg-[#EAFBFF]"}
                    value={list.total_campaigns}
                    label="Campaigns"
                    tooltip="Campaigns refer to the amount of times this list was used in completed campaigns"
                >
                    <WhatsAppCampaignsIcon
                        size={25}
                        color={"#79D7EE"}
                    />
                </StatisticsTile>
                <StatisticsTile
                    color={"#F6A734"}
                    backdropClass={"bg-[#FFEFD8]"}
                    value={new Date(list.modified).toLocaleDateString().replaceAll("/", "-")}
                    label="List Last Used"
                    tooltip="The lasted refers to when last this list was used in a campaign"
                >
                    <WhatsAppListLastSentIcon
                        size={25}
                        color={"#F6A734"}
                    />
                </StatisticsTile>
                <StatisticsTile
                    color={"#FE1722"}
                    backdropClass={"bg-[#FFD3D5]"}
                    value={list.total_optedout}
                    label="Opt Outs"
                    tooltip="Opt outs are the amount times a contact used the “unsubscribe” or “Don’t message me again” options. To ensure they are removed from the list and won’t receive another campaign."
                >
                    <WhatsAppOptedOutIcon
                        size={25}
                        color={"#FE1722"}
                    />
                </StatisticsTile>
            </div>
            <div>
                <h1 className="text-xl font-bold my-4">All Contacts</h1>
                <DataTable
                    columns={columns}
                    data={listContacts}
                    totalCount={totalCount}
                    size="sm"
                    stripped
                    stickyHeader
                    page={page}
                    extLoading={loading}
                    rowsPerPage={rowsPerPage}
                    pagination
                    selfPagination
                    headerCellClass="!text-13p"
                    cellClass="!text-11p"
                    onPaginationChange={(page) => setPage(page)}
                />
            </div>
        </Modal>
    )
}

