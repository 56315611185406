import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const AngleUpIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={12.767} baseHeight={7.299} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 12.767 7.299">
        <path
          d="M12.573,13.451,17.4,18.282a.912.912,0,0,0,1.288-1.292l-5.469-5.473a.911.911,0,0,0-1.258-.027l-5.507,5.5a.912.912,0,1,0,1.288,1.292Z"
          transform="translate(-6.188 -11.251)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
