import {CALL_LOG_ACTION} from "../action-types";
import {IDataTableRef} from "../../components";
import {CALL_TAB} from "../../utils/enums";
import {FilterModel} from "../../utils/types";
import {CallRecordModel} from "../../utils/types";

export const setCallTab = (tab: CALL_TAB) => ({
  type: CALL_LOG_ACTION.SET_TAB,
  tab,
});

export const setCallFilters = (filters: FilterModel[]) => ({
  type: CALL_LOG_ACTION.SET_FILTERS,
  filters,
});

export const addCallFilters = (filters: FilterModel[]) => ({
  type: CALL_LOG_ACTION.ADD_FILTERS,
  filters,
});

export const setCallLogs = (logs: CallRecordModel[]) => ({
  type: CALL_LOG_ACTION.SET_LOGS,
  logs,
});

export const setSelectedCallLogs = (selectedLogs: CallRecordModel[]) => ({
  type: CALL_LOG_ACTION.SET_SELECTED_LOGS,
  selectedLogs,
});

export const setCallTableRef = (tableRef: IDataTableRef) => ({
  type: CALL_LOG_ACTION.SET_TABLE_REF,
  tableRef,
});
