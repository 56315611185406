import React, {FC, useState} from "react";
import {Button, Modal} from "../../../../components";
import BlockedKeywordForm, {IBlockedKeywordFormInitParams} from "../BlockedKeywordForm";
import {ToastService} from "../../../../services";

interface IBlockedKeywordCreateModalProps {
  onClose: () => void;
}

const BlockedKeywordCreateModal: FC<IBlockedKeywordCreateModalProps> = ({
  onClose,
}) => {
  const [createForm, setCreateForm] = useState<IBlockedKeywordFormInitParams>({
    getFormData: () => null,
  });

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> blocked the keywords.</>);
      onClose();
    }
  };

  return (
    <Modal
      title="Mark keywords as spam"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onCreate}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedKeywordForm onInit={setCreateForm} onClose={onClose} />
    </Modal>
  );
};

export default BlockedKeywordCreateModal;
