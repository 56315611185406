import React, {FC} from "react";
import {IIconProps} from "../icons/types";
import {Icon} from "../icons/Icon";

export const TwoFactorAuthenticationSvg: FC<IIconProps> = (props) => (
  <Icon baseWidth={404.214} baseHeight={310.32} {...props}>
    {(width, height, color) => (
      <svg  width={width} height={height} viewBox="0 0 404.214 310.32">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_2624" data-name="Rectangle 2624" width="404.214" height="310.32" fill="#ddd"/>
        </clipPath>
        <clipPath id="clipPath-2">
          <rect id="Rectangle_2622" data-name="Rectangle 2622" width="31.815" height="35.265" fill="#ddd"/>
        </clipPath>
        <clipPath id="clipPath-3">
          <rect id="Rectangle_2623" data-name="Rectangle 2623" width="55.499" height="43.214" fill="#ddd"/>
        </clipPath>
        <clipPath id="clipPath-4">
          <rect id="Rectangle_2625" data-name="Rectangle 2625" width="40.436" height="114.11" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-5">
          <rect id="Rectangle_2626" data-name="Rectangle 2626" width="50.531" height="114.11" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-6">
          <rect id="Rectangle_2627" data-name="Rectangle 2627" width="169.93" height="95.366" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-7">
          <rect id="Rectangle_2628" data-name="Rectangle 2628" width="64.302" height="95.252" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-8">
          <rect id="Rectangle_2630" data-name="Rectangle 2630" width="77.323" height="150.648" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-9">
          <rect id="Rectangle_2631" data-name="Rectangle 2631" width="49.731" height="150.648" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-10">
          <rect id="Rectangle_2637" data-name="Rectangle 2637" width="4.833" height="12.718" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-11">
          <rect id="Rectangle_2638" data-name="Rectangle 2638" width="6.045" height="19.265" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-12">
          <rect id="Rectangle_2639" data-name="Rectangle 2639" width="7.049" height="33.527" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-13">
          <rect id="Rectangle_2640" data-name="Rectangle 2640" width="2.092" height="15.768" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-14">
          <rect id="Rectangle_2641" data-name="Rectangle 2641" width="8.109" height="15.768" fill="none"/>
        </clipPath>
        <clipPath id="clipPath-15">
          <rect id="Rectangle_2642" data-name="Rectangle 2642" width="5.304" height="14.77" fill="none"/>
        </clipPath>
      </defs>
      <g id="Group_4952" data-name="Group 4952" transform="translate(-145.786 -274.84)">
        <g id="Group_4069" data-name="Group 4069" transform="translate(145.786 274.84)">
          <g id="Group_4068" data-name="Group 4068" clipPath="url(#clip-path)">
            <path id="Path_1963" data-name="Path 1963" d="M212.856,361.216c86.568,0,156.746,4.1,156.746,9.151s-70.178,9.151-156.746,9.151-156.746-4.1-156.746-9.151,70.177-9.151,156.746-9.151" transform="translate(-10.749 -69.199)" fill="#ddd"/>
            <path id="Path_1964" data-name="Path 1964" d="M200.312,228.623H44.213a4.616,4.616,0,0,1-4.608-4.616V4.576A4.616,4.616,0,0,1,44.213,0h156.1a4.616,4.616,0,0,1,4.616,4.616V224.007a4.617,4.617,0,0,1-4.616,4.616M44.213.162a4.413,4.413,0,0,0-4.406,4.414V224.007a4.414,4.414,0,0,0,4.406,4.414h156.1a4.423,4.423,0,0,0,4.414-4.414V4.576A4.422,4.422,0,0,0,200.312.162Z" transform="translate(-7.587)" fill="#ddd"/>
            <path id="Path_1965" data-name="Path 1965" d="M416.618,228.623H260.511a4.624,4.624,0,0,1-4.616-4.616V4.576A4.624,4.624,0,0,1,260.511,0H416.618a4.616,4.616,0,0,1,4.6,4.576V224.007a4.616,4.616,0,0,1-4.6,4.616M260.511.162A4.422,4.422,0,0,0,256.1,4.576V224.007a4.422,4.422,0,0,0,4.414,4.414H416.618a4.423,4.423,0,0,0,4.414-4.414V4.576A4.422,4.422,0,0,0,416.618.162Z" transform="translate(-49.022)" fill="#ddd"/>
            <rect id="Rectangle_2614" data-name="Rectangle 2614" width="33.315" height="0.202" transform="translate(36.161 269.594)" fill="#ddd"/>
            <rect id="Rectangle_2615" data-name="Rectangle 2615" width="33.412" height="0.202" transform="translate(103.972 267.978)" fill="#ddd"/>
            <rect id="Rectangle_2616" data-name="Rectangle 2616" width="8.084" height="0.202" transform="translate(172.777 267.978)" fill="#ddd"/>
            <rect id="Rectangle_2617" data-name="Rectangle 2617" width="37.39" height="0.202" transform="translate(62.669 274.881)" fill="#ddd"/>
            <rect id="Rectangle_2618" data-name="Rectangle 2618" width="17.462" height="0.202" transform="translate(211.921 269.489)" fill="#ddd"/>
            <rect id="Rectangle_2619" data-name="Rectangle 2619" width="22.579" height="0.202" transform="translate(236.578 269.489)" fill="#ddd"/>
            <rect id="Rectangle_2620" data-name="Rectangle 2620" width="50.478" height="0.202" transform="translate(317.575 272.908)" fill="#ddd"/>
            <rect id="Rectangle_2621" data-name="Rectangle 2621" width="404.214" height="0.202" transform="translate(0 261.662)" fill="#ddd"/>
            <g id="Group_4064" data-name="Group 4064" transform="translate(238.884 12.996)" opacity="0.8">
              <g id="Group_4063" data-name="Group 4063">
                <g id="Group_4062" data-name="Group 4062" clipPath="url(#clip-path-2)">
                  <path id="Path_1966" data-name="Path 1966" d="M327.3,19.855l-2.015,27.708a4.131,4.131,0,0,1-4.043,3.779H299.016a3.493,3.493,0,0,1-3.514-3.779l2.015-27.708a4.116,4.116,0,0,1,4.043-3.779h22.224a3.476,3.476,0,0,1,3.514,3.779" transform="translate(-295.492 -16.076)" fill="#ddd"/>
                </g>
              </g>
            </g>
            <path id="Path_1967" data-name="Path 1967" d="M320.023,34.513c-.286,4.087-3.241,7.4-6.6,7.4s-5.851-3.313-5.565-7.4,3.241-7.4,6.6-7.4,5.851,3.313,5.565,7.4" transform="translate(-58.973 -5.194)" fill="#ddd"/>
            <path id="Path_1968" data-name="Path 1968" d="M321.032,55.5H300.693c-1.289,0-2.183-.618-2.215-1.487-.046-1.257,6.55-6.713,12.806-6.24.023,0,.046,0,.069,0h.074A17.207,17.207,0,0,1,321.9,51.8c3.019,2.386.162,3.692-.871,3.692" transform="translate(-57.18 -9.146)" fill="#ddd"/>
            <path id="Path_1969" data-name="Path 1969" d="M191,54.727a8.564,8.564,0,0,1-8.494-7.756,7.236,7.236,0,0,1,7.2-7.951,8.565,8.565,0,0,1,8.494,7.755,7.237,7.237,0,0,1-7.2,7.952m-.951-11.534a3.391,3.391,0,0,0-3.374,3.727,4.012,4.012,0,0,0,3.98,3.633,3.39,3.39,0,0,0,3.374-3.725,4.015,4.015,0,0,0-3.981-3.635" transform="translate(-34.958 -7.475)" fill="#ddd"/>
            <path id="Path_1970" data-name="Path 1970" d="M192.422,38.89l-2.978.037-1.58-19.173,2.979-.037Z" transform="translate(-35.989 -3.777)" fill="#ddd"/>
            <path id="Path_1971" data-name="Path 1971" d="M193.655,20.074l-5.592.07-.246-2.981,5.592-.07Z" transform="translate(-35.981 -3.274)" fill="#ddd"/>
            <path id="Path_1972" data-name="Path 1972" d="M194.131,24.813l-5.258.065-.246-2.981,5.258-.065Z" transform="translate(-36.136 -4.182)" fill="#ddd"/>
            <path id="Path_1973" data-name="Path 1973" d="M194.851,31.5l-4.589.057-.246-2.981,4.589-.057Z" transform="translate(-36.402 -5.463)" fill="#ddd"/>
            <path id="Path_1974" data-name="Path 1974" d="M79.305,258.452a7.307,7.307,0,0,0,7.171,6.7h68.3a6.147,6.147,0,0,0,6.243-5.982,6.919,6.919,0,0,0-.01-.719,7.306,7.306,0,0,0-7.17-6.694h-68.3a6.164,6.164,0,0,0-6.234,6.694" transform="translate(-15.189 -48.23)" fill="#ddd"/>
            <path id="Path_1975" data-name="Path 1975" d="M161.467,259.169a4.275,4.275,0,0,1-.06.647,7.315,7.315,0,0,0-7.146-6.443l-72.639.113a6.22,6.22,0,0,1,4.363-1.73h68.3a7.321,7.321,0,0,1,7.175,6.694c.011.243.015.485,0,.72" transform="translate(-15.637 -48.229)" fill="#ddd"/>
            <path id="Path_1976" data-name="Path 1976" d="M164.7,255.754l.229,3.268,3.062-1.135.7,1.614-3.128,1.032,2.184,2.741-1.412,1.033-2.126-2.85-1.7,2.85L161,263.272l1.772-2.741-3.243-1.032.467-1.6,3.249,1.094-.226-3.236Z" transform="translate(-30.561 -48.995)" fill="#ddd"/>
            <path id="Path_1977" data-name="Path 1977" d="M141.6,255.754l.229,3.268,3.062-1.135.694,1.614-3.127,1.032,2.184,2.741-1.412,1.033-2.126-2.85-1.7,2.85-1.513-1.033,1.772-2.741-3.243-1.032.467-1.6,3.249,1.094-.226-3.236Z" transform="translate(-26.134 -48.995)" fill="#ddd"/>
            <path id="Path_1978" data-name="Path 1978" d="M118.489,255.754l.229,3.268,3.062-1.135.7,1.614-3.128,1.032,2.184,2.741-1.412,1.033-2.126-2.85-1.7,2.85-1.513-1.033,1.772-2.741-3.243-1.032.467-1.6,3.249,1.094-.226-3.236Z" transform="translate(-21.707 -48.995)" fill="#ddd"/>
            <path id="Path_1979" data-name="Path 1979" d="M95.382,255.754l.229,3.268,3.062-1.135.694,1.614-3.127,1.032,2.184,2.741-1.412,1.033-2.126-2.85-1.7,2.85-1.513-1.033,1.772-2.741L90.2,259.5l.467-1.6,3.249,1.094-.226-3.236Z" transform="translate(-17.281 -48.995)" fill="#ddd"/>
            <path id="Path_1980" data-name="Path 1980" d="M151.98,248.646H148.8l.015-.808h3.184Z" transform="translate(-28.505 -47.479)" fill="#ddd"/>
            <path id="Path_1981" data-name="Path 1981" d="M167.211,248.646H156.819l.015-.808h10.392Z" transform="translate(-30.042 -47.479)" fill="#ddd"/>
            <g id="Group_4067" data-name="Group 4067" transform="translate(217.778 173.71)" opacity="0.8">
              <g id="Group_4066" data-name="Group 4066">
                <g id="Group_4065" data-name="Group 4065" clipPath="url(#clip-path-3)">
                  <path id="Path_1982" data-name="Path 1982" d="M269.385,238.255l12.881,19.832,42.489-42.49a.421.421,0,0,0-.558-.628l-40.492,31.738-7.646-12.329Z" transform="translate(-269.385 -214.874)" fill="#ddd"/>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_4072" data-name="Group 4072" transform="translate(325.352 330.803)" opacity="0.1">
          <g id="Group_4071" data-name="Group 4071">
            <g id="Group_4070" data-name="Group 4070" clipPath="url(#clip-path-4)">
              <path id="Path_1983" data-name="Path 1983" d="M252.627,69.225l-30.509,114.11H232.38l30.172-114.11Z" transform="translate(-222.117 -69.225)" fill="#fff"/>
            </g>
          </g>
        </g>
        <g id="Group_4075" data-name="Group 4075" transform="translate(341.19 330.803)" opacity="0.1">
          <g id="Group_4074" data-name="Group 4074">
            <g id="Group_4073" data-name="Group 4073" clipPath="url(#clip-path-5)">
              <path id="Path_1984" data-name="Path 1984" d="M272.219,69.225l-30.51,114.11h20.359L292.24,69.225Z" transform="translate(-241.709 -69.225)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_1985" data-name="Path 1985" d="M374.817,173.581l6.436-91.6a5.048,5.048,0,0,0-5.121-5.475l-159.854.139a5.992,5.992,0,0,0-5.892,5.484l-6.436,91.6a5.048,5.048,0,0,0,5.121,5.475l159.854-.139a5.993,5.993,0,0,0,5.892-5.484" transform="translate(106.718 260.183)" fill="#263238"/>
        <path id="Path_1986" data-name="Path 1986" d="M372.279,171.11l6.436-91.6a5.048,5.048,0,0,0-5.121-5.475l-159.854.139a5.992,5.992,0,0,0-5.892,5.484l-6.437,91.6a5.049,5.049,0,0,0,5.122,5.475l159.854-.139a5.993,5.993,0,0,0,5.892-5.484" transform="translate(107.204 260.656)" fill="#407bff"/>
        <g id="Group_4078" data-name="Group 4078" transform="translate(312.303 338.358)" opacity="0.6">
          <g id="Group_4077" data-name="Group 4077">
            <g id="Group_4076" data-name="Group 4076" clipPath="url(#clip-path-6)">
              <path id="Path_1987" data-name="Path 1987" d="M368.919,173.331a1.74,1.74,0,0,1-1.207.468l-159.854.139a1.9,1.9,0,0,1-1.878-2l6.437-91.6a1.719,1.719,0,0,1,.546-1.16,1.738,1.738,0,0,1,1.207-.468l159.854-.138a1.894,1.894,0,0,1,1.877,2l-6.437,91.6a1.719,1.719,0,0,1-.547,1.16" transform="translate(-205.976 -78.571)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_1988" data-name="Path 1988" d="M327.782,133.5h-56.11a2.058,2.058,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.625,1.805h56.11a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(94.291 249.265)" fill="#fff"/>
        <path id="Path_1989" data-name="Path 1989" d="M277.853,174.954H269.1a2.059,2.059,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.626,1.805h8.756a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(94.785 241.323)" fill="#fff"/>
        <path id="Path_1990" data-name="Path 1990" d="M296.554,174.954H287.8a2.059,2.059,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.626,1.805h8.756a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(91.202 241.323)" fill="#fff"/>
        <path id="Path_1991" data-name="Path 1991" d="M315.255,174.954H306.5a2.059,2.059,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.626,1.805h8.755a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(87.62 241.323)" fill="#fff"/>
        <path id="Path_1992" data-name="Path 1992" d="M333.956,174.954H325.2a2.059,2.059,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.626,1.805h8.756a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(84.037 241.323)" fill="#fff"/>
        <path id="Path_1993" data-name="Path 1993" d="M280.4,165.629h35.1a3.506,3.506,0,0,0,3.412-3.074l.421-4.028a2.725,2.725,0,0,0-2.767-3.075h-35.1a3.505,3.505,0,0,0-3.411,3.075l-.422,4.028A2.725,2.725,0,0,0,280.4,165.629Z" transform="translate(92.603 245.06)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Path_1994" data-name="Path 1994" d="M330.283,112.364h-56.11a2.058,2.058,0,0,0-2,1.805l-.859,8.206a1.6,1.6,0,0,0,1.625,1.805h56.11a2.058,2.058,0,0,0,2-1.805l.859-8.206a1.6,1.6,0,0,0-1.625-1.805" transform="translate(93.812 253.314)" fill="#fff"/>
        <path id="Path_1995" data-name="Path 1995" d="M279.839,140.333a1.771,1.771,0,0,0-1.8-2,2.278,2.278,0,0,0-2.218,2,1.771,1.771,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(92.948 248.339)" fill="#263238"/>
        <path id="Path_1996" data-name="Path 1996" d="M287.472,140.333a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.217,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(91.486 248.339)" fill="#263238"/>
        <path id="Path_1997" data-name="Path 1997" d="M295.105,140.333a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(90.024 248.339)" fill="#263238"/>
        <path id="Path_1998" data-name="Path 1998" d="M302.74,140.333a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(88.561 248.339)" fill="#263238"/>
        <path id="Path_1999" data-name="Path 1999" d="M310.522,140.333a1.771,1.771,0,0,0-1.8-2,2.28,2.28,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.217-2" transform="translate(87.07 248.339)" fill="#263238"/>
        <path id="Path_2000" data-name="Path 2000" d="M282.339,119.2a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(92.469 252.388)" fill="#263238"/>
        <path id="Path_2001" data-name="Path 2001" d="M289.974,119.2a1.771,1.771,0,0,0-1.8-2,2.277,2.277,0,0,0-2.217,2,1.771,1.771,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(91.007 252.388)" fill="#263238"/>
        <path id="Path_2002" data-name="Path 2002" d="M297.607,119.2a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(89.545 252.388)" fill="#263238"/>
        <path id="Path_2003" data-name="Path 2003" d="M305.241,119.2a1.771,1.771,0,0,0-1.8-2,2.278,2.278,0,0,0-2.218,2,1.771,1.771,0,0,0,1.8,2,2.279,2.279,0,0,0,2.217-2" transform="translate(88.082 252.388)" fill="#263238"/>
        <path id="Path_2004" data-name="Path 2004" d="M312.874,119.2a1.771,1.771,0,0,0-1.8-2,2.278,2.278,0,0,0-2.217,2,1.771,1.771,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(86.62 252.388)" fill="#263238"/>
        <path id="Path_2005" data-name="Path 2005" d="M320.508,119.2a1.771,1.771,0,0,0-1.8-2,2.279,2.279,0,0,0-2.218,2,1.772,1.772,0,0,0,1.8,2,2.279,2.279,0,0,0,2.218-2" transform="translate(85.157 252.388)" fill="#263238"/>
        <path id="Path_2006" data-name="Path 2006" d="M312.735,89.085l-.437,4.144a.771.771,0,0,1-.751.676.6.6,0,0,1-.608-.676l.437-4.144a2.152,2.152,0,0,0-2.183-2.428H307.7a2.775,2.775,0,0,0-2.7,2.428l-.379,3.849h-1.359l.379-3.849a4.32,4.32,0,0,1,4.2-3.78h1.49a3.35,3.35,0,0,1,3.4,3.78" transform="translate(87.688 258.498)" fill="#407bff"/>
        <path id="Path_2007" data-name="Path 2007" d="M312.446,94.7l-.852,8.074a1.053,1.053,0,0,1-1.026.924h-8.113a.818.818,0,0,1-.831-.924l.851-8.074a1.051,1.051,0,0,1,.519-.783,1,1,0,0,1,.508-.141h8.113a.861.861,0,0,1,.466.132.822.822,0,0,1,.365.792" transform="translate(88.004 256.875)" fill="#407bff"/>
        <line id="Line_7" data-name="Line 7" x1="19.755" y2="0.118" transform="translate(443.993 334.16)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_8" data-name="Line 8" x1="6.982" y2="0.031" transform="translate(467.534 334.106)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <g id="Group_4081" data-name="Group 4081" transform="translate(313.804 338.473)" opacity="0.3">
          <g id="Group_4080" data-name="Group 4080">
            <g id="Group_4079" data-name="Group 4079" clipPath="url(#clip-path-7)">
              <path id="Path_2008" data-name="Path 2008" d="M229.876,78.712h22.617L229.5,173.965H211.249a3.489,3.489,0,0,1-3.314-4.355Z" transform="translate(-207.833 -78.712)" fill="#fff"/>
              <path id="Path_2009" data-name="Path 2009" d="M270.047,78.712h9.6l-22.992,95.253h-9.6Z" transform="translate(-215.347 -78.712)" fill="#fff"/>
            </g>
          </g>
        </g>
        <rect id="Rectangle_2629" data-name="Rectangle 2629" width="404.214" height="310.32" transform="translate(145.786 274.84)" fill="none"/>
        <path id="Path_2010" data-name="Path 2010" d="M84.806,41.932h4.589A7.7,7.7,0,0,0,81.617,50.3l9.74,139.29a9.11,9.11,0,0,0,8.948,8.363H95.716a9.11,9.11,0,0,1-8.948-8.363L77.028,50.3a7.7,7.7,0,0,1,7.778-8.363" transform="translate(131.034 266.807)" fill="#407bff"/>
        <path id="Path_2011" data-name="Path 2011" d="M84.653,41.932h4.589A7.7,7.7,0,0,0,81.464,50.3l9.74,139.29a9.11,9.11,0,0,0,8.948,8.363H95.563c-4.619,0-9.271-3.745-9.594-8.363L76.229,50.3c-.323-4.619,3.806-8.363,8.425-8.363" transform="translate(131.186 266.807)" fill="#263238"/>
        <path id="Path_2012" data-name="Path 2012" d="M97.408,197.948h56.184a7.7,7.7,0,0,0,7.779-8.362L151.629,50.3a9.109,9.109,0,0,0-8.947-8.363H86.5A7.7,7.7,0,0,0,78.721,50.3l9.739,139.29a9.111,9.111,0,0,0,8.948,8.362" transform="translate(130.709 266.807)" fill="#407bff"/>
        <g id="Group_4086" data-name="Group 4086" transform="translate(211.894 311.423)" opacity="0.6">
          <g id="Group_4085" data-name="Group 4085">
            <g id="Group_4084" data-name="Group 4084" clipPath="url(#clip-path-8)">
              <path id="Path_2013" data-name="Path 2013" d="M143.119,45.251a6.346,6.346,0,0,1,6.223,5.821l9.74,139.289a5.069,5.069,0,0,1-5.147,5.54H97.751a6.346,6.346,0,0,1-6.223-5.821L81.788,50.79a5.068,5.068,0,0,1,5.148-5.539Z" transform="translate(-81.773 -45.252)" fill="#fff"/>
            </g>
          </g>
        </g>
        <g id="Group_4089" data-name="Group 4089" transform="translate(225.905 311.423)" opacity="0.3">
          <g id="Group_4088" data-name="Group 4088">
            <g id="Group_4087" data-name="Group 4087" clipPath="url(#clip-path-9)">
              <path id="Path_2014" data-name="Path 2014" d="M132.723,195.9H110.315L128.794,45.251h18.034a5.691,5.691,0,0,1,4.154,1.789Z" transform="translate(-101.252 -45.252)" fill="#fff"/>
              <path id="Path_2015" data-name="Path 2015" d="M104.145,195.9H99.1L117.584,45.251h5.041Z" transform="translate(-99.104 -45.252)" fill="#fff"/>
            </g>
          </g>
        </g>
        <line id="Line_9" data-name="Line 9" x2="40.697" transform="translate(229.197 377.246)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_10" data-name="Line 10" x2="31.877" transform="translate(234.447 383.265)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Path_2016" data-name="Path 2016" d="M103.993,91.657l.056,1.6h-1.043l-.056-1.612-1.846-2.9h1.108l1.274,2,1.133-2h1.024Z" transform="translate(126.417 257.839)" fill="#407bff"/>
        <path id="Path_2017" data-name="Path 2017" d="M106.772,90.981a2.239,2.239,0,0,1,2.384-2.334,2.435,2.435,0,0,1,2.549,2.334,2.24,2.24,0,0,1-2.385,2.334,2.434,2.434,0,0,1-2.548-2.334m3.876,0a1.434,1.434,0,0,0-1.461-1.444,1.32,1.32,0,0,0-1.359,1.444,1.434,1.434,0,0,0,1.46,1.445,1.321,1.321,0,0,0,1.36-1.445" transform="translate(125.332 257.857)" fill="#407bff"/>
        <path id="Path_2018" data-name="Path 2018" d="M113.793,91.27l-.088-2.527h1.043l.087,2.488c.029.858.416,1.213,1.047,1.213s.992-.355.962-1.213l-.087-2.488h1.031l.089,2.527a1.8,1.8,0,0,1-1.969,2.063,1.944,1.944,0,0,1-2.114-2.063" transform="translate(124.003 257.839)" fill="#407bff"/>
        <path id="Path_2019" data-name="Path 2019" d="M123.064,93.256,122.15,92h-.96l.044,1.257h-1.043l-.158-4.513h1.951c1.2,0,1.979.626,2.015,1.638a1.4,1.4,0,0,1-.877,1.424l1.062,1.451Zm-1.107-3.662h-.851l.056,1.573h.85c.637,0,.949-.3.932-.787s-.35-.787-.986-.787" transform="translate(122.791 257.839)" fill="#407bff"/>
        <path id="Path_2020" data-name="Path 2020" d="M127.9,90.981a2.223,2.223,0,0,1,2.364-2.334,2.412,2.412,0,0,1,1.884.8l-.648.619a1.5,1.5,0,0,0-1.151-.529,1.328,1.328,0,0,0-1.392,1.444,1.443,1.443,0,0,0,1.493,1.445,1.38,1.38,0,0,0,1.113-.535l.693.618a2.233,2.233,0,0,1-1.833.806,2.414,2.414,0,0,1-2.522-2.334" transform="translate(121.285 257.857)" fill="#407bff"/>
        <path id="Path_2021" data-name="Path 2021" d="M133.619,90.981A2.239,2.239,0,0,1,136,88.647a2.435,2.435,0,0,1,2.549,2.334,2.24,2.24,0,0,1-2.385,2.334,2.434,2.434,0,0,1-2.548-2.334m3.876,0a1.435,1.435,0,0,0-1.461-1.444,1.32,1.32,0,0,0-1.359,1.444,1.434,1.434,0,0,0,1.46,1.445,1.321,1.321,0,0,0,1.36-1.445" transform="translate(120.189 257.857)" fill="#407bff"/>
        <path id="Path_2022" data-name="Path 2022" d="M140.6,88.743h2.048A2.343,2.343,0,0,1,145.212,91a2.159,2.159,0,0,1-2.406,2.256h-2.049Zm2.124,3.656a1.293,1.293,0,0,0,1.432-1.4,1.4,1.4,0,0,0-1.53-1.4h-.953l.1,2.8Z" transform="translate(118.851 257.839)" fill="#407bff"/>
        <path id="Path_2023" data-name="Path 2023" d="M150.8,92.418l.029.838h-3.49l-.158-4.513h3.407l.029.838H148.25l.034.98h2.093l.029.812h-2.093l.036,1.044Z" transform="translate(117.59 257.839)" fill="#407bff"/>
        <path id="Path_2024" data-name="Path 2024" d="M112.223,112.731l.032,1.814h-8.51l-.025-1.435,4.509-4.353c1.1-1.072,1.289-1.748,1.277-2.391-.019-1.056-.755-1.666-2.174-1.666a3.2,3.2,0,0,0-2.683,1.2l-1.521-1.155a5.24,5.24,0,0,1,4.354-1.913c2.506,0,4.146,1.27,4.181,3.315.019,1.121-.276,2.144-1.85,3.644l-3.032,2.935Z" transform="translate(126.03 255.139)" fill="#263238"/>
        <path id="Path_2025" data-name="Path 2025" d="M123.911,111.121c.034,1.946-1.4,3.627-4.505,3.627a7.359,7.359,0,0,1-4.361-1.336l.895-1.665a5.467,5.467,0,0,0,3.4,1.138c1.534,0,2.429-.677,2.41-1.749-.018-1.039-.788-1.715-2.537-1.715h-1.055l-.025-1.467,2.566-3.116h-5.244l-.031-1.8h7.867l.025,1.434-2.745,3.332c2.182.281,3.31,1.583,3.34,3.315" transform="translate(123.747 255.1)" fill="#263238"/>
        <path id="Path_2026" data-name="Path 2026" d="M137.1,111.231c.038,2.144-1.8,3.479-4.673,3.479s-4.723-1.335-4.761-3.479a2.836,2.836,0,0,1,1.8-2.754,2.688,2.688,0,0,1-1.477-2.424c-.035-1.979,1.675-3.216,4.231-3.216,2.573,0,4.343,1.237,4.378,3.216a2.579,2.579,0,0,1-1.424,2.424,2.966,2.966,0,0,1,1.927,2.754m-2.162-.066c-.02-1.138-1.021-1.847-2.6-1.847s-2.528.709-2.508,1.847c.021,1.171.99,1.863,2.572,1.863s2.561-.693,2.54-1.863m-4.838-5.013c.017.99.853,1.616,2.205,1.616,1.369,0,2.2-.627,2.182-1.616-.019-1.039-.919-1.633-2.239-1.633s-2.166.594-2.148,1.633" transform="translate(121.329 255.139)" fill="#263238"/>
        <path id="Path_2027" data-name="Path 2027" d="M150.4,111.231c.038,2.144-1.8,3.479-4.673,3.479s-4.724-1.335-4.762-3.479a2.838,2.838,0,0,1,1.8-2.754,2.69,2.69,0,0,1-1.478-2.424c-.035-1.979,1.676-3.216,4.232-3.216,2.573,0,4.342,1.237,4.377,3.216a2.578,2.578,0,0,1-1.424,2.424,2.968,2.968,0,0,1,1.927,2.754m-2.163-.066c-.019-1.138-1.021-1.847-2.6-1.847s-2.528.709-2.507,1.847c.021,1.171.99,1.863,2.572,1.863s2.561-.693,2.54-1.863m-4.837-5.013c.018.99.852,1.616,2.2,1.616,1.369,0,2.2-.627,2.183-1.616-.019-1.039-.92-1.633-2.24-1.633s-2.166.594-2.147,1.633" transform="translate(118.781 255.139)" fill="#263238"/>
        <line id="Line_11" data-name="Line 11" x2="1.433" y2="19.54" transform="translate(207.808 328.928)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_12" data-name="Line 12" x2="0.378" y2="5.156" transform="translate(207.155 320.026)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_13" data-name="Line 13" y1="11.897" transform="translate(245.522 288.701)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_14" data-name="Line 14" x1="5.946" y1="10.296" transform="translate(230.158 292.819)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_15" data-name="Line 15" x1="5.946" y2="10.301" transform="translate(254.939 292.819)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <rect id="Rectangle_2632" data-name="Rectangle 2632" width="404.214" height="310.32" transform="translate(145.786 274.84)" fill="none"/>
        <path id="Path_2046" data-name="Path 2046" d="M171.774,360.086a.948.948,0,0,0,.6-.711.559.559,0,0,0-.13-.557c-.623-.651-3.152-.189-3.438-.134a.168.168,0,0,0-.133.128.164.164,0,0,0,.066.17,8.674,8.674,0,0,0,1.641.935,2.069,2.069,0,0,0,1.4.17m.223-1.04a.233.233,0,0,1,.048.247.625.625,0,0,1-.387.481c-.458.165-1.336-.145-2.388-.833,1.017-.156,2.4-.241,2.727.105" transform="translate(113.474 206.166)" fill="#407bff"/>
        <path id="Path_2047" data-name="Path 2047" d="M168.857,358.533c.774-.1,2.554-1.01,2.711-1.8a.576.576,0,0,0-.346-.64.89.89,0,0,0-.745-.061c-.976.334-1.763,2.195-1.8,2.274a.163.163,0,0,0,.092.216.16.16,0,0,0,.085.011m2.2-2.15c.189.1.2.209.179.287-.1.482-1.28,1.23-2.11,1.468.294-.606.878-1.593,1.46-1.793a.555.555,0,0,1,.4,0c.023.01.045.021.069.033" transform="translate(113.474 206.645)" fill="#407bff"/>
        <path id="Path_2048" data-name="Path 2048" d="M159.41,351.506l6.581,2.66,5.744-15.207-6.581-2.66Z" transform="translate(115.248 210.414)" fill="#ffb573"/>
        <path id="Path_2049" data-name="Path 2049" d="M165.859,357.014l-7.394-2.989a.568.568,0,0,0-.71.23l-3.245,5.416a1.118,1.118,0,0,0,.575,1.579c2.6,1,3.9,1.351,7.151,2.665,2,.808,4.838,2.194,7.6,3.311,2.7,1.092,4.032-1.512,2.983-2.22-4.7-3.176-4.889-5.011-6.027-7.15a1.846,1.846,0,0,0-.934-.842" transform="translate(116.214 207.027)" fill="#263238"/>
        <path id="Path_2050" data-name="Path 2050" d="M171.842,346.731l-11.43-4.62,1.047-4.189,12.264,4.487Z" transform="translate(115.056 210.104)" fill="#407bff"/>
        <path id="Path_2051" data-name="Path 2051" d="M183.554,127.185c-2.319,6.712-9.185,22.525-14.474,15.939a15.234,15.234,0,0,1-2.31-3.849c-2.246-5.54-2.8-14.515-3.083-16.475l-1.8-6.151a1.184,1.184,0,0,1-.048-.327l-.029-3.513c0-1.424,1.147-2.27,2.7-2.14,0,0,2.506-.293,2.506,1.2l.488,5.272h-.016c.252,1.839-.48,4.394-.049,6.078,1.611,6.33,3.839,6.615,5.239,13.147,1.945-4.711,5.435-12.871,5.435-12.871,2.352.293,6.208,1.473,5.443,3.686" transform="translate(114.788 253.644)" fill="#ffb573"/>
        <path id="Path_2052" data-name="Path 2052" d="M175.451,146.646a4.168,4.168,0,0,1-6.847.234,15.359,15.359,0,0,1-2.238-4.2c-2.1-6.892-3.4-11.163-3.58-18.363a5.674,5.674,0,0,1,5.842-1.383L172.07,134.6s7.013-17.422,9.845-18.048c3.344-.732,4.735,2.248,6.142,5.917,1.015,2.64-8.038,17.13-12.607,24.177" transform="translate(114.601 252.533)" fill="#407bff"/>
        <path id="Path_2053" data-name="Path 2053" d="M210.3,95.014c-.793,4.045-2.574,12.865.255,15.565,0,0-1.105,4.1-8.619,4.1-8.262,0-3.948-4.1-3.948-4.1,4.509-1.077,4.393-4.422,3.607-7.565Z" transform="translate(108.073 256.638)" fill="#ffb573"/>
        <path id="Path_2054" data-name="Path 2054" d="M197.276,82.682a.317.317,0,0,0,.144-.035.322.322,0,0,0,.145-.433,3.2,3.2,0,0,0-2.341-1.789.323.323,0,0,0-.075.641,2.556,2.556,0,0,1,1.839,1.437.323.323,0,0,0,.289.179" transform="translate(108.456 259.433)" fill="#263238"/>
        <path id="Path_2055" data-name="Path 2055" d="M194.238,87.589a14.7,14.7,0,0,1-2.376,3.241,2.364,2.364,0,0,0,1.9.606Z" transform="translate(109.031 258.061)" fill="#ed893e"/>
        <path id="Path_2056" data-name="Path 2056" d="M195.295,85.926c.116.538-.074,1.035-.425,1.111s-.73-.3-.846-.835.074-1.035.424-1.111.731.3.847.835" transform="translate(108.623 258.541)" fill="#263238"/>
        <path id="Path_2057" data-name="Path 2057" d="M194.431,85.066l-1.371-.092s.881.864,1.371.092" transform="translate(108.801 258.562)" fill="#263238"/>
        <path id="Path_2058" data-name="Path 2058" d="M159.977,110.322v.016c0-.008-.008-.008-.008-.016Z" transform="translate(115.14 253.705)" fill="#ffb573"/>
        <g id="Group_4105" data-name="Group 4105" transform="translate(296.35 376.546)" opacity="0.2">
          <g id="Group_4104" data-name="Group 4104">
            <g id="Group_4103" data-name="Group 4103" clipPath="url(#clip-path-10)">
              <path id="Path_2059" data-name="Path 2059" d="M187.944,126.638c1.432-1.31,2.4-.919,3.059.122.651,3.027-3.205,9.365-4.76,11.765Z" transform="translate(-186.243 -125.807)"/>
            </g>
          </g>
        </g>
        <path id="Path_2060" data-name="Path 2060" d="M220.887,128.36c-.186,3.331-.5,7.526-1.011,12.805-.534,5.57-1.293,12.337-2.336,20.558-10.987,0-22.49-.17-27.834-.17.447-10.92.612-21.985-4.287-43.721a2.058,2.058,0,0,1,1.644-2.478,79.4,79.4,0,0,1,9.636-1.143,88.068,88.068,0,0,1,12.571,0c5.392.493,11.52,1.746,11.52,1.746s.679,2,.1,12.4" transform="translate(110.275 253.004)" fill="#407bff"/>
        <g id="Group_4108" data-name="Group 4108" transform="translate(325.117 374.903)" opacity="0.2">
          <g id="Group_4107" data-name="Group 4107">
            <g id="Group_4106" data-name="Group 4106" clipPath="url(#clip-path-11)">
              <path id="Path_2061" data-name="Path 2061" d="M227.872,130.235c-.186,3.331-.5,7.526-1.011,12.805-2.959-3.428-5.756-15.231-4.867-19.265a26.513,26.513,0,0,1,5.748,5.821,5.927,5.927,0,0,1,.129.639" transform="translate(-221.827 -123.776)"/>
            </g>
          </g>
        </g>
        <path id="Path_2062" data-name="Path 2062" d="M264.447,149.319l5.63-3.166,1.052,5.764s-4.771,1.828-7.3.547Z" transform="translate(95.243 246.841)" fill="#ffb573"/>
        <path id="Path_2063" data-name="Path 2063" d="M275.329,146.682l.391,1.881a1.493,1.493,0,0,1-.623,1.538l-2.488,1.69-1.052-5.765,1.948-.488a1.493,1.493,0,0,1,1.825,1.144" transform="translate(93.763 246.968)" fill="#ffb573"/>
        <path id="Path_2064" data-name="Path 2064" d="M222.6,123.954c1.8,3.671,8.422,17.533,11.331,20.738l.569.6.285.3c.1.1.175.189.331.325a9.987,9.987,0,0,0,1.715,1.3,13.231,13.231,0,0,0,3.526,1.423,24.726,24.726,0,0,0,6.613.627,48.471,48.471,0,0,0,12.207-2.073l-.723-3.375c-1.863.158-3.821.273-5.716.3a45.449,45.449,0,0,1-5.619-.178,21.852,21.852,0,0,1-5.09-.99,7.657,7.657,0,0,1-1.88-.949,3.711,3.711,0,0,1-.6-.537,2.473,2.473,0,0,1-.182-.208l-.211-.252-.422-.5c-2.234-2.8-8.127-16.279-9.9-19.65Z" transform="translate(103.143 251.688)" fill="#ffb573"/>
        <path id="Path_2065" data-name="Path 2065" d="M255.87,149.431a19.9,19.9,0,0,1-15.157,1.473,20.271,20.271,0,0,1-11.391-9.227c-2.921-4.3-10.69-16.438-9.714-19.578,1.155-3.75,3.613-6.078,7.721-5.069,2.889.7,8.4,14.713,11.056,20.319,1.05,1.562,3.051,4.036,5.289,4.8,3.954,1.366,6.867.773,11.008.187.757.456.7,1.155,1.082,1.944a5.877,5.877,0,0,1,.106,5.15" transform="translate(103.731 252.465)" fill="#407bff"/>
        <path id="Path_2066" data-name="Path 2066" d="M213.795,84.381c-1.239,6.649-1.623,9.489-5.433,12.51-5.73,4.543-13.625,1.566-14.4-5.32-.694-6.2,1.451-16.03,8.34-17.884a9.224,9.224,0,0,1,11.489,10.695" transform="translate(108.651 260.788)" fill="#ffb573"/>
        <path id="Path_2067" data-name="Path 2067" d="M237.707,120.789c-1.269,1.447-1.669,2.285-3.432,3.069s-5.61.1-6.83-1.395c.4,1.164,2.711,3.257,3.924,3.419a6.866,6.866,0,0,1-8.456-1.488,4.377,4.377,0,0,0,2.878,3.161c-15.492.4-8.969-6.889-18.663-17.257-10.74-11.484-8.411-20.973-8.411-20.973a10.95,10.95,0,0,0-2.11-6.581c-1.552-1.988-1.1-4.673-.606-6.176.72-2.207,2.91-2.983,5.012-3.112.37-2.178,4.82-1.777,5.389-1.414.489.313.613.285,1.1-.027,1.094-.7,3.842-1.433,6.784.947,1.8,1.453,3.715,4.058,3.779,9.6.067,5.767-2.685,12.609,6.848,21.371,5.618,5.163,2.932,16.247,12.793,16.852" transform="translate(108.327 261.175)" fill="#263238"/>
        <path id="Path_2068" data-name="Path 2068" d="M197.67,87.368a5.064,5.064,0,0,0-.871,3.674c.271,1.706,1.935,1.82,3.139.725,1.084-.984,2.249-3.058,1.361-4.473a2.138,2.138,0,0,0-3.629.074" transform="translate(108.096 258.303)" fill="#ffb573"/>
        <path id="Path_2069" data-name="Path 2069" d="M206.284,172.994s-3.3,46.409-7.027,77.02A75.415,75.415,0,0,1,191,276.267l-18.26,34.405L163.394,307s6.438-32.7,19.065-50.536c0,0-9.425-53.3,2.91-83.629Z" transform="translate(114.484 241.73)" fill="#263238"/>
        <g id="Group_4111" data-name="Group 4111" transform="translate(305.317 425.963)" opacity="0.2">
          <g id="Group_4110" data-name="Group 4110">
            <g id="Group_4109" data-name="Group 4109" clipPath="url(#clip-path-12)">
              <path id="Path_2070" data-name="Path 2070" d="M200.079,186.935s2.778,21.052,4.306,33.527c-5.734-7.519-10.123-18.009-4.306-33.527" transform="translate(-197.335 -186.935)"/>
            </g>
          </g>
        </g>
        <path id="Path_2071" data-name="Path 2071" d="M244.846,365.534a.936.936,0,0,0,.192-.9.572.572,0,0,0-.38-.432c-.86-.288-2.876,1.285-3.1,1.466a.163.163,0,0,0-.056.173.165.165,0,0,0,.139.12,8.758,8.758,0,0,0,1.9.07,2.058,2.058,0,0,0,1.312-.492m-.3-1.022a.234.234,0,0,1,.16.2.616.616,0,0,1-.113.6,3.415,3.415,0,0,1-2.506.364c.825-.606,2.006-1.318,2.459-1.163" transform="translate(99.523 205.076)" fill="#407bff"/>
        <path id="Path_2072" data-name="Path 2072" d="M241.751,365.578c.638-.441,1.776-2.069,1.538-2.839a.587.587,0,0,0-.61-.406.881.881,0,0,0-.688.289c-.7.745-.513,2.751-.505,2.837a.168.168,0,0,0,.1.134.178.178,0,0,0,.087.015.172.172,0,0,0,.081-.03m.92-2.914c.217,0,.272.094.3.171.145.47-.546,1.677-1.167,2.27a3.732,3.732,0,0,1,.437-2.257.545.545,0,0,1,.357-.18.482.482,0,0,1,.077,0" transform="translate(99.53 205.427)" fill="#407bff"/>
        <path id="Path_2073" data-name="Path 2073" d="M231,363.188l7.081-.682-2.161-16.083-7.081.682Z" transform="translate(101.946 208.475)" fill="#ffb573"/>
        <path id="Path_2074" data-name="Path 2074" d="M238.792,365.25l-7.955.766a.559.559,0,0,0-.518.53l-.289,6.281a1.135,1.135,0,0,0,1.258,1.129c2.769-.315,4.09-.6,7.588-.94,2.152-.208,5.319-.29,8.29-.576,2.907-.281,2.843-3.194,1.579-3.336-5.665-.64-6.7-2.176-8.726-3.54a1.885,1.885,0,0,0-1.226-.314" transform="translate(101.719 204.869)" fill="#263238"/>
        <path id="Path_2075" data-name="Path 2075" d="M219.824,172.995s9.058,55.385,10.8,88.195l.326,58.769-7.329,1.43S213,283.895,211.782,262.224c-3.242-57.5-21.971-57.6-10.7-89.332Z" transform="translate(107.948 241.719)" fill="#263238"/>
        <path id="Path_2076" data-name="Path 2076" d="M238.722,354l-12.137,1.662L225.354,351l12.972-1.687Z" transform="translate(102.615 207.922)" fill="#407bff"/>
        <path id="Path_2077" data-name="Path 2077" d="M159.993,110.382h-.024l.008-.04c0,.016.008.024.016.04" transform="translate(115.14 253.701)" fill="#ffb573"/>
        <path id="Path_2078" data-name="Path 2078" d="M160.771,99.523h.464a.794.794,0,0,0-.8.846l.738,14.078a.9.9,0,0,0,.889.845H161.6a.9.9,0,0,1-.889-.845l-.738-14.078a.8.8,0,0,1,.8-.846" transform="translate(115.141 255.774)" fill="#407bff"/>
        <g id="Group_4114" data-name="Group 4114" transform="translate(275.109 355.297)" opacity="0.1">
          <g id="Group_4113" data-name="Group 4113">
            <g id="Group_4112" data-name="Group 4112" clipPath="url(#clip-path-13)">
              <path id="Path_2079" data-name="Path 2079" d="M160.771,99.523h.464a.794.794,0,0,0-.8.846l.738,14.078a.9.9,0,0,0,.889.845H161.6a.9.9,0,0,1-.889-.845l-.738-14.078a.8.8,0,0,1,.8-.846" transform="translate(-159.969 -99.523)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_2080" data-name="Path 2080" d="M161.768,115.291h5.678a.794.794,0,0,0,.8-.846l-.738-14.078a.9.9,0,0,0-.889-.845h-5.678a.794.794,0,0,0-.8.845l.738,14.078a.9.9,0,0,0,.889.846" transform="translate(115.108 255.774)" fill="#407bff"/>
        <g id="Group_4117" data-name="Group 4117" transform="translate(275.248 355.297)" opacity="0.4">
          <g id="Group_4116" data-name="Group 4116">
            <g id="Group_4115" data-name="Group 4115" clipPath="url(#clip-path-14)">
              <path id="Path_2081" data-name="Path 2081" d="M161.768,115.291h5.678a.794.794,0,0,0,.8-.846l-.738-14.078a.9.9,0,0,0-.889-.845h-5.678a.794.794,0,0,0-.8.845l.738,14.078a.9.9,0,0,0,.889.846" transform="translate(-160.14 -99.523)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_2082" data-name="Path 2082" d="M167.99,114.247a.523.523,0,0,1-.137.388.538.538,0,0,1-.38.154h-5.505a.609.609,0,0,1-.6-.574l-.72-13.654a.493.493,0,0,1,.137-.38.513.513,0,0,1,.38-.162h5.505a.609.609,0,0,1,.6.574l.048.978Z" transform="translate(115.01 255.679)" fill="#263238"/>
        <g id="Group_4120" data-name="Group 4120" transform="translate(277.025 355.699)" opacity="0.1">
          <g id="Group_4119" data-name="Group 4119">
            <g id="Group_4118" data-name="Group 4118" clipPath="url(#clip-path-15)">
              <path id="Path_2083" data-name="Path 2083" d="M167.948,101.571l-1.827,13.218H163.93l2.07-14.77h1.3a.609.609,0,0,1,.6.574Z" transform="translate(-162.644 -100.02)" fill="#fff"/>
              <path id="Path_2084" data-name="Path 2084" d="M162.833,114.79h-.494l2.07-14.77h.494Z" transform="translate(-162.339 -100.02)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_2085" data-name="Path 2085" d="M186.982,174.875a59.781,59.781,0,0,0-6.493,26.15c13.762,10.662,26.541-1.825,42.272.285-.548-7.1-2.962-20.25-5.828-26.774-10.4.439-20.131-.6-29.951.34" transform="translate(111.209 241.417)" fill="#407bff"/>
        <path id="Path_2086" data-name="Path 2086" d="M190.091,171.354s-1.11,2.847-.792,2.847h28.842c.247,0,.453-.121.469-.277l.248-2.462c.017-.171-.2-.314-.469-.314h-27.85a.5.5,0,0,0-.448.206" transform="translate(109.533 242.053)" fill="#fff"/>
        <path id="Path_2087" data-name="Path 2087" d="M195.081,174.387h-.746c-.148,0-.261-.077-.251-.171l.35-3.2c.01-.094.14-.17.289-.17h.746c.148,0,.261.076.251.17l-.35,3.2c-.011.094-.14.171-.289.171" transform="translate(108.605 242.11)" fill="#407bff"/>
        <path id="Path_2088" data-name="Path 2088" d="M217.445,174.387H216.7c-.148,0-.261-.077-.251-.171l.35-3.2c.01-.094.14-.17.289-.17h.746c.148,0,.261.076.251.17l-.35,3.2c-.011.094-.14.171-.289.171" transform="translate(104.321 242.11)" fill="#407bff"/>
        <rect id="Rectangle_2643" data-name="Rectangle 2643" width="404.214" height="310.32" transform="translate(145.786 274.84)" fill="none"/>
        <rect id="Rectangle_2644" data-name="Rectangle 2644" width="404.214" height="310.32" transform="translate(145.786 274.84)" fill="none"/>
      </g>
    </svg>
    )}
  </Icon>
);
