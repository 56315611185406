import React, {FC, FormEvent, useEffect, useState} from "react";
import {Button, Dropdown, TextField} from "../../../components";
import {CloseCircleIcon, SearchIcon} from "../../../components/icons";
import {FilterModel} from "../../../utils/types";

interface IUUIDFilterProps {
  title?: string;
  category?: string;
  opened?: boolean;
  value: FilterModel,
  onChange?: (value: FilterModel) => void;
  onDelete?: (value: FilterModel) => void;
}

export const UUIDFilter: FC<IUUIDFilterProps> = ({
  title = 'UUID',
  category = 'uuid',
  opened,
  value,
  onChange = () => {},
  onDelete = () => {},
}) => {
  const [search, setSearch] = useState('');
  const [dropdownOpened, setDropdownOpened] = useState(opened);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  useEffect(() => {
    setSearch(value.value || '');
  }, [value]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    onChange({
      category,
      text: `${title || 'UUID'}: “${search}”`,
      value: search,
    });
    setDropdownOpened(false);
  };

  return (
    <div className="flex items-center mb-2">
      <Dropdown
        text={value?.text || title}
        buttonProps={{
          className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4 shadow-md',
          color: 'primary',
        }}
        dropdownClass="w-88 max-h-unset rounded-bl-md rounded-br-md py-4"
        dropdownOpened={dropdownOpened}
        onDropdownOpened={() => setDropdownOpened(true)}
        onDropdownClosed={() => setDropdownOpened(false)}
      >
        <form className="flex items-center px-4" onSubmit={onSubmit}>
          <TextField
            size="sm"
            fullWidth
            inputClass="placeholder:text-xs"
            className="!rounded-full"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder={`Filter by ${title}`}
            onChange={(value) => setSearch(value)}
          />

          <Button
            type="submit"
            color="primary"
            className="shadow-md rounded-full text-sm px-6 ml-2"
            disabled={!search}
          >
            Filter
          </Button>
        </form>
      </Dropdown>

      <CloseCircleIcon
        className="ml-1 cursor-pointer"
        color="#DEDEDE"
        size={24}
        onClick={() => onDelete(value)}
      />
    </div>
  );
};
