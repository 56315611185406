import React, {ButtonHTMLAttributes, FC, MouseEventHandler, ReactElement, useMemo} from "react";
import classnames from "classnames";

type ButtonVariant = 'standard' | 'outline';
type ButtonColor = 'default' | 'primary' | 'primary-o' | 'secondary' | 'danger' | 'blue' | 'blue-light' | 'blue-dark' | 'blue-semi-dark' | 'white';
type ButtonShape = 'standard' | 'icon';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  className?: string;
  shape?: ButtonShape;
  leftIcon?: ReactElement;
  leftIconClass?: string;
  rightIcon?: ReactElement;
  rightIconClass?: string;
}

export const Button: FC<IButtonProps> = ({
  children,
  className = '',
  variant = 'standard',
  color = 'default',
  shape = 'standard',
  leftIcon,
  leftIconClass = '',
  rightIcon,
  rightIconClass = '',
  type = 'button',
  disabled,
  ...props
}) => {
  const buttonTheme = useMemo(() => {
    const classes: string[] = [];
    if (color !== 'default') {
      classes.push('border');
    }
    switch (color) {
      case 'primary':
        if (variant === 'standard') {
          classes.push('bg-primary text-white border-primary');
        } else {
          classes.push('text-primary border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white');
        }
        break;

      case 'primary-o':
        if (variant === 'standard') {
          classes.push('bg-primary bg-opacity-24 text-primary border-transparent hover:bg-opacity-12');
        } else {
          classes.push('text-primary border-primary hover:bg-primary bg-opacity-24 hover:text-white');
        }
        break;

      case 'secondary':
        if (variant === 'standard') {
          classes.push('bg-secondary text-white border-secondary');
        } else {
          classes.push('text-secondary border-secondary hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white');
        }
        break;

      case 'blue':
        if (variant === 'standard') {
          classes.push('bg-blue text-white border-blue');
        } else {
          classes.push('text-blue border-blue hover:bg-blue hover:text-white focus:bg-blue focus:text-white');
        }
        break;

      case 'blue-light':
        if (variant === 'standard') {
          classes.push('bg-blue-light text-white border-blue-light');
        } else {
          classes.push('text-blue-light border-blue-light hover:bg-blue-light hover:text-white');
        }
        break;

      case 'blue-dark':
        if (variant === 'standard') {
          classes.push('bg-blue-dark text-white border-blue-dark');
        } else {
          classes.push('text-blue-dark border-blue-dark hover:bg-blue-dark hover:text-white');
        }
        break;

      case 'blue-semi-dark':
        if (variant === 'standard') {
          classes.push('bg-blue-semidark text-white border-blue-semidark');
        } else {
          classes.push('text-blue-semidark border-blue-semidark hover:bg-blue-semidark hover:text-white');
        }
        break;

      case 'danger':
        if (variant === 'standard') {
          classes.push('bg-danger text-white border-danger');
        } else {
          classes.push('text-danger border-danger hover:bg-danger hover:text-white focus:bg-danger focus:text-white');
        }
        break;

      case 'white':
        if (variant === 'standard') {
          classes.push('bg-white text-primary border-white');
        } else {
          classes.push('text-white border-white hover:bg-white hover:text-primary focus:bg-white focus:text-primary');
        }
        break;
    }

    return classes;
  }, [variant, color]);

  return (
    <button
      className={classnames(
        'flex-center font-medium uppercase outline-none px-3 py-2 transition-all',
        buttonTheme,
        className,
        { 'opacity-60': disabled }
      )}
      type={type}
      disabled={disabled}
      {...props}
    >
      {
        !!leftIcon && (
          <div className={classnames(
            'flex-shrink-0 flex items-center',
            { 'mr-2': shape !== 'icon' },
            leftIconClass
          )}>{leftIcon}</div>
        )
      }
      { children }
      {
        !!rightIcon && (
          <div className={classnames(
            'flex-shrink-0 flex items-center',
            { 'ml-2': shape !== 'icon' },
            rightIconClass
          )}>{rightIcon}</div>
        )
      }
    </button>
  );
};
