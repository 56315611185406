import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppListLastSentIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={25.2} baseHeight={28} {...props}>
    {(width, height, color = '#f6a734') => (
      <svg width={width} height={height} viewBox="0 0 25.2 28">
        <path
          id="Icon_material-date-range"
          data-name="Icon material-date-range"
          d="M12.9,15.6H10.1v2.8h2.8Zm5.6,0H15.7v2.8h2.8Zm5.6,0H21.3v2.8h2.8Zm2.8-9.8H25.5V3H22.7V5.8H11.5V3H8.7V5.8H7.3A2.787,2.787,0,0,0,4.514,8.6L4.5,28.2A2.8,2.8,0,0,0,7.3,31H26.9a2.808,2.808,0,0,0,2.8-2.8V8.6A2.808,2.808,0,0,0,26.9,5.8Zm0,22.4H7.3V12.8H26.9Z"
          transform="translate(-4.5 -3)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
