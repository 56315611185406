import React, {FC} from "react";
import {Button} from "../../../../../components";
import {ArrowLeftIcon} from "../../../../../components/icons";
import {WhatsAppStep3Svg} from "../../../../../components/svgs";

export interface IFollowStep3Props {
  onBack(): void;
  onNext(): void;
}

const FollowStep3: FC<IFollowStep3Props> = ({
  onBack, onNext,
}) => {
  return (
    <div className="h-full flex flex-col text-center px-8 pt-8">
      <h6 className="font-semibold">To set up your number for WhatsApp</h6>
      <p className="text-primary text-3xl font-bold">Follow all the steps below</p>
      <div className="max-w-160 mx-auto mt-10 mb-6">
        <WhatsAppStep3Svg size={240} className="mx-auto" />
        <p className="font-semibold mt-10">
          Once you have selected "Call me" the call might not work the first time, in this case you have to wait
          1 hour before selecting "Call me" again.
        </p>
      </div>
      <div className="flex mt-auto mb-6">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FollowStep3;
