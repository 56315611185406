import { CHAT_INTRO_NOTIFY_TYPES, CHAT_LOG_STATUS } from "../enums";
import { WAChatIntroLogService } from "../../services";

export class WAChatIntroLogModel {
  uuid: string;
  source: string;
  reference_id: string;
  lead_wa_number: string;
  lead_wa_name: string;
  notified_destination: string;
  notify_type: CHAT_INTRO_NOTIFY_TYPES;
  notify_message_status: string;
  response_time: number;
  status: CHAT_LOG_STATUS;
  created: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      source: '',
      reference_id: '',
      lead_wa_number: '',
      lead_wa_name: '',
      notified_destination: '',
      notify_type: '',
      response_time: 0,
      status: '',
      created: '',
      ...init,
    };

    data.lead_wa_number = init.contact_data.whatsapp_number;
    data.lead_wa_name = init.contact_data?.whatsapp_name;

    Object.assign(this, data);
    switch (this.notify_type) {
      case CHAT_INTRO_NOTIFY_TYPES.WHATSAPP:
        this.notify_message_status = init.notify_data?.message_status;
        this.notified_destination = init.notify_data?.to_phone_number;
        break;
      case CHAT_INTRO_NOTIFY_TYPES.SMS:
        this.notified_destination = init.notify_data?.to_phone_number;
        break;
      case CHAT_INTRO_NOTIFY_TYPES.VOICE_CALL:
        this.notified_destination = init.notify_data?.to_phone_number;
        break;
      case CHAT_INTRO_NOTIFY_TYPES.WEBHOOK:
        this.notified_destination = init.notify_data?.url;
        break;
      case CHAT_INTRO_NOTIFY_TYPES.EMAIL:
        this.notified_destination = init.notify_data?.email;
        break;
    }
  }

  static search(query: any = {}, ...kwargs) {
    return WAChatIntroLogService.search(query, ...kwargs);
  }
}
