import React, {FC, ReactElement, useCallback, useState} from "react";
import classnames from "classnames";
import {WithTooltipIcon} from "../../WithTooltipIcon";
import {IAbstractInputControlProps} from "../types";

export interface IInputWrapperProps {
  value?: any;
  maxLength?: number;
  children(props: IInputOptions): ReactElement;
}

export interface IInputOptions {
  className: string;
  containerClass: string;
  disabled: boolean;
  readonly: boolean;
  onFocus(): void;
  onBlur(): void;
}

export const InputWrapper: FC<IInputWrapperProps & IAbstractInputControlProps> = ({
  className = '',
  label = '',
  labelClass = 'text-xs',
  labelFloat = false,
  value = '',
  description = '',
  descriptionClass = 'text-xs mb-2',
  tooltip,
  tooltipPlacement,
  containerClass = '',
  inputClass = '',
  focusClass = '',
  fullWidth = false,
  direction = 'vertical',
  size = 'md',
  icon,
  iconPosition = 'left',
  iconClass = '',
  maxLength,
  readonly = false,
  disabled = false,
  error,
  helperText,
  helperTextClass = '',
  absoluteHelper = false,
  onBlur = () => {},
  children,
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback(() => {
    setFocused(false);
    onBlur();
  }, [onBlur]);

  return (
    <div className={classnames(
      `input-wrapper input-size-${size} relative flex`,
      (error !== undefined || helperText) && 'pb-5',
      fullWidth ? 'w-full' : 'w-full max-w-120',
      direction === 'horizontal' ? 'items-center' : 'flex-col',
      containerClass,
    )}>
      {label && (
        <label className={classnames(
          'input-label relative text-blue-dark flex items-center mb-1 transition-all',
          labelFloat && (!focused && !value ? `as-placeholder` : 'top-0 left-4'),
          { '!text-danger': error },
          labelClass,
        )}>
          <WithTooltipIcon tooltip={tooltip} placement={tooltipPlacement} iconClass="ml-1.5">
            <div>{label}</div>
          </WithTooltipIcon>
        </label>
      )}
      {description && (
        <p className={classnames(
          error ? 'text-danger' : 'text-blue-dark',
          descriptionClass,
        )}>
          {description}
        </p>
      )}
      <div
        className={classnames(
          'flex items-center border rounded transition-all',
          { 'bg-gray-f5 bg-opacity-40 border-0': disabled || readonly },
          { 'bg-gray-f8': !readonly && !disabled },
          { 'border-gray-f8': !readonly && !disabled && !focused && !error },
          { 'border-[#639be0]': !readonly && !disabled && focused && !error },
          { 'border-danger': !readonly && !disabled && error },
          { '!bg-gray-fc': !readonly && !disabled && focused },
          { [focusClass]: focused },
          className,
        )}
      >
        {
          iconPosition === 'left' && !!icon && (
            <div className={classnames('flex-shrink-0 flex items-center ml-3 -mr-2', iconClass)}>{ icon }</div>
          )
        }
        {children({
          containerClass: 'w-0 flex-grow bg-transparent !p-0 outline-none transition-all',
          className: classnames('!rounded', inputClass),
          disabled,
          readonly,
          onFocus: () => setFocused(true),
          onBlur: handleBlur,
        })}
        {
          iconPosition === 'right' && !!icon && (
            <div className={classnames('flex-shrink-0 flex items-center mr-3 -ml-2', iconClass)}>{ icon }</div>
          )
        }
      </div>
      {(maxLength || helperText) && (
        <div className={classnames(
          'flex items-start text-xs ml-2',
          absoluteHelper ? 'absolute -bottom-5 left-0' : 'mt-2',
          { '!text-danger': error },
          helperTextClass,
        )}>
          <div>{helperText || `You have a limit of ${maxLength} characters`}</div>
          {!!maxLength && (
            <div className={classnames(
              'flex items-center text-blue ml-auto',
              value?.length > maxLength ? 'text-danger' : 'text-blue',
            )}>
              <span className={"ml-2"}>Characters:</span>
              <span className="font-semibold min-w-18 text-center ml-1">
                {value.length}/{maxLength}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
