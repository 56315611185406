import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const PauseIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"
        />
      </svg>
    )}
  </Icon>
);
