import {TOAST_ACTION} from "../action-types";
import {IToastOptions} from "../../components";

export const showToast = (options: IToastOptions) => ({
  type: TOAST_ACTION.SHOW_TOAST,
  options,
});

export const closeToast = () => ({
  type: TOAST_ACTION.CLOSE_TOAST,
});
