export class ApiTokenModel {
  uuid: string;
  name: string;
  key: string;
  org_uuid: string;
  created_by_uuid: string;
  permissions: string[];
  created: string;
  modified: string;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      name: '',
      key: '',
      org_uuid: null,
      created_by_uuid: '',
      permissions: [],
      created: '',
      modified: '',
      ...init,
    };

    this.uuid = data.uuid;
    this.name = data.name;
    this.key = data.key;
    this.org_uuid = data.org_uuid;
    this.created_by_uuid = data.created_by_uuid;
    this.permissions = data.permissions;
    this.created = data.created;
    this.modified = data.modified;
  }
}
