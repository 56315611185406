import React, { FC, ReactElement, useState } from "react";
import { Button, TextField, Modal, Input } from "../../components";
import { TextFileInput, TextFileInputForm } from "../TextFileInput";
import { FormControl, Validators } from "../../components/ReactiveForm";
import { AUDIO_TYPE } from "../../utils/enums";

export type ConfirmType = 'default' | 'passcode' | 'textFile' | 'email';

export interface IConfirmationModalProps {
  type?: ConfirmType;
  audioType?: AUDIO_TYPE;
  title?: string | ReactElement;
  message?: string | ReactElement;
  confirmMessage?: string | ReactElement;
  passcode?: string;
  confirmButtonText?: string;
  onClose?: (confirm: boolean | TextFileInputForm | string) => void;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
  type = 'default',
  audioType,
  title = 'Confirmation',
  message,
  confirmMessage,
  passcode,
  confirmButtonText = 'Confirm',
  onClose = () => { },
}) => {
  const [code, setCode] = useState('');
  const [textFileForm, setTextFileForm] = useState<TextFileInputForm>();
  const [emailInput] = useState(new FormControl('', [Validators.required(), Validators.email()]));

  const onSubmitTextFile = () => {
    if (textFileForm.validate()) {
      onClose(textFileForm);
    }
  };

  const onConfirm = () => {
    if (type === 'default') {
      onClose(true);
    } else if (type === 'email') {
      if (!emailInput.invalid) {
        onClose(emailInput.value);
      }
    }
  };

  return (
    <Modal
      size="sm"
      title={title}
      overrideParentModal
      footerComponent={(
        <>
          {(type === 'default' || type === 'email') && (
            <Button
              data-cy="confirm"
              color="primary"
              className="text-sm rounded-full shadow-md px-4 ml-auto"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          )}
          {type === 'passcode' && (
            <Button
              data-cy="confirm"
              color="primary"
              className="text-sm rounded-full shadow-md px-4 ml-2"
              disabled={passcode && code !== passcode}
              onClick={() => onClose(true)}
            >
              {confirmButtonText}
            </Button>
          )}
          {type === 'textFile' && (
            <Button
              data-cy="confirm"
              color="primary"
              className="text-sm rounded-full shadow-md px-4 ml-2"
              onClick={onSubmitTextFile}
            >
              Confirm
            </Button>
          )}
        </>
      )}
      onClose={() => onClose(false)}
    >
      <div className="z-[99999999]">
        {message && (
          <p className="text-sm">{message}</p>
        )}
        {confirmMessage && (
          <p className="text-sm mt-2">{confirmMessage}</p>
        )}
        {type === 'passcode' && confirmMessage !== null && (
          <p className="text-sm mt-2">If you are sure, please type <b>'{passcode}'</b> in the text box below.</p>
        )}
        {type === 'passcode' && (
          <TextField
            containerClass="mt-4"
            fullWidth
            value={code}
            placeholder={passcode}
            onChange={setCode}
          />
        )}
        {type === 'textFile' && (
          <TextFileInput className="mt-4" audioType={audioType} onInit={setTextFileForm} />
        )}
        {type === 'email' && (
          <Input
            control={emailInput}
            containerClass="mt-4"
            fullWidth
            placeholder="Enter your email address"
          />
        )}

      </div>


    </Modal>
  );
}
