import React, {FC, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {Slider} from "@mui/material";
import {PauseCircleIcon, PlayCircleIcon} from "../icons";
import {formatDuration} from "../../utils/helpers";

export interface IAudioPlayerProps {
  className?: string;
  src?: string;
  disabled?: boolean;
}

export const AudioPlayer: FC<IAudioPlayerProps> = ({
  className = '',
  src,
  disabled,
}) => {
  const ref = useRef<HTMLAudioElement>();
  const [available, setAvailable] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setAvailable(false);
    setCurrentTime(0);
    setDuration(0);
    setPlaying(false);

    ref.current.load()
  }, [src]);

  const onLoadAudio = () => {
    setDuration(ref.current.duration);
    setCurrentTime(0);
  };

  const onPlay = () => {
    ref.current.play().then(() => {
      setPlaying(true);
    });
  };

  const onPause = () => {
    ref.current.pause();
    setPlaying(false);
  };

  const onSeekTime = (time) => {
    ref.current.currentTime = time;
    onPlay();
  };

  const audioTag = (
    <audio
      ref={ref}
      onLoadedMetadata={onLoadAudio}
      onCanPlay={() => setAvailable(true)}
      onError={() => setAvailable(false)}
      onTimeUpdate={() => setCurrentTime(ref.current.currentTime)}
      onEnded={() => setPlaying(false)}
    >
      <source src={src}/>
    </audio>
  );

  return (
    <div
      className={classnames(
        'flex-center rounded px-4 py-2',
        className,
      )}
    >
      {playing ? (
        <PauseCircleIcon
          data-cy="pause-audio"
          className="cursor-pointer"
          size={28}
          color="primary"
          onClick={onPause}
        />
      ) : (
        <PlayCircleIcon
          data-cy="play-audio"
          className={classnames(
            'cursor-pointer',
            { 'pointer-events-none': !available || disabled },
          )}
          size={28}
          color={available && !disabled ? 'primary' : '#c3c3c3'}
          onClick={onPlay}
        />
      )}
      <Slider
        className="slider-primary size-xs ml-4"
        classes={{
          valueLabel: '!transform-none !bg-transparent !text-2xs !text-gray !mt-5'
        }}
        min={0}
        max={duration}
        value={currentTime}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => formatDuration(value, 'auto')}
        disabled={!available || disabled}
        onChange={(_, value) => onSeekTime(value as number)}
      />
      {src && audioTag}
    </div>
  );
};
