import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const FocusCircleIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={19.75} baseHeight={19.75} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 19.75 19.75">
        <path
          fill={color}
          d="M9.875,0A10.133,10.133,0,0,0,0,9.875,10.133,10.133,0,0,0,9.875,19.75,10.133,10.133,0,0,0,19.75,9.875,10.133,10.133,0,0,0,9.875,0Zm8.181,9.052H16.4a6.589,6.589,0,0,0-5.7-5.7V1.694A8.665,8.665,0,0,1,18.056,9.052Zm-8.181,5.76a4.938,4.938,0,1,1,4.938-4.937A4.943,4.943,0,0,1,9.875,14.813ZM9.052,1.694V3.349a6.589,6.589,0,0,0-5.7,5.7H1.694A8.665,8.665,0,0,1,9.052,1.694Zm-7.358,9H3.349a6.589,6.589,0,0,0,5.7,5.7v1.654A8.665,8.665,0,0,1,1.694,10.7Zm9,7.358V16.4a6.589,6.589,0,0,0,5.7-5.7h1.654A8.665,8.665,0,0,1,10.7,18.056Zm0,0"
        />
      </svg>
    )}
  </Icon>
);
