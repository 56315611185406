import React, {FC, useCallback, useEffect, useState} from "react";
import {NumberModel} from "../../../../utils/types";
import {FormArray, FormControl, FormGroup, Input, Validators} from "../../../../components/ReactiveForm";
import {Button, Card, WithTooltipIcon} from "../../../../components";
import {CloseCircleIcon, PlusCircleIcon} from "../../../../components/icons";
import {AddExtraInfoModal, IExtraInfo, IExtraInfoForm} from "../../../../parts";

export interface IExtraInfoProps {
  number: NumberModel;
  form: FormArray<IExtraInfoForm>;
  formData: IExtraInfo[];
  initialForm?:any
  onUpdate(data: any): void;
}

const ExtraInfo: FC<IExtraInfoProps> = ({
  number,
  form,
  formData = [],
  initialForm,
  onUpdate,
}) => {
  const [showExtraInfoModal, setShowExtraInfoModal] = useState(false);

  const onUpdateForm = useCallback((data: IExtraInfo[]) => {
    form.clear();
    data.forEach((item) => {
      form.append(new FormGroup({
        label: new FormControl(item.label, [Validators.required()]),
        content: new FormControl(item.content, [Validators.required()]),
      }));
    });
    onSave();
  }, [form]);

  useEffect(() => {
    const data: IExtraInfo[] = [];
    Object.entries(number.meta || {}).forEach(([key, value]) => {
      data.push({
        label: key,
        content: value,
      });

    });
    initialForm.extraInfo = data
    onUpdateForm(data);
  }, [number, onUpdateForm]);

  const onSave = () => {
    if (!form.validate()) {
      return;
    }

    const formData = form.getFormData();
    const meta: any = {};
    formData.forEach((item) => {
      meta[item.label] = item.content;
    });

    onUpdate({
      meta_data: meta,
    });
  };

  const onDeleteItem = (item: IExtraInfoForm) => {
    form.remove(item);
    onSave();
  };

  return (
    <>
      <Card
        size="sm"
        header={(
          <WithTooltipIcon
            tooltip={`Any extra info you would like to add onto your number. E.g if you used it in a Facebook campaign. Extra Info allows you to give more context to your number`}
            placement="right"
            iconClass="ml-4"
          >
            <Button
              data-cy="add-extra-info"
              className="text-blue px-0"
              leftIcon={<PlusCircleIcon />}
              onClick={() => setShowExtraInfoModal(true)}
            >
              Extra info
            </Button>
          </WithTooltipIcon>
        )}
      >
        {!!formData?.length && (
          <div>
            {form.controls.map((item, i) => (
              <div key={i} className="flex items-center group">
                <Input
                  label={item.controls.label.value}
                  control={item.controls.content}
                  containerClass="mt-4"
                  onBlur={onSave}
                />
                <CloseCircleIcon
                  className="text-gray-d opacity-0 group-hover:opacity-100 mt-8 ml-3 cursor-pointer transition-all"
                  size={32}
                  onClick={() => onDeleteItem(item)}
                />
              </div>
            ))}
          </div>
        )}
      </Card>

      {showExtraInfoModal && (
        <AddExtraInfoModal
          data={form.getFormData()}
          onSave={onUpdateForm}
          onClose={() => setShowExtraInfoModal(false)}
        />
      )}
    </>
  );
};

export default ExtraInfo;
