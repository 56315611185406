import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "../../../../components";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { MessageBoxService, ToastService } from "../../../../services";
import { WEBHOOK_TYPE, WA_WEBHOOK_TYPE } from "../../../../utils/enums";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import { WebhookModel } from "../../../../utils/types";
import { IWebhookFormInitParams, WebhookForm, deselectAllSubscribedStatuses } from "../WebhookForm";

export const WebhookEdit = () => {
  //hooks
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const queryParams = new URLSearchParams(window.location.search);

  //state
  const [editForm, setEditForm] = React.useState<IWebhookFormInitParams>({
    getFormData: () => null,
  });
  const [webhook, setWebhook] = React.useState<WebhookModel>();
  const [touched, setTouched] = React.useState<boolean>(false)
  const [webhookTouched, setWebhookTouched] = React.useState<boolean>(false)
  const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false)

  React.useEffect(() => {
    WebhookModel.get_service(queryParams.get("type")).find(params.id).then(data => {
      setWebhook(data);
    }).catch(err => {
      ToastService.showHttpError(err, 'Loading project failed');
      onClose();
    });
  }, [params])

  //functions
  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(bypass, touched, webhookTouched, setShowConfirmCancel, history, ROUTES.DEVELOPER.WEBHOOKS.INDEX)
  };

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      if (webhook.type === WEBHOOK_TYPE.WHATSAPP && data.type !== WEBHOOK_TYPE.WHATSAPP) {
        ToastService.error(<><span className="text-success mr-1">Error</span> WhatsApp webhook cannot be changed into a non WhatsApp webhook.</>);
        return
      }
      if (webhook.type !== WEBHOOK_TYPE.WHATSAPP && data.type === WEBHOOK_TYPE.WHATSAPP) {
        ToastService.error(<><span className="text-success mr-1">Error</span> Non WhatsApp webhook cannot be changed into a WhatsApp webhook.</>);
        return
      }

      if (data.type === WEBHOOK_TYPE.WHATSAPP && data.webhook_type !== WA_WEBHOOK_TYPE.ALL)
        data.subscribed_statuses = deselectAllSubscribedStatuses(
          data.subscribed_statuses,
          (data.webhook_type === WA_WEBHOOK_TYPE.MESSAGE)
            ? WA_WEBHOOK_TYPE.CHATINTROLOG
            : WA_WEBHOOK_TYPE.MESSAGE,
        );

      webhook.update(data).then((res) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onClose(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Updating webhook failed');
      });
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">WEBHOOK</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        webhook.delete().then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose();
        }).catch((err) => {
          if (err?.response.data[0].includes('This webhook is still attached to')) {
            ToastService.error('Webhook cannot be deleted as it is attached to a number. If you wish to delete it, please remove it from the number.');
          } else {
            ToastService.showHttpError(err, 'Deleting webhook failed');
          }
        });
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Edit Webhook</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={() => onClose()} />
      </div>
      <div className="h-0 flex-grow overflow-auto my-4 mx-26">
        <WebhookForm
          webhook={webhook}
          setForm={setEditForm}
          onClose={onClose}
          confirmCancel={showConfirmCancel}
        />
        <div className="flex flex-row bg-inherit justify-end">
          <Button
            data-cy="webhook-delete"
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Delete Webhook
          </Button>
          <Button
            data-cy="webhook-save"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
