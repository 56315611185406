export const CONFIG = {
  API_SERVER: process.env.REACT_APP_API_SERVER,
  INBOX_URL: "https://inbox.sudonum.com",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  fireAuth: {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECTID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    functionBaseURL: process.env.REACT_APP_FB_FUNC_BASEURL,
    messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  },
  MAX_SMS_LENGTH: 160,
};
