import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppFailedIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={30} baseHeight={30} {...props}>
    {(width, height, color = '#fe1722') => (
      <svg width={width} height={height} viewBox="0 0 30 30">
        <path
          d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z"
          transform="translate(-3 -3)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
