import React, {FC, useState} from "react";
import {Button, Modal} from "../../../components";
import SendMessageForm, {ISendMessageFormInitParams} from "../SendMessageForm";
import {SmsService, ToastService} from "../../../services";
import {SmsRecordModel} from "../../../utils/types";
import { ConfirmationModal } from "../../../parts";

interface IReplyMessageModalProps {
  receivedMessage: SmsRecordModel;
  setTouched?: any;
  showConfirmCancel?: any,
  setShowConfirmCancel?:any,
  touched?: any,
  onClose: (result?: any) => void;
}

const ReplyMessageModal: FC<IReplyMessageModalProps> = ({
  receivedMessage, onClose,setShowConfirmCancel, touched, showConfirmCancel, setTouched
}) => {
  const [messageForm, setMessageForm] = useState<ISendMessageFormInitParams>({
    getFormData: () => null,
  });

  const onSend = () => {
    const data = messageForm.getFormData();
    if (data) {
      SmsService.sendSms({
        from_number: data.fromNumber.replace(/\D/g, ''),
        to_number: data.targetNumber.replace(/\D/g, ''),
        text: data.text,
      }).then((res) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> sent message.</>);
        onClose(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Send message failed');
      });
    }
  };

  const close = () => {
    if (touched) {
      setShowConfirmCancel(true)
      return;
    }
    onClose()
  }

  return (
    <Modal
      title="Reply to Sender"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={close}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onSend}
          >
            Send SMS
          </Button>
        </>
      )}
      onClose={close}
    >
      {
        showConfirmCancel &&
        <ConfirmationModal

          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={onClose}
          />
      }
      <SendMessageForm
      receivedMessage={receivedMessage}
      onInit={setMessageForm}
      setTouched={setTouched}
      onClose={close}
      />
    </Modal>
  );
};

export default ReplyMessageModal;

