import moment from "moment";
import {CALL_LOG_ACTION} from "../action-types";
import {IDataTableRef} from "../../components";
import {CALL_DIRECTION, CALL_STATUS, CALL_TAB} from "../../utils/enums";
import {FilterModel} from "../../utils/types";
import {CallRecordModel} from "../../utils/types";

export interface ICallLogState {
  tab: CALL_TAB;
  filters: FilterModel[];
  logs: CallRecordModel[];
  selectedLogs: CallRecordModel[];
  tableRef?: IDataTableRef;
}

const initialState: ICallLogState = {
  tab: CALL_TAB.RECEIVED,
  filters: [],
  logs: [],
  selectedLogs: [],
};

const callLogReducer = (state: ICallLogState = initialState, action: any) => {
  switch (action.type) {
    case CALL_LOG_ACTION.SET_TAB: {
      let newFilter;
      let filters = [...state.filters];
      if (action.tab === CALL_TAB.MADE) {
        newFilter = {
          category: 'direction',
          text: 'Calls Made',
          value: CALL_DIRECTION.OUTBOUND,
          locked: true,
        };
      } else if (action.tab === CALL_TAB.RECEIVED) {
        newFilter = {
          category: 'direction',
          text: 'Calls Received',
          value: CALL_DIRECTION.INBOUND,
          locked: true,
        };
      } else if (action.tab === CALL_TAB.BLOCKED) {
        newFilter = {
          category: 'status',
          text: 'Blocked Calls',
          value: CALL_STATUS.BLOCKED_NUMBER,
          locked: true,
        };
        filters = filters.filter((item) => item.category !== 'status');
      } else {
        return state;
      }

      if (!filters.length) {
        filters = [
          newFilter,
          {
            category: 'range',
            text: 'Last 7 Days',
            value: {
              startDate: moment().subtract(7, 'days').startOf('day').toDate(),
              endDate: moment().endOf('day').toDate(),
            },
          },
        ];
      } else {
        filters = filters.filter((item) => !item.locked);
        filters.unshift(newFilter);
      }

      return {
        ...state,
        filters,
        tab: action.tab,
      };
    }

    case CALL_LOG_ACTION.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case CALL_LOG_ACTION.ADD_FILTERS: {
      let newFilters = state.filters.filter((item) => (
        action.filters.every((f) => f.category !== item.category)
      ));
      newFilters = newFilters.concat(action.filters);
      return {
        ...state,
        filters: newFilters,
      };
    }

    case CALL_LOG_ACTION.SET_LOGS:
      return {
        ...state,
        logs: action.logs,
      };

    case CALL_LOG_ACTION.SET_SELECTED_LOGS:
      return {
        ...state,
        selectedLogs: action.selectedLogs,
      };

    case CALL_LOG_ACTION.SET_TABLE_REF:
      return {
        ...state,
        tableRef: action.tableRef,
      };

    default:
      return state;
  }
};

export default callLogReducer;
