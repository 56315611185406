import React, {FC, useEffect} from "react";
import {useLocation} from "react-router";
import Header from "./Header";
import FullLayoutSidebar from "./Sidebar";
import {useToggle} from "../../hooks";
import './style.css';

const FullLayout: FC = ({ children }) => {
  const location = useLocation();
  const [sidebarCollapsed, toggleSidebar] = useToggle(true);

  useEffect(() => {
    const element = document.querySelector('.full-layout');
    element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <div className="full-layout w-full h-screen flex flex-col">
      <Header
        sidebarCollapsed={sidebarCollapsed}
        onToggleSidebar={toggleSidebar}
      />
      <div className="h-0 flex-grow flex relative">
        <FullLayoutSidebar
          sidebarCollapsed={sidebarCollapsed}
          onToggleSidebar={toggleSidebar}
        />
        <div className="page-content w-0 h-full flex-grow bg-gray-f8 md:ml-14 lg:m-0 overflow-auto">
          {children}

          {!sidebarCollapsed && (
            <div
              className="lg:hidden fixed top-0 left-0 !z-[999] w-screen h-screen bg-black opacity-40"
              onClick={toggleSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FullLayout;
