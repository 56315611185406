import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { IssueModel } from "../utils/types";
import { HttpService } from "./http.service";

export class IssueService {
  static search(query = {}, showSpinner = true) {
    return HttpService.get('/errors/tracking/', trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(IssueModel, res));
  }

  static find(issueID: string, showSpinner = true) {
    return HttpService.get(`/errors/tracking/${issueID}/`, {}, {}, showSpinner)
      .then((res) => new IssueModel(res));
  }
}
