import React, { FC, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { ArrowLeftIcon } from "../../../../components/icons";
import { Button, Input } from "../../../../components";
import { Form, FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import { INumberCreateForm } from "../index";

export interface INumberCreateStep5Props {
  formData: INumberCreateForm;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<INumberCreateForm>): void;
}

export const NumberCreateStep5: FC<INumberCreateStep5Props> = ({
  formData, onBack, onNext, onSubmit,
}) => {
  const [form, data] = useForm<{
    recordCallsEnabled: FormControl;
    recordCalls: FormControl;
    whisperMessageEnabled: FormControl;
    whisperMessage: FormControl;
  }>({
    recordCallsEnabled: new FormControl(false),
    recordCalls: new FormControl('', [Validators.required()], { disabled: true }),
    whisperMessageEnabled: new FormControl(false),
    whisperMessage: new FormControl('', [Validators.required()], { disabled: true }),
  });

  useEffect(() => {
    form.patch({
      recordCallsEnabled: formData.recordCallsEnabled || false,
      recordCalls: formData.recordCalls || '',
      whisperMessageEnabled: formData.whisperMessageEnabled || false,
      whisperMessage: formData.whisperMessage || '',
    });
  }, [formData]);

  useEffect(() => {
    form.controls.recordCalls.disable(!data.recordCallsEnabled);
    form.controls.whisperMessage.disable(!data.whisperMessageEnabled);
  }, [data]);

  const onContinue = (data) => {
    onSubmit({
      ...data,
      recordCalls: data.recordCallsEnabled ? data.recordCalls : '',
      whisperMessage: data.whisperMessageEnabled ? data.whisperMessage : '',
    });
    onNext();
  };

  return (
    <Form className="h-full flex flex-col" formGroup={form} onSubmit={onContinue}>
      <div className="h-0 flex-grow overflow-x-visible overflow-y-auto px-4">
        <div className="text-center">
          <h6 className="text-sm font-semibold mt-4">Number setup</h6>
          <p className="text-blue text-4xl font-bold">Enable features on your number</p>
        </div>
        <div className="max-w-150 mt-10 mx-auto">
          <div>
            <Input
              type="switch"
              control={form.controls.recordCallsEnabled}
              label="Record your calls"
              labelClass="!font-semibold"
            />
            <p className="text-xs">Add an optional disclaimer to let people know the call is recorded.</p>
            <Tooltip arrow title={<>This is a brief description on why enabling<br />recording your calls in necessary</>}>
              <span className="text-blue text-sm !font-bold">Why is this necessary?</span>
            </Tooltip>
            <Input
              control={form.controls.recordCalls}
              containerClass="mt-1"
              inputClass="!text-sm"
              placeholder="e.g. This call will be recorded for quality and training purposes."
            />
          </div>
          <div className="mt-6">
            <Input
              type="switch"
              control={form.controls.whisperMessageEnabled}
              label="Whisper Message"
              labelClass="!font-semibold"
            />
            <p className="text-xs">This is a short introductory message that plays when you receive a call. The caller does not hear it.</p>
            <Tooltip arrow title={<>This is a short introductory message that plays when you receive a call.<br />The caller does not hear it.</>}>
              <span className="text-blue text-sm !font-bold">Who will hear this?</span>
            </Tooltip>
            <Input
              control={form.controls.whisperMessage}
              containerClass="mt-1"
              inputClass="!text-sm"
              placeholder="e.g. This call is for marketing purposes."
            />
          </div>
        </div>
      </div>
      <div className="flex mt-4 mx-4">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button
          data-cy="create-number-step-4"
          type="submit"
          color="primary"
          className="text-sm rounded-full shadow-md px-6 ml-2"
        >
          Continue
        </Button>
      </div>
    </Form>
  );
};
