import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { FormControl, FormElement, FormGroup, Input, Validators } from "../../components/ReactiveForm";
import { AUDIO_TYPE } from "../../utils/enums";

export type TextFileInputMode = 'text' | 'file';
export interface TextFileInputValue {
  mode?: TextFileInputMode;
  text?: string;
  file?: File;
}
export type TextFileInputForm = FormGroup<{
  enabled?: FormControl;
  mode: FormControl;
  text: FormControl;
  file: FormControl;
  [field: string]: FormElement;
}>;

interface ITextFileInputProps {
  className?: string;
  form?: TextFileInputForm;
  value?: TextFileInputValue;
  disabled?: boolean;
  fileAccept?: string[];
  audioType?: AUDIO_TYPE;
  hidetts?: boolean;
  initialForm?: any;
  setTouched?: any;
  onInit?: (form: TextFileInputForm) => void;
  onUploadFile?: (file: File | string) => Promise<any>;
  onRemoveFile?: (fileName: string) => Promise<void>;
  onChange?: (value: any) => void;
}

export const TextFileInput: FC<ITextFileInputProps> = ({
  audioType,
  className = '',
  form,
  value,
  disabled = false,
  fileAccept = ['audio/*'],
  hidetts,
  initialForm,
  setTouched,
  onInit = () => { },
  onUploadFile,
  onRemoveFile,
  onChange,
}) => {
  const [mode, setMode] = useState<TextFileInputMode>('text');

  const formGroup = useMemo<TextFileInputForm>(() => {
    return form || new FormGroup({
      mode: new FormControl('text'),
      text: new FormControl('', [Validators.required()]),
      file: new FormControl(null, [Validators.required()]),
    });
  }, [form]);

  useEffect(() => {
    onInit(formGroup);
  }, [formGroup]);

  const onUpdate = useCallback(() => {
    if (setTouched) setTouched(initialForm, formGroup.getFormData())
    if (onChange && formGroup.validate()) {
      onChange(formGroup.getFormData());
    }
  }, [formGroup, onChange]);

  useEffect(() => {
    if (value) {
      formGroup.patch(value);
    }
  }, [formGroup, value]);

  useEffect(() => {
    setMode(formGroup.controls.mode.value);
    if (hidetts) {
      setMode('file')
    }
  }, [formGroup.controls.mode.value]);

  useEffect(() => {
    if (formGroup) {
      if (disabled) {
        formGroup.disable(true);
      } else {
        formGroup.controls.text.disable(mode !== 'text');
        formGroup.controls.file.disable(mode !== 'file');
      }
    }
  }, [formGroup, disabled, mode]);

  const onTabChange = (tab) => {
    setMode(tab);
    formGroup.controls.mode.patch(tab);
  };

  return (
    <div className={className}>
      <Tabs
        className={disabled ? 'disabled' : ''}
        value={(!hidetts) ? mode : "file"}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={(_, value) => onTabChange(value)}
      >
        {!hidetts && <Tab label="Text to speech" value="text" disabled={disabled} />}
        <Tab label="Audio file" value="file" disabled={disabled} />
      </Tabs>
      <div className="px-6 py-4">
        {mode === 'text' ? (
          !hidetts &&
          <Input
            key={`text-input-${formGroup.controls.text.value}`}
            control={formGroup.controls.text}
            type="textarea"
            fullWidth
            size="sm"
            minRows={4}
            placeholder="This text will be played in the beginning of the call"
            onBlur={onUpdate}
          />
        ) : (
          <Input
            key={`file-input-${formGroup.controls.file.value}`}
            control={formGroup.controls.file}
            type="file"
            fullWidth
            maxFiles={1}
            accept={fileAccept}
            selectFromAudioList
            audioType={audioType}
            onUpload={onUploadFile}
            onRemove={onRemoveFile}
            onChange={onUpdate}
          />
        )}
      </div>
    </div>
  );
};
