import React, {FC, useState} from "react";
import {Button, Modal} from "../../../../components";
import {TrashIcon} from "../../../../components/icons";
import ApiTokenForm, {IApiTokenFormInitParams} from "../ApiTokenForm";
import {ApiTokenModel} from "../../../../utils/types";
import {ApiTokenService, MessageBoxService, ToastService} from "../../../../services";
import { ConfirmationModal } from "../../../../parts";

interface IApiTokenEditModalProps {
  apiToken: ApiTokenModel;
  onClose: (data?: any) => void;
}

const ApiTokenEditModal: FC<IApiTokenEditModalProps> = ({
  apiToken, onClose,
}) => {
  const [editForm, setEditForm] = useState<IApiTokenFormInitParams>({
    getFormData: () => null,
  });
  const [touched, setTouched] = useState<boolean>(false)
  const [permissionsTouched, setPermissionsTouched] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      ApiTokenService.update(apiToken.org_uuid, apiToken.uuid, {
        ...data,
        org_uuid: apiToken.org_uuid,
      }).then((data) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onClose(data);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Updating api token failed');
      });
    }
  };

  const close = (value?: any) => {
    if (value) {
      onClose()
    } else {
      setShowConfirmation(false)
    }

    if ((touched && value === undefined) || (permissionsTouched && value === undefined)) {
      setShowConfirmation(true)
      return;
    }
    if ((value === undefined && !touched) || (value === undefined && !permissionsTouched)) {
      onClose()
    }
  }

  const onDeleteToken = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">API TOKEN</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        ApiTokenService.delete(apiToken.org_uuid, apiToken.uuid).then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose(null);
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting api token failed');
        });
      }
    });
  };

  return (
    <Modal
      title="Edit API Token"
      footerComponent={(
        <>
          <Button
            data-cy="delete-token"
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDeleteToken}
          >
            Delete Token
          </Button>
          <Button
            data-cy="save-api-token"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={close}
    >
      <ApiTokenForm
      apiToken={apiToken}
      onInit={setEditForm}
      setTouched={setTouched}
      setPermissionsTouched={setPermissionsTouched}
      onClose={() => onClose()} />
      {
        showConfirmation &&
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={close}
        />
      }
    </Modal>
  );
};

export default ApiTokenEditModal;
