import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import AccountView from "../AccountView";
import {CloseCircleIcon} from "../../../../components/icons";
import {WhatsappAccountModel} from "../../../../utils/types";

const AccountViewPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [account, setAccount] = useState<WhatsappAccountModel>();

  useEffect(() => {
    if (!id) {
      return;
    }

    setAccount(
      new WhatsappAccountModel({ uuid: id, name: 'The cool Business', phone_number: '27712348765', business_website: 'www.thecoolbusines.com', business_email: 'thecoolbusiness@gmail.com', business_address: 'This is the address', fb_business_manager_id: '552577341567091', chat_intro_count: 332, description: 'This is a description', is_active: true, created_at: '2021-04-13T03:12:56.543Z' })
    );
  }, [id]);

  const onClose = () => {
    history.goBack();
  };

  return (
    <div className="fixed top-0 left-0 z-100 w-screen h-screen flex flex-col">
      <div className="flex items-center bg-blue-dark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Account details - {account?.display_name || ''}
        </div>
      </div>
      <div className="flex-1 bg-gray-f6 px-6 py-3 lg:px-9 lg:py-5 overflow auto">
        {account ? (
          <AccountView className="max-w-253" account={account} />
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <div className="absolute top-4 right-5 cursor-pointer" onClick={onClose}>
        <CloseCircleIcon color="white" size={32} />
      </div>
    </div>
  );
};

export default AccountViewPage;
