import classnames from "classnames";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { IDataTableRef } from "../../../components";
import { TextField } from "../../../components/TextField";
import { PlusIcon } from "../../../components/icons";
import { SearchIcon } from "../../../components/icons/Search";
import { WACampaignsModel } from "../../../utils/types";
import { CampaignsCreateModal } from "./CampaignsCreateModal";
import { CampaignsDetailModal } from "./CampaignsDetailModal";
import { CampaignsImportModal } from "./CampaignsImportModal";
import { CampaignsInfoModal } from "./CampaignsInfoModal";
import { CampaignsSummaryModal } from "./CampaignsSummaryModal";
import { CampaignsTable } from "./CampaignsTable";
import { CampaignsSendModal } from "./CampaignSendModal";
import { CampaignNotFound } from "./CampaignNotFound";

export const Campaigns = () => {
    //search bar state
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        search: '',
    });

    //campaign state
    const [campaign, setCampaign] = useState<WACampaignsModel>();

    //modal state
    const [firstCreate, setFirstCreate] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
    const [showSendModal, setShowSendModal] = useState<boolean>(false);

    //datatable ref
    const [tableRef, setTableRef] = useState<IDataTableRef>();

    const debouncedSetFilter = useCallback(
        debounce((filter) => setFilter(filter), 500),
        []
    );

    const onSearch = value => {
        setSearch(value);
        debouncedSetFilter({ search: value });
    };

    const refreshTable = useCallback(() => {
        if (tableRef) {
            tableRef.refresh();
        }
    }, [tableRef]);

    const onFirstCampaignCreate = () => {
        setShowInfoModal(false);
        setShowCreateModal(true);
    }

    const resolveFirstCreate = (callback: Function) => {
        setCampaign(null)
        callback(true)
    }

    //TODO look into making a switch here
    const onCampaignCreate = (camp: any) => {
        if (camp) {
            setCampaign(camp);
            setShowDetailsModal(true);
            refreshTable();
        }
        setShowCreateModal(false);
    }

    const onNameEdit = (camp: any) => {
        if (camp) {
            setCampaign(camp);
            setShowDetailsModal(true);
        }
        setShowCreateModal(true);
    }

    const onCampaignEdit = (camp: any) => {
        if (camp) {
            setCampaign(camp);
            setShowDetailsModal(true);
        }
    }

    const onCampaignView = (camp: any) => {
        if (camp) {
            setCampaign(camp);
            setShowSummaryModal(true);
        }
    }

    const onCampaignSave = (camp: any) => {
        if (camp) {
            setCampaign(camp)
            refreshTable();
        }
    }

    const onCampaignSend = (camp: any) => {
        if (camp) {
            setCampaign(camp);
        }
        setShowSendModal(true);
        setShowDetailsModal(false);
    }

    const onCampaignSendCompletion = (camp: any) => {
        setShowSendModal(false);
        if (camp) {
            refreshTable();
        }
    }

    const onCampaignListCreate = (camp: any) => {
        if (camp) {
            setCampaign(camp);
        }
    }

    return (
        <div className="h-full flex flex-col px-4 pt-4 bg-[#F8F8F8]">
            {firstCreate && (
                <CampaignNotFound
                    onCreate={() => setShowInfoModal(true)}
                />
            )}

            {!firstCreate && (
                <>
                    <div className="flex justify-between items-center">
                        <TextField
                            data-cy="wa-campaigns-search"
                            size="sm"
                            fullWidth
                            className="!rounded-full !bg-white shadow-md"
                            containerClass="w-80 ml-auto mr-4"
                            value={search}
                            icon={<SearchIcon size={16} color="primary" />}
                            placeholder="Search Campaigns"
                            clearable
                            onChange={(value) => onSearch(value)} />
                    </div>
                    <CampaignsTable
                        filter={filter}
                        className="h-0 flex-grow p-4"
                        onEdit={(campaign) => onCampaignEdit(campaign)}
                        onView={(campaign) => onCampaignView(campaign)}
                        firstCreate={(result: boolean) => setFirstCreate(result)}
                        setTableRef={setTableRef} />
                    <div
                        data-cy="wa-campaigns-create-button"
                        className={classnames(
                            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-60 h-13.5 flex items-center overflow-hidden px-3',
                            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer'
                        )}
                        onClick={() => firstCreate ? resolveFirstCreate(setShowInfoModal) : resolveFirstCreate(setShowCreateModal)}
                    >
                        <PlusIcon color="white" size={30} />
                        <span className="ml-4">Create Campaign</span>
                    </div>
                </>
            )}

            {showInfoModal &&
                <CampaignsInfoModal
                    onClose={onFirstCampaignCreate}
                />
            }

            {showCreateModal &&
                <CampaignsCreateModal
                    campaign={campaign}
                    onClose={(camp) => onCampaignCreate(camp)}
                />
            }

            {showDetailsModal &&
                <CampaignsDetailModal
                    campaign={campaign}
                    onNameEdit={(camp) => onNameEdit(camp)}
                    onImport={() => setShowImportModal(true)}
                    onClose={() => setShowDetailsModal(false)}
                    onCampaignSave={(camp) => onCampaignSave(camp)}
                    onCampaignSend={(camp) => onCampaignSend(camp)}
                />
            }

            {showSummaryModal &&
                <CampaignsSummaryModal
                    campaign={campaign}
                    onClose={() => setShowSummaryModal(null)}
                />
            }

            {showImportModal &&
                <CampaignsImportModal
                    campaign={campaign}
                    onListCreate={(camp) => onCampaignListCreate(camp)}
                    onClose={() => setShowImportModal(null)}
                />
            }

            {showSendModal &&
                <CampaignsSendModal
                    campaign={campaign}
                    onClose={(camp?: any) => onCampaignSendCompletion(camp)}
                />
            }
        </div>
    );
}