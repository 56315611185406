import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import Projects from "./index";
import ProjectCreate from "./ProjectCreate";
import ProjectEdit from "./ProjectEdit";

const ProjectsRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.PROJECTS.INDEX} component={Projects} />
    <Route exact path={ROUTES.SETTINGS.PROJECTS.CREATE} component={ProjectCreate} />
    <Route exact path={ROUTES.SETTINGS.PROJECTS.EDIT} component={ProjectEdit} />
  </Switch>
);

export default ProjectsRouting;
