import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WAOnboardingStep2: FC<IIconProps> = (props) => (
  <Icon baseWidth={27.615} baseHeight={27.448} {...props}>
    {(width, height, color = "#307cd8") => (
      <svg width={width} height={height} viewBox="0 0 27.615 27.448">
        <path
          id="Icon_awesome-facebook"
          data-name="Icon awesome-facebook"
          d="M28.178,14.37A13.808,13.808,0,1,0,12.213,28.01V18.361H8.705V14.37h3.508V11.328c0-3.46,2.06-5.372,5.215-5.372a21.249,21.249,0,0,1,3.091.269v3.4H18.777a2,2,0,0,0-2.25,2.156V14.37h3.829l-.612,3.991H16.527V28.01A13.812,13.812,0,0,0,28.178,14.37Z"
          transform="translate(-0.563 -0.563)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
