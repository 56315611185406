import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const DocumentsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={44} baseHeight={44} {...props}>
    {(width, height, color, color2) => (
      <svg width={width} height={height} viewBox="0 0 44 44">
        <path fill={color}
              d="M43.262,7.786l-5.15-4.911a1.045,1.045,0,0,0-.777-.382H16.707a1.094,1.094,0,0,0-1.093,1.093V6.941L8.344,8.187a1.077,1.077,0,0,0-.874,1.242l.369,2.151c-2.363.8-4.789,1.615-7.132,2.456a1.082,1.082,0,0,0-.646,1.374L4.721,28.363a.639.639,0,0,0,1.2-.433l-4.59-12.76c2.207-.79,4.492-1.563,6.725-2.315l2.2,12.8L12.78,40.4a1.082,1.082,0,0,0,1.242.876L20.865,40.1l5.347-.917L18.17,42.074l-6.342,2.28L6.9,30.655a.639.639,0,0,0-1.2.432L10.7,44.981a1.079,1.079,0,0,0,1.011.709,1.057,1.057,0,0,0,.363-.064L18.6,43.277l14.832-5.331,2.834-.486h6.26a1.1,1.1,0,0,0,1.093-1.1V8.506a.919.919,0,0,0-.359-.72Zm-1.693.151H38.292c-.153,0-.512,0-.6-.087a.36.36,0,0,1-.045-.22l-.007-3.443Zm.774,28.244H16.893V23.728a.639.639,0,1,0-1.278,0V36.364a1.1,1.1,0,0,0,1.093,1.1h12L20.649,38.84l-6.644,1.14L11.514,25.44,8.764,9.412l6.85-1.174V20.764a.639.639,0,1,0,1.278,0V3.771H36.358l.008,3.862a1.522,1.522,0,0,0,.423,1.125,1.954,1.954,0,0,0,1.42.458h4.134Z"
              transform="translate(0 -2.493)"/>
        <path fill={color2 || color}
              d="M234.43,112.088a.639.639,0,0,0,.639.639h18.27a.639.639,0,1,0,0-1.278h-18.27A.639.639,0,0,0,234.43,112.088Z"
              transform="translate(-214.457 -102.166)"/>
        <path fill={color2 || color} d="M253.339,164.731h-18.27a.639.639,0,0,0,0,1.278h18.27a.639.639,0,1,0,0-1.278Z"
              transform="translate(-214.457 -150.909)"/>
        <path fill={color2 || color} d="M253.339,218.013h-18.27a.639.639,0,0,0,0,1.278h18.27a.639.639,0,0,0,0-1.278Z"
              transform="translate(-214.457 -199.651)"/>
        <path fill={color2 || color} d="M253.339,271.3h-18.27a.639.639,0,0,0,0,1.278h18.27a.639.639,0,1,0,0-1.278Z"
              transform="translate(-214.457 -248.394)"/>
        <path fill={color2 || color} d="M253.339,324.578h-18.27a.639.639,0,0,0,0,1.278h18.27a.639.639,0,1,0,0-1.278Z"
              transform="translate(-214.457 -297.137)"/>
      </svg>
    )}
  </Icon>
);
