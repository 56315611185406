import { formatPaginatedResult } from "../utils/helpers";
import { GA4Model } from "../utils/types/GA4.model";
import { HttpService } from "./http.service";
export class GA4Service {
    static search(query = {}, showSpinner = true) {
        return HttpService.get('/integrations/', query, {}, showSpinner)
            .then((res) => formatPaginatedResult(GA4Model, res));
    }

    static find(name: string, showSpinner = true) {
        return HttpService.get(`/integrations/${name}/`, {}, {}, showSpinner)
            .then((res) => new GA4Model(res));
    }

    static create(data, showSpinner = true) {
        return HttpService.post(`/integrations/`, data, {}, showSpinner)
            .then((res) => new GA4Model(res));
    }

    // TODO create modals for data being passed in
    static update(name: string, data, showSpinner = true) {
        return HttpService.put(`/integrations/${name}/`, data, {}, showSpinner)
            .then((res) => new GA4Model(res));
    }

    static patch(name: string, data, showSpinner = true) {
        return HttpService.patch(`/integrations/${name}/`, data, {}, showSpinner)
            .then((res) => new GA4Model(res));
    }

    static delete(name: string, showSpinner = true) {
        return HttpService.delete(`/integrations/${name}/`, {}, {}, showSpinner);
    }
}