import React, {FC} from "react";
import {Button} from "../../../../../components";
import {ArrowLeftIcon} from "../../../../../components/icons";
import {WhatsAppStep5Svg} from "../../../../../components/svgs";

export interface IFollowStep5Props {
  onBack(): void;
  onNext(): void;
}

const FollowStep5: FC<IFollowStep5Props> = ({
  onBack, onNext,
}) => {
  return (
    <div className="h-full flex flex-col text-center px-8 pt-8">
      <h6 className="font-semibold">To set up your number for WhatsApp</h6>
      <p className="text-primary text-3xl font-bold">Follow all the steps below</p>
      <div className="max-w-160 mx-auto mt-16 mb-6">
        <WhatsAppStep5Svg size={520} className="mx-auto" />
        <p className="font-semibold mt-12">
          After listening to the recording in your calls list and entering your OTP, you have to manually disable the OTP Mode.
        </p>
        <p className="font-semibold mt-3">
          To do this you have to go to your number section and select your number from the list.
          Scroll down to the "Features" card and disable the toggle.
        </p>
      </div>
      <div className="flex mt-auto mb-6">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>
          Continue to Calls screen
        </Button>
      </div>
    </div>
  );
};

export default FollowStep5;
