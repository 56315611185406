import { TEMPLATE_FILE_TYPES } from "../enums";

export class WATemplatesFileModel {
  uuid: string;
  caption: string;
  created: string;
  full_url: string;
  modified: string;
  type: TEMPLATE_FILE_TYPES;
  whatsapp_number: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      caption: '',
      created: '',
      full_url: '',
      modified: '',
      type: null,
      whatsapp_number: '',
      ...init,
    };

    Object.assign(this, data);
  }
}