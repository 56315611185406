import React from "react";
import {useHistory} from "react-router";
import NumberCreate from "../../Numbers/NumberCreate";
import {ROUTES} from "../../../constants";

const CreateNumber = () => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const onFinish = () => {
    history.replace(ROUTES.CALLS.INDEX);
  };

  return (
    <div className="w-screen h-screen flex-center bg-gradient-to-r from-blue-dark to-[#22599C]">
      <div className="w-250 max-w-[95vw] max-h-[95vh] overflow-auto">
        <NumberCreate
          className="rounded-md"
          addBillingInfo
          onClose={onBack}
          onCreate={onFinish}
        />
      </div>
    </div>
  );
};

export default CreateNumber;
