import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, FormControl, Input, Validators, useForm } from '../../../../../components/ReactiveForm'
import { GA4Model } from '../../../../../utils/types/GA4.model';
import { Switch } from '@mui/material';
import _ from 'lodash';

export interface IGA4FormProps {
  ga4?: GA4Model;
  onInit: any;
  setTouched?: any;
}

const GA4Form: FC<IGA4FormProps> = ({
  ga4,
  onInit,
  setTouched,
}) => {

  const [form, formData] = useForm<{
    name: FormControl;
    api_secret: FormControl;
    measurement_id: FormControl;
    is_active: FormControl;
    third_party: FormControl;
    event: FormControl
  }>({
    name: new FormControl('', [Validators.required()]),
    api_secret: new FormControl('', [Validators.required()]),
    measurement_id: new FormControl('', [Validators.required()]),
    event: new FormControl('call',),
    is_active: new FormControl(true),
    third_party: new FormControl('GoogleAnalytics4'),
  });

  const [initialForm, setInitialForm] = useState(form.getFormData())

  let isTheSame = _.isEqual(initialForm, form.getFormData())

  setTouched(!isTheSame)

  const toggleActiveState = () => {
    form.patch({
      is_active: !formData.is_active
    })
  }


  useEffect(() => {
    if (ga4) {
      form.patch({
        name: ga4.name,
        api_secret: ga4.auth_credentials.api_secret,
        measurement_id: ga4.auth_credentials.measurement_id,
        event: ga4.event,
        is_active: ga4.is_active,
        third_party: ga4.third_party
      });

      setInitialForm({
        name: ga4.name,
        api_secret: ga4.auth_credentials.api_secret,
        measurement_id: ga4.auth_credentials.measurement_id,
        event: ga4.event,
        is_active: ga4.is_active,
        third_party: ga4.third_party
      })
      // form.controls.name.disable();
    }
  }, [ga4]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <Form formGroup={form}>
      <Input
        control={form.controls.name}
        fullWidth
        label={'Google Analytics Property Name'}
        labelClass="text-md font-semibold"
        placeholder={'Property Name'}
      />

      <Input
        control={form.controls.measurement_id}
        fullWidth
        label={'Measurement ID'}
        labelClass="text-md font-semibold"
        placeholder={'Copy your measurement ID from google analytics and paste it here'}
        helperText={<p>
          Not sure how to get your Measurement ID? <span className='font-semibold text-[#F6A734]'> We can help</span>
        </p>}
      />
      <Input
        control={form.controls.api_secret}
        fullWidth
        label={'API Secret'}
        labelClass="text-md font-semibold mt-5"
        placeholder={'Paste the API secrets for Measurement Protocol here'}
        helperText={<p className=''>
          Not sure how to get your api secret? <span className='font-semibold text-[#F6A734]'> We can help</span>
        </p>}
      />


      <div className='flex flex-col'>
        <div className='text-md font-semibold my-5'>Event Type</div>
        <div className='flex flex-row justify-stretch'>
          <div className='flex-1'>
            Call
          </div>

          <div className='flex-1'>
            <Switch
              value={formData.is_active}
              checked={formData.is_active}
              onChange={() => toggleActiveState()}
            />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default GA4Form
