import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import ProjectForm, {IProjectFormInitParams} from "../ProjectForm";
import {Button} from "../../../../components";
import {CloseCircleIcon, TrashIcon} from "../../../../components/icons";
import {ROUTES} from "../../../../constants";
import {getActiveOrganization} from "../../../../redux/selectors";
import {
  AuthService,
  MessageBoxService,
  ProjectService,
  RelationshipService,
  ToastService,
  UserService,
} from '../../../../services';
import { ProjectModel, UserModel } from '../../../../utils/types';
import { ROLE } from '../../../../utils/enums';
import { setOrganizations } from '../../../../redux/actions';


function isEqualUser(a: UserModel, b: UserModel){
  return a.uuid === b.uuid
}

function userIn(list: UserModel[], u: UserModel){
  for(var x of list) if(isEqualUser(x, u)) return true
  return false
}

const ProjectEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useSelector(getActiveOrganization);
  const { id } = useParams<{ id: string }>();
  const [existingUserIds, setExistingUserIds] = useState<{ [rel_uuid: string]: string }>({});
  const [project, setProject] = useState<ProjectModel>();
  const [users, setUsers] = useState<{ user: UserModel, roles: ROLE[] }[]>([]);
  const [createForm, setCreateForm] = useState<IProjectFormInitParams>({
    getFormData: () => null,
  });

  const onClose = useCallback(() => {
    history.replace(ROUTES.SETTINGS.PROJECTS.INDEX);
  }, []);

  // Get Project info
  useEffect(() => {
    if (organization) {
      if(organization.uuid === id){ setProject(organization); return }

      ProjectService.find(organization.uuid, id).then((data) => {
        setProject(data);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Loading project failed');
        onClose();
      });
    }
  }, [organization, id])

  // Get Projects user list
  useEffect(() => {
    Promise.all([
      UserService.search(id).catch((err) => {
        ToastService.showHttpError(err, 'Loading users of project failed');
        throw err;
      }),
      UserService.search(organization.uuid).catch((err) => {
        ToastService.showHttpError(err, 'Loading users of Org failed');
        throw err;
      }),
    ]).then(([sub_org_users, org_users]) => {
      for(var u of org_users){
        u.relationship.is_owner = false
      }
      const all_users = [
        ...sub_org_users.map((user) => ({ user, roles: user.relationship.roles })),
        ...org_users.filter(user => !userIn(sub_org_users, user)).map((user) => ({ user, roles: [] }))
      ]
      setUsers(all_users)
    })
  }, [id]);

  const fetchOrganizations = () => {
    AuthService.getMyOrganizations().then((data) => {
      dispatch(setOrganizations(data));
    });
  };

  const onSave = () => {
    const data = createForm.getFormData();
    if (data) {
      let relationships:any = []
      users.forEach(({ user, roles }, i) => {
        relationships[i] = { uuid: user.uuid, roles }
      })
      relationships = relationships.filter(({ user, roles }) => roles.length)

      ProjectService.update(
        organization.uuid,
        project.uuid,
        { ...data, relationships }
      ).then((data) => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onClose();
      }).catch((err) => {
        ToastService.showHttpError(err, 'Updating project failed');
      });
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete this <span className="font-semibold">{project.name}</span>?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        ProjectService.delete(organization.uuid, project.uuid).then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> deleted.</>);
          onClose();
        }).catch((err) => {
          ToastService.showHttpError(err, 'Deleting project failed');
        });
      }
    });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Edit Project</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="h-0 flex-grow overflow-auto px-6">
        <div className="w-172 max-w-full mx-auto my-6">
          {project && (
            <ProjectForm
              project={project}
              users={users}
              setUsers={setUsers}
              onInit={setCreateForm}
              onClose={onClose}
            />
          )}
        </div>
      </div>
      <div className="flex items-center px-7 py-3">
        <Button
          data-cy="delete-project"
          className="text-sm rounded-full mr-4"
          color="danger"
          variant="outline"
          leftIcon={<TrashIcon size={12} />}
          onClick={onDelete}
        >
          Delete Project
        </Button>
        <Button
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProjectEdit;
