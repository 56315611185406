import React, {FC, useState} from "react";
import {Button, Modal} from "../../../../components";
import BlockedNumberForm, {IBlockedNumberFormInitParams} from "../BlockedNumberForm";
import {MessageBoxService, ToastService} from "../../../../services";
import {BlockedNumberModel} from "../../../../utils/types";
import {TrashIcon} from "../../../../components/icons";

interface IBlockedNumberEditModalProps {
  blockedNumber: BlockedNumberModel;
  onEdited: () => void;
  onDeleted: () => void;
  onClose: () => void;
}

const BlockedNumberEditModal: FC<IBlockedNumberEditModalProps> = ({
  blockedNumber, onEdited, onDeleted, onClose,
}) => {
  const [editForm, setEditForm] = useState<IBlockedNumberFormInitParams>({
    getFormData: () => null,
  });

  const onSave = () => {
    const data = editForm.getFormData();
    if (data) {
      Object.assign(blockedNumber, data)
      blockedNumber.edit().then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
        onEdited();
      }).catch(err => {
        ToastService.showHttpError(err, "Edit failed.");
      })
    }
  };

  const onDelete = () => {
    MessageBoxService.confirm({
      type: 'passcode',
      title: 'Delete confirmation',
      message: <>Are you sure you want to remove this number from spam?</>,
      passcode: 'Delete',
    }).then((result) => {
      if (result) {
        blockedNumber.delete().then(() => {
          ToastService.success(<><span className="text-success mr-1">Successfully</span> removed from spam.</>);
          onDeleted();
        }).catch(err => {
          ToastService.showHttpError(err, "Delete failed.");
        })
      }
    });
  };

  return (
    <Modal
      title="Edit Blocked Number"
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Remove from spam
          </Button>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedNumberForm blockedNumber={blockedNumber} onChange={setEditForm} onClose={onClose} />
    </Modal>
  );
};

export default BlockedNumberEditModal;
