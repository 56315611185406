import React, { FC, useCallback, useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { INumberFeaturesForm } from "../index";
import { NumberModel } from "../../../../utils/types";
import { FormControl, FormGroup, Validators } from "../../../../components/ReactiveForm";
import { Card, WithTooltipIcon } from "../../../../components";
import { TextFileInput, TextFileInputForm } from "../../../../parts";
import { AudioService, MessageBoxService } from "../../../../services";
import { AUDIO_TYPE, NUMBER_FEATURE } from "../../../../utils/enums";

export interface IFeaturesProps {
  number: NumberModel;
  form: FormGroup<INumberFeaturesForm>;
  formData: any;
  initialForm: any;
  setTouched?: any;
  onUpdate(data: any): void;
}

const Features: FC<IFeaturesProps> = ({
  number,
  form,
  initialForm,
  setTouched,
  onUpdate,
}) => {
  const [features, setFeatures] = useState({
    blockAnonymous: false,
    otpMode: false,
    parkNumber: false,
  });

  useEffect(() => {
    setFeatures({
      blockAnonymous: number.features.includes(NUMBER_FEATURE.BLOCK_ANONYMOUS_CALLERS),
      otpMode: number.features.includes(NUMBER_FEATURE.VOICE_OTP),
      parkNumber: number.isParked(),
    });
    initialForm.features = {
      blockAnonymous: number.features.includes(NUMBER_FEATURE.BLOCK_ANONYMOUS_CALLERS),
      otpMode: number.features.includes(NUMBER_FEATURE.VOICE_OTP),
      parkNumber: number.isParked(),
    };

    if (number.isParked()) {
      const parkNumberForm = new FormGroup({
        mode: new FormControl(number.features.includes(NUMBER_FEATURE.KEEP_NUMBER_FILE) ? 'file' : 'text'),
        text: new FormControl(number.tts, [Validators.required()]),
        file: new FormControl(number.keep_number_file, [Validators.required()]),
      });
      form.setControl('parkNumberForm', parkNumberForm);
      initialForm.features.parkNumberForm = {
        mode: number.features.includes(NUMBER_FEATURE.KEEP_NUMBER_FILE) ? 'file' : 'text',
        text: number.tts,
        file: number.keep_number_file,
      }
    }
  }, [number]);

  useEffect(() => {
    form.patch(features);
  }, [features]);

  const onUploadKeepNumberFile = useCallback(async (file: File | string): Promise<any> => {
    if (typeof file === 'string') {
      return file;
    } else {
      return await AudioService
        .upload({
          name: `${AUDIO_TYPE.KEEP_NUMBER}_${number.number}_${Date.now()}`,
          type: AUDIO_TYPE.KEEP_NUMBER,
          file,
        }, false)
        .then((audio) => audio.name);
    }
  }, []);

  const onRemoveKeepNumberFile = useCallback((fileName) => {
    return AudioService.delete(fileName, AUDIO_TYPE.KEEP_NUMBER, false);
  }, []);

  const onFeatureChange = (field: string, value: any) => {
    if (field !== 'parkNumber' || !value) {
      form.removeControl('parkNumberForm');
    }

    if (field === 'otpMode') {
      if (value) {
        MessageBoxService.confirm({
          title: 'Activate OTP Mode',
          message: 'Are you sure you want to activate OTP mode?',
          confirmMessage: <>This means <b>no calls or messages</b> will be forwarded to the target number and will automatically end up as a <b>voice recording in your call logs</b>. This should only be used when you are trying to receive an OTP for online applications setup.</>,
        }).then((result) => {
          if (result) {
            updateFeatures({
              blockAnonymous: false,
              otpMode: true,
              parkNumber: false,
            });
          }
        });
      } else {
        MessageBoxService.confirm({
          title: 'Disable OTP Mode',
          message: 'Are you sure you want to disable OTP mode?',
          confirmMessage: 'By disabling the OTP mode, you will no longer receiver a voice recording with your one-time-pin. All calls and messages will be forwarded to your target number(s).',
        }).then((result) => {
          if (result) {
            updateFeatures({
              ...features,
              otpMode: false,
            });
          }
        });
      }
    } else if (field === 'parkNumber') {
      if (value) {
        MessageBoxService.confirm({
          type: 'textFile',
          audioType: AUDIO_TYPE.KEEP_NUMBER,
          title: 'Park Number',
          confirmMessage: <>This feature allows you to keep a number <b>without requiring a target number</b>. It is used in combination with the TTS (text-to-speech) or playing an audio message parameter to produce the message a caller hears when they call this number.</>,
        }).then(async (result?: TextFileInputForm) => {
          if (result) {
            if (result.controls.mode.value === 'file') {
              const fileName = await onUploadKeepNumberFile(result.controls.file.value);
              result.controls.file.patch(fileName);
            } else {
              result.controls.file.patch(null);
            }
            form.setControl('parkNumberForm', result);
            updateFeatures({
              blockAnonymous: false,
              otpMode: false,
              parkNumber: true,
            });
          }
        });
      } else {
        MessageBoxService.confirm({
          title: 'Unpark Number',
          confirmMessage: <>Unparking this number will <b>allow it to have target numbers</b> and <b>receive calls</b> between the caller and receiver.</>,
        }).then((result) => {
          if (result) {
            updateFeatures({
              ...features,
              parkNumber: false,
            });
          }
        });
      }
    } else {
      updateFeatures({
        ...features,
        [field]: value,
      });
    }
  };

  const updateFeatures = (features) => {
    setFeatures(features);
    form.patch(features);
    onSave().then();
  };

  const onSave = async () => {
    if (!form.validate()) {
      setTouched(true)
      return;
    }

    const formData = form.getFormData();
    const data: any = {
    };

    const validFeatures = [
      NUMBER_FEATURE.VOICE_OTP,
      NUMBER_FEATURE.KEEP_NUMBER_TTS,
      NUMBER_FEATURE.KEEP_NUMBER_FILE,
      NUMBER_FEATURE.BLOCK_ANONYMOUS_CALLERS
    ];
    let features = number.features.filter((item) => !validFeatures.includes(item));

    if (formData.otpMode) {
      features = [NUMBER_FEATURE.VOICE_OTP];
    } else if (formData.parkNumber) {
      if (formData.parkNumberForm.mode === 'text') {
        features = [NUMBER_FEATURE.KEEP_NUMBER_TTS];
        data.tts = formData.parkNumberForm.text;
        // data.keep_number_file = await uploadTTS(data.tts, AUDIO_TYPE.KEEP_NUMBER);
      } else {
        features = [NUMBER_FEATURE.KEEP_NUMBER_FILE];
        data.keep_number_file = formData.parkNumberForm.file;
      }
    } else {
      if (formData.blockAnonymous) {
        features.push(NUMBER_FEATURE.BLOCK_ANONYMOUS_CALLERS);
      }
    }

    data.features = features;

    onUpdate(data);
  };

  return (
    <Card size="sm">
      <h5 className="text-lg font-semibold">Features</h5>
      <div data-cy="feature-content" className="grid sm:grid-cols-2 gap-x-4 gap-y-2 sm:gap-y-0 text-sm mt-4">
        {!features.otpMode && !features.parkNumber && (
          <>
            <WithTooltipIcon tooltip="This will block all calls who does not have a caller ID" placement="right">
              Block anonymous Callers
            </WithTooltipIcon>
            <Switch
              data-cy="block-anonymous-callers"
              checked={features.blockAnonymous}
              onChange={(_, checked) => onFeatureChange('blockAnonymous', checked)}
            />
          </>
        )}

        {!features.parkNumber && (
          <>
            <WithTooltipIcon tooltip="Activating your OTP Mode will result in no calls or messages will be forwarded to the target number and will automatically end up as a voice recording in your call logs. This should only be used when you are trying to receive an OTP for online applications setup" placement="right">
              OTP Mode
            </WithTooltipIcon>
            <Switch
              data-cy="otp-mode"
              checked={features.otpMode}
              onChange={(_, checked) => onFeatureChange('otpMode', checked)}
            />
          </>
        )}

        {!features.otpMode && (
          <>
            <WithTooltipIcon tooltip="This feature allows you to keep a number without requiring a target number. It is used in combination with the TTS (text-to-speech) or playing an audio message parameter to produce the message a caller hears when they call this number." placement="right">
              Park Number
            </WithTooltipIcon>
            <Switch checked={features.parkNumber} onChange={(_, checked) => onFeatureChange('parkNumber', checked)} />
          </>
        )}
      </div>

      {features.parkNumber && (
        <TextFileInput
          form={form.controls.parkNumberForm}
          audioType={AUDIO_TYPE.KEEP_NUMBER}
          onUploadFile={onUploadKeepNumberFile}
          onChange={onSave}
        />
      )}
    </Card>
  );
};

export default Features;
