import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const WhatsAppContactsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={32.005} baseHeight={22.403} {...props}>
    {(width, height, color = '#2680eb') => (
      <svg width={width} height={height} viewBox="0 0 32.005 22.403">
        <path
          d="M9.6,13.452A5.6,5.6,0,1,0,4,7.851,5.6,5.6,0,0,0,9.6,13.452Zm3.841,1.6h-.415a7.733,7.733,0,0,1-6.851,0H5.761A5.762,5.762,0,0,0,0,20.813v1.44a2.4,2.4,0,0,0,2.4,2.4H16.8a2.4,2.4,0,0,0,2.4-2.4v-1.44A5.762,5.762,0,0,0,13.442,15.052ZM24,13.452a4.8,4.8,0,1,0-4.8-4.8A4.8,4.8,0,0,0,24,13.452Zm2.4,1.6h-.19a6.306,6.306,0,0,1-4.421,0H21.6a5.554,5.554,0,0,0-2.785.77A7.318,7.318,0,0,1,20.8,20.813v1.92c0,.11-.025.215-.03.32H29.6a2.4,2.4,0,0,0,2.4-2.4,5.6,5.6,0,0,0-5.6-5.6Z"
          transform="translate(0 -2.25)"
          fill={color}
        />
      </svg>
    )}
  </Icon>
);
