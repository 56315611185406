import { WACampaignsService } from "../../services";

export class WACampaignsLogModel {
  uuid: string;
  contact_count: number;
  contact_count_subscribed: number;
  contact_count_unsubscribed: number;
  messages_sent_count: number;
  messages_failed_count: number;
  messages_delivered_count: number;
  created: string;
  modified: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      message_data: {
        whatsapp_id: '',
        template_name: '',
        campaign_list_uuid: '',
      },
      contact_count: 0,
      contact_count_subscribed: 0,
      messages_sent_count: 0,
      messages_failed_count: 0,
      messages_delivered_count: 0,
      opted_out_count: 0,
      campaign_message_data: {
        whatsapp_id: '',
        template_name: '',
        campaign_list_uuid: '',
      },
      created: '',
      modified: '',
      ...init,
    };

    Object.assign(this, data);
  }

  static search(query: any = {}, ...kwargs) {
    return WACampaignsService.search(query, ...kwargs);
  }
}