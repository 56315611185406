import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const SmsReceiveIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={59.05} baseHeight={58.936} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 59.05 58.936">
        <g fill={color}>
          <path d="M77.2,110.25a2.132,2.132,0,0,1,1.284,2.256c-.044,3.7-.017,7.4-.017,11.1v.688h2.79c.731,0,1.461.017,2.19,0a1.743,1.743,0,0,1,1.756.963,1.763,1.763,0,0,1-.283,1.983c-.972,1.284-1.934,2.576-2.9,3.863Q78.4,135.9,74.792,140.71a1.838,1.838,0,0,1-1.487.846,1.872,1.872,0,0,1-1.579-.891q-4.28-5.707-8.57-11.405c-.507-.674-1.007-1.354-1.52-2.024a1.76,1.76,0,0,1,1.473-2.946c1.631.019,3.262.005,4.98.005v-.63c0-3.72.027-7.439-.017-11.158a2.132,2.132,0,0,1,1.284-2.256Z" transform="translate(-43.752 -110.25)"/>
          <path d="M41,139.326l.572-1.582L59.863,153.9,41.579,170.13,41,168.563Z" transform="translate(-41 -114.003)"/>
          <path d="M106.4,168.564l-.57,1.574L87.539,153.9l18.283-16.152.58,1.576Z" transform="translate(-47.353 -114.004)"/>
          <path d="M44.678,175.34l18.274-16.219c1.487,1.32,2.948,2.617,4.407,3.916a5.077,5.077,0,0,0,5.325,1.182,6.826,6.826,0,0,0,2.042-1.238c1.473-1.23,2.881-2.535,4.373-3.861l18.278,16.221-.026.207a15.569,15.569,0,0,1-2.113.292c-7.207.022-14.413.013-21.619.013q-13.375,0-26.75-.013a16.188,16.188,0,0,1-2.167-.29Z" transform="translate(-41.502 -116.921)"/>
          <path d="M97.334,135.031q-2.477,2.193-4.953,4.387-9.919,8.777-19.839,17.554c-1.147,1.016-1.923,1.019-3.058.015q-11.965-10.594-23.935-21.181c-.3-.262-.589-.528-1.03-.925a12.033,12.033,0,0,1,1.936-.351c3.728-.03,7.455-.023,11.182,0a1.009,1.009,0,0,1,.681.373q4.186,5.527,8.341,11.078a5.145,5.145,0,0,0,6.956,1.6,6.641,6.641,0,0,0,1.744-1.635c2.794-3.658,5.549-7.346,8.3-11.035a.879.879,0,0,1,.806-.4c3.612.014,7.225-.007,10.837.022a14.351,14.351,0,0,1,2.009.291Z" transform="translate(-41.48 -113.561)"/>
        </g>
      </svg>
    )}
  </Icon>
);
