import React, {useState} from "react";
import {useHistory} from "react-router";
import {Switch} from "@mui/material";
import {Button, Card, Input} from "../../../../components";
import {CloseCircleIcon} from "../../../../components/icons";
import {FormControl, FormGroup, useForm, Validators} from "../../../../components/ReactiveForm";
import {CreditCardDetail, ICreditCardFormData} from "../../../../parts";
import {ToastService} from "../../../../services";
import {CREDIT_CARD, ROUTES} from "../../../../constants";

export type IBalanceForm = {
  autoBalanceEnabled: FormControl;
  autoBalance: FormGroup<{
    thresholdAmount: FormControl;
    refillAmount: FormControl;
    maxMonthlyRefillAmount: FormControl;
  }>;
  lowBalanceEnabled: FormControl;
  lowBalance: FormGroup<{
    minimumAmount: FormControl;
  }>;
};

const AutoRecharging = () => {
  const history = useHistory();

  const [cardInfo, setCardInfo] = useState<ICreditCardFormData>({
    cardType: CREDIT_CARD.MASTER_CARD,
    cardNumber: '1234123412341234',
    csv: '865',
    expiryDate: '10/2023',
  });

  const [form, formData] = useForm<IBalanceForm>({
    autoBalanceEnabled: new FormControl(true),
    autoBalance: new FormGroup({
      thresholdAmount: new FormControl('', [Validators.required()]),
      refillAmount: new FormControl('', [Validators.required()]),
      maxMonthlyRefillAmount: new FormControl('', [Validators.required()]),
    }),
    lowBalanceEnabled: new FormControl(true),
    lowBalance: new FormGroup({
      minimumAmount: new FormControl('', [Validators.required()]),
    }),
  });

  const onClose = () => {
    history.replace(ROUTES.SETTINGS.BILLING.INDEX);
  };

  const onSave = () => {
    if (form.validate()) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> saved your changes.</>);
      onClose();
    }
  };

  const onEditCard = () => {
    history.push(ROUTES.SETTINGS.BILLING.CARD);
  };

  const onRemoveCard = () => {
    onClose();
  };

  const onToggleAutoBalanceEnable = (enabled) => {
    form.controls.autoBalanceEnabled.patch(enabled);
    form.controls.autoBalance.disable(!enabled);
  };

  const onToggleLowBalanceEnable = (enabled) => {
    form.controls.lowBalanceEnabled.patch(enabled);
    form.controls.lowBalance.disable(!enabled);
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Enable Auto Recharging</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="h-0 flex-grow overflow-auto p-6">
        <Card
          title="Payment Details"
          size="sm"
          headerClass="text-blue-dark text-lg font-semibold !border-0 pb-2"
        >
          <CreditCardDetail data={cardInfo} editable removable onEdit={onEditCard} onRemove={onRemoveCard} />
        </Card>

        <div className="text-lg text-blue-dark font-semibold mt-6 mb-2 ml-2">Low Balance Settings</div>
        <div className="grid md:grid-cols-2 gap-4">
          <Card
            size="sm"
            header={(
              <div className="flex items-center">
                <div className="text-blue-dark text-md font-medium">Auto Balance top up</div>
                <Switch
                  className="ml-auto"
                  checked={formData?.autoBalanceEnabled || false}
                  onChange={(_, checked) => onToggleAutoBalanceEnable(checked)}
                />
              </div>
            )}
          >
            <div className="grid grid-cols-12 gap-3 mt-3">
              <label className="col-span-5 text-right text-sm mt-2">Threshold Amount</label>
              <Input
                control={form.controls.autoBalance.controls.thresholdAmount}
                containerClass="col-span-7"
                fullWidth
                size="sm"
              />
              <label className="col-span-5 text-right text-sm mt-2">Refill Amount</label>
              <Input
                control={form.controls.autoBalance.controls.refillAmount}
                containerClass="col-span-7"
                fullWidth
                size="sm"
              />
              <label className="col-span-5 text-right text-sm mt-2">Max monthly Refill Amount</label>
              <Input
                control={form.controls.autoBalance.controls.maxMonthlyRefillAmount}
                containerClass="col-span-7"
                fullWidth
                size="sm"
              />
            </div>
          </Card>

          <Card
            size="sm"
            header={(
              <div className="flex items-center">
                <div className="text-blue-dark text-md font-medium">Low Balance Notification</div>
                <Switch
                  className="ml-auto"
                  checked={formData?.lowBalanceEnabled || false}
                  onChange={(_, checked) => onToggleLowBalanceEnable(checked)}
                />
              </div>
            )}
          >
            <div className="grid grid-cols-12 gap-3 mt-3">
              <label className="col-span-5 text-right text-sm mt-2">Minimum Amount</label>
              <Input
                control={form.controls.lowBalance.controls.minimumAmount}
                containerClass="col-span-7"
                fullWidth
                size="sm"
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="flex items-center px-7 py-3">
        <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
        <Button
          className="text-sm rounded-full px-6 py-2"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AutoRecharging;
