export enum NUMBER_FEATURE {
  RECORD_CALL = 'record_call',
  RECORD_CALL_ON_ANSWER = 'record_call_on_answer',
  WHISPER = 'whisper',
  WHISPER_FILE = 'whisper_file',
  BLOCK_ANONYMOUS_CALLERS = 'block_anonymous_callers',
  VOICE_OTP = 'voice_otp',
  KEEP_NUMBER_TTS = 'keepnumber_tts',
  KEEP_NUMBER_FILE = 'keepnumber_file',
  KEEP_NUMBER_EARLYMEDIA = 'keepnumber_earlymedia',
  SHOW_CALLER_ID = 'show_sudonum_in_cli',
  VOICE_MAIL = 'voicemail_lite',
  DISABLE_SMS = 'disable_sms',
  SPAN_MULTIPLE_SMS = 'span_multiple_sms',
  HUNT_GROUP_LINEAR = 'hunt_group_linear',
  HUNT_GROUP_RANDOM = 'hunt_group_random',
  HUNT_GROUP_RING_ALL = 'hunt_group_ring_all',
  EXTENSION_DIALING = 'extension_dialing',
  RING_NO_ANSWER = 'ring_no_answer',
  WHOLESALE = 'wholesale',
  SIP_ENABLED = 'sip_enabled',
  IVR = 'ivr',
}

export enum CAPABILITY {
  VOICE = "VOICE",
  SMS = "SMS",
}

export enum NUMBER_MODE {
  OTP = "OTP",
  NORMAL = "NORMAL"
}

export enum NUMBER_CREATE_MODE {
  RANDOM = "RANDOM",
  SPECIFIC = "SPECIFIC"
}
