import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { IDataTableRef, TextField } from "../../../components";
import { SearchIcon } from "../../../components/icons";
import { WAListsModel } from "../../../utils/types";
import { ListDetailsModal } from "./ListDetailsModal";
import { ListEditModal } from "./ListEditModal";
import { ListsTable } from "./ListsTable";

export const Lists = () => {

    //search bar state
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        search: '',
    });

    //state
    const [list, setList] = useState<WAListsModel>(null);

    //datatable
    const [tableRef, setTableRef] = useState<IDataTableRef>();

    //modals
    const [editModal, setEditModal] = useState<boolean>(null);
    const [detailsModal, setDetailsModal] = useState<boolean>(null);

    const debouncedSetFilter = useCallback(
        debounce((filter) => setFilter(filter), 500),
        []
    );

    const onSearch = value => {
        setSearch(value);
        debouncedSetFilter({ search: value });
    };

    //TODO look at building a switch here
    const onListEdit = (list) => {
        setList(list);
        setEditModal(true);
    }

    const onListView = (list) => {
        setList(list);
        setDetailsModal(true);
    }

    const onEditClose = (list?) => {
        if (list) {
            refreshTable();
        }
        setEditModal(false);
    }

    const onViewClose = () => {
        setDetailsModal(false);
    }

    const refreshTable = useCallback(() => {
        if (tableRef) {
            tableRef.refresh();
        }
    }, [tableRef]);

    return (
        <div className="h-full flex flex-col px-4 pt-4 bg-[#F8F8F8]">
            <div className="flex justify-between items-center">
                <TextField
                    data-cy="wa-campaigns-search"
                    size="sm"
                    fullWidth
                    className="!rounded-full !bg-white shadow-md"
                    containerClass="w-80 ml-auto"
                    value={search}
                    icon={<SearchIcon size={16} color="primary" />}
                    placeholder="Search Campaigns"
                    clearable
                    onChange={(value) => onSearch(value)}
                />
            </div>

            <ListsTable
                filter={filter}
                className="h-0 flex-grow px-0 py-4"
                onEdit={(res) => onListEdit(res)}
                onView={(res) => onListView(res)}
                setTableRef={setTableRef}
            />

            {editModal &&
                <ListEditModal
                    list={list}
                    onClose={(list) => onEditClose(list)} />
            }
            {detailsModal &&
                <ListDetailsModal
                    list={list}
                    onClose={onViewClose} />
            }
        </div>
    )
}