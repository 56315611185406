import React, { FC, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { Radio, RadioGroup } from "@mui/material";
import { Input, Switch } from "../../../../components";
import { Form, FormControl, useForm, Validators } from "../../../../components/ReactiveForm";
import { BlockedKeywordModel } from "../../../../utils/types";
import { BLOCKED_KEYWORD_CHANNEL_VALUES, BLOCKED_KEYWORD_TYPE } from "../../../../utils/enums";

export interface IBlockedKeywordFormInitParams {
  getFormData: () => any;
}

export interface IBlockedKeywordFormProps {
  blockedKeyword?: BlockedKeywordModel;
  onInit?: (params: IBlockedKeywordFormInitParams) => void;
  onClose?: () => void;
}

export type IBlockedKeywordForm = {
  keywords: FormControl;
  type: FormControl;
  channels: FormControl;
}

const typeOptions = [
  { text: 'Exact', value: BLOCKED_KEYWORD_TYPE.EXACT, description: `The exact keyword that you have entered will be blocked` },
  { text: 'Ends with', value: BLOCKED_KEYWORD_TYPE.ENDS_WITH, description: `Block any words that ends with the keyword you have entered` },
  { text: 'Starts with', value: BLOCKED_KEYWORD_TYPE.STARTS_WITH, description: `Block any words that starts with the keyword you have entered` },
];

const BlockedKeywordForm: FC<IBlockedKeywordFormProps> = ({
  blockedKeyword,
  onInit = () => { },
  onClose = () => { },
}) => {
  const [form, formData] = useForm<IBlockedKeywordForm>({
    keywords: new FormControl('', [Validators.required()]),
    type: new FormControl('', [Validators.required()]),
    channels: new FormControl([], [Validators.required()]),
  });
  const [channels, setChannels] = useState(BLOCKED_KEYWORD_CHANNEL_VALUES.map((channel) => ({
    text: channel,
    active: false,
  })));
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (blockedKeyword) {
      form.patch({
        keywords: blockedKeyword.keywords,
        type: blockedKeyword.type,
        channels: blockedKeyword.channels,
      });
      setChannels(BLOCKED_KEYWORD_CHANNEL_VALUES.map((channel) => ({
        text: channel,
        active: blockedKeyword.channels.includes(channel),
      })));
    }
  }, [blockedKeyword]);

  const getFormData = useCallback(() => {
    setValidate(true);
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  const onSelectTypeOption = (type) => {
    if (formData?.keywords) {
      form.controls.type.patch(type.value);
    }
  };

  const onToggleChannel = (channel) => {
    const newChannels = channels.map((item) => (
      item.text === channel.text ? { ...item, active: !item.active } : item
    ));
    setChannels(newChannels);
    const selectedChannels = newChannels.filter((item) => item.active).map((item) => item.text);
    form.controls.channels.patch(selectedChannels);
  };

  return (
    <Form formGroup={form}>
      <Input
        type="textarea"
        control={form.controls.keywords}
        fullWidth
        label="Keywords"
        labelClass="text-md font-semibold"
        placeholder="Add keywords to block."
      />

      <div>
        <div className={classnames(
          'font-semibold',
          formData?.keywords ? 'text-blue' : 'text-gray-b4',
          { '!text-danger': validate && !formData?.type },
        )}>
          Select a type to block
        </div>
        <RadioGroup>
          <div className="grid md:grid-cols-3 gap-x-4 gap-y-2 mt-2">
            {typeOptions.map((item, i) => (
              <div
                key={i}
                className={classnames(
                  'flex items-start border rounded px-2 pt-1 pb-3',
                  formData?.keywords && item.value === formData?.type ? 'border-blue' : 'text-gray-b4',
                  formData?.keywords ? 'cursor-pointer' : 'cursor-default',
                  { '!border-danger': validate && !formData?.type },
                )}
                onClick={() => onSelectTypeOption(item)}
              >
                <Radio
                  size="small"
                  value={item.value}
                  checked={item.value === formData?.type}
                  disabled={!formData?.keywords}
                  sx={{ marginTop: -0.5, marginBottom: 'auto' }}
                />
                <div className="ml-1 mt-1.5">
                  <div className="text-sm font-bold">{item.text}</div>
                  <p className="text-xs my-2">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </RadioGroup>
        {validate && !formData?.type && (
          <div className="text-xs text-danger ml-2 mt-1">Please select a type.</div>
        )}
      </div>

      <div className="text-blue-dark mt-6">
        <div className={classnames(
          'font-semibold',
          { '!text-danger': validate && !formData?.channels?.length },
        )}>
          Channels to block keywords
        </div>
        <div className="grid grid-cols-1fr-auto md:grid-cols-2">
          {channels.map((channel, i) => (
            <React.Fragment key={i}>
              <span className="text-sm mt-2">{channel.text}</span>
              <Switch value={channel.active} onChange={() => onToggleChannel(channel)} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Form>
  );
};

export default BlockedKeywordForm;
