import React, {FC, useMemo, useState} from "react";
import {Button, Modal} from "../../components";
import BlockedKeywordForm, {IBlockedKeywordFormInitParams} from "../../pages/Settings/BlockedKeywords/BlockedKeywordForm";
import {ToastService} from "../../services";
import {BlockedKeywordModel} from "../../utils/types";

interface IBlockKeywordsModalProps {
  keywords: string;
  onClose: (result?: any) => void;
}

export const BlockKeywordsModal: FC<IBlockKeywordsModalProps> = ({
  keywords,
  onClose,
}) => {
  const [createForm, setCreateForm] = useState<IBlockedKeywordFormInitParams>({
    getFormData: () => null,
  });

  const blockedKeyword = useMemo(() => new BlockedKeywordModel({ keywords }), [keywords]);

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> marked as spam.</>);
      onClose(data);
    }
  };

  return (
    <Modal
      title="Mark keywords as spam"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onCreate}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedKeywordForm blockedKeyword={blockedKeyword} onInit={setCreateForm} onClose={() => onClose()} />
    </Modal>
  );
};
