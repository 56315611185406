import { WA_WEBHOOK_TYPE, WEBHOOK_TYPE } from "../enums";
import { WebhookService, WAWebhookService } from "../../services";

export class WebhookModel {
  name: string;
  type: WEBHOOK_TYPE;
  url: string;
  username: string;
  password: string;
  description: string;
  content_type: string;
  hmac_secret: string;
  is_active: boolean;

  // Fields from Whatsapp Webhook
  uuid: string;
  whatsapp_number: string;
  webhook_type: WA_WEBHOOK_TYPE;
  subscribed_statuses: [];
  created: string;
  modified: string;

  service: any;

  constructor(init: any = {}) {
    const data = {
      name: '',
      type: '',
      url: '',
      username: '',
      password: '',
      description: '',
      content_type: '',
      hmac_secret: '',
      whatsapp_number: '',
      is_active: false,
      ...init,
    };

    Object.assign(this, data)
    if (init.whatsapp_number) this.type = WEBHOOK_TYPE.WHATSAPP
    this.service = WebhookModel.get_service(this.type)
  }

  id() {
    return {
      "true": this.uuid,
      "false": this.name,
    }[`${this.type === WEBHOOK_TYPE.WHATSAPP}`]
  }

  static get_service(type) {
    return {
      "true": WAWebhookService,
      "false": WebhookService,
    }[`${type === WEBHOOK_TYPE.WHATSAPP}`]
  }

  static search(data, type, ...kwargs) {
    const service = this.get_service(type)
    return service.search(data, ...kwargs)
  }

  static create(data, type, ...kwargs) {
    const service = this.get_service(type)
    return service.create(data, ...kwargs)
  }

  update(data, ...kwargs) {
    return this.service.update(this.id(), data, ...kwargs)
  }

  patch(data, ...kwargs) {
    return this.service.patch(this.id(), data, ...kwargs)
  }

  delete(...kwargs) {
    return this.service.delete(this.id(), ...kwargs)
  }

  disable() {
    return this.patch({ is_active: !this.is_active })
  }
}
