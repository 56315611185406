import React, { FC } from "react";
import { Card } from "../Card";
import classNames from "classnames";
import { WithTooltipIcon } from "../WithTooltipIcon";

interface IStatisticsTileProps {
    className?: string;
    value: any;
    label?: string;
    color?: string;
    backdropClass?: string;
    tooltip?: string;
    textAlign?: string;
    onClick?: () => void;
}

export const StatisticsTile: FC<IStatisticsTileProps> = ({
    className,
    value,
    label = '',
    color = 'gray',
    backdropClass = 'bg-gray-300',
    tooltip,
    children,
    textAlign = "text-left",
    onClick,
}) => {
    return (
        <Card
            onClick={onClick}
            className={classNames(className, "min-w-[14rem] mr-4 !p-2")}
        >
            {tooltip &&
                <WithTooltipIcon
                    className="justify-end"
                    placement="bottom"
                    tooltip={tooltip}
                    iconSize={15}
                />
            }
            <div className="flex flex-row p-2">
                {children &&
                    <div className={classNames("rounded-2xl h-[60px] w-[60px] grid place-content-center p-4", backdropClass)}>
                        {children}
                    </div>
                }
                <div className={classNames("flex flex-col mx-2 whitespace-nowrap", textAlign)}>
                    <p className={"font-bold text-xl"} style={{ color: `${color}` }}>{value}</p>
                    <p>{label}</p>
                </div>
            </div>
        </Card>
    )
}