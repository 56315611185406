import React, {useMemo, useState} from "react";
import classnames from "classnames";
import {Tooltip} from "@mui/material";
import {DataTable, IDataTableColumn, TextField} from "../../../components";
import {EditIcon, PlusIcon, SearchIcon} from "../../../components/icons";
import BlockedKeywordCreateModal from "./BlockedKeywordCreateModal";
import BlockedKeywordEditModal from "./BlockedKeywordEditModal";
import {BLOCKED_KEYWORD_CHANNEL, BLOCKED_KEYWORD_CHANNEL_VALUES, BLOCKED_KEYWORD_TYPE} from "../../../utils/enums";
import {BlockedKeywordModel} from "../../../utils/types";

const data: BlockedKeywordModel[] = [
  { id: '1', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '2', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '3', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '4', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: [BLOCKED_KEYWORD_CHANNEL.SMS], blockedBy: 'Marisha van der Merwe' },
  { id: '5', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: [BLOCKED_KEYWORD_CHANNEL.WHATSAPP], blockedBy: 'Marisha van der Merwe' },
  { id: '6', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '7', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '8', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '9', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '10', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '11', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '12', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '13', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '14', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '15', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '16', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '17', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '18', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '19', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '20', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '21', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '22', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '23', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '24', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '25', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '26', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '27', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '28', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '29', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
  { id: '30', keywords: 'This is keywords', type: BLOCKED_KEYWORD_TYPE.EXACT, channels: BLOCKED_KEYWORD_CHANNEL_VALUES, blockedBy: 'Marisha van der Merwe' },
];

const BlockedKeywords = () => {
  const [search, setSearch] = useState('');
  const [creatingKeyword, setCreatingKeyword] = useState(false);
  const [editingKeyword, setEditingKeyword] = useState<BlockedKeywordModel>();

  const columns = useMemo<IDataTableColumn<BlockedKeywordModel>[]>(() => [
    { title: 'Keywords', field: 'keywords', cellClass: '!text-primary !font-medium'},
    {
      title: 'Channels',
      field: 'channels',
      render(row: BlockedKeywordModel) {
        return (
          row.channels.length === BLOCKED_KEYWORD_CHANNEL_VALUES.length ? 'All' : row.channels.join(', ')
        );
      },
    },
    { title: 'Blocked by', field: 'blockedBy' },
    {
      render(row: BlockedKeywordModel) {
        return (
          <div className="flex justify-end">
            <Tooltip title="Edit blocked keyword" arrow placement="left">
              <div
                data-cy="edit-number"
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md cursor-pointer"
                onClick={() => setEditingKeyword(row)}
              >
                <EditIcon color="blue" size={22} />
              </div>
            </Tooltip>
          </div>
        );
      }
    }
  ], []);

  return (
    <>
      <div className="relative h-full flex flex-col px-4 py-5">
        <div>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow-md"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search keywords"
            clearable
            onChange={(value) => setSearch(value)}
          />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          data={data}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!px-8"
          pagination
          rowsPerPage={50}
          paginationClass="mr-16"
        />

        <div
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-84 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setCreatingKeyword(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Block another keyword</span>
        </div>
      </div>

      {creatingKeyword && (
        <BlockedKeywordCreateModal onClose={() => setCreatingKeyword(false)} />
      )}

      {editingKeyword && (
        <BlockedKeywordEditModal
          blockedKeyword={editingKeyword}
          onClose={() => setEditingKeyword(undefined)}
        />
      )}
    </>
  );
};

export default BlockedKeywords;
