import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Chip, Chips, Modal, TextField } from '../../components';
import { CloneIcon, SearchIcon } from '../../components/icons';
import { Tooltip } from '@mui/material';
import { copyToClipboard } from '../../utils/helpers';
import { ToastService } from '../../services';
import { GA4Service } from '../../services/GA4.service';

interface IGA4ModalProps {
    ga4s?: string[];
    onClose?: () => void;
    onSubmit?: (newga4s: string[]) => void;
}


const AddGA4: FC<IGA4ModalProps> = (
    {
        ga4s = [],
        onClose,
        onSubmit,
    }
) => {

    const [search, setSearch] = useState('');
    // TODO create ga4 modal
    const [allGA4, setAllGA4] = useState([]);
    const [selectedGa4, setSelectedGA4] = useState<string[]>(ga4s);

    useEffect(() => {
        GA4Service.search().then((data) => {
            setAllGA4(data.results);
        }).catch((err) => {
            setAllGA4([]);
          ToastService.showHttpError(err, 'Loading GA4 failed');
        });
      }, []);


    const searchedGA4 = useMemo(() => {
        const keyword = search.toLowerCase();
        return allGA4
            .filter((item) => item.name.toLowerCase().includes(keyword));
    }, [search, allGA4]);


    const onToggleSelect = (ga4: any) => {
        if (selectedGa4.includes(ga4.name)) {
            setSelectedGA4(selectedGa4.filter((t) => t !== ga4.name));
        } else {
            setSelectedGA4([...selectedGa4, ga4.name]);
        }
    };


    const onSave = () => {
        onSubmit(selectedGa4);
        onClose();
    };


    return (
        <Modal
            title="Add Google Analytics"
            disableBackdropClose
            footerComponent={(
                <>
                    <Button
                        color="primary"
                        className="rounded-3xl text-sm shadow-md px-6 ml-auto"
                        disabled={!selectedGa4.length}
                        onClick={onSave}
                    >
                        Add
                    </Button>
                </>
            )}
            onClose={onClose}
        >
            <TextField
                fullWidth
                placeholder="Search your events"
                icon={<SearchIcon size={16} color="primary" />}
                value={search}
                clearable
                onChange={setSearch}
            />

            <Chips className="mt-4">
                {searchedGA4.map((item, i) => (
                    <Tooltip
                        key={i}
                        title={(
                            <div className="flex-center">
                                {item.url}
                                <CloneIcon className="ml-2 cursor-pointer" size={14} color="primary" onClick={() => copyToClipboard(item.url)} />
                            </div>
                        )}
                        arrow
                        placement="top"
                    >
                        <div>
                            {/* TODO Fix when type is set */}

                            {/* <Chip active={selectedGa4.includes(item.name)} onClick={() => onToggleSelect(item)}>
                                {item.name} - {item.type}
                            </Chip> */}

                            <Chip active={selectedGa4.includes(item.name)} onClick={() => onToggleSelect(item)}>
                                {item.name} - Google Analytic
                            </Chip>
                        </div>
                    </Tooltip>
                ))}
            </Chips>
        </Modal>
    )
}

export default AddGA4;
