import React, {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Snackbar, Stack} from "@mui/material";
import {getToastOptions} from "../../redux/selectors";
import {closeToast} from "../../redux/actions";

export type ToastSeverity = 'success' | 'info' | 'warning' | 'error';
export type ToastXPosition = 'left' | 'center' | 'right';
export type ToastYPosition = 'top' | 'bottom';

export interface IToastOptions {
  message: string | ReactElement;
  severity: ToastSeverity;
  autoHideDuration?: number;
  xPosition?: ToastXPosition;
  yPosition?: ToastYPosition;
}

export const ToastContainer = () => {
  const toastOptions = useSelector(getToastOptions);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toastOptions) {
      setOpen(true);
      const timer = setTimeout(() => {
        dispatch(closeToast());
      }, toastOptions.autoHideDuration || 3500);
      return () => clearTimeout(timer);
    } else {
      setOpen(false);
    }
  }, [toastOptions]);

  return (
    <Stack spacing={2}>
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: toastOptions?.xPosition || 'center', vertical: toastOptions?.yPosition || 'bottom' }}
      >
        <Alert
          data-cy="toast-panel"
          variant="filled"
          severity={toastOptions?.severity}
          onClose={() => setOpen(false)}
        >
          {toastOptions?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
