import React, {useState} from "react";
import NumberInput from "./NumberInput";
import FollowStep from "./FollowStep";
import {useHistory} from "react-router";
import {ROUTES} from "../../../constants";

export interface IWhatsAppSetupFormData {
  number: string;
}

const WhatsAppSetup = () => {
  const [form, setForm] = useState<IWhatsAppSetupFormData>();
  const [step, setStep] = useState(1);
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const onFinish = () => {
    history.replace(ROUTES.CALLS.INDEX);
  };

  const onUpdateForm = (data) => {
    setForm({
      ...form,
      ...data,
    });
    setStep(step + 1);
  };

  return (
    <div className="w-screen h-screen flex-center bg-gradient-to-r from-blue-dark to-[#22599C]">
      <div className="w-250 max-w-[95vw] max-h-[95vh] overflow-auto">
        <div className="h-[80vh] flex flex-col bg-white rounded-md shadow-md">
          {step === 1 && (
            <NumberInput formData={form} onBack={onBack} onNext={onUpdateForm} />
          )}
          {step === 2 && (
            <FollowStep formData={form} onBack={() => setStep(1)} onFinish={onFinish} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppSetup;
