import React from "react";
import { Button } from "../../../components";
import {
  ArrowRightCircleIcon, BarChartOutlineIcon,
  DocumentsIcon,
  HashTagIcon,
  LightBulbOnIcon,
  PhoneInTalkIcon,
  WhatsAppIcon
} from "../../../components/icons";
import { AuthService } from "../../../services"

const onClick = (redirect: string = "") => {
  AuthService.readme_login(redirect)
    .then((res) => {
      window.open(res.url);
    });
}

const DeveloperDocumentation = () => {
  return (
    <div className="developer-documentation px-10 py-9">
      <h1 className="text-3xl text-center">
        Let's get <span className="text-primary font-semibold">you started</span>
      </h1>

      <div className="grid grid-cols-12 gap-4 mt-8">
        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape1 bg-gradient-to-b from-[#1A477F] to-[#1C56A1] text-white rounded-md shadow-md p-6">
          <div className="relative">
            <h2 className="text-2xl font-bold">Getting you <span className="text-primary">started</span></h2>
            <div className="xs:flex items-start">
              <div className="mr-1">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  While working through the Getting Started guides use our API Explorer to browse the APIs and try them out without writing a line of code.
                </p>
              </div>
              <Button className="flex-shrink-0 text-sm rounded-full shadow-md px-6 max-xs:mt-4 max-xs:mx-auto" color="primary" onClick={() => onClick('/')}>
                Let's get started
              </Button>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-blue text-white rounded-xl mx-auto">
            <PhoneInTalkIcon size={20} />
          </div>
          <h3 className="text-lg font-bold mt-1">Call Tracking</h3>
          <p className="max-w-44 text-xs font-medium mt-1 mx-auto">Read & learn more about our voice product</p>
          <button className="mt-2" onClick={() => onClick('/docs/voice-call')}>
            <ArrowRightCircleIcon size={20} color="blue" />
          </button>
        </div>

        <div className="col-span-12 sm:col-span-4 md:col-span-6 xl:col-span-3 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-primary text-white rounded-xl mx-auto">
            <HashTagIcon size={20} />
          </div>
          <h3 className="text-lg font-bold mt-1">All about numbers</h3>
          <p className="max-w-60 text-xs font-medium mt-1 mx-auto">Everything you need to know about Our numbers</p>
          <button className="mt-2" onClick={() => onClick('/docs/number')}>
            <ArrowRightCircleIcon size={20} color="primary" />
          </button>
        </div>

        <div className="col-span-12 sm:col-span-4 md:col-span-6 xl:col-span-3 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-purple text-white rounded-xl mx-auto">
            <LightBulbOnIcon size={20} color={['white', 'white']} />
          </div>
          <h3 className="text-lg font-bold mt-1">Get some insights</h3>
          <p className="max-w-44 text-xs font-medium mt-1 mx-auto">Learn more about our number insights</p>
          <button className="mt-2" onClick={() => onClick('/docs/number-insights')}>
            <ArrowRightCircleIcon size={20} color="#4457FF" />
          </button>
        </div>

        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape2 bg-[#1a4e8e] text-white rounded-md shadow-md p-6">
          <div className="relative xs:flex">
            <div>
              <h2 className="text-2xl font-bold">Explore our <span className="text-primary">API's</span></h2>
              <div className="mr-1">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  You will need an API account with us to do this, email
                  <a className="text-primary font-semibold mx-1" href="mailto:support@sudonum.com" target="_blank" rel="noreferrer">support@sudonum.com</a>
                  to request one if you don't already have one.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center flex-shrink-0 xs:ml-2 max-xs:mt-4">
              <Button className="w-full xs:w-44 text-sm rounded-full shadow-md" color="primary" onClick={() => onClick('/reference/api-token-auth-1')}>
                Explore API's
              </Button>
              <a href="mailto:support@sudonum.com" target="_blank" rel="noreferrer">
                <Button className="w-full xs:w-44 text-sm rounded-full shadow-md mt-2" color="primary" variant="outline">Pop us a mail</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 md:col-span-4 xl:col-span-3 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-blue-dark text-white rounded-xl mx-auto">
            <DocumentsIcon size={20} />
          </div>
          <h3 className="text-lg font-bold mt-1">Documentation</h3>
          <p className="text-xs font-medium mt-1 mx-auto">Need to read something?<br />Check our all documentation</p>
          <button className="mt-2" onClick={() => onClick('/docs')}>
            <ArrowRightCircleIcon size={20} color="black" />
          </button>
        </div>

        <div className="col-span-12 sm:col-span-8 md:col-span-9 lg:col-span-7 xl:col-span-5 flex items-center bg-white text-center rounded-md shadow-md p-6">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 flex-center bg-primary text-white rounded-xl mx-auto">
              <DocumentsIcon size={20} />
            </div>
            <h3 className="text-md text-primary font-bold mt-2">Webhooks</h3>
          </div>
          <div className="flex-shrink-0 w-1 h-7 bg-primary mx-3" />
          <div>
            <p className="text-xs text-left font-medium mt-1">
              If Features are superpowers, Webhooks are invisible minions used to trigger events in your system when things happen in our system.
            </p>
            <Button className="text-sm rounded-full shadow-md mt-2" color="primary" onClick={() => onClick('/docs/webhooks')}>
              Wow, I need to know more
            </Button>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 md:col-span-4 xl:col-span-3 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-sky text-white rounded-xl mx-auto">
            <BarChartOutlineIcon size={20} />
          </div>
          <h3 className="text-lg font-bold mt-1">GA4 Integration</h3>
          <p className="text-xs font-medium mt-1 mx-auto">Looking to integrate your GA4 Project with Sudonum? Then this is the doc for you.</p>
          <button className="mt-2" onClick={() => onClick('/docs/ga4')}>
            <ArrowRightCircleIcon size={20} color="#79D7EE" />
          </button>
        </div>

        <div className="col-span-12 sm:col-span-8 md:col-span-8 lg:col-span-6 xl:col-span-4 bg-white text-center rounded-md shadow-md px-4 pt-4 pb-2">
          <div className="w-10 h-10 flex-center bg-[#69D35F] text-white rounded-xl mx-auto">
            <WhatsAppIcon size={26} />
          </div>
          <h3 className="text-lg font-bold mt-1">WhatsApp Lead Tracking</h3>
          <p className="text-xs font-medium mt-1 mx-auto">Your one stop for anything WhatsApp related. From Chat intros to web hooks</p>
          <button className="mt-2" onClick={() => onClick('/docs/whatsapp-chat-intros')}>
            <ArrowRightCircleIcon size={20} color="#69D35F" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeveloperDocumentation;
