import React, {FC} from "react";
import {Button} from "../../../../../components";
import {ArrowLeftIcon} from "../../../../../components/icons";
import {WhatsAppStep4Svg} from "../../../../../components/svgs";

export interface IFollowStep4Props {
  onBack(): void;
  onNext(): void;
}

const FollowStep4: FC<IFollowStep4Props> = ({
  onBack, onNext,
}) => {
  return (
    <div className="h-full flex flex-col text-center px-8 pt-8">
      <h6 className="font-semibold">To set up your number for WhatsApp</h6>
      <p className="text-primary text-3xl font-bold">Follow all the steps below</p>
      <div className="max-w-145 mx-auto mt-10 mb-6">
        <WhatsAppStep4Svg size={520} className="mx-auto" />
        <p className="font-semibold mt-8">
          WhatsApp is going to call you, once the call is completed there will be a voice recording in your call logs.
          Go to your call log and look for your number.
        </p>
        <p className="font-semibold mt-3">
          Once you have your number press the "<span className="text-blue font-bold">Play</span>" button and listen to the recording.
          The recording will be your voice OTP that you have to input into WhatsApp Business after you 60min delay.
        </p>
      </div>
      <div className="flex mt-auto mb-6">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2" onClick={onNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FollowStep4;
