import { Step, StepIconProps, StepLabel, Stepper, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import classNames from "classnames";
import React, { FC } from "react";

interface IHorizontalStepperProps {
    steps?: any[];
    activeStep: number;
    className?: string;
    color?: string;
}

const Connector = styled(StepConnector)(({ color }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: color || "#307CD8"
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: color || "#307CD8"
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#DBDBDB',
    },
}));

const StepIconComponent = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean; color?: string; };
}>(({ ownerState }) => ({
    backgroundColor: '#DBDBDB',
    zIndex: 1,
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: ownerState.color || "#307CD8",
    }),
    ...(ownerState.completed && {
        backgroundColor: ownerState.color || "#307CD8",
    }),
}));

export const HorizontalStepper: FC<IHorizontalStepperProps> = ({
    steps = [1, 2],
    activeStep,
    className,
    color = '#307CD8',
}) => {

    const StepComponent = (props: StepIconProps) => {
        const { active, completed } = props;
        return (
            <StepIconComponent ownerState={{ completed, active, color }} />
        );
    }

    return (
        <div className={classNames(className)}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<Connector color={color} />}>
                {steps.map((step) => (
                    <Step key={step}>
                        <StepLabel StepIconComponent={StepComponent}></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
