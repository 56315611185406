import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { uniq } from "lodash";
import { Button, SelfHelpButton } from "../../components";
import { BlockNumberModal, ExportEmailSentModal } from "../../parts";
import CallsFilters from "./Filters";
import CallsTable from "./CallsTable";
import { ROUTES } from "../../constants";
import { setCallTab, setSelectedCallLogs } from "../../redux/actions";
import { getSelectedCallLogs, getCallTab, getCallTableRef } from "../../redux/selectors";
import { CALL_TAB } from "../../utils/enums";
import { formatPhoneNumber } from "../../utils/helpers";
import { CallRecordModel } from "../../utils/types";
import { BlockedNumberService, CallService, ToastService } from '../../services';
import { ExistingBlockNumberModal } from '../../parts/ExistingBlockNumberModal';

const Calls = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tab = useSelector(getCallTab);
  const tableRef = useSelector(getCallTableRef);
  const selectedLogs = useSelector(getSelectedCallLogs);

  const [query, setQuery] = useState<any>();
  const [isExported, setIsExported] = useState(false);
  const [blockingNumber, setBlockingNumber] = useState<string>();
  const [blockedNumbers, setBlockedNumbers] = useState(['27763239906']);
  const [showBlockedModal, setShowBlockedModal] = useState<boolean>();

  useEffect(() => {
    const tabs = [
      { path: ROUTES.CALLS.RECEIVED, tab: CALL_TAB.RECEIVED },
      { path: ROUTES.CALLS.MADE, tab: CALL_TAB.MADE },
      { path: ROUTES.CALLS.BLOCKED, tab: CALL_TAB.BLOCKED },
    ];
    const tab = tabs.find((item) => location.pathname.startsWith(item.path)) || tabs[0];
    dispatch(setCallTab(tab.tab));
  }, [location.pathname]);

  const refreshTable = () => {
    if (tableRef) {
      tableRef.refresh(true);
    }
  };

  const onUnBlockNumbers = (call: CallRecordModel) => {
    BlockedNumberService.search({ number: call.from_number }).then(res => {
      if (res.results.length === 0) {
        ToastService.success(<>{call.from_number} has already been unblocked.</>);
        return;
      }

      res.results[0].delete().then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> unblocked number.</>);
      })
    })
  }

  const onBlockNumbers = (call: CallRecordModel) => {
    BlockedNumberService.search({ number: call.from_number }).then(res => {
      if (res.results.length > 0) {
        setShowBlockedModal(true);
      } else {
        setBlockingNumber(call.from_number);
      }
    })
  };

  const onCloseBlockNumberModal = (result) => {
    if (result) {
      refreshTable();
      const numbers = blockingNumber.split(',').map((item) => item.replace(/\D/g, ''));
      setBlockedNumbers((prev) => ([
        ...prev,
        ...numbers,
      ]));
      dispatch(setSelectedCallLogs([]));
    }

    setBlockingNumber(undefined);
  };

  const onQueryChange = useCallback((query) => {
    setQuery(query);
  }, []);

  const onDownload = useCallback(() => {
    CallService.exportCalls(query).then(() => {
      setIsExported(true);
    });
  }, [query]);

  return (
    <>
      <div
        data-cy="call-content"
        className="h-full flex flex-col px-4 pt-4">
        <div className="flex items-start max-xl:flex-wrap">
          <CallsFilters />
          <div className="flex flex-wrap flex-shrink-0 ml-auto">
            {tab === CALL_TAB.RECEIVED && selectedLogs.length > 0 && (
              <Button
                color="blue-dark"
                className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
              // onClick={() => onBlockNumbers(selectedLogs)}
              >
                Block all Numbers
              </Button>
            )}
            {tab === CALL_TAB.BLOCKED && selectedLogs.length > 0 && (
              <Button
                color="blue-dark"
                className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
              >
                Unblock Caller
              </Button>
            )}
            <Button
              color="blue-light"
              className="text-xs rounded-full !normal-case shadow px-6 ml-2 mb-2"
              onClick={onDownload}
            >
              Export list
            </Button>
          </div>
          <SelfHelpButton url={'/docs/voice-call'} />
        </div>

        <CallsTable
          onBlockNumbers={onBlockNumbers}
          onUnBlockNumbers={onUnBlockNumbers}
          onQueryChange={onQueryChange}
        />
      </div>

      {blockingNumber && (
        <BlockNumberModal
          title="Mark caller as spam"
          defaults={{ number: blockingNumber, channels: ["VOICE"] }}
          onClose={onCloseBlockNumberModal}
        />
      )}

      {showBlockedModal && (
        <ExistingBlockNumberModal
          onClose={() => setShowBlockedModal(false)}
        />
      )}

      {isExported && (
        <ExportEmailSentModal onClose={() => setIsExported(false)} />
      )}
    </>
  );
};

export default Calls;
