import React, { FC } from "react";
import classnames from "classnames";
import {LogoIcon, LogoTextIcon} from "../../components/icons";

export interface ISimpleLayoutProps {
  className?: string;
}

const SimpleLayout: FC<ISimpleLayoutProps> = ({
  className = '',
  children,
}) => {
  return (
    <div className="simple-layout w-full h-screen bg-gray-f5 flex flex-col">
      <div className="flex items-center bg-blue-dark shadow-md px-4 py-2">
        <LogoIcon color="primary" size={32} />
        <LogoTextIcon color="white" className="ml-2" size={130} />
      </div>
      <div className={classnames('h-0 flex-grow overflow-auto', className)}>
        {children}
      </div>
    </div>
  );
};

export default SimpleLayout;
