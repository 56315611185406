import React, { FC, useState } from "react";
import { useHistory } from 'react-router';
import { Button, Modal } from "../../../components";
import { ROUTES } from "../../../constants";
import SendMessageForm, { ISendMessageFormInitParams } from "../SendMessageForm";
import { SmsService, ToastService } from "../../../services";
import { SmsCalculator } from "../../../utils/helpers/sms.helpers";

interface ISendMessageModalProps {
  onSend: (result?: any) => void;
  onClose: (result?: any) => void;
}

const SendMessageModal: FC<ISendMessageModalProps> = ({
  onSend,
  onClose,
}) => {
  const [messageForm, setMessageForm] = useState<ISendMessageFormInitParams>({
    getFormData: () => null,
  });
  const history = useHistory();

  const sendSms = () => {
    const data = messageForm.getFormData();
    if (data) {
      SmsService.sendSms({
        from_number: data.fromNumber.replace(/\D/g, ''),
        to_number: data.targetNumber.replace(/\D/g, ''),
        text: data.text,
        unicode_encoding: SmsCalculator.detectEncoding(data.text)[0] === 70,
      }).then((res) => {
        history.push(ROUTES.MESSAGES.SENT);
        onSend(res);
        ToastService.success(<><span className="text-success mr-1">Successfully</span> sent message.</>);
        onClose(res);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Send message failed');
      });
    }
  };

  return (
    <Modal
      title="Send SMS"
      footerComponent={(
        <>
          <Button
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={sendSms}
          >
            Send SMS
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <SendMessageForm onInit={setMessageForm} />
    </Modal>
  );
};

export default SendMessageModal;
