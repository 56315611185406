import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import { Card, Chip, Chips, TextField } from "../../../components";
import {
  CoinsIcon,
  LogoIcon,
  PhoneContactIcon,
  PhoneIcon,
  PhoneRingIcon,
  PhoneVideoCallIcon,
  TimeIcon,
  TimeIntervalIcon
} from "../../../components/icons";
import { AddTagModal, FullAudioPlayer, IExtraInfo } from "../../../parts";
import { LogService, MessageBoxService, ToastService } from "../../../services";
import { copyToClipboard, formatPhoneNumber, getCountryCodeFromNumber } from "../../../utils/helpers";
import { CallRecordModel, VoiceCallLogModel } from "../../../utils/types";
import { CALL_DIRECTION, CALL_STATUS } from "../../../utils/enums";

export interface ICallDetailProps {
  call: CallRecordModel;
}

const CallDetail: FC<ICallDetailProps> = ({
  call,
}) => {
  const [callLog, setCallLog] = useState<VoiceCallLogModel>();
  const [tags, setTags] = useState<string[]>(['Important', 'Follow up Required']);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);
  const [webhooks, setWebhooks] = useState<string[]>([]);
  const [features, setFeatures] = useState<string[]>([]);

  useEffect(() => {
    LogService.searchVoiceCallLogs({ uuid: call.uuid }).then((data) => {
      const log = data[0];
      setCallLog(log);

      if (log) {
        setWebhooks(log.webhooks);
        setFeatures(log.features);
      }
    }).catch((err) => {
      ToastService.showHttpError(err, 'Loading call detail failed');
    });
  }, [call]);

  const onAddTags = (newTags: []) => {
    setTags([...tags, ...newTags]);
  };

  const onRemoveTag = (index: number) => {
    MessageBoxService.confirm({
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete <b>'{tags[index]}'</b> tag?</>,
    }).then((result) => {
      if (result) {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
      }
    });
  };

  return (
    <>
      <div>
        <Card size="lg">
          <div className="flex items-start flex-col sm:flex-row sm:items-center">
            <div className="text-28p font-semibold">Call Details</div>
            <div className="sm:ml-auto" />
            {call.call_direction !== CALL_DIRECTION.INBOUND && (
              <div
                className="text-11p hover:text-blue-light ml-0 has-hover-action cursor-pointer"
                onClick={() => copyToClipboard(callLog?.ip_address || '')}
              >
                <span className="font-semibold transition-all">IP address</span>
                <span className="transition-all">: {callLog?.ip_address || ''}</span>
                <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
              </div>
            )}
            <div
              className="text-11p hover:text-blue-light ml-0 sm:ml-4 has-hover-action cursor-pointer"
              onClick={() => copyToClipboard(call.uuid)}
            >
              <span className="font-semibold transition-all">UUID</span>
              <span className="transition-all">: {call.uuid}</span>
              <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
            </div>
          </div>

          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 mt-8">
            <div className="relative flex items-center">
              <div className={classnames(
                'absolute w-18.5 h-18.5 flex-center bg-white rounded-full shadow-md',
                call.status === CALL_STATUS.BLOCKED_NUMBER ? 'border-2 text-danger-light border-danger-light' : 'border text-blue-light border-blue-light',
              )}>
                <PhoneContactIcon size={44} />
              </div>
              <div className={classnames(
                'flex-grow h-18 flex items-center flex-wrap text-white font-medium text-lg rounded-md pl-13 pr-4 ml-9',
                call.status === CALL_STATUS.BLOCKED_NUMBER ? 'bg-danger-light !pl-12' : 'bg-blue-light',
              )}>
                <span className="font-semibold mr-2">
                  {call.status === CALL_STATUS.BLOCKED_NUMBER ? 'Blocked Caller' : 'Caller No:'}
                </span>
                <div className="flex items-center ml-auto">
                  <img src={`/assets/images/flags/${getCountryCodeFromNumber(call.from_number)?.toLowerCase()}.png`} alt="" width={24} />
                  <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(call.from_number)}</span>
                </div>
              </div>
            </div>
            {call.number && call.call_direction === CALL_DIRECTION.INBOUND && (
              <div className="relative flex items-center">
                <div className="absolute w-18.5 h-18.5 flex-center bg-white border border-gray-c0 rounded-full shadow-md">
                  <LogoIcon color="blue-dark" size={44} />
                </div>
                <div className="flex-grow h-18 flex items-center flex-wrap bg-gray-f5 font-medium text-lg rounded-md pl-13 pr-4 ml-9">
                  <div className="font-semibold leading-none mr-2">
                    <div>Sudo Number:</div>
                    {call.meta?.name && (
                      <div className="text-xs text-blue-dark">{call.meta.name}</div>
                    )}
                  </div>
                  <div className="flex items-center ml-auto">
                    <img src={`/assets/images/flags/${getCountryCodeFromNumber(call.number).toLowerCase()}.png`} alt="" width={24} />
                    <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(call.number)}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="relative flex items-center">
              <div className="absolute w-18.5 h-18.5 flex-center bg-white border border-primary rounded-full shadow-md">
                <PhoneVideoCallIcon color="primary" size={44} />
              </div>
              <div className="flex-grow h-18 flex items-center flex-wrap bg-primary font-medium text-lg text-white rounded-md pl-13 pr-4 ml-9">
                <span className="font-semibold mr-2">Target No:</span>
                <div className="flex items-center ml-auto">
                  <img src={`/assets/images/flags/${getCountryCodeFromNumber(call.target_number).toLowerCase()}.png`} alt="" width={24} />
                  <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(call.target_number)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 xl:grid-cols-5 gap-x-3 gap-y-10 mt-15">
            <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">
                <TimeIcon color="blue" />
              </div>
              <div className="text-sm mt-3">Start Time</div>
              <div className="text-blue-light text-xl">
                <span>{call.call_start_stamp.substr(0, 10)}</span>&nbsp;
                <span className="font-semibold">{call.call_start_stamp.substr(11, 8)}</span>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">
                <TimeIntervalIcon color="blue" />
              </div>
              <div className="text-sm mt-3">Duration</div>
              <div className="text-blue-light font-semibold text-xl">{call.formatDuration()}</div>
            </div>
            {/*<div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">*/}
            {/*  <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">*/}
            {/*    <TimeIcon color="blue" />*/}
            {/*  </div>*/}
            {/*  <div className="text-sm mt-3">End Time</div>*/}
            {/*  <div className="text-blue-light text-xl">*/}
            {/*    <span>{call.call_end_stamp.substr(0, 10)}</span>&nbsp;*/}
            {/*    <span className="font-semibold">{call.call_end_stamp.substr(11, 8)}</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={classnames(
              'relative flex flex-col items-center text-center bg-primary-lighter text-primary rounded-md p-4',
              { '!bg-[#ecf5dd] !text-success': call.status === CALL_STATUS.ANSWERED },
              { '!bg-[#fed8da] !text-danger-light': call.status === CALL_STATUS.BLOCKED_NUMBER },
            )}>
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-inherit">
                <PhoneIcon />
              </div>
              <div className="text-body text-sm mt-3">Call Status</div>
              <div className="font-semibold text-xl">
                {call.status === CALL_STATUS.BLOCKED_NUMBER ? 'Blocked Number' : call.status}
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">
                <PhoneRingIcon color="blue" size={33} />
              </div>
              <div className="text-sm mt-3">Ring Time</div>
              <div className="text-blue-light font-semibold text-xl">{call.formatRingTime()}</div>
            </div>
            <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">
                <CoinsIcon color="blue" />
              </div>
              <div className="text-sm mt-3">Amount</div>
              <div className="text-blue-light font-semibold text-xl">{call.formatAmount()}</div>
            </div>
          </div>
        </Card>

        {call.voice_recording_url ? (
          <Card>
            <div className="flex items-center border-b border-gray-f4 pb-3">
              <div className="text-blue-dark font-semibold text-lg px-1">Call Recording</div>
              <div className="flex items-center ml-auto">
                <i className="far fa-circle text-blue text-sm" />
                <span className="text-15p ml-2">Caller</span>
              </div>
              <div className="flex items-center ml-8">
                <i className="far fa-circle text-primary text-sm" />
                <span className="text-15p ml-2">Target</span>
              </div>
            </div>

            <FullAudioPlayer src={call.voice_recording_url} stereo />
          </Card>
        ) : (
          <div className="bg-info-lighter text-blue-dark font-semibold rounded-md shadow-md px-6 py-4 mb-4.5">
            This call has no call recording record.
          </div>
        )}
        {/*<Card*/}
        {/*  header={(*/}
        {/*    <Button*/}
        {/*      className="text-blue"*/}
        {/*      leftIcon={<PlusCircleIcon size={24} />}*/}
        {/*      onClick={() => setShowAddTagModal(true)}*/}
        {/*    >*/}
        {/*      Add a tag*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*>*/}
        {/*  <Chips>*/}
        {/*    {tags.map((item, i) => (*/}
        {/*      <Chip key={i} size="sm" active clickable={false} removable onRemove={() => onRemoveTag(i)}>*/}
        {/*        {item}*/}
        {/*      </Chip>*/}
        {/*    ))}*/}
        {/*  </Chips>*/}
        {/*</Card>*/}

        {!!Object.keys(call.meta).length && (<Card
          title="Extra Info"
          headerClass="text-blue-dark text-lg font-semibold px-1"
        >
          {Object.entries(call.meta).map((info, i) => (
            <TextField
              key={i}
              containerClass="mb-5"
              label={info[0]}
              value={info[1]}
              readonly
            />
          ))}
        </Card>)}

        {call.number && call.call_direction === CALL_DIRECTION.OUTBOUND && (
          <div className="grid lg:grid-cols-2 gap-5">
            <Card
              title="Webhooks"
              headerClass="text-blue-dark text-lg font-semibold px-1"
            >
              <Chips>
                {webhooks.map((item, i) => (
                  <Chip key={i} size="sm" active clickable={false}>
                    {item}
                  </Chip>
                ))}
              </Chips>
            </Card>

            <Card
              title="Features"
              headerClass="text-blue-dark text-lg font-semibold px-1"
            >
              <Chips>
                {features.map((item, i) => (
                  <Chip key={i} size="sm" active clickable={false}>
                    {item}
                  </Chip>
                ))}
              </Chips>
            </Card>
          </div>
        )}
      </div>

      {showAddTagModal && (
        <AddTagModal
          tags={tags}
          onSubmit={onAddTags}
          onClose={() => setShowAddTagModal(false)}
        />
      )}
    </>
  );
};

export default CallDetail;
