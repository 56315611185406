import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import ChatIntros from "./index";

const WhatsAppChatIntrosRouting = () => (
  <Switch>
    <Route path={ROUTES.WHATSAPP.CHAT_INTROS.INDEX} component={ChatIntros} />
  </Switch>
);

export default WhatsAppChatIntrosRouting;
