import React, { FC, ReactNode, useMemo, useState } from "react";
import { Button, Dropdown } from "../../../../components";
import {
  CalendarIcon,
  PlusCircleIcon,
  VoiceMailIcon,
  WhatsAppOutlineIcon,
} from '../../../../components/icons';
import { DateRangeFilter, MultiSelectFilter, NumberFilter, UUIDFilter } from '../../../../parts';
import { chatLogStatuses } from "../../../../utils/enums";
import { FilterModel } from "../../../../utils/types";
import { TITLE } from "../../../../constants";

export interface IChatLogsFiltersProps {
  filters: FilterModel[];
  onChange(filters: FilterModel[]): void;
  children?: ReactNode;
}

const statusOptions = chatLogStatuses.map((item) => ({
  value: item.value,
  text: <span className="text-10p"><b className="text-11p">{item.text}</b> ({item.value})</span>,
  plainText: `${item.text} (${item.value})`,
}))

const categories = [
  { type: 'range', title: TITLE.range, icon: CalendarIcon, iconSize: 18, component: DateRangeFilter },
  { type: 'account', title: TITLE.account, icon: WhatsAppOutlineIcon, iconSize: 20, component: NumberFilter },
  { type: 'chat_intro_uuid', title: TITLE.chat_intro_uuid, icon: WhatsAppOutlineIcon, iconSize: 20, component: UUIDFilter },
  {
    type: 'status',
    title: TITLE.status,
    icon: VoiceMailIcon,
    iconSize: 16,
    component: MultiSelectFilter,
    props: {
      options: statusOptions,
      dropdownClass: '!w-110 !max-w-110',
    },
  },
];

const ChatLogsFilter: FC<IChatLogsFiltersProps> = ({
  filters,
  onChange,
  children,
}) => {
  const [activeFilter, setActiveFilter] = useState();

  const remainCategories = useMemo(() => (
    categories.filter((item) => filters.every((f) => f.category !== item.type))
  ), [filters]);

  const onAddFilter = (category) => {
    onChange([
      ...filters,
      new FilterModel({
        category: category.type,
        text: '',
        value: null,
      }),
    ]);
    setTimeout(() => {
      setActiveFilter(category.type);
    }, 500);
  };

  const onSelectFilter = (filter: FilterModel) => {
    onChange(filters.map((item) => (
      item.category === filter.category ? new FilterModel(filter) : item
    )));
    setActiveFilter(undefined);
  };

  const onDeleteFilter = (filter: FilterModel) => {
    onChange(filters.filter((item) => item.category !== filter.category));
    setActiveFilter(undefined);
  };

  return (
    <div
      data-cy="cl-filter"
      className="flex items-center flex-wrap flex-shrink">
      <span className="text-sm mr-2 mb-2">Filter data:</span>
      {filters.map((filter, i) => {
        if (filter.locked) {
          return (
            <React.Fragment key={filter.category}>
              {i > 0 && (
                <span className="text-xs mx-2 mb-2">And</span>
              )}
              <Button color="primary" className="text-xs !normal-case font-semibold rounded-full px-4 shadow-md !cursor-default mb-2">
                {filter.text}
              </Button>
            </React.Fragment>
          );
        }

        const category = categories.find((item) => item.type === filter.category);
        return (
          <React.Fragment key={filter.category}>
            {i > 0 && (
              <span className="text-xs mx-2 mb-2">And</span>
            )}
            <category.component
              title={category.title}
              key={filter.category}
              category={filter.category}
              value={filter}
              opened={filter.category === activeFilter}
              {...category.props as any}
              onChange={onSelectFilter}
              onDelete={onDeleteFilter}
            />
          </React.Fragment>
        );
      })}
      {remainCategories.length > 0 && (
        <>
          <span className="text-xs mx-2 mb-2">And</span>
          <Dropdown
            key={remainCategories.length}
            className="mb-2"
            text="Add a Filter"
            buttonProps={{
              className: 'text-xs font-semibold rounded-3xl hover:!bg-primary hover:text-white',
              color: 'primary-o',
              leftIcon: <PlusCircleIcon className="fill-current" color="current" size={20} />,
            }}
            dropdownClass="w-max min-w-full max-h-60 rounded-bl-md rounded-br-md px-3 pt-2"
          >
            {remainCategories.map((item) => (
              <div
                key={item.type}
                className="flex items-center cursor-pointer mb-2"
                onClick={() => onAddFilter(item)}
              >
                <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-primary bg-opacity-24 rounded-md">
                  {item.icon({ color: 'primary', size: item.iconSize })}
                </div>
                <span className="min-w-fit text-primary text-15p font-medium ml-2">{item.title}</span>
              </div>
            ))}
          </Dropdown>
        </>
      )}

      <div className="mr-auto" />

      {children}
    </div>
  );
};

export default ChatLogsFilter;
