import React, { FC, useState } from "react";
import classnames from "classnames";
import { Card, TextField } from "../../../components";
import {
  CalendarIcon,
  LogoIcon,
  SmsIcon,
  SmsReceiveIcon,
  SmsSendWithTailIcon
} from "../../../components/icons";
import { AddTagModal } from "../../../parts";
import { MessageBoxService, ToastService } from "../../../services";
import { SMS_DIRECTION, SMS_STATUS } from "../../../utils/enums";
import { copyToClipboard, formatPhoneNumber, getCountryCodeFromNumberToLowerCase } from "../../../utils/helpers";
import { SmsRecordModel } from "../../../utils/types";

export interface IMessageDetailProps {
  message: SmsRecordModel;
  onClose: (result?: any) => void;
}

const MessageDetail: FC<IMessageDetailProps> = ({
  message,
  onClose,
}) => {
  const [tags, setTags] = useState<string[]>(['Important', 'Follow up Required']);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);

  const onCopyUuid = () => {
    copyToClipboard(message.uuid);
  };

  const onAddTags = (newTags: []) => {
    setTags([...tags, ...newTags]);
    ToastService.success(<><span className="text-success mr-1">Successfully</span> added a tag.</>);
    onClose(new SmsRecordModel({
      ...message,
      tags: [...tags, ...newTags],
    }));
  };

  const onRemoveTag = (index: number) => {
    MessageBoxService.confirm({
      title: 'Delete confirmation',
      message: <>Are you sure you want to delete <b>'{tags[index]}'</b> tag?</>,
    }).then((result) => {
      if (result) {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);

        ToastService.success(<><span className="text-success mr-1">Successfully</span> removed a tag.</>);
        onClose(new SmsRecordModel({
          ...message,
          tags: newTags,
        }));
      }
    });
  };

  return (
    <>
      <div>
        <Card size="lg">
          <div className="flex items-start flex-col sm:flex-row sm:items-center">
            <div className="text-28p font-semibold">Message Details</div>
            <div className="text-11p hover:text-blue-light ml-0 sm:ml-auto has-hover-action cursor-pointer" onClick={onCopyUuid}>
              <span className="font-semibold transition-all">UUID</span>
              <span className="transition-all">: {message.uuid}</span>
              <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
            </div>
          </div>

          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5 mt-8">
            <div className="relative flex items-center">
              <div className={classnames(
                'absolute w-18.5 h-18.5 flex-center bg-white rounded-full shadow-md',
                message.status === SMS_STATUS.BLOCKED ? 'border-2 text-danger-light border-danger-light' : 'border text-blue-light border-blue-light',
              )}>
                <SmsSendWithTailIcon size={44} />
              </div>
              <div className={classnames(
                'flex-grow h-18 flex items-center flex-wrap text-white font-medium text-lg rounded-md pl-13 pr-4 ml-9',
                message.status === SMS_STATUS.BLOCKED ? 'bg-danger-light !pl-11 !pr-2' : 'bg-blue-light',
              )}>
                <span className="font-semibold mr-2">
                  {message.status === SMS_STATUS.BLOCKED ? 'Blocked Sender' : 'Sender:'}
                </span>
                <div className="flex items-center ml-auto">
                  <img src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(message.from_number)}.png`} alt="" width={24} />
                  <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(message.from_number)}</span>
                </div>
              </div>
            </div>
            {message.direction !== SMS_DIRECTION.OUTBOUND && !!message.number && (
              <div className="relative flex items-center">
                <div className="absolute w-18.5 h-18.5 flex-center bg-white border border-gray-c0 rounded-full shadow-md">
                  <LogoIcon color="blue-dark" size={44} />
                </div>
                <div className="flex-grow h-18 flex items-center flex-wrap bg-gray-f5 font-medium text-lg rounded-md pl-13 pr-4 ml-9">
                  <div className="font-semibold leading-none mr-2">
                    <div>Sudo Number:</div>
                    {message.meta?.name && (
                      <div className="text-xs text-blue-dark">{message.meta.name}</div>
                    )}
                  </div>
                  <div className="flex items-center ml-auto">
                    <img src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(message.number)}.png`} alt="" width={24} />
                    <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(message.number)}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="relative flex items-center">
              <div className="absolute w-18.5 h-18.5 flex-center bg-white border border-primary rounded-full shadow-md">
                <SmsReceiveIcon className="mb-1" color="primary" size={36} />
              </div>
              <div className="flex-grow h-18 flex items-center flex-wrap bg-primary font-medium text-lg text-white rounded-md pl-13 pr-4 ml-9">
                <span className="font-semibold mr-2">Target No:</span>
                <div className="flex items-center  ml-auto">
                  <img src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(message.target_number)}.png`} alt="" width={24} />
                  <span className="ml-2 text-sm sm:text-lg">{formatPhoneNumber(message.target_number)}</span>
                </div>
              </div>
            </div>
            {!message.number && (
              <div className="relative flex items-center">
                <div className="absolute w-12 h-12 flex-center bg-[#f4f8fc] rounded-full">
                  <CalendarIcon color="blue" size={28} />
                </div>
                <div className="flex-grow h-18 bg-[#f4f8fc] flex flex-col justify-center text-lg text-blue text-center rounded-md pl-13 pr-4 ml-6">
                  <span className="text-sm text-body">Date</span>
                  <div className="flex-center">
                    <span>{message.time.substr(0, 10)}</span>&nbsp;
                    <span className="font-semibold">{message.time.substr(11, 8)}</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="grid md:grid-cols-3 xl:grid-cols-5 gap-x-3 gap-y-10 mt-15">
            {!!message.number && (
              <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-4">
                <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-gray-f8">
                  <CalendarIcon color="blue" />
                </div>
                <div className="text-sm mt-3">Date</div>
                <div className="text-blue-light text-xl">
                  <span>{message.time.substr(0, 10)}</span>&nbsp;
                  <span className="font-semibold">{message.time.substr(11, 8)}</span>
                </div>
              </div>
            )}
            <div className={classnames(
              'relative flex flex-col items-center text-center bg-primary-lighter text-primary rounded-md p-4',
              { '!bg-[#ecf5dd] !text-success': message.status === SMS_STATUS.DELIVERED || message.status === SMS_STATUS.SENT },
              { '!bg-[#fed8da] !text-danger-light': message.status.includes(SMS_STATUS.BLOCKED) },
            )}>
              <div className="absolute -top-6 w-11 h-11 flex-center rounded-full bg-inherit">
                <SmsIcon />
              </div>
              <div className="text-body text-sm mt-3">Message Status</div>
              <div className="font-semibold text-xl">
                {message.status === SMS_STATUS.BLOCKED_SENDER ? 'Blocked Sender' :
                  message.status === SMS_STATUS.BLOCKED_KEYWORD ? 'Blocked Keyword(s)' : message.status}
              </div>
            </div>
          </div>
        </Card>

        <Card
          header={(
            <div className="flex items-center">
              <span className="text-lg font-semibold">Message Content</span>
              <span className="text-blue ml-auto">
                {message.message.length} Characters | <span className="font-semibold">{message.sms_count} {message.sms_count > 1 ? 'Messages' : 'Message'}</span>
              </span>
            </div>
          )}
        >
          {message.message}
        </Card>

        {/*<Card*/}
        {/*  header={(*/}
        {/*    <Button*/}
        {/*      className="text-blue"*/}
        {/*      leftIcon={<PlusCircleIcon size={24} />}*/}
        {/*      onClick={() => setShowAddTagModal(true)}*/}
        {/*    >*/}
        {/*      Add a tag*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*>*/}
        {/*  <Chips>*/}
        {/*    {tags.map((item, i) => (*/}
        {/*      <Chip key={i} size="sm" active clickable={false} removable onRemove={() => onRemoveTag(i)}>*/}
        {/*        {item}*/}
        {/*      </Chip>*/}
        {/*    ))}*/}
        {/*  </Chips>*/}
        {/*</Card>*/}

        {!!Object.keys(message.meta).length && (<Card
          title="Extra Info"
          headerClass="text-blue-dark text-lg font-semibold px-1"
        >
          {Object.entries(message.meta).map((info, i) => (
            <TextField
              key={i}
              containerClass="mb-5"
              label={info[0]}
              value={info[1]}
              readonly
            />
          ))}
        </Card>)}
      </div>

      {showAddTagModal && (
        <AddTagModal
          tags={tags}
          onSubmit={onAddTags}
          onClose={() => setShowAddTagModal(false)}
        />
      )}
    </>
  );
};

export default MessageDetail;
