import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../components";
import { CloseCircleIcon } from "../../../../components/icons";
import AudioFileForm, { IAudioFileFormInitParams } from "../AudioFileForm";
import { AudioService, ToastService } from "../../../../services";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import { ROUTES } from "../../../../constants";

const AudioFileCreate = () => {
  const history = useHistory();
  const [createForm, setCreateForm] = useState<IAudioFileFormInitParams>({
    getFormData: () => null,
  });

  const [touched, setTouched] = useState<boolean>(false)
  const [audioTouched, setAudioTouched] = useState<boolean>(false)
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false)

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(bypass, touched, audioTouched, setShowConfirmCancel, history, ROUTES.SETTINGS.AUDIO_FILES.INDEX)
  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      AudioService.upload({
        name: data.name,
        type: data.type,
        file: data.file,
      }).then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> added new audio file.</>);
        onClose(true);
      }).catch((err) => {
        ToastService.showHttpError(err, 'Adding audio file failed');
      });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">Add Audio File</div>
        <CloseCircleIcon color="white" size={32} className="cursor-pointer" onClick={() => onClose()} />
      </div>
      <div className="h-0 flex-grow overflow-auto">
        <AudioFileForm
          setForm={setCreateForm}
          onClose={onClose}
          confirmCancel={showConfirmCancel}
          setTouched={setTouched}
          setAudioTouched={setAudioTouched}
        />
      </div>
      <div className="flex items-center px-7 py-3">
        <Button
          data-cy="add-audio-orange"
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={onCreate}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AudioFileCreate;
