import React, { FC, useState } from "react";
import { Button, Modal } from "../../../../components";
import BlockedNumberForm, { IBlockedNumberFormInitParams } from "../BlockedNumberForm";
import { BlockedNumberModel } from "../../../../utils/types";
import { ToastService } from "../../../../services";

interface IBlockedNumberCreateModalProps {
  onCreated: () => void;
  onClose: () => void;
}

const BlockedNumberCreateModal: FC<IBlockedNumberCreateModalProps> = ({
  onCreated,
  onClose,
}) => {
  const [createForm, setCreateForm] = useState<IBlockedNumberFormInitParams>({
    getFormData: () => null,
  });

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      BlockedNumberModel.create(data).then(res => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> blocked the number.</>);
        onCreated();
      }).catch(err => {
        ToastService.showHttpError(err, "Create failed.");
      })
    }
  };

  return (
    <Modal
      title="Mark number as spam"
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            className="text-sm rounded-full px-6 py-2"
            color="primary"
            onClick={onCreate}
          >
            Save
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <BlockedNumberForm onChange={setCreateForm} onClose={onClose} />
    </Modal>
  );
};

export default BlockedNumberCreateModal;
