import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WhatsAppCampaignStep3Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={215.268} baseHeight={159.239} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 215.268 159.2395">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_4521" data-name="Rectangle 4521" width="215.268" height="159.239" fill="none" />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect id="Rectangle_4514" data-name="Rectangle 4514" width="215.268" height="159.238" fill="none" />
          </clipPath>
          <clipPath id="clip-path-4">
            <rect id="Rectangle_4519" data-name="Rectangle 4519" width="34.229" height="34.563" fill="#3362cb" />
          </clipPath>
        </defs>
        <g id="Group_5765" data-name="Group 5765" clipPath="url(#clip-path)">
          <g id="Group_5759" data-name="Group 5759" transform="translate(0 0)">
            <g id="Group_5758" data-name="Group 5758" clipPath="url(#clip-path-2)">
              <path id="Path_3014" data-name="Path 3014" d="M110.528,607.169c-6.217.017-12.433.153-18.639.781-2.34.237-4.631.758-6.976.968-4.684.42-9.285,1.2-13.986,1.564-3.785.291-7.587.463-11.362.832a43.891,43.891,0,0,1-10.969-.305c-2.6-.423-5.211-.661-7.767-1.341a34.642,34.642,0,0,0-8.945-1.284c-4.248.028-8.385.031-12.451,1.374a12.583,12.583,0,0,0-4.258,2.235c-2.481,2.264-2.817,7.914-.34,10.262,4.172,3.952,13.077,5.5,18.925,5.576,6.409.08,12.634-.353,19.052-.607,5.964-.237,11.912-.573,17.886-.562,12.2.024,24.4,0,36.581-.742,11.442-.7,22.912-.458,34.379-.458,11.759,0,23.438.084,35.112,1.506,5.086.62,10.268.837,15.38,1.271a81.832,81.832,0,0,0,13.538-.367,34.847,34.847,0,0,0,11.112-2.71c2.753-1.2,7.662-4.919,8.507-7.778,2.367-8.015-33.363-7.822-38.848-7.8-6.137.02-12.323.2-18.455-.009a47.526,47.526,0,0,1-7.638-1.051,65.867,65.867,0,0,0-7.577-.584c-10.6-.878-21.233-.75-31.871-.75-3.462,0-6.925-.027-10.389-.018" transform="translate(-10.152 -469.162)" fill="#f7f7f7" />
            </g>
          </g>
          <path id="Path_3015" data-name="Path 3015" d="M855.526,464.26l-1.035,19.248-1.932,25.984-2.89.206-3.511-23.6-4.858-19.97Z" transform="translate(-650.077 -358.736)" fill="#bcbec0" />
          <path id="Path_3016" data-name="Path 3016" d="M878.2,663.459l3.236,1.341,1.452-1.674-1.674-1.452-3.126.222Z" transform="translate(-678.502 -511.28)" fill="#305399" />
          <path id="Path_3017" data-name="Path 3017" d="M804.267,453.88l-.634,11.551,1.882,34.347,2.894.145,4.337-22.527,3.825-24.7Z" transform="translate(-620.972 -349.798)" fill="#bcbec0" />
          <path id="Path_3018" data-name="Path 3018" d="M808.345,659.324l-3.208,1.408-1.487-.965,1.643-1.486,3.129.156Z" transform="translate(-620.985 -508.657)" fill="#305399" />
          <g id="Group_5764" data-name="Group 5764" transform="translate(0 0)">
            <g id="Group_5763" data-name="Group 5763" clipPath="url(#clip-path-2)">
              <path id="Path_3019" data-name="Path 3019" d="M812.295,299.823l-9.062-.595s-5,.743-7.911,12.8c-1.622,6.71-1.767,17.182-1.577,25.154.26,1.128,1.7,6.054,6.9,4.994,8.93-1.821,13.655-3.468,17.6-2.9,3.365.587,2.893-3.992,2.383-7.481l-1.776-10.786Z" transform="translate(-613.28 -231.215)" fill="#587bc9" />
              <path id="Path_3020" data-name="Path 3020" d="M835.564,251.068a16.947,16.947,0,0,0-6.912-.215,1.9,1.9,0,0,0-1.275.756,1.983,1.983,0,0,0-.189.558c-.486,2.159-1.085,5.355.4,7.265a3.526,3.526,0,0,0,1.053.873,5.005,5.005,0,0,0,1.71.59,9.023,9.023,0,0,1-.446,1.572c.553.764,2.986.758,3.4-.014q0-.979-.005-1.957a4.822,4.822,0,0,0,.831-.476,5.5,5.5,0,0,0,2-2.969c.38-1.282.939-2.508,1.281-3.812a1.818,1.818,0,0,0-.048-1.429,1.936,1.936,0,0,0-1.37-.663l-.436-.078" transform="translate(-638.755 -193.62)" fill="#b97262" />
              <path id="Path_3021" data-name="Path 3021" d="M818.014,234.94a7.1,7.1,0,0,1,3.828-1.117c3.2.1,4.472,2.4,4.764,5.329.834-1.155,2.428-2.63,2.468-4.074a6.745,6.745,0,0,0-.9-3.772c-.657-.927-2.258-1.385-3.22-1.9l-2.721-1.451a1.344,1.344,0,0,0-1.338-.116l-6.212,1.882c-.4.121-.85.291-.982.685a1.319,1.319,0,0,0,.048.746,31,31,0,0,1,.943,3.264c.154.634.671,3.9,1.787,3.471a14.742,14.742,0,0,0,.567-1.822,2.6,2.6,0,0,1,.966-1.126" transform="translate(-628.724 -175.959)" fill="#3b455a" />
              <path id="Path_3022" data-name="Path 3022" d="M783.024,299.228l-3.293,1.152-11.653,13.262-.551,4.235,6.219,9.373,1.427-3.589-2.758-7,3.8-2.916,4.735-11.96Z" transform="translate(-593.072 -231.216)" fill="#587bc9" />
              <path id="Path_3023" data-name="Path 3023" d="M867.3,215.959,873.227,209l-2.21-18.1,2.676-1.309,4.467,18.268-.945,4.638-6.557,9.879Z" transform="translate(-670.168 -146.501)" fill="#587bc9" />
              <path id="Path_3024" data-name="Path 3024" d="M881.511,176.833l-.069-1.662-2.019-2.454-1.262,1.224.951,2.215-.511.8.807.909Z" transform="translate(-678.56 -133.459)" fill="#b97262" />
              <path id="Path_3025" data-name="Path 3025" d="M177,273.36l2.991-2.091-.426-1.907-3.032,1.046-1.464.954v1.058Z" transform="translate(-135.276 -208.137)" fill="#efbfa5" />
              <path id="Path_3026" data-name="Path 3026" d="M51.137,640.184a1.189,1.189,0,0,0-1.076-.461,1.216,1.216,0,0,0-.9.681,2.31,2.31,0,0,0-.155.775c-.113,1.364-.1,4.6,1.715,5.07.764.2,1.4-1.024,1.6-1.71a5.571,5.571,0,0,0-1.18-4.355" transform="translate(-37.834 -494.308)" fill="#305399" />
              <path id="Path_3027" data-name="Path 3027" d="M42.746,551.234l-.1,8.322c.111,3.7-.466,7.414-.356,11.111a9.371,9.371,0,0,1,.033,2.589c-.21.824-.879.97-1.567.552-.921-.559-.773-1.865-.926-2.818-.421-2.618-.916-5.223-1.356-7.838-.216-1.282.229-3.078.132-4.375L37.267,551.8Z" transform="translate(-28.796 -425.942)" fill="#efbfa5" />
              <path id="Path_3028" data-name="Path 3028" d="M19.537,453.5l3.622,16.09.769,15.195,5.743-.129,1.562-42.5-11.134.2Z" transform="translate(-15.097 -341.654)" fill="#3b455a" />
              <path id="Path_3029" data-name="Path 3029" d="M87.553,636.048a1.168,1.168,0,0,0-1.092-.4,1.241,1.241,0,0,0-.872.735,2.339,2.339,0,0,0-.122.784c-.054,1.372.095,4.614,1.926,4.973a1.376,1.376,0,0,0,1.55-1.128,7.131,7.131,0,0,0-1.391-4.967" transform="translate(-66.035 -491.152)" fill="#305399" />
              <path id="Path_3030" data-name="Path 3030" d="M81.353,556.374l.487,8.309c.04,2.309.348,4.6.587,6.894.222,2.13-.123,4.349.99,6.287.349.608,1.055,1.012,1.548.274.38-.569.154-1.518.227-2.163.3-2.635.668-5.26.985-7.893.155-1.29-.374-3.064-.338-4.364l1.014-7.03Z" transform="translate(-62.862 -429.914)" fill="#efbfa5" />
              <path id="Path_3031" data-name="Path 3031" d="M73.563,453.565l.468,15.619-2.451,15.794-5.842.175.258-17.094L62.9,453.884l-1.038-9.279,9.27-1.094Z" transform="translate(-47.804 -342.704)" fill="#3b455a" />
              <path id="Path_3032" data-name="Path 3032" d="M27.427,302.07l-9.987-.238L11.649,312.6l2.058,10.087.367,12.071,14.246.194,4.687-.761-1.245-12.9.652-15.739Z" transform="translate(-9.001 -233.227)" fill="#bcbec0" />
              <path id="Path_3033" data-name="Path 3033" d="M8.107,305.1,0,317.447l1.219,6.879,12.556,8.7,2.363-2.066L7.743,320.347l.326-6.706Z" transform="translate(0 -235.752)" fill="#bcbec0" />
              <path id="Path_3034" data-name="Path 3034" d="M78.4,292.92l9.6,3.46,5.553-1.367,9.6-12.59-3.342-1.456-10.078,8.655-9.122-2.733Z" transform="translate(-60.583 -217.105)" fill="#bcbec0" />
              <path id="Path_3035" data-name="Path 3035" d="M50.124,302.012l3.1,4.379,1.457-.119,2.428-4.441Z" transform="translate(-38.731 -233.227)" fill="#7db5db" />
              <path id="Path_3036" data-name="Path 3036" d="M60.74,416.542l-.054,1.684,2.973,1.082,1.714-1.3V416.05l-1.654-.092-.052-.431-.542-.348Z" transform="translate(-46.892 -320.81)" fill="#efbfa5" />
              <path id="Path_3037" data-name="Path 3037" d="M59.755,257.735a3.133,3.133,0,0,1,2.215-.681c.39,0,.521.1.722.063a.992.992,0,0,0,.344-.152,2.217,2.217,0,0,0,.7-1.058A3.467,3.467,0,0,1,65.21,258.6a10.045,10.045,0,0,1-.332,4.426,5.472,5.472,0,0,1-2.93,3.35c-.08.031-.16.059-.239.085a3.529,3.529,0,0,1-1.667.149l-1.733-.826q-.129-.11-.251-.231c-.569-2.466.048-6.362,1.7-7.815" transform="translate(-44.702 -197.74)" fill="#efbfa5" />
              <path id="Path_3038" data-name="Path 3038" d="M2.722,276.652A3.9,3.9,0,0,0,3.8,278.683q.065-.4.136-.791-.071.392-.136.791a4.9,4.9,0,0,0,1.211.914,7.877,7.877,0,0,0,3.737.8,14.254,14.254,0,0,1,3.08.1,2.861,2.861,0,0,1,2.257,1.874c.14.551.095,1.243.571,1.553a1.47,1.47,0,0,0,.772.166,2.887,2.887,0,0,0,2.287-.6c.847-.935.352-2.811,1.5-3.343a4.162,4.162,0,0,1,1.087-.193c1.548-.262,2.305-2.014,2.157-3.621a3.954,3.954,0,0,0-.21-.967c-.64-1.785-2.122-3.109-3.245-4.637-2.192-2.983-3.674-6.967-2.326-10.5a9.6,9.6,0,0,0,.358-1.008,2.492,2.492,0,0,0,.085-.575c.02-1.053-.764-1.919-1.27-2.843q-.071-.129-.134-.272a4.93,4.93,0,0,1-.258-.771c-.01-.042-.021-.084-.03-.126a6.128,6.128,0,0,1-1.154-6.583q.087-.183.185-.359-.1.175-.185.359a6.128,6.128,0,0,0,1.154,6.583c-.569-2.466.048-6.362,1.7-7.815a3.133,3.133,0,0,1,2.215-.681c.39,0,.521.1.722.063a.993.993,0,0,0,.344-.152,2.214,2.214,0,0,0,.7-1.059,3.468,3.468,0,0,1,1.477,2.692,10.053,10.053,0,0,1-.332,4.426,5.473,5.473,0,0,1-2.931,3.35c-.08.031-.16.059-.239.085a1.947,1.947,0,0,0,.267.37,4.828,4.828,0,0,0,3.07,1.164.823.823,0,0,0,.4-.015.577.577,0,0,0,.317-.546,2.339,2.339,0,0,0-.14-.653c-.645-2.246.5-4.811.688-7.075s-.276-4.718-2.09-6.246a4.859,4.859,0,0,0-.892.343c-.029-.038-.058-.075-.088-.111-2.625-3.152-6.332-.457-8.326,1.95A17.434,17.434,0,0,0,8.638,251.5a27.164,27.164,0,0,0-.535,8.223,40.264,40.264,0,0,0,.995,4.161,6.112,6.112,0,0,1,.082,2.572c-.38,1.791-1.8,3.1-3.181,4.347q-.189.484-.376.983T5.251,272.8q-.119.33-.23.672.111-.341.23-.672.184-.513.372-1.011T6,270.8c-.191.172-.382.342-.569.513-1.541,1.4-3.057,3.281-2.707,5.335m13.8-31.2a6.2,6.2,0,0,1,.627-.364,6.2,6.2,0,0,0-.627.364" transform="translate(-2.064 -186.484)" fill="#2f3849" />
              <rect id="Rectangle_4515" data-name="Rectangle 4515" width="42.115" height="32.873" transform="translate(156.373 2.907)" fill="#407bff" />
              <path id="Path_3039" data-name="Path 3039" d="M679.424,88.5l.2,27.7,40.323-.29a5.722,5.722,0,0,0,5.48-5.942l-.157-21.8-22.82,14.457Z" transform="translate(-524.995 -68.129)" fill="#3362cb" />
              <rect id="Rectangle_4516" data-name="Rectangle 4516" width="31.175" height="0.671" transform="translate(163.993 9.024)" fill="#f7f7f7" />
              <rect id="Rectangle_4517" data-name="Rectangle 4517" width="35.476" height="0.697" transform="translate(159.693 14.256)" fill="#f7f7f7" />
              <rect id="Rectangle_4518" data-name="Rectangle 4518" width="35.476" height="0.697" transform="translate(159.693 19.501)" fill="#f7f7f7" />
              <path id="Path_3040" data-name="Path 3040" d="M164.692,40.605l-5.808,12.1-.015-17.124L215.858,0Z" transform="translate(-122.759 -0.001)" fill="#305399" />
              <g id="Group_5762" data-name="Group 5762" transform="translate(36.124 17.954)" opacity="0.13">
                <g id="Group_5761" data-name="Group 5761">
                  <g id="Group_5760" data-name="Group 5760" clipPath="url(#clip-path-4)">
                    <path id="Path_3041" data-name="Path 3041" d="M158.932,96.748l34.229-17.759L164.578,101.98l-5.6,11.572Z" transform="translate(-158.932 -78.989)" fill="#3362cb" />
                  </g>
                </g>
              </g>
              <path id="Path_3042" data-name="Path 3042" d="M158.936,53.307l5.633-11.916L215.785.776Z" transform="translate(-122.811 -0.6)" fill="#3b455a" />
              <path id="Path_3043" data-name="Path 3043" d="M407.214.176,407.34,0l-.664.514Z" transform="translate(-314.241 0)" fill="#707f78" />
              <path id="Path_3044" data-name="Path 3044" d="M234.783.776l-.538.338-50.663,40.2-.058.035,16.329,14.667Z" transform="translate(-141.809 -0.6)" fill="#407bff" />
              <path id="Path_3045" data-name="Path 3045" d="M168.217,0l-71,23.13,13.932,12.514L167.553.515Z" transform="translate(-75.118 -0.001)" fill="#407bff" />
              <path id="Path_3046" data-name="Path 3046" d="M284.164,648.93l-.228,1.775,3.322,1.554,2-.869-1.547-2-3.322-2.231Z" transform="translate(-219.399 -500.061)" fill="#305399" />
              <path id="Path_3047" data-name="Path 3047" d="M282.676,469.127l2.562-22.637-3.082-25.342.693-5.391,13.767-.523.5,8.774L294.8,446.524l-8.695,23.76Z" transform="translate(-218.023 -320.853)" fill="#bcbec0" />
              <path id="Path_3048" data-name="Path 3048" d="M351.46,305.037l6.925,1.886,8.009,14.191-3.794,3.513L357.26,318.3Z" transform="translate(-271.575 -235.704)" fill="#7db5db" />
              <path id="Path_3049" data-name="Path 3049" d="M342.8,418.928l.036,22.844-4.6,29.408-3.31-.033-1.819-27.2-4.258-27.22Z" transform="translate(-254.103 -322.011)" fill="#bcbec0" />
              <path id="Path_3050" data-name="Path 3050" d="M355.608,656.192l3.561,1.148,1.807-1.094-1.772-1.807-3.579-.036Z" transform="translate(-274.78 -505.661)" fill="#305399" />
              <path id="Path_3051" data-name="Path 3051" d="M275.813,339.876c.032-.99.087-1.816.095-2.111.071-2.655.308-5.305.64-7.942.3-2.363.674-8.229,1.078-10.577a50.178,50.178,0,0,1,2.338-8.577c2.09-4.472,1.444-4.885,10.627-6.213,4.024-.582,4.976,1.926,8.266,4.81.325.284.307,1.978.382,2.427.133.79.275,1.578.438,2.363q.192.925.428,1.842h0s2.762,12.57,4.589,15.239,5.6,6.4-.712,8.68a22.578,22.578,0,0,1-3.409.89s-5.162-.574-7.817-.554a45.108,45.108,0,0,0-7.3.68,15.722,15.722,0,0,0-4.686,1.41c-1.372.717-2.362,1.771-3.754,2.513a.725.725,0,0,1-.426.122c-.8-.111-.837-2.92-.771-5" transform="translate(-213.105 -235.19)" fill="#7db5db" />
              <path id="Path_3052" data-name="Path 3052" d="M324.207,272.858a3.776,3.776,0,0,1-2.7-.136.6.6,0,0,1-.382-.558,15.472,15.472,0,0,1,.167-2.071c-.856-.3-1.844-.752-1.993-1.647a2.562,2.562,0,0,1,.05-.851l.6-3.491a3.12,3.12,0,0,1,.363-1.161,2.62,2.62,0,0,1,1.836-.992,6.376,6.376,0,0,1,5.163,1.258,2.8,2.8,0,0,1,.872,1.15,3.788,3.788,0,0,1-.127,2.212l-.528,2.01c-.2.771-.494,1.647-1.237,1.936-.394.153-.86.109-1.217.336-.629.4-.545,1.336-.872,2.007" transform="translate(-246.711 -202.331)" fill="#b97262" />
              <path id="Path_3053" data-name="Path 3053" d="M321.793,241.512a7.539,7.539,0,0,1,.581-.834,14.733,14.733,0,0,0,1.4-1.69,1.906,1.906,0,0,0,.057-2.072,2.274,2.274,0,0,0-1.209-.7,8.79,8.79,0,0,0-4.623-.085c-1.44.366-2.728.973-4.178.432a4.882,4.882,0,0,0-3.769.206c-1.54.55-3.608.679-5,1.455a4.113,4.113,0,0,0-1.409,1.389,6.2,6.2,0,0,0-.017,6.554,8.841,8.841,0,0,0,.964,1.216,6.642,6.642,0,0,0,2.6,2.053c.024-.894-.791-3.714-.1-4.447,1.09-1.163,3.554-.757,4.906-.471,1.475.312,2.789,1.357,4.251,1.58a5.513,5.513,0,0,0,3.289-.9c1.1-.672,1.618-2.629,2.264-3.69" transform="translate(-233.897 -182.254)" fill="#3b455a" />
              <path id="Path_3054" data-name="Path 3054" d="M266.157,312.305l-5.73,5.02-6.391,13.432,8.073,9.6,1.389-5.371Z" transform="translate(-196.295 -241.32)" fill="#7db5db" />
              <path id="Path_3055" data-name="Path 3055" d="M239.339,334.42l-70.825,5.332,37.075,19.412Z" transform="translate(-130.212 -258.408)" fill="#3362cb" />
              <path id="Path_3056" data-name="Path 3056" d="M158.745,345.722l3.434,45.609,66.392-5c5.022-.378,8.765-5.035,8.361-10.4l-2.7-35.891-35.971,26.372Z" transform="translate(-122.663 -262.75)" fill="#3362cb" />
              <path id="Path_3057" data-name="Path 3057" d="M424.084,335.377a12.293,12.293,0,0,0,0,1.854,12.157,12.157,0,0,0,1.659,5.29l11.049-8.1Z" transform="translate(-327.665 -258.409)" fill="#292949" />
              <path id="Path_3058" data-name="Path 3058" d="M447.919,352.828a12.223,12.223,0,0,0,2.978-.6l-.918-12.187-12.021,8.813a12.184,12.184,0,0,0,9.96,3.975" transform="translate(-338.413 -262.751)" fill="#292949" />
              <path id="Path_3059" data-name="Path 3059" d="M436.81,294.533A12.205,12.205,0,1,1,449.9,305.787a12.205,12.205,0,0,1-13.087-11.255" transform="translate(-337.499 -217.448)" fill="#f6a734" />
              <path id="Path_3060" data-name="Path 3060" d="M483.373,322.767l-2.858.17-.561-9.408-1.786.106L478,310.778l4.645-.277Z" transform="translate(-369.351 -239.926)" fill="#fff" />
              <path id="Path_3061" data-name="Path 3061" d="M674.962,647.928a1.186,1.186,0,0,1,1.073-.46,1.214,1.214,0,0,1,.9.679,2.3,2.3,0,0,1,.155.773c-.034-.408.248,4.556-1.711,5.059a1.35,1.35,0,0,1-1.6-1.03,7.155,7.155,0,0,1,1.177-5.021" transform="translate(-520.564 -500.292)" fill="#305399" />
              <path id="Path_3062" data-name="Path 3062" d="M676.446,559.175l.1,8.3c-.11,3.688.466,7.4.355,11.086a9.351,9.351,0,0,0-.033,2.583c.209.822.877.968,1.563.551.919-.558.771-1.861.924-2.811.42-2.612.914-5.212,1.353-7.821.215-1.279-.229-3.071-.132-4.366l1.341-6.959Z" transform="translate(-522.694 -432.078)" fill="#efbfa5" />
              <path id="Path_3063" data-name="Path 3063" d="M678.513,461.657,674.9,477.712l-.768,15.161-5.731-.129-1.558-42.408,11.109.2Z" transform="translate(-515.272 -347.978)" fill="#3362cb" />
              <path id="Path_3064" data-name="Path 3064" d="M638.29,646.776a1.164,1.164,0,0,1,1.09-.4,1.238,1.238,0,0,1,.87.733,2.347,2.347,0,0,1,.121.783c-.017-.424-.024,4.59-1.922,4.962a1.372,1.372,0,0,1-1.547-1.125,7.114,7.114,0,0,1,1.388-4.956" transform="translate(-492.089 -499.443)" fill="#305399" />
              <path id="Path_3065" data-name="Path 3065" d="M637.855,564.3l-.486,8.29c-.04,2.3-.347,4.59-.585,6.878-.222,2.126.123,4.34-.988,6.273-.348.606-1.052,1.01-1.545.273-.379-.568-.154-1.515-.227-2.159-.3-2.629-.667-5.248-.983-7.875-.155-1.288.373-3.057.338-4.355l-1.012-7.014Z" transform="translate(-488.634 -436.041)" fill="#efbfa5" />
              <path id="Path_3066" data-name="Path 3066" d="M623.018,461.723l-.466,15.584L625,493.067l5.829.175-.257-17.056,3.085-14.144,1.036-9.259-9.25-1.091Z" transform="translate(-481.049 -349.025)" fill="#3362cb" />
              <path id="Path_3067" data-name="Path 3067" d="M637.866,310.563l9.965-.238,5.778,10.746-2.054,10.065-.366,12.045-14.215.194-4.677-.759,1.242-12.869-.712-17.191Z" transform="translate(-488.58 -239.79)" fill="#d3e5ef" />
              <path id="Path_3068" data-name="Path 3068" d="M674.854,313.585l8.089,12.321-1.216,6.864L669.2,341.449l-2.358-2.061,8.376-10.588-.325-6.691Z" transform="translate(-515.272 -242.309)" fill="#d3e5ef" />
              <path id="Path_3069" data-name="Path 3069" d="M570.526,324.651l-5.191,10.377-5.6,1.112-13.944-6.089,2.405-2.728,12.717,2.328,5.07-9.52Z" transform="translate(-421.738 -247.367)" fill="#d3e5ef" />
              <path id="Path_3070" data-name="Path 3070" d="M657.52,265.787c1.5-4.368,7.268-2.731,8.457,1.158.775,2.537-1.184,5.829-3.812,6.557a2.536,2.536,0,0,0-.02.272,8.54,8.54,0,0,1-.112,1.216,13.242,13.242,0,0,1-2.579-.341.348.348,0,0,1-.238-.117c-.093-.144.058-.57.075-.73l.06-.568a4.537,4.537,0,0,1-2.343-3.353,8.424,8.424,0,0,1,.515-4.093" transform="translate(-507.61 -203.363)" fill="#efbfa5" />
              <path id="Path_3071" data-name="Path 3071" d="M640.881,255.17a2.289,2.289,0,0,1,.52,1.172,1.83,1.83,0,0,1,.045.759c-.214.991-1.2.94-1.958,1.259a5.073,5.073,0,0,0-2.272,1.955c-.822,1.309-.452,2.992-.119,4.435.117.509.354,1.265-.041,1.719a1.693,1.693,0,0,1-1.807.153c-.438-.217-.8-.617-1.281-.7-.51-.085-1.2.139-1.436-.329a3.161,3.161,0,0,0-.156-.408c-.282-.4-.982.069-1.379-.215a.775.775,0,0,1-.16-.841,2,2,0,0,0,.164-.893,2.938,2.938,0,0,0-.35-.7,1.37,1.37,0,0,1,.189-1.456c.628-.726,1.843-.588,2.515-1.272a2.959,2.959,0,0,0,.617-1.355,2.6,2.6,0,0,1,.662-1.328c.463-.407,1.131-.338,1.587-.82a9.372,9.372,0,0,1,.748-.9,6.035,6.035,0,0,1,1.666-.741,2.167,2.167,0,0,1,2.244.5" transform="translate(-487.209 -196.693)" fill="#2f3849" />
              <path id="Path_3072" data-name="Path 3072" d="M671.161,253.283c.027,1.438-.687,2.992-.025,4.257s2.334,1.507,3.754,1.574c.234.011,3.23.27,3.243.213a9.756,9.756,0,0,1,.98-2.9,3.2,3.2,0,0,1,2.4-1.67.616.616,0,0,1-.4-.7,4.747,4.747,0,0,1,.277-.854,2.012,2.012,0,0,0-.525-2.022c-1.06-.988-2.982-.578-3.891-1.712-.564-.7-.5-1.724-.792-2.583-.571-1.661-2.389-2.009-3.749-2.759a6.511,6.511,0,0,0-4.442-.95,3.375,3.375,0,0,0-2.787,4.4c1.016,2.523,5.075,2.3,5.836,4.866a3.162,3.162,0,0,1,.122.846" transform="translate(-513.842 -187.836)" fill="#2f3849" />
              <path id="Path_3073" data-name="Path 3073" d="M663.985,310.505l-3.091,4.369-1.453-.119-2.422-4.431Z" transform="translate(-507.682 -239.79)" fill="#3b455a" />
              <path id="Path_3074" data-name="Path 3074" d="M537.077,349.235a.875.875,0,0,1-.633-.262l-7.041-7.217-4.373,5.6a.777.777,0,0,1-.623.309,1.02,1.02,0,0,1-.91-.643l-10.368-27.913a.8.8,0,0,1,.2-.9.767.767,0,0,1,.61-.3.9.9,0,0,1,.331.067l27.647,11.056a.946.946,0,0,1,.611.751.934.934,0,0,1-.395.871l-5.6,4.159,7.026,7.2a.918.918,0,0,1,0,1.28l-5.825,5.682a.933.933,0,0,1-.648.261m-7.766-9.755a.937.937,0,0,1,.661.265l7.129,7.308,4.528-4.417-7.115-7.294a.879.879,0,0,1-.258-.691.99.99,0,0,1,.409-.708l5.14-3.815-24.257-9.7,9.1,24.491,4.016-5.141a.811.811,0,0,1,.65-.3" transform="translate(-396.451 -245.647)" fill="#fff" />
              <path id="Path_3075" data-name="Path 3075" d="M542.637,340.613l-3.4-3.486-3.352-3.437,2.332-1.731,2.959-2.2a1.254,1.254,0,0,0-.293-2.211l-9.4-3.758-14.851-5.939-2.02-.808-1.378-.551a1.228,1.228,0,0,0-.458-.091,1.1,1.1,0,0,0-.862.413,1.142,1.142,0,0,0-.264,1.247l.374,1.006,3.151,8.482,5.569,14.994.644,1.732.631,1.7a1.357,1.357,0,0,0,1.229.866,1.113,1.113,0,0,0,.892-.44l2.226-2.85,1.907-2.441,2.636,2.7,3.18,3.26.952.976a1.214,1.214,0,0,0,.877.365,1.278,1.278,0,0,0,.886-.358l2.867-2.8,2.957-2.885a1.248,1.248,0,0,0,.008-1.762m-.484,1.274-5.824,5.682a.589.589,0,0,1-.41.165.538.538,0,0,1-.389-.16l-.952-.976-6.361-6.521-4.613,5.906a.437.437,0,0,1-.355.178.674.674,0,0,1-.59-.421l-1.274-3.431-5.569-14.994-3.525-9.489a.462.462,0,0,1,.129-.547.429.429,0,0,1,.358-.195.551.551,0,0,1,.2.042l3.4,1.359,14.851,5.939,9.4,3.758a.574.574,0,0,1,.14,1.031l-5.917,4.392.5.509,6.8,6.973a.574.574,0,0,1,0,.8" transform="translate(-395.294 -244.488)" fill="#fff" />
              <path id="Path_3076" data-name="Path 3076" d="M541.19,341.631a.574.574,0,0,1,.11-.887l5.63-4.178-2.4-.961-14.851-5.939-8.282-3.312.849,2.285,5.569,14.994,3.159,8.5,4.391-5.622a.473.473,0,0,1,.381-.167.6.6,0,0,1,.417.162l.952.976,6.415,6.576,5.016-4.893-.551-.565-6.8-6.973m2.35,11.467-1.416-1.452L537.6,347.01l-.145-.149-.807-.828a1.265,1.265,0,0,0-.905-.368,1.15,1.15,0,0,0-.918.429l-2.2,2.811-1.444,1.849-1.094-2.943-1.64-4.417L522.881,328.4l-.314-.844,6.854,2.741,7.425,2.969,7.425,2.969,1.231.492.04.016-1.786,1.325-2.864,2.126a1.33,1.33,0,0,0-.546.955,1.217,1.217,0,0,0,.354.956l3.4,3.486,3.4,3.486.076.077Z" transform="translate(-402.883 -252.175)" fill="#fff" />
              <path id="Path_3077" data-name="Path 3077" d="M517.13,321.99,527.5,349.9l4.971-6.363,7.754,7.949,2.912-2.841,2.912-2.841-7.754-7.949,6.484-4.812Z" transform="translate(-399.589 -248.804)" fill="#3b455a" />
              <path id="Path_3078" data-name="Path 3078" d="M638.959,427.245l.054,1.681-2.967,1.08-1.71-1.3v-1.957l1.651-.092.052-.43.541-.348Z" transform="translate(-490.155 -329.083)" fill="#efbfa5" />
              <path id="Path_3079" data-name="Path 3079" d="M259.14,345.082l1.678-2.241,3.406-.662,1.834,1.831-3.248,3.381Z" transform="translate(-200.239 -264.404)" fill="#b97262" />
              <path id="Path_3080" data-name="Path 3080" d="M378.419,330.83l1.678-2.241,3.406-.662,1.834,1.831-3.248,3.381Z" transform="translate(-292.407 -253.392)" fill="#b97262" />
            </g>
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
