import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import Users from "./index";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";

const UsersRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.USERS.INDEX} component={Users} />
    <Route exact path={ROUTES.SETTINGS.USERS.CREATE} component={UserCreate} />
    <Route exact path={ROUTES.SETTINGS.USERS.EDIT} component={UserEdit} />
  </Switch>
);

export default UsersRouting;
