import React, {FC, useEffect, useState} from "react";
import {Button, Chip, Chips, Dropdown} from "../../../components";
import {CloseCircleIcon} from "../../../components/icons";
import {CALL_DIRECTION} from "../../../utils/enums";
import {FilterModel} from "../../../utils/types";

interface ICallDirectionFilterProps {
  category?: string;
  opened?: boolean;
  value: FilterModel,
  onChange?: (value: FilterModel) => void;
  onDelete?: (value: FilterModel) => void;
}

const options = [
  { label: 'Inbound', value: CALL_DIRECTION.INBOUND },
  { label: 'Outbound', value: CALL_DIRECTION.OUTBOUND },
];

export const CallDirectionFilter: FC<ICallDirectionFilterProps> = ({
  category = 'direction',
  opened,
  value,
  onChange = () => {},
  onDelete = () => {},
}) => {
  const [selectedDirection, setSelectedDirection] = useState<CALL_DIRECTION>();
  const [dropdownOpened, setDropdownOpened] = useState(opened);

  useEffect(() => {
    setDropdownOpened(opened);
  }, [opened]);

  useEffect(() => {
    setSelectedDirection(value.value);
  }, [value]);

  const onSave = () => {
    if (!selectedDirection)
      return;

    onChange({
      category,
      text: selectedDirection === CALL_DIRECTION.INBOUND ? 'Calls Received' : 'Calls Made',
      value: selectedDirection,
    });
    setDropdownOpened(false);
  };

  return (
    <div className="flex items-center mb-2">
      <Dropdown
        text={value?.text || 'Direction'}
        buttonProps={{
          className: 'text-xs font-semibold justify-between rounded-3xl min-w-38 px-4 shadow-md',
          color: 'primary',
        }}
        dropdownClass="w-80 max-h-80 rounded-bl-md rounded-br-md"
        dropdownOpened={dropdownOpened}
        onDropdownOpened={() => setDropdownOpened(true)}
        onDropdownClosed={() => setDropdownOpened(false)}
      >
        <Chips className="p-2">
          {options.map((item, i) => (
            <Chip
              key={i}
              active={selectedDirection === item.value}
              onClick={() => setSelectedDirection(item.value)}
            >
              {item.label}
            </Chip>
          ))}
        </Chips>

        <div className="px-3 py-2">
          <Button
            color="primary"
            className="shadow-md rounded-full text-sm px-6 ml-auto"
            disabled={!selectedDirection}
            onClick={onSave}
          >
            Filter
          </Button>
        </div>
      </Dropdown>

      <CloseCircleIcon
        className="ml-1 cursor-pointer"
        color="#DEDEDE"
        size={24}
        onClick={() => onDelete(value)}
      />
    </div>
  );
};
