import {HttpService} from "./http.service";
import {SmsRecordModel} from "../utils/types";
import {formatPaginatedResult, trimObject, filterObjectByKey} from "../utils/helpers";
import {ISearchMessagesRequest, ISendSmsRequest, ISendSmsResponse} from "../utils/dto";

export class SmsService {

  static search(query?: ISearchMessagesRequest, showSpinner = true) {
    return HttpService.get('/sms-records/', trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(SmsRecordModel, res));
  }

  static exportSms(query?: ISearchMessagesRequest, showSpinner = true) {
    const exclude_list = ["page", "page_size"]
    query = filterObjectByKey(query, ([key]) => exclude_list.indexOf(key) < 0)
    return HttpService.get('/sms-records/export/', trimObject(query), {}, showSpinner);
  }

  static sendSms(data: ISendSmsRequest, showSpinner = true): Promise<ISendSmsResponse> {
    return HttpService.post('/send-sms/', trimObject(data), {}, showSpinner);
  }
}
