import React, {FC, memo, useEffect, useMemo, useState} from "react";
import WaveSurferJs from "wavesurfer.js";
import classnames from "classnames";
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/selectors';

export interface WaveSurferOptions {
  audioRate?: number;
  audioContext?: object;
  audioScriptProcessor?: object;
  autoCenter?: boolean;
  backend?: string;
  backgroundColor?: string;
  barGap?: number;
  barHeight?: number;
  barMinHeight?: number;
  barRadius?: number;
  barWidth?: number;
  closeAudioContext?: boolean;
  container?: string | HTMLElement;
  cursorColor?: string;
  cursorWidth?: number;
  drawingContextAttributes?: object;
  fillParent?: boolean;
  forceDecode?: boolean;
  height?: number;
  hideScrollbar?: boolean;
  hideCursor?: boolean;
  interact?: boolean;
  loopSelection?: boolean;
  maxCanvasWidth?: number;
  mediaControls?: boolean;
  mediaType?: string;
  minPxPerSec?: number;
  normalize?: boolean;
  partialRender?: boolean;
  pixelRatio?: number;
  plugins?: any[];
  progressColor?: string;
  regionsMinLength?: number;
  removeMediaElementOnDestroy?: boolean;
  renderer?: any;
  responsive?: boolean;
  scrollParent?: boolean;
  skipLength?: number;
  splitChannels?: boolean;
  splitChannelsOptions?: {
    overlay?: boolean;
    relativeNormalization?: boolean;
    filterChannels?: number[];
    channelColors?: {
      [channel: number]: {
        progressColor?: string;
        waveColor?: string;
      };
    };
  };
  waveColor?: string;
  xhr?: any;
}

export interface IWaveSurferProps {
  className?: string;
  file?: Blob | File;
  src: string;
  options?: WaveSurferOptions;
  autoPlay?: boolean;
  onInit?: (wavesurfer: any) => void;
  onReady?: (wavesurfer: any) => void;
  onProcess?: (duration: number) => void;
  onSeek?: (duration: number) => void;
  onError?: (error: string) => void;
}

const defaultOptions: WaveSurferOptions = {
  barGap: 0,
  barWidth: 1.5,
  waveColor: '#D9D9D9',
  responsive: true,
  normalize: true,
  fillParent: false,
  cursorWidth: 0,
  partialRender: true,
};

export const WaveSurfer: FC<IWaveSurferProps> = memo(({
  className = '',
  file,
  src,
  options,
  autoPlay = false,
  onInit,
  onReady,
  onProcess,
  onSeek,
  onError,
}) => {
  const [surfer, setSurfer] = useState<any>();
  const token = useSelector(getToken);

  const id = useMemo(() => `waveform-${Math.floor(Math.random() * 100000)}`, []);

  useEffect(() => {
    const wavesurfer = WaveSurferJs.create({
      container: `#${id}`,
      ...defaultOptions,
      ...options,
      // xhr: {
      //   requestHeaders: [
      //     {
      //       key: 'Authorization',
      //       value: `Bearer ${token}`
      //     }
      //   ]
      // }
    });

    setSurfer(wavesurfer);
    if (onInit) {
      onInit(wavesurfer);
    }

    return () => wavesurfer.destroy();
  }, [id, options, onInit]);

  useEffect(() => {
    if (!surfer)
      return;

    if (file) {
      surfer.loadBlob(file);
    } else {
      surfer.load(src);
    }
  }, [surfer, src, file]);

  useEffect(() => {
    if (!surfer)
      return;

    const wavesurfer: any = surfer;
    const _onReady = () => {
      if (autoPlay) {
        wavesurfer.play();
      }
      if (onReady) {
        onReady(wavesurfer);
      }

      if (options.splitChannels) {
        const waveCanvas = document.querySelector<HTMLCanvasElement>(`#${id} > wave > canvas`);
        const height = waveCanvas.height;
        waveCanvas.style.display = 'none';

        const progressCanvas = document.querySelector<HTMLCanvasElement>(`#${id} > wave > wave > canvas`);
        progressCanvas.style.display = 'none';

        setTimeout(() => {
          const waveCtx: CanvasRenderingContext2D = wavesurfer.drawer.canvases[0].waveCtx;
          const progressCtx: CanvasRenderingContext2D = wavesurfer.drawer.canvases[0].progressCtx;
          const width = waveCtx.canvas.width;

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          ctx.drawImage(waveCanvas, 0, 0, width, height / 2, 0, 0, width, height / 2);
          ctx.drawImage(waveCanvas, 0, height * 1.5, width, height / 2, 0, height / 2, width, height / 2);
          waveCtx.clearRect(0, 0, width, height * 2);
          waveCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height * 2);

          ctx.clearRect(0, 0, width, height);
          ctx.drawImage(progressCanvas, 0, 0, width, height / 2, 0, 0, width, height / 2);
          ctx.drawImage(progressCanvas, 0, height * 1.5, width, height / 2, 0, height / 2, width, height / 2);
          progressCtx.clearRect(0, 0, width, height * 2);
          progressCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height * 2);

          waveCanvas.style.display = 'block';
          progressCanvas.style.display = 'block';

          canvas.remove();
        }, 500);
      }
    };

    wavesurfer.on('ready', _onReady);
    return () => wavesurfer.un('ready', _onReady);
  }, [surfer, autoPlay, onReady]);

  useEffect(() => {
    if (!surfer)
      return;

    surfer.on('error', onError);
    return () => surfer.un('error', onError);
  }, [surfer, onError]);

  useEffect(() => {
    if (!surfer || !onProcess)
      return;

    const wavesurfer: any = surfer;
    const _onProcess = () => {
      onProcess(wavesurfer.getCurrentTime());
    };
    wavesurfer.on('audioprocess', _onProcess);
    return () => wavesurfer.un('audioprocess', _onProcess);
  }, [surfer, onProcess]);

  useEffect(() => {
    if (!surfer || !onSeek)
      return;

    const wavesurfer: any = surfer;
    const _onSeek = () => {
      onSeek(wavesurfer.getCurrentTime());
    };
    wavesurfer.on('seek', _onSeek);
    return () => wavesurfer.un('seek', _onSeek);
  }, [surfer, onSeek]);

  return useMemo(() => (
    <div
      id={id}
      className={classnames('wavesurfer', className)}
    />
  ), [id, className]);
});
