import { Radio } from "@mui/material";
import classnames from "classnames";
import React, { FC } from "react";
import { Button } from "../../../../components/Button";
import { ArrowLeftIcon } from "../../../../components/icons";
import { NUMBER_CREATE_MODE } from "../../../../utils/enums";
import { INumberCreateForm } from "..";

interface INumberCreateStep0Props {
  formData: INumberCreateForm;
  onBack(): void;
  onNext(): void;
  type: NUMBER_CREATE_MODE;
  onTypeSelect: (res?: any) => void;
}

export const NumberCreateStep0: FC<INumberCreateStep0Props> = ({
  formData,
  onBack,
  onNext,
  type,
  onTypeSelect = () => { },
}) => {
  const [availableDids, setAvailableDids] = React.useState<boolean>(true);

  const options = [
    {
      value: NUMBER_CREATE_MODE.SPECIFIC,
      title: 'User Selected Number',
      description: availableDids ? 'I want to have some control over what my number looks like.' : 'There are no specific number options available for your prefix.',
    },
    {
      value: NUMBER_CREATE_MODE.RANDOM,
      title: 'System Selected Number',
      description: 'I don\'t mind what my number looks like, I just need one.',
    },
  ];

  React.useEffect(() => {
    setAvailableDids(formData.available_dids);
  }, [formData])

  React.useEffect(() => {
    if (!availableDids) {
      onTypeSelect(NUMBER_CREATE_MODE.RANDOM);
    }
  }, [availableDids])

  return (
    <div className="h-full flex flex-col">
      <div className="h-0 flex-grow text-center overflow-auto">
        <h5 className="text-sm font-semibold mt-4">Number Setup</h5>
        <p className="text-blue text-4xl font-bold mb-12">Would you like to buy a…</p>

        {options.map((option, i) => (
          <div
            data-cy="number-mode-panel"
            key={i}
            className={classnames(
              !availableDids && 'pointer-events-none',
              'flex flex-col border-1.5 rounded-md overflow-hidden my-4 mx-70',
              option.value === type ? 'border-blue' : 'border-gray-d0 cursor-pointer text-gray-b4',
            )}
            onClick={(e) => onTypeSelect(option.value)}
          >
            <div className="relative flex flex-1 items-start px-9 pt-1 pb-4 text-left">
              <Radio size="small" checked={option.value === type} className="!absolute top-1 left-0" />
              <div className="h-full ml-0 mt-1.5 !w-full">
                <strong>{option.title}</strong>
                <p className="text-sm mt-1">{option.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex mt-4 mx-4">
        <Button className="text-primary text-sm ml-auto" onClick={onBack}>
          <ArrowLeftIcon className="mr-1" size={14} /> Back
        </Button>
        <Button
          data-cy="create-number-step-3"
          color="primary"
          className="text-sm rounded-full shadow-md px-6 ml-2"
          onClick={onNext}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
