import moment from "moment";
import { BlockedNumberService } from "../../services";
import { BLOCKED_NUMBER_CHANNEL } from "../enums";
import { formatPhoneNumber } from "../../utils/helpers";

export class BlockedNumberModel {
  uuid: string;
  number: string;
  description: string;
  channels: BLOCKED_NUMBER_CHANNEL[];
  created_by: string;
  created: string;

  constructor(init: any = {}) {
    this.uuid = ""
    this.number = ""
    this.description = ""
    this.channels = []
    this.created_by = ""
    this.created = ""
    Object.assign(this, init)
  }

  pretty_number() {
    return formatPhoneNumber(this.number)
  }

  static search(query: any = {}, ...kwargs) {
    return BlockedNumberService.search(query, ...kwargs)
  }

  static create(data: any = {}, ...kwargs) {
    data.number = data.number.replace(/\D/g, '')
    return BlockedNumberService.create(data, ...kwargs)
  }

  delete(...kwargs) {
    return BlockedNumberService.delete(this.uuid, ...kwargs)
  }

  edit(...kwargs) {
    return BlockedNumberService.update(
      this.uuid,
      {
        number: this.number.replace(/\D/g, ''),
        description: this.description,
        channels: this.channels,
      }
    )
  }

  formatCreated() {
    if (!this.created) return '';
    return moment(this.created).fromNow();
  }
}
