import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import BlockedNumbers from "./index";

const BlockedNumbersRouting = () => (
  <Switch>
    <Route exact path={ROUTES.SETTINGS.BLOCKED_NUMBERS} component={BlockedNumbers} />
  </Switch>
);

export default BlockedNumbersRouting;
