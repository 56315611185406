import React, { FC, useState } from "react";
import { Button, Modal } from "../../../../components";
import { WhatsAppCampaignStep1Svg, WhatsAppCampaignStep2Svg, WhatsAppCampaignStep3Svg, WhatsAppCampaignStep4Svg } from "../../../../components/svgs";

interface ICampaignInfoModalProps {
  onClose: (result?: any) => void;
}

export const CampaignsInfoModal: FC<ICampaignInfoModalProps> = ({
  onClose,
}) => {

  const [modalIndex, setModalIndex] = useState<number>(0);

  const Content = () => {
    switch (modalIndex) {
      case 1:
        return (
          <>
            <h1 className="text-5xl font-bold text-blue m-8">
              Do you have a WABA?
            </h1>
            <p className="m-2 mb-12">
              <strong>You need a new WhatsApp Business Account with Sudonum to send your campaign from.</strong>
              <br />
              Don't have one yet? contact <a href="mailto:support@sudonum.com" className="text-primary font-bold">support@sudonum.com</a>
            </p>
            <WhatsAppCampaignStep2Svg />
          </>
        )

      case 2:
        return (
          <>
            <h1 className="text-5xl font-bold text-blue m-8">
              Do you have an approved message template?
            </h1>
            <p className="m-2 mb-12">
              <strong>You need an approved template linked to your WhatsApp Business account.</strong>
              <br />
              Need help? - contact <a href="mailto:support@sudonum.com" className="text-primary font-bold">support@sudonum.com</a>
            </p>
            <WhatsAppCampaignStep3Svg />
          </>
        )

      case 3:
        return (
          <>
            <h1 className="text-5xl font-bold text-blue m-8">
              Do you have a CSV file of contacts ready to upload?
            </h1>
            <p className="m-2 mb-12">
              <strong>To send your campaign, we will need a list of contacts via a CSV file, or you need to upload contacts before you start your campaign.</strong>
            </p>
            <WhatsAppCampaignStep4Svg />
          </>
        )

      default:
        return (
          <>
            <h1 className="text-5xl font-bold text-blue m-8">
              Create a WA Campaign
            </h1>
            <p className="m-2 mb-12">
              <strong>You are about to create your very first WhatsApp Campaign.</strong>
              <br />
              Before you start, here are a few tips to ensure you don't have any hiccups
            </p>
            <WhatsAppCampaignStep1Svg />
          </>
        )
    }
  }

  return (
    <Modal
      footerComponent={(
        <Button
          className="text-sm rounded-full px-6 py-2 ml-auto"
          color="primary"
          onClick={() => modalIndex < 3 ? setModalIndex(modalIndex + 1) : onClose()}
        >
          {modalIndex > 2 ? "Start Your Campaign" : "Next"}
        </Button>
      )}
      onClose={onClose}
    >
      <div className="flex-col text-center p-4">
        <Content />
      </div>
    </Modal>
  )
}