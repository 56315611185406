import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PauseIcon, PlayIcon } from "../../components/icons";
import { WaveSurfer, WaveSurferOptions } from "../WaveSurfer";
import { formatDuration } from "../../utils/helpers";

const PIXEL_PER_SEC = 20;
const SCROLL_PADDING = 50;

export interface IFullAudioPlayerProps {
  file?: File;
  src: string;
  stereo?: boolean;
}

export const FullAudioPlayer: FC<IFullAudioPlayerProps> = ({
  file,
  src,
  stereo = false,
}) => {
  const playerRef = useRef<HTMLDivElement>();
  const wavesurfer = useRef<any>();

  const [totalDuration, setTotalDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(true);
  const [speed, setSpeed] = useState(1);
  const [error, setError] = useState<string>();

  const fitScrollPosition = useCallback(() => {
    if (!playing || !wavesurfer?.current)
      return;

    const duration = wavesurfer?.current?.getDuration() || 0;
    setTotalDuration(duration);

    const playerWidth = playerRef.current.clientWidth;
    const time = wavesurfer?.current?.getCurrentTime() || 0;
    setCurrentTime(time);

    if (!wavesurfer.current.isPlaying())
      setPlaying(false);

    const cursorOffset = time * PIXEL_PER_SEC - playerRef.current.scrollLeft;
    if (cursorOffset > playerWidth - SCROLL_PADDING) {
      playerRef.current.scrollLeft = time * PIXEL_PER_SEC - SCROLL_PADDING;
    } else if (cursorOffset < SCROLL_PADDING) {
      playerRef.current.scrollLeft = time * PIXEL_PER_SEC - playerWidth + SCROLL_PADDING;
    }

    const cursorEl = playerRef.current.querySelector('.cursor') as HTMLDivElement;
    cursorEl.style.left = `${time * PIXEL_PER_SEC - 5}px`;
  }, [playing]);

  useEffect(() => {
    const timer = setInterval(fitScrollPosition, 30);
    return () => clearInterval(timer);
  }, [fitScrollPosition]);

  const onSeek = useCallback((e) => {
    let offset = e.clientX + playerRef.current.scrollLeft;
    let el = e.target;
    while (el) {
      offset -= el.offsetLeft;
      el = el.offsetParent;
    }
    const time = offset / PIXEL_PER_SEC;
    wavesurfer.current.seekTo(time / wavesurfer.current.getDuration());
    wavesurfer.current.play();
    setPlaying(true);
  }, []);

  const onTogglePlay = useCallback(() => {
    if (playing) {
      wavesurfer?.current?.pause();
    } else {
      wavesurfer?.current?.play();
    }
    setPlaying(!playing);
  }, [playing]);

  const onSpeedUp = useCallback(() => {
    const speeds = [0.5, 1, 2];
    const id = speeds.indexOf(speed) + 1;
    const newSpeed = speeds[id % speeds.length];
    setSpeed(newSpeed);

    const time = wavesurfer.current.getCurrentTime() || 0;
    wavesurfer.current.setPlaybackRate(newSpeed);
    wavesurfer.current.seekTo(time / wavesurfer.current.getDuration());
  }, [speed]);

  const wavesurferOptions = useMemo(() => {
    const options: WaveSurferOptions = {
      progressColor: '#F6A734',
      interact: false,
    };

    if (stereo) {
      options.splitChannels = true;
      options.splitChannelsOptions = {
        overlay: false,
        channelColors: {
          0: { progressColor: '#307CD8' },
          1: { progressColor: '#F6A734' },
        },
      };
    }

    return options;
  }, [stereo]);

  const wavesurferElement = useMemo(() => (
    <WaveSurfer
      file={file}
      src={src}
      options={wavesurferOptions}
      onReady={(wave) => wavesurfer.current = wave}
      onError={setError}
    />
  ), [src, wavesurferOptions]);

  const formatTime = (time) => formatDuration(time, time < 3600 ? 'mm:ss' : 'hh:mm:ss');

  if (error) {
    return (
      <div className="text-center p-10">
        Loading failed
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="relative flex items-center py-1">
        <div className="absolute w-full flex items-center">
          <span className="text-gray-b4 text-12p">{formatTime(currentTime)}</span>
          <div className="w-0 flex-grow border-b border-gray-d9 mx-3" />
          <span className="text-gray-b4 text-12p">{formatTime(totalDuration)}</span>
        </div>
        <div className="p-2 mx-10 overflow-hidden" ref={playerRef}>
          <div
            className="relative overflow-visible"
            style={{ width: totalDuration * PIXEL_PER_SEC }}
            onClick={onSeek}
          >
            {wavesurferElement}
            <div className="cursor absolute top-7 -left-1 z-10 flex flex-col items-center">
              <div className="bg-blue w-1 h-17 rounded-full" />
              <div className="w-2.5 h-2.5 rounded-full border-2 border-blue mt-0.5" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-center mt-4">
        <div className="w-15 h-15 flex-center flex-shrink-0 bg-blue-light rounded-full shadow-blue-lg cursor-pointer" onClick={onTogglePlay}>
          {totalDuration && playing ? (
            <PauseIcon color="white" size={36} />
          ) : (
            <PlayIcon color="white" size={36} />
          )}
        </div>
      </div>
    </div>
  );
};
