import store from "../redux/store";
import {IConfirmationModalProps} from "../parts";
import {closeConfirmModal, showConfirmModal} from "../redux/actions";

export class MessageBoxService {

  static async confirm(options: IConfirmationModalProps) {
    return new Promise((resolve) => {
      store.dispatch(showConfirmModal({
        ...options,
        onClose(result) {
          store.dispatch(closeConfirmModal());
          resolve(result);
        },
      }));
    });
  }
}
