import { HttpService } from "./http.service";
import { ICreateWebhookRequest, ISearchWebhooksRequest, IUpdateWebhookRequest } from "../utils/dto";
import { formatPaginatedResult } from "../utils/helpers";
import { WebhookModel } from "../utils/types";

export class WebhookService {

  static search(query: ISearchWebhooksRequest = {}, showSpinner = true) {
    return HttpService.get('/webhooks/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(WebhookModel, res));
  }

  static find(name: string, showSpinner = true) {
    return HttpService.get(`/webhooks/${name}/`, {}, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static create(data: ICreateWebhookRequest, showSpinner = true) {
    return HttpService.post(`/webhooks/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static update(name: string, data: IUpdateWebhookRequest, showSpinner = true) {
    return HttpService.put(`/webhooks/${name}/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static patch(name: string, data: Partial<IUpdateWebhookRequest>, showSpinner = true) {
    return HttpService.patch(`/webhooks/${name}/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static delete(name: string, showSpinner = true) {
    return HttpService.delete(`/webhooks/${name}/`, {}, {}, showSpinner);
  }
}

export class WAWebhookService {

  static search(query: any = {}, showSpinner = true) {
    return HttpService.get('/whatsapp/webhooks/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(WebhookModel, res));
  }

  static find(uuid: string, showSpinner = true) {
    return HttpService.get(`/whatsapp/webhooks/${uuid}/`, {}, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static create(data: any, showSpinner = true) {
    return HttpService.post(`/whatsapp/webhooks/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static update(uuid: string, data: IUpdateWebhookRequest, showSpinner = true) {
    return HttpService.put(`/whatsapp/webhooks/${uuid}/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static patch(uuid: string, data: Partial<IUpdateWebhookRequest>, showSpinner = true) {
    return HttpService.patch(`/whatsapp/webhooks/${uuid}/`, data, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }

  static delete(uuid: string, showSpinner = true) {
    return HttpService.delete(`/whatsapp/webhooks/${uuid}/`, {}, {}, showSpinner)
      .then((res) => new WebhookModel(res));
  }
}
