import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../constants";
import Numbers from "./index";
import NumberEditPage from "./NumberEditPage";

const NumbersRouting = () => (
  <Switch>
    <Route exact path={ROUTES.NUMBERS.INDEX} component={Numbers} />
    <Route exact path={`${ROUTES.NUMBERS.INDEX}/:id`} component={NumberEditPage} />
  </Switch>
);

export default NumbersRouting;
