import React from "react";
import { IssueTable } from "./IssueTable";

export const Issues = () => {
  return (
    <div className="h-full flex flex-col px-4 pt-4 bg-[#F8F8F8]">
      <IssueTable />
    </div>
  );
};
