import {HttpService} from "./http.service";
import {ICreateRelationshipRequest, IUpdateRelationshipRequest} from "../utils/dto";
import {RelationshipModel} from "../utils/types";

export class RelationshipService {

  static search(orgUuid: string, showSpinner = true): Promise<RelationshipModel[]> {
    return HttpService.get(`/admin/org/${orgUuid}/relationship`, {}, {}, showSpinner)
      .then((res) => res.results.map((item) => new RelationshipModel(item)));
  }

  static find(orgUuid: string, uuid: string, showSpinner = true): Promise<RelationshipModel> {
    return HttpService.get(`/admin/org/${orgUuid}/relationship/${uuid}`, {}, {}, showSpinner)
      .then((res) => new RelationshipModel(res));
  }

  static create(orgUuid: string, data: ICreateRelationshipRequest, showSpinner = true): Promise<RelationshipModel> {
    return HttpService.post(`/admin/org/${orgUuid}/relationship`, data, {}, showSpinner)
      .then((res) => new RelationshipModel(res));
  }

  static update(orgUuid: string, uuid: string, data: IUpdateRelationshipRequest, showSpinner = true): Promise<RelationshipModel> {
    return HttpService.patch(`/admin/org/${orgUuid}/relationship/${uuid}`, data, {}, showSpinner)
      .then((res) => new RelationshipModel(res));
  }

  static delete(orgUuid: string, uuid: string, showSpinner = true) {
    return HttpService.delete(`/admin/org/${orgUuid}/relationship/${uuid}`, {}, {}, showSpinner);
  }
}
