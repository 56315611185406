export class WAOptedOutsModel {
  uuid: string;
  contact_details: {
    uuid: string;
    first_name: string;
    last_name: string;
    whatsapp_number: string;
    created: string;
  };
  created: string;
  modified: string;


  constructor(init: any = {}) {
    const data = {
      uuid: '',
      contact_details: {
        uuid: '',
        first_name: '',
        last_name: '',
        whatsapp_number: '',
        created: '',
      },
      created: '',
      modified: '',
      ...init,
    };

    Object.assign(this, data);
  }
}