import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const BarChartIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={15.5} baseHeight={16.909} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 15.5 16.909">
        <g transform="translate(-5.625 -4.5)">
          <path d="M12.375,4.5h2.818V21.409H12.375Z" transform="translate(-2.523)" fill={color} />
          <path d="M5.625,23.625H8.443v4.932H5.625Z" transform="translate(0 -7.148)" fill={color} />
          <path d="M19.125,19.125h2.818v7.75H19.125Z" transform="translate(-5.045 -5.466)" fill={color} />
          <path d="M25.875,12.375h2.818V24.352H25.875Z" transform="translate(-7.568 -2.943)" fill={color} />
        </g>
      </svg>
    )}
  </Icon>
);
