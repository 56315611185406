import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {NoData} from '../../components';
import {getActiveOrganization} from "../../redux/selectors";

const Dashboard = () => {
  const location = useLocation();

  const [url, setUrl] = useState("");
  const organization = useSelector(getActiveOrganization);
  const dashboardType = location.pathname.split("/").pop();

  useEffect(() => {
    if (organization?.dashboard_pages) {
      setUrl(organization.dashboard_pages[dashboardType]);
    }
  }, [organization, dashboardType]);

  if (!url) {
    return (
      <NoData
        title="You do not have a Looker Studio dashboard"
        text=""
      />
    );
  }

  return (
    <iframe
      title="Dashboard"
      src={ url }
      frameBorder="0"
      style={{border: 0, width: '100%', height: '100%'}}
      allowFullScreen>
    </iframe>
  );
};

export default Dashboard;
