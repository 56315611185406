import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Button } from "../../../../components";
import { ArrowLeftIcon, CheckCircleIcon, CloseCircleIcon } from "../../../../components/icons";
import { Form, FormControl, Input, useForm, Validators } from "../../../../components/ReactiveForm";
import { IWhatsAppSetupFormData } from "../index";
import { ROUTES } from "../../../../constants";

export interface INumberInputProps {
  formData?: IWhatsAppSetupFormData;
  onBack(): void;
  onNext(data: IWhatsAppSetupFormData): void;
}

const options = [
  { icon: CheckCircleIcon, title: `Yes`, value: true, description: 'I do have a Sudo Number I want to use for setting up my WhatsApp.' },
  { icon: CloseCircleIcon, title: `No`, value: false, description: 'I do not have a Sudo Number yet, but I would like to get one.' },
];

const NumberInput: FC<INumberInputProps> = ({
  formData = {}, onBack, onNext,
}) => {
  const [form] = useForm<{
    number: FormControl;
  }>({
    number: new FormControl(formData.number, [Validators.required(), Validators.phone()]),
  });
  const [hasNumber, setHasNumber] = useState<boolean>();

  return (
    <>
      <div className="w-full h-15 bg-gray-ef rounded-t-md" />
      <Form className="whatsapp-number-input h-full flex flex-col text-center overflow-auto px-8 pt-8" formGroup={form} onSubmit={onNext}>
        <h6 className="font-semibold">WhatsApp Set up</h6>
        <p className="text-primary text-3xl font-bold">Do you have a Sudo Number?</p>
        <div className="max-w-135 text-left mx-auto mt-10 mb-6">
          {options.map((option, i) => (
            <div
              key={i}
              className={classnames(
                'radio-box flex items-start text-gray-be border-1.5 border-gray-e7 rounded cursor-pointer px-4 py-3 mt-4',
                { 'active': hasNumber === option.value },
              )}
              onClick={() => setHasNumber(option.value)}
            >
              <option.icon size={24} />
              <div className="ml-1">
                <div className="text text-gray-75 font-medium">{option.title}</div>
                <p className="text-sm mt-0.5">{option.description}</p>
              </div>
            </div>
          ))}
          {hasNumber && (
            <Input
              type="phone"
              control={form.controls.number}
              label="Your Sudo Number"
              containerClass="mt-6"
              labelClass="text-sm font-semibold mb-2"
              fullWidth
            />
          )}
          {hasNumber === false && (
            <div className="mt-6">
              <div className="text-blue text-lg font-bold">Not a problem!</div>
              <p className="text-xs font-semibold mt-1">
                Before we can set up your number for WhatsApp, you would first have to create one.
                Once you have created your new number, you can select "<span className="text-blue font-bold">Set Up WhatsApp</span>" and follow the steps.
              </p>
            </div>
          )}
        </div>
        <div className="flex mt-auto mb-6">
          <Button className="text-primary text-sm ml-auto" onClick={onBack}>
            <ArrowLeftIcon className="mr-1" size={14} /> Back
          </Button>
          {hasNumber === false ? (
            <Link to={ROUTES.ONBOARD.CREATE_NUMBER}>
              <Button color="primary" className="text-sm rounded-full shadow-md px-6 ml-2">
                Create a Number
              </Button>
            </Link>
          ) : (
            <Button type="submit" color="primary" className="text-sm rounded-full shadow-md px-6 ml-2">
              Continue
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default NumberInput;
