import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WAOnboardingFailed: FC<IIconProps> = (props) => (
  <Icon baseWidth={270.045} baseHeight={241.477} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 270.045 241.477">
        <g id="Warning-rafiki" transform="translate(-56.11 -80.804)">
          <g id="freepik--background-simple--inject-19" transform="translate(102.565 80.804)">
            <path id="Path_3608" data-name="Path 3608" d="M320.9,231.966c4.659-28.684-30.077-107.466-63.6-133.177-43.475-33.343-100.217-20.2-125.628,38.364-23.614,54.374,2.416,110.155,49.673,128.908C223.4,282.767,316.722,266.778,320.9,231.966Z" transform="translate(-122.819 -80.804)" fill="#307cd8" />
            <path id="Path_3609" data-name="Path 3609" d="M320.9,231.966c4.659-28.684-30.077-107.466-63.6-133.177-43.475-33.343-100.217-20.2-125.628,38.364-23.614,54.374,2.416,110.155,49.673,128.908C223.4,282.767,316.722,266.778,320.9,231.966Z" transform="translate(-122.819 -80.804)" fill="#fff" opacity="0.9" />
          </g>
          <g id="freepik--Shadow--inject-19" transform="translate(56.11 306.514)">
            <ellipse id="freepik--path--inject-19" cx="135.022" cy="7.883" rx="135.022" ry="7.883" fill="#f5f5f5" />
          </g>
          <g id="freepik--Warning--inject-19" transform="translate(69 131.776)">
            <path id="Path_3610" data-name="Path 3610" d="M76.465,213.171a.3.3,0,0,1-.3-.272L74.62,194.306a.292.292,0,0,1,.265-.306.3.3,0,0,1,.32.272l1.546,18.594a.3.3,0,0,1-.272.32Z" transform="translate(-74.619 -166.144)" fill="#307cd8" />
            <path id="Path_3611" data-name="Path 3611" d="M78.255,234.678a.3.3,0,0,1-.3-.272l-.467-5.627a.3.3,0,1,1,.592-.049l.467,5.627a.3.3,0,0,1-.272.32Z" transform="translate(-75.49 -176.606)" fill="#307cd8" />
            <path id="Path_3612" data-name="Path 3612" d="M234.177,265.11H90.387a7.4,7.4,0,0,1-7.222-6.671l-8.1-97.348a6.059,6.059,0,0,1,6.114-6.671H224.964a7.41,7.41,0,0,1,7.229,6.671l8.1,97.334a6.065,6.065,0,0,1-6.114,6.685Z" transform="translate(-74.746 -154.127)" fill="#fff" />
            <path id="Path_3613" data-name="Path 3613" d="M234.057,265.262H90.267a7.66,7.66,0,0,1-7.5-6.922L74.668,161a6.407,6.407,0,0,1,1.65-4.91A6.337,6.337,0,0,1,81.061,154H224.844a7.66,7.66,0,0,1,7.507,6.964l8.092,97.334a6.33,6.33,0,0,1-6.386,6.964Zm-153-110.677a5.78,5.78,0,0,0-5.843,6.379l8.1,97.327a7.117,7.117,0,0,0,6.964,6.414H234.057a5.78,5.78,0,0,0,5.836-6.365L231.794,161a7.11,7.11,0,0,0-6.964-6.414Z" transform="translate(-74.626 -154)" fill="#307cd8" />
            <path id="Path_3614" data-name="Path 3614" d="M224.964,154.44H81.181a6.059,6.059,0,0,0-6.114,6.657H232.193a7.41,7.41,0,0,0-7.228-6.657Z" transform="translate(-74.746 -154.133)" fill="#307cd8" />
            <path id="Path_3615" data-name="Path 3615" d="M87.433,158.6a1.3,1.3,0,0,1-1.316,1.442,1.6,1.6,0,0,1-1.56-1.441,1.309,1.309,0,0,1,1.316-1.442,1.588,1.588,0,0,1,1.56,1.442Z" transform="translate(-77.635 -154.959)" fill="#fff" />
            <path id="Path_3616" data-name="Path 3616" d="M95.845,158.6a1.3,1.3,0,0,1-1.316,1.442A1.6,1.6,0,0,1,93,158.6a1.309,1.309,0,0,1,1.316-1.442A1.588,1.588,0,0,1,95.845,158.6Z" transform="translate(-80.197 -154.959)" fill="#fff" />
            <path id="Path_3617" data-name="Path 3617" d="M104.233,158.6a1.3,1.3,0,0,1-1.316,1.442,1.6,1.6,0,0,1-1.56-1.441,1.309,1.309,0,0,1,1.316-1.442A1.588,1.588,0,0,1,104.233,158.6Z" transform="translate(-82.735 -154.959)" fill="#fff" />
            <path id="Path_3618" data-name="Path 3618" d="M227.928,260.845H107.007a5.884,5.884,0,0,1-5.745-5.3l-5.683-67.856a4.819,4.819,0,0,1,4.875-5.3H221.4a5.884,5.884,0,0,1,5.745,5.3l5.648,67.891a4.819,4.819,0,0,1-4.861,5.265Z" transform="translate(-80.976 -162.619)" fill="#f0f0f0" />
            <path id="Path_3619" data-name="Path 3619" d="M214.506,197.714l-37.466,45.112a10.717,10.717,0,0,0,5.975,17.326l60.795,13.141a10.717,10.717,0,0,0,12.222-14.457l-23.308-58.26a10.717,10.717,0,0,0-18.217-2.862Z" transform="translate(-104.964 -166.091)" fill="#263238" opacity="0.1" />
            <path id="Path_3620" data-name="Path 3620" d="M106.292,206.564l-13.05,35.174a6.769,6.769,0,0,0,7.006,9.053l38.113-3.74a6.769,6.769,0,0,0,4.631-10.961l-25.07-31.407a6.769,6.769,0,0,0-11.63,1.88Z" transform="translate(-80.15 -168.618)" fill="#263238" opacity="0.1" />
            <path id="Path_3621" data-name="Path 3621" d="M234.341,180.968,199.313,231.8a11.268,11.268,0,0,0,7.8,17.563L271.5,257.94a11.268,11.268,0,0,0,11.588-16.163l-29.36-59.43a11.268,11.268,0,0,0-19.387-1.379Z" transform="translate(-111.874 -160.703)" fill="#f4a734" />
            <path id="Path_3622" data-name="Path 3622" d="M210.879,246.954a7.117,7.117,0,0,1-5.446-3.949,6.268,6.268,0,0,1,.327-6.407l35.119-50.989a5.905,5.905,0,0,1,5.773-2.451,7.1,7.1,0,0,1,5.446,3.949l29.415,59.576a6.267,6.267,0,0,1-.327,6.407,5.9,5.9,0,0,1-5.773,2.458Z" transform="translate(-114.116 -162.83)" fill="#fff" />
            <path id="Path_3623" data-name="Path 3623" d="M258.341,209.046l4.1.543a5.272,5.272,0,0,1,4.533,5.362L266.7,220.3a3.973,3.973,0,0,1-.063.578L263.717,237a4.338,4.338,0,0,1-4.951,3.482h0a5.3,5.3,0,0,1-4.533-4.784l-1.177-16.658a4.02,4.02,0,0,1,0-.578l.272-5.355a4.325,4.325,0,0,1,5.014-4.06Zm-.327,35.906,1.114.146a4.721,4.721,0,0,1,4.06,4.8h0a3.879,3.879,0,0,1-4.492,3.656l-1.114-.153a4.715,4.715,0,0,1-4.06-4.791h0a3.872,3.872,0,0,1,4.492-3.656Z" transform="translate(-128.792 -170.695)" fill="#f4a734" />
            <path id="Path_3624" data-name="Path 3624" d="M127.211,192.052l-9.617,36.47a6.769,6.769,0,0,0,7.848,8.357l37.305-7.277a6.769,6.769,0,0,0,3.614-11.268L138.7,189.12a6.776,6.776,0,0,0-11.49,2.932Z" transform="translate(-87.6 -164.015)" fill="#307cd8" />
            <path id="Path_3625" data-name="Path 3625" d="M126.7,235.115a4.13,4.13,0,0,1-3.774-1.267,4.046,4.046,0,0,1-1.045-3.816l9.638-36.567a3.482,3.482,0,0,1,2.73-2.542,4.136,4.136,0,0,1,3.774,1.274l27.758,29.248a4.053,4.053,0,0,1,1.052,3.816,3.482,3.482,0,0,1-2.73,2.535Z" transform="translate(-88.931 -165.19)" fill="#fff" />
            <path id="Path_3626" data-name="Path 3626" d="M141.355,206.709l2.375-.467a3.148,3.148,0,0,1,3.538,2.277l.85,3.182a3.226,3.226,0,0,1,.077.355l1.511,10.021a2.6,2.6,0,0,1-2.089,3h0a3.134,3.134,0,0,1-3.426-1.936l-3.858-9.527a2.785,2.785,0,0,1-.118-.341l-.877-3.189a2.618,2.618,0,0,1,2.019-3.377Zm6.72,21.087.648-.125a2.786,2.786,0,0,1,3.169,2.033h0a2.34,2.34,0,0,1-1.783,2.994l-.648.125a2.786,2.786,0,0,1-3.169-2.026h0a2.347,2.347,0,0,1,1.783-3Z" transform="translate(-94.23 -169.849)" fill="#307cd8" />
          </g>
          <g id="freepik--Character--inject-19" transform="translate(239.41 88.768)">
            <path id="Path_3627" data-name="Path 3627" d="M386,206l-5.4,1.755,4.345,5.139a16.463,16.463,0,0,0,3.169-3.7,1.72,1.72,0,0,0-.613-2.333Z" transform="translate(-337.929 -126.78)" fill="#7f3e3b" />
            <path id="Path_3628" data-name="Path 3628" d="M377.03,212.78l3.238,3.935,3.6-3.015-4.352-5.139Z" transform="translate(-336.846 -127.557)" fill="#7f3e3b" />
            <path id="Path_3629" data-name="Path 3629" d="M387.418,145.68c1.316,3.621,2.528,7.242,3.677,10.912s2.208,7.354,3.134,11.212l.327,1.483.153.773.077.39v.244l.042.292a12.208,12.208,0,0,1-.2,3.969,29.171,29.171,0,0,1-2.006,6.072,57.167,57.167,0,0,1-5.961,10.307l-3.614-2c1.281-3.482,2.549-7.04,3.482-10.488a29.4,29.4,0,0,0,.961-4.937,5.836,5.836,0,0,0-.084-1.727l-.3-1.086-.39-1.288c-1.093-3.482-2.34-7.068-3.572-10.634s-2.465-7.166-3.649-10.738Z" transform="translate(-337.595 -108.466)" fill="#7f3e3b" />
            <path id="Path_3630" data-name="Path 3630" d="M347.212,114.435c.091.439-.063.843-.341.919s-.585-.23-.7-.7.056-.843.341-.912S347.122,114,347.212,114.435Z" transform="translate(-327.467 -98.767)" fill="#263238" />
            <path id="Path_3631" data-name="Path 3631" d="M347.1,115.94a16.858,16.858,0,0,1-1.267,4.227,2.493,2.493,0,0,0,2.131-.125Z" transform="translate(-327.373 -99.436)" fill="#630f0f" />
            <path id="Path_3632" data-name="Path 3632" d="M345.885,110.626a.265.265,0,0,1-.216-.111.258.258,0,0,1,.063-.362,2.577,2.577,0,0,1,2.361-.181.258.258,0,0,1,.139.341.265.265,0,0,1-.341.146,2.02,2.02,0,0,0-1.859.118A.23.23,0,0,1,345.885,110.626Z" transform="translate(-327.309 -97.562)" fill="#263238" />
            <path id="Path_3633" data-name="Path 3633" d="M363.53,116.72c.487,5.056-.23,9.951.9,14.346,0,0-1.253,4.081-8.726,4.081-8.217,0-3.767-4.081-3.767-4.081,4.527-1.065,4.534-4.394,3.879-7.521Z" transform="translate(-328.861 -99.673)" fill="#7f3e3b" />
            <path id="Path_3634" data-name="Path 3634" d="M366.354,137.587c.641-1.288,1.065-3.12-.258-3.579-1.456-.508-9.234-.7-12.055.279a2.981,2.981,0,0,0-2.138,3.572Z" transform="translate(-329.192 -104.795)" fill="#263238" />
            <path id="Path_3635" data-name="Path 3635" d="M396.073,402.119a6.8,6.8,0,0,0,1.476-.571.146.146,0,0,0,.084-.132.132.132,0,0,0-.1-.125c-.244-.084-2.4-.8-2.974-.313a.46.46,0,0,0-.146.446.857.857,0,0,0,.46.7,1.831,1.831,0,0,0,1.2,0Zm1.031-.7a2.87,2.87,0,0,1-2.089.383.55.55,0,0,1-.306-.46.167.167,0,0,1,.049-.181c.285-.223,1.455.021,2.347.286Z" transform="translate(-342.121 -185.926)" fill="#307cd8" />
            <path id="Path_3636" data-name="Path 3636" d="M397.887,400.861a.146.146,0,0,0,.1-.181c0-.07-.564-1.727-1.393-2.089a.78.78,0,0,0-.634-.042h0a.46.46,0,0,0-.355.467c0,.7,1.5,1.685,2.187,1.873Zm-1.741-2.089a.515.515,0,0,1,.348.042,3.433,3.433,0,0,1,1.121,1.671c-.7-.313-1.685-1.107-1.7-1.511,0-.035,0-.118.167-.188h0Z" transform="translate(-342.487 -185.225)" fill="#307cd8" />
            <path id="Path_3637" data-name="Path 3637" d="M341.543,408.136a7.269,7.269,0,0,0,1.567-.216.144.144,0,0,0,.042-.265c-.216-.132-2.152-1.323-2.82-.982a.446.446,0,0,0-.244.4.877.877,0,0,0,.292.752A1.88,1.88,0,0,0,341.543,408.136Zm1.156-.418a2.89,2.89,0,0,1-2.131-.111.571.571,0,0,1-.195-.515.159.159,0,0,1,.091-.167c.341-.174,1.448.334,2.235.794Z" transform="translate(-325.628 -187.688)" fill="#307cd8" />
            <path id="Path_3638" data-name="Path 3638" d="M343.608,407.315a.188.188,0,0,0,.084,0,.153.153,0,0,0,.063-.132c0-.07-.153-1.8-.85-2.375a.752.752,0,0,0-.606-.181h0a.459.459,0,0,0-.453.369c-.125.7,1.072,1.985,1.7,2.326Zm-1.212-2.43a.487.487,0,0,1,.327.118,3.4,3.4,0,0,1,.7,1.887c-.62-.474-1.393-1.462-1.309-1.859,0,0,0-.125.209-.146h.063Z" transform="translate(-326.16 -187.083)" fill="#307cd8" />
            <path id="Path_3639" data-name="Path 3639" d="M351.247,402.63h-5.5l-.056-12.73h5.5Z" transform="translate(-327.33 -182.614)" fill="#7f3e3b" />
            <path id="Path_3640" data-name="Path 3640" d="M404.7,395.958l-5.293.968-3.8-12.528,5.293-.968Z" transform="translate(-342.487 -180.65)" fill="#7f3e3b" />
            <path id="Path_3641" data-name="Path 3641" d="M396.351,400.177l6.01-1.393a.46.46,0,0,1,.529.258l1.992,4.547a.843.843,0,0,1-.592,1.149c-2.089.46-3.148.571-5.794,1.2-1.623.383-4.937,1.351-7.187,1.88s-2.974-1.643-2.089-2.089c3.942-1.887,5.251-3.482,6.33-5.014a1.393,1.393,0,0,1,.8-.536Z" transform="translate(-340.435 -185.307)" fill="#263238" />
            <path id="Path_3642" data-name="Path 3642" d="M340.707,407.26h6a.46.46,0,0,1,.453.376l.9,4.875a.829.829,0,0,1-.829.975c-2.166-.035-5.286-.16-8.008-.16-3.182,0-4.318.174-8.05.174-2.256,0-2.786-2.284-1.838-2.486,4.332-.947,6.226-1.045,10.035-3.336A2.6,2.6,0,0,1,340.707,407.26Z" transform="translate(-322.229 -187.885)" fill="#263238" />
            <path id="Path_3643" data-name="Path 3643" d="M383.924,139.03c5.132,3.614,6.678,12.918,6.678,12.918l-11.63,5.891a55.48,55.48,0,0,1-4.248-11.539C373.478,140.8,380.275,136.468,383.924,139.03Z" transform="translate(-336.1 -106.217)" fill="#307cd8" />
            <path id="Path_3644" data-name="Path 3644" d="M378.715,147.88l4.582,10.975-3.719,1.9a61.935,61.935,0,0,1-3.008-7.187Z" transform="translate(-336.706 -109.134)" opacity="0.2" />
            <path id="Path_3645" data-name="Path 3645" d="M338.889,162.676c.042,6.135.411,13.768,1.323,23.238h29.395c.355-4.318-1.448-24.673,2.932-45.056a2.7,2.7,0,0,0-2.221-3.231,67.269,67.269,0,0,0-6.964-.745,108.418,108.418,0,0,0-12.5,0,48.866,48.866,0,0,0-5.007.7,6.671,6.671,0,0,0-5.139,4.9,58.292,58.292,0,0,0-1.609,11.456C338.965,156.443,338.868,159.347,338.889,162.676Z" transform="translate(-325.265 -105.74)" fill="#307cd8" />
            <path id="Path_3646" data-name="Path 3646" d="M348.263,156.807c-1.943,1.393-5.662,2.089-9.053,2.375.056-.9.125-1.748.2-2.549.912-.481,1.79-.919,2.618-1.323Z" transform="translate(-325.363 -111.39)" opacity="0.2" />
            <g id="Group_6068" data-name="Group 6068" transform="translate(18.36 202.78)" opacity="0.2">
              <path id="Path_3647" data-name="Path 3647" d="M345.69,389.9l.035,7.075h5.495l-.028-7.075Z" transform="translate(-345.69 -385.394)" />
              <path id="Path_3648" data-name="Path 3648" d="M400.9,383.43l-5.293.975,1.964,6.456,5.293-.975Z" transform="translate(-360.846 -383.43)" />
            </g>
            <path id="Path_3649" data-name="Path 3649" d="M361.524,105.5c.8,5.411,1.546,8.531-.543,11.943a7.089,7.089,0,0,1-12.535-.7c-2.152-4.5-3.106-12.6,1.609-16.017a7.173,7.173,0,0,1,11.469,4.77Z" transform="translate(-327.628 -94.37)" fill="#7f3e3b" />
            <path id="Path_3650" data-name="Path 3650" d="M348.471,107.324c-2.04-.9-1.845-4.109-1.226-5.46-2.361.808-5.773,1.274-6.386-1.128s1.469-3.169.195-5.223,2.786-4.29,4.68-1.393,6.574,1.6,9.6-1.163a2.667,2.667,0,0,1,4.143,3.21s6.616-.425,5.3,4.067-3.176,4.645-2.751,7.841c.292,2.159.543,6.546-2.047,7.166C356.842,115.959,349.028,113.181,348.471,107.324Z" transform="translate(-325.829 -92.241)" fill="#263238" />
            <path id="Path_3651" data-name="Path 3651" d="M350.972,122.137c-.6.432-1.219.891-1.852,1.393l.035.077A7.319,7.319,0,0,0,352,126.747c.4-.383.62-1.671,1.358-1.65,2.208.063,6.476-.864,7.187-3.357Z" transform="translate(-328.372 -101.197)" opacity="0.2" />
            <path id="Path_3652" data-name="Path 3652" d="M353.04,112.81a6.581,6.581,0,0,1-.529,3.531c-.627,1.274-1.616.46-2-1.107-.348-1.393-.439-3.767.467-4.464S352.866,111.222,353.04,112.81Z" transform="translate(-328.719 -97.803)" fill="#7f3e3b" />
            <path id="Path_3653" data-name="Path 3653" d="M355.77,207.4s4.617,42.006,7.43,57.744c3.085,17.236,19.06,71.331,19.06,71.331l9.847-2.089s-11-50.613-12.5-67.675C378,248.187,374.733,207.4,374.733,207.4Z" transform="translate(-330.391 -127.205)" fill="#307cd8" />
            <path id="Path_3654" data-name="Path 3654" d="M355.77,207.4s4.617,42.006,7.43,57.744c3.085,17.236,19.06,71.331,19.06,71.331l9.847-2.089s-11-50.613-12.5-67.675c-1.532-17.6-1.532-17.57-4.875-59.311Z" transform="translate(-330.391 -127.205)" opacity="0.3" />
            <path id="Path_3655" data-name="Path 3655" d="M390.65,387.068c-.042,0,1.671,3.593,1.671,3.593l11.107-2.375-.787-3.357Z" transform="translate(-340.98 -181.105)" fill="#263238" />
            <path id="Path_3656" data-name="Path 3656" d="M359.9,228.874c7.159,1.393,5.355,28.308,3.955,42.145-1.7-9.749-4.018-28.6-5.571-41.95a2.431,2.431,0,0,1,1.616-.195Z" transform="translate(-331.153 -133.707)" opacity="0.2" />
            <path id="Path_3657" data-name="Path 3657" d="M339.748,207.4s-2.34,41.087-2.389,57.863c-.056,17.41,3.83,74.513,3.83,74.513h9.318s2.507-56.066,3.579-73.19c1.17-18.67,4.993-59.193,4.993-59.193Z" transform="translate(-324.801 -127.202)" fill="#307cd8" />
            <path id="Path_3658" data-name="Path 3658" d="M339.748,207.4s-2.34,41.087-2.389,57.863c-.056,17.41,3.83,74.513,3.83,74.513h9.318s2.507-56.066,3.579-73.19c1.17-18.67,4.993-59.193,4.993-59.193Z" transform="translate(-324.801 -127.202)" opacity="0.3" />
            <path id="Path_3659" data-name="Path 3659" d="M340.5,392.18c-.042,0,.376,3.865.376,3.865H352.2l.439-3.482Z" transform="translate(-325.753 -183.306)" fill="#263238" />
            <path id="Path_3660" data-name="Path 3660" d="M345.924,140.909c-1.114.1-2.054.167-3.078.195s-2,.049-2.994.042a54.915,54.915,0,0,1-6.031-.348,45.962,45.962,0,0,1-6.086-1.045,29.631,29.631,0,0,1-6.142-2.18l-.23-.118a3.628,3.628,0,0,1-1.657-4.875,3.791,3.791,0,0,1,.341-.564,19.61,19.61,0,0,1,2.82-3.015c.94-.836,1.88-1.581,2.827-2.291,1.894-1.428,3.83-2.688,5.773-3.914s3.928-2.361,5.926-3.482,4.011-2.131,6.1-3.1a2.089,2.089,0,0,1,2.138,3.524h0c-1.748,1.344-3.552,2.674-5.3,4.039s-3.482,2.709-5.216,4.088-3.384,2.786-4.965,4.178a26.037,26.037,0,0,0-2.221,2.152,14.26,14.26,0,0,0-1.643,2.006l-1.539-5.571a25.521,25.521,0,0,0,4.68,1.247,49.3,49.3,0,0,0,5.16.6c1.762.111,3.545.174,5.334.174h2.688c.891,0,1.832-.07,2.611-.111h.091a4.178,4.178,0,0,1,.641,8.308Z" transform="translate(-319.326 -99.474)" fill="#7f3e3b" />
            <path id="Path_3661" data-name="Path 3661" d="M359.106,114.7l-3.482.773s-4.659-.578-5.724,2.932l2.786,2.354,4.262.7a3.217,3.217,0,0,0,1.971-.32l1.086-.564a1.657,1.657,0,0,0,.891-1.316l.258-2.786a1.657,1.657,0,0,0-2.047-1.769Z" transform="translate(-328.609 -99.045)" fill="#7f3e3b" />
            <path id="Path_3662" data-name="Path 3662" d="M347.895,151.427c-5.857,1.63-12.535-.4-12.535-.4l2.458-13.113s5.815-.515,9.45-.592C356.621,137.1,358.452,148.5,347.895,151.427Z" transform="translate(-324.194 -105.926)" fill="#307cd8" />
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
