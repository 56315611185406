import React, { FC, useEffect, useMemo, useState } from "react";
import { DataTable, IDataTableColumn, Modal } from "../../../../components";
import { ToastService, WACampaignsService } from "../../../../services";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";
import { WACampaignsLogModel, WAOptedOutsModel } from "../../../../utils/types";

interface ICampaignsOptedOutModal {
    campaignLog: WACampaignsLogModel;
    onClose?: (result?: any) => void;
}

export const CampaignsOptedOutModal: FC<ICampaignsOptedOutModal> = ({
    campaignLog,
    onClose,
}) => {

    //state 
    const [optedOuts, setOptedOuts] = useState<WAOptedOutsModel[]>([]);

    const columns = useMemo<IDataTableColumn<WAOptedOutsModel>[]>(() => {
        return [
            {
                title: 'Contact Name',
                field: 'contact_details.first_name',
                tooltip: { title: 'Contact Name' },
            },
            {
                title: 'Contact Last Name',
                field: 'contact_details.last_name',
                tooltip: { title: 'Contact Last Name' },
            },
            {
                title: 'Contact WhatsApp Number',
                field: 'contact_details.whatsapp_number',
                tooltip: { title: 'Contact WhatsApp Number' },
                render(row: WAOptedOutsModel) {
                    return formatPhoneNumber(row.contact_details.whatsapp_number);
                }
            },
            {
                title: 'Date Sent',
                field: 'created',
                tooltip: { title: 'Date Sent' },
                render(row: WAOptedOutsModel) {
                    return formatDateTime(row.created);
                }
            },
            {
                title: 'Opted Out Date',
                field: 'modified',
                tooltip: { title: 'Opted Out Date' },
                render(row: WAOptedOutsModel) {
                    return formatDateTime(row.modified);
                }
            },
            {
                cellClass: '!pr-6',
                headerClass: '!pr-6',
                render() {
                    return <span
                        className="p-2 bg-[#FFD5D7] text-[#FE1722] !font-semibold rounded-md capitalize">
                        Opted Out
                    </span>
                }
            },
        ];
    }, []);

    useEffect(() => {
        WACampaignsService.getCampaignOptedOuts(campaignLog.uuid)
            .then((res) => {
                setOptedOuts(res)
            })
            .catch((err) => {
                ToastService.showHttpError(err, "Could not resolve opted outs for the given campaign log.")
            })
    }, [campaignLog]);

    return (
        <Modal
            title={"View All Opted Out"}
            size="md"
            onClose={() => onClose()}
            contentClass="!p-0"
        >
            <DataTable
                tableContainerClass={"!m-0"}
                columns={columns}
                serverSide={false}
                data={optedOuts}
                size="sm"
                stripped
                stickyHeader
                selfPagination
                headerCellClass="!text-13p"
                cellClass="!text-11p"
            />
        </Modal>
    )
}