import React, { FC } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Avatar, Button, Card, TextField, WithTooltipIcon } from "../../../../components";
import { BriefcaseIcon, CheckCircleIcon, CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";
import { getActiveOrganization } from "../../../../redux/selectors";
import { WhatsappAccountModel } from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";
import { SwitchSmall } from "../../../../components/SwitchSmall";
import { ToastService, WhatsappService } from "../../../../services";
import { ROLE } from "../../../../utils/enums";

export interface IAccountViewProps {
  className?: string;
  account: WhatsappAccountModel;
  onDelete?: () => void;
}

const AccountView: FC<IAccountViewProps> = ({
  className,
  account,
  onDelete
}) => {
  //state
  //todo polish up state based off account values
  const [campaignMode, setCampaignMode] = React.useState(false);
  const organization = useSelector(getActiveOrganization);

  //modals
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>();
  const [showActivateCampaignModal, setShowActivateCampaignModal] = React.useState<boolean>();
  const [showDeactivateCampaignModal, setShowDeactivateCampaignModal] = React.useState<boolean>();

  const weekDayMap = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
  ];

  const onAccountDelete = () => {
    setShowConfirmationModal(false);
    onDelete();
  }

  const onCampaignModeToggle = (mode: boolean) => {
    setCampaignMode(mode)
    if (mode) {
      setShowActivateCampaignModal(true);
      return;
    }
    setShowDeactivateCampaignModal(true);
  }

  const onModalConfirmation = (confirm?: boolean) => {
    if (confirm) {
      WhatsappService.modifyAccount({
        uuid: account.uuid,
        campaign_mode: campaignMode,
      }).then(() => {
        ToastService.success("Successfully updated account.");
      }).catch((e) => {
        setCampaignMode(false);
        ToastService.showHttpError(e, "Failed to update account.")
      })
    }
    setShowActivateCampaignModal(false);
    setShowDeactivateCampaignModal(false);
  }

  React.useEffect(() => {
    if (account.campaign_mode) {
      setCampaignMode(account.campaign_mode)
    }
  }, [account])

  return (
    <>
      <div data-cy="wa-view-modal" className={classnames('h-full flex flex-col mx-auto', className)}>
        <Card size="lg" className="h-0 flex-1 overflow-auto">
          <div className="flex flex-row align-middle !w-full mb-12">
            <h2 className="text-lg font-semibold ">WhatsApp Profile Details</h2>
            <div className="flex flex-row gap-4 ml-auto">
              <div className="flex items-center text-15p font-medium">
                <h4 className="mr-2">Allow duplicate workflows</h4>
                {account.allow_duplicate_workflows ? (
                  <CheckCircleIcon className="text-green" size={20} />
                ) : (
                  <CloseCircleIcon className="text-danger" size={20} />
                )}
              </div>
              {campaignMode &&
                <div className='rounded-md bg-[#C8DCF4] text-[#307CD8] uppercase p-2 text-xs !font-bold max-w-fit ml:auto'>Campaign</div>
              }
            </div>
          </div>

          <div className="flex max-sm:flex-col gap-x-11 gap-y-4">
            <div data-cy="wa-business-logo">
              <div className="text-xs">Business Logo</div>
              <Avatar
                className="!w-36 !h-36 mt-4"
                src={account.profile_picture}
                background="#e5e5e5"
                alt={<BriefcaseIcon className="text-gray-f8" size={80} />}
              />
            </div>

            <div className="flex-1 grid md:grid-cols-3 gap-4">
              <TextField
                containerClass="mb-4"
                label="WhatsApp Number"
                value={formatPhoneNumber(account.whatsapp_number)}
                fullWidth
                readonly
              />
              <TextField
                containerClass="mb-4"
                label="Date Created"
                value={formatDateTime(account.created)}
                fullWidth
                readonly
              />
              <TextField
                containerClass="mb-4"
                label="Display Name"
                value={account.display_name}
                fullWidth
                readonly
              />

              <TextField
                containerClass="mb-4"
                label="Business website"
                value={account.business_website}
                fullWidth
                readonly
              />
              <TextField
                containerClass="mb-4"
                label="Business email address"
                fullWidth
                value={account.business_email}
                readonly
              />
              <TextField
                label="Facebook business manager ID"
                fullWidth
                value={account.fb_business_manager_id}
                readonly
              />
              <div className="flex-1 grid md:grid-cols-2 gap-4 col-span-3">
                <TextField
                  containerClass="mb-4"
                  inputClass="!font-semibold"
                  label="Address"
                  type="textarea"
                  minRows={4}
                  value={account.business_address}
                  fullWidth
                  readonly
                />
                <TextField
                  containerClass="mb-5"
                  inputClass="!font-semibold"
                  type="textarea"
                  label="Description"
                  value={account.description}
                  fullWidth
                  readonly
                  minRows={4}
                />
              </div>
              {/* <TextField
                containerClass="mb-5"
                inputClass="!font-semibold"
                label="No. of Templates"
                value={10}
                fullWidth
                readonly
              /> */}
              {!!Object.keys(account.business_day_hours).length &&
                <div className="colspan-3">
                  <h4 className="text-15p font-semibold">Business Hours (UTC)</h4>
                  <div className="xs:grid grid-rows-4 grid-flow-col gap-x-8">
                    {weekDayMap.map((item, i) => (
                      <div key={i} className="flex items-center text-15p mt-2">
                        <div className="w-10 font-bold">{item}</div>
                        {account.business_day_hours?.workdays.includes(i) ?
                          <div className="ml-3">
                            {account.business_day_hours.start_time} - {account.business_day_hours.end_time}
                          </div>
                          : <div className="ml-3">CLOSED</div>}
                      </div>
                    ))}
                  </div>
                </div>}
            </div>
          </div>
        </Card>

        {organization?.relationship.roles.includes(ROLE.CAMPAIGN) && <Card>
          <h4 className="text-15p font-semibold mb-4">Additional Features</h4>
          <div className="grid grid-cols-2">
            <div className="flex flex-row">
              <p className="text-sm">Campaigns</p>
              <WithTooltipIcon
                className="ml-2"
                placement="right"
                tooltip={"Enabling campaigns will allow you to use this WhatsApp Account to send campaigns out to your audience"}
                iconSize={15}
              />
            </div>
            <SwitchSmall
              value={campaignMode}
              onChange={(mode: boolean) => onCampaignModeToggle(mode)}
            />
          </div>
        </Card>}

        <div className="flex justify-end">
          <Button
            data-cy="delete-token"
            className="text-sm rounded-full mr-auto"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={() => setShowConfirmationModal(true)}
          >
            Delete Account
          </Button>
          <a
            data-cy="wa-view-in-whatsapp"
            href={`https://wa.me/${account.whatsapp_number}`} target="_blank" rel="noreferrer"
            className="bg-primary text-white uppercase text-sm rounded-full shadow-md px-4 py-2.5"
          >
            View in WhatsApp
          </a>
        </div>
      </div>
      {showConfirmationModal &&
        <ConfirmationModal
          type="passcode"
          title={"Account Deletion"}
          message={<p>Are you sure you would like to delete this WhatsApp account? <p>Once completed, this will disconnect Sudonum from your WhatsApp account.</p></p>}
          onClose={onAccountDelete}
          passcode="Disconnect"
        />
      }
      {showActivateCampaignModal &&
        <ConfirmationModal
          title={"Activate Campaign Mode"}
          message={<p>By <strong>activating campaign mode</strong> on a number, it will <strong>flag your number</strong> as a <strong>“campaign number”</strong> and show it in your list of WhatsApp Accounts when creating a new campaign. You can still use this number for any other lead tracking purpose, but we would not advice it.</p>}
          onClose={onModalConfirmation}
        />
      }
      {showDeactivateCampaignModal &&
        <ConfirmationModal
          title={"Disable Campaign Mode"}
          message={<p>By disabling campaign mode, this number will no longer show up in your list of WhatsApp Accounts when you are creating a campaign.</p>}
          onClose={onModalConfirmation}
        />
      }
    </>
  );
};

export default AccountView;
