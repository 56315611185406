import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const SmsCreateIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={41.178} baseHeight={38.244} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 41.178 38.244">
        <g fill={color} transform="translate(-15.302 -18.588)">
          <path d="M22.287,28.784a1.476,1.476,0,0,1-1.039.432H17.443a1.475,1.475,0,0,1-1.471-1.471V23.931a1.476,1.476,0,0,1,.432-1.039l.074-.074L30.044,9.252a.367.367,0,0,0-.257-.625H7.645a5.4,5.4,0,0,0-5.4,5.4V34.611a5.4,5.4,0,0,0,5.4,5.4h23.53a5.4,5.4,0,0,0,5.4-5.4V15.4a.365.365,0,0,0-.625-.257L22.36,28.71Z" transform="translate(13.052 16.827)"/>
          <g transform="translate(30.735 18.588)">
            <path d="M32.84,6.1,14.274,24.663a.723.723,0,0,0-.211.515v2.436a.728.728,0,0,0,.726.726h2.417a.744.744,0,0,0,.524-.211L36.305,9.562a.727.727,0,0,0,0-1.029L33.87,6.1A.727.727,0,0,0,32.84,6.1Z" transform="translate(-14.062 -2.604)" fill="#fff"/>
            <path d="M34.523,5.121,33.2,3.8l-.018-.018a1.625,1.625,0,0,0-1.057-.4,1.592,1.592,0,0,0-1.066.414L30.02,4.836a.372.372,0,0,0,0,.515l.956.956L32.97,8.3a.366.366,0,0,0,.524,0l1.039-1.039a1.567,1.567,0,0,0,.4-1.066A1.663,1.663,0,0,0,34.523,5.121Z" transform="translate(-9.192 -3.375)"/>
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
