export enum CALL_CONTEXT {
  CALL_TRACKING = 'call-tracking',
  VOICE_CALL = 'voice-call',
}

export enum CALL_DIRECTION {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum CALL_STATUS {
  ANSWERED = 'Answered',
  BLOCKED_NUMBER = 'Blacklisted Number',
  BUSY = 'Busy',
  CALLER_BUSY = 'Caller number - Busy',
  UNKNOWN = 'Unknown',
  CALLED_MISSED = 'Caller number - Missed Call',
  MISSED = 'Missed Call',
  MISSED_CALL_NETWORK_FAILURE = 'Missed Call Network Failure',
  CALLER_NUMBER_BUSY = 'Caller number - Busy',
  CALLER_NUMBER_MISSED_CALL = 'Caller number - Missed Call',
  CALLER_NUMBER_MISSED_CALL_NETWORK_FAILURE = 'Caller number - Missed Call Network Failure',
  CALLER_NUMBER_REJECTED = 'Caller number - Rejected',
  MISSED_CALL = 'Missed Call',
  NUMBER_NOT_AVAILABLE = 'Number Not Available',
  OUT_OF_SERVICE = 'Out of Service',
  REJECTED = 'Rejected',
  VOICEMAIL = 'Voicemail',
}

export const CALL_MADE_STATUSES = [
  CALL_STATUS.ANSWERED,
  CALL_STATUS.BUSY,
  CALL_STATUS.CALLER_NUMBER_BUSY,
  CALL_STATUS.CALLER_NUMBER_MISSED_CALL,
  CALL_STATUS.CALLER_NUMBER_MISSED_CALL_NETWORK_FAILURE,
  CALL_STATUS.CALLER_NUMBER_REJECTED,
  CALL_STATUS.MISSED_CALL,
  CALL_STATUS.MISSED_CALL_NETWORK_FAILURE,
  CALL_STATUS.NUMBER_NOT_AVAILABLE,
  CALL_STATUS.OUT_OF_SERVICE,
  CALL_STATUS.REJECTED,
  CALL_STATUS.VOICEMAIL,
];

export const CALL_RECEIVED_STATUSES = [
  CALL_STATUS.ANSWERED,
  CALL_STATUS.BLOCKED_NUMBER,
  CALL_STATUS.BUSY,
  CALL_STATUS.MISSED_CALL,
  CALL_STATUS.MISSED_CALL_NETWORK_FAILURE,
  CALL_STATUS.NUMBER_NOT_AVAILABLE,
  CALL_STATUS.OUT_OF_SERVICE,
  CALL_STATUS.REJECTED,
  CALL_STATUS.UNKNOWN,
  CALL_STATUS.VOICEMAIL,
];

export enum CALL_TAB {
  RECEIVED = 'Received',
  MADE = 'Made',
  BLOCKED = 'Blocked',
}
