import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {ROUTES} from "../../constants";
import OnboardGetStarted from "./GetStarted";
import CreateNumber from "./CreateNumber";
import WhatsAppSetup from "./WhatsAppSetup";
import ApiDocumentation from "./ApiDocumentation";
import SetupPayment from "./SetupPayment";

const OnboardRouting = () => (
  <Switch>
    <Route exact path={ROUTES.ONBOARD.INDEX} component={OnboardGetStarted} />
    <Route exact path={ROUTES.ONBOARD.CREATE_NUMBER} component={CreateNumber} />
    <Route exact path={ROUTES.ONBOARD.WHATSAPP_SETUP} component={WhatsAppSetup} />
    <Route exact path={ROUTES.ONBOARD.API_DOC} component={ApiDocumentation} />
    <Route exact path={ROUTES.ONBOARD.SETUP_PAYMENT} component={SetupPayment} />
    <Redirect to={ROUTES.ONBOARD.INDEX} />
  </Switch>
);

export default OnboardRouting;
