import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WhatsAppCampaignStep4Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={241.568} baseHeight={179.997} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 241.568 179.997">
        <g id="Checklist-rafiki" transform="translate(0 -55)">
          <g id="freepik--background-complete--inject-3" transform="translate(0 55)">
            <rect id="Rectangle_4615" data-name="Rectangle 4615" width="241.568" height="0.121" transform="translate(0 158.179)" fill="#ebebeb" />
            <rect id="Rectangle_4616" data-name="Rectangle 4616" width="43.917" height="0.121" transform="translate(173.446 162.778)" fill="#ebebeb" />
            <rect id="Rectangle_4617" data-name="Rectangle 4617" width="12.025" height="0.121" transform="translate(147.999 161.469)" fill="#ebebeb" />
            <rect id="Rectangle_4618" data-name="Rectangle 4618" width="18.644" height="0.121" transform="translate(168.866 167.267)" fill="#ebebeb" />
            <rect id="Rectangle_4619" data-name="Rectangle 4619" width="10.938" height="0.121" transform="translate(25.345 166.455)" fill="#ebebeb" />
            <rect id="Rectangle_4620" data-name="Rectangle 4620" width="12.992" height="0.121" transform="translate(40.583 166.455)" fill="#ebebeb" />
            <rect id="Rectangle_4621" data-name="Rectangle 4621" width="22.63" height="0.121" transform="translate(86.148 164.416)" fill="#ebebeb" />
            <path id="Path_3391" data-name="Path 3391" d="M134.253,191.631H40.964a2.759,2.759,0,0,1-2.754-2.759V57.735A2.759,2.759,0,0,1,40.964,55h93.289a2.759,2.759,0,0,1,2.759,2.759V188.872A2.759,2.759,0,0,1,134.253,191.631ZM40.964,55.1a2.638,2.638,0,0,0-2.633,2.638V188.872a2.638,2.638,0,0,0,2.633,2.638h93.289a2.643,2.643,0,0,0,2.638-2.638V57.735a2.643,2.643,0,0,0-2.638-2.638Z" transform="translate(-19.749 -55)" fill="#ebebeb" />
            <path id="Path_3392" data-name="Path 3392" d="M350.552,191.631H257.259a2.764,2.764,0,0,1-2.759-2.759V57.735A2.764,2.764,0,0,1,257.259,55h93.294a2.759,2.759,0,0,1,2.749,2.735V188.872A2.759,2.759,0,0,1,350.552,191.631ZM257.259,55.1a2.643,2.643,0,0,0-2.638,2.638V188.872a2.643,2.643,0,0,0,2.638,2.638h93.294a2.643,2.643,0,0,0,2.638-2.638V57.735a2.643,2.643,0,0,0-2.638-2.638Z" transform="translate(-131.542 -55)" fill="#ebebeb" />
            <rect id="Rectangle_4622" data-name="Rectangle 4622" width="57.764" height="127.746" transform="translate(83.104 158.174) rotate(180)" fill="#f0f0f0" />
            <rect id="Rectangle_4623" data-name="Rectangle 4623" width="55.024" height="127.746" transform="translate(80.37 158.174) rotate(180)" fill="#f5f5f5" />
            <rect id="Rectangle_4624" data-name="Rectangle 4624" width="121.949" height="49.227" transform="translate(77.471 33.332) rotate(90)" fill="#f0f0f0" />
            <path id="Path_3393" data-name="Path 3393" d="M79.265,240.24H69.23a1.3,1.3,0,0,1-1.29-1.29h0a1.3,1.3,0,0,1,1.29-1.29H79.265a1.3,1.3,0,0,1,1.29,1.29h0A1.3,1.3,0,0,1,79.265,240.24Z" transform="translate(-35.116 -149.41)" fill="#fff" />
            <rect id="Rectangle_4625" data-name="Rectangle 4625" width="121.949" height="0.754" transform="translate(28.998 33.332) rotate(90)" fill="#f0f0f0" />
            <rect id="Rectangle_4626" data-name="Rectangle 4626" width="8.6" height="37.298" transform="translate(118.77 155.754) rotate(180)" fill="#f0f0f0" />
            <rect id="Rectangle_4627" data-name="Rectangle 4627" width="42.41" height="2.363" transform="translate(117.117 158.121) rotate(180)" fill="#f0f0f0" />
            <rect id="Rectangle_4628" data-name="Rectangle 4628" width="37.115" height="37.298" transform="translate(73.055 118.46)" fill="#f5f5f5" />
            <rect id="Rectangle_4629" data-name="Rectangle 4629" width="30.351" height="12.233" transform="translate(76.432 123.05)" fill="#f0f0f0" />
            <rect id="Rectangle_4630" data-name="Rectangle 4630" width="30.351" height="12.233" transform="translate(76.432 138.931)" fill="#f0f0f0" />
            <path id="Path_3394" data-name="Path 3394" d="M181.666,310.026h6.817a.841.841,0,0,0,.836-.836h0a.841.841,0,0,0-.836-.841h-6.817a.841.841,0,0,0-.836.841h0a.841.841,0,0,0,.836.836Z" transform="translate(-93.464 -185.947)" fill="#f5f5f5" />
            <path id="Path_3395" data-name="Path 3395" d="M181.666,342.927h6.817a.841.841,0,0,0,.836-.821h0a.841.841,0,0,0-.836-.836h-6.817a.841.841,0,0,0-.836.836h0A.841.841,0,0,0,181.666,342.927Z" transform="translate(-93.464 -202.962)" fill="#f5f5f5" />
            <path id="Path_3396" data-name="Path 3396" d="M194.788,276.574h0a.643.643,0,0,1-.638-.638V256.558a.638.638,0,0,1,.638-.638h0a.643.643,0,0,1,.638.638v19.379A.643.643,0,0,1,194.788,276.574Z" transform="translate(-100.349 -158.848)" fill="#f5f5f5" />
            <rect id="Rectangle_4631" data-name="Rectangle 4631" width="11.933" height="1.503" transform="translate(88.472 116.972)" fill="#f0f0f0" />
            <path id="Path_3397" data-name="Path 3397" d="M201.969,263.645h0a.319.319,0,0,1-.319-.314v-9.672a.324.324,0,0,1,.319-.319h0a.324.324,0,0,1,.319.319v9.663a.319.319,0,0,1-.319.324Z" transform="translate(-104.225 -157.515)" fill="#f0f0f0" />
            <path id="Path_3398" data-name="Path 3398" d="M176.26,251.131S176.5,242,185.541,242s9.276,9.122,9.276,9.122Z" transform="translate(-91.102 -151.653)" fill="#f0f0f0" />
            <path id="Path_3399" data-name="Path 3399" d="M297.767,287.6h0c.256,0,.517,0,.778.024a11.112,11.112,0,0,1,10.286,10.334,11.276,11.276,0,0,1-.357,3.609,55.465,55.465,0,0,0-1.739,14.441v14.175a1.86,1.86,0,0,1-1.87,1.865H259.87V287.6Z" transform="translate(-134.317 -175.222)" fill="#f0f0f0" />
            <path id="Path_3400" data-name="Path 3400" d="M236.92,298.729a11.083,11.083,0,1,1,21.789,2.9,55.668,55.668,0,0,0-1.734,14.441v14.175a1.86,1.86,0,0,1-1.865,1.86H240.9a1.86,1.86,0,0,1-1.86-1.86V315.218a50.491,50.491,0,0,0-1.72-13.528,11.113,11.113,0,0,1-.4-2.962Z" transform="translate(-122.455 -175.263)" fill="#f5f5f5" />
            <rect id="Rectangle_4632" data-name="Rectangle 4632" width="40.395" height="26.988" transform="translate(181.225 156.826) rotate(180)" fill="#f0f0f0" />
            <path id="Path_3401" data-name="Path 3401" d="M0,0H54.068V41.39a8.387,8.387,0,0,1-8.387,8.387H8.387A8.387,8.387,0,0,1,0,41.39Z" transform="translate(214.609 135.945) rotate(180)" fill="#e0e0e0" />
            <path id="Path_3402" data-name="Path 3402" d="M0,0H54.068V41.8a7.986,7.986,0,0,1-7.986,7.986H8.006A7.986,7.986,0,0,1,.019,41.8V0Z" transform="translate(204.043 135.945) rotate(180)" fill="#f5f5f5" />
            <path id="Path_3403" data-name="Path 3403" d="M.73,0H37.965a2.416,2.416,0,0,1,2.416,2.416V8.537a2.416,2.416,0,0,1-2.416,2.416H.73a.73.73,0,0,1-.73-.73V.725A.73.73,0,0,1,.73,0Z" transform="translate(179.316 138.366) rotate(180)" fill="#e0e0e0" />
            <path id="Path_3404" data-name="Path 3404" d="M411.9,287.6h0c.256,0,.512,0,.778.024a11.088,11.088,0,0,1,9.924,13.943,55.464,55.464,0,0,0-1.739,14.446v14.175a1.86,1.86,0,0,1-1.865,1.86H374V287.6Z" transform="translate(-193.307 -175.222)" fill="#f0f0f0" />
            <path id="Path_3405" data-name="Path 3405" d="M351.06,298.613a11.083,11.083,0,0,1,22.147-.725,11.277,11.277,0,0,1-.358,3.609,55.464,55.464,0,0,0-1.739,14.441v14.175a1.86,1.86,0,0,1-1.86,1.86H355.036a1.86,1.86,0,0,1-1.86-1.86V315.1a50.683,50.683,0,0,0-1.715-13.528,11.112,11.112,0,0,1-.4-2.962Z" transform="translate(-181.45 -175.147)" fill="#f5f5f5" />
            <path id="Path_3406" data-name="Path 3406" d="M260.058,380.953h91.463a1.348,1.348,0,0,0,1.348-1.353H258.71a1.348,1.348,0,0,0,1.348,1.353Z" transform="translate(-133.718 -222.774)" fill="#e0e0e0" />
            <rect id="Rectangle_4633" data-name="Rectangle 4633" width="29.418" height="32.085" transform="translate(209.928 54.532) rotate(180)" fill="#e0e0e0" />
            <rect id="Rectangle_4634" data-name="Rectangle 4634" width="30.182" height="32.085" transform="translate(208.937 54.532) rotate(180)" fill="#f5f5f5" />
            <rect id="Rectangle_4635" data-name="Rectangle 4635" width="27.031" height="25.128" transform="translate(206.406 24.973) rotate(90)" fill="#fff" />
            <path id="Path_3407" data-name="Path 3407" d="M393.87,120.52l6.87,11.9H387Z" transform="translate(-200.026 -88.865)" fill="#f5f5f5" />
            <rect id="Rectangle_4636" data-name="Rectangle 4636" width="29.418" height="32.085" transform="translate(168.658 73.263) rotate(180)" fill="#e0e0e0" />
            <rect id="Rectangle_4637" data-name="Rectangle 4637" width="30.182" height="32.085" transform="translate(167.668 73.263) rotate(180)" fill="#f5f5f5" />
            <rect id="Rectangle_4638" data-name="Rectangle 4638" width="27.031" height="25.128" transform="translate(165.136 43.705) rotate(90)" fill="#fff" />
            <path id="Path_3408" data-name="Path 3408" d="M305.746,172.2l-2.981-5.165a3.546,3.546,0,0,1,3.073-5.314h5.976a3.551,3.551,0,0,1,3.078,5.314L311.91,172.2a3.551,3.551,0,0,1-6.165,0Z" transform="translate(-156.244 -110.16)" fill="#f5f5f5" />
          </g>
          <g id="freepik--Shadow--inject-3" transform="translate(27.109 224.059)">
            <ellipse id="freepik--path--inject-3" cx="93.675" cy="5.469" rx="93.675" ry="5.469" fill="#f5f5f5" />
          </g>
          <g id="freepik--Checklist--inject-3" transform="translate(41.54 66.291)">
            <path id="Path_3409" data-name="Path 3409" d="M197.353,99.3H85.98l7.329,151.676H204.682Z" transform="translate(-85.98 -89.188)" fill="#407bff" />
            <path id="Path_3410" data-name="Path 3410" d="M197.353,99.3H85.98l7.329,151.676H204.682Z" transform="translate(-85.98 -89.188)" opacity="0.2" />
            <path id="Path_3411" data-name="Path 3411" d="M199.483,99.3H88.11l7.334,151.676H206.817Z" transform="translate(-87.081 -89.188)" fill="#407bff" />
            <path id="Path_3412" data-name="Path 3412" d="M199.051,109.86H98.65l7.015,139.718,87.3-.116L207.477,236.6Z" transform="translate(-92.529 -94.646)" opacity="0.2" />
            <path id="Path_3413" data-name="Path 3413" d="M199.819,109.85a1137.6,1137.6,0,0,0,10.122,125.065q-11.933,8.213-23.746,16.426H111.105A1140.533,1140.533,0,0,1,98.65,109.85Z" transform="translate(-92.529 -94.641)" fill="#fff" />
            <path id="Path_3414" data-name="Path 3414" d="M303.6,363.024q-11.934,8.213-23.746,16.427,2.58-10.871,5.377-21.741Z" transform="translate(-186.184 -222.75)" fill="#ebebeb" />
            <path id="Path_3415" data-name="Path 3415" d="M136.786,134.578q.285,7.672.667,15.354H122.1q-.387-7.682-.667-15.354h15.349m1.565-1.618H119.76q.324,9.291.807,18.591h18.6q-.483-9.3-.807-18.591Z" transform="translate(-103.44 -106.585)" fill="#407bff" />
            <path id="Path_3416" data-name="Path 3416" d="M228.43,144.529H175.855c-.048-1.155-.092-2.314-.135-3.469h52.57C228.334,142.215,228.382,143.374,228.43,144.529Z" transform="translate(-132.363 -110.772)" fill="#e0e0e0" />
            <path id="Path_3417" data-name="Path 3417" d="M217.643,159.629H176.5c-.053-1.16-.111-2.314-.159-3.469h41.139Z" transform="translate(-132.684 -118.576)" fill="#e0e0e0" />
            <path id="Path_3418" data-name="Path 3418" d="M139.854,196.819q.483,7.682,1.077,15.364H125.577q-.594-7.682-1.077-15.364h15.354m1.517-1.619H122.78q.57,9.3,1.3,18.6h18.615q-.725-9.3-1.3-18.6Z" transform="translate(-105.001 -138.755)" fill="#407bff" />
            <path id="Path_3419" data-name="Path 3419" d="M198.477,206.774H179.152c-.077-1.16-.159-2.314-.232-3.474h19.325C198.318,204.46,198.4,205.614,198.477,206.774Z" transform="translate(-134.017 -142.941)" fill="#e0e0e0" />
            <path id="Path_3420" data-name="Path 3420" d="M241.821,206.774H227.612c-.077-1.16-.159-2.314-.232-3.474h14.209C241.662,204.46,241.739,205.614,241.821,206.774Z" transform="translate(-159.064 -142.941)" fill="#e0e0e0" />
            <path id="Path_3421" data-name="Path 3421" d="M226.235,221.884H180.226c-.087-1.16-.169-2.314-.256-3.474h46.009C226.066,219.57,226.148,220.724,226.235,221.884Z" transform="translate(-134.56 -150.751)" fill="#e0e0e0" />
            <path id="Path_3422" data-name="Path 3422" d="M144.578,259.089q.691,7.682,1.488,15.364H130.712q-.8-7.682-1.488-15.364h15.354m1.478-1.618h-18.6q.821,9.305,1.8,18.606h18.6Q146.887,266.77,146.056,257.47Z" transform="translate(-107.419 -170.94)" fill="#407bff" />
            <path id="Path_3423" data-name="Path 3423" d="M237.85,280.69H185.27l.348,3.469H238.2Z" transform="translate(-137.299 -182.941)" fill="#e0e0e0" />
            <path id="Path_3424" data-name="Path 3424" d="M224.984,265.58H183.84c.106,1.155.213,2.314.324,3.469H225.3C225.2,267.894,225.085,266.749,224.984,265.58Z" transform="translate(-136.56 -175.132)" fill="#e0e0e0" />
            <path id="Path_3425" data-name="Path 3425" d="M150.961,321.358q.9,7.682,1.9,15.359H137.506q-1-7.672-1.9-15.359h15.354m1.449-1.618H133.8q1.063,9.305,2.295,18.6h18.577q-1.222-9.291-2.295-18.6Z" transform="translate(-110.696 -203.125)" fill="#407bff" />
            <path id="Path_3426" data-name="Path 3426" d="M243.4,331.284h-52.58c-.14-1.16-.28-2.314-.415-3.474h52.575C243.115,328.984,243.25,330.139,243.4,331.284Z" transform="translate(-139.951 -207.296)" fill="#e0e0e0" />
            <path id="Path_3427" data-name="Path 3427" d="M216.614,346.419H192.68c-.15-1.155-.295-2.314-.44-3.469h23.935C216.32,344.1,216.464,345.264,216.614,346.419Z" transform="translate(-140.902 -215.121)" fill="#e0e0e0" />
            <path id="Path_3428" data-name="Path 3428" d="M260.744,346.419H250.41c-.15-1.155-.295-2.314-.44-3.469H260.3C260.449,344.1,260.614,345.264,260.744,346.419Z" transform="translate(-170.74 -215.121)" fill="#e0e0e0" />
            <path id="Path_3429" data-name="Path 3429" d="M134.312,149.745l-.681-1.082a18.9,18.9,0,0,0-5.571-5.1l.783-1.387a21.983,21.983,0,0,1,5.44,4.672,50.183,50.183,0,0,1,10.532-10.011l1,1.3c-5.054,3.783-8.59,7.247-10.822,10.581Z" transform="translate(-107.73 -108.591)" fill="#407bff" />
            <path id="Path_3430" data-name="Path 3430" d="M137.941,211.994l-.71-1.082a19.634,19.634,0,0,0-5.7-5.1l.739-1.387a22.707,22.707,0,0,1,5.571,4.677A48.107,48.107,0,0,1,148.1,199.08c.343.435.691.87,1.034,1.309-4.952,3.788-8.4,7.247-10.537,10.586C138.376,211.313,138.159,211.656,137.941,211.994Z" transform="translate(-109.523 -140.76)" fill="#407bff" />
            <path id="Path_3431" data-name="Path 3431" d="M143.28,274.424l-.739-1.082a20.4,20.4,0,0,0-5.841-5.1c.232-.483.483-.928.705-1.387a23.718,23.718,0,0,1,5.691,4.672,46.544,46.544,0,0,1,9.991-10.02l1.073,1.309c-4.831,3.788-8.213,7.247-10.257,10.586Z" transform="translate(-112.195 -173.028)" fill="#407bff" />
            <path id="Path_3432" data-name="Path 3432" d="M194.11,380a9.989,9.989,0,0,1-5.943-1.981,8.326,8.326,0,0,0-5.1-1.73,6.466,6.466,0,0,0-4.614,1.73A7.731,7.731,0,0,1,173.074,380a9.961,9.961,0,0,1-5.938-1.981,8.3,8.3,0,0,0-5.1-1.73,6.457,6.457,0,0,0-4.614,1.73A7.727,7.727,0,0,1,152.048,380a10,10,0,0,1-5.943-1.981,8.315,8.315,0,0,0-5.1-1.73c-.063-.483-.13-.928-.193-1.391a9.973,9.973,0,0,1,5.938,1.981,8.311,8.311,0,0,0,5.1,1.73,6.466,6.466,0,0,0,4.614-1.73,7.745,7.745,0,0,1,5.382-1.981,9.957,9.957,0,0,1,5.933,1.981,8.326,8.326,0,0,0,5.1,1.73,6.466,6.466,0,0,0,4.614-1.73,7.745,7.745,0,0,1,5.382-1.981,9.973,9.973,0,0,1,5.938,1.981,8.315,8.315,0,0,0,5.1,1.73C193.974,379.074,194.047,379.538,194.11,380Z" transform="translate(-114.32 -231.635)" fill="#ebebeb" />
            <path id="Path_3433" data-name="Path 3433" d="M201.35,92.236c-1.633-4.348-5.406-7.2-9.465-7.2h-8.271a3.145,3.145,0,0,1-2.875-1.933,7.73,7.73,0,0,0-7.039-4.73,6.846,6.846,0,0,0-6.58,4.73,2.793,2.793,0,0,1-2.691,1.933h-8.266c-4.063,0-7.561,2.87-8.769,7.2l-.483,1.725h55.078Z" transform="translate(-117.472 -78.369)" fill="#263238" />
            <path id="Path_3434" data-name="Path 3434" d="M200.3,110.526H146.09a1.807,1.807,0,0,1-1.783-1.7h0a1.609,1.609,0,0,1,1.619-1.7h54.208a1.807,1.807,0,0,1,1.783,1.7h0a1.609,1.609,0,0,1-1.618,1.7Z" transform="translate(-116.126 -93.235)" fill="#407bff" />
            <path id="Path_3435" data-name="Path 3435" d="M200.3,110.526H146.09a1.807,1.807,0,0,1-1.783-1.7h0a1.609,1.609,0,0,1,1.619-1.7h54.208a1.807,1.807,0,0,1,1.783,1.7h0a1.609,1.609,0,0,1-1.618,1.7Z" transform="translate(-116.126 -93.235)" opacity="0.2" />
          </g>
          <g id="freepik--Character--inject-3" transform="translate(124.113 85.597)">
            <path id="Path_3436" data-name="Path 3436" d="M356.754,401.459h-3.541l-.353-8.2H356.4Z" transform="translate(-306.493 -260.431)" fill="#ffc3bd" />
            <path id="Path_3437" data-name="Path 3437" d="M399.219,401.459h-3.541l-1.778-8.2h3.537Z" transform="translate(-327.705 -260.431)" fill="#ffc3bd" />
            <path id="Path_3438" data-name="Path 3438" d="M391.149,409.38h3.976a.3.3,0,0,1,.3.242l.7,3.15a.522.522,0,0,1-.517.628c-1.387-.024-2.063-.106-3.812-.106-1.077,0-3.3.111-4.778.111s-1.681-1.449-1.073-1.6a8.759,8.759,0,0,0,4.628-2.208A.87.87,0,0,1,391.149,409.38Z" transform="translate(-323.427 -268.763)" fill="#263238" />
            <path id="Path_3439" data-name="Path 3439" d="M344.52,409.38h3.865a.3.3,0,0,1,.3.242l.7,3.15a.512.512,0,0,1-.483.628c-1.4-.024-3.411-.106-5.165-.106-2.048,0-3.817.111-6.223.111-1.449,0-1.855-1.449-1.246-1.6,2.768-.609,5.029-.672,7.421-2.15a1.662,1.662,0,0,1,.831-.275Z" transform="translate(-297.776 -268.763)" fill="#263238" />
            <path id="Path_3440" data-name="Path 3440" d="M359.925,142.92c-.483,2.59-.763,5.8,1.048,7.527,0,0-1.039,2.628-5.851,2.628-4.4,0-2.193-2.628-2.193-2.628,2.9-.691,2.735-4.261,2.232-6.281Z" transform="translate(-306.244 -131.04)" fill="#ffc3bd" />
            <path id="Path_3441" data-name="Path 3441" d="M350.66,156.876c.1-.643,1.227-1.391,2.512-1.672s6.223-.9,7.73-.28-.261,2.469-.261,2.469Z" transform="translate(-305.356 -137.106)" fill="#407bff" />
            <path id="Path_3442" data-name="Path 3442" d="M350.66,156.876c.1-.643,1.227-1.391,2.512-1.672s6.223-.9,7.73-.28-.261,2.469-.261,2.469Z" transform="translate(-305.356 -137.106)" fill="#fff" opacity="0.4" />
            <path id="Path_3443" data-name="Path 3443" d="M352.86,393.27l.184,4.223h3.541l-.184-4.223Z" transform="translate(-306.493 -260.436)" opacity="0.2" />
            <path id="Path_3444" data-name="Path 3444" d="M397.441,393.27H393.9l.918,4.223h3.541Z" transform="translate(-327.705 -260.436)" opacity="0.2" />
            <path id="Path_3445" data-name="Path 3445" d="M380.786,165.1l-4.266,2.85s6.126,10.895,5.614,12.639c-.7,2.377-1.676,7.571-3.005,10.209a4.963,4.963,0,0,0,1.551,2.092c3.382-2.57,6.8-10.629,6.672-12.885C387.057,174.888,380.786,165.1,380.786,165.1Z" transform="translate(-318.722 -142.504)" fill="#ffc3bd" />
            <path id="Path_3446" data-name="Path 3446" d="M378.3,218.031l-3.382-.261,1.773,3.725s2.952.13,3.445-2.126Z" transform="translate(-317.895 -169.727)" fill="#b55b52" />
            <path id="Path_3447" data-name="Path 3447" d="M341.508,217.492l-.285.536-1.913,3.619L271.39,185.735l2.193-4.155Z" transform="translate(-264.385 -151.022)" fill="#407bff" />
            <path id="Path_3448" data-name="Path 3448" d="M350.6,200.311l-1.4,2.648-.792,1.5L280.48,168.545l2.193-4.155Z" transform="translate(-269.083 -142.137)" fill="#407bff" />
            <rect id="Rectangle_4639" data-name="Rectangle 4639" width="4.696" height="76.833" transform="translate(9.221 30.546) rotate(-62.09)" fill="#407bff" />
            <rect id="Rectangle_4640" data-name="Rectangle 4640" width="4.696" height="76.833" transform="translate(9.221 30.546) rotate(-62.09)" opacity="0.2" />
            <path id="Path_3449" data-name="Path 3449" d="M263.216,164.946l-1.976,3.739,4.9,8.17,6.585-12.455Z" transform="translate(-259.138 -142.142)" fill="#407bff" />
            <path id="Path_3450" data-name="Path 3450" d="M263.216,164.946l-1.976,3.739,4.9,8.17,6.585-12.455Z" transform="translate(-259.138 -142.142)" fill="#fff" opacity="0.8" />
            <path id="Path_3451" data-name="Path 3451" d="M256.89,165.767l2.1,3.5,1.976-3.739Z" transform="translate(-256.89 -142.726)" fill="#407bff" />
            <path id="Path_3452" data-name="Path 3452" d="M369.29,219.466l1.768,3.295,2.739-1.266-1.773-3.725Z" transform="translate(-314.985 -169.727)" fill="#b55b52" />
            <path id="Path_3453" data-name="Path 3453" d="M377.937,160.091c3.348.483,7.184,12.078,7.184,12.078l-7.034,3.971a84.1,84.1,0,0,1-4.619-9.6C371.864,162.241,374.347,159.569,377.937,160.091Z" transform="translate(-316.888 -139.881)" fill="#263238" />
            <path id="Path_3454" data-name="Path 3454" d="M378.344,168.169c-1.522-1.391-.589,4.566.135,8.378.372.744.725,1.42,1.024,1.986C381.2,175.537,380.441,170.087,378.344,168.169Z" transform="translate(-319.242 -143.983)" opacity="0.2" />
            <path id="Path_3455" data-name="Path 3455" d="M340.154,159.361s-4.179,10.687-.213,30.592h18.953c.13-2.9-1.7-17.084,1.106-30.776a49.28,49.28,0,0,0-6.339-.8,69.766,69.766,0,0,0-8.044,0A35.271,35.271,0,0,0,340.154,159.361Z" transform="translate(-298.933 -138.968)" fill="#263238" />
            <path id="Path_3456" data-name="Path 3456" d="M323.647,168.8l-4.773-1.889s-5.5,11.223-7.247,11.789c-2.353.773-7.184,2.9-10.112,3.314a5.109,5.109,0,0,0-.846,2.464c4.034,1.319,12.62-.43,14.4-1.812C319.145,179.515,323.647,168.8,323.647,168.8Z" transform="translate(-279.513 -143.439)" fill="#ffc3bd" />
            <path id="Path_3457" data-name="Path 3457" d="M343.64,165.654a3.487,3.487,0,0,0-3.865-2.309,50.817,50.817,0,0,0-1.449,14.494l1.126.541S344.988,168.761,343.64,165.654Z" transform="translate(-298.969 -141.57)" opacity="0.2" />
            <path id="Path_3458" data-name="Path 3458" d="M338.164,162.847c1.189,3.169-4.831,12.494-4.831,12.494l-7.083-3.865a43.23,43.23,0,0,1,4.092-8.286C332.83,159.474,336.855,159.373,338.164,162.847Z" transform="translate(-292.74 -140.033)" fill="#263238" />
            <path id="Path_3459" data-name="Path 3459" d="M298.283,198.15l-4.213.894,2.135,3.9a11.658,11.658,0,0,0,3.165-2.309Z" transform="translate(-276.107 -159.586)" fill="#ffc3bd" />
            <path id="Path_3460" data-name="Path 3460" d="M289.95,200.9l1.681,2.329,2.445.705-2.135-3.9Z" transform="translate(-273.978 -160.563)" fill="#ffc3bd" />
            <path id="Path_3461" data-name="Path 3461" d="M350.66,134.636a.436.436,0,0,1-.367.483c-.188,0-.324-.251-.3-.536s.179-.483.367-.483S350.679,134.351,350.66,134.636Z" transform="translate(-305.008 -126.481)" fill="#263238" />
            <path id="Path_3462" data-name="Path 3462" d="M350.293,135.26a10.186,10.186,0,0,1-1.5,2.358,1.638,1.638,0,0,0,1.348.333Z" transform="translate(-304.39 -127.08)" fill="#ed847e" />
            <path id="Path_3463" data-name="Path 3463" d="M350.47,132.043a.227.227,0,0,1-.072-.029.164.164,0,0,1-.048-.232,1.681,1.681,0,0,1,1.309-.792.179.179,0,0,1,.169.169.169.169,0,0,1-.169.169h0a1.324,1.324,0,0,0-1.01.633A.169.169,0,0,1,350.47,132.043Z" transform="translate(-305.181 -124.873)" fill="#263238" />
            <path id="Path_3464" data-name="Path 3464" d="M360.343,128.908c-.415,3.488-.483,5.546-2.353,7.247a4.669,4.669,0,0,1-7.682-2.846c-.57-3.189.208-8.353,3.715-9.552a4.793,4.793,0,0,1,6.319,5.15Z" transform="translate(-305.087 -120.999)" fill="#ffc3bd" />
            <path id="Path_3465" data-name="Path 3465" d="M350.048,126.92c-1.222.242-3.174-2.474-1.58-4.778-.807-.4-3.44,1.184-4.43-.295s1.759-2.266,1.232-3.517c1.865.382-1.208,2.71.565,2.483s3.817-3.5,5.8-2.16c3.5,2.353,8.276-1.57,6.329,3.343,2.387-.483,4.02,2.338,1.744,4.3-4.537,3.889-1.744,4.4-4.619,4.6C353.647,131,350.3,129.283,350.048,126.92Z" transform="translate(-301.821 -118.33)" fill="#263238" />
            <path id="Path_3466" data-name="Path 3466" d="M357.217,135.886a2.9,2.9,0,0,1-1.686,1.493c-.966.333-1.522-.531-1.295-1.483.2-.86.966-2.068,1.966-1.981a1.319,1.319,0,0,1,1.015,1.971Z" transform="translate(-307.178 -126.383)" fill="#ffc3bd" />
            <path id="Path_3467" data-name="Path 3467" d="M355.67,223.86s4.02,27.056,6.223,37.2c2.416,11.112,11.513,48.067,11.513,48.067h6.484s-6-36.071-7.378-47.062c-1.493-11.924-4.624-38.192-4.624-38.192Z" transform="translate(-307.946 -172.875)" fill="#407bff" />
            <path id="Path_3468" data-name="Path 3468" d="M355.67,223.86s4.02,27.056,6.223,37.2c2.416,11.112,11.513,48.067,11.513,48.067h6.484s-6-36.071-7.378-47.062c-1.493-11.924-4.624-38.192-4.624-38.192Z" transform="translate(-307.946 -172.875)" fill="#fff" opacity="0.2" />
            <path id="Path_3469" data-name="Path 3469" d="M362.805,242.9c-.3-.425-.57-.57-.821-.483h-.053a.7.7,0,0,0-.159.1.967.967,0,0,0-.14.14l-.077.1-.1.159a3.108,3.108,0,0,0-.217.44c-.039.092-.077.188-.111.285s-.087.246-.126.382-.111.367-.164.565a.6.6,0,0,1-.039.155c-.014.053-.053.222-.077.333s-.043.193-.063.29c-.043.188-.082.387-.121.589s-.082.44-.121.667a1.416,1.416,0,0,1-.039.237c-.048.29-.092.585-.135.889h0c1.073,6.735,2.348,14.455,3.382,19.842.048-.174.1-.358.145-.556a25.116,25.116,0,0,0,.483-2.725C365.163,257.358,364.743,245.666,362.805,242.9Z" transform="translate(-310.308 -182.452)" opacity="0.2" />
            <path id="Path_3470" data-name="Path 3470" d="M341.5,223.86s-.483,26.466-.1,37.264c.4,11.228,4.324,47.99,4.324,47.99h6s.217-36.11.483-47.135c.29-12.02,1.739-38.119,1.739-38.119Z" transform="translate(-300.496 -172.875)" fill="#407bff" />
            <path id="Path_3471" data-name="Path 3471" d="M341.5,223.86s-.483,26.466-.1,37.264c.4,11.228,4.324,47.99,4.324,47.99h6s.217-36.11.483-47.135c.29-12.02,1.739-38.119,1.739-38.119Z" transform="translate(-300.496 -172.875)" fill="#fff" opacity="0.2" />
            <path id="Path_3472" data-name="Path 3472" d="M356.034,396.262c.024,0,0,1.957,0,1.957H349.12l-.29-2.2Z" transform="translate(-304.41 -261.858)" fill="#263238" />
            <path id="Path_3473" data-name="Path 3473" d="M397.851,396.536c.029,0,.324,1.812.324,1.812h-7.126l-.628-2.077Z" transform="translate(-325.907 -261.987)" fill="#263238" />
            <path id="Path_3474" data-name="Path 3474" d="M393.72,409.7a1.063,1.063,0,0,1-.681-.179.483.483,0,0,1-.174-.444.276.276,0,0,1,.14-.237c.391-.2,1.536.483,1.667.58a.087.087,0,0,1,.034.087.082.082,0,0,1-.063.068A4.262,4.262,0,0,1,393.72,409.7Zm-.527-.73a.257.257,0,0,0-.111,0,.106.106,0,0,0-.053.1.343.343,0,0,0,.116.3,1.691,1.691,0,0,0,1.256.068,3.382,3.382,0,0,0-1.208-.469Z" transform="translate(-327.168 -268.466)" fill="#407bff" />
            <path id="Path_3475" data-name="Path 3475" d="M395.393,408.709a.063.063,0,0,1-.039,0c-.367-.2-1.073-.966-1-1.367a.271.271,0,0,1,.266-.217.44.44,0,0,1,.358.106c.411.333.483,1.358.483,1.4a.1.1,0,0,1-.039.077.082.082,0,0,1-.029,0Zm-.715-1.449h-.014c-.111,0-.121.063-.126.087-.043.232.406.817.773,1.092a2,2,0,0,0-.42-1.106.275.275,0,0,0-.227-.063Z" transform="translate(-327.938 -267.595)" fill="#407bff" />
            <path id="Path_3476" data-name="Path 3476" d="M349.5,409.7a1.329,1.329,0,0,1-.86-.217.483.483,0,0,1-.155-.406.251.251,0,0,1,.135-.222c.43-.242,1.855.483,2.015.565a.082.082,0,0,1-.024.155A5.731,5.731,0,0,1,349.5,409.7Zm-.643-.73a.353.353,0,0,0-.155.029.106.106,0,0,0-.053.092.3.3,0,0,0,.1.266,2.271,2.271,0,0,0,1.585.1A4.656,4.656,0,0,0,348.859,408.973Z" transform="translate(-304.232 -268.468)" fill="#407bff" />
            <path id="Path_3477" data-name="Path 3477" d="M351.528,408.7h-.034c-.43-.193-1.275-.966-1.208-1.358,0-.092.082-.213.309-.232a.6.6,0,0,1,.483.14,2.4,2.4,0,0,1,.546,1.367.073.073,0,0,1-.034.073A.087.087,0,0,1,351.528,408.7Zm-.865-1.449h-.034c-.15,0-.159.072-.164.1-.039.237.536.841.966,1.1a1.933,1.933,0,0,0-.483-1.092.483.483,0,0,0-.285-.1Z" transform="translate(-305.161 -267.588)" fill="#407bff" />
          </g>
        </g>
      </svg>
    )}
  </Icon>
);
