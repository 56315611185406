import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {ROUTES} from "../../constants";
import Messages from "./index";

const MessagesRouting = () => (
  <Switch>
    <Redirect exact from={ROUTES.MESSAGES.PREFIX} to={ROUTES.MESSAGES.RECEIVED} />
    <Route path={ROUTES.MESSAGES.SENT} component={Messages} />
    <Route path={ROUTES.MESSAGES.RECEIVED} component={Messages} />
    <Route path={ROUTES.MESSAGES.BLOCKED} component={Messages} />
  </Switch>
);

export default MessagesRouting;
