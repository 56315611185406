export class WATemplatesModel {
  uuid: string;
  //TODO fix type to be correct
  media_type: string;
  type: string;
  message_template: {
    body?: string;
    header?: string;
    footer?: string;
    buttons?: any[];
  };
  created: string;
  modified: string;
  name: string;
  whatsapp_number: string;

  constructor(init: any = {}) {
    const data = {
      uuid: '',
      //TODO fix type to be correct
      media_type: '',
      type: '',
      message_template: {},
      created: '',
      modified: '',
      name: '',
      whatsapp_number: '',
      ...init,
    };

    Object.assign(this, data);
  }
}