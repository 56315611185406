import React, { FC } from "react";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import { MenuItem } from "../../../../constants";
import { Avatar } from "../../../../components";
import { LogoutIcon } from "../../../../components/icons";
import { AuthService } from "../../../../services";

interface IMenuNavProps {
  sidebarCollapsed: boolean;
  menus: MenuItem[];
  activeMenu?: MenuItem;
  onSelectMenu(menu: MenuItem): void;
}

const MenuNav: FC<IMenuNavProps> = ({
  sidebarCollapsed, menus, activeMenu, onSelectMenu,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onMenuClick = (menu: MenuItem) => {
    if (menu.type === 'menu') {
      onSelectMenu(menu !== activeMenu ? menu : null);
      if (menu.link) {
        history.push(menu.link);
      }
    }
  };
  return (
    <div className={classnames(
      'menu-nav bg-blue-dark flex flex-col overflow-x-hidden md:overflow-x-visible transition-all',
      sidebarCollapsed ? 'w-0 md:w-14' : 'w-14',
    )}>
      {menus.map((menu: MenuItem, index: number) => {
        const menuId = menu.type === 'menu'
          ? `menu-${menu.text.replace(/\W/g, '-').toLowerCase()}`
          : undefined;
        const isActive = menu.text === activeMenu?.text || (!activeMenu && menu.type === 'menu' && location.pathname.startsWith(menu.prefix));
        return (
          <div
            key={index}
            id={menuId}
            className={classnames(
              'w-14 min-h-14 flex flex-col items-center justify-center text-white transition-all',
              'border-l-6 py-2 pl-1 pr-3 has-tooltip cursor-pointer',
              { 'flex-grow': menu.type === 'spacing' },
              { 'hover:bg-[#8b939e]': !isActive && menu.type === 'menu' },
              isActive ? 'border-primary bg-white' : 'border-transparent',
            )}
            onClick={() => onMenuClick(menu)}
          >
            {menu.icon && (
              menu.icon({ color: !isActive ? 'white' : 'primary' })
            )}
            {menu.iconText && (
              <div className={classnames('text-2xs mt-1', { 'text-primary': isActive })}>
                {menu.iconText}
              </div>
            )}
            {!isActive && menu.text && (
              <div
                className={classnames(
                  'tooltip absolute left-14 min-w-24 bg-blue-dark text-white text-xs whitespace-nowrap',
                  'px-2 py-1 shadow-md transition-all pointer-events-none opacity-0'
                )}
              >
                <div className="absolute w-2 h-2 bg-blue-dark -left-1 top-2 transform rotate-45" />
                <span className="relative">{menu.text}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MenuNav;
