import React, { useCallback, useState } from 'react';
import ChatIntrosFilter from "./ChatIntrosFilter";
import ChatIntrosTable from "./ChatIntrosTable";
import { FilterModel } from "../../../utils/types";
import { SelfHelpButton, TextField } from '../../../components';
import { SearchIcon } from '../../../components/icons';
import debounce from 'lodash.debounce';

const ChatIntros = () => {
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [search, setSearch] = useState('');
  const [referenceId, setReferenceId] = useState('');

  const debouncedSetFilter = useCallback(debounce((filter) => setReferenceId(filter), 500), []);

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter(search);
  };

  return (
    <>
      <div className="h-full flex flex-col px-4 pt-4">
        <div className="flex justify-between items-center">
          <ChatIntrosFilter filters={filters} onChange={setFilters} />
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search Reference ID"
            clearable
            onChange={(text) => onSearchChange(text.split(' ').join(''))}
          />
          <SelfHelpButton url={'/docs/whatsapp-chat-intros'} />
        </div>
        <ChatIntrosTable
          className="h-0 flex-1 mt-3"
          referenceId={referenceId}
          filters={filters}
          onQueryChange={setFilters}
        />
      </div>
    </>
  );
};

export default ChatIntros;
