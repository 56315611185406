import { ROUTES } from "../constants";
import { IRegisterForm } from "../utils/interfaces";
import { OrganizationModel } from "../utils/types";
import { HttpService } from "./http.service";

export class AuthService {
  static async login(email, password, showSpinner = true) {
    const res = await HttpService.post('/admin/login', { email, password }, {}, showSpinner);
    localStorage.setItem("login", "true")
    return res
  }

  static csrf() {
    return HttpService.get('/admin/csrf', {}, {}, false);
  }

  static readme_login(redirect: string = "", showSpinner = true) {
    const params = (redirect) ? `?redirect=${redirect}` : ""
    return HttpService.get(`admin/readme-login${params}`, {}, {}, false)
  }

  static reset(email, showSpinner = true) {
    return HttpService.post('/admin/send-password-reset', { email }, {}, showSpinner);
  }

  static confirmResetPassword(password, confirmPassword, uidb64, token, showSpinner = true) {
    return HttpService
      .post('/admin/confirm-password-reset', { password, confirmPassword, uidb64, token }, {}, showSpinner);
  }

  static totpSession(token) {
    return HttpService.post('/admin/totp-session', { token }, {}, false);
  }

  static totpVerify(token: string, showSpinner = true) {
    return HttpService.post('/admin/totp-verify', { token }, {}, showSpinner);
  }

  static totpDisable(token: string, showSpinner = true) {
    return HttpService.post('/admin/totp-disable', { token }, {}, showSpinner);
  }

  static totpGet(name) {
    return HttpService.get(`/admin/totp/${name}`, {}, {}, false);
  }

  //  static async firebaseGoogleLogin() {
  //    const googleProvider = new GoogleAuthProvider();
  //    try {
  //      const res = await signInWithPopup(auth, googleProvider);
  //      const user = res.user;
  //      return user.getIdToken();
  //    } catch (e) {
  //      throw e;
  //    }
  //  }

  static register(data: IRegisterForm, showSpinner = true) {
    return HttpService.post('/admin/register', data, {}, showSpinner);
  }

  static resendVerifyEmail(uuid: string, showSpinner = true) {
    return HttpService.post('/admin/resend-verify', { user_uuid: uuid }, {}, showSpinner);
  }

  static verify(uid64: string, token: string, password: string = "", showSpinner = true) {
    let body: any = { uidb64: uid64, token }
    if (password) body.password = password
    return HttpService.post('/admin/verify', body, {}, showSpinner);
  }

  static getQRCode(showSpinner = true) {
    return HttpService.get('/admin/qrcode', {}, {}, showSpinner);
  }

  static logout() {
    localStorage.removeItem("login")
    HttpService.post('/admin/logout', {}, {}, false)
      .then(res => {
        window.location.href = ROUTES.AUTH.LOGIN;
      })
  }

  static getMyOrganizations(showSpinner = true): Promise<OrganizationModel[]> {
    return HttpService.get('/admin/org', {}, {}, showSpinner)
      .then((res) => res.results.map((item) => new OrganizationModel(item)));
  }
}
