import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { uniq } from "lodash";
import { Button, SelfHelpButton } from "../../components";
import { SmsCreateIcon } from "../../components/icons";
import { BlockKeywordsModal, BlockNumberModal, ExportEmailSentModal, ExistingBlockNumberModal } from "../../parts";
import SmsFilters from "./Filters";
import MessagesTable from "./MessagesTable";
import SendMessageModal from "./SendMessageModal";
import { ROUTES } from "../../constants";
import { setSelectedSmsLogs, setSmsTab } from "../../redux/actions";
import { getSelectedSmsLogs, getSmsTab, getSmsTableRef } from "../../redux/selectors";
import { ToastService, BlockedNumberService, SmsService } from '../../services';
import { SMS_TAB } from "../../utils/enums";
import { SmsRecordModel } from "../../utils/types";

const Messages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tab = useSelector(getSmsTab);
  const tableRef = useSelector(getSmsTableRef);
  const selectedLogs = useSelector(getSelectedSmsLogs);

  const [query, setQuery] = useState<any>();
  const [isExported, setIsExported] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>();
  const [showBlockedModal, setShowBlockedModal] = useState<boolean>();
  const [newMessageSent, setNewMessageSent] = useState<boolean>(false);
  const [blockingNumber, setBlockingNumber] = useState<string>();
  const [blockingKeywords, setBlockingKeywords] = useState<string>();

  useEffect(() => {
    const tabs = [
      { path: ROUTES.MESSAGES.RECEIVED, tab: SMS_TAB.RECEIVED },
      { path: ROUTES.MESSAGES.SENT, tab: SMS_TAB.SENT },
      { path: ROUTES.MESSAGES.BLOCKED, tab: SMS_TAB.BLOCKED },
    ];
    const tab = tabs.find((item) => location.pathname.startsWith(item.path)) || tabs[0];
    dispatch(setSmsTab(tab.tab));
  }, [location.pathname]);

  const refreshTable = () => {
    if (tableRef) {
      tableRef.refresh(true);
    }
  };

  const onCloseSendMessageModal = (result) => {
    if (result) {
      refreshTable();
    }

    setShowCreateModal(false);
  };

  const onUnBlockNumbers = (message: SmsRecordModel) => {
    BlockedNumberService.search({ number: message.from_number }).then(res => {
      if (res.results.length === 0) {
        ToastService.success(<>{message.from_number} has already been unblocked.</>);
        return;
      }

      res.results[0].delete().then(() => {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> unblocked number.</>);
      })
    })
  }

  const onBlockNumbers = (message: SmsRecordModel) => {
    BlockedNumberService.search({ number: message.from_number }).then((res) => {
      if (res.results.length > 0) {
        setShowBlockedModal(true);
      } else {
        setBlockingNumber(message.from_number);
      }
    })
  };

  const onCloseBlockedNumberModal = () => {
    setShowBlockedModal(false);
  };

  const onCloseBlockNumberModal = (result) => {
    if (result) {
      refreshTable();
      dispatch(setSelectedSmsLogs([]));
    }
    setBlockingNumber(undefined);
  };

  const onCloseBlockKeywordsModal = (result) => {
    if (result) {
      refreshTable();
      dispatch(setSelectedSmsLogs([]));
    }
  };

  const onQueryChange = useCallback((query) => {
    setQuery(query);
  }, []);

  const onDownload = useCallback(() => {
    SmsService.exportSms(query).then(() => {
      setIsExported(true);
    });
  }, [query]);

  return (
    <>
      <div className="h-full flex flex-col px-4 pt-4">
        <div className="flex items-start max-xl:flex-wrap">
          <SmsFilters />
          <div className="flex flex-wrap flex-shrink-0 ml-auto">
            {tab === SMS_TAB.RECEIVED && selectedLogs.length > 0 && (
              <>
                {/* selectedLogs.some((log) => !blockedNumbers.includes(log.from_number)) && (
                  <Button
                    color="blue-dark"
                    className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
                    onClick={() => onBlockKeywords(selectedLogs)}
                  >
                    Mark all keyword(s) as spam
                  </Button>
                  ) */}
                <Button
                  color="blue-dark"
                  className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
                // onClick={() => onBlockNumbers(selectedLogs)}
                >
                  Mark all number(s) as spam
                </Button>
              </>
            )}
            {tab === SMS_TAB.BLOCKED && selectedLogs.length > 0 && (
              <>
                <Button
                  color="blue-dark"
                  className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
                >
                  Unblock keyword(s)
                </Button>
                <Button
                  color="blue-dark"
                  className="text-xs rounded-full !normal-case shadow ml-2 mb-2"
                >
                  Unblock sender
                </Button>
              </>
            )}
            <Button
              color="blue-light"
              className="text-xs rounded-full !normal-case shadow px-6 ml-2 mb-2"
              onClick={onDownload}
            >
              Export list
            </Button>
          </div>
          <SelfHelpButton url={'/docs/sending-and-receiving-smses'} />
        </div>

        <MessagesTable
          onBlockNumbers={onBlockNumbers}
          onUnBlockNumbers={onUnBlockNumbers}
          onQueryChange={onQueryChange}
          newMessageSent={newMessageSent}
          setNewMessageSent={setNewMessageSent}
        />

        <div
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-53 h-13.5 flex items-center overflow-hidden pl-4 pr-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setShowCreateModal(true)}
        >
          <SmsCreateIcon className="mb-1" color="white" size={26} />
          <span className="ml-4">Send Message</span>
        </div>
      </div>

      {showCreateModal && (
        <SendMessageModal onSend={() => { setNewMessageSent(true) }} onClose={onCloseSendMessageModal} />
      )}
      {blockingNumber && (
        <BlockNumberModal
          title="Mark sender as spam"
          defaults={{ number: blockingNumber, channels: ["SMS"] }}
          onClose={onCloseBlockNumberModal}
        />
      )}
      {showBlockedModal && (
        <ExistingBlockNumberModal
          onClose={onCloseBlockedNumberModal}
        />
      )}
      {blockingKeywords !== undefined && (
        <BlockKeywordsModal keywords={blockingKeywords} onClose={onCloseBlockKeywordsModal} />
      )}
      {isExported && (
        <ExportEmailSentModal onClose={() => setIsExported(false)} />
      )}
    </>
  );
};

export default Messages;
