import { HttpService } from "./http.service";
import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { BlockedNumberModel } from "../utils/types";

export class BlockedNumberService {
  static create(data: any = {}, showSpinner = false) {
    return HttpService.post("/blacklist/number", trimObject(data), {}, showSpinner)
      .then((res) => new BlockedNumberModel(res));
  }

  static update(uuid: string, data: any = {}, showSpinner = false) {
    return HttpService.put(`/blacklist/number/${uuid}`, trimObject(data), {}, showSpinner)
      .then((res) => new BlockedNumberModel(res));
  }

  static delete(uuid: string, showSpinner = false) {
    return HttpService.delete(`/blacklist/number/${uuid}`, {}, {}, showSpinner)
  }

  static search(query: any = {}, showSpinner = false) {
    return HttpService.get("/blacklist/number", trimObject(query), {}, showSpinner)
      .then(res => formatPaginatedResult(BlockedNumberModel, res));
  }
}
