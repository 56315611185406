export enum AUDIO_TYPE {
  RECORD_CALL = 'record_prompt',
  WHISPER = 'whisper',
  KEEP_NUMBER = 'keepnumber',
  VOICE_MAIL_LITE = 'voicemail_lite',
  CAPTURE_DIGITS = 'capture_digits',
  IVR = 'ivr',
}

export enum TTS_VOICE {
  MALE = 'male',
  FEMALE = 'female',
}
