import React, { FC } from "react";
import { Icon, IIconProps } from "../icons";

export const WAOnboardingStep3: FC<IIconProps> = (props) => (
  <Icon baseWidth={25.364} baseHeight={22.828} {...props}>
    {(width, height, color = "#307cd8") => (
      <svg width={width} height={height} viewBox="0 0 25.364 22.828">
        <path
          id="Icon_material-business-center"
          data-name="Icon material-business-center"
          d="M13.146,20.987V19.719H4.281l-.013,5.073A2.528,2.528,0,0,0,6.8,27.328H24.56A2.528,2.528,0,0,0,27.1,24.791V19.719H18.219v1.268ZM25.828,9.573H20.742V7.036L18.206,4.5H13.133L10.6,7.036V9.573H5.536A2.544,2.544,0,0,0,3,12.109v3.8A2.528,2.528,0,0,0,5.536,18.45h7.609V15.914h5.073V18.45h7.609a2.544,2.544,0,0,0,2.536-2.536v-3.8A2.544,2.544,0,0,0,25.828,9.573Zm-7.609,0H13.146V7.036h5.073Z"
          transform="translate(-3 -4.5)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
