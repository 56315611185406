import React, {useCallback, useEffect, useState} from "react";
import {MenuItem} from '@mui/material';
import {useSelector} from 'react-redux';
import {Button, Chip} from '../../../components';
import {Form, FormControl, Input, useForm, Validators} from "../../../components/ReactiveForm";
import {ToastService, UserService} from "../../../services";
import {getActiveOrganization} from '../../../redux/selectors';

export type IUserProfileForm = {
  first_name: FormControl;
  last_name: FormControl;
  email: FormControl;
  phone_number: FormControl;
}

export type ICompanyProfileForm = {
  companyName: FormControl;
  vatNo: FormControl;
  country: FormControl;
  region: FormControl;
  city: FormControl;
  addressType: FormControl;
  address1: FormControl;
  address2: FormControl;
  zipcode: FormControl;
}

export const ADDRESS_TYPE = [
  { value: "POBOX", label: "POBOX" },
  { value: "STREET", label: "STREET" },
  { value: "DELIVERY", label: "DELIVERY" },
];

const Profile = () => {
  const organization = useSelector(getActiveOrganization);
  const [billingCurrency, setBillingCurrency] = useState('');
  const [userForm, userFormData] = useForm<IUserProfileForm>({
    first_name: new FormControl('', [Validators.required()]),
    last_name: new FormControl('', [Validators.required()]),
    email: new FormControl('', [Validators.required(), Validators.email()]),
    phone_number: new FormControl('', [Validators.required(), Validators.phone()]),
  });

  const [companyForm, companyFormData] = useForm<ICompanyProfileForm>({
    companyName: new FormControl('', [Validators.required()]),
    vatNo: new FormControl(''),
    country: new FormControl('', [Validators.required()]),
    region: new FormControl('', [Validators.required()]),
    city: new FormControl('', [Validators.required()]),
    addressType: new FormControl('', [Validators.required()]),
    address1: new FormControl('', [Validators.required()]),
    address2: new FormControl(''),
    zipcode: new FormControl('', [Validators.required()]),
  });

  const loadCompanyDetail = useCallback(() => {
    UserService.getCompanyDetail(organization.uuid).then((data) => {
      setBillingCurrency(data.billing_currency);
      if (data.details) {
        companyForm.patch({
          companyName: data.name,
          vatNo: data.details.TaxNumber,
          address1: data.details.AddressLine1,
          address2: data.details.AddressLine2,
          city: data.details.City,
          region: data.details.Region,
          zipcode: data.details.PostalCode,
          country: data.details.Country,
          addressType: data.details.AddressType,
        });
      }
    });
  }, [organization, companyForm]);

  const saveCompanyDetail = useCallback(() => {
    UserService.setCompanyDetail(organization.uuid, {
      name: companyFormData.companyName,
      details: {
        TaxNumber: companyFormData.vatNo,
        AddressLine1: companyFormData.address1,
        AddressLine2: companyFormData.address2,
        City: companyFormData.city,
        Region: companyFormData.region,
        PostalCode: companyFormData.zipcode,
        Country: companyFormData.country,
        AddressType: companyFormData.addressType,
      }
    }).then(() => {
      loadCompanyDetail();
    }, (err) => {
      ToastService.showHttpError(err, 'Saving company detail failed');
    });
  }, [companyFormData, loadCompanyDetail]);

  useEffect(() => {
    if(!organization || !organization.relationship.is_owner) return
    loadCompanyDetail();
  }, [loadCompanyDetail]);

  const loadUserProfile = useCallback(() => {
    UserService.getProfile().then((data) => {
      userForm.patch({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
      });
    });
  }, [userForm]);

  const saveUserProfile = useCallback(() => {
    UserService.modifyProfile({
      first_name: userFormData.first_name,
      last_name: userFormData.last_name,
      email: userFormData.email,
      phone_number: userFormData.phone_number,
    }).then(() => {
      loadUserProfile();
    }, (err) => {
      ToastService.showHttpError(err, 'Saving profile failed');
    });
  }, [userFormData, loadUserProfile]);

  const handleSaveProfile = useCallback(() => {
    userForm.validate();
    companyForm.validate();
    if (!userForm.invalid && !companyForm.invalid) {
      saveUserProfile();
      saveCompanyDetail();
    }
  }, [saveUserProfile, saveCompanyDetail]);

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  const companyDetail = (
    <>
      <div className="mb-4 flex justify-between">
        <h4 className="text-xl font-semibold">Company Details</h4>
        <div className="flex">
          <div className="flex flex-col">
            <p className="font-bold text-sm">Preferred currency to be billed in</p>
            <p className="text-xs">To change, please contact <a href="mailto:support@sudonum.com" className="text-primary font-bold">support@sudonum.com</a></p>
          </div>
          <Chip className="!text-sm ml-4 px-5 py-2 !text-blue-dark" clickable={false}>
            {billingCurrency || 'UNKNOWN'}
          </Chip>
        </div>
      </div>
      <Form className="grid md:grid-cols-2 gap-x-5 gap-y-4" formGroup={companyForm} onSubmit={saveCompanyDetail}>
        <Input
          control={companyForm.controls.companyName}
          label="Company Name"
          placeholder="Company Name"
          labelClass="text-xs font-medium"
          size="sm"
          fullWidth
        />
        <Input
          control={companyForm.controls.vatNo}
          label="Tax/VAT No"
          labelClass="text-xs font-medium"
          placeholder="Tax/VAT No"
          size="sm"
          fullWidth
        />
        <Input
          type="select"
          control={companyForm.controls.addressType}
          label="Address Type"
          placeholder="Country"
          labelClass="text-xs font-medium"
          containerClass="col-span-2"
          size="sm"
          fullWidth
        >
          {ADDRESS_TYPE.map((option) => (
            <MenuItem key={option.value} className="!text-sm" value={option.value}>{option.label}</MenuItem>
          ))}
        </Input>
        <Input
          containerClass="col-span-2"
          control={companyForm.controls.address1}
          placeholder="Address line 1"
          size="sm"
          fullWidth
        />
        <Input
          containerClass="col-span-2"
          control={companyForm.controls.address2}
          placeholder="Address line 2"
          size="sm"
          fullWidth
        />
        <Input
          control={companyForm.controls.city}
          placeholder="City"
          size="sm"
          fullWidth
        />
        <Input
          control={companyForm.controls.region}
          placeholder="Region"
          size="sm"
          fullWidth
        />
        <Input
          control={companyForm.controls.zipcode}
          placeholder="Postal/Zip code"
          size="sm"
          fullWidth
        />
        <Input
          type="country"
          control={companyForm.controls.country}
          placeholder="Country"
          size="sm"
          fullWidth
        />
      </Form>
    </>
  )

  return (
    <div className="px-5 sm:px-15 py-5">
      <div className="mb-20 bg-white rounded-md shadow-md px-12 py-8">
        <h4 className="text-xl font-semibold mb-2">Profile</h4>
        <Form className="grid md:grid-cols-2 gap-x-5 gap-y-4" formGroup={userForm}>
          <Input
            control={userForm.controls.first_name}
            label="First Name"
            placeholder="First Name"
            labelClass="text-xs font-medium"
            size="sm"
            fullWidth
          />
          <Input
            control={userForm.controls.last_name}
            label="Last Name"
            labelClass="text-xs font-medium"
            placeholder="Last Name"
            size="sm"
            fullWidth
          />
          <Input
            control={userForm.controls.email}
            label="Email"
            labelClass="text-xs font-medium"
            placeholder="Email"
            size="sm"
            fullWidth
            disabled
          />
          <Input
            type="phone"
            control={userForm.controls.phone_number}
            label="Phone Number"
            labelClass="text-xs font-medium"
            placeholder="Phone Number"
            size="sm"
            fullWidth
          />
        </Form>
        {!organization?.parent_uuid && organization?.relationship.is_owner && companyDetail}
        <div className="fixed right-0 bottom-0 w-full flex items-center justify-end pt-4 pb-4 pr-10 b shadow-[0_10px_15px_5px_rgba(0,0,0,0.3)] bg-gray-f6">
          <Button
            color="primary"
            className="text-sm rounded-full shadow-md px-6 ml-auto"
            onClick={handleSaveProfile}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
