import React, {useState} from "react";
import classnames from "classnames";
import {Card, Chip, Chips, TextField} from "../../../components";
import {PlusIcon, SearchIcon} from "../../../components/icons";
import {TagModel} from "../../../utils/types";
import TagCreateModal from "./TagCreateModal";
import TagEditModal from "./TagEditModal";

const defaultTags: TagModel[] = [
  { id: '1', name: 'Important' },
  { id: '2', name: 'Follow up Required' },
  { id: '3', name: 'New Prospect' },
  { id: '4', name: 'Lead' },
  { id: '5', name: 'Existing Customer' },
];

const Tags = () => {
  const [search, setSearch] = useState('');
  const [customTags, setCustomTags] = useState<TagModel[]>([
    { id: '100', name: 'Tag 1' },
  ]);
  const [editingTag, setEditingTag] = useState<TagModel>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>();

  return (
    <>
      <div className="relative h-full flex flex-col px-4 py-5">
        <div>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow-md"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search"
            clearable
            onChange={(value) => setSearch(value)}
          />
        </div>

        <Card className="flex-shrink overflow-auto mt-4">
          <div className="text-blue-dark font-semibold mb-1">Tags</div>
          <p className="text-gray text-xs">
            Tags are given to items to help you categories and label them for your personal or professional reasons.
            Here we have given you {defaultTags.length} standard tags, but feel free to add any new one's that might help you.
          </p>
          <Chips className="mt-3">
            {defaultTags.map((item, i) => (
              <Chip key={i} active>{item.name}</Chip>
            ))}
          </Chips>
          {!!customTags.length && (
            <>
              <div className="text-blue-dark font-medium text-sm mt-4">Your tags</div>
              <Chips className="mt-3">
                {customTags.map((item, i) => (
                  <Chip key={i} active onClick={() => setEditingTag(item)}>{item.name}</Chip>
                ))}
              </Chips>
            </>
          )}
        </Card>

        <div
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-64 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          onClick={() => setShowCreateModal(true)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Create a new tag</span>
        </div>
      </div>

      {showCreateModal && (
        <TagCreateModal onClose={() => setShowCreateModal(false)} />
      )}
      {editingTag && (
        <TagEditModal tag={editingTag} onClose={() => setEditingTag(null)} />
      )}
    </>
  );
};

export default Tags;
