import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment-timezone';
import Tooltip from '@mui/material/Tooltip';
import { AlignType, DataTable, IDataTableColumn, Menu } from '../../../components';
import { InfoCircleIcon, MoreVertIcon, PlayCircleIcon } from '../../../components/icons';
import { AddTagModal, RecordPlayerModal } from '../../../parts';
import CallDetailModal from '../CallDetailModal';
import { setCallLogs, setSelectedCallLogs } from '../../../redux/actions';
import { getCallFilters, getCallTab, getActiveOrganization, getSelectedCallLogs } from '../../../redux/selectors';
import { CallService, ToastService } from '../../../services';
import { ISearchCallsRequest } from '../../../utils/dto';
import { CALL_STATUS, CALL_TAB } from '../../../utils/enums';
import { formatDateTime, formatPhoneNumber } from '../../../utils/helpers';
import { CallRecordModel } from '../../../utils/types';

let lastQuery: any;
const rowsPerPage = 50;

export interface ICallTableProps {
  onBlockNumbers(call: CallRecordModel): void;
  onUnBlockNumbers(call: CallRecordModel): void;
  onQueryChange(query: any): void;
}

const CallsTable: FC<ICallTableProps> = ({
  onBlockNumbers,
  onUnBlockNumbers,
  onQueryChange,
}) => {
  const dispatch = useDispatch();
  const tab = useSelector(getCallTab);
  const filters = useSelector(getCallFilters);
  const selectedLogs = useSelector(getSelectedCallLogs);
  const organization = useSelector(getActiveOrganization);

  const [page, setPage] = useState(0);
  const [numberSearch, setNumberSearch] = useState('');
  const [callerSearch, setCallerSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [calls, setCalls] = useState<CallRecordModel[]>([]);
  const [filteredCalls, setFilteredCalls] = useState<CallRecordModel[]>([]);
  const [viewCall, setViewCall] = useState<CallRecordModel>();
  const [playingCall, setPlayingCall] = useState<CallRecordModel>();
  const [addTagCall, setAddTagCall] = useState<CallRecordModel>();

  useEffect(() => {
    lastQuery = null;
  }, [organization]);

  useEffect(() => {
    setPage(0);
    dispatch(setSelectedCallLogs([]));
  }, [tab]);

  const columns = useMemo<IDataTableColumn<CallRecordModel>[]>(() => {
    return [
      // {
      //   title: '',
      //   cellClass: 'max-w-5 w-5 !px-1',
      //   headerClass: 'max-w-5 w-5 !px-1',
      //   render: (row) => (
      //     <div
      //       className={classnames(
      //         'w-3 h-3 rounded-full mx-auto',
      //         { 'bg-green shadow-md': row.isNew },
      //       )}
      //     />
      //   ),
      // },
      {
        title: `Date and Time`,
        field: 'call_start_stamp',
        tooltip: {
          title: tab === CALL_TAB.MADE ? 'The local time and date the call was made' : 'The local time and date the call was received',
        },
        cellClass: 'max-w-[130px] w-[130px]',
        render(row: CallRecordModel) {
          return formatDateTime(row.call_start_stamp);
        },
      },
      {
        title: 'Caller',
        field: 'from_number',
        tooltip: {
          title: tab === CALL_TAB.MADE ? 'The person or number that made the call' : 'The person or number that started the call',
        },
        cellClass: 'max-w-[110px] w-[110px]',
        render(row: CallRecordModel) {
          return formatPhoneNumber(row.from_number);
        },
      },
      ...(tab === CALL_TAB.MADE ? [] : [{
        title: 'Sudo Number',
        field: 'sudo',
        cellClass: 'max-w-[127px] w-[127px] !font-semibold',
        tooltip: { title: 'The number that was called. This is the number you created to mask your personal number' },
        render(row: CallRecordModel) {
          if (!row.number) {
            return null;
          }
          return (
            <Link to={`/numbers/${row.number}`}>
              <div className="text-primary text-11p">{formatPhoneNumber(row.number)}</div>
              <div className="text-9p">{row.meta?.name || ''}</div>
            </Link>
          );
        },
      }]),
      {
        title: 'Target Number',
        field: 'target_number',
        tooltip: {
          title: tab === CALL_TAB.MADE ? 'The number the call is forwarded to' : 'The number the call is forwarded to when received',
        },
        cellClass: 'max-w-[140px] w-[140px]',
        render(row: CallRecordModel) {
          return formatPhoneNumber(row.target_number);
        },
      },
      ...(tab === CALL_TAB.MADE ? [{
        title: 'Ring Time',
        field: 'ring_time',
        align: 'center' as AlignType,
        tooltip: {
          title: 'The time it took it took for your call to start ringing',
        },
        render(row: CallRecordModel) {
          return row.formatRingTime();
        },
      },
      {
        title: 'Call Duration',
        field: 'call_duration',
        align: 'center' as AlignType,
        tooltip: {
          title: 'The length of the call',
        },
        cellClass: '!font-semibold',
        render(row: CallRecordModel) {
          return row.formatDuration();
        },
      }] : [{
        title: 'Call Duration',
        field: 'call_duration',
        tooltip: {
          title: 'The length of the call',
        },
        align: 'center' as AlignType,
        cellClass: '!font-semibold',
        render(row: CallRecordModel) {
          return row.formatDuration();
        },
      },
      {
        title: 'Ring Time',
        field: 'ringTime',
        tooltip: {
          title: 'The time it took it took for your call to start ringing',
        },
        align: 'center' as AlignType,
        render(row: CallRecordModel) {
          return row.formatRingTime();
        },
      }]),
      {
        title: 'Amount',
        field: 'amount',
        tooltip: {
          title: 'The cost of the call',
        },
        render(row: CallRecordModel) {
          return `${row.formatAmount()}`;
        },
      },
      {
        title: 'Status',
        field: 'status',
        tooltip: {
          title: 'Status indicates if your call was answered or missed',
        },
        render(row) {
          return <span
            className={classnames(
              { 'text-green-dark !font-semibold': row.status === CALL_STATUS.ANSWERED },
              { 'text-danger-light !font-semibold': row.status === CALL_STATUS.BLOCKED_NUMBER },
              { 'text-primary !font-semibold': row.status === CALL_STATUS.BUSY || row.status === CALL_STATUS.CALLER_BUSY || row.status === CALL_STATUS.MISSED || row.status === CALL_STATUS.CALLED_MISSED || row.status === CALL_STATUS.MISSED_CALL_NETWORK_FAILURE },
            )}
          >{row.status === CALL_STATUS.BLOCKED_NUMBER ? 'Blocked' : row.status}</span>;
        },
      },
      {
        render(row: CallRecordModel) {
          return (
            <div className="flex items-center justify-end">
              <Tooltip title="Play Recording" arrow placement="top">
                <div
                  className={classnames(
                    'w-7.5 h-7.5 flex-center flex-shrink-0 bg-[#fdeace] rounded-md ml-3 cursor-pointer',
                    { 'opacity-0 pointer-events-none': !row.voice_recording_url },
                  )}
                  onClick={() => setPlayingCall(row)}
                >
                  <PlayCircleIcon color="primary" size={22} />
                </div>
              </Tooltip>
              <Tooltip title="View Details" arrow placement="top">
                <div
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                  onClick={() => setViewCall(row)}
                >
                  <InfoCircleIcon color="blue" size={18} />
                </div>
              </Tooltip>
              {tab !== CALL_TAB.MADE && (
                <Menu
                  className="ml-2"
                  text={<MoreVertIcon color="blue-dark" />}
                  arrow={null}
                  items={[
                    ...(tab !== CALL_TAB.BLOCKED ? [{ text: 'Mark this caller as spam', value: 'block' }] : []),
                    ...(tab === CALL_TAB.BLOCKED ? [{ text: 'Unblock this caller', value: 'unblock' }] : []),
                    // { text: 'Add a tag', value: 'add-tag' },
                  ]}
                  menuItemClass="!text-xs"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  // tooltipText="More options"
                  onSelectItem={(option) => handleRowAction(row, option.value)}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [tab]);

  useEffect(() => {
    const numberFilter = filters.find((item) => item.category === 'number');
    setNumberSearch(numberFilter?.value || '');
    const callerFilter = filters.find((item) => item.category === 'caller');
    setCallerSearch(callerFilter?.value || '');
  }, [filters]);

  const loadCalls = useCallback((query) => {
    setLoading(true);
    CallService.search(query, false).then((data) => {
      setCalls(data.results);
      setTotalCount(data.count);
      dispatch(setCallLogs(data.results));
      setLoading(false);
    }).catch(() => {
      setCalls([]);
      setTotalCount(0);
      dispatch(setCallLogs([]));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!filters.length) {
      return;
    }

    const query: ISearchCallsRequest = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    if (tab !== CALL_TAB.BLOCKED) {
      query.not_statuses = [
        CALL_STATUS.BLOCKED_NUMBER,
      ].join();
    }

    filters.forEach((filter) => {
      if (!filter.value) {
        return;
      }

      switch (filter.category) {
        case 'range':
          query.start_date = moment(filter.value.startDate).utc().format('YYYY-MM-DD HH:mm:ss');
          query.end_date = moment(filter.value.endDate).utc().format('YYYY-MM-DD HH:mm:ss');
          break;

        case 'target_number':
          query.target_number = filter.value.replace(/\D/g, '');
          break;

        case 'number':
          query.number = filter.value.replace(/\D/g, '');
          break;

        case 'caller':
          query.from_number = filter.value.replace(/\D/g, '');
          break;

        case 'direction':
          query.call_direction = filter.value;
          break;

        case 'duration':
          const [min, max] = filter.value.split('~');
          if (min) query.min_duration = min;
          if (max) query.max_duration = max;
          break;

        case 'status':
          query.statuses = Array.isArray(filter.value) ? filter.value?.join() : filter.value;
          break;

        default:
          query[filter.category] = filter.value;
      }
    });

    // To prevent unnecessary reloads
    if (loading || JSON.stringify(lastQuery) === JSON.stringify(query)) return

    lastQuery = query;
    loadCalls(query);
    onQueryChange(query);
  }, [page, rowsPerPage, filters, tab, organization, loadCalls, onQueryChange]);

  useEffect(() => {
    let data = calls;
    if (numberSearch) {
      const numberKeywords = numberSearch.replace(/\D/g, '');
      data = data.filter((item) => item.number?.includes(numberKeywords));
    }
    if (callerSearch) {
      const callerKeywords = callerSearch.replace(/\D/g, '');
      data = data.filter((item) => item.from_number?.includes(callerKeywords));
    }
    setFilteredCalls(data);
  }, [numberSearch, callerSearch, calls]);

  const setSelectedCalls = useCallback((calls: CallRecordModel[]) => {
    dispatch(setSelectedCallLogs(calls));
  }, []);

  const refreshTable = () => {
    loadCalls(lastQuery);
  };

  const handleRowAction = (call: CallRecordModel, action: string) => {
    switch (action) {
      case 'unblock': {
        onUnBlockNumbers(call);
        return;
      }
      case 'block': {
        onBlockNumbers(call);
        return;
      }
      case 'add-tag': {
        setAddTagCall(call);
        return;
      }
      case 'mark-as-spam': {
        return;
      }
    }
  };

  const onAddTags = () => {
    ToastService.success(<><span className="text-success mr-1">Successfully</span> added a tag.</>);
    refreshTable();
  };

  return (
    <>
      <DataTable
        wrapperClass="h-0 flex-grow mt-3"
        columns={columns}
        serverSide={false}
        data={filteredCalls}
        totalCount={totalCount}
        extLoading={loading}
        size="xs"
        stripped
        stickyHeader
        // checkboxSelection
        selectedRows={selectedLogs}
        isEqual={(a, b) => a.uuid === b.uuid}
        page={page}
        pagination
        selfPagination
        rowsPerPage={rowsPerPage}
        paginationClass="mr-16"
        onSelectionChange={setSelectedCalls}
        onPaginationChange={(page) => setPage(page)}
      />

      {viewCall && (
        <CallDetailModal call={viewCall} onClose={() => setViewCall(null)} />
      )}
      {playingCall && (
        <RecordPlayerModal src={playingCall.voice_recording_url} onClose={() => setPlayingCall(null)} />
      )}
      {addTagCall && (
        <AddTagModal tags={[]} onSubmit={onAddTags} onClose={() => setAddTagCall(null)} />
      )}
    </>
  );
};

export default CallsTable;
