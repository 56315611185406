import React, {useState} from "react";
import {useHistory} from "react-router";
import AuthLayout from "../../../layouts/AuthLayout";
import CompanyDetail from "./CompanyDetail";
import CreditCard from "./CreditCard";
import AddToAccount from "./AddToAccount";
import PaymentComplete from "./PaymentComplete";
import {CREDIT_CARD, ROUTES} from "../../../constants";

export interface ISetupPaymentForm {
  companyName?: string;
  vatNo?: string;
  email?: string;
  cardType?: CREDIT_CARD;
  cardNumber?: string;
  csv?: string;
  expiryDate?: string;
  amount?: string;
}

const SetupPayment = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState<ISetupPaymentForm>({
    amount: 'R 2000',
  });

  const onUpdateForm = (data) => {
    setForm({
      ...form,
      ...data,
    });
  };

  const gotoPrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const gotoNextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      history.replace(ROUTES.DASHBOARD);
    }
  };

  return (
    <AuthLayout contentClass="w-185 h-full" contentWrapperClass="h-full">
      {step === 1 && (
        <CompanyDetail data={form} onNext={gotoNextStep} onSubmit={onUpdateForm} />
      )}
      {step === 2 && (
        <CreditCard data={form} onBack={gotoPrevStep} onNext={gotoNextStep} onSubmit={onUpdateForm} />
      )}
      {step === 3 && (
        <AddToAccount data={form} onBack={gotoPrevStep} onNext={gotoNextStep} onSubmit={onUpdateForm} />
      )}
      {step === 4 && (
        <PaymentComplete onBack={gotoPrevStep} onNext={gotoNextStep} />
      )}
    </AuthLayout>
  );
};

export default SetupPayment;
