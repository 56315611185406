import React, { FC, ReactElement, ReactNode } from "react";
import classnames from "classnames";
import { Tooltip } from "@mui/material";
import { InfoCircleIcon } from "../icons";

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface IWithTooltipIconProps {
  className?: string;
  tooltip?: NonNullable<ReactNode>;
  placement?: TooltipPlacement;
  icon?: ReactElement;
  iconClass?: string;
  tooltipClassName?: string;
  iconSize?: number;
}

export const WithTooltipIcon: FC<IWithTooltipIconProps> = ({
  className = '',
  tooltipClassName = '',
  tooltip,
  placement = 'top',
  icon,
  iconClass = 'ml-2',
  children,
  iconSize = 12,
}) => {
  return (
    <div className={classnames('relative flex items-center', className)}>
      {children}
      {tooltip && (
        <Tooltip title={tooltip} className={tooltipClassName} arrow placement={placement}>
          <span className={classnames('flex items-center', iconClass)}>
            {icon || <InfoCircleIcon color="blue-dark" size={iconSize} />}
          </span>
        </Tooltip>
      )}
    </div>
  );
};
