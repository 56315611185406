import React, {FC, useEffect, useMemo, useState} from "react";
import {Tooltip} from "@mui/material";
import {Button, TextField, Modal, Chips, Chip} from "../../components";
import {CloneIcon, SearchIcon} from "../../components/icons";
import {copyToClipboard} from "../../utils/helpers";
import {ToastService} from "../../services";
import {WebhookModel} from "../../utils/types";
import { WEBHOOK_TYPE } from "../../utils/enums";

interface IAddWebhookModalProps {
  webhooks?: string[];
  onClose: () => void;
  onSubmit: (newWebhooks: string[]) => void;
}

export const AddWebhookModal: FC<IAddWebhookModalProps> = ({
  webhooks = [],
  onClose,
  onSubmit,
}) => {
  const [search, setSearch] = useState('');
  const [allWebhooks, setAllWebhooks] = useState<WebhookModel[]>([]);
  const [selectedWebhooks, setSelectedWebhooks] = useState<string[]>(webhooks);

  useEffect(() => {
    WebhookModel.search({}, WEBHOOK_TYPE.CDR, false).then((data) => {
      setAllWebhooks(data.results);
    }).catch((err) => {
      setAllWebhooks([]);
      ToastService.showHttpError(err, 'Loading webhooks failed');
    });
  }, []);

  const searchedWebhooks = useMemo(() => {
    const keyword = search.toLowerCase();
    return allWebhooks
      .filter((item) => item.name.toLowerCase().includes(keyword));
  }, [search, allWebhooks]);

  const onToggleSelect = (webhook: WebhookModel) => {
    if (selectedWebhooks.includes(webhook.name)) {
      setSelectedWebhooks(selectedWebhooks.filter((t) => t !== webhook.name));
    } else {
      setSelectedWebhooks([...selectedWebhooks, webhook.name]);
    }
  };

  const onSave = () => {
    onSubmit(selectedWebhooks);
    onClose();
  };

  return (
    <Modal
      title="Add Webhook"
      disableBackdropClose
      footerComponent={(
        <>
          <Button className="text-primary text-sm ml-auto" onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            className="rounded-3xl text-sm shadow-md px-6"
            disabled={!selectedWebhooks.length}
            onClick={onSave}
          >
            Add
          </Button>
        </>
      )}
      onClose={onClose}
    >
      <TextField
        fullWidth
        placeholder="Search Webhooks"
        icon={<SearchIcon size={16} color="primary" />}
        value={search}
        clearable
        onChange={setSearch}
      />

      <Chips className="mt-4">
        {searchedWebhooks.map((item, i) => (
          <Tooltip
            key={i}
            title={(
              <div className="flex-center">
                {item.url}
                <CloneIcon className="ml-2 cursor-pointer" size={14} color="primary" onClick={() => copyToClipboard(item.url)} />
              </div>
            )}
            arrow
            placement="top"
          >
            <div>
              <Chip active={selectedWebhooks.includes(item.name)} onClick={() => onToggleSelect(item)}>
                {item.name}
              </Chip>
            </div>
          </Tooltip>
        ))}
      </Chips>
    </Modal>
  );
};
