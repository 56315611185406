import React from "react";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../../constants";
import ChatLogs from "./index";

const WhatsAppChatLogsRouting = () => (
  <Switch>
    <Route path={ROUTES.WHATSAPP.CHAT_LOGS.INDEX} component={ChatLogs} />
  </Switch>
);

export default WhatsAppChatLogsRouting;
