import classnames from "classnames";
import _ from 'lodash';
import React, { FC } from 'react';
import { Country } from "react-phone-number-input";
import { HorizontalStepper } from '../../../components';
import { NumberService, ToastService } from "../../../services";
import { AUDIO_TYPE, NUMBER_CREATE_MODE, NUMBER_FEATURE, NUMBER_MODE } from "../../../utils/enums";
import { uploadTTS } from "../../../utils/helpers";
import { ILocationInfo, INumberSummary } from "../../../utils/interfaces";
import { NumberCreateCompleteStep } from "./CompleteStep";
import { NumberCreateStep0 } from "./Step0";
import { NumberCreateStep1 } from "./Step1";
import { NumberCreateStep2 } from "./Step2";
import { NumberCreateStep3 } from "./Step3";
import { NumberCreateStep4 } from "./Step4";
import { NumberCreateStep5 } from "./Step5";


export interface INumberCreateProps {
  className?: string;
  addBillingInfo?: boolean;
  setTouched?: any;
  onClose(): void;
  onCreate(data: any): void;
}

export interface INumberCreateForm {
  category: string;
  subCategory: string;
  country: { iso: Country, code: string };
  prefix: string;
  available_dids: boolean;
  type: string;
  name: string;
  target: string;
  features: string[];
  available_dids_enabled: boolean;
  favouriteNumber: string;
  favouriteNumberOptions: {
    mode: number;
    similarNumbers: INumberSummary[];
    selectedSimilarNumber: string;
    prefix: string;
    prefixOptions: ILocationInfo[];
    searchedPrefixNumbers: INumberSummary[];
    selectedPrefixNumber: string;
    filter: string;
    search: string;
    searchedMatchNumbers: INumberSummary[];
    selectedMatchedNumber: string;
  };
  recordCallsEnabled: boolean;
  recordCalls: string;
  whisperMessageEnabled: boolean;
  whisperMessage: string;
}

const NumberCreate: FC<INumberCreateProps> = ({
  className = '',
  addBillingInfo = false,
  setTouched,
  onClose,
  onCreate,
}) => {
  //stepper
  const [step, setStep] = React.useState<number>(0);
  const [steps, setSteps] = React.useState<number[]>([0, 1, 2, 3, 4, 5, 6]);

  //state
  const [otpMode, setOtpMode] = React.useState<NUMBER_MODE>(NUMBER_MODE.NORMAL);
  const [type, setType] = React.useState<NUMBER_CREATE_MODE>(NUMBER_CREATE_MODE.RANDOM);
  const [numbers, setNumbers] = React.useState<INumberSummary[]>([]);
  const [prefixOptions, setPrefixOptions] = React.useState<ILocationInfo[]>();
  const [form, setForm] = React.useState<INumberCreateForm>({ features: [] } as any);
  const [initialForm, setInitialForm] = React.useState({})

  setTouched(!_.isEqual(form, initialForm))

  const onUpdateForm = (data) => {
    setForm({
      ...form,
      ...data,
    });
  };

  const gotoPrevStep = () => {
    if (step === 0) {
      onClose();
      return;
    }
    setStep(step - 1);
  };

  const gotoNextStep = () => {
    if (step === 6) {
      onSubmit(form);
      return;
    }
    setStep(step + 1);
  };

  const onOtpChange = (mode: NUMBER_MODE) => {
    setOtpMode(mode);
    if (mode === NUMBER_MODE.OTP) {
      form.features = [NUMBER_FEATURE.VOICE_OTP]
      setSteps(steps.filter((i) => i !== 5));
      return;
    }
    if (!steps.includes(5)) {
      form.features = []
      setSteps([...steps, 5].sort());
    }
  }

  const onTypeChange = (type: NUMBER_CREATE_MODE) => {
    setType(type);
    if (type === NUMBER_CREATE_MODE.RANDOM) {
      form.favouriteNumber = null;
      setSteps(steps.filter((i) => i !== 4));
    } else {
      if (!steps.includes(4)) {
        setSteps([...steps, 4].sort());
      }
    }
  }

  const onSubmit = async (data: INumberCreateForm, callback?) => {
    const formData: any = {
      name: data.name,
      target_number: data.target.replace(/\D/g, ''),
      features: data.features,
    }

    if (data.favouriteNumber) {
      formData.number = data.favouriteNumber.replace(/\D/g, '');
    } else {
      formData.country_code = data.country.code;
      formData.capabilities = "voice";
      formData.prefix = data.prefix;
    }

    if (formData.features[0] !== NUMBER_FEATURE.VOICE_OTP) {
      if (data.recordCallsEnabled) {
        formData.features.push(NUMBER_FEATURE.RECORD_CALL);
        formData.record_call_file = await uploadTTS(data.recordCalls, AUDIO_TYPE.RECORD_CALL);
      }
      if (data.whisperMessageEnabled) {
        formData.features.push(NUMBER_FEATURE.WHISPER);
        formData.tts = data.whisperMessage;
      }
    }

    NumberService.create(formData).then(() => {
      if (callback) {
        callback();
      } else {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> created your number.</>);
        onCreate(form);
      }
    }).catch((err) => {
      ToastService.showHttpError(err, 'Creating number failed');
    })
  };

  return (
    <div className={classnames('relative h-[85vh] md:h-[80vh] flex flex-col', className)}>
      <div className="px-8 py-4 w-full">
        <HorizontalStepper
          steps={steps}
          activeStep={step}
          className='w-full mt-6'
          color={step === 6 ? "#7ED93D" : "#307CD8"}
        />
      </div>
      <div className="h-0 flex-grow p-4 bg-white rounded-b-md">
        {steps[step] === 0 && (
          <NumberCreateStep1
            formData={form}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            onSubmit={onUpdateForm}
            setTouched={setTouched}
          />
        )}
        {steps[step] === 1 && (
          <NumberCreateStep2
            formData={form}
            prefixOptions={prefixOptions}
            setPrefixOptions={setPrefixOptions}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            onSubmit={onUpdateForm}
          />
        )}
        {steps[step] === 2 && (
          <NumberCreateStep0
            formData={form}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            type={type}
            onTypeSelect={onTypeChange}
          />
        )}
        {steps[step] === 3 && (
          <NumberCreateStep3
            formData={form}
            otpMode={otpMode}
            onOtp={onOtpChange}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            onSubmit={onUpdateForm}
          />
        )}
        {steps[step] === 4 && (
          <NumberCreateStep4
            numbers={numbers}
            prefixOptionsData={prefixOptions}
            formData={form}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            onSubmit={onUpdateForm}
          />
        )}
        {steps[step] === 5 && (
          <NumberCreateStep5
            formData={form}
            onBack={gotoPrevStep}
            onNext={gotoNextStep}
            onSubmit={onUpdateForm}
          />
        )}
        {steps[step] === 6 && (
          <NumberCreateCompleteStep
            formData={form}
            otpMode={otpMode}
            addBillingInfo={addBillingInfo}
            onBack={gotoPrevStep}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default NumberCreate;

