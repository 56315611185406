import React, {FC} from "react";
import {Icon, IIconProps} from "../icons";

export const WhatsAppStep4Svg: FC<IIconProps> = (props) => (
  <Icon baseWidth={576} baseHeight={119.783} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 576 119.783">
        <defs>
          <clipPath id="whatsapp-step4-mask">
            <rect fill="none" width="103" height="3"/>
          </clipPath>
        </defs>
        <g transform="translate(-395 -285.905)">
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(759 376.956)">
            <tspan x="-4.381" y="0">1</tspan>
          </text>
          <path fill="#d6e3f4" opacity={0.278} d="M3,0H223a3,3,0,0,1,3,3V98a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                transform="translate(402 300.253)"/>
          <rect fill="#307cd8" opacity={0.318} width="226" height="33" transform="translate(402 335.253)"/>
          <g transform="translate(596.382 338.837)">
            <rect fill="#d6e3f4" width="25.831" height="25.831" rx="6" transform="translate(0 0)"/>
            <path fill="#307cd8"
                  d="M9.858,3a6.858,6.858,0,1,0,6.858,6.858A6.861,6.861,0,0,0,9.858,3Zm.686,10.287H9.172V9.172h1.372Zm0-5.486H9.172V6.429h1.372Z"
                  transform="translate(3.058 3.058)"/>
          </g>
          <g transform="translate(565.096 338.837)">
            <g transform="translate(0 0)">
              <rect fill="#fdeace" width="25.831" height="25.831" rx="6"/>
            </g>
            <path fill="#f6a734"
                  d="M11.329,3.375a7.954,7.954,0,1,0,7.954,7.954A7.953,7.953,0,0,0,11.329,3.375Zm3.2,8.1L9.287,14.652a.17.17,0,0,1-.256-.149V8.155a.169.169,0,0,1,.256-.149l5.247,3.174A.175.175,0,0,1,14.534,11.478Z"
                  transform="translate(1.587 1.865)"/>
          </g>
          <g transform="translate(573.546 359.832) rotate(-13)">
            <path fill="#f6a734"
                  d="M9.1,21.658c-.07-.3-.127-.6-.213-.9q-2.45-8.312-4.9-16.624a3.2,3.2,0,0,1,2.195-4,3.178,3.178,0,0,1,3.98,2.124c.839,2.7,1.617,5.426,2.422,8.14.1.346.216.688.357,1.134,1.826-1.565,3.6-1.686,5.345.066.149-.134.285-.246.409-.369a3.19,3.19,0,0,1,4.609-.126c.367.336.554.359.933-.006a3.21,3.21,0,0,1,5.422,1.4q2.029,6.721,3.968,13.47a10.006,10.006,0,0,1,.219,4.491,12.176,12.176,0,0,0,.2,5.828c.253.815.127.985-.713,1.237-3.261.976-6.528,1.931-9.793,2.893q-2.136.63-4.271,1.261c-.756.225-.965.134-1.248-.608-.212-.554-.379-1.126-.6-1.676a7.766,7.766,0,0,0-3.116-3.643A29.647,29.647,0,0,1,7.828,30.2c-.685-.8-1.351-1.618-2.1-2.349-2.583-2.513-4.05-5.718-5.6-8.876-.283-.576-.088-1.1.526-1.6a3.774,3.774,0,0,1,4.141-.107,10.227,10.227,0,0,1,3.581,3.8c.157.248.357.469.536.7Zm23.66,7.352a14.953,14.953,0,0,0-.946-4.9c-1.114-3.767-2.208-7.54-3.332-11.3a2,2,0,0,0-3.868,1.051c.189.823.431,1.634.682,2.441.136.436.2.863-.307,1.031-.573.189-.743-.267-.872-.709-.358-1.228-.707-2.459-1.082-3.682a1.953,1.953,0,0,0-2.569-1.392,1.882,1.882,0,0,0-1.346,2.238c.2.936.545,1.841.742,2.777.052.247-.21.561-.327.844a7.392,7.392,0,0,1-.8-.456c-.088-.064-.086-.251-.125-.383-.359-1.2-.705-2.412-1.081-3.611a1.93,1.93,0,0,0-1.776-1.381,2.035,2.035,0,0,0-2.008,2.814c.215.746.45,1.486.674,2.23.12.4.17.813-.3.992-.535.2-.724-.207-.876-.614-.016-.043-.033-.086-.046-.129Q11.518,11.147,9.838,5.427c-.245-.833-.486-1.667-.746-2.495C8.675,1.6,7.7.98,6.588,1.319A2.031,2.031,0,0,0,5.229,4.1q2.533,8.54,5.075,17.078c.24.81.489,1.619.679,2.441.126.547-.276.924-.669.631A15.15,15.15,0,0,1,7.427,21.79c-.984-1.231-1.764-2.619-3.192-3.421A2.445,2.445,0,0,0,1.7,18.184a.712.712,0,0,0-.267.629c1.159,2.223,2.061,4.6,3.731,6.524,1.168,1.346,2.383,2.653,3.546,4a25.952,25.952,0,0,0,5.758,5.042,9.583,9.583,0,0,1,4.435,5.53c.119.422.322.511.759.38q6.239-1.867,12.493-3.68c.52-.151.669-.363.516-.874a9.7,9.7,0,0,1-.224-4.145C32.562,30.734,32.657,29.871,32.762,29.01Z"
                  transform="translate(1.75 5.675)"/>
            <path fill="#f6a734"
                  d="M6.884,17.465c-3.8-.6-6.865-4.41-6.884-8.5A8.762,8.762,0,0,1,7.649.1a8.811,8.811,0,0,1,9.626,6.03c1.164,3.493-.222,7.454-2.243,8.9a1.074,1.074,0,0,1,.107-1.408A7.821,7.821,0,0,0,11.621,1.5,7.434,7.434,0,0,0,3.828,2.8,7.459,7.459,0,0,0,1.07,10.132a7.605,7.605,0,0,0,5.057,6.141C6.671,16.486,7.049,16.755,6.884,17.465Z"
                  transform="translate(0 0)"/>
            <path fill="#f6a734"
                  d="M11.319,8.955a2.574,2.574,0,0,1-.371-1.828A4.992,4.992,0,0,0,1.791,3.5a4.922,4.922,0,0,0,.653,5.96,2.663,2.663,0,0,1,.724,1.711c-1.26-.162-2.8-2.264-3.1-4.181A6.067,6.067,0,0,1,10.6,2.031,5.935,5.935,0,0,1,11.319,8.955Z"
                  transform="translate(2.773 2.813)"/>
          </g>
          <text fill="#307cd8" fontSize={14} fontWeight={700} transform="translate(395 310.253)">
            <tspan x="18.647" y="14">Calls</tspan>
          </text>
          <g transform="translate(175 -4.663)">
            <path fill="#cfe0f7" opacity={0.486}
                  d="M8979.833,2586.1H8829.165a77.65,77.65,0,0,1,10.24-65.678,78.644,78.644,0,0,1,137.426,13.263,77.333,77.333,0,0,1,4.573,14.656,78.25,78.25,0,0,1-1.571,37.759Z"
                  transform="translate(-8221.5 -2195.433)"/>
            <g clipPath="url(#whatsapp-step4-mask)" transform="translate(570 387.67)">
              <g transform="translate(-564.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
              <g transform="translate(-528.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
              <g transform="translate(-492.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
            </g>
            <g clipPath="url(#whatsapp-step4-mask)" transform="translate(693 387.67)">
              <g transform="translate(-564.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
              <g transform="translate(-528.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
              <g transform="translate(-492.5 -336)">
                <line fill="none" stroke="#307cd8" strokeWidth={3} x2="27" transform="translate(564.5 337.5)"/>
              </g>
            </g>
          </g>
          <line fill="none" stroke="#307cd8" strokeWidth={3} strokeDasharray={8} x2="80" transform="translate(636.5 351.753)"/>
          <path fill="#307cd8" d="M5.977,19.5h18.26L15.82,27.919l2.18,2.1L30.023,18,18,5.977l-2.1,2.1L24.237,16.5H5.977Z"
                transform="translate(694.5 333.753)"/>
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(793 376.956)">
            <tspan x="-6.693" y="0">2</tspan>
          </text>
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(830 376.956)">
            <tspan x="-6.693" y="0">3</tspan>
          </text>
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(882 376.956)">
            <tspan x="-7.808" y="0">4</tspan>
          </text>
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(916 376.956)">
            <tspan x="-6.716" y="0">5</tspan>
          </text>
          <text fill="#307cd8" fontSize={23} fontWeight={600} transform="translate(953 376.956)">
            <tspan x="-7.21" y="0">6</tspan>
          </text>
        </g>
      </svg>
    )}
  </Icon>
);
