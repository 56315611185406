import React, {FC, ReactElement, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {Avatar, AvatarSize, AvatarVariant} from "../Avatar";

export interface IAvatarGroupProps {
  className?: string;
  max?: number;
  avatarClass?: string;
  spacing?: number;
  size?: AvatarSize | number;
  variant?: AvatarVariant;
  backgrounds?: string[];
  children: ReactElement[];
}

export const AvatarGroup: FC<IAvatarGroupProps> = ({
  children,
  className = '',
  spacing = 0,
  max,
  avatarClass,
  backgrounds,
  ...avatarProps
}) => {
  const avatars = useMemo(() => {
    let items = children;
    if (max && children.length > max) {
      items = [
        ...children.slice(0, max - 1),
        <Avatar>+{children.length - max + 1}</Avatar>
      ];
    }

    return items.map((item, i) => {
      const style = item.props.style || {};
      if (i > 0 && spacing) {
        style.marginLeft = `${spacing / 16}rem`;
      }
      if (backgrounds?.length) {
        style.backgroundColor = backgrounds[i % backgrounds.length];
      }
      return (
        React.createElement(
          item.type,
          { ...item.props, ...avatarProps, style, key: i, className: classnames(item.props.className, avatarClass) },
          item.props.children,
        )
      );
    });
  }, [children, max, spacing]);

  return (
    <div
      className={classnames(
        'flex items-center',
        className,
      )}
    >
      {avatars}
    </div>
  );
};
