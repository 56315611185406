import { SetStateAction } from "react";

export const onCloseConfirmation = (
  bypass: boolean,
  touched: boolean,
  extra: boolean,
  setShowConfirmCancel: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void; (arg0: boolean): void;
  },
  history: { replace: (arg0: string) => void; }, route) => {


  if (bypass) {
      history.replace(route);
    return;
  }

  // When the confirmation models just needs to close
  if (!bypass && bypass !== undefined) {
    setShowConfirmCancel(false)
    return;
  }


  if (touched || extra) {
    setShowConfirmCancel(true)
    return;
  }

  history.replace(route);
}
