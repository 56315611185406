import { ICreateCampaignRequest, ICreateCampaignResponse, ICreateListRequest, ICreateListResponse, IModifyAccountRequest, ISearchCampaignRequest, ISearchListRequest, IUploadCampaignListRequest, IUploadCampaignTemplateImageRequest } from "../utils/dto";
import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { WACampaignsLogModel, WACampaignsModel, WAChatIntroLogModel, WAChatIntroModel, WAListsModel, WAMessagesModel, WAOptedOutsModel, WATemplatesFileModel, WATemplatesModel, WhatsappAccountModel } from "../utils/types";
import { HttpService } from "./http.service";
export class WhatsappService {
  static searchAccounts(query: any = {}, showSpinner = false) {
    return HttpService.get('/whatsapp/accounts/', trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(WhatsappAccountModel, res));
  }

  static modifyAccount(account: IModifyAccountRequest, showSpinner = false) {
    return HttpService.patch(`/whatsapp/accounts/${account.uuid}/`, account, {}, showSpinner)
      .then((res) => new WhatsappAccountModel(res));
  }
}

export class WAChatIntroService {

  static search(query: any = {}, showSpinner = false) {
    return HttpService.get('/whatsapp/chat-intros/', trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(WAChatIntroModel, res));
  }
}

export class WAChatIntroLogService {

  static search(query: any = {}, showSpinner = false) {
    return HttpService.get("/whatsapp/chat-intro-logs/", trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(WAChatIntroLogModel, res));
  }
}
export class WACampaignsService {

  static search(query: ISearchCampaignRequest = {}, showSpinner = false) {
    return HttpService.get('/campaign/campaigns/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(WACampaignsModel, res));
  }

  static searchAccounts(query: any = {}, showSpinner = false) {
    return HttpService.get('/whatsapp/accounts/', trimObject(query), {}, showSpinner)
      .then((res) => res.results.map((item) => new WhatsappAccountModel(item)));
  }

  static searchTemplates(query: any = {}, showSpinner = false) {
    return HttpService.get('/whatsapp/message-templates/', trimObject(query), {}, showSpinner)
      .then((res) => res.results.map((item) => new WATemplatesModel(item)));
  }

  static createCampaign(campaign: ICreateCampaignRequest, showSpinner = true): Promise<ICreateCampaignResponse> {
    return HttpService.post('/campaign/campaigns/', trimObject(campaign), {}, showSpinner);
  }

  static modifyCampaign(campaign: ICreateCampaignRequest, showSpinner = true): Promise<ICreateCampaignResponse> {
    return HttpService.patch(`/campaign/campaigns/${campaign.uuid}/`, campaign, {}, showSpinner)
      .then((res) => new WACampaignsModel(res));
  }

  static uploadTemplateFile(data: IUploadCampaignTemplateImageRequest, showSpinner = true) {
    const formData = new FormData();
    formData.append('media_file', data.media_file);
    formData.append('type', data.type);
    formData.append('whatsapp_number', data.whatsapp_number);
    formData.append('caption', data.caption);

    return HttpService.post('/whatsapp/template-media/', formData, { 'Content-Type': 'multipart/form-data' }, showSpinner)
      .then((res) => new WATemplatesFileModel(res));
  }

  static getTemplateFile(campaign_uuid: string, query = {}, showSpinner = true) {
    return HttpService.get(`/whatsapp/template-media/${campaign_uuid}/`, query, {}, showSpinner)
  }

  static getCampaignLogs(campaign_log_uuid: string, query: any = {}, showSpinner = false) {
    return HttpService.get(`/campaign/logs/${campaign_log_uuid}/`, trimObject(query), {}, showSpinner)
      .then((res) => new WACampaignsLogModel(res));
  }

  static getCampaignOptedOuts(campaign_log_uuid: string, query: any = {}, showSpinner = false) {
    return HttpService.get(`/campaign/logs/${campaign_log_uuid}/optedouts/`, trimObject(query), {}, showSpinner)
      .then((res) => res.results.map((item) => new WAOptedOutsModel(item)));
  }
}

export class WAListsService {
  static search(query: ISearchListRequest = {}, showSpinner = false) {
    return HttpService.get('/campaign/campaign-lists/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(WAListsModel, res));
  }

  static searchListDetails(list, showSpinner = false) {
    return HttpService.get(`/campaign/campaign-lists/${list.uuid}/`, list, {}, showSpinner)
      .then((res) => new WAListsModel(res));
  }

  static searchListContacts(list_uuid, query: any = {}, showSpinner = false) {
    return HttpService.get(`/campaign/campaign-lists/${list_uuid}/contacts/`, trimObject(query), {}, showSpinner)
      .then((res) => formatPaginatedResult(WAListsModel, res));
  }

  static createList(list: ICreateListRequest, showSpinner = true): Promise<ICreateListResponse> {
    return HttpService.post('/campaign/campaign-lists/', trimObject(list), {}, showSpinner);
  }

  static modifyList(list: ICreateListRequest, showSpinner = true): Promise<ICreateListResponse> {
    return HttpService.patch(`/campaign/campaign-lists/${list.uuid}/`, list, {}, showSpinner)
      .then((res) => new WAListsModel(res));
  }

  static downloadSampleCSV(campaign_uuid: string, showSpinner = true) {
    return HttpService.get(`/campaign/campaigns/${campaign_uuid}/sample_csv/`, {}, {}, showSpinner)
  }

  static uploadCSV(data: IUploadCampaignListRequest, showSpinner = true) {
    const formData = new FormData();
    formData.append('uploaded_file', data.uploaded_file);

    return HttpService.post(`/campaign/campaigns/${data.campaign_uuid}/upload_csv/`, formData, { 'Content-Type': 'multipart/form-data' }, showSpinner)
  }
}

export class WAMessagesService {
  static search(query, showSpinner = true) {
    return HttpService.get('/whatsapp/messages/', query, {}, showSpinner)
      .then((res) => formatPaginatedResult(WAMessagesModel, res));
  }
}

export class WAEmbeddedOnboardingService {
  static create(accessToken: any, showSpinner = true) {
    return HttpService.post('whatsapp/private/meta/embedded-auth/', accessToken, {}, showSpinner)
  }
}
