export enum COMMON_ACTION {
  SET_COUNTRIES = 'SET_COUNTRIES',
}

export enum AUTH_ACTION {
  SET_TOKEN = 'SET_TOKEN',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
  SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION',
  SET_EDITING_ORGANIZATIONS = 'SET_EDITING_ORGANIZATIONS',
}

export enum MESSAGE_BOX_ACTION {
  SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL',
}

export enum SPINNER_ACTION {
  START_LOADING = 'START_LOADING',
  FINISH_LOADING = 'FINISH_LOADING'
}

export enum TOAST_ACTION {
  SHOW_TOAST = 'SHOW_TOAST',
  CLOSE_TOAST = 'CLOSE_TOAST',
}

export enum CALL_LOG_ACTION {
  SET_TAB = 'CALLS/SET_TAB',
  SET_FILTERS = 'CALLS/SET_FILTERS',
  ADD_FILTERS = 'CALLS/ADD_FILTERS',
  SET_LOGS = 'CALLS/SET_LOGS',
  SET_SELECTED_LOGS = 'CALLS/SET_SELECTED_LOGS',
  SET_TABLE_REF = 'CALLS/SET_TABLE_REF',
}

export enum SMS_LOG_ACTION {
  SET_TAB = 'SMS/SET_TAB',
  SET_FILTERS = 'SMS/SET_FILTERS',
  SET_LOGS = 'SMS/SET_LOGS',
  SET_SELECTED_LOGS = 'SMS/SET_SELECTED_LOGS',
  SET_TABLE_REF = 'SMS/SET_TABLE_REF',
}

export enum CAMPAIGN_ACTION {
  SET_WABA_ACCOUNTS = 'CAMPAIGNS/SET_WABA_ACCOUNTS',
}
