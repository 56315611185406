import { Switch, Tooltip } from "@mui/material";
import classnames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Chip, DataTable, IDataTableColumn, IDataTableRef, SelfHelpButton, TextField, TruncatedTableCell } from "../../../components";
import { CloneIcon, EditIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { ToastService } from "../../../services";
import { ISearchWebhooksRequest } from "../../../utils/dto";
import { WEBHOOK_TYPE } from "../../../utils/enums";
import { copyToClipboard, formatPhoneNumber, getWebhookTypeName } from "../../../utils/helpers";
import { WebhookModel } from "../../../utils/types";

const Webhooks = () => {
  //hooks
  const organization = useSelector(getActiveOrganization);

  //state
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    search: '',
  });
  const [tableRef, setTableRef] = useState<IDataTableRef>();

  //functions
  const onSearch = (e) => {
    e.preventDefault();
    setFilter({
      search,
    });
  };

  const loadWebhooks = useCallback((options, callback) => {
    const query: ISearchWebhooksRequest = {};
    if (filter.search) {
      query.name = filter.search;
    }

    Promise.all([
      WebhookModel.search(query, WEBHOOK_TYPE.CDR, false),
      WebhookModel.search(query, WEBHOOK_TYPE.WHATSAPP, false),
    ]).then(([cdrs_webhooks, wa_webhooks]) => {
      callback({
        count: cdrs_webhooks.count + wa_webhooks.count,
        data: [...cdrs_webhooks.results, ...wa_webhooks.results]
      });
    }).catch(() => {
      callback({ count: 0, data: [] })
    })
  }, [organization, filter]);

  const onToggleDisable = useCallback((webhook: WebhookModel) => {
    webhook.disable().then((res) => {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> {res.is_active ? 'enabled' : 'disabled'}.</>);
      tableRef?.refresh();
    }).catch((err) => {
      ToastService.showHttpError(err, `${webhook.is_active ? 'Disabling' : 'Enabling'} webhook failed`);
    });
  }, [tableRef]);

  const columns = useMemo<IDataTableColumn<WebhookModel>[]>(() => [
    {
      title: 'Name',
      field: 'name',
      cellClass: 'truncate max-w-[100px] md:max-w-[200px]',
      tooltip: { title: 'The name of your WebhookYour webhook’s name' },
      render(row: WebhookModel) {
        return (
          <TruncatedTableCell
            cellData={row.name}
            cellClass={classnames(!row.is_active && 'text-gray-9a')}
          />
        );
      },
    },
    {
      title: 'WA Account',
      field: 'whatsapp_number',
      tooltip: { title: 'The number that was called. This is the number you created to mask your personal number' },
      render(row: WebhookModel) {
        if (!row.whatsapp_number) {
          return null;
        }
        return (
          <Link className="text-primary text-xs font-semibold" to={`/whatsapp/accounts/${row.whatsapp_number}`}>
            {formatPhoneNumber(row.whatsapp_number)}
          </Link>
        );
      },
    },
    {
      title: 'Description',
      field: 'description',
      cellClass: 'max-w-[100px] md:max-w-[200px]',
      tooltip: { title: 'Description' },
      render(row: WebhookModel) {
        return (
          <TruncatedTableCell
            cellData={row.description}
          />
        );
      },
    },
    {
      title: 'URL',
      field: 'url',
      cellClass: 'truncate  max-w-[120px] md:max-w-[200px] lg:max-w[350]',
      tooltip: { title: 'a URL is a reference to a web resource that specifies its location on a computer network and a mechanism for retrieving it' },
      render(row: WebhookModel) {
        return (
          <Tooltip
            title={(
              <div className="flex-center">
                {row.url}
                <CloneIcon className="ml-2 cursor-pointer" size={14} color="primary" onClick={() => copyToClipboard(row.url)} />
              </div>
            )}
            arrow
            placement="top"
          >
            <span className={row.url && row.url.length > 22 ? "hover:text-blue text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer block" : "hover:text-blue text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer"}>
              {row.url.slice(0, 70)}{row.url.length > 70 && '...'}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Type',
      field: 'type',
      tooltip: { title: 'The webhook type refers to what the webhook is? Not sure and where the webhook can be used' },
      render(row: WebhookModel) {
        return (
          <Chip className="capitalize" active clickable={false} disabled={!row.is_active}>
            {getWebhookTypeName(row.type)}
          </Chip>
        );
      },
    },
    {
      cellClass: '!px-0',
      field: 'disabled',
      render(row: WebhookModel) {
        return (
          <Switch checked={row.is_active} onChange={() => onToggleDisable(row)} />
        );
      },
    },
    {
      cellClass: '!pl-0',
      render(row: WebhookModel) {
        return (
          <div className="flex items-center justify-end">
            <Tooltip title="Edit Webhook" arrow placement="left">
              <Link
                data-cy={"webhook-edit"}
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                to={`${ROUTES.DEVELOPER.WEBHOOKS.PREFIX}/${row.id()}/edit?type=${row.type}`}
              >
                <EditIcon color="blue" size={22} />
              </Link>
            </Tooltip>
          </div >
        );
      },
    },
  ], [tableRef]);

  return (
    <>
      <div className="h-full flex flex-col p-4">
        <div className="flex flex-row justify-end">
          <form onSubmit={onSearch}>
            <TextField
              size="sm"
              fullWidth
              className="!bg-white rounded-full shadow-md"
              containerClass="w-80 ml-auto"
              value={search}
              icon={<SearchIcon size={16} color="primary" />}
              placeholder="Search"
              clearable
              onChange={(value) => setSearch(value)}
            />
          </form>
          <SelfHelpButton url={'/docs/webhooks'} />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          serverSide
          datasource={loadWebhooks}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!px-8"
          cellClass={(row) => classnames('!text-xs !px-8', { '!text-secondary': !row.is_active })}
          pagination={false}
          onInit={setTableRef}
        />
        <Link
          data-cy="create-webhook"
          className={classnames(
            'plus-action fixed bottom-5 right-5 w-13.5 hover:w-62 h-13.5 flex items-center overflow-hidden px-3',
            'bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer',
          )}
          to={ROUTES.DEVELOPER.WEBHOOKS.CREATE}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Create Webhook</span>
        </Link>
      </div>
    </>
  );
};

export default Webhooks;
