import React, {FC, useCallback, useEffect} from "react";
import classnames from "classnames";
import {MenuItem} from "@mui/material";
import {FormControl, Input, useForm, Validators} from "../../components/ReactiveForm";
import {CREDIT_CARD, CREDIT_CARDS} from "../../constants";

export interface ICreditCardFormData {
  cardType?: CREDIT_CARD;
  cardNumber?: string;
  csv?: string;
  expiryDate?: string;
}

export type ICreditCardForm = {
  cardType: FormControl;
  cardNumber: FormControl;
  csv: FormControl;
  expiryDate?: FormControl;
};

export interface ICreditCardFormInitParams {
  getFormData: () => any;
}

export interface ICreditCardFormProps {
  className?: string;
  data?: ICreditCardFormData;
  onInit?: (params: ICreditCardFormInitParams) => void;
}

export const CreditCardForm: FC<ICreditCardFormProps> = ({
  className = '',
  data,
  onInit = () => {},
}) => {
  const [form] = useForm<ICreditCardForm>({
    cardType: new FormControl('', [Validators.required()]),
    cardNumber: new FormControl('', [Validators.required(), Validators.pattern(/^\d{16}$/)]),
    csv: new FormControl('', [Validators.required()]),
    expiryDate: new FormControl('', [Validators.required()]),
  });

  useEffect(() => {
    form.patch({
      cardType: data?.cardType || '',
      cardNumber: data?.cardNumber || '',
      csv: data?.csv || '',
      expiryDate: data?.expiryDate || '',
    });
  }, [data]);

  const getFormData = useCallback(() => {
    if (form.validate()) {
      return form.getFormData();
    }
    return null;
  }, [form]);

  useEffect(() => {
    onInit({
      getFormData,
    });
  }, [onInit, getFormData]);

  return (
    <div className={classnames(
      'grid md:grid-cols-2 gap-x-6 gap-y-4',
      className,
    )}>
      <Input
        type="select"
        control={form.controls.cardType}
        label="Select your card"
        fullWidth
      >
        {CREDIT_CARDS.map((option) => (
          <MenuItem key={option.value} className="!text-sm" value={option.value}>{option.text}</MenuItem>
        ))}
      </Input>
      <Input
        control={form.controls.cardNumber}
        label="Card Number"
        fullWidth
      />
      <Input
        control={form.controls.csv}
        label="CSV"
        fullWidth
      />
      <Input
        control={form.controls.expiryDate}
        label="Expiry Date"
        fullWidth
      />
    </div>
  );
};
