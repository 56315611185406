import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Modal } from "../../../../components";
import { Form, FormControl, Input, Validators, useForm } from "../../../../components/ReactiveForm";
import { ToastService, WAListsService } from "../../../../services";
import { WAListsModel } from "../../../../utils/types";

export interface ICreateCampaignFormData {
    listName: string;
}

export interface ICreateCampaignFormInitParams {
    getFormData: () => ICreateCampaignFormData;
}

export interface ICreateCampaignsFormProps {
    list?: WAListsModel;
    setTouched?: any;
    onInit?: (params: ICreateCampaignFormInitParams) => void;
    onClose?: (result?: any) => void;
    onEdit?: (result?: any) => void;
}

export type ICreateCampaignsForm = {
    listName: FormControl;
}

export const ListEditModal: FC<ICreateCampaignsFormProps> = ({
    list,
    setTouched,
    onInit = () => { },
    onClose,
}) => {

    const [form, formData] = useForm<ICreateCampaignsForm>({
        listName: new FormControl("", [Validators.required()]),
    });
    const [initialForm, setInitialForm] = useState(form.getFormData())

    if (setTouched) setTouched(!_.isEqual(form.getFormData(), initialForm))

    useEffect(() => {
        if (list) {
            form.patch({
                listName: list.name,
            });
            setInitialForm({
                listName: list.name,
            })
        }
    }, [list]);

    const getFormData = useCallback(() => {
        if (form.validate()) {
            const formData = form.getFormData();
            return {
                ...formData,
            };
        }
        return null;
    }, [form]);

    useEffect(() => {
        onInit({
            getFormData,
        });
    }, [onInit, getFormData]);

    const onListEdit = () => {
        const data = form.getFormData();
        if (data) {
            WAListsService.modifyList({
                uuid: list.uuid,
                name: data.listName
            }).then((res) => {
                onClose(res);
            }).catch((error) => {
                ToastService.error("Failed to update list name.");
            })
        }
    }

    return (
        <Modal
            title={"Edit List"}
            onClose={onClose}
            footerComponent={
                <Button
                    color="primary"
                    className="rounded-full"
                    onClick={() => onListEdit()}
                >
                    Save
                </Button>
            }
        >
            <Form formGroup={form}>
                <Input
                    control={form.controls.listName}
                    size="sm"
                    fullWidth
                    label="Update your list name below"
                    labelClass="text-md font-semibold"
                    placeholder="This is the list name"
                />
            </Form>
        </Modal>
    )
}